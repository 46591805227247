//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { DEFAULT_DATE_RANGE } from '@/config/constants';
import OneSideComparison from '@/components/comparison/OneSideComparison';
import ColorLegend from '@/components/shared/ColorLegend';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
var CURRENT_LOCALSTORAGE_COMPARISON_VERSION = 1;
export default {
  name: 'Comparison',
  components: {
    OneSideComparison: OneSideComparison,
    ColorLegend: ColorLegend
  },
  data: function data() {
    return {
      config: {
        first: null,
        second: null,
        _version: CURRENT_LOCALSTORAGE_COMPARISON_VERSION
      },
      isLoadingCompanyProperties: true
    };
  },
  // the values must be defined before mounting the template
  created: function created() {
    var config = this.$store.state.general.comparison;
    if (config && config._version === CURRENT_LOCALSTORAGE_COMPARISON_VERSION) {
      this.config.first = config.first ? config.first : this.getInitialConfig();
      this.config.second = config.second ? config.second : this.getInitialConfig();
    } else {
      this.config.first = this.getInitialConfig();
      this.config.second = this.getInitialConfig();
      if (config && config._version !== CURRENT_LOCALSTORAGE_COMPARISON_VERSION) {
        this.$toasted.global.info(this.$t('corruptSelection'));
        // remove incorrect configuration
        this.persistConfig();
      }
    }
    this.setSecondConfigDateToFirstIfDirty();
    this.setFirstConfigDateToSecondIfDirty();
    this.loadCompanyProperties();
  },
  methods: {
    loadCompanyProperties: function loadCompanyProperties() {
      var _this = this;
      this.isLoadingCompanyProperties = true;
      CompanyPropertiesService.update().then(function () {
        _this.isLoadingCompanyProperties = false;
      }).catch(function () {
        _this.isLoadingCompanyProperties = false;
      });
    },
    setFirstConfig: function setFirstConfig(config) {
      this.config.first = config;
      this.persistConfig();
      this.setSecondConfigDateToFirstIfDirty();
    },
    setSecondConfig: function setSecondConfig(config) {
      this.config.second = config;
      this.persistConfig();
      this.setFirstConfigDateToSecondIfDirty();
    },
    clearFirstConfig: function clearFirstConfig() {
      this.setFirstConfig(this.getInitialConfig());
      this.setFirstConfigDateToSecondIfDirty();
      this.resetBothIfBothDirty();
    },
    clearSecondConfig: function clearSecondConfig() {
      this.setSecondConfig(this.getInitialConfig());
      this.setSecondConfigDateToFirstIfDirty();
      this.resetBothIfBothDirty();
    },
    setSecondConfigDateToFirstIfDirty: function setSecondConfigDateToFirstIfDirty() {
      // if the second config is dirty, change its initial date to the date of the first config
      if (this.config.first && !this.config.first.dirty && this.config.second && this.config.second.dirty) {
        this.config.second.startInSeconds = this.config.first.startInSeconds;
        this.config.second.endInSeconds = this.config.first.endInSeconds;
        this.persistConfig();
      }
    },
    setFirstConfigDateToSecondIfDirty: function setFirstConfigDateToSecondIfDirty() {
      // if the first config is dirty, change its initial date to the date of the second config
      if (this.config.second && !this.config.second.dirty && this.config.first && this.config.first.dirty) {
        this.config.first.startInSeconds = this.config.second.startInSeconds;
        this.config.first.endInSeconds = this.config.second.endInSeconds;
        this.persistConfig();
      }
    },
    resetBothIfBothDirty: function resetBothIfBothDirty() {
      // resets the date of both sides, when both sides are deleted
      // otherwise, if second side was deleted after the first one, the first one would
      // still have the date of the previous second one
      if (this.config && this.config.first && this.config.second) {
        if (this.config.first.dirty && this.config.second.dirty) {
          this.setFirstConfig(this.getInitialConfig());
          this.setSecondConfig(this.getInitialConfig());
        }
      }
    },
    persistConfig: function persistConfig() {
      // store only the configuration which is not dirty
      var persistingConfig = {
        first: null,
        second: null,
        _version: CURRENT_LOCALSTORAGE_COMPARISON_VERSION
      };
      if (this.config.first && !this.config.first.dirty) {
        persistingConfig.first = this.config.first;
      }
      if (this.config.second && !this.config.second.dirty) {
        persistingConfig.second = this.config.second;
      }
      this.$store.commit('general/setComparison', persistingConfig);
    },
    getInitialConfig: function getInitialConfig() {
      var defaultConfig = {
        dirty: true,
        startInSeconds: DEFAULT_DATE_RANGE.START.valueOf(),
        endInSeconds: DEFAULT_DATE_RANGE.END.valueOf(),
        locations: {
          locations: [],
          locationGroups: [],
          queryLocations: []
        },
        surveyId: null,
        choiceAttributeIds: []
      };
      return defaultConfig;
    },
    switchSides: function switchSides() {
      var firstConfig = this.config.first;
      var secondConfig = this.config.second;
      this.config.first = secondConfig;
      this.config.second = firstConfig;
      this.persistConfig();
    }
  }
};