var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-tabs" },
    [
      _c(
        "b-card",
        { attrs: { "no-block": "" } },
        [
          !_vm.isLoading && !_vm.errorOccurred
            ? _c(
                "div",
                { staticClass: "search float-right" },
                [
                  _c("b-form-input", {
                    staticClass: "search-box",
                    attrs: {
                      placeholder: _vm.$t("searchPlaceholder"),
                      type: "search",
                    },
                    model: {
                      value: _vm.filter,
                      callback: function ($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter",
                    },
                  }),
                  _vm._v(" "),
                  _vm.filter.length === 0
                    ? _c("font-awesome", {
                        staticClass: "search-icon",
                        attrs: { icon: ["fas", "search"] },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-tabs",
            { attrs: { card: "", "no-body": "" } },
            [
              _vm.errorOccurred
                ? _c(
                    "div",
                    { staticClass: "alert-wrapper" },
                    [
                      _c("alert", {
                        staticClass: "positioned-alert",
                        attrs: {
                          type: "danger",
                          icon: ["fas", "exclamation-triangle"],
                          title: _vm.$t("serverError.title"),
                          message: _vm.$t("serverError.message"),
                          show: _vm.errorOccurred,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-tab",
                { attrs: { title: _vm.$t("panelTitle.active"), active: "" } },
                [
                  _vm.isLoading
                    ? _c("div", { staticClass: "spinner-wrapper" }, [
                        _c("div", { staticClass: "opn-spinner" }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isLoading && !_vm.errorOccurred
                    ? _c(
                        "div",
                        [
                          _c("user-management-overview-table", {
                            attrs: {
                              users: _vm.activeUsers,
                              filter: _vm.filter,
                              "display-user-names-configuration":
                                _vm.displayUserNamesConfiguration,
                            },
                            on: {
                              "deactivate-user": _vm.requestDeactivateUser,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                { attrs: { title: _vm.$t("panelTitle.deactivated") } },
                [
                  _vm.isLoading
                    ? _c("div", { staticClass: "spinner-wrapper" }, [
                        _c("div", { staticClass: "opn-spinner" }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isLoading && !_vm.errorOccurred
                    ? _c(
                        "div",
                        [
                          _c("user-management-overview-table", {
                            attrs: {
                              users: _vm.deactivatedUsers,
                              filter: _vm.filter,
                              "display-user-names-configuration":
                                _vm.displayUserNamesConfiguration,
                              type: "deactivated",
                            },
                            on: {
                              "activate-user": _vm.requestActivateUser,
                              "delete-user": _vm.requestDeleteUser,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }