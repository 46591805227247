import _objectSpread from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import LocationGroupSelect from '@/components/shared/LocationGroupSelect';
import { QuickStatistics, LocationPerformance, ChangeOverTime as ChangeOverTimeResource } from '@/services/ResourceService';
import QueryService from '@/services/QueryService';
import QueryUtils from '@/services/utils/QueryUtils';
import ClearFilter from '@/components/shared/ClearFilter';
import SearchFilterSelector from '@/components/shared/saved-search-filter/SearchFilterSelector';
import EmptyState from '@/components/shared/EmptyState';
import QuickStat from '@/components/dashboard/QuickStat';
import LocationList from '@/components/dashboard/LocationList';
import opnDateRangePicker from '@/components/shared/opnDateRangePicker';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import ColorLegend from '@/components/shared/ColorLegend';
import ChangeOverTime from '@/components/shared/change-over-time/ChangeOverTime';
import ChangeOverTimeLoading from '@/components/shared/change-over-time/ChangeOverTimeLoading';
import { mapState } from 'vuex';
export default {
  name: 'Dashboard',
  components: {
    LocationList: LocationList,
    LocationGroupSelect: LocationGroupSelect,
    QuickStat: QuickStat,
    opnDateRangePicker: opnDateRangePicker,
    SearchFilterSelector: SearchFilterSelector,
    ClearFilter: ClearFilter,
    EmptyState: EmptyState,
    ColorLegend: ColorLegend,
    ChangeOverTime: ChangeOverTime,
    ChangeOverTimeLoading: ChangeOverTimeLoading
  },
  data: function data() {
    return {
      isLoading: true,
      dashboardIsEmpty: false,
      isWaitingForChartToRender: false,
      dashboard: {
        quickStatistics: {
          change: {}
        },
        performance: [],
        changeOverTime: {}
      },
      filter: {
        location: {},
        dateRange: []
      }
    };
  },
  watch: {
    $route: function $route() {
      this.initializeDashboard();
    }
  },
  mounted: function mounted() {
    this.initializeDashboard();
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    currentUser: function currentUser(state) {
      return state.general.user;
    }
  })), {}, {
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    currentUserIsSuperAdmin: function currentUserIsSuperAdmin() {
      return this.currentUser.authorities.includes('ROLE_SUPER_ADMIN');
    }
  }),
  methods: {
    initializeDashboard: function initializeDashboard() {
      var _this = this;
      this.isLoading = true;
      var filter = QueryService.get('filter') || {};
      this.filter.dateRange = QueryUtils.getDateRangeFilter(filter.dateRange);
      this.filter.location = QueryUtils.getLocationFilter(filter.location);
      this.$nextTick(function () {
        _this.loadDashboard();
      });
    },
    loadDashboard: function loadDashboard() {
      var _this2 = this;
      this.isLoading = true;
      this.dashboardIsEmpty = false;
      var params = [this.$store.state.general.companyId, this.filter.location.queryLocations, moment(this.filter.dateRange[0]).format('DD.MM.YYYY'), moment(this.filter.dateRange[1]).format('DD.MM.YYYY')];
      var quickStatisticsParams = params.slice();
      var performanceParams = params.slice();
      var changeOverTimeParams = params.slice();
      performanceParams.push('location');
      Promise.all([QuickStatistics.getData.apply(null, quickStatisticsParams), LocationPerformance.getLocationData.apply(null, performanceParams), ChangeOverTimeResource.getData.apply(null, changeOverTimeParams), CompanyPropertiesService.update()]).then(function (response) {
        var quickStatistics = response[0].data;
        var performance = response[1].data;
        var changeOverTime = response[2].data;
        _this2.isLoading = false;
        _this2.dashboard = {
          performance: performance,
          quickStatistics: quickStatistics,
          changeOverTime: changeOverTime
        };
        _this2.isWaitingForChartToRender = true;
        window.setTimeout(function () {
          // this delay helps rendering the page faster (the resources are not blocked by the chart generation)
          _this2.isWaitingForChartToRender = false;
        }, _this2.$store.state.general.ie ? 1800 : 800);
      }, function () {
        _this2.dashboardIsEmpty = true;
        _this2.isLoading = false;
      });
    },
    dateRangeChanged: function dateRangeChanged(dateRange) {
      this.filter.dateRange = dateRange;
      QueryService.put('filter', this.filter);
    },
    locationsChanged: function locationsChanged(locationFilter) {
      this.filter.location = locationFilter;
      QueryService.put('filter', this.filter);
    },
    routeToFeedbackWithComments: function routeToFeedbackWithComments() {
      var query = QueryService.getAll();
      query.timelineFilter = QueryUtils.getTimelineFilter();
      query.timelineFilter.comments = 'ONLY_WITH_COMMENTS';
      this.$router.push({
        name: 'feedback',
        query: QueryService.encode(query)
      });
    }
  }
};