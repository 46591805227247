//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GenericChartWithTitle',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    centerTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};