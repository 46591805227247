var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "answer-inspection-modal" },
    [
      _c("modal-icon-button", {
        attrs: {
          index: 0,
          icon: ["far", "times"],
          message: _vm.$t("closeModal"),
          "color-type": "danger",
          "tooltip-placement": "right",
        },
        on: { click: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "title-wrapper" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.left.d200",
                modifiers: { hover: true, left: true, d200: true },
              },
            ],
            staticClass: "timeline-icon",
            attrs: { title: _vm.$t("icon.long") },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("icon.short")) +
                _vm._s(_vm.counter) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.questionText !== null
          ? _c("div", {
              staticClass: "title",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$options.filters.languageAwareString(_vm.questionText)
                ),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.hasSubquestion
          ? _c("div", { staticClass: "subtitle" }, [
              _vm._v("\n      ·\n      "),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.languageAwareString(_vm.attributeText)
                  ),
                },
              }),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.isLoadingInitial
        ? _c("div", { staticClass: "intro-text" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t(_vm.question.questionType + ".introText", {
                    number: _vm.totalCount - _vm.emptyCount,
                  })
                )
            ),
            _vm.emptyCount > 0
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      " " +
                        _vm.$t(_vm.question.questionType + ".introTextAddOn", {
                          number: _vm.emptyCount,
                        })
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(".\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-input-group",
        { staticClass: "search-box-wrapper" },
        [
          _c("b-form-input", {
            staticClass: "search-box",
            attrs: {
              type: "search",
              autocomplete: "off",
              placeholder: _vm.$t(
                _vm.question.questionType + ".searchPlaceholder"
              ),
            },
            on: {
              change: function ($event) {
                return _vm.resetList()
              },
            },
            model: {
              value: _vm.filter,
              callback: function ($$v) {
                _vm.filter = $$v
              },
              expression: "filter",
            },
          }),
          _vm._v(" "),
          _c(
            "b-input-group-append",
            [
              _c("b-button", { attrs: { variant: "primary" } }, [
                _vm._v(_vm._s(_vm.$t("searchButton"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "search-result-count" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.totalSearchResults) +
              "\n      " +
              _vm._s(_vm.$t(_vm.question.questionType + ".answers"))
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "answers" },
        [
          _vm._l(_vm.answers, function (answer) {
            return _c(
              "div",
              {
                key: answer.answerId,
                staticClass: "answer",
                on: {
                  click: function ($event) {
                    return _vm.openFeedbackModal(answer.answerSetId)
                  },
                },
              },
              [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      answer.answerEscaped !== null
                        ? answer.answerEscaped
                        : _vm.FormatUtils.escapeHTML(answer.text)
                    ),
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "show-feedback" },
                  [
                    _c("font-awesome", {
                      attrs: { icon: ["far", "comment-dots"] },
                    }),
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("showFeedback")) + "\n      "
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "infinite-loading",
            {
              ref: "infiniteLoading",
              attrs: {
                identifier: _vm.infiniteScrollIdentifier,
                spinner: "waveDots",
              },
              on: { infinite: _vm.onInfinite },
            },
            [
              _c("span", { attrs: { slot: "no-more" }, slot: "no-more" }, [
                _c("i", [
                  _vm._v(
                    _vm._s(_vm.$t(_vm.question.questionType + ".noMoreAnswers"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "span",
                { attrs: { slot: "no-results" }, slot: "no-results" },
                [
                  _c("i", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.question.questionType + ".noAnswersFound")
                      )
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }