var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      staticClass: "tablet-migration-pool",
      attrs: { "panel-title": _vm.$t("panelTitle"), "is-loading": false },
    },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("fatalError.title"),
              message: _vm.$t("fatalError.message"),
              show: _vm.fatalErrorOccurred,
            },
          }),
          _vm._v(" "),
          _vm.isLoading
            ? _c("div", { staticClass: "spinner-wrapper" }, [
                _c("div", { staticClass: "opn-spinner" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading && !_vm.fatalErrorOccurred
            ? _c(
                "div",
                [
                  _c("b-table", {
                    attrs: {
                      striped: "",
                      hover: "",
                      items: _vm.items,
                      fields: _vm.fields,
                      "sort-by": "number",
                      "show-empty": true,
                      "empty-text": _vm.$t("emptyTableText"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(number)",
                          fn: function (data) {
                            return [
                              data.item.number
                                ? _c("span", [_vm._v(_vm._s(data.item.number))])
                                : _c("span", [_vm._v("—")]),
                            ]
                          },
                        },
                        {
                          key: "cell(currentLocation)",
                          fn: function (ref) {
                            return [
                              _c("span", { staticClass: "italic-greyed-out" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("noLocation")) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "cell(lastActiveLocation)",
                          fn: function (data) {
                            return [
                              data.item.lastActiveLocation !== null
                                ? _c("span", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(data.item.lastActiveLocation) +
                                        "\n          "
                                    ),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          },
                        },
                        {
                          key: "cell(lastActiveTimestamp)",
                          fn: function (data) {
                            return [
                              data.item.lastActiveLocation !== null
                                ? _c("span", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.formatDateTime(
                                            data.item.lastActiveTimestamp
                                          )
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          },
                        },
                        {
                          key: "cell(type)",
                          fn: function (data) {
                            return [
                              data.item.type.os === "iOS"
                                ? _c("font-awesome", {
                                    staticClass: "apple",
                                    attrs: { icon: ["fab", "apple"] },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              data.item.type.os === "ANDROID"
                                ? _c("font-awesome", {
                                    staticClass: "android",
                                    attrs: { icon: ["fab", "android"] },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              data.item.type.os === "UNKNOWN"
                                ? _c("font-awesome", {
                                    staticClass: "unknown",
                                    attrs: { icon: ["fas", "question"] },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.languageAwareString(
                                      data.item.type.name
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "cell(newMeasurement)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-button",
                                {
                                  staticClass: "float-right mr-3",
                                  attrs: {
                                    variant: "primary",
                                    size: "sm",
                                    disabled: _vm.isLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showModal(data.item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("startNewMeasurement")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      904623671
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }