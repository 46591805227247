var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-management-create-edit-survey-modal" },
    [
      _c("modal-icon-button", {
        attrs: {
          index: 0,
          icon: ["far", "times"],
          "color-type": "danger",
          message: _vm.$t("tooltip.closeModal"),
          "tooltip-placement": "top",
        },
        on: { click: _vm.closeModal },
      }),
      _vm._v(" "),
      _vm.errorOccurred
        ? _c(
            "div",
            { staticClass: "mr-2 ml-2" },
            [
              _c("alert", {
                attrs: {
                  icon: ["fas", "times"],
                  type: "danger",
                  title: _vm.$t("error.title"),
                  message: _vm.$t("error.message"),
                  show: _vm.errorOccurred,
                  closeable: false,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "bold-uppercase-legend",
                          attrs: { label: _vm.$t("form.label.surveyName") },
                        },
                        [
                          _c("form-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t(
                                "form.placeholder.surveyName"
                              ),
                              "max-length": 191,
                            },
                            model: {
                              value: _vm.surveyName,
                              callback: function ($$v) {
                                _vm.surveyName = $$v
                              },
                              expression: "surveyName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "bold-uppercase-legend",
                          attrs: { label: _vm.$t("form.label.languages") },
                        },
                        [
                          _c("form-multi-select", {
                            attrs: {
                              id: "survey-languages",
                              value: _vm.selectedLanguages,
                              options: _vm.availableLanguages,
                              placeholder: _vm.$t("form.placeholder.languages"),
                              "close-on-select": false,
                              searchable: false,
                              "allow-empty": false,
                              limit: 1,
                              multiple: "",
                            },
                            on: { input: _vm.surveyLanguagesChanged },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showUpdateTranslationsAlert
                        ? _c(
                            "div",
                            { staticClass: "alert alert-warning" },
                            [
                              _c("font-awesome", {
                                attrs: {
                                  icon: ["fas", "exclamation-triangle"],
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("alert.updateSurveyWithNewLanguages")
                                  ),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: {
                        type: "submit",
                        variant: "primary",
                        disabled: !_vm.isValid || _vm.isLoading,
                      },
                      on: { click: _vm.createOrSaveSurvey },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.isCreateSurveyModal
                              ? _vm.$t("form.button.saveAndAddQuestions")
                              : _vm.$t("form.button.saveSurvey")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }