//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LocationGroupTableEntry',
  props: {
    items: {
      type: Array,
      required: true
    },
    isGroup: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    labelTranslationKey: function labelTranslationKey() {
      if (this.isGroup) {
        return 'locationGroupCount';
      }
      return 'locationCount';
    }
  }
};