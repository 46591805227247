import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4langEn from '@amcharts/amcharts4/lang/en';
import am4langDeCh from '@amcharts/amcharts4/lang/de_CH';
import am4langFrFr from '@amcharts/amcharts4/lang/fr_FR';
import am4langItIt from '@amcharts/amcharts4/lang/it_IT';
export default {
  name: 'CompanyStatisticsPieChart',
  props: {
    data: {
      type: Object,
      required: true,
      validator: function validator(item) {
        return item.distribution !== undefined;
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    this.initChart();
  },
  computed: {
    enrichedDistribution: function enrichedDistribution() {
      var _this = this;
      return Object.keys(this.data.distribution).map(function (category) {
        var absoluteValue = _this.data.distribution[category];
        return {
          category: category,
          categoryLabel: _this.$t("category.".concat(category)),
          absoluteValue: absoluteValue
        };
      });
    }
  },
  methods: {
    initChart: function initChart() {
      this.chart = this.createChart(this.$refs.chart, this.enrichedDistribution, this.data.legendPosition, this.$store.state.general.language, {
        amount: this.$t('amount')
      });
    },
    createChart: function createChart(element, distribution, legendPosition, language, translatedStrings) {
      var chart = am4core.create(element, am4charts.PieChart);
      chart.tooltip.numberFormatter.numberFormat = '#,###.#';
      chart.data = distribution;
      chart.innerRadius = am4core.percent(40);
      chart.paddingRight = 10;
      chart.paddingTop = 40;
      chart.paddingBottom = 0;
      chart.language.locale = function () {
        if (language === 'de') {
          return am4langDeCh;
        } else if (language === 'fr') {
          return am4langFrFr;
        } else if (language === 'it') {
          return am4langItIt;
        } else {
          return am4langEn;
        }
      }();
      var pieSeries = chart.series.push(new am4charts.PieSeries());
      if (distribution.length !== 2) {
        pieSeries.colors.list = [am4core.color('#5dbfe3'), am4core.color('#53a2c3'), am4core.color('#4783a2'), am4core.color('#3c617e'), am4core.color('#30425d')];
      } else {
        pieSeries.colors.list = [am4core.color('#5dbfe3'), am4core.color('#4783a2')];
      }
      pieSeries.dataFields.value = 'absoluteValue';
      pieSeries.dataFields.category = 'category';
      pieSeries.tooltip.fontSize = 12;
      pieSeries.tooltip.getFillFromObject = false;
      pieSeries.tooltip.background.fill = am4core.color('#333');
      pieSeries.tooltip.background.strokeWidth = 0;
      pieSeries.tooltip.background.cornerRadius = 3;
      pieSeries.tooltip.background.pointerLength = 4;
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.legendSettings.itemLabelText = '[font-size: 12px]{categoryLabel}';
      pieSeries.legendSettings.itemValueText = '[bold font-size: 12px]{value.percent}%[/]';
      pieSeries.slices.template.tooltipText = "[bold]{categoryLabel}[/]\n".concat(translatedStrings.amount, ": [bold]{value.value}[/]");
      pieSeries.slices.template.states.getKey('hover').properties.scale = 1.01;
      pieSeries.slices.template.states.getKey('active').properties.shiftRadius = 0;
      chart.legend = new am4charts.Legend();
      chart.legend.position = 'right';
      chart.legend.useDefaultMarker = true;
      chart.legend.width = 140;
      chart.legend.marginLeft = 0;
      chart.legend.itemContainers.template.paddingTop = 0;
      chart.legend.itemContainers.template.paddingBottom = 0;
      chart.legend.markers.template.dx = 8;
      chart.legend.markers.template.dy = 5;
      var marker = chart.legend.markers.template.children.getIndex(0);
      marker.width = 10;
      marker.height = 10;
      marker.cornerRadius(5, 5, 5, 5);
      return chart;
    },
    resetChart: function resetChart() {
      if (this.chart !== null) {
        this.chart.dispose();
        this.chart = null;
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.resetChart();
  },
  watch: {
    data: {
      deep: true,
      handler: function handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.resetChart();
          this.initChart();
        }
      }
    }
  }
};