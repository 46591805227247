var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "progress" },
    [
      _c("transition", [
        _c(
          "div",
          {
            class: _vm.classObject,
            style: _vm.styleObject,
            attrs: {
              role: "progressbar",
              "aria-valuenow": _vm.value,
              "aria-valuemin": 0,
              "aria-valuemax": _vm.max,
            },
          },
          [
            _vm._t("default", [
              _vm.showProgress
                ? [
                    _c("div", { staticClass: "progress-indicator" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top.d200",
                              modifiers: { hover: true, top: true, d200: true },
                            },
                          ],
                          staticClass: "value",
                          class: {
                            "zero-percent": _vm.value === 0 && _vm.isPercentage,
                            zero: _vm.value === 0 && !_vm.isPercentage,
                            inactive: _vm.value === null,
                          },
                          attrs: {
                            title:
                              _vm.value === null
                                ? _vm.$t("noDataAvailable")
                                : null,
                          },
                        },
                        [_vm._v(_vm._s(_vm.labelComputed))]
                      ),
                    ]),
                  ]
                : _vm.showValue
                ? [_vm._v(_vm._s(_vm.value))]
                : _vm._e(),
            ]),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }