var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "start-measuring-modal" },
    [
      _c("modal-icon-button", {
        attrs: {
          index: 0,
          icon: ["far", "times"],
          message: _vm.$t("tooltip.closeModal"),
          "color-type": "danger",
          "tooltip-placement": "right",
        },
        on: { click: _vm.closeModal },
      }),
      _vm._v(" "),
      _vm.isLoading ? _c("div", [_vm._m(0)]) : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            [
              _vm.noLocationsAvailable || _vm.success || _vm.errorOccurred
                ? _c(
                    "div",
                    { staticClass: "mr-2 ml-2" },
                    [
                      _c("alert", {
                        attrs: {
                          icon: ["fas", "check"],
                          type: "success",
                          title: _vm.$t("success.title"),
                          message: _vm.$t("success.message"),
                          show: _vm.success,
                          closeable: false,
                        },
                      }),
                      _vm._v(" "),
                      _c("alert", {
                        attrs: {
                          icon: ["fas", "times"],
                          type: "danger",
                          title: _vm.$t("error.title"),
                          message: _vm.$t("error.message"),
                          show: _vm.errorOccurred,
                          closeable: false,
                        },
                      }),
                      _vm._v(" "),
                      _c("alert", {
                        attrs: {
                          icon: ["fas", "exclamation-triangle"],
                          type: "danger",
                          title: _vm.$t("noLocationsAvailable.title"),
                          message: _vm.$t("noLocationsAvailable.message"),
                          show: _vm.noLocationsAvailable,
                          closeable: false,
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c("b-col", { attrs: { sm: "12" } }, [
                            _c("p", [_vm._v(_vm._s(_vm.$t("measuringInfo")))]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-3 select-area" },
                        [
                          _c(
                            "b-form-select",
                            {
                              staticClass: "location-selector",
                              model: {
                                value: _vm.selectedLocation,
                                callback: function ($$v) {
                                  _vm.selectedLocation = $$v
                                },
                                expression: "selectedLocation",
                              },
                            },
                            _vm._l(
                              _vm.locationGroups,
                              function (locationGroup) {
                                return _c(
                                  "optgroup",
                                  {
                                    key: locationGroup.locationGroupId,
                                    attrs: {
                                      label: locationGroup.locationGroupName,
                                    },
                                  },
                                  _vm._l(
                                    _vm.locationsGroupedByLocationGroup[
                                      locationGroup.locationGroupId
                                    ],
                                    function (location) {
                                      return _c(
                                        "option",
                                        {
                                          key:
                                            location.locationGroupId +
                                            "-" +
                                            location.value.locationId,
                                          domProps: { value: location.value },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(location.text) +
                                              "\n            "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "submit-button",
                              attrs: {
                                variant: "primary",
                                block: true,
                                disabled: !_vm.selectedLocation,
                              },
                              on: { click: _vm.startMeasurement },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("startMeasurementButton")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-wrapper" }, [
      _c("div", { staticClass: "opn-spinner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }