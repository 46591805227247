import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//

import { NotificationDispatcherResource } from '@/services/ResourceService';
export default {
  name: 'NotificationDispatcher',
  data: function data() {
    return {
      query: {}
    };
  },
  mounted: function mounted() {
    this.query = this.$route.query;
    if (this.query.token) {
      NotificationDispatcherResource.visitLink(this.query.token).then(this.redirect, this.redirect);
    } else {
      this.redirect();
    }
  },
  methods: {
    redirect: function redirect() {
      if (this.query.path) {
        this.$router.replace({
          path: this.query.path
        });
      } else {
        this.$router.replace({
          name: 'dashboard'
        });
      }
    }
  }
};