var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "print-comments print-section" }, [
    _c("div", { staticClass: "print-h2" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("title")) + "\n  "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "comment-grid" },
      _vm._l(_vm.data, function (comment) {
        return _c("print-single-comment", {
          key: comment.answerSetId,
          attrs: { data: comment },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }