var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      staticClass: "company-statistics",
      attrs: {
        "panel-title": _vm.$t("companyStatistics"),
        "is-loading": false,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("loadingError.title"),
              message: _vm.$t("loadingError.message"),
              show: !_vm.isLoading && _vm.hasError,
            },
          }),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "info",
              message: _vm.$t("loadingErrorNoData.message"),
              show: !_vm.isLoading && _vm.hasErrorNoData,
            },
          }),
          _vm._v(" "),
          _vm.isLoading
            ? [
                _c("div", { staticClass: "spinner-wrapper" }, [
                  _c("div", {
                    staticClass: "opn-spinner",
                    class: { shown: true },
                  }),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.statistics !== null && !_vm.isLoading
            ? [
                _c(
                  "div",
                  { staticClass: "year-buttons" },
                  [
                    _c(
                      "b-button-group",
                      { staticClass: "btn-group", attrs: { size: "sm" } },
                      [
                        _c(
                          "b-button",
                          {
                            class: { active: _vm.selectedView === "TOTAL" },
                            attrs: { variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                _vm.selectedView = "TOTAL"
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("total")))]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.years, function (year) {
                          return _c(
                            "b-button",
                            {
                              key: year,
                              class: { active: _vm.selectedView === year },
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function ($event) {
                                  _vm.selectedView = year
                                },
                              },
                            },
                            [_vm._v(_vm._s(year))]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.selectedView === "TOTAL"
                  ? _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c(
                          "b-container",
                          { staticClass: "text-content", attrs: { fluid: "" } },
                          [
                            _c(
                              "div",
                              { staticClass: "text-content-inner-wrapper" },
                              [
                                _c(
                                  "company-statistics-row",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "label.numberOfFeedbacksWithSpam"
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("groupedNumber")(
                                            _vm.statistics.total
                                              .numberOfFeedbacksWithSpam
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("company-statistics-row", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("groupedNumber")(
                                          _vm.statistics.total
                                            .numberOfSpamFeedbacks
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "padded-title",
                                      attrs: { slot: "title" },
                                      slot: "title",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "label.numberOfSpamFeedbacks"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "company-statistics-row",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "label.numberOfFeedbacksWithoutSpam"
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("groupedNumber")(
                                            _vm.statistics.total
                                              .numberOfFeedbacksWithoutSpam
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "company-statistics-row",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "label.timeOfFirstFeedback"
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm
                                            .moment(
                                              _vm.statistics.total
                                                .timeOfFirstFeedback
                                            )
                                            .format("DD.MM.YYYY HH:mm")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "company-statistics-row",
                                  {
                                    attrs: {
                                      title: _vm.$t("label.timeOfLastFeedback"),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm
                                            .moment(
                                              _vm.statistics.total
                                                .timeOfLastFeedback
                                            )
                                            .format("DD.MM.YYYY HH:mm")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("hr"),
                                _vm._v(" "),
                                _c(
                                  "company-statistics-row",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "label.numberOfCommentsWithoutSpam"
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("groupedNumber")(
                                            _vm.statistics.total
                                              .numberOfCommentsWithoutSpam
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("hr"),
                                _vm._v(" "),
                                _c(
                                  "company-statistics-row",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "label.numberOfActiveUsers"
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("groupedNumber")(
                                            _vm.statistics.total
                                              .numberOfActiveUsers
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("company-statistics-row", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("groupedNumber")(
                                          _vm.statistics.total
                                            .numberOfActiveAdmins
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "padded-title",
                                      attrs: { slot: "title" },
                                      slot: "title",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("label.numberOfActiveAdmins")
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("company-statistics-row", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("groupedNumber")(
                                          _vm.statistics.total
                                            .numberOfActiveManagers
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "padded-title",
                                      attrs: { slot: "title" },
                                      slot: "title",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "label.numberOfActiveManagers"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("hr"),
                                _vm._v(" "),
                                _c(
                                  "company-statistics-row",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "label.numberOfActiveLocations"
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("groupedNumber")(
                                            _vm.statistics.total
                                              .numberOfActiveLocations
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "company-statistics-row",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "label.numberOfActiveLocationGroups"
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("groupedNumber")(
                                            _vm.statistics.total
                                              .numberOfActiveLocationGroups
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("hr"),
                                _vm._v(" "),
                                _c(
                                  "company-statistics-row",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "label.numberOfActiveTabletSources"
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.statistics.total
                                            .numberOfActiveTabletSources
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "company-statistics-row",
                                  {
                                    attrs: {
                                      title: _vm.$t(
                                        "label.numberOfActiveWebSources"
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.statistics.total
                                            .numberOfActiveWebSources
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "chart-content" }, [
                          _c("div", { staticClass: "multi-chart" }, [
                            _c("div", { staticClass: "chart-row" }, [
                              _c(
                                "div",
                                { staticClass: "chart-box" },
                                [
                                  _c("div", { staticClass: "chart-title" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("chartTitleFeedbacks"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("company-statistics-chart", {
                                    staticClass: "chart",
                                    attrs: {
                                      data: {
                                        year: "TOTAL",
                                        oneYear: false,
                                        monthlyDistribution:
                                          _vm.statistics.total
                                            .monthlyDistribution,
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "chart-row" }, [
                              _c(
                                "div",
                                { staticClass: "chart-box pie" },
                                [
                                  _c("div", { staticClass: "chart-title" }, [
                                    _vm._v(_vm._s(_vm.$t("chartTitleSources"))),
                                  ]),
                                  _vm._v(" "),
                                  _vm.statistics.total
                                    .numberOfTabletFeedbacksWithoutSpam > 0 ||
                                  _vm.statistics.total
                                    .numberOfWebFeedbacksWithoutSpam > 0
                                    ? _c("company-statistics-pie-chart", {
                                        staticClass: "chart",
                                        attrs: {
                                          data: {
                                            distribution: {
                                              TABLET:
                                                _vm.statistics.total
                                                  .numberOfTabletFeedbacksWithoutSpam,
                                              WEB: _vm.statistics.total
                                                .numberOfWebFeedbacksWithoutSpam,
                                            },
                                          },
                                        },
                                      })
                                    : _c("div", { staticClass: "no-data" }, [
                                        _vm._v(_vm._s(_vm.$t("noData"))),
                                      ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "chart-box pie" },
                                [
                                  _c("div", { staticClass: "chart-title" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("chartTitleLanguages"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.hasLanguageDistributionData
                                    ? _c("company-statistics-pie-chart", {
                                        staticClass: "chart",
                                        attrs: {
                                          data: {
                                            distribution:
                                              _vm.statistics.total
                                                .languageDistribution,
                                          },
                                        },
                                      })
                                    : _c("div", { staticClass: "no-data" }, [
                                        _vm._v(_vm._s(_vm.$t("noData"))),
                                      ]),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.statistics.years, function (yearData, year) {
                  return _c(
                    "div",
                    { key: year, staticClass: "content" },
                    [
                      year === _vm.selectedView
                        ? [
                            _c(
                              "b-container",
                              {
                                staticClass: "text-content",
                                attrs: { fluid: "" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-content-inner-wrapper" },
                                  [
                                    _c(
                                      "company-statistics-row",
                                      {
                                        attrs: { title: _vm.$t("label.year") },
                                      },
                                      [_c("b", [_vm._v(_vm._s(year))])]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "company-statistics-row",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "label.numberOfFeedbacksWithSpam"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("groupedNumber")(
                                                yearData.numberOfFeedbacksWithSpam
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("company-statistics-row", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._f("groupedNumber")(
                                              yearData.numberOfSpamFeedbacks
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "padded-title",
                                          attrs: { slot: "title" },
                                          slot: "title",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "label.numberOfSpamFeedbacks"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "company-statistics-row",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "label.numberOfFeedbacksWithoutSpam"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("groupedNumber")(
                                                yearData.numberOfFeedbacksWithoutSpam
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("hr"),
                                    _vm._v(" "),
                                    _c(
                                      "company-statistics-row",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "label.numberOfCommentsWithoutSpam"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("groupedNumber")(
                                                _vm.statistics.total
                                                  .numberOfCommentsWithoutSpam
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "chart-content" },
                              [
                                _c("div", { staticClass: "chart-title" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("chartTitleYear", { year: year })
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("company-statistics-chart", {
                                  staticClass: "chart",
                                  attrs: {
                                    data: {
                                      year: year,
                                      oneYear: true,
                                      monthlyDistribution:
                                        yearData.monthlyDistribution,
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }