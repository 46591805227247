var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "filter-criteria",
    { attrs: { variant: "primary" } },
    [
      _c("font-awesome", {
        staticClass: "font-awesome",
        attrs: { icon: ["fas", "map-marker-alt"] },
      }),
      _vm._v(" "),
      _vm.locationIds.length === 0
        ? [_vm._v("\n    " + _vm._s(_vm.$t("allLocations")) + "\n  ")]
        : _vm.locationIds.length === 1
        ? [_vm._v("\n    " + _vm._s(_vm.$t("singleLocation")) + "\n  ")]
        : _vm.locationIds.length > 1
        ? [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("multipleLocations", {
                    locations: _vm.locationIds.length,
                  })
                ) +
                "\n  "
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }