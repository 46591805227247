var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title" }, [
      !_vm.isLoading
        ? _c("span", { staticClass: "text" }, [
            _vm._v(
              _vm._s(_vm.$t("title")) +
                " «" +
                _vm._s(_vm.survey.surveyName) +
                "»"
            ),
          ])
        : _c("span", { staticClass: "text-loading" }),
    ]),
    _vm._v(" "),
    _vm.isLoading
      ? _c("div", [_c("questions-loading")], 1)
      : _c(
          "div",
          { staticClass: "survey" },
          [
            _vm._l(_vm.survey.questions, function (q, index) {
              return [
                _c("question", {
                  key: q.questionType + q.questionId,
                  attrs: {
                    question: q,
                    "survey-id": _vm.parentConfig.surveyId,
                    "query-parameters": _vm.queryParameters,
                    counter: index + 1,
                    "is-narrow": true,
                  },
                }),
              ]
            }),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }