var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: { disabled: _vm.disabledQuestion } },
    [
      !_vm.isPrintView ? _c("div", { staticClass: "timeline-line" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.bottom.d200",
              modifiers: { hover: true, bottom: true, d200: true },
            },
          ],
          class: _vm.isPrintView ? "timeline-icon-print" : "timeline-icon",
          attrs: {
            title: _vm.$t("icon.long") + (_vm.index ? " " + _vm.index : ""),
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("icon.short") + (_vm.index ? _vm.index : "")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.top.d200",
              modifiers: { hover: true, top: true, d200: true },
            },
          ],
          staticClass: "question-content",
          attrs: {
            title: _vm.disabledQuestion
              ? _vm.$t("tooltip.noAnswerGiven")
              : null,
          },
        },
        [
          _vm.question.questionType === "RATING_QUESTION"
            ? _c("div", [
                _c("div", { staticClass: "question-header" }, [
                  _c("span", {
                    staticClass: "badge badge-pill badge-category",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.languageAwareString(
                          _vm.question.category.name
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasIndexCalculation
                    ? _c(
                        "div",
                        { staticClass: "question-rating" },
                        [
                          _vm.question.answer !== null
                            ? _c("font-awesome", {
                                staticClass: "font-awesome",
                                attrs: { icon: ["far", "smile"] },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.question.answer !== null
                            ? _c("span", { staticClass: "rating" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("groupedNumber")(
                                      _vm.question.index,
                                      true,
                                      1
                                    )
                                  )
                                ),
                              ])
                            : _c("span", [_vm._v("–")]),
                        ],
                        1
                      )
                    : _vm.question.answer !== null
                    ? _c("div", { staticClass: "question-rating" }, [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.scaleTooltipText) },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.question.answer !== null &&
                  _vm.smileyIconAndColor !== null &&
                  _vm.smileyIconAndColor.icon !== null
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.bottom.d200.html",
                              modifiers: {
                                hover: true,
                                bottom: true,
                                d200: true,
                                html: true,
                              },
                            },
                          ],
                          staticClass: "smiley",
                          class: _vm.smileyIconAndColor.color,
                          attrs: {
                            title: _vm.hasIndexCalculation
                              ? _vm.scaleTooltipText
                              : null,
                          },
                        },
                        [_c(_vm.smileyIconAndColor.icon, { tag: "component" })],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.question.answer !== null &&
                  _vm.smileyIconAndColor !== null &&
                  _vm.smileyIconAndColor.icon === null
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.bottom.d200.html",
                              modifiers: {
                                hover: true,
                                bottom: true,
                                d200: true,
                                html: true,
                              },
                            },
                          ],
                          staticClass: "slider-icon",
                          class: [
                            _vm.smileyIconAndColor.color,
                            {
                              first: _vm.question.answer === 1,
                              last: _vm.question.answer === _vm.question.scale,
                            },
                          ],
                          attrs: {
                            title: _vm.hasIndexCalculation
                              ? _vm.scaleTooltipText
                              : null,
                          },
                        },
                        [
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.question.answer)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "question-text",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$options.filters.languageAwareString(
                        _vm.question.text
                      )
                    ),
                  },
                }),
                _vm._v(" "),
                (_vm.question.ratingReasons !== null &&
                  _vm.question.ratingReasons.length > 0) ||
                _vm.question.customRatingReason !== null
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "answers" },
                        [
                          _vm._l(
                            _vm.question.ratingReasons,
                            function (ratingReason) {
                              return _c("div", {
                                key: ratingReason.id,
                                staticClass: "answer",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.languageAwareString(
                                      ratingReason.text
                                    )
                                  ),
                                },
                              })
                            }
                          ),
                          _vm._v(" "),
                          _vm.question.customRatingReason !== null &&
                          _vm.question.customRatingReason.answer !== null
                            ? _c(
                                "div",
                                { staticClass: "answer" },
                                [
                                  _c("font-awesome", {
                                    staticClass:
                                      "font-awesome custom-answer-icon",
                                    attrs: { icon: ["fas", "comment-edit"] },
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.question.customRatingReason.text
                                      ) +
                                      " |\n            " +
                                      _vm._s(
                                        _vm.question.customRatingReason.answer
                                      ) +
                                      "\n          "
                                  ),
                                ],
                                1
                              )
                            : _vm.question.customRatingReason !== null
                            ? _c(
                                "div",
                                { staticClass: "answer" },
                                [
                                  _c("font-awesome", {
                                    staticClass:
                                      "font-awesome custom-answer-icon",
                                    attrs: { icon: ["fas", "comment-edit"] },
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.question.customRatingReason.text
                                      ) +
                                      "\n          "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm.question.questionType === "CHOICE_QUESTION"
            ? _c("div", [
                _c("div", {
                  staticClass: "question-text",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$options.filters.languageAwareString(
                        _vm.question.text
                      )
                    ),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "question-header" }, [
                  !_vm.disabledQuestion
                    ? _c("div", { staticClass: "type" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("choiceType." + _vm.question.choiceType)
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                !_vm.disabledQuestion
                  ? _c("div", { staticClass: "answers" }, [
                      _vm.question.choiceAttributes !== null &&
                      _vm.question.choiceAttributes.length > 0
                        ? _c(
                            "span",
                            { staticClass: "choice-attribute-wrapper" },
                            _vm._l(
                              _vm.question.choiceAttributes,
                              function (choiceAttribute) {
                                return _c("div", {
                                  key: choiceAttribute.id,
                                  staticClass: "answer",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$options.filters.languageAwareString(
                                        choiceAttribute.text
                                      )
                                    ),
                                  },
                                })
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.question.customChoiceAttributes !== null &&
                      _vm.question.customChoiceAttributes.length > 0
                        ? _c(
                            "span",
                            { staticClass: "choice-attribute-wrapper" },
                            _vm._l(
                              _vm.question.customChoiceAttributes,
                              function (customChoiceAttribute) {
                                return _c(
                                  "div",
                                  {
                                    key: customChoiceAttribute.customChoiceAttributeId,
                                    staticClass: "answer",
                                  },
                                  [
                                    customChoiceAttribute.answer !== null
                                      ? _c(
                                          "span",
                                          [
                                            _c("font-awesome", {
                                              staticClass:
                                                "font-awesome custom-answer-icon",
                                              attrs: {
                                                icon: ["fas", "comment-edit"],
                                              },
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  customChoiceAttribute.text
                                                ) +
                                                " |\n              " +
                                                _vm._s(
                                                  customChoiceAttribute.answer
                                                ) +
                                                "\n            "
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "span",
                                          [
                                            _c("font-awesome", {
                                              staticClass:
                                                "font-awesome custom-answer-icon",
                                              attrs: {
                                                icon: ["fas", "comment-edit"],
                                              },
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  customChoiceAttribute.text
                                                ) +
                                                "\n            "
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm.question.questionType === "COMMENT_QUESTION"
            ? _c("div", [
                _c("div", { staticClass: "question-text" }, [
                  _vm.question.text !== null
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.languageAwareString(
                              _vm.question.text
                            )
                          ),
                        },
                      })
                    : _c("span", [_vm._v(_vm._s(_vm.$t("comment")))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "question-header" }, [
                  !_vm.disabledQuestion
                    ? _c("div", { staticClass: "type" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("questionType.COMMENT_QUESTION")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.question.answer !== null
                  ? _c("div", { staticClass: "answers" }, [
                      _c("div", { staticClass: "answer" }, [
                        _vm.question.answer !== null
                          ? _c("span", [_vm._v(_vm._s(_vm.question.answer))])
                          : _c("span", [
                              _c("i", [_vm._v(_vm._s(_vm.$t("noComment")))]),
                            ]),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm.question.questionType === "EMAIL_QUESTION"
            ? _c("div", [
                _c("div", { staticClass: "question-text" }, [
                  _vm.question.text !== null
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.languageAwareString(
                              _vm.question.text
                            )
                          ),
                        },
                      })
                    : _c("span", [_vm._v(_vm._s(_vm.$t("email")))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "question-header" }, [
                  !_vm.disabledQuestion
                    ? _c("div", { staticClass: "type" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("questionType.EMAIL_QUESTION")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.question.answer !== null
                  ? _c("div", { staticClass: "answers" }, [
                      _c("div", { staticClass: "answer" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: /^.+?@.+?$/.test(_vm.question.answer)
                                ? "mailto:" + _vm.question.answer
                                : null,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.question.answer) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm.question.questionType === "DATE_QUESTION"
            ? _c("div", [
                _c("div", { staticClass: "question-text" }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.languageAwareString(
                          _vm.question.text
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "question-header" }, [
                  !_vm.disabledQuestion
                    ? _c("div", { staticClass: "type" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("questionType.DATE_QUESTION")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.question.answer !== null
                  ? _c("div", { staticClass: "answers" }, [
                      _c("div", { staticClass: "answer" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.formatDate(_vm.question.answer)) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm.question.questionType === "NUMBER_QUESTION"
            ? _c("div", [
                _c("div", { staticClass: "question-text" }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.languageAwareString(
                          _vm.question.text
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "question-header" }, [
                  !_vm.disabledQuestion
                    ? _c("div", { staticClass: "type" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("questionType.NUMBER_QUESTION")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.question.answer !== null
                  ? _c("div", { staticClass: "answers" }, [
                      _c("div", { staticClass: "answer monospace" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.question.answer) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm.question.questionType === "TEXT_QUESTION"
            ? _c("div", [
                _c("div", { staticClass: "question-text" }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.languageAwareString(
                          _vm.question.text
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "question-header" }, [
                  !_vm.disabledQuestion
                    ? _c("div", { staticClass: "type" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("questionType.TEXT_QUESTION")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.question.answer !== null
                  ? _c("div", { staticClass: "answers" }, [
                      _c("div", { staticClass: "answer" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.question.answer) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm.question.questionType === "BARCODE_QUESTION"
            ? _c("div", [
                _c("div", { staticClass: "question-text" }, [
                  _vm.question.barcodeInputMethod === "CODE_MANUALLY_ENTERED" &&
                  _vm.question.fallbackText !== null
                    ? _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.languageAwareString(
                              _vm.question.fallbackText
                            )
                          ),
                        },
                      })
                    : _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.languageAwareString(
                              _vm.question.text
                            )
                          ),
                        },
                      }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "question-header" }, [
                  !_vm.disabledQuestion
                    ? _c("div", { staticClass: "type" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("questionType.BARCODE_QUESTION")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.question.answer !== null
                  ? _c("div", { staticClass: "answers" }, [
                      _c(
                        "div",
                        { staticClass: "answer" },
                        [
                          _vm.question.barcodeInputMethod === "CODE_RECOGNIZED"
                            ? _c("font-awesome", {
                                staticClass: "font-awesome barcode-answer-icon",
                                attrs: { icon: ["far", "barcode-read"] },
                              })
                            : _c("font-awesome", {
                                staticClass: "font-awesome barcode-answer-icon",
                                attrs: { icon: ["fas", "keyboard"] },
                              }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.question.answer) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }