var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "confirm-header",
      class: [_vm.type, { "has-icon": _vm.icon }],
    },
    [
      _vm.icon
        ? _c("font-awesome", {
            staticClass: "font-awesome",
            attrs: { icon: _vm.icon },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.title))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }