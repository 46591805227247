var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: { disabled: _vm.disabled } },
    [
      _c("div", {
        ref: "slider",
        staticClass: "slider",
        attrs: { disabled: _vm.disabled },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "coloring-overflow-wrapper" }, [
        _c("div", { staticClass: "coloring", style: _vm.backgroundStyle }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }