var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon" },
    _vm._l(_vm.characters, function (character) {
      return _c("div", { key: character, staticClass: "character-box" }, [
        _c("div", { staticClass: "character" }, [_vm._v(_vm._s(character))]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }