var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-select-item-group" },
    [
      _c(
        "b-dropdown-header",
        { staticClass: "form-select-header" },
        [_vm.title === null ? _vm._t("title") : [_vm._v(_vm._s(_vm.title))]],
        2
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }