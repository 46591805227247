var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      attrs: {
        "is-loading": true,
        "loading-min-height": _vm.heightInPixel + 62,
      },
    },
    [
      _c("change-over-time-chart-loading", {
        attrs: {
          slot: "content",
          "height-in-pixel": _vm.heightInPixel,
          small: _vm.small,
        },
        slot: "content",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }