import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
import Principal from '@/services/auth/Principal';
import Store from '@/store/Store';
import MainProperties from '@/services/auth/MainProperties';
import Dashboard from '@/pages/Dashboard';
import SurveyAnalysis from '@/pages/SurveyAnalysis';
import Timeline from '@/pages/Timeline';
import Locations from '@/pages/Locations';
import Matrix from '@/pages/Matrix';
import Comparison from '@/pages/Comparison';
import Goals from '@/pages/Goals';
import RawExport from '@/pages/RawExport';
import Report from '@/pages/Report';
import PrintReport from '@/pages/PrintReport';
import TabletMigration from '@/pages/TabletMigration';
import WebSourceManagement from '@/pages/WebSourceManagement';
import UserManagement from '@/pages/UserManagement';
import LocationAdministration from '@/pages/LocationAdministration';
import CompanySettings from '@/pages/CompanySettings';
import UserProfile from '@/pages/UserProfile';
import SurveyManagement from '@/pages/SurveyManagement';
import PrintSingleFeedback from '@/components/print/single-feedback/PrintSingleFeedback';
import GoalOverview from '@/components/goal/GoalOverview';
import GoalDetails from '@/components/goal/GoalDetails';
import GoalEditPanel from '@/components/goal/GoalEditPanel';
import WebSourceManagementOverview from '@/components/web-source-management/WebSourceManagementOverview';
import UserEditSubPage from '@/components/user-management/UserEditSubPage';
import UserManagementOverview from '@/components/user-management/UserManagementOverview';
import SurveyManagementOverview from '@/components/survey-management/SurveyManagementOverview';
import LocationAdministrationOverview from '@/components/location-administration/LocationAdministrationOverview';
import LocationAdministrationEditor from '@/components/location-administration/LocationAdministrationEditor';
import SurveyManagementEdit from '@/components/survey-management/SurveyManagementEdit';
import Login from '@/pages/authentication/Login';
import PasswordRecovery from '@/pages/authentication/PasswordRecovery';
import PasswordRecoveryVerify from '@/pages/authentication/PasswordRecoveryVerify';
import Maintenance from '@/pages/error/Maintenance';
import NotificationDispatcher from '@/pages/NotificationDispatcher';
import WebSourceEditSubPage from '@/components/web-source-management/WebSourceEditSubPage';
import FilterManagement from '@/pages/FilterManagement';
import FilterManagementOverview from '@/components/filter-management/FilterManagementOverview';
import FilterEditSubPage from '@/components/filter-management/FilterEditSubPage';
Vue.use(VueRouter);
var requireNoAuth = function requireNoAuth(_to, _from, next) {
  if (Principal.isAuthenticated()) {
    next({
      name: 'dashboard'
    });
  } else {
    next();
  }
};
var requireAuth = function requireAuth(to, _from, next) {
  if (Principal.isAuthenticated()) {
    next();
  } else {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath
      }
    });
  }
};
var requireAuthAndAdmin = function requireAuthAndAdmin(to, _from, next) {
  if (Principal.isAuthenticated()) {
    if (Store.state.general.user.authorities.includes('ROLE_ADMIN')) {
      next();
    } else {
      next({
        name: 'dashboard'
      });
    }
  } else {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath
      }
    });
  }
};
var requireAuthAndAdminAndPermissionToAccessSurveyManagement = function requireAuthAndAdminAndPermissionToAccessSurveyManagement(to, _from, next) {
  if (Principal.isAuthenticated()) {
    if (!Store.state.general.user.authorities.includes('ROLE_ADMIN') || Store.state.general.user.adminPermissionHasAccessToSurveyManagement !== true) {
      next({
        name: 'dashboard'
      });
    } else {
      next();
    }
  } else {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath
      }
    });
  }
};
var goalDetailsHelper = function goalDetailsHelper(route) {
  var goalId = null;
  if (route && route.params && route.params.goal) {
    goalId = window.parseInt(route.params.goal);
  }
  return {
    goalId: goalId
  };
};
var verifyHelper = function verifyHelper(route, isActivation) {
  var token = '';
  if (route && route.params && route.params.token) {
    token = route.params.token;
  }
  return {
    token: token,
    isActivation: isActivation
  };
};
var userEditHelper = function userEditHelper(route) {
  var userId = null;
  if (route && route.params && route.params.user) {
    userId = window.parseInt(route.params.user, 10);
  }
  return {
    isCreation: false,
    userId: userId
  };
};
var goalEditHelper = function goalEditHelper(route) {
  var goalId = null;
  if (route && route.params && route.params.goal) {
    goalId = window.parseInt(route.params.goal);
  }
  return {
    isCreation: false,
    goalId: goalId
  };
};
var locationEditHelper = function locationEditHelper(route) {
  var locationId = null;
  if (route && route.params && route.params.location) {
    locationId = window.parseInt(route.params.location, 10);
  }
  return {
    isCreation: false,
    locationId: locationId
  };
};
var webSourceEditHelper = function webSourceEditHelper(route) {
  var sourceId = null;
  if (route && route.params && route.params.source) {
    sourceId = window.parseInt(route.params.source, 10);
  }
  return {
    isCreation: false,
    sourceId: sourceId
  };
};
var filterEditHelper = function filterEditHelper(route) {
  var filterId = null;
  if (route && route.params && route.params.filter) {
    filterId = window.parseInt(route.params.filter, 10);
  }
  return {
    isCreation: false,
    filterId: filterId
  };
};
var Router = new VueRouter({
  mode: 'history',
  routes: [{
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: requireAuth
  }, {
    path: '/survey-analysis',
    name: 'survey-analysis',
    component: SurveyAnalysis,
    beforeEnter: requireAuth
  }, {
    path: '/feedback',
    name: 'feedback',
    component: Timeline,
    beforeEnter: requireAuth
  }, {
    path: '/locations',
    name: 'locations',
    component: Locations,
    beforeEnter: requireAuth
  }, {
    path: '/matrix',
    name: 'matrix',
    component: Matrix,
    beforeEnter: requireAuth
  }, {
    path: '/comparison',
    name: 'comparison',
    component: Comparison,
    beforeEnter: requireAuth
  }, {
    path: '/goals',
    component: Goals,
    beforeEnter: requireAuth,
    children: [{
      path: '/goals',
      name: 'goal-overview',
      component: GoalOverview,
      beforeEnter: requireAuth
    }, {
      path: '/goals/create',
      name: 'goal-create',
      component: GoalEditPanel,
      beforeEnter: requireAuth,
      props: {
        isCreation: true
      }
    }, {
      path: '/goals/:goal(\\d+)/edit',
      name: 'goal-edit',
      component: GoalEditPanel,
      beforeEnter: requireAuth,
      props: goalEditHelper
    }, {
      path: '/goals/:goal(\\d+)/details',
      name: 'goal-details',
      component: GoalDetails,
      beforeEnter: requireAuth,
      props: goalDetailsHelper
    }]
  }, {
    path: '/raw-export',
    name: 'raw-export',
    component: RawExport,
    beforeEnter: requireAuth
  }, {
    path: '/report',
    name: 'report',
    component: Report,
    beforeEnter: requireAuth
  }, {
    path: '/tablet-migration',
    name: 'tablet-migration',
    component: TabletMigration,
    beforeEnter: requireAuth
  }, {
    path: '/my-filters',
    name: 'filters',
    component: FilterManagement,
    beforeEnter: requireAuth,
    children: [{
      path: '/my-filters',
      name: 'filter-management',
      component: FilterManagementOverview,
      beforeEnter: requireAuth
    }, {
      path: '/my-filters/create',
      name: 'filter-create',
      component: FilterEditSubPage,
      beforeEnter: requireAuth,
      props: {
        isCreation: true
      }
    }, {
      path: '/my-filters/:filter(\\d+)/edit',
      name: 'filter-edit',
      component: FilterEditSubPage,
      beforeEnter: requireAuth,
      props: filterEditHelper
    }]
  }, {
    path: '/web-surveys',
    component: WebSourceManagement,
    beforeEnter: requireAuthAndAdmin,
    children: [{
      path: '/web-survey-link-management',
      name: 'web-source-management',
      component: WebSourceManagementOverview,
      beforeEnter: requireAuthAndAdmin
    }, {
      path: '/web-survey-link-management/create',
      name: 'web-source-create',
      component: WebSourceEditSubPage,
      beforeEnter: requireAuthAndAdmin,
      props: {
        isCreation: true
      }
    }, {
      path: '/web-survey-link-management/:source(\\d+)/edit',
      name: 'web-source-edit',
      component: WebSourceEditSubPage,
      beforeEnter: requireAuthAndAdmin,
      props: webSourceEditHelper
    }]
  }, {
    path: '/user-management',
    component: UserManagement,
    beforeEnter: requireAuthAndAdmin,
    children: [{
      path: '/user-management',
      name: 'user-management',
      component: UserManagementOverview,
      beforeEnter: requireAuthAndAdmin
    }, {
      path: '/user-management/create',
      name: 'user-create',
      component: UserEditSubPage,
      beforeEnter: requireAuthAndAdmin,
      props: {
        isCreation: true
      }
    }, {
      path: '/user-management/:user(\\d+)',
      name: 'user-edit',
      component: UserEditSubPage,
      beforeEnter: requireAuthAndAdmin,
      props: userEditHelper
    }]
  }, {
    path: '/survey-management',
    component: SurveyManagement,
    beforeEnter: requireAuthAndAdminAndPermissionToAccessSurveyManagement,
    children: [{
      path: '/survey-management',
      name: 'survey-management',
      component: SurveyManagementOverview,
      beforeEnter: requireAuthAndAdminAndPermissionToAccessSurveyManagement
    }, {
      path: '/survey-management/:surveyId(\\d+)',
      name: 'survey-management-edit',
      component: SurveyManagementEdit,
      beforeEnter: function beforeEnter(to, from, next) {
        var surveyId = null;
        if (to && to.params && to.params.surveyId) {
          surveyId = window.parseInt(to.params.surveyId, 10);
        }
        Store.commit('surveyManagement/setSelectedSurveyId', surveyId);
        return requireAuthAndAdminAndPermissionToAccessSurveyManagement(to, from, next);
      }
    }]
  }, {
    path: '/location-administration',
    component: LocationAdministration,
    beforeEnter: requireAuthAndAdmin,
    children: [{
      path: '/location-administration',
      name: 'location-administration',
      component: LocationAdministrationOverview,
      beforeEnter: requireAuthAndAdmin
    }, {
      path: '/location-administration/create',
      name: 'location-create',
      component: LocationAdministrationEditor,
      beforeEnter: requireAuthAndAdmin,
      props: {
        isCreation: true
      }
    }, {
      path: '/location-administration/:location(\\d+)',
      name: 'location-edit',
      component: LocationAdministrationEditor,
      beforeEnter: requireAuthAndAdmin,
      props: locationEditHelper
    }]
  }, {
    path: '/company-settings',
    name: 'company-settings',
    component: CompanySettings,
    beforeEnter: requireAuthAndAdmin
  }, {
    path: '/user-profile',
    name: 'user-profile',
    component: UserProfile,
    beforeEnter: requireAuth
  }, {
    path: '/auth/login',
    name: 'login',
    component: Login,
    beforeEnter: requireNoAuth
  }, {
    path: '/auth/password-recovery',
    name: 'password-recovery',
    component: PasswordRecovery,
    beforeEnter: requireNoAuth
  }, {
    path: '/auth/password-recovery/verify/:token',
    name: 'password-recovery-verify',
    component: PasswordRecoveryVerify,
    beforeEnter: requireNoAuth,
    props: function props(route) {
      return verifyHelper(route, false);
    }
  }, {
    path: '/auth/activation/verify/:token',
    name: 'activation-verify',
    component: PasswordRecoveryVerify,
    beforeEnter: requireNoAuth,
    props: function props(route) {
      return verifyHelper(route, true);
    }
  }, {
    path: '/print/report',
    name: 'print-report',
    component: PrintReport
  }, {
    path: '/print/feedback',
    name: 'print-feedback',
    component: PrintSingleFeedback
  }, {
    path: '/error/maintenance',
    name: 'maintenance',
    component: Maintenance
  }, {
    path: '/notification-dispatcher',
    name: 'notification-dispatcher',
    component: NotificationDispatcher,
    props: true
  }, {
    // TODO: replace '*' with '/' and add another route with '*' and redirect it to 404
    path: '*',
    redirect: '/dashboard'
  }]
});
Router.beforeEach(function (to, _from, next) {
  // prevent fetching user when back-end is not available (endless loop caused by Http interceptor)
  // and always redirect to maintenance (if 'to' is not yet maintenance, otherwise also an infinite loop)
  if (Store.state.general.underMaintenance) {
    if (to.name === 'maintenance') {
      next();
    } else {
      next({
        name: 'maintenance'
      });
    }
  } else {
    // This ensures an up-to-date user & mainProperties before each router change
    Promise.all([MainProperties.get(), Principal.identity()]).then(function () {
      next();
    });
  }
});
export default Router;