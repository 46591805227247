//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getColorNameByIndex } from '@/services/ColorService';
export default {
  name: 'PrintQuickStat',
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Number,
      required: true
    },
    showAdditionalCharacters: {
      type: Boolean,
      required: false,
      default: false
    },
    isPercentage: {
      type: Boolean,
      required: false,
      default: false
    },
    change: {
      type: Number,
      required: false,
      default: null
    },
    isChangePercentage: {
      type: Boolean,
      required: false,
      default: false
    },
    hasIndexColoration: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    getColorNameByIndex: getColorNameByIndex
  }
};