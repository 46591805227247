import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
export var stringIsEmpty = function stringIsEmpty(string) {
  return typeof string === 'undefined' || string === null || string === '' || string.trim() === '';
};
export var stringContainsInvalidCharacters = function stringContainsInvalidCharacters(string) {
  if (stringIsEmpty(string)) {
    return false;
  }
  // Make sure this is the same as in backend
  var allowedCharactersRegex = /^[a-zA-ZäÄàÀâÂéÉèÈêÊîÎöÖôÔüÜûÛßçÇ0-9 !¡"#$£€%&'()*+,\-./:;<=>?¿@[\]^_`{|}~\\]+$/;
  return !allowedCharactersRegex.test(string);
};
export var stringIsEmptyOrContainsInvalidCharacters = function stringIsEmptyOrContainsInvalidCharacters(string) {
  return stringIsEmpty(string) || stringContainsInvalidCharacters(string);
};