import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//

import QueryUtils from '@/services/utils/QueryUtils';
import QueryService from '@/services/QueryService';
import moment from 'moment';
export default {
  name: 'ClearFilter',
  data: function data() {
    return {
      isLoadingInternally: false,
      currentFilter: {},
      currentTimelineFilter: {},
      currentSurveyAnalysisFilter: {}
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    isTimeline: {
      type: Boolean,
      required: false
    },
    isSurveyAnalysis: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    dynamicClass: function dynamicClass() {
      var defaultDateRange = QueryUtils.getDateRangeFilter();
      if (Object.keys(this.currentFilter).length === 0) {
        return {
          shown: false
        };
      }
      if (this.isTimeline && QueryUtils.timelineFilterIsNotDefault(this.currentTimelineFilter)) {
        return {
          shown: true
        };
      }
      if (this.isSurveyAnalysis && QueryUtils.surveyAnalysisFilterIsNotDefault(this.currentSurveyAnalysisFilter)) {
        return {
          shown: true
        };
      }
      if (moment(this.currentFilter.dateRange[0]).isSame(moment(defaultDateRange[0])) && moment(this.currentFilter.dateRange[1]).isSame(moment(defaultDateRange[1])) && this.currentFilter.location.locations.length === 0 && this.currentFilter.location.locationGroups.length === 0 && this.currentFilter.location.queryLocations.length === 0) {
        return {
          shown: false
        };
      }
      return {
        shown: true
      };
    }
  },
  watch: {
    $route: function $route() {
      this.refreshCurrentFilter();
    }
  },
  mounted: function mounted() {
    this.refreshCurrentFilter();
  },
  methods: {
    refreshCurrentFilter: function refreshCurrentFilter() {
      var filter = QueryService.get('filter') || {};
      this.currentFilter = {
        dateRange: QueryUtils.getDateRangeFilter(filter.dateRange),
        location: QueryUtils.getLocationFilter(filter.location)
      };
      if (this.isTimeline) {
        this.currentTimelineFilter = QueryUtils.getTimelineFilter(QueryService.get('timelineFilter') || {});
      }
      if (this.isSurveyAnalysis) {
        this.currentSurveyAnalysisFilter = QueryUtils.getSurveyAnalysisFilter(QueryService.get('surveyAnalysisFilter') || {});
      }
    },
    clearFilter: function clearFilter() {
      var _this = this;
      this.isLoadingInternally = true;
      var resetLoadingInternally = function resetLoadingInternally() {
        _this.$emit('reset-filter');
        _this.isLoadingInternally = false;
      };
      QueryService.clearAll().then(resetLoadingInternally, resetLoadingInternally);
    }
  }
};