//
//
//
//
//
//
//

import FilterCriteria from '@/components/comparison/filter-criteria/FilterCriteria';
export default {
  name: 'FilterCriteriaDate',
  components: {
    FilterCriteria: FilterCriteria
  },
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  }
};