import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import LocationGroupSelect from '@/components/shared/LocationGroupSelect';
import Alert from '@/components/shared/Alert';
import ValidationErrorList from '@/components/shared/ValidationErrorList';
import ResetPasswordPanel from '@/components/user-management/ResetPasswordPanel';
import { UserManagement } from '@/services/ResourceService';
import ComparisonService from '@/services/ComparisonService';
import ScrollAnimatorService from '@/services/ScrollAnimatorService';
import ValidationHelperService from '@/services/ValidationHelperService';
import moment from 'moment';
import _ from 'lodash';
export default {
  name: 'UserEdit',
  components: {
    Panel: Panel,
    LocationGroupSelect: LocationGroupSelect,
    Alert: Alert,
    ValidationErrorList: ValidationErrorList,
    ResetPasswordPanel: ResetPasswordPanel
  },
  props: {
    isCreation: {
      type: Boolean,
      required: true
    },
    userId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function data() {
    return {
      isLoading: true,
      isSubmitting: false,
      isGeneratingPassword: false,
      successfullySaved: false,
      serverErrorOccurred: false,
      validationErrorOccurred: false,
      validationErrors: null,
      passwordVisible: false,
      rawData: null,
      formData: null,
      languages: [{
        value: 'EN',
        text: this.$t('languages.EN')
      }, {
        value: 'DE',
        text: this.$t('languages.DE')
      }, {
        value: 'FR',
        text: this.$t('languages.FR')
      }, {
        value: 'IT',
        text: this.$t('languages.IT')
      }],
      roles: [{
        value: 'ROLE_MANAGER',
        text: this.$t('roles.ROLE_MANAGER')
      }, {
        value: 'ROLE_ADMIN',
        text: this.$t('roles.ROLE_ADMIN')
      }]
    };
  },
  created: function created() {
    if (!this.isCreation) {
      this.loadData();
    } else {
      this.initNewUserData();
    }
  },
  computed: {
    formattedCreatedDate: function formattedCreatedDate() {
      return moment(this.rawData.createdDate).format('YYYY-MM-DD HH:mm');
    },
    showLogin: function showLogin() {
      if (this.isCreation) {
        return false;
      }
      return this.rawData.user.email !== this.rawData.user.login;
    },
    isSelf: function isSelf() {
      if (this.isCreation || this.isLoading || this.rawData === null) {
        return false;
      }
      return this.rawData.self;
    },
    userIsActivated: function userIsActivated() {
      if (!this.isCreation && this.rawData !== null && this.rawData.activated === false) {
        return false;
      }
      return true;
    },
    inputsDisabled: function inputsDisabled() {
      return this.isSubmitting || this.isCreation && this.successfullySaved;
    },
    originalData: function originalData() {
      if (this.isCreation || this.rawData === null) {
        return null;
      }
      return {
        firstName: this.rawData.user.firstName,
        lastName: this.rawData.user.lastName,
        email: this.rawData.user.email,
        login: this.rawData.user.login,
        role: this.getHighestRole(),
        language: this.rawData.user.language,
        locations: this.rawData.user.locationIds,
        activated: this.rawData.activated
      };
    },
    isOriginalData: function isOriginalData() {
      return _.isEqual(this.formData, this.originalData);
    }
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      UserManagement.getUser(this.$store.state.general.companyId, this.userId).then(function (response) {
        _this.rawData = response.data;
        _this.formData = _.cloneDeep(_this.originalData);
        _this.isSubmitting = false;
        _this.isLoading = false;
      }, function () {
        _this.serverErrorOccurred = true;
        _this.isSubmitting = false;
        _this.isLoading = false;
      });
    },
    initNewUserData: function initNewUserData() {
      this.formData = {
        firstName: null,
        lastName: null,
        email: null,
        login: null,
        role: 'ROLE_MANAGER',
        language: 'DE',
        activated: true,
        locations: [],
        sendActivationEmail: true,
        password: null
      };
      this.isLoading = false;
    },
    getHighestRole: function getHighestRole() {
      if (this.isCreation) {
        if (this.formData !== null) {
          return this.formData.role;
        }
        return 'ROLE_MANAGER';
      }
      var roles = this.rawData.user.authorities;
      roles = roles.sort(ComparisonService.compareRoles);
      return roles[0];
    },
    saveChanges: function saveChanges() {
      this.isSubmitting = true;
      this.serverErrorOccurred = false;
      this.successfullySaved = false;
      this.successfullyResetPassword = false;
      var user = this.getUserFromFormData();
      UserManagement.putUser(this.$store.state.general.companyId, this.userId, user).then(this.handleSuccess, this.handleError);
    },
    createUser: function createUser() {
      this.isSubmitting = true;
      this.serverErrorOccurred = false;
      this.successfullySaved = false;
      var user = this.getUserFromFormData();
      var rawData = {
        user: user,
        sendActivationEmail: this.formData.sendActivationEmail,
        password: this.formData.sendActivationEmail ? null : this.formData.password
      };
      UserManagement.createUser(this.$store.state.general.companyId, rawData).then(this.handleSuccess, this.handleError);
    },
    handleSuccess: function handleSuccess() {
      var _this2 = this;
      this.validationErrorOccurred = false;
      this.validationErrors = null;
      this.successfullySaved = true;
      ScrollAnimatorService.scrollToTopOfElement(this.$el);
      if (this.isCreation) {
        this.isSubmitting = false;
        setTimeout(function () {
          _this2.$router.push({
            name: 'user-management'
          });
        }, 1000);
      } else {
        this.loadData();
      }
    },
    handleError: function handleError(error) {
      var data = error.response.data;
      var errorCode = data.status.code;
      var payload = data.payload;
      if (errorCode === 400 && payload !== null && payload.length > 0) {
        this.validationErrorOccurred = true;
        this.validationErrors = ValidationHelperService.parseValidationErrorsWithTranslations(payload, this);
      } else {
        this.serverErrorOccurred = true;
      }
      this.isSubmitting = false;
      ScrollAnimatorService.scrollToTopOfElement(this.$el);
    },
    getFieldState: function getFieldState(fieldName) {
      return ValidationHelperService.getFieldState(fieldName, this.validationErrors);
    },
    getFieldErrors: function getFieldErrors(fieldName) {
      return ValidationHelperService.getFieldErrors(fieldName, this.validationErrors);
    },
    generatePassword: function generatePassword() {
      var _this3 = this;
      this.isGeneratingPassword = true;
      UserManagement.generatePassword(this.$store.state.general.companyId).then(function (response) {
        _this3.formData.password = response.data;
        _this3.passwordVisible = true;
        _this3.isGeneratingPassword = false;
      }, function () {
        _this3.serverErrorOccurred = true;
        _this3.isGeneratingPassword = false;
      });
    },
    getUserFromFormData: function getUserFromFormData() {
      var authorities = ['ROLE_MANAGER'];
      if (this.formData.role === 'ROLE_ADMIN') {
        authorities.push('ROLE_ADMIN');
      }
      return {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        email: this.formData.email,
        login: this.showLogin ? this.formData.login : this.formData.email,
        language: this.formData.language,
        activated: this.formData.activated,
        authorities: authorities,
        locationIds: this.formData.locations
      };
    },
    togglePasswordVisibility: function togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    locationsChanged: function locationsChanged(newFilter) {
      this.formData.locations = newFilter.queryLocations;
    },
    roleChanged: function roleChanged(role) {
      if (role === 'ROLE_ADMIN') {
        this.formData.locations = [];
      }
    }
  }
};