//
//
//
//
//
//
//

export default {
  name: 'PrintInfoBox',
  props: {
    text: {
      type: String,
      required: true
    }
  }
};