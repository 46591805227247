var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "survey-management-panel",
    {
      staticClass: "survey-management-success",
      attrs: { padded: true },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _vm.editPageState.data.success
                ? [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("title.success")) + "\n    "
                    ),
                  ]
                : _vm.editPageState.data.error
                ? [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("title.error")) + "\n    "
                    ),
                  ]
                : [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("title.loading")) + "\n    "
                    ),
                  ],
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("modal-icon-button", {
        attrs: {
          index: 0,
          icon: ["far", "times"],
          "color-type": "danger",
          message: _vm.$t("button.close"),
          disabled:
            !_vm.editPageState.data.success && !_vm.editPageState.data.error,
          "horizontal-in-box": "",
        },
        on: { click: _vm.close },
      }),
      _vm._v(" "),
      !_vm.editPageState.data.success && !_vm.editPageState.data.error
        ? _c("div", { staticClass: "message-container loading" }, [
            _c(
              "div",
              { staticClass: "message" },
              [
                _c("font-awesome", {
                  staticClass: "font-awesome",
                  attrs: { icon: ["fad", "spinner-third"], spin: "" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("message.loading"))),
                ]),
              ],
              1
            ),
          ])
        : _vm.editPageState.data.success && !_vm.editPageState.data.error
        ? _c("div", { staticClass: "message-container success" }, [
            _c(
              "div",
              { staticClass: "message" },
              [
                _c("font-awesome", {
                  staticClass: "font-awesome",
                  attrs: { icon: ["fas", "check"] },
                }),
                _vm._v(" "),
                _vm.editPageState.name === "SAVING_ADDING"
                  ? _c("span", { staticClass: "text" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("message.successAdding")) +
                          "\n      "
                      ),
                    ])
                  : _vm.editPageState.name === "SAVING_EDIT" &&
                    _vm.editPageState.data.isNew
                  ? _c("span", { staticClass: "text" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("message.successCreated")) +
                          "\n      "
                      ),
                    ])
                  : _vm.editPageState.name === "SAVING_EDIT" &&
                    !_vm.editPageState.data.isNew
                  ? _c("span", { staticClass: "text" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("message.successEdited")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm.editPageState.data.error
        ? _c("div", { staticClass: "message-container error" }, [
            _c(
              "div",
              { staticClass: "message" },
              [
                _c("font-awesome", {
                  staticClass: "font-awesome",
                  attrs: { icon: ["fas", "times"] },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("message.error"))),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form-step-indicator",
        {
          staticClass: "form-step",
          attrs: { "step-index": _vm.currentStepIndex, steps: _vm.steps },
        },
        [
          _c(
            "b-button",
            {
              attrs: {
                slot: "previous",
                variant: "outline-secondary",
                disabled: true,
              },
              slot: "previous",
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("button.previous")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                slot: "next",
                variant: "primary",
                disabled:
                  !_vm.editPageState.data.success &&
                  !_vm.editPageState.data.error,
              },
              on: { click: _vm.close },
              slot: "next",
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("button.close")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }