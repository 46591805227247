var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    { staticClass: "print-info" },
    [
      _c("font-awesome", {
        staticClass: "font-awesome",
        attrs: { icon: ["far", "info-circle"] },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "vert-mid" }, [_vm._v(_vm._s(_vm.text))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }