import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect/src/Multiselect';
import { Location } from '@/services/ResourceService';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
export default {
  name: 'LocationsGroupSelect',
  components: {
    Multiselect: Multiselect
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    preSelectedIds: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    showArchived: {
      type: Boolean,
      required: false,
      default: null
    },
    showLocations: {
      type: Boolean,
      required: false,
      default: true
    },
    showLocationGroups: {
      type: Boolean,
      required: false,
      default: true
    },
    limit: {
      type: Number,
      required: false,
      default: 5
    },
    isInForm: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    state: {
      type: Boolean,
      required: false,
      default: true
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    }
  },
  data: function data() {
    return {
      selectedLocations: [],
      allLocations: [],
      filteredLocations: [],
      inputChanged: false,
      isInternallyLoading: true,
      isInternallyDisabled: false
    };
  },
  created: function created() {
    var _this = this;
    var promises = [];
    var responseHandlers = [];
    if (this.showArchived === null && this.$store.state.general.companyProperties === null) {
      promises.push(CompanyPropertiesService.update());
      responseHandlers.push(function () {});
    }
    promises.push(Location.getAllowedLocations(this.$store.state.general.companyId));
    responseHandlers.push(function (data) {
      _this.$store.commit('general/setAllLocations', data);
    });
    Promise.all(promises).then(function (responses) {
      responses.forEach(function (response, index) {
        responseHandlers[index](response.data);
      });
      _this.initialize();
    }, this.disable);
  },
  watch: {
    preSelectedIds: {
      handler: function handler() {
        this.addPreSelectedIdsToSelected();
      },
      deep: true
    },
    showLocations: function showLocations() {
      this.allLocations = this.generateLocationsGroupsList(this.$store.state.general.allLocations);
      this.filteredLocations = this.allLocations;
      this.selectedLocations = [];
    },
    showLocationGroups: function showLocationGroups() {
      this.allLocations = this.generateLocationsGroupsList(this.$store.state.general.allLocations);
      this.filteredLocations = this.allLocations;
      this.selectedLocations = [];
    }
  },
  computed: {
    shouldShowArchivedLocations: function shouldShowArchivedLocations() {
      if (this.showArchived === null) {
        return this.$store.state.general.companyProperties.showArchivedLocationAnalytics;
      }
      return this.showArchived;
    }
  },
  methods: {
    initialize: function initialize() {
      this.allLocations = this.generateLocationsGroupsList(this.$store.state.general.allLocations);
      this.filteredLocations = this.allLocations;
      this.addPreSelectedIdsToSelected();
      this.isInternallyLoading = false;
    },
    disable: function disable() {
      this.isInternallyLoading = false;
      this.isInternallyDisabled = true;
    },
    limitItems: function limitItems(count) {
      return this.$t('andXMore', {
        count: count
      });
    },
    onInputChanged: function onInputChanged() {
      this.inputChanged = true;
    },
    applyLocationGroupFilter: function applyLocationGroupFilter() {
      if (!this.inputChanged) {
        return;
      }
      this.inputChanged = false;
      var newFilter = {
        locations: [],
        locationGroups: [],
        queryLocations: []
      };
      this.selectedLocations.forEach(function (item) {
        if (item.type === 'location') {
          newFilter.locations.push(item.id);
          if (newFilter.queryLocations.indexOf(item.id) === -1) {
            newFilter.queryLocations.push(item.id);
          }
        } else {
          newFilter.locationGroups.push(item.id);
          item.locations.forEach(function (location) {
            if (newFilter.queryLocations.indexOf(location.id) === -1) {
              newFilter.queryLocations.push(location.id);
            }
          });
        }
      });
      this.$emit('change-locations', newFilter);
    },
    filterLocations: function filterLocations(query) {
      this.filteredLocations = [];
      var matches = [];
      this.allLocations.forEach(function (listItem) {
        // If item matches a location
        if (listItem.type === 'location') {
          if (listItem.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
            matches.push(listItem);
          }
        }
        // If item matches a locationGroup or matches a location inside a locationGroup
        if (listItem.type === 'locationGroup') {
          if (listItem.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
            matches.push(listItem);
          } else {
            listItem.locations.forEach(function (location) {
              if (location.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 && !matches.includes(listItem)) {
                matches.push(listItem);
              }
            });
          }
        }
      });
      this.filteredLocations = matches;
    },
    generateLocationsGroupsList: function generateLocationsGroupsList(locations) {
      var _this2 = this;
      var locationList = [];
      var locationGroupList = [];
      locations.forEach(function (location) {
        // Only show archived locations if they are preselected
        // or if they should be shown
        if (_this2.shouldShowArchivedLocations || !location.archived || _this2.preSelectedIds && _this2.preSelectedIds.locations && _this2.preSelectedIds.locations.includes(location.id)) {
          locationList.push({
            id: location.id,
            name: location.name,
            groupName: location.locationGroup.name,
            archived: location.archived,
            type: 'location',
            key: "location_lg".concat(location.locationGroup.id, "_l").concat(location.id)
          });
          if (_this2.showLocationGroups === true) {
            locationGroupList = _this2.addLocationToLocationGroups(location, locationGroupList);
          }
        }
      });
      if (this.showLocations === true) {
        locationList.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      }
      if (this.showLocationGroups === true) {
        locationGroupList.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        locationGroupList.forEach(function (locationGroup) {
          locationGroup.locations.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
        });
      }
      if (this.showLocations === true && this.showLocationGroups === false) {
        return locationList;
      } else if (this.showLocations === false && this.showLocationGroups === true) {
        return locationGroupList;
      } else if (this.showLocations === true && this.showLocationGroups === true) {
        return locationList.concat(locationGroupList);
      } else {
        return [];
      }
    },
    addLocationToLocationGroups: function addLocationToLocationGroups(location, locationGroups) {
      if (location.locationGroup === null) {
        window.console.warn('Mind. 1 Standort hat keine Standortgruppe.');
        return locationGroups;
      }
      var inGroup = false;
      var newLocation = {
        id: location.id,
        name: location.name,
        archived: location.archived
      };
      locationGroups.forEach(function (group) {
        // If group already exists, simply push location into group
        if (group.id === location.locationGroup.id) {
          inGroup = true;
          group.locations.push(newLocation);
        }
      });
      if (inGroup === false) {
        locationGroups.push({
          id: location.locationGroup.id,
          name: location.locationGroup.name,
          type: 'locationGroup',
          key: "locationGroup_lg".concat(location.locationGroup.id),
          locations: [newLocation]
        });
      }
      return locationGroups;
    },
    addPreSelectedIdsToSelected: function addPreSelectedIdsToSelected() {
      this.selectedLocations = [];
      if (this.preSelectedIds && this.preSelectedIds.locations && this.preSelectedIds.locations.length > 0) {
        this.addLocationsById(this.preSelectedIds.locations);
      }
      if (this.preSelectedIds && this.preSelectedIds.locationGroups && this.preSelectedIds.locationGroups.length > 0) {
        this.addLocationGroupsById(this.preSelectedIds.locationGroups);
      }
    },
    addLocationsById: function addLocationsById(locationIds) {
      var _this3 = this;
      locationIds.forEach(function (id) {
        _this3.allLocations.forEach(function (location) {
          if (parseInt(id, 10) === location.id && location.type === 'location') {
            _this3.selectedLocations.push(location);
          }
        });
      });
    },
    addLocationGroupsById: function addLocationGroupsById(locationGroupIds) {
      var _this4 = this;
      locationGroupIds.forEach(function (id) {
        _this4.allLocations.forEach(function (locationGroup) {
          if (parseInt(id, 10) === locationGroup.id && locationGroup.type === 'locationGroup') {
            _this4.selectedLocations.push(locationGroup);
          }
        });
      });
    }
  }
};