var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      staticClass: "change-over-time",
      class: { small: _vm.small },
      attrs: { "panel-title": _vm.title, "is-loading": false },
    },
    [
      _c("template", { slot: "filter" }, [
        _c(
          "div",
          { staticClass: "controls float-right" },
          [
            _c("div", { staticClass: "relevance-filter" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.bottom.d200",
                      modifiers: { hover: true, bottom: true, d200: true },
                    },
                  ],
                  staticClass: "filter-icon",
                  attrs: { title: _vm.$t("tooltip.relevanceFilter") },
                },
                [
                  _c("font-awesome", {
                    attrs: { icon: ["far", "info-circle"] },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("relevanceFilter"))),
                  ]),
                  _vm._v(" "),
                  _c("b-form-checkbox", {
                    staticClass: "checkbox",
                    model: {
                      value: _vm.hasRelevanceFilter,
                      callback: function ($$v) {
                        _vm.hasRelevanceFilter = $$v
                      },
                      expression: "hasRelevanceFilter",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "b-button-group",
              { attrs: { size: "sm" } },
              _vm._l(["HOURLY", "DAILY", "WEEKLY"], function (interval) {
                return _c(
                  "b-button",
                  {
                    key: interval,
                    class: { active: interval === _vm.selectedInterval },
                    attrs: {
                      disabled: !_vm.availableIntervals.includes(interval),
                      variant: "outline-primary",
                    },
                    on: {
                      click: function ($event) {
                        _vm.selectedInterval = interval
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("intervals." + interval)) +
                        "\n        "
                    ),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.selectedInterval !== null
            ? _c("change-over-time-chart", {
                attrs: {
                  data: _vm.data[_vm.selectedInterval].data,
                  interval: _vm.selectedInterval,
                  "use-index-calculation":
                    _vm.$store.getters["general/hasIndexCalculation"],
                  "has-relevance-filter": _vm.hasRelevanceFilter,
                  "height-in-pixel": _vm.heightInPixel,
                  small: _vm.small,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }