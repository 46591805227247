//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataUtils from '@/services/utils/DataUtils';
export default {
  name: 'FormImageUploader',
  props: {
    accept: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    state: {
      type: Boolean,
      required: false,
      default: null
    },
    value: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: function data() {
    return {
      rawFile: null,
      encodedFile: this.value
    };
  },
  watch: {
    rawFile: {
      deep: true,
      handler: function handler() {
        var _this = this;
        if (this.rawFile !== null) {
          DataUtils.encodeFileAsDataURL(this.rawFile).then(function (result) {
            _this.encodedFile = {
              binaryFileContentType: _this.rawFile.type,
              binaryFile: result.substr(result.indexOf('base64,') + 'base64,'.length)
            };
            _this.$emit('input', _this.encodedFile);
          }).catch(function (error) {
            window.console.error(error);
            _this.clearSelectedFile();
          });
        }
      }
    }
  },
  methods: {
    clearSelectedFile: function clearSelectedFile() {
      this.$refs['file-input'].reset();
      this.rawFile = null;
      this.encodedFile = null;
      this.$emit('input', this.encodedFile);
    },
    openFileInput: function openFileInput() {
      this.$refs['file-input'].$el.click();
    },
    formatByteSizeOfFile: function formatByteSizeOfFile(file) {
      return DataUtils.formatNumberOfBytes(DataUtils.getByteSizeOfBase64StringAsInt(file));
    }
  }
};