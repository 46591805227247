//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalIconButton from '@/components/shared/ModalIconButton';
import Alert from '@/components/shared/Alert';
import ConfirmService from '@/services/ConfirmService';
import FormatUtils from '@/services/utils/FormatUtils';
import { TabletMigration } from '@/services/ResourceService';
export default {
  name: 'StopMeasuringModal',
  data: function data() {
    return {
      isLoading: false,
      adapted: false,
      success: false,
      errorOccurred: false
    };
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    Alert: Alert,
    ModalIconButton: ModalIconButton
  },
  methods: {
    closeModal: function closeModal() {
      this.$vuedals.close({
        adapted: this.adapted
      });
    },
    requestStopMeasurement: function requestStopMeasurement() {
      var _this = this;
      ConfirmService.createModal({
        name: 'confim-stop-measuring',
        type: 'danger',
        title: this.$t('confirmTitle'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('confirmText', {
          number: FormatUtils.escapeHTML(this.data.number),
          location: FormatUtils.escapeHTML(this.data.locationName)
        }),
        textIsHtml: true,
        okayButton: this.$t('yes'),
        dismissButton: this.$t('no'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this.stopMeasurement();
          } else {
            // same tick would lead to closing confirm modal recursively
            _this.$nextTick(function () {
              _this.closeModal();
            });
          }
        }
      });
    },
    stopMeasurement: function stopMeasurement() {
      var _this2 = this;
      this.isLoading = true;
      TabletMigration.stopMeasurement(this.$store.state.general.companyId, this.data.tabletSourceId).then(function () {
        _this2.isLoading = false;
        _this2.adapted = true;
        _this2.success = true;
      }, function () {
        _this2.isLoading = false;
        _this2.errorOccurred = true;
      });
    },
    escapeHTML: FormatUtils.escapeHTML
  }
};