var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internalEditQuestion !== null &&
    _vm.internalCurrentLanguage !== null
    ? _c(
        "div",
        { staticClass: "survey-management-edit-placeholder" },
        [
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend",
              attrs: { label: _vm.$t("label.placeholder") },
            },
            [
              _c("survey-management-las-wrapper", {
                attrs: {
                  optional: _vm.optional,
                  las: _vm.internalEditQuestion.placeholder,
                  "enabled-languages":
                    _vm.$store.state.surveyManagement.fullSurvey.data
                      .enabledLanguages,
                  "current-language": _vm.internalCurrentLanguage,
                  invalid:
                    _vm.editPageState.data.showValidation &&
                    !_vm.validation.placeholder.valid,
                },
                on: {
                  change: function ($event) {
                    _vm.internalEditQuestion.placeholder = $event
                  },
                  "change-language": function ($event) {
                    _vm.internalCurrentLanguage = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }