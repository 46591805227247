//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CompanySettings } from '@/services/ResourceService';
import Panel from '@/components/shared/Panel';
import Alert from '@/components/shared/Alert';
import IndexThresholdSlider from '@/components/company-settings/IndexThresholdSlider';
import { DisplayUsernamesConfigurations } from '@/constants/displayUsernamesConfigurations';
export default {
  name: 'IndexThresholdSettings',
  components: {
    Panel: Panel,
    Alert: Alert,
    IndexThresholdSlider: IndexThresholdSlider
  },
  data: function data() {
    return {
      DisplayUsernamesConfigurations: DisplayUsernamesConfigurations,
      isLoading: false,
      successfullySaved: false,
      loadingErrorOccurred: false,
      savingErrorOccurred: false,
      form: null,
      displayUsernameOptions: [{
        value: DisplayUsernamesConfigurations.FIRSTNAME_LASTNAME_SAME_COLUMN,
        text: this.$t('label.firstNameLastNameSameColumn')
      }, {
        value: DisplayUsernamesConfigurations.LASTNAME_FIRSTNAME_SAME_COLUMN,
        text: this.$t('label.lastNameFirstNameSameColumn')
      }, {
        value: DisplayUsernamesConfigurations.FIRSTNAME_LASTNAME_TWO_COLUMNS,
        text: this.$t('label.firstNameLastNameTwoColumns')
      }, {
        value: DisplayUsernamesConfigurations.LASTNAME_FIRSTNAME_TWO_COLUMNS,
        text: this.$t('label.lastNameFirstNameTwoColumns')
      }]
    };
  },
  computed: {
    hasNewIndexCalculation: {
      set: function set(value) {
        this.form.calculationType = value ? 'INDEX_CALCULATION' : 'SATISFACTION_CALCULATION';
      },
      get: function get() {
        return this.form.calculationType === 'INDEX_CALCULATION';
      }
    }
  },
  methods: {
    fetch: function fetch() {
      var _this = this;
      this.isLoading = true;
      this.loadingErrorOccurred = false;
      CompanySettings.fetchSettings(this.$store.state.general.companyId).then(function (response) {
        _this.isLoading = false;
        _this.form = response.data;
      }, function () {
        _this.isLoading = false;
        _this.loadingErrorOccurred = true;
        _this.clearFormToInit();
      });
    },
    submit: function submit() {
      var _this2 = this;
      var data = function () {
        if (_this2.hasNewIndexCalculation) {
          return _this2.form;
        } else {
          return {
            calculationType: _this2.form.calculationType,
            showArchivedLocationAnalytics: _this2.form.showArchivedLocationAnalytics,
            displayUserNamesConfiguration: _this2.form.displayUserNamesConfiguration,
            spamRequiresReason: _this2.form.spamRequiresReason
          };
        }
      }();
      this.isLoading = true;
      this.savingErrorOccurred = false;
      this.successfullySaved = false;
      CompanySettings.updateSettings(this.$store.state.general.companyId, data).then(function () {
        _this2.successfullySaved = true;
        _this2.isLoading = false;
        _this2.resetLocations();
      }, function () {
        _this2.isLoading = false;
        _this2.savingErrorOccurred = true;
        _this2.resetLocations();
      });
    },
    clearFormToInit: function clearFormToInit() {
      this.form = {
        calculationType: 'SATISFACTION_CALCULATION',
        indexThresholdLow: null,
        indexThresholdLowerMedium: null,
        indexThresholdUpperMedium: null,
        indexThresholdHigh: null
      };
    },
    resetLocations: function resetLocations() {
      this.$store.commit('general/setAllLocations', []);
    }
  },
  created: function created() {
    this.clearFormToInit();
    this.fetch();
  }
};