import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import smileyAngry from '@/assets/smileys/angry.vue';
import smileyUnhappy from '@/assets/smileys/unhappy.vue';
import smileyOk from '@/assets/smileys/ok.vue';
import smileyHappy from '@/assets/smileys/happy.vue';
import smileyVeryHappy from '@/assets/smileys/veryhappy.vue';

// Change the colors also in opnn-survey and the PDF report (opnn-backend)
// ATTENTION: green-light === yellowgreen
var completeStructure = {
  3: [{
    rating: 1,
    icon: smileyAngry,
    color: 'red'
  }, {
    rating: 2,
    icon: smileyOk,
    color: 'yellow'
  }, {
    rating: 3,
    icon: smileyVeryHappy,
    color: 'green'
  }],
  4: [{
    rating: 1,
    icon: smileyAngry,
    color: 'red'
  }, {
    rating: 2,
    icon: smileyUnhappy,
    color: 'orange'
  }, {
    rating: 3,
    icon: smileyHappy,
    color: 'yellow'
  }, {
    rating: 4,
    icon: smileyVeryHappy,
    color: 'green'
  }],
  5: [{
    rating: 1,
    icon: smileyAngry,
    color: 'red'
  }, {
    rating: 2,
    icon: smileyUnhappy,
    color: 'orange'
  }, {
    rating: 3,
    icon: smileyOk,
    color: 'yellow'
  }, {
    rating: 4,
    icon: smileyHappy,
    color: 'green-light'
  }, {
    rating: 5,
    icon: smileyVeryHappy,
    color: 'green'
  }],
  6: [{
    rating: 1,
    icon: null,
    color: 'red'
  }, {
    rating: 2,
    icon: null,
    color: 'red'
  }, {
    rating: 3,
    icon: null,
    color: 'orange'
  }, {
    rating: 4,
    icon: null,
    color: 'yellow'
  }, {
    rating: 5,
    icon: null,
    color: 'green-light'
  }, {
    rating: 6,
    icon: null,
    color: 'green'
  }],
  7: [{
    rating: 1,
    icon: null,
    color: 'red'
  }, {
    rating: 2,
    icon: null,
    color: 'red'
  }, {
    rating: 3,
    icon: null,
    color: 'orange'
  }, {
    rating: 4,
    icon: null,
    color: 'orange'
  }, {
    rating: 5,
    icon: null,
    color: 'yellow'
  }, {
    rating: 6,
    icon: null,
    color: 'green-light'
  }, {
    rating: 7,
    icon: null,
    color: 'green'
  }],
  8: [{
    rating: 1,
    icon: null,
    color: 'red'
  }, {
    rating: 2,
    icon: null,
    color: 'red'
  }, {
    rating: 3,
    icon: null,
    color: 'orange'
  }, {
    rating: 4,
    icon: null,
    color: 'orange'
  }, {
    rating: 5,
    icon: null,
    color: 'yellow'
  }, {
    rating: 6,
    icon: null,
    color: 'yellow'
  }, {
    rating: 7,
    icon: null,
    color: 'green-light'
  }, {
    rating: 8,
    icon: null,
    color: 'green'
  }],
  9: [{
    rating: 1,
    icon: null,
    color: 'red'
  }, {
    rating: 2,
    icon: null,
    color: 'red'
  }, {
    rating: 3,
    icon: null,
    color: 'orange'
  }, {
    rating: 4,
    icon: null,
    color: 'orange'
  }, {
    rating: 5,
    icon: null,
    color: 'yellow'
  }, {
    rating: 6,
    icon: null,
    color: 'yellow'
  }, {
    rating: 7,
    icon: null,
    color: 'yellow'
  }, {
    rating: 8,
    icon: null,
    color: 'green-light'
  }, {
    rating: 9,
    icon: null,
    color: 'green'
  }],
  10: [{
    rating: 1,
    icon: null,
    color: 'red'
  }, {
    rating: 2,
    icon: null,
    color: 'red'
  }, {
    rating: 3,
    icon: null,
    color: 'red'
  }, {
    rating: 4,
    icon: null,
    color: 'orange'
  }, {
    rating: 5,
    icon: null,
    color: 'orange'
  }, {
    rating: 6,
    icon: null,
    color: 'orange'
  }, {
    rating: 7,
    icon: null,
    color: 'yellow'
  }, {
    rating: 8,
    icon: null,
    color: 'yellow'
  }, {
    rating: 9,
    icon: null,
    color: 'green-light'
  }, {
    rating: 10,
    icon: null,
    color: 'green'
  }]
};
export var getColorsAndIcons = function getColorsAndIcons(scale) {
  if (!completeStructure.hasOwnProperty(scale)) {
    window.console.error('[ColorIconService] Invalid scale passed.');
    return null;
  }
  return completeStructure[scale].slice();
};
export var getColorAndIcon = function getColorAndIcon(rating, scale) {
  var structure = getColorsAndIcons(scale);
  var oneObjectInSet = structure.filter(function (item) {
    return item.rating === rating;
  });
  if (oneObjectInSet.length !== 1) {
    window.console.error('[ColorIconService] Invalid rating passed.');
    return null;
  }
  return oneObjectInSet[0];
};
export var getColorNameByIndex = function getColorNameByIndex(index, companyProperties) {
  if (typeof index === 'undefined' || index === null || isNaN(index) || index > 100 || index < 0) {
    return 'grey';
  }
  if (index >= 0 && index < companyProperties.indexThresholdLow) {
    return 'red';
  }
  if (index >= companyProperties.indexThresholdLow && index < companyProperties.indexThresholdLowerMedium) {
    return 'orange';
  }
  if (index >= companyProperties.indexThresholdLowerMedium && index < companyProperties.indexThresholdUpperMedium) {
    return 'yellow';
  }
  if (index >= companyProperties.indexThresholdUpperMedium && index < companyProperties.indexThresholdHigh) {
    return 'green-light';
  }
  if (index >= companyProperties.indexThresholdHigh && index <= 100) {
    return 'green';
  }
};
export var getColorNameBySatisfactionRate = function getColorNameBySatisfactionRate(satisfactionRate, companyProperties) {
  if (satisfactionRate === null) {
    return 'grey';
  } else if (satisfactionRate >= companyProperties.colorClassificationThresholdHigh) {
    return 'green';
  } else if (satisfactionRate >= companyProperties.colorClassificationThresholdLow) {
    return 'orange';
  } else {
    return 'red';
  }
};