import "core-js/modules/es.error.cause.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ComparisonService from '@/services/ComparisonService';
import LocationGroupTableEntry from '@/components/shared/LocationGroupTableEntry';
export default {
  name: 'FilterManagementTable',
  components: {
    LocationGroupTableEntry: LocationGroupTableEntry
  },
  props: {
    filters: {
      type: Array,
      required: true
    },
    filter: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    fields: function fields() {
      // fields in computed allows translation to adapt
      return [{
        key: 'name',
        label: this.$t('fields.name'),
        sortable: true
      }, {
        key: 'locations',
        label: this.$t('fields.locations'),
        sortable: false
      }, {
        key: 'locationGroups',
        label: this.$t('fields.locationGroups'),
        sortable: false
      }, {
        key: 'createdDate',
        label: this.$t('fields.createdDate'),
        sortable: true
      }, {
        key: 'lastModifiedDate',
        label: this.$t('fields.lastModifiedDate'),
        sortable: true
      }, {
        key: 'actions',
        label: ' ',
        sortable: false
      }];
    }
  },
  methods: {
    sortCompare: function sortCompare(aRow, bRow, key) {
      var a = aRow[key];
      var b = bRow[key];
      if (key === 'name') {
        return ComparisonService.compareStrings(a, b);
      } else if (key === 'createdDate' || key === 'lastModifiedDate') {
        // Invert sort order so the latest dates are first
        return ComparisonService.compare(b, a);
      }
      throw new Error("Sorting by key '".concat(key, "' is not enabled."));
    },
    deleteFilter: function deleteFilter(filter) {
      this.$emit('delete-filter', filter);
    }
  }
};