// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".print-quick-stat.colored {\n  border-left: 5px solid #ccc;\n}\n.print-quick-stat.colored.color-green {\n    border-left-color: #67c892;\n}\n.print-quick-stat.colored.color-green-light {\n    border-left-color: #9ec867;\n}\n.print-quick-stat.colored.color-yellow {\n    border-left-color: #edc84c;\n}\n.print-quick-stat.colored.color-orange {\n    border-left-color: #e9ad6a;\n}\n.print-quick-stat.colored.color-red {\n    border-left-color: #e96a6a;\n}\n.print-quick-stat .title {\n  margin-bottom: 7px;\n}\n.print-quick-stat .data {\n  font-size: 28px;\n  text-align: center;\n  color: #6a6a6a;\n}\n.print-quick-stat .value.inactive {\n  opacity: 0.5;\n}\n.print-quick-stat .change {\n  font-size: 50%;\n  margin-left: 5px;\n  color: rgba(0, 0, 0, 0.35);\n}\n.print-quick-stat .change .font-awesome {\n    margin-left: 2px;\n    font-size: 80%;\n}\n.print-quick-stat .change .font-awesome.long-arrow-up {\n      -webkit-transform: rotate(45deg);\n              transform: rotate(45deg);\n}\n.print-quick-stat .change .font-awesome.long-arrow-down {\n      -webkit-transform: rotate(-45deg);\n              transform: rotate(-45deg);\n}\n.print-quick-stat .change .font-awesome.exchange {\n      margin-left: 0;\n      font-size: 71%;\n      -webkit-transform: rotate(90deg);\n              transform: rotate(90deg);\n}\n", ""]);
// Exports
exports.locals = {
	"green": "#67c892",
	"green-light": "#9ec867",
	"yellow": "#edc84c",
	"orange": "#e9ad6a",
	"red": "#e96a6a",
	"secondaryColor": "#30415d",
	"primaryColor": "#5dbfe2",
	"primaryColorDark": "#4886a5",
	"gray": "#a1a1a1"
};
module.exports = exports;
