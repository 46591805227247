import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {
      initialValue: 0
    };
  },
  computed: {
    classObject: function classObject() {
      return ['opn-progress-bar', this.progressVariant, this.animated ? 'opn-progress-bar-animated' : '', {
        'full-width-no-progress-indicator': this.value === 100 && !this.showProgress
      }, this.isPdf ? 'is-pdf' : ''];
    },
    styleObject: function styleObject() {
      return {
        width: this.progress + '%'
      };
    },
    progressVariant: function progressVariant() {
      return this.variant ? "bg-".concat(this.variant) : null;
    },
    progress: function progress() {
      if (this.value !== null) {
        var p = Math.pow(10, this.precision);
        return Math.round(100 * p * this.value * this.initialValue / this.max) / p;
      } else {
        return 0;
      }
    },
    labelComputed: function labelComputed() {
      if (this.label) {
        return this.label;
      } else if (this.value === null) {
        return 'n/a';
      } else if (this.isPercentage) {
        return this.progress + '%';
      } else {
        return this.progress;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    // used to animate bar
    setTimeout(function () {
      _this.initialValue = 1;
    }, 100);
  },
  props: {
    animated: {
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    variant: {
      type: String,
      default: null,
      validator: function validator(value) {
        return ['dark-blue', 'red', 'orange', 'yellow', 'green-light', 'green'].includes(value);
      }
    },
    showProgress: {
      type: Boolean,
      default: false
    },
    showValue: {
      type: Boolean,
      default: false
    },
    isPercentage: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    isPdf: {
      type: Boolean,
      default: false
    }
  }
};