var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      attrs: { "panel-title": _vm.$t("indexThresholds"), "is-loading": false },
    },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("loadingError.title"),
              message: _vm.$t("loadingError.message"),
              show: _vm.loadingErrorOccurred && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("savingError.title"),
              message: _vm.$t("savingError.message"),
              show: _vm.savingErrorOccurred && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "check"],
              type: "success",
              title: _vm.$t("success.title"),
              message: _vm.$t("success.message"),
              show: _vm.successfullySaved && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          !_vm.loadingErrorOccurred
            ? _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-1" },
                        [
                          _c("b-col", { attrs: { sm: "12" } }, [
                            _c(
                              "p",
                              { staticClass: "alert alert-warning" },
                              [
                                _c("font-awesome", {
                                  staticClass: "font-awesome",
                                  attrs: {
                                    icon: ["fas", "exclamation-triangle"],
                                  },
                                }),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.$t("infoMessage")),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "form-label form-label-for-checkbox",
                                attrs: {
                                  for: "input-has-new-index-calculation",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("label.hasNewIndexCalculation"))
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "7", xl: "9" } },
                            [
                              _c("b-form-checkbox", {
                                staticClass: "checkbox-blue checkbox-strong",
                                attrs: {
                                  id: "input-has-new-index-calculation",
                                  name: "input-has-new-index-calculation",
                                  disabled: _vm.isLoading,
                                  value: true,
                                  "unchecked-value": false,
                                },
                                model: {
                                  value: _vm.hasNewIndexCalculation,
                                  callback: function ($$v) {
                                    _vm.hasNewIndexCalculation = $$v
                                  },
                                  expression: "hasNewIndexCalculation",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hasNewIndexCalculation
                        ? _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                                _c("label", { staticClass: "form-label" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("label.colorDistribution"))
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { sm: "7", xl: "9" } },
                                [
                                  _c("index-threshold-slider", {
                                    attrs: {
                                      values: [
                                        _vm.form.indexThresholdLow,
                                        _vm.form.indexThresholdLowerMedium,
                                        _vm.form.indexThresholdUpperMedium,
                                        _vm.form.indexThresholdHigh,
                                      ],
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.form.indexThresholdLow = $event[0]
                                        _vm.form.indexThresholdLowerMedium =
                                          $event[1]
                                        _vm.form.indexThresholdUpperMedium =
                                          $event[2]
                                        _vm.form.indexThresholdHigh = $event[3]
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-4" },
                        [
                          _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "form-label form-label-for-checkbox",
                                attrs: {
                                  for: "input-show-archived-location-analytics",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "label.showArchivedLocationAnalytics"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "7", xl: "9" } },
                            [
                              _c("b-form-checkbox", {
                                staticClass: "checkbox-blue checkbox-strong",
                                attrs: {
                                  id: "input-show-archived-location-analytics",
                                  name: "input-show-archived-location-analytics",
                                  disabled: _vm.isLoading,
                                  value: true,
                                  "unchecked-value": false,
                                },
                                model: {
                                  value: _vm.form.showArchivedLocationAnalytics,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "showArchivedLocationAnalytics",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.showArchivedLocationAnalytics",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-4" },
                        [
                          _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "form-label form-label-for-checkbox",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "label.howToShowFirstNameAndLastName"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "7", xl: "9" } },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.displayUsernameOptions },
                                model: {
                                  value: _vm.form.displayUserNamesConfiguration,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "displayUserNamesConfiguration",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.displayUserNamesConfiguration",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-1" },
                        [
                          _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "form-label form-label-for-checkbox",
                                attrs: { for: "input-spam-requires-reason" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("label.spamRequiresReason"))
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "7", xl: "9" } },
                            [
                              _c("b-form-checkbox", {
                                staticClass: "checkbox-blue checkbox-strong",
                                attrs: {
                                  id: "input-spam-requires-reason",
                                  name: "input-spam-requires-reason",
                                  disabled: _vm.isLoading,
                                  value: true,
                                  "unchecked-value": false,
                                },
                                model: {
                                  value: _vm.form.spamRequiresReason,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "spamRequiresReason",
                                      $$v
                                    )
                                  },
                                  expression: "form.spamRequiresReason",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: {
                            variant: "primary",
                            disabled: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("saveIndex")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }