import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import SurveyManagementLasWrapper from '@/components/survey-management/edit-question/questions/SurveyManagementLasWrapper';
import FormSelect from '@/components/shared/form-input/FormSelect';
import ScaleOrderIcon from '@/components/survey-management/icons/ScaleOrderIcon';
export default {
  name: 'SurveyManagementEditingQuestion',
  components: {
    SurveyManagementLasWrapper: SurveyManagementLasWrapper,
    FormSelect: FormSelect,
    ScaleOrderIcon: ScaleOrderIcon
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    editQuestion: {
      type: Object,
      required: true
    },
    currentLanguage: {
      type: String,
      required: true
    }
  },
  created: function created() {
    this.setInternalEditQuestion();
    this.setInternalCurrentLanguage();
  },
  data: function data() {
    return {
      internalEditQuestion: null,
      internalCurrentLanguage: null
    };
  },
  computed: {
    editPageState: function editPageState() {
      return this.$store.state.surveyManagement.editPageState;
    },
    visibleInPoolSectorCategoriesWithQuestionCategory: function visibleInPoolSectorCategoriesWithQuestionCategory() {
      var _this = this;
      // Return not only all visibleInPool categories, but also the question's current category
      // (if not already visibleInPool), which could be an 'invalid' category, as it is not in the company's sector
      if (!this.$store.state.surveyManagement.categories.isLoading && !this.$store.state.surveyManagement.categories.hasError) {
        var visibleInPoolCategories = _.cloneDeep(this.$store.state.surveyManagement.categories.data);
        var categories = _.cloneDeep(visibleInPoolCategories);
        if (this.question.categoryId !== null) {
          var initialCategory = visibleInPoolCategories.find(function (category) {
            return category.categoryId === _this.question.categoryId;
          });

          // If the initial category of the question is not in the list of visibleInPool categories,
          // it's either not visibleInPool or not in company's sector, so we add it here
          if (typeof initialCategory === 'undefined') {
            categories.push({
              categoryId: this.question.categoryId,
              categoryName: _.cloneDeep(this.question.categoryName)
            });
          }
        }
        categories.sort(function (categoryA, categoryB) {
          var categoryNameA = _this.$options.filters.languageAwareString(categoryA.categoryName, true);
          var categoryNameB = _this.$options.filters.languageAwareString(categoryB.categoryName, true);
          if ((typeof categoryNameA === 'undefined' || categoryNameA === null) && !(typeof categoryNameB === 'undefined' || categoryNameB === null)) {
            return 1;
          } else if (!(typeof categoryNameA === 'undefined' || categoryNameA === null) && (typeof categoryNameB === 'undefined' || categoryNameB === null)) {
            return -1;
          } else if ((typeof categoryNameA === 'undefined' || categoryNameA === null) && (typeof categoryNameB === 'undefined' || categoryNameB === null)) {
            return 0;
          }
          return categoryNameA.localeCompare(categoryNameB);
        });
        return categories;
      }
      return [];
    },
    currentlySelectedCategoryName: function currentlySelectedCategoryName() {
      var _this2 = this;
      if (this.editQuestion.categoryId === null || this.visibleInPoolSectorCategoriesWithQuestionCategory === null) {
        return null;
      }
      var currentlySelectedCategory = this.visibleInPoolSectorCategoriesWithQuestionCategory.find(function (category) {
        return category.categoryId === _this2.editQuestion.categoryId;
      });
      if (typeof currentlySelectedCategory !== 'undefined') {
        return currentlySelectedCategory.categoryName;
      }
      return null;
    },
    validation: function validation() {
      return this.$store.getters['surveyManagement/editQuestionValidation'];
    }
  },
  methods: {
    setInternalEditQuestion: function setInternalEditQuestion() {
      this.internalEditQuestion = this.cloneDeep(this.editQuestion);
    },
    setInternalCurrentLanguage: function setInternalCurrentLanguage() {
      this.internalCurrentLanguage = this.currentLanguage;
    },
    cloneDeep: _.cloneDeep
  },
  watch: {
    internalEditQuestion: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalEditQuestion, this.editQuestion)) {
          this.$emit('update-edit-question', this.cloneDeep(this.internalEditQuestion));
        }
      }
    },
    editQuestion: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalEditQuestion, this.editQuestion)) {
          this.setInternalEditQuestion();
        }
      }
    },
    internalCurrentLanguage: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalCurrentLanguage, this.currentLanguage)) {
          this.$emit('change-language', this.internalCurrentLanguage);
        }
      }
    },
    currentLanguage: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalCurrentLanguage, this.currentLanguage)) {
          this.setInternalCurrentLanguage();
        }
      }
    }
  }
};