var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-table" },
    [
      _c("b-table", {
        attrs: {
          items: _vm.users,
          fields: _vm.fields,
          filter: _vm.filter,
          "filter-included-fields": ["firstName", "lastName", "email"],
          "show-empty": true,
          "empty-text": _vm.$t("emptyTableText"),
          "empty-filtered-text": _vm.$t("emptyTableText"),
          "sort-compare": _vm.sortCompare,
          "sort-by": "firstName",
          "no-sort-reset": "",
          responsive: "",
          striped: "",
          hover: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(firstName)",
            fn: function (data) {
              return [
                data.item.firstName
                  ? _c("span", [
                      _vm._v(
                        "\n        " + _vm._s(data.item.firstName) + "\n      "
                      ),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(lastName)",
            fn: function (data) {
              return [
                data.item.lastName
                  ? _c("span", [
                      _vm._v(
                        "\n        " + _vm._s(data.item.lastName) + "\n      "
                      ),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(firstNameLastName)",
            fn: function (data) {
              return [
                data.item.firstName || data.item.lastName
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(data.item.firstName) +
                          " " +
                          _vm._s(data.item.lastName) +
                          "\n      "
                      ),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(lastNameFirstName)",
            fn: function (data) {
              return [
                data.item.firstName || data.item.lastName
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(data.item.lastName) +
                          " " +
                          _vm._s(data.item.firstName) +
                          "\n      "
                      ),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(email)",
            fn: function (data) {
              return [
                data.item.email
                  ? _c("span", [_vm._v(_vm._s(data.item.email))])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(role)",
            fn: function (data) {
              return [
                data.item.role === "ROLE_ADMIN"
                  ? _c("span", { staticClass: "label light-blue" }, [
                      _vm._v("ADMIN"),
                    ])
                  : data.item.role === "ROLE_MANAGER"
                  ? _c("span", { staticClass: "label light-blue" }, [
                      _vm._v("MANAGER"),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(locations)",
            fn: function (data) {
              return [
                _c("location-group-table-entry", {
                  attrs: { items: data.item.locations },
                }),
              ]
            },
          },
          {
            key: "cell(last-login)",
            fn: function (data) {
              return [
                data.item["last-login"]
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          data.item["last-login"].format("DD-MM-YYYY HH:mm")
                        )
                      ),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex float-right" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "user-edit",
                            params: { user: data.item.id },
                          },
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "primary", size: "sm" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("editButton")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isTableActiveUsers
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              disabled: _vm.currentUserId === data.item.id,
                              variant: "secondary",
                              size: "sm",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deactivateUser(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("deactivateButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isTableDeactivatedUsers
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              disabled: _vm.currentUserId === data.item.id,
                              variant: "secondary",
                              size: "sm",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.activateUser(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("activateButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isTableDeactivatedUsers
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              disabled: _vm.currentUserId === data.item.id,
                              variant: "danger",
                              size: "sm",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteUser(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("deleteButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }