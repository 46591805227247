var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      staticClass: "selection-box mb-4",
      attrs: { "is-loading": _vm.isLoading },
    },
    [
      _c("template", { slot: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("title")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _c("modal-icon-button", {
            attrs: {
              index: 0,
              icon: ["far", "trash"],
              message: _vm.$t("tooltip.reset"),
              disabled: _vm.isLoading,
              "color-type": "danger",
              "horizontal-in-box": "",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("clear-config")
              },
            },
          }),
          _vm._v(" "),
          _c("modal-icon-button", {
            attrs: {
              index: 1,
              icon: ["far", "pencil"],
              message: _vm.$t("tooltip.edit"),
              disabled: _vm.isLoading,
              "color-type": "info",
              "horizontal-in-box": "",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("edit-config")
              },
            },
          }),
          _vm._v(" "),
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c("filter-criteria-date", {
                    attrs: {
                      "start-date": _vm.startDate,
                      "end-date": _vm.endDate,
                    },
                  }),
                  _vm._v(" "),
                  _c("filter-criteria-location", {
                    attrs: {
                      "location-ids": _vm.config.locations.queryLocations,
                    },
                  }),
                  _vm._v(" "),
                  _c("filter-criteria-survey", {
                    attrs: {
                      "survey-name": _vm.data.surveyName,
                      "is-loading": false,
                    },
                  }),
                  _vm._v(" "),
                  _vm.data.choiceAttributes.length > 0
                    ? _c(
                        "div",
                        { staticClass: "filter-criteria-choice-wrapper-box" },
                        [
                          _vm._l(
                            _vm.data.choiceAttributes,
                            function (choiceAttribute) {
                              return [
                                _c(
                                  "filter-criteria-choice",
                                  {
                                    key: choiceAttribute.choiceAttributeId,
                                    staticStyle: { cursor: "auto" },
                                    attrs: {
                                      short: true,
                                      active: true,
                                      disabled: false,
                                      title:
                                        _vm.$options.filters.languageAwareString(
                                          choiceAttribute.choiceAttributeText,
                                          true
                                        ),
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$options.filters.languageAwareString(
                                            choiceAttribute.choiceAttributeText
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n          " + _vm._s(" ") + "\n        "
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "div",
                _vm._l(3, function (index) {
                  return _c("div", {
                    key: index,
                    staticClass: "loading-placeholder",
                  })
                }),
                0
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }