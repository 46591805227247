//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SortIcon',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    sortType: {
      type: String,
      default: 'alpha'
    },
    sortOrder: {
      type: String,
      default: 'desc'
    }
  },
  computed: {
    icon: function icon() {
      return 'sort-' + this.sortType + '-' + (this.sortOrder === 'desc' ? 'up' : 'down');
    }
  }
};