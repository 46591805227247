import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QueryService from '@/services/QueryService';
import OpnnLogo from '@/assets/opiniooon-logo-white.svg';
export default {
  name: 'Sidebar',
  data: function data() {
    return {
      fullQuery: null
    };
  },
  computed: {
    user: function user() {
      return this.$store.state.general.user;
    },
    isCustomDomain: function isCustomDomain() {
      return this.$store.getters['general/isCustomDomain'];
    },
    customDomain: function customDomain() {
      return this.$store.getters['general/customDomain'];
    },
    customDomainStyles: function customDomainStyles() {
      if (this.isCustomDomain) {
        var width = 140;
        return {
          width: "".concat(width, "px"),
          height: "".concat(width / this.customDomain.logoDarkSideRatio, "px")
        };
      }
      return {};
    },
    logoSrc: function logoSrc() {
      if (this.isCustomDomain) {
        return "data:".concat(this.customDomain.logoBrightContentType, ";base64,").concat(this.customDomain.logoBright);
      }
      return OpnnLogo;
    },
    userIsAdmin: function userIsAdmin() {
      if (this.user !== null && this.user.authorities && this.user.authorities.length > 0) {
        return this.user.authorities.includes('ROLE_ADMIN');
      }
      return false;
    },
    currentUserIsSuperAdmin: function currentUserIsSuperAdmin() {
      return this.user.authorities.includes('ROLE_SUPER_ADMIN');
    },
    userHasAccessToSurveyManagement: function userHasAccessToSurveyManagement() {
      return this.userIsAdmin && this.user.adminPermissionHasAccessToSurveyManagement;
    }
  },
  watch: {
    $route: function $route() {
      var _this = this;
      this.$nextTick(function () {
        _this.fullQuery = QueryService.encodedQuery();
      });
    }
  }
};