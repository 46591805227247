import "core-js/modules/es.error.cause.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ComparisonService from '@/services/ComparisonService';
export default {
  name: 'LocationAdministrationOverviewTable',
  props: {
    locations: {
      type: Array,
      required: true
    },
    filter: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    fields: function fields() {
      // fields in computed allows translation to adapt
      return [{
        key: 'name',
        label: this.$t('fields.name'),
        sortable: true
      }, {
        key: 'surveyName',
        label: this.$t('fields.surveyName'),
        sortable: true
      }, {
        key: 'defaultLanguage',
        label: this.$t('fields.defaultLanguage'),
        sortable: true
      }, {
        key: 'locationGroupName',
        label: this.$t('fields.locationGroupName'),
        sortable: true
      }, {
        key: 'actions',
        label: ' ',
        sortable: false
      }];
    }
  },
  methods: {
    sortCompare: function sortCompare(aRow, bRow, key) {
      var a = aRow[key];
      var b = bRow[key];
      if (key === 'defaultLanguage' || key === 'locationGroupName' || key === 'name' || key === 'surveyName') {
        return ComparisonService.compareStrings(a, b);
      }
      throw new Error("Sorting by key '".concat(key, "' is not enabled."));
    },
    archiveLocation: function archiveLocation(location) {
      this.$emit('archive-location', location);
    },
    unarchiveLocation: function unarchiveLocation(location) {
      this.$emit('unarchive-location', location);
    },
    deleteLocation: function deleteLocation(location) {
      this.$emit('delete-location', location);
    }
  }
};