//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PdfReport from '@/components/report/PdfReport';
export default {
  name: 'Report',
  components: {
    PdfReport: PdfReport
  },
  data: function data() {
    return {};
  }
};