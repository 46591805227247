import Vue from 'vue';
import Vuex from 'vuex';
import PersistedState from 'vuex-persistedstate';
import general from '@/store/modules/general';
import surveyManagement from '@/store/modules/survey-management';
Vue.use(Vuex);
var Store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    general: general,
    surveyManagement: surveyManagement
  },
  plugins: [PersistedState({
    key: 'opnn-store-modules',
    paths: ['general.selectedUser', 'general.companyId', 'general.language', 'general.comparison']
  })]
});
export default Store;