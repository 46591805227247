var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-management-overview-table" },
    [
      _c("b-table", {
        attrs: {
          items: _vm.surveys,
          fields: _vm.tableFields,
          filter: _vm.filter,
          "filter-included-fields": ["surveyName"],
          "show-empty": true,
          "empty-text": _vm.$t("emptyTableText"),
          "empty-filtered-text": _vm.$t("emptyTableText"),
          "sort-compare": _vm.sortCompare,
          "sort-by": "surveyName",
          hover: "",
          "no-sort-reset": "",
          responsive: "",
          striped: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(surveyName)",
            fn: function (data) {
              return [
                _vm.type === "active"
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "survey-management-edit",
                            params: { surveyId: data.item.surveyId },
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "clickable-link" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(data.item.surveyName) +
                              "\n        "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.type === "archived"
                  ? [_vm._v(_vm._s(data.item.surveyName))]
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(numberOfEnabledQuestions)",
            fn: function (data) {
              return [_vm._v("\n      " + _vm._s(data.value) + "\n    ")]
            },
          },
          {
            key: "cell(usedInNumberOfActiveLocations)",
            fn: function (data) {
              return [
                data.value
                  ? _c("span", { staticClass: "label green" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("active")) + "\n      "
                      ),
                    ])
                  : _c("span", { staticClass: "label gray" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("inactive")) + "\n      "
                      ),
                    ]),
              ]
            },
          },
          {
            key: "cell(enabledLanguages)",
            fn: function (data) {
              return [
                data.value.hasMultipleLanguages
                  ? _c(
                      "b-badge",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top.d200",
                            modifiers: { hover: true, top: true, d200: true },
                          },
                        ],
                        attrs: {
                          pill: "",
                          variant: "secondary",
                          title: data.value.enabledLanguagesJoined,
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t("languageCount", {
                                count: data.value.enabledLanguagesCount,
                              })
                            ) +
                            "\n      "
                        ),
                      ]
                    )
                  : [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "languages." +
                                data.value.enabledLanguagesJoined.toLowerCase()
                            )
                          ) +
                          "\n      "
                      ),
                    ],
              ]
            },
          },
          {
            key: "cell(lastAnswerSetNotSpamWithThisSurvey)",
            fn: function (data) {
              return [
                data.value
                  ? _c("span", [
                      _vm._v(_vm._s(data.value.format("DD.MM.YYYY HH:mm"))),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex float-right" },
                  [
                    _vm.type === "active"
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "survey-management-edit",
                                params: { surveyId: data.item.surveyId },
                              },
                            },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-1",
                                attrs: { variant: "primary", size: "sm" },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("editButton")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "active"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.archiveSurvey(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("archiveButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "archived"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.unarchiveSurvey(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("unarchiveButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "active"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.assignSurvey(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("assignButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "archived"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "danger", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSurvey(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("deleteButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }