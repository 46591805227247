import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SurveyManagementQuestionTypeAlphaNumericIcon from '@/components/survey-management/icons/SurveyManagementQuestionTypeAlphaNumericIcon';
export default {
  name: 'SurveyManagementPreviewChoiceQuestion',
  components: {
    SurveyManagementQuestionTypeAlphaNumericIcon: SurveyManagementQuestionTypeAlphaNumericIcon
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    questionType: {
      type: String,
      required: true,
      validator: function validator(type) {
        return ['CHOICE_QUESTION_SINGLE_CHOICE', 'CHOICE_QUESTION_MULTIPLE_CHOICE'].includes(type);
      }
    }
  },
  computed: {
    currentLanguage: function currentLanguage() {
      return this.$store.state.surveyManagement.editPageState.data.currentLanguage;
    }
  },
  methods: {
    lasPreviewTranslation: function lasPreviewTranslation(las) {
      if (las === null || typeof las === 'undefined') {
        return null;
      }
      return las[this.currentLanguage];
    }
  }
};