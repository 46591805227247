var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "print-rating-distribution-chart" }, [
    _c(
      "div",
      { staticClass: "chart", class: "scale-" + _vm.enrichedData.scale },
      [
        _c(
          "div",
          { staticClass: "bars" },
          _vm._l(_vm.enrichedData.distribution, function (item) {
            return _c(
              "div",
              {
                key: item.distributionIndex,
                staticClass: "bar",
                class: item.colorName,
                style: {
                  height:
                    (item.ratingAbsolute / _vm.maxAbsoluteValue) * 100 + "%",
                },
              },
              [
                _c("div", { staticClass: "distribution-index" }, [
                  _vm._v(_vm._s(item.distributionIndex)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "percentage-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("groupedNumber")(item.ratingPercent, true, 1)
                      ) +
                      "%\n        "
                  ),
                ]),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "grid-top",
          attrs: { "data-content": _vm.gridData.top },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "grid-top-middle" }),
        _vm._v(" "),
        _c("div", {
          staticClass: "grid-middle",
          attrs: { "data-content": _vm.gridData.middle },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "grid-middle-bottom" }),
        _vm._v(" "),
        _c("div", {
          staticClass: "grid-bottom",
          attrs: { "data-content": _vm.gridData.bottom },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }