var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "web-source-qr-code-modal" },
    [
      _c("modal-icon-button", {
        attrs: {
          index: 0,
          icon: ["far", "times"],
          message: _vm.$t("message.closeModal"),
          "color-type": "danger",
          "tooltip-placement": "right",
        },
        on: { click: _vm.closeModal },
      }),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { offset: "2", cols: "8" } },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "info"],
              closeable: false,
              message: _vm.$t("embedQRCodeExplanation"),
              type: "info",
            },
          }),
          _vm._v(" "),
          _c("b-form-textarea", {
            attrs: { value: _vm.embedQRCodeHTML, rows: "10" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { staticClass: "mt-4", attrs: { cols: "12" } },
        [
          _c(
            "b-button",
            {
              staticClass: "float-right",
              attrs: { variant: "outline-secondary" },
              on: {
                click: function ($event) {
                  return _vm.closeModal()
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("button.close")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }