var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-view",
    { staticClass: "login" },
    [
      _c("template", { slot: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("login")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _c("alert", {
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("error.title"),
              message: _vm.$t("error.message"),
              show: _vm.errorOccurred && !_vm.submitting,
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-fieldset",
            { attrs: { label: _vm.$t("email") } },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: {
                  type: "email",
                  placeholder: _vm.$t("placeholder.emailAddress"),
                  disabled: _vm.submitting,
                  name: "email",
                  autofocus: "",
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.submit($event)
                  },
                },
                model: {
                  value: _vm.inputEmail,
                  callback: function ($$v) {
                    _vm.inputEmail = $$v
                  },
                  expression: "inputEmail",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-fieldset",
            { attrs: { label: _vm.$t("password") } },
            [
              _c("b-form-input", {
                staticClass: "form-control",
                attrs: {
                  type: "password",
                  placeholder: _vm.$t("placeholder.password"),
                  disabled: _vm.submitting,
                  name: "password",
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.submit($event)
                  },
                },
                model: {
                  value: _vm.inputPassword,
                  callback: function ($$v) {
                    _vm.inputPassword = $$v
                  },
                  expression: "inputPassword",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-fieldset",
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "checkbox-blue",
                  attrs: {
                    value: "true",
                    "unchecked-value": "false",
                    disabled: _vm.submitting,
                  },
                  model: {
                    value: _vm.rememberMe,
                    callback: function ($$v) {
                      _vm.rememberMe = $$v
                    },
                    expression: "rememberMe",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("rememberMe")))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button-row" },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    block: true,
                    disabled: _vm.submitting,
                  },
                  on: { click: _vm.submit },
                },
                [
                  _vm.submitting
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("loading")))])
                    : _c(
                        "span",
                        [
                          _c("font-awesome", {
                            attrs: { icon: ["far", "sign-in"] },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("signIn")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "btn-uppercase",
                  attrs: { to: { name: "password-recovery" } },
                },
                [_vm._v(_vm._s(_vm.$t("passwordReset")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }