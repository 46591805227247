var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      staticClass: "pdf-report",
      attrs: { "panel-title": _vm.$t("panelTitle"), "is-loading": false },
    },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "info"],
              type: "info",
              title: _vm.$t("noDataError.title"),
              message: _vm.$t("noDataError.message"),
              show: _vm.noData && !_vm.isLoading,
              closeable: false,
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "opn-spinner",
            class: { shown: _vm.isLoading },
          }),
          _vm._v(" "),
          !_vm.isLoading && !_vm.noData
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.d200.bottom.html",
                      modifiers: {
                        hover: true,
                        d200: true,
                        bottom: true,
                        html: true,
                      },
                    },
                  ],
                  staticClass:
                    "number-of-feedbacks-and-comments badge badge-light",
                  attrs: { title: _vm.checkDataTitle },
                },
                [
                  _c(
                    "div",
                    { staticClass: "feedbacks" },
                    [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("groupedNumber")(
                              _vm.checkData.numberOfFeedbacks
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("font-awesome", {
                        staticClass: "font-awesome",
                        attrs: { icon: ["fas", "user"] },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "surveys" },
                    [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("groupedNumber")(
                              _vm.checkData.numberOfSurveys
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("font-awesome", {
                        staticClass: "font-awesome",
                        attrs: { icon: ["far", "poll-h"] },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "comments" },
                    [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("groupedNumber")(
                              _vm.checkData.numberOfComments
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("font-awesome", {
                        staticClass: "font-awesome",
                        attrs: { icon: ["far", "comment-lines"] },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "alert alert-info notice-duration" },
            [
              _c("font-awesome", {
                staticClass: "icon",
                attrs: { icon: ["fas", "info-circle"] },
              }),
              _vm._v("\n      " + _vm._s(_vm.$t("noticeDuration")) + "\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "input-date-range" },
                      },
                      [_vm._v(_vm._s(_vm.$t("label.dateRange")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { sm: "7", xl: "9" } }, [
                    _c(
                      "div",
                      [
                        _c("opn-date-range-picker", {
                          attrs: {
                            value: this.form.dateRange,
                            id: "input-date-range",
                            "is-loading": false,
                            "is-in-form": true,
                            "with-limited-ranges": true,
                          },
                          on: { "change-daterange": _vm.dateRangeChanged },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "input-locations" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.locations")) +
                            "\n            "
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.d200",
                                modifiers: { hover: true, d200: true },
                              },
                            ],
                            staticClass: "info-circle-wrapper",
                            attrs: { title: _vm.$t("tooltip.locations") },
                          },
                          [
                            _c("font-awesome", {
                              staticClass: "info-circle",
                              attrs: { icon: ["far", "info-circle"] },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { id: "location-flex", sm: "7", xl: "9" } },
                    [
                      _c("location-group-select", {
                        attrs: {
                          id: "input-locations",
                          "is-loading": false,
                          "pre-selected-ids": _vm.form.location,
                          limit: 999,
                          "is-in-form": true,
                        },
                        on: { "change-locations": _vm.changeLocations },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { id: "filter-selector" } },
                        [
                          !_vm.currentUserIsSuperAdmin
                            ? _c("search-filter-selector", {
                                attrs: {
                                  "is-loading": false,
                                  "pre-selected-search-filter-id":
                                    _vm.form.location.selectedSearchFilterId,
                                },
                                on: { "change-locations": _vm.changeLocations },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "select-language" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.language")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { sm: "7", xl: "9" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "b-form-select",
                          {
                            attrs: { id: "select-language" },
                            model: {
                              value: _vm.language,
                              callback: function ($$v) {
                                _vm.language = $$v
                              },
                              expression: "language",
                            },
                          },
                          _vm._l(
                            _vm.allowedLanguages,
                            function (allowedLanguage) {
                              return _c(
                                "option",
                                {
                                  key: allowedLanguage,
                                  domProps: { value: allowedLanguage },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "languages." +
                                            allowedLanguage.toLowerCase()
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "checkbox-components" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("label.components")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "7", xl: "9" } },
                    [
                      _c(
                        "b-form-checkbox-group",
                        {
                          attrs: { id: "checkbox-components" },
                          model: {
                            value: _vm.components,
                            callback: function ($$v) {
                              _vm.components = $$v
                            },
                            expression: "components",
                          },
                        },
                        _vm._l(_vm.componentOptions, function (option) {
                          return _c(
                            "div",
                            { key: option },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "checkbox-blue",
                                  attrs: {
                                    value: option,
                                    disabled:
                                      option === "BASIC" ||
                                      (option === "COMMENTS" &&
                                        _vm.tooManyComments) ||
                                      (option === "SURVEY_ANALYSIS" &&
                                        _vm.tooManySurveys) ||
                                      (option === "CUSTOM_ANSWERS" &&
                                        !_vm.components.includes(
                                          "SURVEY_ANALYSIS"
                                        )) ||
                                      (option === "TEXT_ANSWERS" &&
                                        !_vm.components.includes(
                                          "SURVEY_ANALYSIS"
                                        )),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("components." + option)) +
                                      "\n                "
                                  ),
                                  option === "SURVEY_ANALYSIS" &&
                                  !_vm.isLoading &&
                                  !_vm.noData
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.checkData.numberOfSurveys
                                            ) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  option === "COMMENTS" &&
                                  !_vm.isLoading &&
                                  !_vm.noData
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.checkData.numberOfComments
                                            ) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  option === "BASIC"
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.d200",
                                              modifiers: {
                                                hover: true,
                                                d200: true,
                                              },
                                            },
                                          ],
                                          staticClass: "info-circle-wrapper",
                                          attrs: {
                                            title: _vm.$t(
                                              "tooltip.basicOption"
                                            ),
                                          },
                                        },
                                        [
                                          _c("font-awesome", {
                                            staticClass: "info-circle",
                                            attrs: {
                                              icon: ["far", "info-circle"],
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  option === "SURVEY_ANALYSIS" &&
                                  _vm.tooManySurveys
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.d200",
                                              modifiers: {
                                                hover: true,
                                                d200: true,
                                              },
                                            },
                                          ],
                                          staticClass: "warning-icon-wrapper",
                                          attrs: {
                                            title: _vm.$t(
                                              "tooltip.tooManySurveys"
                                            ),
                                          },
                                        },
                                        [
                                          _c("font-awesome", {
                                            staticClass: "font-awesome",
                                            attrs: {
                                              icon: [
                                                "fas",
                                                "exclamation-triangle",
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  option === "CUSTOM_ANSWERS" &&
                                  !_vm.components.includes("SURVEY_ANALYSIS")
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.d200",
                                              modifiers: {
                                                hover: true,
                                                d200: true,
                                              },
                                            },
                                          ],
                                          staticClass: "info-circle-wrapper",
                                          attrs: {
                                            title: _vm.$t(
                                              "tooltip.customAnswers"
                                            ),
                                          },
                                        },
                                        [
                                          _c("font-awesome", {
                                            staticClass: "info-circle",
                                            attrs: {
                                              icon: ["far", "info-circle"],
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  option === "TEXT_ANSWERS" &&
                                  !_vm.components.includes("SURVEY_ANALYSIS")
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.d200",
                                              modifiers: {
                                                hover: true,
                                                d200: true,
                                              },
                                            },
                                          ],
                                          staticClass: "info-circle-wrapper",
                                          attrs: {
                                            title: _vm.$t(
                                              "tooltip.textAnswers"
                                            ),
                                          },
                                        },
                                        [
                                          _c("font-awesome", {
                                            staticClass: "info-circle",
                                            attrs: {
                                              icon: ["far", "info-circle"],
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  option === "COMMENTS" && _vm.tooManyComments
                                    ? _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.d200",
                                              modifiers: {
                                                hover: true,
                                                d200: true,
                                              },
                                            },
                                          ],
                                          staticClass: "warning-icon-wrapper",
                                          attrs: {
                                            title: _vm.$t(
                                              "tooltip.tooManyComments"
                                            ),
                                          },
                                        },
                                        [
                                          _c("font-awesome", {
                                            staticClass: "font-awesome",
                                            attrs: {
                                              icon: [
                                                "fas",
                                                "exclamation-triangle",
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("b-container", { attrs: { fluid: "" } }, [
            _vm.isLoading || _vm.noData
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-primary float-right disabled",
                    attrs: { href: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("font-awesome", {
                      attrs: { icon: ["fas", "cloud-download-alt"] },
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("downloadButton")) +
                        "\n      "
                    ),
                  ],
                  1
                )
              : _c(
                  "a",
                  {
                    staticClass: "btn btn-primary float-right",
                    attrs: { href: _vm.requestUrl, target: "_blank" },
                  },
                  [
                    _c("font-awesome", {
                      attrs: { icon: ["fas", "cloud-download-alt"] },
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("downloadButton")) +
                        "\n      "
                    ),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }