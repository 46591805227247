import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Store from '@/store/Store';
import Http from '@/config/Http';
var INITIAL_DELAY = 1 * 60 * 1000;
var KEEPALIVE_TIMEOUT = 5 * 60 * 1000;
var KEEPALIVE_ERROR_TIMEOUT = 0.5 * 60 * 1000;
var isOffline = false;
var onOfflineListener = function onOfflineListener() {
  if (window.navigator.onLine) {
    // a change from offline to online happened:
    if (isOffline) {
      window.setTimeout(function () {
        Http.get('/keep-alive');
      }, 500);
    }
    isOffline = false;
  } else {
    isOffline = true;
  }
};
var resolve = function resolve() {
  return Promise.resolve();
};
var timeoutPromise = function timeoutPromise(delay) {
  return new Promise(function (resolve) {
    window.setTimeout(function () {
      resolve();
    }, delay);
  });
};
var getKeepAliveFromBackEnd = function getKeepAliveFromBackEnd() {
  if (!Store.state.general.underMaintenance) {
    return Http.get('/keep-alive');
  } else {
    return resolve();
  }
};
var keepAlivePromiseWithTimeout = function keepAlivePromiseWithTimeout() {
  return Promise.all([timeoutPromise(KEEPALIVE_TIMEOUT), getKeepAliveFromBackEnd().then(resolve, function () {
    return timeoutPromise(KEEPALIVE_ERROR_TIMEOUT).then(function () {
      return getKeepAliveFromBackEnd().then(resolve, resolve);
    });
  })]);
};
var redo = function redo() {
  keepAlivePromiseWithTimeout().then(redo, redo);
};
var init = function init() {
  window.addEventListener('online', onOfflineListener);
  window.addEventListener('offline', onOfflineListener);
  timeoutPromise(INITIAL_DELAY).then(redo);
};
export default {
  init: init
};