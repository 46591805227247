import "core-js/modules/es.string.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import FormStepIndicator from '@/components/shared/FormStepIndicator';
import FormDateAndLocations from '@/components/comparison/FormDateAndLocations';
import FormSurvey from '@/components/comparison/FormSurvey';
import FormFilters from '@/components/comparison/FormFilters';
import ModalIconButton from '@/components/shared/ModalIconButton';
export default {
  name: 'FormBox',
  components: {
    Panel: Panel,
    FormDateAndLocations: FormDateAndLocations,
    FormSurvey: FormSurvey,
    FormFilters: FormFilters,
    ModalIconButton: ModalIconButton,
    FormStepIndicator: FormStepIndicator
  },
  props: {
    parentConfig: {
      required: true,
      type: Object
    }
  },
  data: function data() {
    return {
      stepIndex: 0,
      form: {}
    };
  },
  created: function created() {
    this.initialize();
  },
  computed: {
    steps: function steps() {
      var _this = this;
      return Array.from({
        length: 3
      }, function (_value, i) {
        return _this.$t("step".concat(i));
      });
    }
  },
  methods: {
    initialize: function initialize() {
      this.form = Object.assign({}, this.parentConfig);
    },
    saveFormAndClose: function saveFormAndClose() {
      this.form.dirty = false;
      this.$emit('save-form', this.form);
      this.$emit('close');
    },
    changeForm: function changeForm(newForm) {
      this.form.dirty = true;
      this.form = newForm;
      this.clearValuesOfSubsequentSteps();
    },
    clearValuesOfSubsequentSteps: function clearValuesOfSubsequentSteps() {
      if (this.stepIndex === 0) {
        this.form.surveyId = null;
        this.form.choiceAttributeIds = [];
      } else if (this.stepIndex === 1) {
        this.form.choiceAttributeIds = [];
      }
    }
  },
  watch: {
    parentConfig: {
      deep: true,
      handler: function handler() {
        this.initialize();
      }
    }
  }
};