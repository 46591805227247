import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import { stringContainsInvalidCharacters, stringIsEmpty } from '@/services/utils/StringUtils';
var getValidObject = function getValidObject(valid, details, single) {
  return {
    valid: valid,
    details: typeof details === 'undefined' || details === null ? undefined : details,
    single: typeof single === 'undefined' || single === null ? undefined : single
  };
};
var isValidLas = function isValidLas(las, enabledLanguages, optional) {
  if (las === null) {
    return !!optional;
  }
  var hasMissingLanguages = false;
  var hasSpecialChars = false;
  enabledLanguages.forEach(function (language) {
    if (stringContainsInvalidCharacters(las[language])) {
      hasSpecialChars = true;
    }
    if (!las.hasOwnProperty(language) || stringIsEmpty(las[language])) {
      hasMissingLanguages = true;
    }
  });
  return !hasMissingLanguages && !hasSpecialChars;
};
var getInvalidLanguagesFromLas = function getInvalidLanguagesFromLas(las, enabledLanguages, optional) {
  if (isValidLas(las, enabledLanguages, optional)) {
    return [];
  }
  if (las === null && !optional) {
    return enabledLanguages;
  }
  var invalidLanguages = [];
  enabledLanguages.forEach(function (language) {
    if (!las.hasOwnProperty(language) || stringIsEmpty(las[language]) || stringContainsInvalidCharacters(las[language])) {
      invalidLanguages.push(language);
    }
  });
  return invalidLanguages;
};
var getLasValidObject = function getLasValidObject(las, enabledLanguages, optional) {
  return getValidObject(isValidLas(las, enabledLanguages, optional), getInvalidLanguagesFromLas(las, enabledLanguages, optional));
};
export var getValidationState = function getValidationState(text, enabledLanguages) {
  return getLasValidObject(text, enabledLanguages, false);
};