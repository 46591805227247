var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("vuedal"),
      _vm._v(" "),
      !_vm.printView ? _c("progress-bar") : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.general.showLoadingIndicator
        ? _c("loading-indicator", {
            attrs: { "has-overlay": "", "position-fixed": "" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "transition",
              { attrs: { name: "fade-slide-left", mode: "out-in" } },
              [
                _vm.isAuthenticated && !_vm.underMaintenance && !_vm.printView
                  ? _c("sidebar", { staticClass: "main-sidebar" })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "main ml-auto",
                class: {
                  "no-auth":
                    !_vm.isAuthenticated ||
                    _vm.underMaintenance ||
                    _vm.printView,
                },
              },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade-slide-top", mode: "out-in" } },
                  [
                    _vm.isAuthenticated &&
                    !_vm.underMaintenance &&
                    !_vm.printView
                      ? _c("navbar")
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [
                    _vm.initialLoadingCompleted
                      ? _c("router-view", {
                          class: !_vm.printView ? ["p-3", "pt-4"] : null,
                          attrs: { id: "scroll-to-main-container-anchor" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }