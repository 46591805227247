import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import Panel from '@/components/shared/Panel';
import RoutingUtils from '@/services/utils/RoutingUtils';
import { getColorNameByIndex, getColorNameBySatisfactionRate } from '@/services/ColorService';
import QueryService from '@/services/QueryService';
import QueryUtils from '@/services/utils/QueryUtils';
export default {
  name: 'MatrixView',
  components: {
    Panel: Panel
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    matrixLocations: {
      type: Object,
      required: true
    },
    matrixLocationGroups: {
      type: Object,
      required: true
    },
    locationIdToLocationGroupMapping: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Array,
      required: true,
      validator: function validator(value) {
        return Array.isArray(value) && [0, 2].includes(value.length) && value.every(function (e) {
          return moment(e).isValid();
        });
      }
    }
  },
  data: function data() {
    return {
      showFullMatrix: false,
      order: 'asc',
      groupBy: 'location',
      groupingOptions: [{
        value: 'location',
        text: this.$t('groupByLocation')
      }, {
        value: 'locationGroup',
        text: this.$t('groupByLocationGroup')
      }]
    };
  },
  computed: {
    tableData: function tableData() {
      var _this = this;
      var tableData = [];
      if (!this.noData) {
        var sourceData = this.groupBy === 'location' ? this.matrixLocations : this.matrixLocationGroups;
        sourceData.rows.sort(function (a, b) {
          if (_this.order === 'asc') {
            return a.locationName.localeCompare(b.locationName);
          } else {
            return b.locationName.localeCompare(a.locationName);
          }
        });
        var headerRow = [];
        tableData.push(headerRow);

        // top-left "Standort" cell
        headerRow.push({
          cellId: 'top-left-cell',
          isTopLeftCell: true,
          isCategoryCell: false,
          isLocationCell: false,
          isValueCell: false
        });

        // matrix category cells
        sourceData.categories.forEach(function (category) {
          headerRow.push({
            cellId: 'category-' + category.id,
            isTopLeftCell: false,
            isCategoryCell: true,
            isLocationCell: false,
            isValueCell: false,
            categoryId: category.id,
            categoryName: category.name
          });
        });

        // value & location cells
        sourceData.rows.forEach(function (row) {
          var dataRow = [];
          dataRow.push({
            cellId: 'location-' + row.locationId,
            isTopLeftCell: false,
            isCategoryCell: false,
            isLocationCell: true,
            isValueCell: false,
            locationId: row.locationId,
            locationName: row.locationName
          });
          var index = 0;
          row.cells.forEach(function (cell) {
            dataRow.push({
              cellId: 'value-' + row.locationId + '-' + index,
              isTopLeftCell: false,
              isCategoryCell: false,
              isLocationCell: false,
              isValueCell: true,
              averageIndex: cell !== null ? cell.averageIndex : null,
              satisfactionRate: cell !== null ? cell.satisfactionRate : null,
              counter: cell !== null ? cell.counter : null
            });
            index++;
          });
          tableData.push(dataRow);
        });
      }
      return tableData;
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    noData: function noData() {
      return Object.keys(this.matrixLocations).length === 0 && Object.keys(this.matrixLocationGroups).length === 0;
    }
  },
  methods: {
    onChangeGrouping: function onChangeGrouping(value) {
      this.groupBy = value;
    },
    changeOrder: function changeOrder() {
      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }
    },
    exportMatrix: function exportMatrix() {
      var _QueryService$get;
      var filter = (_QueryService$get = QueryService.get('filter')) !== null && _QueryService$get !== void 0 ? _QueryService$get : {};
      var locationFilter = QueryUtils.getLocationFilter(filter.location);
      this.$router.push({
        path: '/raw-export',
        query: {
          type: 'matrix',
          matrixExportAggregateByLocationGroup: this.groupBy === 'locationGroup' ? 'true' : 'false',
          start: moment(this.dateRange[0]).format('YYYY-MM-DD'),
          end: moment(this.dateRange[1]).format('YYYY-MM-DD'),
          locationFilter: locationFilter
        }
      });
    },
    getColorNameByIndex: getColorNameByIndex,
    getColorNameBySatisfactionRate: getColorNameBySatisfactionRate,
    routeToDashboardWithLocation: RoutingUtils.routeToDashboardWithLocation,
    routeToDashboardWithLocationGroupByLocationGroupId: RoutingUtils.routeToDashboardWithLocationGroupByLocationGroupId
  }
};