var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "42px",
        height: "41px",
        viewBox: "0 0 42 41",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("defs", [
        _c("polygon", {
          attrs: {
            id: "path-1",
            points:
              "0.126994136 40.9747362 40.8828756 40.9747362 40.8828756 0.232112568 0.126994136 0.232112568",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Smileys",
                transform: "translate(-184.000000, 0.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "veryhappy",
                    transform: "translate(184.217943, 0.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Group-3",
                        transform: "translate(-0.000000, 0.000698)",
                      },
                    },
                    [
                      _c("mask", { attrs: { id: "mask-2", fill: "white" } }, [
                        _c("use", { attrs: { "xlink:href": "#path-1" } }),
                      ]),
                      _vm._v(" "),
                      _c("g", { attrs: { id: "Clip-2" } }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M20.9622552,38.3033231 C20.8071488,38.3075137 20.6504122,38.3091433 20.4957716,38.3091433 C10.82235,38.3091433 3.04909807,30.7327749 2.79920445,21.0606419 C2.67693591,16.3329489 4.40383356,11.8406278 7.66153342,8.41132178 C10.9196991,4.98178292 15.3183391,3.0259377 20.0474536,2.90371193 C20.2027929,2.89975414 20.3595295,2.89765884 20.5141701,2.89765884 C30.1873588,2.89765884 37.9606107,10.4742601 38.2109702,20.1463931 C38.4631927,29.9058303 30.7253404,38.0509559 20.9622552,38.3033231 M40.8761916,20.0777138 C40.7380863,14.7309764 38.5507603,9.73298983 34.7173502,6.00428884 C30.7635348,2.15871673 25.5169332,0.0915878478 19.9785174,0.239422833 C14.5374507,0.379807867 9.47646443,2.630159 5.72806014,6.57583985 C1.98012164,10.5215207 -0.00668401386,15.6899249 0.133983037,21.1293211 C0.271855379,26.4760586 2.45918145,31.4738123 6.29259149,35.202979 C10.1190148,38.9249284 15.1634656,40.9748293 20.4957716,40.9748293 C20.6732357,40.9748293 20.8527958,40.9725012 21.0311914,40.9673793 C32.2638281,40.6772968 41.1663756,31.3061884 40.8761916,20.0777138",
                          id: "Fill-1",
                          fill: "#333333",
                          mask: "url(#mask-2)",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M17.0387857,15.9290451 C17.0005913,14.4574468 15.7765085,13.2952542 14.3043952,13.3332024 C12.8322819,13.3711506 11.6699156,14.5950381 11.707877,16.0666364 C11.7458385,17.5384675 12.9696884,18.7004272 14.4425004,18.6622462 C15.9143808,18.624298 17.0767471,17.4006434 17.0387857,15.9290451",
                      id: "Fill-4",
                      fill: "#333333",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M26.5678139,13.3333421 C25.0957006,13.3712903 23.9331014,14.5949449 23.9715287,16.0663105 C24.0092573,17.5381416 25.233573,18.7003341 26.7054534,18.6621531 C28.1775667,18.6242049 29.339933,17.4005503 29.3019715,15.928952 C29.2642429,14.4573536 28.0396943,13.2951611 26.5678139,13.3333421",
                      id: "Fill-6",
                      fill: "#333333",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M20.5048777,32.3859891 C24.1151768,32.3859891 27.4352918,30.731867 29.6140008,27.8475715 C30.0581268,27.2601893 29.9412148,26.4248634 29.3533942,25.9808929 C28.7662723,25.5373879 27.9301883,25.6537934 27.4862952,26.2414084 C25.8159905,28.4524145 23.2714073,29.7207688 20.5048777,29.7207688 C17.7388138,29.7207688 15.1937649,28.4524145 13.5234602,26.2414084 C13.2614561,25.8942872 12.8627442,25.7115305 12.4586758,25.7115305 C12.1789719,25.7115305 11.8964734,25.7995331 11.6563612,25.9808929 C11.0685406,26.4248634 10.9520944,27.2601893 11.3957546,27.8475715 C13.5744636,30.731867 16.8950444,32.3859891 20.5048777,32.3859891",
                      id: "Fill-8",
                      fill: "#333333",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }