var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items
    ? _c(
        "span",
        [
          _vm.items.length === 1
            ? _c("span", [_vm._v(_vm._s(_vm.items[0]))])
            : _vm.items.length > 1
            ? _c(
                "b-badge",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.top.d200",
                      modifiers: { hover: true, top: true, d200: true },
                    },
                  ],
                  attrs: {
                    pill: "",
                    variant: "secondary",
                    title: _vm.items.join(", "),
                  },
                },
                [
                  _vm._v(
                    "\n    " +
                      _vm._s(
                        _vm.$t(_vm.labelTranslationKey, {
                          count: _vm.items.length,
                        })
                      ) +
                      "\n  "
                  ),
                ]
              )
            : _c("span", [_vm._v("—")]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }