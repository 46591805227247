//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChangeUserData from '@/components/user-profile/ChangeUserData';
import ChangePassword from '@/components/user-profile/ChangePassword';
import EditNotifications from '@/components/user-profile/EditNotifications';
export default {
  name: 'UserProfile',
  components: {
    ChangeUserData: ChangeUserData,
    ChangePassword: ChangePassword,
    EditNotifications: EditNotifications
  }
};