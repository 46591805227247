var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-view",
    { staticClass: "password-recovery" },
    [
      _c("template", { slot: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("passwordRecovery")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _c("alert", {
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              message: _vm.$t("error.message"),
              title: _vm.$t("error.title"),
              show: _vm.errorOccurred && !_vm.submitting,
            },
          }),
          _vm._v(" "),
          _c("alert", {
            attrs: {
              icon: ["fas", "check"],
              type: "success",
              message: _vm.$t("success.message"),
              title: _vm.$t("success.title"),
              show: _vm.finished && !_vm.submitting,
              closeable: false,
            },
          }),
          _vm._v(" "),
          !_vm.finished
            ? _c(
                "div",
                [
                  _c(
                    "b-form-fieldset",
                    { attrs: { label: _vm.$t("email") } },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          placeholder: _vm.$t("placeholder.emailAddress"),
                          disabled: _vm.submitting,
                          autofocus: "",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submit($event)
                          },
                        },
                        model: {
                          value: _vm.inputEmail,
                          callback: function ($$v) {
                            _vm.inputEmail = $$v
                          },
                          expression: "inputEmail",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "button-row" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            block: true,
                            disabled: _vm.submitting,
                          },
                          on: { click: _vm.submit },
                        },
                        [
                          _vm.submitting
                            ? _c("span", [_vm._v(_vm._s(_vm.$t("loading")))])
                            : _c(
                                "span",
                                [
                                  _c("font-awesome", {
                                    attrs: {
                                      icon: ["far", "angle-double-right"],
                                    },
                                  }),
                                  _vm._v(
                                    "\n            " + _vm._s(_vm.$t("submit"))
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "btn-uppercase",
                          attrs: { to: { name: "login" } },
                        },
                        [
                          _c("font-awesome", {
                            attrs: { icon: ["far", "angle-left"] },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("toLogin")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }