var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-management-preview-text-input-question" },
    [
      _c(
        "div",
        {
          staticClass: "fake-text-input",
          class: {
            "is-textarea": _vm.isTextArea,
            "is-full-width": _vm.isFullWidth,
          },
        },
        [
          _c("div", { staticClass: "placeholder overflow-ellipsis" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.lasPreviewTranslation(_vm.question.placeholder)) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _vm.hasAlphaNumericIcon
            ? _c("SurveyManagementQuestionTypeAlphaNumericIcon", {
                staticClass: "question-type-icon",
                attrs: {
                  type:
                    _vm.questionType === "NUMBER_QUESTION"
                      ? "numeric"
                      : "alpha",
                },
              })
            : _vm.questionType === "DATE_QUESTION"
            ? _c("font-awesome", {
                staticClass: "question-type-icon calendar-icon",
                attrs: { icon: ["far", "calendar-alt"] },
              })
            : _vm.questionType === "EMAIL_QUESTION"
            ? _c("div", { staticClass: "question-type-icon email-icon" })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.question.contactRequestEnabled === true &&
      _vm.lasPreviewTranslation(_vm.question.contactRequestText)
        ? _c("div", { staticClass: "contact-request" }, [
            _c("div", { staticClass: "checkbox" }),
            _vm._v(" "),
            _c("div", { staticClass: "contact-request-text" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.lasPreviewTranslation(_vm.question.contactRequestText)
                  ) +
                  "\n    "
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }