import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import ValidationErrorList from '@/components/shared/ValidationErrorList';
import { LocationAdministration } from '@/services/ResourceService';
import ValidationHelperService from '@/services/ValidationHelperService';
export default {
  name: 'LocationAdministrationEditor',
  components: {
    Alert: Alert,
    ValidationErrorList: ValidationErrorList
  },
  data: function data() {
    return {
      isSubmitting: false,
      successfullySaved: false,
      validationErrorOccurred: false,
      serverErrorOccurred: false,
      locationGroupName: null,
      validationErrors: null
    };
  },
  methods: {
    submitForm: function submitForm() {
      this.isSubmitting = true;
      this.validationErrorOccurred = false;
      this.serverErrorOccurred = false;
      this.successfullySaved = false;
      this.validationErrors = null;
      if (typeof this.locationGroupName === 'string' && this.locationGroupName.trim() === '') {
        this.locationGroupName = null;
      }
      var locationGroupData = {
        name: this.locationGroupName
      };
      LocationAdministration.createLocationGroup(this.$store.state.general.companyId, locationGroupData).then(this.onSubmissionSuccess, this.onSubmissionError);
    },
    onSubmissionSuccess: function onSubmissionSuccess(response) {
      this.isSubmitting = false;
      this.successfullySaved = true;
      this.closeModal(response.data);
    },
    onSubmissionError: function onSubmissionError(error) {
      var data = error.response.data;
      var errorCode = data.status.code;
      var payload = data.payload;
      if (errorCode === 400 && payload !== null && payload.length > 0) {
        this.validationErrorOccurred = true;
        this.validationErrors = ValidationHelperService.parseValidationErrorsWithTranslations(payload, this);
      } else {
        this.serverErrorOccurred = true;
      }
      this.isSubmitting = false;
    },
    closeModal: function closeModal(locationGroupId) {
      this.$vuedals.close({
        locationGroupId: locationGroupId
      });
    },
    getFieldState: function getFieldState(fieldName) {
      return ValidationHelperService.getFieldState(fieldName, this.validationErrors);
    },
    getFieldErrors: function getFieldErrors(fieldName) {
      return ValidationHelperService.getFieldErrors(fieldName, this.validationErrors);
    }
  }
};