//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import ModalIconButton from '@/components/shared/ModalIconButton';
export default {
  name: 'WebSourceEmbedQRCodeModal',
  components: {
    Alert: Alert,
    ModalIconButton: ModalIconButton
  },
  props: {
    webSourceLink: {
      type: String,
      required: true
    }
  },
  computed: {
    embedQRCodeHTML: function embedQRCodeHTML() {
      var encodedURI = encodeURIComponent(this.webSourceLink);
      return "<img src='https://chart.googleapis.com/chart?cht=qr&chl=".concat(encodedURI, "&choe=UTF-8&chld=L|2&chs=250x250' alt=''>");
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.$vuedals.close();
    }
  }
};