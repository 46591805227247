//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DashedBox'
};