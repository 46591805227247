var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-md-5" }, [
          _c("h1", { staticClass: "site-title" }, [
            _vm._v(_vm._s(_vm.$t("siteTitle"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-7" }, [
          _c("span", { staticClass: "float-right" }, [
            _c(
              "span",
              [
                _c("opn-date-range-picker", {
                  attrs: {
                    "is-loading": _vm.isLoading,
                    value: _vm.filter.dateRange,
                  },
                  on: { "change-daterange": _vm.dateRangeChanged },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("location-group-select", {
              attrs: {
                "is-loading": _vm.isLoading,
                "pre-selected-ids": _vm.filter.location,
                "show-archived": true,
              },
              on: { "change-locations": _vm.locationsChanged },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            !_vm.currentUserIsSuperAdmin
              ? _c("search-filter-selector", {
                  staticClass: "mt-n3",
                  attrs: {
                    "is-loading": _vm.isLoading,
                    "pre-selected-search-filter-id":
                      _vm.filter.location.selectedSearchFilterId,
                  },
                  on: { "change-locations": _vm.locationsChanged },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("clear-filter", {
              attrs: {
                "is-loading": _vm.isLoading,
                "is-survey-analysis": true,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.surveyListIsLoading
        ? [_vm._m(0)]
        : !_vm.surveyListIsLoading && _vm.noData
        ? [_vm.noData ? _c("empty-state") : _vm._e()]
        : _c(
            "div",
            [
              _vm.selectedSurveyId !== null
                ? _c("survey-select", {
                    attrs: {
                      "is-loading": _vm.surveyIsLoading,
                      list: _vm.surveyList,
                      "selected-survey-id": _vm.selectedSurveyId,
                    },
                    on: {
                      "change-survey-id": function ($event) {
                        return _vm.changeSurveyId($event)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("survey", {
                staticClass: "survey",
                attrs: {
                  "is-loading": _vm.surveyIsLoading,
                  data: _vm.survey,
                  "query-parameters": _vm.queryParameters,
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _vm.$store.getters["general/hasIndexCalculation"] &&
      !_vm.surveyListIsLoading &&
      !_vm.noData
        ? _c("color-legend", {
            staticClass: "color-legend-item",
            attrs: {
              "is-loading": _vm.surveyIsLoading,
              "company-properties": _vm.$store.state.general.companyProperties,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center pos-rel" }, [
      _c("div", { staticClass: "opn-spinner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }