var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "41px",
        height: "41px",
        viewBox: "0 0 41 41",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("defs", [
        _c("polygon", {
          attrs: {
            id: "path-1",
            points:
              "0.152006788 40.9747362 40.9081016 40.9747362 40.9081016 0.232216426 0.152006788 0.232216426",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Smileys",
                transform: "translate(-139.000000, 0.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "happy",
                    transform: "translate(139.036804, 0.000000)",
                  },
                },
                [
                  _c("g", { attrs: { id: "Group-12" } }, [
                    _c("mask", { attrs: { id: "mask-2", fill: "white" } }, [
                      _c("use", { attrs: { "xlink:href": "#path-1" } }),
                    ]),
                    _vm._v(" "),
                    _c("g", { attrs: { id: "Clip-11" } }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M20.9872679,38.3033231 C20.8321615,38.3075137 20.6754249,38.3091433 20.5207843,38.3091433 C10.8473627,38.3091433 3.07411072,30.7327749 2.8242171,21.0606419 C2.70194856,16.3331817 4.42884622,11.8406278 7.68654607,8.41132178 C10.9447117,4.98178292 15.3433518,3.0259377 20.0724663,2.90371193 C20.2278055,2.89998695 20.3845422,2.89789165 20.5391828,2.89789165 C30.2123715,2.89789165 37.9856234,10.4744929 38.2359828,20.1463931 C38.4882053,29.9058303 30.7503531,38.0509559 20.9872679,38.3033231 M40.9012042,20.0777138 C40.763099,14.7309764 38.5757729,9.73298983 34.7423629,6.00452165 C30.7885474,2.15871673 25.5419459,0.0915878478 20.0035301,0.239655644 C14.5624633,0.379807867 9.50147708,2.630159 5.75307279,6.57607266 C2.00513429,10.5215207 0.0183286373,15.6901577 0.158995689,21.1293211 C0.29686803,26.4762914 2.4841941,31.4740452 6.31760414,35.202979 C10.1440274,38.9249284 15.1884783,40.9748293 20.5207843,40.9748293 C20.6982483,40.9748293 20.8778084,40.9725012 21.056204,40.9673793 C32.2888407,40.6772968 41.1913882,31.3061884 40.9012042,20.0777138",
                        id: "Fill-10",
                        fill: "#333333",
                        mask: "url(#mask-2)",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M17.0637983,15.9291615 C17.0256039,14.457796 15.8015211,13.2953706 14.3294078,13.3333188 C12.8572945,13.3714998 11.6949282,14.5951545 11.7328897,16.0667528 C11.7708512,17.5383511 12.9947011,18.7005437 14.4675131,18.6623626 C15.9393935,18.6244145 17.1017598,17.4007598 17.0637983,15.9291615",
                      id: "Fill-13",
                      fill: "#333333",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M26.5928499,13.3334585 C25.1207366,13.3714067 23.9581374,14.5950613 23.9965646,16.0664269 C24.0342932,17.538258 25.2586089,18.7006833 26.7304893,18.6625023 C28.2026026,18.6243213 29.3649689,17.4006667 29.3270075,15.9290684 C29.2892789,14.45747 28.0647303,13.2955103 26.5928499,13.3334585",
                      id: "Fill-15",
                      fill: "#333333",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M12.7236841,30.3525247 C12.7236841,30.3525247 15.6299492,31.4835206 20.4915795,31.4835206 C25.673437,31.4835206 28.3363295,30.3525247 28.3363295,30.3525247 C29.1377125,30.0573204 29.6652139,29.7576926 29.6652139,29.024338 C29.6652139,28.2909833 29.0701736,27.6961512 28.3363295,27.6961512 C28.3363295,27.6961512 24.1938711,28.6578934 20.5302397,28.6285593 C17.0713207,28.6006219 12.7236841,27.6961512 12.7236841,27.6961512 C11.9900729,27.6961512 11.3950326,28.2909833 11.3950326,29.024338 C11.3950326,29.7576926 11.8745582,30.0573204 12.7236841,30.3525247",
                      id: "Fill-17",
                      fill: "#333333",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }