export default {
  scrollToTopOfElement: function scrollToTopOfElement(element) {
    var parent = findFirstScrollableParent(element);
    var scrollOffset = findYOffsetBetween(element, parent);
    if (parent === document.documentElement) {
      // Subtract the padding of the main content to avoid scrolling things behind the navbar
      scrollOffset -= document.getElementById('scroll-to-main-container-anchor').offsetTop;
    } else {
      scrollOffset -= parent.offsetTop;
    }

    // IE doesn't support smooth scrolling
    var smoothScrollSupported = ('scrollBehavior' in document.documentElement.style);
    if (smoothScrollSupported) {
      parent.scroll({
        top: scrollOffset,
        behavior: 'smooth'
      });
    } else {
      parent.scrollTop = scrollOffset;
    }
    if (parent !== document.documentElement) {
      // Scroll parent container into view
      this.scrollToTopOfElement(parent);
    }
  }
};
function findFirstScrollableParent(element) {
  var parent = element.parentElement;
  while (parent.parentElement && !isScrollable(parent.parentElement)) {
    parent = parent.parentElement;
  }
  if (isScrollable(parent.parentElement)) {
    return parent.parentElement;
  }

  // This will always return the HTML element
  return parent;
}
function findYOffsetBetween(child, parent) {
  var yOffset = 0;
  var element = child;
  while (element && element !== parent && parent.contains(element)) {
    yOffset += element.offsetTop;
    element = element.offsetParent;
  }
  return yOffset;
}
function isScrollable(element) {
  if (typeof element !== 'undefined' && element !== null && element.style !== null && element.scrollHeight !== null && element.clientHeight !== null) {
    var style = window.getComputedStyle(element);
    if (style.overflowY === 'scroll' || style.overflowY === 'auto') {
      if (element.scrollHeight > element.clientHeight) {
        return true;
      }
    }
  }
  return false;
}