//
//
//
//
//
//
//
//

export default {
  name: 'PasswordValidationErrorList',
  props: {
    errors: {
      type: Array,
      required: true
    }
  }
};