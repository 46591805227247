import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpnnLogo from '@/assets/opiniooon-logo-dark.svg';
export default {
  name: 'AuthView',
  computed: {
    isCustomDomain: function isCustomDomain() {
      return this.$store.getters['general/isCustomDomain'];
    },
    customDomain: function customDomain() {
      return this.$store.getters['general/customDomain'];
    },
    customDomainStyles: function customDomainStyles() {
      if (this.isCustomDomain) {
        var width = 200;
        return {
          width: "".concat(width, "px"),
          height: "".concat(width / this.customDomain.logoDarkSideRatio, "px")
        };
      }
      return {};
    },
    logoSrc: function logoSrc() {
      if (this.isCustomDomain) {
        return "data:".concat(this.customDomain.logoDarkContentType, ";base64,").concat(this.customDomain.logoDark);
      }
      return OpnnLogo;
    },
    opnnLogo: function opnnLogo() {
      return OpnnLogo;
    }
  }
};