import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Auth from '@/services/auth/Auth';
import Alert from '@/components/shared/Alert';
import AuthView from '@/components/authentication/AuthView';
export default {
  name: 'Login',
  components: {
    Alert: Alert,
    AuthView: AuthView
  },
  data: function data() {
    return {
      rememberMe: true,
      inputEmail: '',
      inputPassword: '',
      submitting: false,
      errorOccurred: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.submitting = true;
      this.errorOccurred = false;
      if (this.inputEmail === '' || this.inputPassword === '') {
        this.submitting = false;
        this.$nextTick(function () {
          // toggle watch listener of alert box (when error message is manually closed,
          // it won't be shown again, if errorOccurred is changed in the same tick)
          _this.errorOccurred = true;
        });
      } else {
        Auth.login({
          username: this.inputEmail,
          password: this.inputPassword,
          rememberMe: this.rememberMe
        }, false).then(function () {
          _this.submitting = false;
          _this.errorOccurred = false;
          _this.$router.push({
            path: _this.$route.query.redirect || '/dashboard'
          });
        }).catch(function () {
          _this.submitting = false;
          _this.errorOccurred = true;
        });
      }
    }
  }
};