import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SurveyManagementQuestionTypeAlphaNumericIcon from '@/components/survey-management/icons/SurveyManagementQuestionTypeAlphaNumericIcon';
export default {
  name: 'SurveyManagementPreviewTextInputQuestion',
  components: {
    SurveyManagementQuestionTypeAlphaNumericIcon: SurveyManagementQuestionTypeAlphaNumericIcon
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    questionType: {
      type: String,
      required: true
    }
  },
  computed: {
    currentLanguage: function currentLanguage() {
      return this.$store.state.surveyManagement.editPageState.data.currentLanguage;
    },
    hasAlphaNumericIcon: function hasAlphaNumericIcon() {
      return ['COMMENT_QUESTION', 'TEXT_QUESTION_LONG_TEXT', 'NUMBER_QUESTION'].includes(this.questionType);
    },
    isFullWidth: function isFullWidth() {
      return ['COMMENT_QUESTION', 'TEXT_QUESTION_LONG_TEXT', 'EMAIL_QUESTION'].includes(this.questionType);
    },
    isTextArea: function isTextArea() {
      return ['COMMENT_QUESTION', 'TEXT_QUESTION_LONG_TEXT'].includes(this.questionType);
    }
  },
  methods: {
    lasPreviewTranslation: function lasPreviewTranslation(las) {
      if (las === null || typeof las === 'undefined') {
        return null;
      }
      return las[this.currentLanguage];
    }
  }
};