import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import DataUtils from '@/services/utils/DataUtils';
import FormImageUploader from '@/components/shared/FormImageUploader';
import ValidationErrorList from '@/components/shared/ValidationErrorList';
import ValidationHelperService from '@/services/ValidationHelperService';
export default {
  name: 'LocationAdministrationForm',
  components: {
    FormImageUploader: FormImageUploader,
    ValidationErrorList: ValidationErrorList
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    foreignKeyValues: {
      type: Object,
      required: true
    },
    validationErrors: {
      type: Array,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      languagesShort: ['EN', 'DE', 'FR', 'IT', 'ES', 'PT', 'MK', 'SQ', 'TR'],
      customLogoBright: null,
      customLogoDark: null
    };
  },
  computed: {
    regions: function regions() {
      var regions = [{
        value: 'NORDWESTSCHWEIZ',
        text: this.$t('regions.NORDWESTSCHWEIZ')
      }, {
        value: 'GENFERSEEREGION',
        text: this.$t('regions.GENFERSEEREGION')
      }, {
        value: 'ESPACE_MITTELLAND',
        text: this.$t('regions.ESPACE_MITTELLAND')
      }, {
        value: 'ZUERICH',
        text: this.$t('regions.ZUERICH')
      }, {
        value: 'OSTSCHWEIZ',
        text: this.$t('regions.OSTSCHWEIZ')
      }, {
        value: 'ZENTRALSCHWEIZ',
        text: this.$t('regions.ZENTRALSCHWEIZ')
      }, {
        value: 'TESSIN',
        text: this.$t('regions.TESSIN')
      }];
      return _.sortBy(regions, ['text']);
    },
    locationGroups: function locationGroups() {
      var locationGroups = [];
      this.foreignKeyValues.locationGroups.forEach(function (locGrp) {
        locationGroups.push({
          value: locGrp.id,
          text: locGrp.name
        });
      });
      return _.sortBy(locationGroups, ['text']);
    },
    surveys: function surveys() {
      var surveys = [{
        value: null,
        text: this.$t('nullSurvey')
      }];
      this.foreignKeyValues.surveys.forEach(function (survey) {
        surveys.push({
          value: survey.id,
          text: survey.name
        });
      });
      return _.sortBy(surveys, ['text']);
    },
    selectedSurvey: function selectedSurvey() {
      var _this = this;
      if (this.formData.surveyId === null) {
        return null;
      }
      var selectedSurvey = this.foreignKeyValues.surveys.find(function (survey) {
        return survey.id === _this.formData.surveyId;
      });
      if (typeof selectedSurvey === 'undefined') {
        return null;
      }
      return selectedSurvey;
    },
    languages: function languages() {
      var _this2 = this;
      var languages = [];
      this.languagesShort.forEach(function (language) {
        var value = language;
        var text = _this2.$t('languages.' + language.toLowerCase());
        var disabled = !_this2.isLanguageEnabledOnSelectedSurvey(language);
        languages.push({
          value: value,
          text: text,
          disabled: disabled
        });
      });
      return _.sortBy(languages, ['text']);
    },
    parsedValidationErrors: function parsedValidationErrors() {
      var validationErrorsCopy = _.cloneDeep(this.validationErrors);
      if (validationErrorsCopy != null) {
        validationErrorsCopy.forEach(function (error) {
          if (error.fields.includes('CUSTOM_LOGO_DARK') || error.fields.includes('CUSTOM_LOGO_BRIGHT')) {
            if (error.errorType === 'FILE_TOO_LARGE') {
              error.errorPayload = DataUtils.formatNumberOfBytes(error.errorPayload);
            } else if (error.errorType === 'INVALID_FILE_TYPE') {
              error.errorPayload = error.errorPayload.join(', ');
            }
          }
        });
        return ValidationHelperService.parseValidationErrorsWithTranslations(validationErrorsCopy, this);
      }
      return null;
    }
  },
  methods: {
    isLanguageEnabledOnSelectedSurvey: function isLanguageEnabledOnSelectedSurvey(language) {
      if (this.selectedSurvey === null) {
        // Allow all languages if no survey is selected
        return true;
      }
      return this.selectedSurvey.enabledLanguages.includes(language);
    },
    getFieldState: function getFieldState(fieldName) {
      return ValidationHelperService.getFieldState(fieldName, this.parsedValidationErrors);
    },
    getFieldErrors: function getFieldErrors(fieldName) {
      return ValidationHelperService.getFieldErrors(fieldName, this.parsedValidationErrors);
    }
  }
};