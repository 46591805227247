var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "date-input",
      class: {
        optional: _vm.optional,
        disabled: _vm.disabled,
        invalid: _vm.invalid,
      },
    },
    [
      _c("input", {
        ref: "picker",
        staticClass: "form-control",
        attrs: { placeholder: _vm.placeholder },
      }),
      _vm._v(" "),
      _vm.optional && _vm.value !== null && !_vm.disabled
        ? _c(
            "span",
            {
              staticClass: "clear-button",
              on: {
                click: function ($event) {
                  return _vm.$emit("input", null)
                },
              },
            },
            [_c("font-awesome", { attrs: { icon: ["fas", "ban"] } })],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }