//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comparison } from '@/services/ResourceService';
import Panel from '@/components/shared/Panel';
import moment from 'moment';
import { getColorNameByIndex } from '@/services/ColorService';
export default {
  name: 'SimpleQuickStatistics',
  components: {
    Panel: Panel
  },
  props: {
    parentConfig: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      isLoading: true,
      simpleQuickStatistics: {}
    };
  },
  computed: {
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    colorClass: function colorClass() {
      if (!this.isLoading && this.hasIndexCalculation) {
        return getColorNameByIndex(this.simpleQuickStatistics.averageIndex, this.$store.state.general.companyProperties);
      }
      return null;
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      if (!this.parentConfig.dirty) {
        Comparison.getSimpleQuickStatistics(this.$store.state.general.companyId, this.parentConfig.locations.queryLocations, this.parentConfig.surveyId, this.parentConfig.choiceAttributeIds, moment(this.parentConfig.startInSeconds).format('DD.MM.YYYY'), moment(this.parentConfig.endInSeconds).format('DD.MM.YYYY')).then(function (response) {
          _this.simpleQuickStatistics = response.data;
          _this.isLoading = false;
        }, function () {
          _this.survey = {};
          _this.isLoading = false;
        });
      }
    }
  },
  watch: {
    parentConfig: {
      deep: true,
      handler: function handler() {
        this.loadData();
      }
    }
  }
};