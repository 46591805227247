import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getColorsAndIcons } from '@/services/ColorService';
import SurveyManagementPreviewRatingReasons from '@/components/survey-management/edit-question/question-preview/SurveyManagementPreviewRatingReasons';
export default {
  name: 'SurveyManagementPreviewRatingSlider',
  components: {
    SurveyManagementPreviewRatingReasons: SurveyManagementPreviewRatingReasons
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    questionCategoryName: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    hasAtLeastOneScaleLabel: function hasAtLeastOneScaleLabel() {
      return this.question.scaleLabelMin || this.question.scaleLabelMax;
    },
    isLeftHighRightLow: function isLeftHighRightLow() {
      return this.question.scaleOrder === 'LEFT_HIGH_RIGHT_LOW';
    },
    sliderBullets: function sliderBullets() {
      var colorsAndIcons = getColorsAndIcons(this.question.scale);
      if (this.isLeftHighRightLow) {
        return colorsAndIcons.reverse();
      }
      return colorsAndIcons;
    },
    bulletCount: function bulletCount() {
      return this.sliderBullets.length;
    }
  },
  methods: {
    sliderBulletStyle: function sliderBulletStyle(index) {
      // This value should be the same as the .bullet class height and width!
      var bulletHeightAndWidth = '18px';
      var offset = "".concat(bulletHeightAndWidth, " / 2");
      if (index === 0) {
        offset = '0px';
      } else if (index === this.bulletCount - 1) {
        offset = bulletHeightAndWidth;
      }
      return {
        left: "calc(".concat(index / (this.bulletCount - 1) * 100, "% - ").concat(offset, ")")
      };
    },
    lasPreviewTranslation: function lasPreviewTranslation(las) {
      if (las === null || typeof las === 'undefined') {
        return null;
      }
      return las[this.$store.state.surveyManagement.editPageState.data.currentLanguage];
    }
  }
};