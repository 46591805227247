var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper", class: _vm.dynamicClass }, [
    _c(
      "button",
      {
        staticClass: "btn btn-sm btn-link clear-button",
        attrs: { disabled: _vm.isLoading || _vm.isLoadingInternally },
        on: { click: _vm.clearFilter },
      },
      [
        _c("font-awesome", { attrs: { icon: ["fas", "ban"] } }),
        _vm._v("\n    " + _vm._s(_vm.$t("clearFilter")) + "\n  "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }