import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4langEn from '@amcharts/amcharts4/lang/en';
import am4langDeCh from '@amcharts/amcharts4/lang/de_CH';
import am4langFrFr from '@amcharts/amcharts4/lang/fr_FR';
import am4langItIt from '@amcharts/amcharts4/lang/it_IT';
import moment from 'moment';
import colorVariables from '@/styles/_variables.scss';
import user from '@/assets/user.svg';
am4core.options.commercialLicense = true;
export default {
  name: 'ScatterDistributionChart',
  props: {
    distribution: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: function validator(item) {
        return ['date', 'number'].includes(item);
      }
    },
    isPdf: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  computed: {
    enhancedData: function enhancedData() {
      var _this = this;
      return Object.keys(this.distribution).sort(function (a, b) {
        if (_this.type === 'number') {
          return a - b;
        }
        return a.localeCompare(b);
      }).map(function (key) {
        if (_this.type === 'number') {
          return {
            key: Number(key),
            amount: _this.distribution[key],
            label: Number(key)
          };
        }
        return {
          key: moment(key).toDate(),
          amount: _this.distribution[key],
          label: moment(key).format('DD.MM.YYYY')
        };
      });
    }
  },
  methods: {
    createChart: function createChart(element, data, type, language, colorVariables, translatedStrings) {
      var isNumberChart = type === 'number';
      var maxKey = isNumberChart ? data.reduce(function (acc, cur) {
        return {
          key: Math.max(acc.key, cur.key)
        };
      }).key : 0;
      var maxAmount = data.reduce(function (acc, cur) {
        return {
          amount: Math.max(acc.amount, cur.amount)
        };
      }).amount;
      var chart = am4core.create(element, am4charts.XYChart);
      chart.paddingBottom = this.isPdf ? -15 : -5;
      chart.paddingTop = this.isPdf ? 0 : 20;
      chart.paddingRight = this.isPdf ? 0 : 20;
      chart.paddingLeft = this.isPdf ? 0 : 20;
      chart.tooltip.numberFormatter.numberFormat = '#,###.#';
      chart.data = data;
      chart.language.locale = function () {
        if (language === 'de') {
          return am4langDeCh;
        } else if (language === 'fr') {
          return am4langFrFr;
        } else if (language === 'it') {
          return am4langItIt;
        } else {
          return am4langEn;
        }
      }();
      var xAxis = chart.xAxes.push(isNumberChart ? new am4charts.ValueAxis() : new am4charts.DateAxis());
      xAxis.strictMinMax = true;
      xAxis.maxPrecision = 0;
      xAxis.renderer.minGridDistance = 25;
      xAxis.renderer.baseGrid.disabled = true;
      xAxis.renderer.grid.template.stroke = am4core.color('#e7e7e7');
      xAxis.renderer.grid.template.strokeOpacity = 1;
      xAxis.renderer.grid.template.strokeWidth = 1;
      xAxis.renderer.labels.template.fill = am4core.color('#666');
      xAxis.renderer.labels.template.fontSize = 10;
      xAxis.renderer.labels.template.dy = -5;
      if (!isNumberChart || maxKey > 800) {
        xAxis.renderer.labels.template.dx = 0;
        xAxis.renderer.labels.template.dy = -10;
        xAxis.renderer.labels.template.horizontalCenter = 'right';
        xAxis.renderer.labels.template.verticalCenter = 'top';
        xAxis.renderer.labels.template.rotation = -40;
      }

      // add 2% padding to the axis values
      var axisPaddingLeftAndRightInPercent = 0.02;
      if (isNumberChart) {
        var minNumberKey = data.reduce(function (acc, cur) {
          return {
            key: Math.min(acc.key, cur.key)
          };
        }).key;
        var maxNumberKey = data.reduce(function (acc, cur) {
          return {
            key: Math.max(acc.key, cur.key)
          };
        }).key;
        var differenceNumber = maxNumberKey - minNumberKey;
        xAxis.min = minNumberKey - axisPaddingLeftAndRightInPercent * differenceNumber;
        xAxis.max = maxNumberKey + axisPaddingLeftAndRightInPercent * differenceNumber;
      } else {
        var minDateKey = moment.min(data.map(function (item) {
          return moment(item.key);
        }));
        var maxDateKey = moment.max(data.map(function (item) {
          return moment(item.key);
        }));
        var differenceDate = moment.duration(maxDateKey.diff(minDateKey)).asDays();
        xAxis.min = minDateKey.subtract(differenceDate * axisPaddingLeftAndRightInPercent, 'days').toDate().getTime();
        xAxis.max = maxDateKey.add(differenceDate * axisPaddingLeftAndRightInPercent, 'days').toDate().getTime();
      }
      var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.dx = -6;
      yAxis.dy = -1;
      yAxis.min = maxAmount === 1 ? 0.95 : 0.8;
      yAxis.max = maxAmount * 1.05;
      yAxis.strictMinMax = true;
      yAxis.maxPrecision = 0;
      yAxis.renderer.minGridDistance = 15;
      yAxis.renderer.baseGrid.disabled = true;
      yAxis.renderer.grid.template.stroke = am4core.color('#e7e7e7');
      yAxis.renderer.grid.template.strokeOpacity = 1;
      yAxis.renderer.grid.template.strokeWidth = 1;
      yAxis.renderer.labels.template.fontSize = 10;
      var userIcon = new am4core.Image();
      userIcon.dx = -5;
      userIcon.dy = -1.7;
      userIcon.href = user;
      userIcon.width = 6.5;
      userIcon.horizontalCenter = 'middle';
      userIcon.verticalCenter = 'middle';
      userIcon.opacity = 0.9;
      yAxis.dataItems.template.bullet = userIcon;
      var series = chart.series.push(new am4charts.LineSeries());
      if (isNumberChart) {
        series.dataFields.valueX = 'key';
      } else {
        series.dataFields.dateX = 'key';
      }
      series.dataFields.valueY = 'amount';
      series.strokeWidth = 0;
      series.tooltip.getFillFromObject = false;
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.background.fill = am4core.color('#333');
      series.tooltip.background.strokeWidth = 0;
      series.tooltip.background.cornerRadius = 3;
      series.tooltip.background.pointerLength = 4;
      series.tooltip.fontSize = 10;
      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.strokeWidth = 0;
      bullet.circle.radius = 3.5;
      bullet.tooltipHTML = "\n        <div class=\"html-tooltip\">\n          <div class=\"answer\">".concat(translatedStrings.answer, " &laquo;{label}&raquo;</div>\n          <div class=\"amount\"><span>{amount}</span> <img class=\"user-icon\" src=\"").concat(user, "\" /></div>\n        </div>");
      series.heatRules.push({
        target: bullet.circle,
        min: am4core.color('#5DBFE2'),
        max: am4core.color('#4886A5'),
        property: 'fill',
        dataField: 'valueY'
      });
      return chart;
    },
    initChart: function initChart() {
      this.chart = this.createChart(this.$refs.chart, this.enhancedData, this.type, this.$store.state.general.language, colorVariables, {
        answer: this.$t('answer')
      });
    },
    resetChart: function resetChart() {
      if (this.chart !== null) {
        this.chart.dispose();
        this.chart = null;
      }
    }
  },
  mounted: function mounted() {
    this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    this.resetChart();
  },
  watch: {
    data: {
      deep: true,
      handler: function handler(newDistribution, oldDistribution) {
        if (JSON.stringify(newDistribution) !== JSON.stringify(oldDistribution)) {
          this.resetChart();
          this.initChart();
        }
      }
    }
  }
};