var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "error-list" },
    _vm._l(_vm.errors, function (e) {
      return _c("li", { key: e.errorEnum }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t("errors." + e.errorType, { payload: e.errorPayload })
            ) +
            "\n  "
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }