var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "41px",
        height: "41px",
        viewBox: "0 0 41 41",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("defs", [
        _c("polygon", {
          attrs: {
            id: "path-1",
            points:
              "40.7559529 40.9747362 0 40.9747362 0 0.232216426 40.7559529 0.232216426",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("g", { attrs: { id: "Smileys" } }, [
            _c("g", { attrs: { id: "angry" } }, [
              _c("g", { attrs: { id: "Group-30" } }, [
                _c("mask", { attrs: { id: "mask-2", fill: "white" } }, [
                  _c("use", { attrs: { "xlink:href": "#path-1" } }),
                ]),
                _vm._v(" "),
                _c("g", { attrs: { id: "Clip-29" } }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M20.8351192,38.3033231 C20.6800128,38.3075137 20.5232762,38.3091433 20.3686356,38.3091433 C10.695214,38.3091433 2.92196207,30.7327749 2.67206845,21.0606419 C2.54979991,16.3331817 4.27669757,11.8406278 7.53439742,8.41132178 C10.7925631,4.98201573 15.1912031,3.0259377 19.9203176,2.90394474 C20.0756569,2.89998695 20.2323935,2.89789165 20.3870341,2.89789165 C30.0602228,2.89789165 37.8334747,10.4744929 38.0838342,20.1463931 C38.3360567,29.9058303 30.5982044,38.0509559 20.8351192,38.3033231 M40.7490556,20.0777138 C40.6109503,14.7309764 38.4236243,9.73298983 34.5902142,6.00452165 C30.6363988,2.15871673 25.3897972,0.0915878478 19.8513814,0.239655644 C14.4103147,0.379807867 9.34932843,2.63039181 5.60092414,6.57607266 C1.85298564,10.5215207 -0.133820013,15.6901577 0.00684703859,21.1293211 C0.14471938,26.4762914 2.33204545,31.4740452 6.16545549,35.202979 C9.99187876,38.9249284 15.0363296,40.9748293 20.3686356,40.9748293 C20.5460997,40.9748293 20.7256598,40.9725012 20.9040554,40.9673793 C32.1366921,40.6772968 41.0392396,31.3061884 40.7490556,20.0777138",
                    id: "Fill-28",
                    fill: "#333333",
                    mask: "url(#mask-2)",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M27.318799,24.9567107 C25.8674131,23.6152537 23.7217748,23.9600468 22.7149807,25.6774936 C22.4408662,26.1484702 22.279006,26.6878933 22.0933907,27.205665 C21.9089398,27.7236695 21.7922607,28.2684472 21.5887127,28.7776049 C21.0796097,30.0517795 20.5465189,30.1530523 19.6049346,29.1677961 C19.3201071,28.8690996 19.0911738,28.517555 18.8051819,28.2193241 C18.5196557,27.9236541 18.226677,27.6144811 17.8817632,27.4007606 C16.4806821,26.5319099 15.0956706,27.0624862 14.6541065,28.6348917 C14.473382,29.2814079 14.3653199,29.9493426 14.2192963,30.6314789 C12.5534164,29.6092058 12.0513003,29.6492492 11.1835429,30.8691789 C11.9169213,31.8681709 12.8454636,32.5903506 14.1466338,32.5330791 C16.0996701,32.445775 16.3649346,30.8351885 16.7168351,29.1768757 C17.381976,29.8615729 17.8542819,30.4200865 18.3997161,30.8957193 C19.7111336,32.0406839 21.5551762,31.9768936 22.714282,30.6659349 C23.2476057,30.0636528 23.6204666,29.2909531 23.9474476,28.5429314 C24.3133217,27.7090024 24.5427207,26.8173362 24.8706333,25.8344082 C25.9163205,26.4967555 26.3490347,27.3525687 26.7081549,28.2509864 C26.9135661,28.7622393 27.0390951,29.3060858 27.2463694,29.8161747 C27.4832211,30.405885 28.0174764,30.5923666 28.7317576,30.3900539 C29.3845552,30.2045035 29.6912746,29.8247887 29.530113,29.1696586 C29.1439773,27.5905016 28.5438134,26.088405 27.318799,24.9567107",
                  id: "Fill-31",
                  fill: "#333333",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M18.8541043,15.8911902 C18.7948057,13.6042945 16.892551,11.7983716 14.6045614,11.8573561 C12.3171589,11.9163406 10.5108977,13.8179298 10.5699028,16.105119 C10.628908,18.3917214 12.530869,20.1979376 14.8194457,20.1383663 C17.1068482,20.0796752 18.9131095,18.178086 18.8541043,15.8911902",
                  id: "Fill-33",
                  fill: "#333333",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M26.9144278,13.6437956 C25.6139562,13.6775532 24.5869005,14.7587275 24.6206699,16.0585113 C24.6539735,17.3587607 25.7355261,18.3854572 27.0359977,18.3519325 C28.3367021,18.3181749 29.3637579,17.2374662 29.3302214,15.9372168 C29.2966849,14.6369673 28.2148993,13.610038 26.9144278,13.6437956",
                  id: "Fill-35",
                  fill: "#333333",
                },
              }),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }