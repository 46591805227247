import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import Store from '@/store/Store';
export default {
  initialize: function initialize() {
    Store.commit('general/setIE', false);
    var iePattern = /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/;
    if (window.navigator && window.navigator.userAgent) {
      var userAgent = window.navigator.userAgent;
      var element = document.querySelector('html');
      var classList = element.className.split(' ');
      if (iePattern.test(userAgent)) {
        Store.commit('general/setIE', true);
        if (classList.indexOf('ie') === -1) {
          element.className += ' ' + 'ie';
        }
      } else {
        // required for e.g. the routing transitions
        if (classList.indexOf('non-ie') === -1) {
          element.className += ' ' + 'non-ie';
        }
      }
    }
  }
};