import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.small.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4langEn from '@amcharts/amcharts4/lang/en';
import am4langDeCh from '@amcharts/amcharts4/lang/de_CH';
import am4langFrFr from '@amcharts/amcharts4/lang/fr_FR';
import am4langItIt from '@amcharts/amcharts4/lang/it_IT';
import moment from 'moment';
import colorVariables from '@/styles/_variables.scss';
import { getColorNameByIndex } from '@/services/ColorService';
import UserSvg from '@/assets/user.svg';
import ChangeOverTimeChartLegend from '@/components/shared/change-over-time/ChangeOverTimeChartLegend';
am4core.options.commercialLicense = true;
var CONSTANTS = {
  RELEVANCE_FILTER_MINIMUM: 5,
  MANY_DATAPOINTS_THRESHOLD: 85
};
export default {
  name: 'ChangeOverTimeChart',
  components: {
    ChangeOverTimeChartLegend: ChangeOverTimeChartLegend
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    useIndexCalculation: {
      type: Boolean,
      required: true
    },
    interval: {
      type: String,
      required: true,
      validator: function validator(item) {
        return ['HOURLY', 'DAILY', 'WEEKLY'].includes(item);
      }
    },
    hasRelevanceFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    heightInPixel: {
      type: Number,
      required: true
    },
    small: {
      type: Boolean,
      required: true
    },
    goalValue: {
      type: Number,
      required: false,
      default: null
    },
    showLegend: {
      type: Boolean,
      required: false,
      default: true
    },
    isPdf: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  computed: {
    enrichedData: function enrichedData() {
      var _this = this;
      return this.data.map(function (item) {
        var newItem = {};
        newItem.satisfaction = null;
        newItem.amount = item.feedbacks;
        newItem.date = Date.parse(item.date);
        newItem.color = null;
        if (_this.hasIndexCalculation) {
          newItem.satisfaction = _this.hasRelevanceFilter ? item.feedbacks >= CONSTANTS.RELEVANCE_FILTER_MINIMUM ? item.averageIndex : null : item.averageIndex;
          var colorName = item.averageIndex === null ? null : getColorNameByIndex(item.averageIndex, _this.$store.state.general.companyProperties);
          newItem.color = colorName === null ? null : am4core.color(colorVariables[colorName]);
        } else {
          newItem.satisfaction = _this.hasRelevanceFilter ? item.feedbacks >= CONSTANTS.RELEVANCE_FILTER_MINIMUM ? item.satisfactionRate : null : item.satisfactionRate;
          newItem.color = am4core.color(colorVariables['primaryColorDark']);
        }
        return newItem;
      });
    },
    watchingProperty: function watchingProperty() {
      return [this.data, this.interval, this.hasRelevanceFilter, Date.now()];
    },
    hasIndexCalculation: function hasIndexCalculation() {
      if (this.useIndexCalculation !== null) {
        return this.useIndexCalculation;
      }
      return this.$store.getters['general/hasIndexCalculation'];
    }
  },
  methods: {
    createChart: function createChart(element, data, interval, companyProperties, language, translatedStrings, hasIndexCalculation, CONSTANTS, colorVariables, small, showLegend) {
      var chart = am4core.create(element, am4charts.XYChart);
      if (small) {
        chart.paddingLeft = 0;
        chart.paddingRight = 12;
        chart.paddingBottom = 0;
      } else {
        chart.paddingRight = 25;
      }
      if (showLegend) {
        chart.paddingLeft += 20;
        chart.paddingRight += 20;
        if (!small) {
          chart.paddingRight += 5;
        }
      }
      chart.tooltip.numberFormatter.numberFormat = '#,###.#';
      chart.data = data;
      var chartConstants = {
        axisFontSize: 12,
        gridColor: am4core.color('#f2f2f2'),
        axisColor: am4core.color('#b5b5b5'),
        intervalConfig: {
          HOURLY: {
            timeUnit: 'hour',
            dateFormat: 'dd. MMMM yyyy - HH:mm'
          },
          DAILY: {
            timeUnit: 'day',
            dateFormat: 'dd. MMMM yyyy'
          },
          WEEKLY: {
            timeUnit: 'week',
            dateFormat: "[font-weight:bold]'W'w[/] '(".concat(translatedStrings.dateStartsAt, " 'FF dd. MMMM yyyy)")
          }
        }
      };
      var hasManyDataPoints = data.length > CONSTANTS.MANY_DATAPOINTS_THRESHOLD;
      chart.language.locale = function () {
        if (language === 'de') {
          return am4langDeCh;
        } else if (language === 'fr') {
          return am4langFrFr;
        } else if (language === 'it') {
          return am4langItIt;
        } else {
          return am4langEn;
        }
      }();
      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.startLocation = 0;
      dateAxis.endLocation = 1;
      dateAxis.baseInterval = {
        timeUnit: chartConstants.intervalConfig[interval].timeUnit,
        count: 1
      };
      dateAxis.tooltip.fontSize = 12;
      dateAxis.tooltip.dy = 3;
      dateAxis.tooltip.background.fill = am4core.color('#666');
      dateAxis.tooltip.background.fillOpacity = 0.8;
      dateAxis.tooltip.background.strokeWidth = 0;
      dateAxis.tooltip.background.cornerRadius = 3;
      dateAxis.tooltip.background.pointerLength = 4;
      dateAxis.tooltipDateFormat = chartConstants.intervalConfig[interval].dateFormat;
      dateAxis.renderer.labels.template.fontSize = chartConstants.axisFontSize;
      dateAxis.renderer.grid.template.stroke = chartConstants.gridColor;
      dateAxis.renderer.grid.template.strokeOpacity = 1;
      dateAxis.renderer.grid.template.strokeWidth = 1;
      var amountAxis = chart.yAxes.push(new am4charts.ValueAxis());
      amountAxis.min = 0;
      // keeps the bars below a certain value, that it does not crush with the other chart series:
      amountAxis.max = data.map(function (item) {
        return item.amount;
      }).reduce(function (previous, current) {
        return Math.max(previous, current);
      }) * 1.4;
      amountAxis.maxPrecision = 0;
      amountAxis.cursorTooltipEnabled = false;
      amountAxis.renderer.opposite = true;
      amountAxis.renderer.grid.template.disabled = true;
      amountAxis.renderer.ticks.template.disabled = false;
      amountAxis.renderer.ticks.template.strokeOpacity = 1;
      amountAxis.renderer.ticks.template.stroke = chartConstants.axisColor;
      amountAxis.renderer.ticks.template.strokeWidth = 1;
      amountAxis.renderer.ticks.template.length = 5;
      amountAxis.renderer.line.strokeOpacity = 1;
      amountAxis.renderer.line.strokeWidth = 1;
      amountAxis.renderer.line.stroke = chartConstants.axisColor;
      amountAxis.renderer.labels.template.fontSize = chartConstants.axisFontSize;
      amountAxis.renderer.labels.template.dx = 10;
      var userIcon = new am4core.Image();
      userIcon.href = UserSvg;
      userIcon.width = 8;
      userIcon.horizontalCenter = 'middle';
      userIcon.verticalCenter = 'middle';
      userIcon.dx = 13;
      userIcon.dy = -0.7;
      amountAxis.dataItems.template.bullet = userIcon;
      var amountSeries = chart.series.push(new am4charts.StepLineSeries());
      amountSeries.dataFields.dateX = 'date';
      amountSeries.dataFields.valueY = 'amount';
      amountSeries.yAxis = amountAxis;
      amountSeries.fill = am4core.color(colorVariables['primaryColor']);
      amountSeries.fillOpacity = 0.5;
      amountSeries.strokeWidth = 2;
      amountSeries.defaultState.transitionDuration = 600;
      var satisfactionAxis = chart.yAxes.push(new am4charts.ValueAxis());
      satisfactionAxis.min = 0;
      satisfactionAxis.max = 105;
      satisfactionAxis.strictMinMax = true;
      satisfactionAxis.cursorTooltipEnabled = false;
      satisfactionAxis.renderer.labels.template.fontSize = chartConstants.axisFontSize;
      satisfactionAxis.renderer.ticks.template.disabled = false;
      satisfactionAxis.renderer.ticks.template.length = 5;
      satisfactionAxis.renderer.ticks.template.stroke = chartConstants.axisColor;
      satisfactionAxis.renderer.ticks.template.strokeWidth = 1;
      satisfactionAxis.renderer.ticks.template.strokeOpacity = 1;
      satisfactionAxis.renderer.line.stroke = chartConstants.axisColor;
      satisfactionAxis.renderer.line.strokeWidth = 1;
      satisfactionAxis.renderer.line.strokeOpacity = 1;
      satisfactionAxis.renderer.grid.template.strokeOpacity = 1;
      satisfactionAxis.renderer.grid.template.stroke = chartConstants.gridColor;
      satisfactionAxis.renderer.grid.template.strokeWidth = 1;
      if (!hasIndexCalculation) {
        satisfactionAxis.numberFormatter = new am4core.NumberFormatter();
        satisfactionAxis.numberFormatter.numberFormat = "#'%'";
      }
      var satisfactionSeries = chart.series.push(new am4charts.LineSeries());
      satisfactionSeries.dataFields.dateX = 'date';
      satisfactionSeries.dataFields.valueY = 'satisfaction';
      satisfactionSeries.yAxis = satisfactionAxis;
      satisfactionSeries.stroke = am4core.color(colorVariables['primaryColorDark']);
      satisfactionSeries.strokeWidth = 4;
      satisfactionSeries.tensionX = 0.97;
      satisfactionSeries.tensionY = 0.97;
      satisfactionSeries.tooltipText = "".concat(translatedStrings.satisfactionShort, ": [font-weight: 700]{satisfaction}").concat(hasIndexCalculation ? '' : '%', "[/]\n").concat(translatedStrings.numberOfFeedbacksShort, ": [font-weight: 700]{amount}[/]");
      satisfactionSeries.tooltip.fontSize = 12;
      satisfactionSeries.tooltip.getFillFromObject = false;
      satisfactionSeries.tooltip.pointerOrientation = 'horizontal';
      satisfactionSeries.tooltip.background.fill = am4core.color('#333');
      satisfactionSeries.tooltip.background.strokeWidth = 0;
      satisfactionSeries.tooltip.background.cornerRadius = 3;
      satisfactionSeries.tooltip.background.pointerLength = 4;
      satisfactionSeries.defaultState.transitionDuration = 600;
      if (hasIndexCalculation) {
        var coloredRangeRed = satisfactionAxis.createSeriesRange(satisfactionSeries);
        coloredRangeRed.value = -10;
        coloredRangeRed.endValue = companyProperties.indexThresholdLow;
        coloredRangeRed.contents.stroke = am4core.color(colorVariables['red']);
        var coloredRangeOrange = satisfactionAxis.createSeriesRange(satisfactionSeries);
        coloredRangeOrange.value = companyProperties.indexThresholdLow;
        coloredRangeOrange.endValue = companyProperties.indexThresholdLowerMedium;
        coloredRangeOrange.contents.stroke = am4core.color(colorVariables['orange']);
        var coloredRangeYellow = satisfactionAxis.createSeriesRange(satisfactionSeries);
        coloredRangeYellow.value = companyProperties.indexThresholdLowerMedium;
        coloredRangeYellow.endValue = companyProperties.indexThresholdUpperMedium;
        coloredRangeYellow.contents.stroke = am4core.color(colorVariables['yellow']);
        var coloredRangeGreenLight = satisfactionAxis.createSeriesRange(satisfactionSeries);
        coloredRangeGreenLight.value = companyProperties.indexThresholdUpperMedium;
        coloredRangeGreenLight.endValue = companyProperties.indexThresholdHigh;
        coloredRangeGreenLight.contents.stroke = am4core.color(colorVariables['green-light']);
        var coloredRangeGreen = satisfactionAxis.createSeriesRange(satisfactionSeries);
        coloredRangeGreen.value = companyProperties.indexThresholdHigh;
        coloredRangeGreen.endValue = 110;
        coloredRangeGreen.contents.stroke = am4core.color(colorVariables['green']);
      }
      var bullet = satisfactionSeries.bullets.push(new am4core.Circle());
      bullet.stroke = am4core.color('white');
      bullet.radius = hasManyDataPoints ? 3 : 5;
      bullet.strokeWidth = hasManyDataPoints ? 1 : 1.5;
      bullet.propertyFields.fill = 'color';
      var bulletHover = bullet.states.create('hover');
      bulletHover.properties.scale = hasManyDataPoints ? 2 : 1.5;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = 'none';
      chart.cursor.xAxis = dateAxis;
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.strokeWidth = 0;
      chart.cursor.lineX.fill = am4core.color('black');
      chart.cursor.lineX.fillOpacity = hasManyDataPoints ? 0.2 : 0.05;
      chart.cursor.fullWidthLineX = true;
      chart.cursor.defaultState.transitionDuration = 2000;
      if (this.goalValue !== null) {
        var goalLine = satisfactionAxis.axisRanges.create();
        goalLine.value = this.goalValue;
        goalLine.grid.stroke = am4core.color(colorVariables['primaryColorDark']);
        goalLine.grid.strokeWidth = 2;
        goalLine.label.text = "".concat(translatedStrings.satisfactionGoal, ": ") + this.goalValue + (this.hasIndexCalculation ? '' : '%');
        goalLine.label.fill = goalLine.grid.stroke;
        goalLine.label.inside = true;
        goalLine.label.verticalCenter = 'bottom';
        goalLine.label.paddingBottom = 4;
      }
      var today = moment();
      if (today.isAfter(data[0].date) && today.isBefore(data[data.length - 1].date)) {
        var todayLine = dateAxis.axisRanges.create();
        todayLine.value = today;
        todayLine.grid.stroke = am4core.color('black');
        todayLine.grid.strokeWidth = 1;
        todayLine.label.text = translatedStrings.today;
        todayLine.label.inside = true;
        todayLine.label.verticalCenter = 'middle';
        todayLine.label.fill = todayLine.grid.stroke;
        todayLine.label.rotation = -90;
        todayLine.label.dx = -8;
        todayLine.label.valign = 'middle';
      }
      return chart;
    },
    initChart: function initChart() {
      this.chart = this.createChart(this.$refs.chart, this.enrichedData, this.interval, this.$store.state.general.companyProperties, this.$store.state.general.language, {
        satisfactionShort: this.hasIndexCalculation ? this.$t('satisfactionScoreShort') : this.$t('satisfactionInPercentShort'),
        numberOfFeedbacksShort: this.$t('numberOfFeedbacksShort'),
        dateStartsAt: this.$t('dateStartsAt'),
        satisfactionGoal: this.$t('goal'),
        today: this.$t('today')
      }, this.hasIndexCalculation, CONSTANTS, colorVariables, this.small, this.showLegend);
    },
    resetChart: function resetChart() {
      if (this.chart !== null) {
        this.chart.dispose();
        this.chart = null;
      }
    }
  },
  mounted: function mounted() {
    this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    this.resetChart();
  },
  watch: {
    watchingProperty: {
      deep: true,
      handler: function handler(newValue, oldValue) {
        var _this2 = this;
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          // Delay chart reset to allow other elements to finish rerendering first
          setTimeout(function () {
            _this2.resetChart();
            _this2.initChart();
          }, 0);
        }
      }
    }
  }
};