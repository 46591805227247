var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "survey-management-new-question-button",
      class: { "sibling-hovered": _vm.siblingQuestionIsHovered },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "plus-icon-wrapper", on: { click: _vm.clicked } },
        [
          _c(
            "div",
            { staticClass: "plus-icon" },
            [
              _c("font-awesome", {
                staticClass: "font-awesome",
                attrs: { icon: ["far", "plus"] },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hover-line-wrapper" }, [
      _c("div", { staticClass: "hover-line" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hover-line-wrapper" }, [
      _c("div", { staticClass: "hover-line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }