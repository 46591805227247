import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Bus as VuedalsBus } from 'vuedals';
import SurveyManagementCreateEditSurveyModal from '@/components/survey-management/SurveyManagementCreateEditSurveyModal';
export default {
  name: 'SurveyManagement',
  computed: {
    isInOverview: function isInOverview() {
      return this.$route.name === 'survey-management';
    },
    isOnEditPage: function isOnEditPage() {
      return this.$route.name === 'survey-management-edit';
    },
    overviewIsLoading: function overviewIsLoading() {
      return this.$store.state.surveyManagement.overviewTable.isLoading;
    }
  },
  methods: {
    showCreateSurveyModal: function showCreateSurveyModal() {
      VuedalsBus.$emit('new', {
        title: this.$t('modalTitle.createNewSurvey', {}),
        name: 'SurveyManagementCreateSurveyModal',
        size: 'sm',
        props: {},
        closeOnBackdrop: false,
        component: SurveyManagementCreateEditSurveyModal
      });
    },
    showEditSurveyModal: function showEditSurveyModal() {
      VuedalsBus.$emit('new', {
        title: this.$t('modalTitle.editSurvey', {}),
        name: 'SurveyManagementEditSurveyModal',
        size: 'sm',
        props: {
          data: this.$store.state.surveyManagement.fullSurvey.data
        },
        closeOnBackdrop: false,
        component: SurveyManagementCreateEditSurveyModal
      });
    },
    navigateToOverview: function navigateToOverview() {
      this.$router.push({
        name: 'survey-management'
      });
    }
  }
};