var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { attrs: { toggleable: "sm", type: "light", fixed: "top" } },
    [
      _c("b-nav-toggle", { attrs: { target: "nav-collapse" } }),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "nav-collapse" } },
        [
          _vm.showUserSelector
            ? _c(
                "div",
                { staticClass: "user-select mr-auto" },
                [
                  _c("multiselect", {
                    attrs: {
                      value: _vm.selectedUser,
                      options: _vm.orderedUsers,
                      placeholder: _vm.$t("selectUser"),
                      label: "email",
                      "track-by": "id",
                    },
                    on: { select: _vm.setSelectedUser },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "option",
                          fn: function (props) {
                            return [
                              _c("div", { staticClass: "option" }, [
                                _vm.userHasRole(props.option, "ROLE_ADMIN")
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-red font-weight-bold",
                                      },
                                      [_vm._v("[A]")]
                                    )
                                  : _vm.userHasRole(
                                      props.option,
                                      "ROLE_MANAGER"
                                    )
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-blue font-weight-bold",
                                      },
                                      [_vm._v("[M]")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(props.option.email)),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2559120556
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _vm.currentUserIsSuperAdmin && _vm.companies.length
                ? _c(
                    "div",
                    {
                      staticClass: "company-select-wrapper",
                      attrs: { right: "" },
                    },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            value: _vm.selectedCompanyId,
                            options: _vm.companies,
                            "allow-empty": false,
                            "show-labels": false,
                            label: "name",
                            "track-by": "id",
                          },
                          on: { select: _vm.setCompanyId },
                        },
                        [
                          _c("template", { slot: "singleLabel" }, [
                            _c(
                              "div",
                              { staticClass: "company-select" },
                              [
                                _c("font-awesome", {
                                  attrs: {
                                    icon: ["fas", "building"],
                                    "fixed-width": "",
                                  },
                                }),
                                _vm._v(" "),
                                !_vm.selectedCompanyId
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.$t("selectCompany"))),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.selectedCompanyName)),
                                    ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                { attrs: { right: "", "no-caret": true } },
                [
                  _c("template", { slot: "button-content" }, [
                    _c(
                      "div",
                      { staticClass: "help-button" },
                      [
                        _c("font-awesome", {
                          attrs: {
                            icon: ["fas", "question"],
                            "fixed-width": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("help")))]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isCustomDomain
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            href: _vm.$options.filters.languageAwareString(
                              _vm.customDomain.instructionGuideLink,
                              false
                            ),
                            target: "_blank",
                          },
                        },
                        [
                          _c("font-awesome", {
                            staticClass: "font-awesome",
                            attrs: {
                              icon: ["fas", "file-pdf"],
                              "fixed-width": "",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("userManual")) +
                              "\n        "
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            href: _vm.defaultInstructionLink,
                            target: "_blank",
                          },
                        },
                        [
                          _c("font-awesome", {
                            staticClass: "font-awesome",
                            attrs: {
                              icon: ["fas", "file-pdf"],
                              "fixed-width": "",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("userManual")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c("b-dropdown-divider"),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    { attrs: { href: "mailto:hotline@jls.ch" } },
                    [
                      _c("font-awesome", {
                        staticClass: "font-awesome",
                        attrs: { icon: ["fas", "envelope"], "fixed-width": "" },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("contact")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                { attrs: { right: "", "no-caret": true } },
                [
                  _c("template", { slot: "button-content" }, [
                    _c(
                      "div",
                      { staticClass: "salutation" },
                      [
                        _c("div", [_vm._v(_vm._s(_vm.salutation))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.firstName))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.lastName))]),
                        _vm._v(" "),
                        _c("font-awesome", {
                          staticClass: "font-awesome user-circle",
                          attrs: {
                            icon: ["fas", "user-circle"],
                            "fixed-width": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    { attrs: { to: { name: "user-profile" } } },
                    [
                      _c("font-awesome", {
                        staticClass: "font-awesome",
                        attrs: { icon: ["fas", "user"], "fixed-width": "" },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("userProfile")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-dropdown-divider"),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.logout()
                        },
                      },
                    },
                    [
                      _c("font-awesome", {
                        staticClass: "font-awesome",
                        attrs: { icon: ["fas", "sign-out"], "fixed-width": "" },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("signOut")) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }