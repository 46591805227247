import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import Panel from '@/components/shared/Panel';
import LocationGroupSelect from '@/components/shared/LocationGroupSelect';
import opnDateRangePicker from '@/components/shared/opnDateRangePicker';
import ValidationErrorList from '@/components/shared/ValidationErrorList';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import { Goal as GoalResource } from '@/services/ResourceService';
import ScrollAnimatorService from '@/services/ScrollAnimatorService';
import ValidationHelperService from '@/services/ValidationHelperService';
import _ from 'lodash';
import moment from 'moment';
export default {
  name: 'GoalEdit',
  components: {
    Alert: Alert,
    Panel: Panel,
    LocationGroupSelect: LocationGroupSelect,
    opnDateRangePicker: opnDateRangePicker,
    ValidationErrorList: ValidationErrorList
  },
  props: {
    isCreation: {
      type: Boolean,
      required: true
    },
    goalId: {
      type: Number,
      required: false,
      default: null
    }
  },
  computed: {
    isValidGoalId: function isValidGoalId() {
      return this.goalId !== null && !window.isNaN(this.goalId) && this.goalId >= 0;
    },
    inputsDisabled: function inputsDisabled() {
      return this.isSubmitting || this.isCreation && this.successfullySaved;
    },
    showLocations: function showLocations() {
      return this.formData.goalDomainType === 'LOCATION';
    },
    showLocationGroups: function showLocationGroups() {
      return this.formData.goalDomainType === 'LOCATION_GROUP';
    },
    showGoalTypeSelect: function showGoalTypeSelect() {
      return !this.isCreation && this.hasIndexCalculation !== (this.originalData.goalType === 'AVERAGE_INDEX');
    },
    originalData: function originalData() {
      if (this.isCreation) {
        return null;
      }
      return {
        id: this.goalId,
        title: this.rawData.title,
        description: this.rawData.description,
        startDate: this.rawData.startDate,
        endDate: this.rawData.endDate,
        goalType: this.rawData.goalType,
        value: '' + this.rawData.value,
        goalDomainType: this.rawData.goalDomainType,
        locations: this.rawData.locations,
        locationGroups: this.rawData.locationGroups,
        priority: this.rawData.priority
      };
    },
    isOriginalData: function isOriginalData() {
      return _.isEqual(this.formData, this.originalData);
    },
    preselectedIds: function preselectedIds() {
      var preselectedIds = {
        locations: [],
        locationGroups: []
      };
      this.formData.locations.forEach(function (l) {
        preselectedIds.locations.push(l.id);
      });
      this.formData.locationGroups.forEach(function (lg) {
        preselectedIds.locationGroups.push(lg.id);
      });
      return preselectedIds;
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    userIsAdmin: function userIsAdmin() {
      var user = this.$store.state.general.selectedUser;
      if (user !== null && user.authorities && user.authorities.length > 0) {
        return user.authorities.includes('ROLE_ADMIN');
      }
      return false;
    }
  },
  data: function data() {
    return {
      isLoading: true,
      isSubmitting: false,
      successfullySaved: false,
      serverErrorOccurred: false,
      validationErrorOccurred: false,
      validationErrors: null,
      rawData: null,
      formData: null,
      goalTypes: [{
        value: 'OVERALL_SATISFACTION',
        text: this.$t('goalType.OVERALL_SATISFACTION')
      }, {
        value: 'AVERAGE_INDEX',
        text: this.$t('goalType.AVERAGE_INDEX')
      }],
      goalDomainTypes: [{
        value: 'LOCATION',
        text: this.$t('goalDomainType.LOCATION')
      }, {
        value: 'LOCATION_GROUP',
        text: this.$t('goalDomainType.LOCATION_GROUP')
      }, {
        value: 'COMPANY',
        text: this.$t('goalDomainType.COMPANY')
      }],
      priorities: [{
        value: 'LOW',
        text: this.$t('priority.LOW')
      }, {
        value: 'NORMAL',
        text: this.$t('priority.NORMAL')
      }, {
        value: 'HIGH',
        text: this.$t('priority.HIGH')
      }]
    };
  },
  created: function created() {
    if (!this.isCreation) {
      if (!this.isValidGoalId) {
        this.$router.push({
          name: 'goal-overview'
        });
      } else {
        this.loadData();
      }
    } else {
      this.initNewGoalData();
    }
  },
  watch: {
    formData: {
      handler: function handler() {
        if (typeof this.formData.title === 'string' && this.formData.title.trim() === '') {
          this.formData.title = null;
        }
        if (typeof this.formData.description === 'string' && this.formData.description.trim() === '') {
          this.formData.description = null;
        }
        if (typeof this.formData.value === 'string' && isNaN(parseInt(this.formData.value))) {
          this.formData.value = null;
        }
      },
      deep: true
    }
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      var userId = this.$store.state.general.selectedUser.id;
      GoalResource.get(userId, this.goalId).then(function (response) {
        _this.rawData = response.data;
        _this.formData = _.cloneDeep(_this.originalData);
        _this.isLoading = false;
        _this.isSubmitting = false;
      }, function () {
        _this.serverErrorOccurred = true;
        _this.isLoading = false;
        _this.isSubmitting = false;
      });
      CompanyPropertiesService.update();
    },
    initNewGoalData: function initNewGoalData() {
      this.formData = {
        title: null,
        description: null,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(30, 'days').format('YYYY-MM-DD'),
        goalType: this.hasIndexCalculation ? 'AVERAGE_INDEX' : 'OVERALL_SATISFACTION',
        value: null,
        goalDomainType: 'LOCATION',
        locations: [],
        locationGroups: [],
        priority: 'NORMAL'
      };
      this.isLoading = false;
    },
    saveGoal: function saveGoal() {
      this.isSubmitting = true;
      var saveGoal = GoalResource.updateGoal;
      if (this.isCreation) {
        saveGoal = GoalResource.createGoal;
      }
      var userId = this.$store.state.general.selectedUser.id;
      saveGoal(userId, this.formData).then(this.handleSuccess, this.handleError);
    },
    handleSuccess: function handleSuccess(response) {
      var _this2 = this;
      this.validationErrorOccurred = false;
      this.validationErrors = null;
      this.successfullySaved = true;
      ScrollAnimatorService.scrollToTopOfElement(this.$el);
      var goalId = this.isCreation ? response.data : this.goalId;
      setTimeout(function () {
        _this2.isSubmitting = false;
        _this2.$router.push({
          name: 'goal-details',
          params: {
            goal: goalId
          }
        });
      }, 1000);
    },
    handleError: function handleError(error) {
      var data = error.response.data;
      var payload = data.payload;
      if (data.status.code === 400 && payload !== null && payload.length > 0) {
        this.validationErrorOccurred = true;
        this.validationErrors = ValidationHelperService.parseValidationErrorsWithTranslations(payload, this);
      } else {
        this.serverErrorOccurred = true;
      }
      this.successfullySaved = false;
      this.isSubmitting = false;
      ScrollAnimatorService.scrollToTopOfElement(this.$el);
    },
    getFieldState: function getFieldState(fieldName) {
      return ValidationHelperService.getFieldState(fieldName, this.validationErrors);
    },
    getFieldErrors: function getFieldErrors(fieldName) {
      return ValidationHelperService.getFieldErrors(fieldName, this.validationErrors);
    },
    locationsChanged: function locationsChanged(newFilter) {
      var _this3 = this;
      this.formData.locations = [];
      this.formData.locationGroups = [];
      newFilter.locations.forEach(function (id) {
        _this3.formData.locations.push({
          id: id
        });
      });
      newFilter.locationGroups.forEach(function (id) {
        _this3.formData.locationGroups.push({
          id: id
        });
      });
    },
    dateRangeChanged: function dateRangeChanged(dateRange) {
      this.formData.startDate = moment(dateRange[0]).format('YYYY-MM-DD');
      this.formData.endDate = moment(dateRange[1]).format('YYYY-MM-DD');
    },
    resetLocationsAndGroups: function resetLocationsAndGroups() {
      this.formData.locations = [];
      this.formData.locationGroups = [];
    }
  }
};