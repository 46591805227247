var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    { staticClass: "reset-password-panel", attrs: { "is-loading": false } },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("resetPassword")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              show: _vm.successfullyResetPassword && !_vm.isSubmitting,
              icon: ["fas", "check"],
              type: "success",
              message: _vm.$t("successMessage"),
            },
          }),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              show: _vm.serverErrorOccurred && !_vm.isSubmitting,
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("errorTitle"),
              message: _vm.$t("errorMessage"),
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "alert alert-info" }, [
            _vm._v(_vm._s(_vm.$t("alert"))),
          ]),
          _vm._v(" "),
          !_vm.userActivated
            ? _c("div", { staticClass: "alert alert-warning" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("deactivatedUserAlert")) + "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "float-right",
              attrs: {
                disabled:
                  _vm.disabled || _vm.isSubmitting || !_vm.userActivated,
                variant: "primary",
              },
              on: { click: _vm.requestPasswordReset },
            },
            [_vm._v(_vm._s(_vm.$t("resetPassword")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }