var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.answerSetId != null
    ? _c("feedback-modal", {
        attrs: { "answer-set-id": _vm.answerSetId, "is-print-view": true },
      })
    : _c("div")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }