import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.error.cause.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Bus as VuedalsBus } from 'vuedals';
import { CustomRatingReasonAnswers, CustomChoiceAttributeAnswers, CommentAnswers, TextAnswers, Comparison } from '@/services/ResourceService';
import TextAnswerService from '@/services/TextAnswerService';
import FormatUtils from '@/services/utils/FormatUtils';
import ModalIconButton from '@/components/shared/ModalIconButton';
import InfiniteLoading from 'vue-infinite-loading';
import FeedbackModal from '@/components/shared/FeedbackModal';
export default {
  name: 'AnswerInspection',
  components: {
    ModalIconButton: ModalIconButton,
    InfiniteLoading: InfiniteLoading
  },
  props: {
    surveyId: {
      type: Number,
      required: true
    },
    counter: {
      type: Number,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    queryParameters: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      isLoadingInitial: true,
      isLoading: true,
      questionText: null,
      attributeText: null,
      totalCount: 0,
      emptyCount: 0,
      totalSearchResults: null,
      filter: '',
      nextPage: 0,
      answers: [],
      infiniteScrollIdentifier: 0
    };
  },
  mounted: function mounted() {
    this.resetModal();
  },
  computed: {
    hasSubquestion: function hasSubquestion() {
      return ['RATING_QUESTION', 'CHOICE_QUESTION'].includes(this.question.questionType);
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.$vuedals.close();
    },
    onInfinite: function onInfinite(infiniteLoadingState) {
      this.loadMore(infiniteLoadingState);
    },
    resetModal: function resetModal() {
      var _this = this;
      this.isLoading = true;
      this.isLoadingInitial = true;
      this.countAnswers(this.surveyId, this.question, this.queryParameters).then(function (response) {
        _this.questionText = response.data.questionText;
        _this.attributeText = _this.hasSubquestion ? response.data.text : null;
        _this.totalCount = response.data.totalCount;
        _this.emptyCount = response.data.emptyCount;
        _this.isLoadingInitial = false;
        _this.resetList();
      });
    },
    resetList: function resetList() {
      var _this2 = this;
      this.isLoading = true;
      this.answers = [];
      this.nextPage = 0;
      this.$nextTick(function () {
        _this2.infiniteScrollIdentifier += 1;
      });
    },
    loadMore: function loadMore(infiniteLoadingState) {
      var _this3 = this;
      if (!this.isLoadingInitial) {
        this.isLoading = true;
        TextAnswerService.getPagedTextAnswers(this.surveyId, this.question, this.queryParameters, this.filter, this.nextPage).then(function (response) {
          _this3.processData(response, infiniteLoadingState);
          _this3.isLoading = false;
        }).catch(function () {
          _this3.answers = [];
          infiniteLoadingState.complete();
          _this3.isLoading = false;
        });
      }
    },
    processData: function processData(response, infiniteLoadingState) {
      var data = response.data;
      var escapedData = [];
      data.content.forEach(function (item) {
        escapedData.push({
          answerId: item.answerId,
          answerSetId: item.answerSetId,
          text: item.text,
          answerEscaped: FormatUtils.escapeHTML(item.answer)
        });
      });
      if (escapedData.length > 0) {
        this.highlightSearchResults(escapedData);
        this.answers = this.answers.concat(escapedData);
        this.nextPage = data.number + 1;
        infiniteLoadingState.loaded();
      }
      this.totalSearchResults = data.totalElements;
      if (data.last === true) {
        infiniteLoadingState.complete();
      }
    },
    highlightSearchResults: function highlightSearchResults(escapedData) {
      var _this4 = this;
      var insert1 = '<b>';
      var insert2 = '</b>';

      // Insert '<b>' before and '</b>' after each occurrence of the search string
      if (this.filter !== null && this.filter !== '') {
        escapedData.forEach(function (crr) {
          var answer = crr.answerEscaped;
          var filter = _this4.filter.toLowerCase();
          for (var j = answer.toLowerCase().indexOf(filter, 0); j >= 0 && j < answer.length; j = answer.toLowerCase().indexOf(filter, j + 1)) {
            answer = answer.slice(0, j + filter.length) + insert2 + answer.slice(j + filter.length);
            answer = answer.slice(0, j) + insert1 + answer.slice(j);
            j += insert1.length + insert2.length;
          }
          crr.answerEscaped = answer;
        });
      }
    },
    openFeedbackModal: function openFeedbackModal(answerSetId) {
      var _this5 = this;
      VuedalsBus.$emit('new', {
        title: this.$t('modalTitle'),
        name: 'feedback-modal',
        closeOnBackdrop: false,
        props: {
          answerSetId: answerSetId
        },
        component: FeedbackModal,
        onClose: function onClose(options) {
          if (options.spamStateChanged) {
            _this5.resetModal();
          }
        }
      });
    },
    countAnswers: function countAnswers(surveyId, question, queryParameters) {
      var isFilteredByChoiceAttributes = typeof queryParameters.choiceAttributeIds !== 'undefined' && queryParameters.choiceAttributeIds !== null && queryParameters.choiceAttributeIds.length > 0;
      if (question.questionType === 'RATING_QUESTION') {
        if (isFilteredByChoiceAttributes) {
          return Comparison.countCustomRatingReasons(queryParameters.companyId, queryParameters.locationIds, queryParameters.start, queryParameters.end, queryParameters.choiceAttributeIds, surveyId, question.questionId);
        }
        return CustomRatingReasonAnswers.count(queryParameters.companyId, queryParameters.locationIds, queryParameters.start, queryParameters.end, surveyId, question.questionId);
      } else if (question.questionType === 'CHOICE_QUESTION') {
        if (isFilteredByChoiceAttributes) {
          return Comparison.countCustomChoiceAttributes(queryParameters.companyId, queryParameters.locationIds, queryParameters.start, queryParameters.end, queryParameters.choiceAttributeIds, surveyId, question.customChoiceAttributeId);
        }
        return CustomChoiceAttributeAnswers.count(queryParameters.companyId, queryParameters.locationIds, queryParameters.start, queryParameters.end, surveyId, question.customChoiceAttributeId);
      } else if (question.questionType === 'COMMENT_QUESTION') {
        if (isFilteredByChoiceAttributes) {
          return Comparison.countCommentAnswers(queryParameters.companyId, queryParameters.locationIds, queryParameters.start, queryParameters.end, queryParameters.choiceAttributeIds, surveyId, question.questionId);
        }
        return CommentAnswers.count(queryParameters.companyId, queryParameters.locationIds, queryParameters.start, queryParameters.end, surveyId, question.questionId);
      } else if (question.questionType === 'TEXT_QUESTION') {
        if (isFilteredByChoiceAttributes) {
          return Comparison.countTextAnswers(queryParameters.companyId, queryParameters.locationIds, queryParameters.start, queryParameters.end, queryParameters.choiceAttributeIds, surveyId, question.questionId);
        }
        return TextAnswers.count(queryParameters.companyId, queryParameters.locationIds, queryParameters.start, queryParameters.end, surveyId, question.questionId);
      }
      throw new Error('Invalid question type.');
    }
  }
};