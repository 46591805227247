//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getColorNameByIndex } from '@/services/ColorService';
import Panel from '@/components/shared/Panel';
import moment from 'moment';
export default {
  name: 'QuickStat',
  components: {
    Panel: Panel
  },
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Number,
      required: false,
      default: null
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    hover: {
      type: Boolean,
      default: false
    },
    isPercentage: {
      type: Boolean,
      required: false,
      default: false
    },
    isChangePercentage: {
      type: Boolean,
      required: false,
      default: false
    },
    change: {
      type: Number,
      required: false,
      default: null
    },
    dateRange: {
      type: Array,
      required: true
    },
    showAdditionalCharacters: {
      type: Boolean,
      required: false,
      default: false
    },
    hasIndexColoration: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    timePeriod: function timePeriod() {
      if (this.dateRange && this.dateRange.length === 2) {
        return moment(this.dateRange[1]).diff(moment(this.dateRange[0]), 'days') + 1;
      } else {
        return 'x';
      }
    }
  },
  methods: {
    getColorNameByIndex: getColorNameByIndex
  }
};