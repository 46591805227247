var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-view",
    { staticClass: "password-recovery-verify" },
    [
      _c("template", { slot: "title" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t(
                _vm.isActivation ? "activationVerify" : "passwordRecoveryVerify"
              )
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _c("alert", {
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              message: _vm.$t(
                "errorExpired.message" + (_vm.isActivation ? "Activation" : "")
              ),
              title: _vm.$t("errorExpired.title"),
              show: _vm.errorExpiredOccurred && !_vm.submitting,
              closeable: false,
            },
          }),
          _vm._v(" "),
          _c(
            "alert",
            {
              attrs: {
                icon: ["fas", "exclamation-triangle"],
                type: "danger",
                title: _vm.$t("errorPassword.title"),
                show: _vm.passwordValidationErrorOccurred && !_vm.submitting,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c("password-validation-error-list", {
                    attrs: { errors: _vm.passwordValidationErrors },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("alert", {
            attrs: {
              icon: ["fas", "check"],
              type: "success",
              closeable: false,
              message: _vm.$t(
                (_vm.isActivation ? "successActivation" : "successRecovery") +
                  ".message"
              ),
              title: _vm.$t(
                (_vm.isActivation ? "successActivation" : "successRecovery") +
                  ".title"
              ),
              show: _vm.finished && !_vm.submitting,
            },
          }),
          _vm._v(" "),
          !_vm.finished && !_vm.errorExpiredOccurred
            ? _c(
                "div",
                [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "input-new-password" },
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("password")) + " \n        "),
                      _c("font-awesome", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.d200",
                            modifiers: { hover: true, d200: true },
                          },
                        ],
                        staticClass: "info-circle",
                        attrs: {
                          icon: ["far", "info-circle"],
                          title: _vm.$t("tooltip.newPassword"),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-fieldset",
                    { staticClass: "pos-rel" },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          id: "input-new-password",
                          autocomplete: "new-password",
                          type: _vm.passwordInputVisible ? "text" : "password",
                          placeholder: _vm.$t("placeholder.password"),
                          disabled: _vm.submitting,
                          autofocus: "",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submit($event)
                          },
                        },
                        model: {
                          value: _vm.passwordInput,
                          callback: function ($$v) {
                            _vm.passwordInput = $$v
                          },
                          expression: "passwordInput",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "password-visibility-toggle increase-focus",
                          on: { click: _vm.toggleNewPasswordVisibility },
                        },
                        [
                          _c("font-awesome", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.passwordInputVisible,
                                expression: "!passwordInputVisible",
                              },
                            ],
                            attrs: { icon: ["far", "eye"] },
                          }),
                          _vm._v(" "),
                          _c("font-awesome", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.passwordInputVisible,
                                expression: "passwordInputVisible",
                              },
                            ],
                            attrs: { icon: ["far", "eye-slash"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "button-row" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            block: true,
                            disabled:
                              _vm.submitting || _vm.passwordInput === "",
                          },
                          on: { click: _vm.submit },
                        },
                        [
                          _c("font-awesome", {
                            attrs: { icon: ["far", "angle-double-right"] },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("submit")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isActivation
                        ? _c(
                            "router-link",
                            {
                              staticClass: "btn-uppercase",
                              attrs: { to: { name: "login" } },
                            },
                            [
                              _c("font-awesome", {
                                attrs: { icon: ["far", "angle-left"] },
                              }),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("toLogin")) +
                                  "\n        "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.finished
            ? _c(
                "div",
                { staticClass: "button-row less-margin-top" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        to: { name: "login" },
                        block: true,
                      },
                    },
                    [
                      _c("font-awesome", {
                        attrs: { icon: ["far", "angle-double-right"] },
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("nextToLogin")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorExpiredOccurred
            ? _c(
                "div",
                { staticClass: "button-row less-margin-top" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        to: { name: "password-recovery" },
                        block: true,
                        exact: "",
                      },
                    },
                    [
                      _c("font-awesome", {
                        attrs: { icon: ["far", "angle-double-right"] },
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "resetPasswordAgain" +
                                (_vm.isActivation ? "Activation" : "")
                            )
                          ) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }