var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "print-matrix print-section" }, [
    _c("div", { staticClass: "print-h2" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("title")) + "\n  "),
    ]),
    _vm._v(" "),
    _vm.data === null
      ? _c("div", { staticClass: "no-data-placeholder" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("noRatingDataInSelection")) + "\n  "),
        ])
      : _c("div", { staticClass: "matrix-table-wrapper" }, [
          _c(
            "table",
            { staticClass: "matrix-table" },
            _vm._l(_vm.tableData, function (row) {
              return _c(
                "tr",
                { key: row[0].locationId },
                _vm._l(row, function (cell) {
                  return _c(
                    "td",
                    {
                      key: cell.cellId,
                      staticClass: "cell",
                      class: {
                        "top-left-cell": cell.isTopLeftCell,
                        "category-cell": cell.isCategoryCell,
                        "location-cell": cell.isLocationCell,
                        "value-cell": cell.isValueCell,
                      },
                    },
                    [
                      cell.isTopLeftCell
                        ? _c(
                            "div",
                            { staticClass: "inner-div overflow-ellipsis" },
                            [
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.$t("locationHeader"))),
                              ]),
                            ]
                          )
                        : cell.isCategoryCell
                        ? _c(
                            "div",
                            { staticClass: "inner-div overflow-ellipsis" },
                            [
                              _c("span", {
                                staticClass: "value",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.languageAwareString(
                                      cell.categoryName
                                    )
                                  ),
                                },
                              }),
                            ]
                          )
                        : cell.isLocationCell
                        ? _c("div", { staticClass: "inner-div" }, [
                            _c(
                              "div",
                              { staticClass: "value overflow-ellipsis" },
                              [_vm._v(_vm._s(cell.locationName))]
                            ),
                            _vm._v(" "),
                            _vm.locationIdToLocationGroupMapping[
                              cell.locationId
                            ]
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "location-group-name overflow-ellipsis",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.locationIdToLocationGroupMapping[
                                            cell.locationId
                                          ].locationGroupName
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : cell.isValueCell && _vm.hasIndexCalculation
                        ? _c(
                            "div",
                            {
                              staticClass: "inner-div average-index",
                              class: [
                                _vm.getColorNameByIndex(
                                  cell.averageIndex,
                                  _vm.$store.state.general.companyProperties
                                ),
                              ],
                            },
                            [
                              cell.averageIndex === null
                                ? _c("span", { staticClass: "not-available" }, [
                                    _vm._v("n/a"),
                                  ])
                                : _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "value" },
                                      [
                                        _c("font-awesome", {
                                          staticClass: "font-awesome",
                                          attrs: { icon: ["far", "smile"] },
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("groupedNumber")(
                                                cell.averageIndex,
                                                true,
                                                1
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                            ]
                          )
                        : cell.isValueCell && !_vm.hasIndexCalculation
                        ? _c(
                            "div",
                            {
                              staticClass: "inner-div satisfaction-rate",
                              class: _vm.getColorNameBySatisfactionRate(
                                cell.satisfactionRate,
                                _vm.$store.state.general.companyProperties
                              ),
                            },
                            [
                              cell.satisfactionRate === null
                                ? _c("span", { staticClass: "not-available" }, [
                                    _vm._v("n/a"),
                                  ])
                                : _c("div", [
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("groupedNumber")(
                                            cell.satisfactionRate,
                                            true,
                                            1
                                          )
                                        ) + "%"
                                      ),
                                    ]),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            }),
            0
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }