import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LocationAdministration',
  methods: {
    createLocation: function createLocation() {
      this.$router.push({
        name: 'location-create'
      });
    },
    navigateToOverview: function navigateToOverview() {
      this.$router.push({
        name: 'location-administration'
      });
    }
  },
  data: function data() {
    return {
      routerKey: 0
    };
  },
  computed: {
    isInOverview: function isInOverview() {
      return this.$route.name === 'location-administration';
    }
  },
  watch: {
    $route: function $route(to, from) {
      // Reset component when going from location creation to editing (to force props to update)
      if (from.name === 'location-create' && to.name === 'location-edit') {
        this.routerKey++;
      }
    }
  }
};