//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Question from '@/components/survey-analysis/Question';
import QuestionsLoading from '@/components/survey-analysis/QuestionsLoading';
import { EventBus } from '@/config/EventBus';
var BREAKPOINT_NARROW_1 = 1200;
var BREAKPOINT_NARROW_2 = 1010;
var BREAKPOINT_NARROW_3 = 935;
export default {
  name: 'Survey',
  components: {
    Question: Question,
    QuestionsLoading: QuestionsLoading
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    queryParameters: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      windowWidth: null
    };
  },
  computed: {
    isNarrow: function isNarrow() {
      if (this.windowWidth === null) {
        return false;
      }
      if (this.windowWidth > BREAKPOINT_NARROW_1) {
        return false;
      }
      if (this.windowWidth > BREAKPOINT_NARROW_2) {
        return true;
      }
      if (this.windowWidth > BREAKPOINT_NARROW_3) {
        return false;
      }
      return true;
    }
  },
  methods: {
    resizeHandler: function resizeHandler(size) {
      this.windowWidth = size.width;
    }
  },
  mounted: function mounted() {
    this.resizeHandler({
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    });
    EventBus.$on('window-resize', this.resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    EventBus.$off('window-resize', this.resizeHandler);
  }
};