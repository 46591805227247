//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilterCriteria from '@/components/comparison/filter-criteria/FilterCriteria';
export default {
  name: 'FilterCriteriaChoice',
  components: {
    FilterCriteria: FilterCriteria
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    short: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    clicked: function clicked() {
      if (!this.disabled) {
        this.$emit('click');
      }
    }
  }
};