import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import { DEFAULT_DATE_RANGE } from '@/config/constants';
import moment from 'moment';
var QueryUtils = {
  getDateRangeFilter: function getDateRangeFilter(filter) {
    var range = [];
    if (filter && filter.length === 2) {
      try {
        var dateRangeStart = moment(filter[0]);
        var dateRangeEnd = moment(filter[1]);
        if (dateRangeStart.isSameOrBefore(dateRangeEnd)) {
          range.push(dateRangeStart.toDate());
          range.push(dateRangeEnd.toDate());
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    if (range.length === 0) {
      range.push(DEFAULT_DATE_RANGE.START.toDate());
      range.push(DEFAULT_DATE_RANGE.END.toDate());
    }
    return range;
  },
  getLocationFilter: function getLocationFilter(filter) {
    var locationFilter = {
      locations: [],
      locationGroups: [],
      queryLocations: []
    };
    if (filter && filter.locations && filter.locationGroups && filter.queryLocations && filter.queryLocations.length > 0) {
      if (filter.locations.length > 0) {
        locationFilter.locations = filter.locations;
      }
      if (filter.locationGroups.length > 0) {
        locationFilter.locationGroups = filter.locationGroups;
      }
      locationFilter.queryLocations = filter.queryLocations;
      locationFilter.selectedSearchFilterId = filter.selectedSearchFilterId;
    }
    return locationFilter;
  },
  resetLocationFilter: function resetLocationFilter(filter) {
    if (filter) {
      return {
        locations: [],
        locationGroups: [],
        queryLocations: []
      };
    } else {
      return {};
    }
  },
  getTimelineFilter: function getTimelineFilter(filter) {
    var enums = QueryUtils.getTimelineFilterEnums();
    var defaultIndex = QueryUtils.getTimelineDefaultIndexFilterObject();
    var defaultBarcode = QueryUtils.getTimelineDefaultBarcodeFilterObject();
    var timelineFilter = {
      index: defaultIndex,
      satisfaction: enums.satisfaction.default,
      comments: enums.comments.default,
      email: enums.email.default,
      spam: enums.spam.default,
      barcode: defaultBarcode
    };
    if (filter) {
      if (filter.index && filter.index.filterByIndex === true && filter.index.minimalIndex >= 0 && filter.index.maximalIndex <= 100 && filter.index.maximalIndex >= filter.index.minimalIndex) {
        timelineFilter.index.filterByIndex = true;
        timelineFilter.index.minimalIndex = filter.index.minimalIndex;
        timelineFilter.index.maximalIndex = filter.index.maximalIndex;
      }
      if (enums.satisfaction.values.indexOf(filter.satisfaction) !== -1) {
        timelineFilter.satisfaction = filter.satisfaction;
      }
      if (enums.comments.values.indexOf(filter.comments) !== -1) {
        timelineFilter.comments = filter.comments;
      }
      if (enums.email.values.indexOf(filter.email) !== -1) {
        timelineFilter.email = filter.email;
      }
      if (enums.spam.values.indexOf(filter.spam) !== -1) {
        timelineFilter.spam = filter.spam;
      }
      if (filter.barcode && filter.barcode.filterByBarcode && enums.barcode.values.indexOf(filter.barcode.filterByBarcode) !== -1) {
        timelineFilter.barcode.filterByBarcode = filter.barcode.filterByBarcode;
        timelineFilter.barcode.barcodeFilterString = filter.barcode.barcodeFilterString;
      }
    }
    return timelineFilter;
  },
  timelineFilterIsNotDefault: function timelineFilterIsNotDefault(filter) {
    if (!filter) {
      return false;
    }
    var timelineFilter = QueryUtils.getTimelineFilter(filter);
    var defaultFilter = QueryUtils.getTimelineFilter();
    return JSON.stringify(timelineFilter) !== JSON.stringify(defaultFilter);
  },
  getTimelineFilterEnums: function getTimelineFilterEnums() {
    return {
      satisfaction: {
        default: 'ALL',
        values: ['ALL', 'SATISFIED', 'DISSATISFIED']
      },
      comments: {
        default: 'ALL',
        values: ['ALL', 'ONLY_WITH_COMMENTS']
      },
      email: {
        default: 'ALL',
        values: ['ALL', 'ONLY_WITH_EMAIL', 'ONLY_WITH_CONTACT_REQUEST']
      },
      spam: {
        default: 'ALL',
        values: ['ALL', 'ONLY_NON_SPAM', 'ONLY_SPAM']
      },
      barcode: {
        default: 'ALL',
        values: ['ALL', 'ONLY_WITH_BARCODE']
      }
    };
  },
  getTimelineDefaultIndexFilterObject: function getTimelineDefaultIndexFilterObject() {
    return {
      filterByIndex: false,
      minimalIndex: 0,
      maximalIndex: 100
    };
  },
  getTimelineDefaultBarcodeFilterObject: function getTimelineDefaultBarcodeFilterObject() {
    return {
      filterByBarcode: this.getTimelineFilterEnums().barcode.default,
      barcodeFilterString: null
    };
  },
  getSurveyAnalysisFilter: function getSurveyAnalysisFilter(filter) {
    var result = QueryUtils.getSurveyAnalysisDefaultObject();
    if (filter && filter.surveyId !== null && filter.surveyId > 0) {
      result.surveyId = filter.surveyId;
    }
    return result;
  },
  surveyAnalysisFilterIsNotDefault: function surveyAnalysisFilterIsNotDefault(filter) {
    if (!filter) {
      return false;
    }
    var surveyAnalysisFilter = QueryUtils.getSurveyAnalysisFilter(filter);
    var defaultFilter = QueryUtils.getSurveyAnalysisFilter();
    return JSON.stringify(surveyAnalysisFilter) !== JSON.stringify(defaultFilter);
  },
  getSurveyAnalysisDefaultObject: function getSurveyAnalysisDefaultObject() {
    return {
      surveyId: null
    };
  }
};
export default QueryUtils;