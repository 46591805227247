var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alert-box",
      class: [
        _vm.type,
        {
          "has-icon": !!_vm.icon || _vm.hasIconSlot,
          show: _vm.show && _vm.internallyShow,
          closeable: _vm.closeable,
        },
      ],
    },
    [
      _vm.icon
        ? _c("font-awesome", {
            staticClass: "font-awesome",
            attrs: {
              icon: _vm.icon,
              transform: _vm.iconTransform,
              mask: _vm.iconMask,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasIconSlot
        ? _c("span", { staticClass: "font-awesome" }, [_vm._t("icon")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.title
        ? _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))]),
      _vm._v(" "),
      _vm._t("content"),
      _vm._v(" "),
      _vm.closeable
        ? _c(
            "div",
            {
              staticClass: "close-alert",
              on: {
                click: function ($event) {
                  _vm.internallyShow = false
                },
              },
            },
            [_vm._v("\n    ×\n  ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }