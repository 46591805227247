var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      class: [
        _vm.hasIndexColoration ? "colored" : null,
        !_vm.isLoading && _vm.hasIndexColoration
          ? "color-" +
            _vm.getColorNameByIndex(
              _vm.data,
              _vm.$store.state.general.companyProperties
            )
          : null,
      ],
      attrs: {
        "panel-title": _vm.title,
        "is-loading": _vm.isLoading,
        hover: _vm.hover,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.isLoading
              ? _c(
                  "div",
                  { key: "loading", staticClass: "loading-placeholder" },
                  [_c("div", { staticClass: "data w-75" })]
                )
              : _c("div", { key: "loaded", staticClass: "data" }, [
                  _vm.$slots.additionalContent
                    ? _c(
                        "div",
                        { staticClass: "additional-content" },
                        [_vm._t("additionalContent")],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data !== null
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.bottom.d200",
                              modifiers: {
                                hover: true,
                                bottom: true,
                                d200: true,
                              },
                            },
                          ],
                          staticClass: "value",
                          attrs: {
                            title: _vm.showAdditionalCharacters
                              ? _vm.$options.filters.groupedNumber(
                                  _vm.data,
                                  true,
                                  4
                                ) + (_vm.isPercentage ? "%" : "")
                              : null,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm._f("groupedNumber")(_vm.data, true, 1))
                          ),
                          _vm.isPercentage
                            ? _c("span", [_vm._v("%")])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data === null
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top.d200",
                              modifiers: { hover: true, top: true, d200: true },
                            },
                          ],
                          staticClass: "value inactive",
                          attrs: { title: _vm.$t("noDataAvailable") },
                        },
                        [_vm._v("n/a")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data !== null &&
                  _vm.data !== undefined &&
                  _vm.change !== null &&
                  _vm.change !== undefined
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.bottom.d200",
                              modifiers: {
                                hover: true,
                                bottom: true,
                                d200: true,
                              },
                            },
                          ],
                          staticClass: "change",
                          attrs: {
                            title: _vm.$t("change", {
                              change:
                                (_vm.change > 0 ? "+" : "") +
                                _vm.$options.filters.groupedNumber(
                                  _vm.change,
                                  true,
                                  4
                                ),
                              days: _vm.timePeriod,
                              percentage: _vm.isChangePercentage ? "%" : "",
                            }),
                          },
                        },
                        [
                          _vm.change > 0
                            ? _c("span", [
                                _vm._v(
                                  "+" +
                                    _vm._s(
                                      _vm._f("groupedNumber")(
                                        _vm.change,
                                        true,
                                        1
                                      )
                                    ) +
                                    _vm._s(_vm.isChangePercentage ? "%" : "")
                                ),
                              ])
                            : _vm.change < 0
                            ? _c("span", [
                                _vm._v(
                                  "–" +
                                    _vm._s(
                                      _vm._f("groupedNumber")(
                                        _vm.change * -1,
                                        true,
                                        1
                                      )
                                    ) +
                                    _vm._s(_vm.isChangePercentage ? "%" : "")
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "±" +
                                    _vm._s(_vm.change) +
                                    _vm._s(_vm.isChangePercentage ? "%" : "")
                                ),
                              ]),
                          _vm._v(" "),
                          _vm.change > 0
                            ? _c("font-awesome", {
                                staticClass: "font-awesome long-arrow-up",
                                attrs: { icon: ["fas", "long-arrow-up"] },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.change < 0
                            ? _c("font-awesome", {
                                staticClass: "font-awesome long-arrow-down",
                                attrs: { icon: ["fas", "long-arrow-down"] },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.change === 0
                            ? _c("font-awesome", {
                                staticClass: "font-awesome exchange",
                                attrs: { icon: ["fas", "exchange"] },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }