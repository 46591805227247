import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import Flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de.js';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { Italian } from 'flatpickr/dist/l10n/it.js';
import 'flatpickr/dist/flatpickr.css';
export default {
  name: 'OpnDateRangePicker',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    isInForm: {
      type: Boolean,
      required: false,
      default: false
    },
    withLimitedRanges: {
      type: Boolean,
      required: false,
      default: false
    },
    hasPresetRanges: {
      type: Boolean,
      required: false,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    var _this = this;
    return {
      displayDateText: null,
      config: {
        mode: 'range',
        wrap: true,
        static: true,
        defaultDate: this.value,
        onChange: function onChange(selectedDates) {
          // Only update if a daterange has been selected (not just one date)
          // and old != new value
          if (selectedDates.length > 1) {
            if (moment(selectedDates[0]).isSame(_this.value[0], 'day') && moment(selectedDates[1]).isSame(_this.value[1], 'day')) {
              return;
            }
            _this.displayDateText = _this.getRangeText(selectedDates);
            _this.dateRange = selectedDates;
            _this.$emit('change-daterange', _this.dateRange);
          }
        }
      },
      dateRange: this.value,
      mutableValue: this.value,
      fp: null,
      nextButtonDisabled: false
    };
  },
  computed: {
    ranges: function ranges() {
      var ranges = [{
        name: 'today',
        start: moment(),
        end: moment()
      }, {
        name: 'yesterday',
        start: moment().subtract(1, 'd'),
        end: moment().subtract(1, 'd')
      }, {
        name: 'last7days',
        start: moment().subtract(6, 'days'),
        end: moment()
      }, {
        name: 'thisWeek',
        start: moment().startOf('week'),
        end: moment().endOf('week')
      }, {
        name: 'lastWeek',
        start: moment().startOf('isoweek').subtract(1, 'week'),
        end: moment().endOf('isoweek').subtract(1, 'week')
      }, {
        name: 'last30days',
        start: moment().subtract(29, 'days'),
        end: moment()
      }, {
        name: 'thisMonth',
        start: moment().startOf('month'),
        end: moment().endOf('month')
      }, {
        name: 'lastMonth',
        start: moment().startOf('month').subtract(1, 'month'),
        // subtracting 1 month from the endof current month would lead to 30.10. when it is november
        end: moment().startOf('month').subtract(1, 'months').endOf('month')
      }];

      // add yearly data only if "withLimitedRanges" is false
      if (!this.withLimitedRanges) {
        ranges.push({
          name: 'thisYear',
          start: moment().startOf('year'),
          end: moment().endOf('year')
        });
        ranges.push({
          name: 'lastYear',
          start: moment().startOf('year').subtract(1, 'years'),
          end: moment().endOf('year').subtract(1, 'years')
        });
      }
      return ranges;
    }
  },
  mounted: function mounted() {
    var _start = moment(this.value[0]);
    var _end = moment(this.value[1]);
    if (!_start.isValid() || !_end.isValid()) {
      _start = moment();
      _end = moment();
    }
    this.dateRange = [_start.toDate(), _end.toDate()];
    this.config.defaultDate = this.dateRange;
    this.setLanguage();

    // Load flatPickr if not loaded yet
    if (!this.fp) {
      // Bind on parent element if wrap is true
      var elem = this.config.wrap ? this.$el.parentNode : this.$el;
      this.fp = new Flatpickr(elem, this.config);
      if (this.isInForm === true) {
        this.fp.calendarContainer.classList.add('in-form-calendar');
      }
      this.displayDateText = this.getRangeText(this.dateRange);
    }
    this.setNextButtonEnOrDisabled();
  },
  beforeDestroy: function beforeDestroy() {
    // Free up memory
    if (this.fp) {
      this.fp.destroy();
      this.fp = null;
    }
  },
  methods: {
    changePeriod: function changePeriod(dir) {
      if (this.isLoading) {
        return;
      }
      this.setDateRange(this.calculatePeriod(dir));
    },
    calculatePeriod: function calculatePeriod(dir) {
      var newStart;
      var newEnd;
      var _start = moment(this.dateRange[0]).startOf('day');
      var _end = moment(this.dateRange[1]).endOf('day');
      var startOfMonth = moment(_start).startOf('month');
      var endOfMonth = moment(_end).endOf('month');
      var startOfYear = moment(_start).startOf('year');
      var endOfYear = moment(_end).endOf('year');

      // if start date is first of year and end date is end of year
      if (_start.isSame(startOfYear) && _end.isSame(endOfYear)) {
        if (dir === 'next') {
          newStart = moment(startOfYear).add(1, 'year').startOf('year');
          newEnd = moment(endOfYear).add(1, 'year').endOf('year');
        } else {
          newStart = moment(startOfYear).subtract(1, 'year').startOf('year');
          newEnd = moment(endOfYear).subtract(1, 'year').endOf('year');
        }
        // if start date is first of month and end date is end of month
      } else if (_start.isSame(startOfMonth) && _end.isSame(endOfMonth)) {
        if (dir === 'next') {
          newStart = moment(startOfMonth).add(1, 'month').startOf('month');
          newEnd = moment(endOfMonth).add(1, 'month').endOf('month');
        } else {
          newStart = moment(startOfMonth).subtract(1, 'month').startOf('month');
          newEnd = moment(endOfMonth).subtract(1, 'month').endOf('month');
        }
      } else {
        // Calculate difference in days (plus 1 needed for same dates)
        var diff = _end.diff(_start, 'd') + 1;

        // Add or subtract the amount of days
        if (dir === 'next') {
          newStart = _start.add(diff, 'd');
          newEnd = _end.add(diff, 'd');
        } else {
          newStart = _start.subtract(diff, 'd');
          newEnd = _end.subtract(diff, 'd');
        }
      }
      return {
        start: newStart,
        end: newEnd
      };
    },
    getRangeText: function getRangeText(selectedDates) {
      var value;
      var start = moment(selectedDates[0]);
      var end = moment(selectedDates[1]);
      var format = this.getRangeFormat(start, end, this.hasPresetRanges);

      // If no predefined range exists, index is -1
      var index = this.ranges.findIndex(function (range) {
        return start.isSame(range.start, 'day') && end.isSame(range.end, 'day');
      });
      if (index !== -1 && this.hasPresetRanges) {
        // Predefined and translated text
        value = this.$t(this.ranges[index].name);
      } else {
        if (this.hasPresetRanges && (start.isSame(end, 'day') || start.isSame(end, 'month') && start.isSame(moment(start).startOf('month'), 'day') && end.isSame(moment(end).endOf('month'), 'day'))) {
          // Single day or month
          value = start.format(format);
        } else {
          // Date Range
          value = "".concat(start.format(format), " &nbsp;&ndash;&nbsp; ").concat(end.format(format));
        }
      }
      return value;
    },
    getRangeFormat: function getRangeFormat(start, end, noPresetRange) {
      var formats = {
        currentWeek: {
          singleDay: 'dddd'
        },
        currentYear: {
          singleDay: 'D. MMMM YYYY',
          singleMonth: 'MMMM YYYY',
          range: 'D. MMMM YYYY'
        },
        year: {
          singleDay: 'D. MMMM, YYYY',
          singleMonth: 'MMMM, YYYY',
          range: 'D. MMMM YYYY'
        }
      };
      if (noPresetRange === false) {
        return formats.year.range;
      }
      var now = moment();
      var isSameDay = start.isSame(end, 'day');
      var isSameMonth = start.isSame(end, 'month');
      var isCurrentWeek = start.isSame(now, 'week') && end.isSame(now, 'week');
      var isCurrentYear = start.isSame(now, 'year') && end.isSame(now, 'week');
      var isStartOfMonth = start.isSame(moment(start).startOf('month'), 'day');
      var isEndOfMonth = end.isSame(moment(end).endOf('month'), 'day');
      var isFullMonth = isSameMonth && isStartOfMonth && isEndOfMonth;
      if (isCurrentWeek) {
        return formats.currentWeek.singleDay;
      } else if (isCurrentYear) {
        if (isSameDay) {
          return formats.currentYear.singleDay;
        } else if (isFullMonth) {
          return formats.currentYear.singleMonth;
        } else {
          return formats.currentYear.range;
        }
      } else {
        if (isSameDay) {
          return formats.year.singleDay;
        } else if (isFullMonth) {
          return formats.year.singleMonth;
        } else {
          return formats.year.range;
        }
      }
    },
    setDateRange: function setDateRange(range) {
      this.dateRange[0] = range.start;
      this.dateRange[1] = range.end;
      this.displayDateText = this.getRangeText(this.dateRange);

      // Update flat pickr instance
      this.fp.setDate([range.start.toDate(), range.end.toDate()], true);
      this.setNextButtonEnOrDisabled();
      this.$emit('change-daterange', this.dateRange);
    },
    setNextButtonEnOrDisabled: function setNextButtonEnOrDisabled() {
      var nextPeriod = this.calculatePeriod('next');
      var isAfterToday = function isAfterToday(date) {
        return moment(date).startOf('day').isAfter(moment().startOf('day'));
      };
      this.nextButtonDisabled = isAfterToday(nextPeriod.start);
    },
    setLanguage: function setLanguage() {
      var currentStoredLanguage = this.$store.state.general.user.language.toLowerCase();
      var enLocaleConfig = {
        firstDayOfWeek: 1
      };
      if (currentStoredLanguage !== 'en') {
        if (currentStoredLanguage === 'de') {
          this.config.locale = German;
        } else if (currentStoredLanguage === 'fr') {
          this.config.locale = French;
        } else if (currentStoredLanguage === 'it') {
          this.config.locale = Italian;
        } else {
          this.config.locale = enLocaleConfig;
          window.console.error('An unsupported language was used in the date picker.');
        }
      } else {
        this.config.locale = enLocaleConfig;
      }
    }
  },
  watch: {
    /**
     * Watch for any config changes and redraw date-picker
     *
     * @param newConfig Object
     */
    config: function config(newConfig) {
      this.fp.config = Object.assign(this.fp.config, newConfig);
      this.fp.redraw();
      this.fp.setDate(this.value, true);
    },
    /**
     * Watch for value changed by date-picker itself and notify parent component
     *
     * @param newValue
     */
    mutableValue: function mutableValue(newValue) {
      this.setNextButtonEnOrDisabled();
      this.$emit('input', newValue);
    },
    /**
     * Watch for changes from parent component and update DOM
     *
     * @param newValue
     */
    value: function value(newValue) {
      this.dateRange = newValue;
      this.displayDateText = this.getRangeText(newValue);
      this.fp && this.fp.setDate(newValue, true);
    }
  }
};