import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import opnProgress from '@/components/shared/opnProgress';
import Panel from '@/components/shared/Panel';
import QueryService from '@/services/QueryService';
import QueryUtils from '@/services/utils/QueryUtils';
import { getColorNameByIndex } from '@/services/ColorService';
export default {
  name: 'LocationList',
  components: {
    Panel: Panel,
    opnProgress: opnProgress
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    locations: function locations() {
      return this.data.slice(0, 5);
    },
    btnShowAllVisible: function btnShowAllVisible() {
      return this.data.length > 5;
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    }
  },
  methods: {
    routeToAllLocations: function routeToAllLocations() {
      this.$router.push({
        name: 'locations',
        query: QueryService.encodedQuery()
      });
    },
    filterToLocation: function filterToLocation(locationId) {
      var locationFilter = QueryUtils.getLocationFilter();
      locationFilter.locations.push(locationId);
      locationFilter.queryLocations.push(locationId);
      var filter = QueryService.get('filter') || {};
      filter.location = locationFilter;
      QueryService.put('filter', filter);
    },
    getColorNameByIndex: getColorNameByIndex
  }
};