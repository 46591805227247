var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c("change-over-time", {
            staticClass: "mb-4",
            attrs: {
              data: _vm.data,
              title: _vm.$t("title"),
              "height-in-pixel": 200,
              small: true,
            },
          })
        : _c("change-over-time-loading", {
            staticClass: "mb-4",
            attrs: { "height-in-pixel": 200, small: true },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }