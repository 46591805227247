import _toConsumableArray from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SurveyManagementQuestionTypeAlphaNumericIcon from '@/components/survey-management/icons/SurveyManagementQuestionTypeAlphaNumericIcon';
export default {
  name: 'SurveyManagementPreviewRatingReasons',
  components: {
    SurveyManagementQuestionTypeAlphaNumericIcon: SurveyManagementQuestionTypeAlphaNumericIcon
  },
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  computed: {
    combinedRatingAndCustomRatingReasons: function combinedRatingAndCustomRatingReasons() {
      if (this.question.customRatingReasonEnabled) {
        return [].concat(_toConsumableArray(this.question.activatedRatingReasons), [this.question.customRatingReason]);
      } else {
        return this.question.activatedRatingReasons;
      }
    },
    currentLanguage: function currentLanguage() {
      return this.$store.state.surveyManagement.editPageState.data.currentLanguage;
    }
  },
  methods: {
    lasPreviewTranslation: function lasPreviewTranslation(las) {
      if (las === null || typeof las === 'undefined') {
        return null;
      }
      return las[this.currentLanguage];
    }
  }
};