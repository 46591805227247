import _createForOfIteratorHelper from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import moment from 'moment';
export default {
  name: 'GoalCompleted',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    goals: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      TOOLTIP_MAX_ITEMS: 10,
      fields: [{
        key: 'priority',
        label: ' ',
        sortable: true
      }, {
        key: 'title',
        label: this.$t('fields.title'),
        thStyle: {
          minWidth: '150px'
        },
        sortable: true
      }, {
        key: 'goalDomainType',
        label: this.$t('fields.goalDomainType'),
        sortable: false
      }, {
        key: 'goalDateRange',
        label: this.$t('fields.goalDateRange'),
        sortable: false
      }, {
        key: 'goalCurrentValue',
        label: this.$t('fields.goalCurrentValue'),
        sortable: false
      }, {
        key: 'goalTargetValue',
        label: this.$t('fields.goalTargetValue'),
        sortable: false
      }, {
        key: 'actions',
        label: ' ',
        thStyle: {
          minWidth: '200px'
        },
        sortable: false
      }]
    };
  },
  methods: {
    goalHasMultipleLocationsOrGroups: function goalHasMultipleLocationsOrGroups(goal) {
      if (goal.goalDomainType === 'LOCATION') {
        return goal.locations && goal.locations.length > 1;
      } else if (goal.goalDomainType === 'LOCATION_GROUP') {
        return goal.locationGroups && goal.locationGroups.length > 1;
      } else if (goal.goalDomainType === 'COMPANY') {
        return false;
      }
      return false;
    },
    goalMatchesCompanySetting: function goalMatchesCompanySetting(goal) {
      if (this.$store.getters['general/hasIndexCalculation']) {
        return goal.goalType === 'AVERAGE_INDEX';
      } else {
        return goal.goalType === 'OVERALL_SATISFACTION';
      }
    },
    getGoalDomainTypeTooltip: function getGoalDomainTypeTooltip(goal) {
      var unsortedItems = [];
      var sortedItems = [];
      var tooltip = '';
      if (goal.goalDomainType === 'LOCATION') {
        unsortedItems = goal.locations;
      } else if (goal.goalDomainType === 'LOCATION_GROUP') {
        unsortedItems = goal.locationGroups;
      }
      sortedItems = _.orderBy(unsortedItems, 'name').slice(0, this.TOOLTIP_MAX_ITEMS);
      var _iterator = _createForOfIteratorHelper(sortedItems),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          tooltip += item.name + '<br>';
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      if (sortedItems.length >= this.TOOLTIP_MAX_ITEMS) {
        tooltip += this.$t('tooltip.andXMore', {
          numberOfItems: unsortedItems.length - this.TOOLTIP_MAX_ITEMS
        });
      }
      return tooltip;
    },
    getGoalAmountOfLocationsOrGroups: function getGoalAmountOfLocationsOrGroups(goal) {
      if (goal.goalDomainType === 'LOCATION') {
        return goal.locations.length;
      } else if (goal.goalDomainType === 'LOCATION_GROUP') {
        return goal.locationGroups.length;
      }
    },
    viewDetails: function viewDetails(goal) {
      this.$emit('view-details', goal);
    },
    archiveGoal: function archiveGoal(goal) {
      this.$emit('archive-goal', goal);
    },
    unarchiveGoal: function unarchiveGoal(goal) {
      this.$emit('unarchive-goal', goal);
    },
    editGoal: function editGoal(goal) {
      this.$emit('edit-goal', goal);
    },
    deleteGoal: function deleteGoal(goal) {
      this.$emit('delete-goal', goal);
    },
    /**
     * Custom sort function to sort priority enums according to their priority and not alphabetically
     * Fallback to the default number/string sort function for all other keys
     */
    goalTableSort: function goalTableSort(a, b, key) {
      if (key === 'priority') {
        if (a[key] === 'LOW') {
          if (b[key] === 'LOW') {
            return 0;
          } else {
            return -1;
          }
        }
        if (a[key] === 'NORMAL') {
          if (b[key] === 'LOW') {
            return 1;
          } else if (b[key] === 'HIGH') {
            return -1;
          } else {
            return 0;
          }
        }
        if (a[key] === 'HIGH') {
          if (b[key] === 'HIGH') {
            return 0;
          } else {
            return 1;
          }
        }
      }
      if (typeof a[key] === 'number' && typeof b[key] === 'number') {
        // If both compared fields are native numbers
        return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
      } else {
        // Stringify the field data and use String.localeCompare
        return a[key].toString().localeCompare(b[key].toString(), undefined, {
          numeric: true
        });
      }
    },
    formatDate: function formatDate(date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY');
      }
    }
  }
};