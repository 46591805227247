import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Bus as VuedalsBus } from 'vuedals';
import opnProgress from '@/components/shared/opnProgress';
import AnswerInspectionModal from '@/components/shared/AnswerInspectionModal';
export default {
  name: 'AttributeDistributionChart',
  components: {
    opnProgress: opnProgress
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: function validator(item) {
        return ['ratingReason', 'choiceAttribute'].includes(item);
      }
    },
    counter: {
      type: Number,
      required: true,
      validator: function validator(item) {
        return item > 0;
      }
    },
    surveyId: {
      type: Number,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    queryParameters: {
      type: Object,
      required: true
    },
    isNarrow: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    attributes: function attributes() {
      if (this.question.questionType === 'RATING_QUESTION') {
        return this.question.ratingReasons;
      } else if (this.question.questionType === 'CHOICE_QUESTION') {
        return this.question.choiceAttributes;
      }
      return null;
    }
  },
  methods: {
    openAnswerInspectionModal: function openAnswerInspectionModal(surveyId, counter, question, queryParameters) {
      VuedalsBus.$emit('new', {
        title: this.$t("modalTitle.".concat(question.questionType)),
        name: 'answer-inspection-modal',
        closeOnBackdrop: false,
        props: {
          surveyId: surveyId,
          counter: counter,
          question: question,
          queryParameters: queryParameters
        },
        component: AnswerInspectionModal
      });
    },
    showAnswerInspection: function showAnswerInspection(customChoiceAttributeId) {
      var question = this.question;
      if (customChoiceAttributeId !== null) {
        question.customChoiceAttributeId = customChoiceAttributeId;
      }
      this.openAnswerInspectionModal(this.surveyId, this.counter, question, this.queryParameters);
    }
  }
};