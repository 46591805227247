var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-step-wrapper" }, [
    _c("div", { staticClass: "previous-button" }, [_vm._t("previous")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "form-step-indicator" }, [
      _c(
        "div",
        { staticClass: "steps" },
        _vm._l(_vm.steps, function (stepName, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "step",
              class: {
                active: index === _vm.stepIndex,
                before: index < _vm.stepIndex,
                after: index > _vm.stepIndex,
              },
            },
            [
              _c(
                "div",
                { staticClass: "indicator" },
                [
                  index >= _vm.stepIndex
                    ? _c("span", { staticClass: "number" }, [
                        _vm._v(_vm._s(index + 1)),
                      ])
                    : _c("font-awesome", {
                        staticClass: "number font-awesome",
                        attrs: { icon: ["fas", "check"] },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              index !== 0 ? _c("div", { staticClass: "line" }) : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "blue-line",
                class: { shown: index !== 0 && index === _vm.stepIndex },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "description" }, [
                _vm._v(_vm._s(stepName)),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "next-button" }, [_vm._t("next")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }