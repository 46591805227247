import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import SurveyManagementEditAttribute from '@/components/survey-management/edit-question/questions/SurveyManagementEditAttribute';
import SurveyManagementNewAttribute from '@/components/survey-management/edit-question/questions/SurveyManagementNewAttribute';
import draggable from 'vuedraggable';
export default {
  name: 'SurveyManagementEditChoiceAttributes',
  components: {
    SurveyManagementEditAttribute: SurveyManagementEditAttribute,
    SurveyManagementNewAttribute: SurveyManagementNewAttribute,
    draggable: draggable
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    editQuestion: {
      type: Object,
      required: true
    },
    currentLanguage: {
      type: String,
      required: true
    }
  },
  created: function created() {
    this.setInternalEditQuestion();
    this.setInternalCurrentLanguage();
  },
  data: function data() {
    return {
      internalEditQuestion: null,
      internalCurrentLanguage: null,
      isDraggingRegularChoiceAttribute: false,
      isDraggingCustomChoiceAttribute: false
    };
  },
  computed: {
    hasRegularChoiceAttributes: function hasRegularChoiceAttributes() {
      return this.internalEditQuestion.activatedRegularChoiceAttributes.length + this.internalEditQuestion.disabledRegularChoiceAttributes.length > 0;
    },
    hasCustomChoiceAttributes: function hasCustomChoiceAttributes() {
      return this.internalEditQuestion.activatedCustomChoiceAttributes.length + this.internalEditQuestion.disabledCustomChoiceAttributes.length > 0;
    },
    regularChoiceAttributeDraggingOptions: function regularChoiceAttributeDraggingOptions() {
      return {
        swap: true,
        group: 'regularChoiceAttributes',
        forceFallback: true,
        handle: '.drag-icon',
        disabled: false,
        swapThreshold: 1,
        animation: 150
      };
    },
    customChoiceAttributeDraggingOptions: function customChoiceAttributeDraggingOptions() {
      return {
        swap: true,
        group: 'customChoiceAttributes',
        forceFallback: true,
        handle: '.drag-icon',
        disabled: false,
        swapThreshold: 1,
        animation: 150
      };
    },
    editPageState: function editPageState() {
      return this.$store.state.surveyManagement.editPageState || {};
    },
    validation: function validation() {
      return this.$store.getters['surveyManagement/editQuestionValidation'];
    },
    addNewRegularChoiceAttributeButtonDisabled: function addNewRegularChoiceAttributeButtonDisabled() {
      var hasRegularChoiceAttributes = this.internalEditQuestion.activatedRegularChoiceAttributes.length > 0;
      var hasInvalidRegularChoiceAttributes = this.validation.activatedRegularChoiceAttributes.valid === false;
      return hasRegularChoiceAttributes && hasInvalidRegularChoiceAttributes;
    },
    addNewCustomChoiceAttributeButtonDisabled: function addNewCustomChoiceAttributeButtonDisabled() {
      var hasCustomChoiceAttributes = this.internalEditQuestion.activatedCustomChoiceAttributes.length > 0;
      var hasInvalidCustomChoiceAttributes = this.validation.activatedCustomChoiceAttributes.valid === false;
      return hasCustomChoiceAttributes && hasInvalidCustomChoiceAttributes;
    }
  },
  methods: {
    setInternalEditQuestion: function setInternalEditQuestion() {
      this.internalEditQuestion = this.cloneDeep(this.editQuestion);
    },
    setInternalCurrentLanguage: function setInternalCurrentLanguage() {
      this.internalCurrentLanguage = this.currentLanguage;
    },
    onStartDraggingRegularChoiceAttribute: function onStartDraggingRegularChoiceAttribute() {
      this.isDraggingRegularChoiceAttribute = true;
      window.document.body.classList.add('cursor-grabbing');
    },
    onStartDraggingCustomChoiceAttribute: function onStartDraggingCustomChoiceAttribute() {
      this.isDraggingCustomChoiceAttribute = true;
      window.document.body.classList.add('cursor-grabbing');
    },
    onEndDragging: function onEndDragging() {
      var _this = this;
      this.isDraggingRegularChoiceAttribute = false;
      this.isDraggingCustomChoiceAttribute = false;
      window.document.body.classList.remove('cursor-grabbing');

      // reset all disabled choice attributes to its initial state (the server state or empty for new reasons)
      this.$nextTick(function () {
        if (_this.internalEditQuestion.disabledRegularChoiceAttributes) {
          _this.internalEditQuestion.disabledRegularChoiceAttributes.forEach(function (disabledRegularChoiceAttribute) {
            // clear newly created choice attributes
            if (disabledRegularChoiceAttribute.isNew) {
              disabledRegularChoiceAttribute.text = null;
            } else {
              // a disabled already existing choice attribute's text is reset to the server state
              var foundItem = _this.question.regularChoiceAttributes.find(function (item) {
                return item.choiceAttributeId === disabledRegularChoiceAttribute.id;
              });
              if (typeof foundItem !== 'undefined') {
                disabledRegularChoiceAttribute.text = _.cloneDeep(foundItem.text);
              }
            }
          });
        }
        if (_this.internalEditQuestion.disabledCustomChoiceAttributes) {
          _this.internalEditQuestion.disabledCustomChoiceAttributes.forEach(function (disabledCustomChoiceAttribute) {
            // clear newly created choice attributes
            if (disabledCustomChoiceAttribute.isNew) {
              disabledCustomChoiceAttribute.text = null;
            } else {
              // a disabled already existing choice attribute's text is reset to the server state
              var foundItem = _this.question.customChoiceAttributes.find(function (item) {
                return item.customChoiceAttributeId === disabledCustomChoiceAttribute.id;
              });
              if (typeof foundItem !== 'undefined') {
                disabledCustomChoiceAttribute.text = _.cloneDeep(foundItem.text);
              }
            }
          });
        }
      });
    },
    addNewRegularChoiceAttribute: function addNewRegularChoiceAttribute() {
      this.internalEditQuestion.activatedRegularChoiceAttributes.push({
        id: null,
        text: null,
        isNew: true,
        key: "REGULAR-NEW-".concat(Math.random())
      });
    },
    addNewCustomChoiceAttribute: function addNewCustomChoiceAttribute() {
      this.internalEditQuestion.activatedCustomChoiceAttributes.push({
        id: null,
        text: null,
        isNew: true,
        key: "CUSTOM-NEW-".concat(Math.random())
      });
    },
    cloneDeep: _.cloneDeep
  },
  watch: {
    internalEditQuestion: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalEditQuestion, this.editQuestion)) {
          this.$emit('update-edit-question', this.cloneDeep(this.internalEditQuestion));
        }
      }
    },
    editQuestion: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalEditQuestion, this.editQuestion)) {
          this.setInternalEditQuestion();
        }
      }
    },
    internalCurrentLanguage: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalCurrentLanguage, this.currentLanguage)) {
          this.$emit('change-language', this.internalCurrentLanguage);
        }
      }
    },
    currentLanguage: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalCurrentLanguage, this.currentLanguage)) {
          this.setInternalCurrentLanguage();
        }
      }
    }
  }
};