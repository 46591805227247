var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    _vm._l(_vm.errors, function (error) {
      return _c(
        "div",
        { key: error.message, staticClass: "error-message" },
        [
          _c("font-awesome", {
            staticClass: "exclamation-triangle",
            attrs: { icon: ["far", "exclamation-triangle"] },
          }),
          _vm._v(" " + _vm._s(error.message) + "\n  "),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }