var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-name" },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("form.name")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-name",
                  disabled: _vm.disabled,
                  state: _vm.getFieldState("NAME"),
                },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("NAME") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-address" },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("form.address")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "input-address",
                  rows: "4",
                  "max-rows": "6",
                  disabled: _vm.disabled,
                  state: _vm.getFieldState("ADDRESS"),
                },
                model: {
                  value: _vm.formData.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "address", $$v)
                  },
                  expression: "formData.address",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("ADDRESS") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-region" },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("form.region")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("b-form-select", {
                attrs: {
                  id: "input-region",
                  options: _vm.regions,
                  disabled: _vm.disabled,
                  state: _vm.getFieldState("REGION"),
                },
                model: {
                  value: _vm.formData.region,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "region", $$v)
                  },
                  expression: "formData.region",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("REGION") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-location-group" },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("form.locationGroup")) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "input-location-group",
                      options: _vm.locationGroups,
                      disabled: _vm.disabled,
                      state: _vm.getFieldState("LOCATION_GROUP"),
                    },
                    model: {
                      value: _vm.formData.locationGroupId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "locationGroupId", $$v)
                      },
                      expression: "formData.locationGroupId",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-secondary",
                            disabled: _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("createLocationGroup")
                            },
                          },
                        },
                        [
                          _c("font-awesome", {
                            staticClass: "font-awesome",
                            attrs: { icon: ["far", "plus"] },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("form.createLocationGroup")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("LOCATION_GROUP") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-default-language" },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("form.defaultLanguage")) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("b-form-select", {
                class: {
                  warning: !_vm.isLanguageEnabledOnSelectedSurvey(
                    _vm.formData.defaultLanguage
                  ),
                },
                attrs: {
                  id: "input-default-language",
                  options: _vm.languages,
                  disabled: _vm.disabled,
                  state: _vm.getFieldState("DEFAULT_LANGUAGE"),
                },
                model: {
                  value: _vm.formData.defaultLanguage,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "defaultLanguage", $$v)
                  },
                  expression: "formData.defaultLanguage",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("DEFAULT_LANGUAGE") },
              }),
              _vm._v(" "),
              !_vm.isLanguageEnabledOnSelectedSurvey(
                _vm.formData.defaultLanguage
              )
                ? _c(
                    "div",
                    { staticClass: "warning-message" },
                    [
                      _c("font-awesome", {
                        staticClass: "exclamation-triangle",
                        attrs: { icon: ["far", "exclamation-triangle"] },
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("defaultLanguageNotEnabledInSurvey")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-survey" },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("form.survey")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("b-form-select", {
                attrs: {
                  id: "input-survey",
                  options: _vm.surveys,
                  disabled: _vm.disabled,
                  state: _vm.getFieldState("SURVEY"),
                },
                model: {
                  value: _vm.formData.surveyId,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "surveyId", $$v)
                  },
                  expression: "formData.surveyId",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("SURVEY") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-custom-logo-dark" },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("form.customLogoDark")) + "\n      "
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.d200",
                      modifiers: { hover: true, d200: true },
                    },
                  ],
                  staticClass: "info-circle-wrapper",
                  attrs: { title: _vm.$t("tooltip.customLogoDark") },
                },
                [
                  _c("font-awesome", {
                    staticClass: "info-circle",
                    attrs: { icon: ["far", "info-circle"] },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("form-image-uploader", {
                attrs: {
                  id: "input-custom-logo-dark",
                  accept: "image/png, image/svg+xml, image/jpeg",
                  disabled: _vm.disabled,
                  state: _vm.getFieldState("CUSTOM_LOGO_DARK"),
                },
                model: {
                  value: _vm.formData.customLogoDark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "customLogoDark", $$v)
                  },
                  expression: "formData.customLogoDark",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("CUSTOM_LOGO_DARK") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-custom-logo-bright" },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("form.customLogoBright")) +
                  "\n      "
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.d200",
                      modifiers: { hover: true, d200: true },
                    },
                  ],
                  staticClass: "info-circle-wrapper",
                  attrs: { title: _vm.$t("tooltip.customLogoBright") },
                },
                [
                  _c("font-awesome", {
                    staticClass: "info-circle",
                    attrs: { icon: ["far", "info-circle"] },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("form-image-uploader", {
                attrs: {
                  id: "input-custom-logo-bright",
                  accept: "image/png, image/svg+xml, image/jpeg",
                  disabled: _vm.disabled,
                  state: _vm.getFieldState("CUSTOM_LOGO_BRIGHT"),
                },
                model: {
                  value: _vm.formData.customLogoBright,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "customLogoBright", $$v)
                  },
                  expression: "formData.customLogoBright",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("CUSTOM_LOGO_BRIGHT") },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }