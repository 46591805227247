import { render, staticRenderFns } from "./SurveyManagementPreviewChoiceQuestion.vue?vue&type=template&id=9077391a&scoped=true&"
import script from "./SurveyManagementPreviewChoiceQuestion.vue?vue&type=script&lang=js&"
export * from "./SurveyManagementPreviewChoiceQuestion.vue?vue&type=script&lang=js&"
import style0 from "./SurveyManagementPreviewChoiceQuestion.vue?vue&type=style&index=0&id=9077391a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9077391a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/opiniooon/opnn-analytics/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9077391a')) {
      api.createRecord('9077391a', component.options)
    } else {
      api.reload('9077391a', component.options)
    }
    module.hot.accept("./SurveyManagementPreviewChoiceQuestion.vue?vue&type=template&id=9077391a&scoped=true&", function () {
      api.rerender('9077391a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/survey-management/edit-question/question-preview/SurveyManagementPreviewChoiceQuestion.vue"
export default component.exports