import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      required: true,
      validator: function validator(item) {
        return ['numeric', 'alpha'].includes(item);
      }
    }
  },
  computed: {
    characters: function characters() {
      if (this.type === 'numeric') {
        return [1, 2, 3, 4];
      } else {
        return ['A', 'B', 'C', 'D'];
      }
    }
  }
};