var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "location-group-select" },
    [
      _c(
        "multiselect",
        {
          class: {
            isInForm: _vm.isInForm,
            disabled: _vm.disabled,
            "is-invalid": _vm.state === false,
          },
          attrs: {
            searchable: !_vm.isLoading && !_vm.isInternallyLoading,
            loading: _vm.isLoading || _vm.isInternallyLoading,
            disabled:
              _vm.disabled ||
              _vm.isLoading ||
              _vm.isInternallyLoading ||
              _vm.isInternallyDisabled,
            options: _vm.filteredLocations,
            multiple: true,
            limit: _vm.limit,
            "limit-text": _vm.limitItems,
            "clear-on-select": true,
            "close-on-select": true,
            "internal-search": false,
            "deselect-label": _vm.$t("pressEnterToRemove"),
            "selected-label": _vm.$t("selectedLocation"),
            placeholder: _vm.placeholder
              ? _vm.placeholder
              : _vm.$t("selectLocationsOrGroups"),
            "select-label": _vm.$t("pressEnterToSelect"),
            label: "name",
            "track-by": "key",
          },
          on: {
            "search-change": _vm.filterLocations,
            input: _vm.onInputChanged,
            close: _vm.applyLocationGroupFilter,
            remove: _vm.onInputChanged,
          },
          scopedSlots: _vm._u([
            {
              key: "tag",
              fn: function (props) {
                return [
                  _c("span", { staticClass: "custom__tag" }, [
                    _c(
                      "span",
                      {
                        class: {
                          "location-disabled":
                            props.option.type === "location" &&
                            props.option.archived,
                        },
                      },
                      [_vm._v(_vm._s(props.option.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "custom__remove",
                        on: {
                          click: function ($event) {
                            props.remove(props.option)
                            _vm.applyLocationGroupFilter()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "pos-rel" }, [
                          _c("span", { staticClass: "increase-focus" }, [
                            _vm._v("×"),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              },
            },
            {
              key: "option",
              fn: function (props) {
                return [
                  _c("div", { staticClass: "option__desc" }, [
                    _c("div", { staticClass: "option__title" }, [
                      _c(
                        "div",
                        {
                          class: {
                            "group-title": props.option.type !== "location",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(props.option.name) +
                              "\n            "
                          ),
                          props.option.type === "locationGroup"
                            ? _c(
                                "span",
                                { staticClass: "location-group-indicator" },
                                [
                                  _vm._v(
                                    "\n              – " +
                                      _vm._s(_vm.$t("locationGroup"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          props.option.type === "location" &&
                          props.option.archived
                            ? _c("span", { staticClass: "option__disabled" }, [
                                _vm._v(_vm._s(_vm.$t("archivedLocation"))),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      props.option.type === "location"
                        ? _c("div", { staticClass: "location-group-name" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(props.option.groupName) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    props.option.type === "locationGroup"
                      ? _c(
                          "div",
                          { staticClass: "location-group-locations" },
                          _vm._l(
                            props.option.locations,
                            function (location, index) {
                              return _c(
                                "span",
                                { key: location.id },
                                [
                                  index !== 0 ? [_vm._v(",")] : _vm._e(),
                                  _vm._v(" " + _vm._s(location.name)),
                                  location.archived
                                    ? _c(
                                        "span",
                                        { staticClass: "option__disabled" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("archivedLocation"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectedLocations,
            callback: function ($$v) {
              _vm.selectedLocations = $$v
            },
            expression: "selectedLocations",
          },
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("template", { slot: "noResult" }, [
            _c("i", [_vm._v(_vm._s(_vm.$t("noEntriesFound")))]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }