var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "location-group-adder" },
    [
      _c("alert", {
        staticClass: "positioned-alert",
        attrs: {
          type: "success",
          show: _vm.successfullySaved && !_vm.isSubmitting,
          icon: ["fas", "check"],
          title: _vm.$t("success.title"),
          message: _vm.$t("success.message"),
        },
      }),
      _vm._v(" "),
      _c("alert", {
        staticClass: "positioned-alert",
        attrs: {
          type: "danger",
          show: _vm.validationErrorOccurred && !_vm.isSubmitting,
          icon: ["fas", "exclamation-triangle"],
          title: _vm.$t("validationError.title"),
          message: _vm.$t("validationError.message"),
        },
      }),
      _vm._v(" "),
      _c("alert", {
        staticClass: "positioned-alert",
        attrs: {
          type: "danger",
          show: _vm.serverErrorOccurred && !_vm.isSubmitting,
          icon: ["fas", "exclamation-triangle"],
          title: _vm.$t("serverError.title"),
          message: _vm.$t("serverError.message"),
        },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-name" },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("form.name")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-name",
                  disabled: _vm.isSubmitting,
                  state: _vm.getFieldState("NAME"),
                },
                model: {
                  value: _vm.locationGroupName,
                  callback: function ($$v) {
                    _vm.locationGroupName = $$v
                  },
                  expression: "locationGroupName",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("NAME") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "cancel-button",
          attrs: { variant: "outline-secondary", disabled: _vm.isSubmitting },
          on: {
            click: function ($event) {
              return _vm.closeModal(null)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("cancelButton")) + "\n  ")]
      ),
      _vm._v(" "),
      !_vm.serverErrorOccurred
        ? _c(
            "div",
            { staticClass: "button-spinner-wrapper" },
            [
              _vm.isSubmitting
                ? _c("div", { staticClass: "spinner-wrapper" }, [
                    _c("div", { staticClass: "opn-spinner" }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "submit-button",
                  attrs: {
                    variant: "primary",
                    disabled:
                      _vm.isSubmitting ||
                      _vm.locationGroupName === null ||
                      _vm.locationGroupName.trim() === "",
                  },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("createButton")) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }