import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import Panel from '@/components/shared/Panel';
import Alert from '@/components/shared/Alert';
import { CompanySettings } from '@/services/ResourceService';
import CompanyStatisticsRow from '@/components/company-settings/CompanyStatisticsRow';
import CompanyStatisticsChart from '@/components/company-settings/CompanyStatisticsChart';
import CompanyStatisticsPieChart from '@/components/company-settings/CompanyStatisticsPieChart';
export default {
  name: 'CompanyStatistics',
  components: {
    Panel: Panel,
    Alert: Alert,
    CompanyStatisticsRow: CompanyStatisticsRow,
    CompanyStatisticsChart: CompanyStatisticsChart,
    CompanyStatisticsPieChart: CompanyStatisticsPieChart
  },
  data: function data() {
    return {
      statistics: null,
      isLoading: true,
      hasError: false,
      hasErrorNoData: false,
      selectedView: 'TOTAL'
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  computed: {
    years: function years() {
      if (this.statistics !== null) {
        return Object.keys(this.statistics.years).sort().reverse();
      }
      return [];
    },
    hasLanguageDistributionData: function hasLanguageDistributionData() {
      if (this.statistics !== null) {
        var distribution = this.statistics.total.languageDistribution;
        if (Object.keys(distribution).length > 0) {
          var hasData = false;
          Object.keys(distribution).forEach(function (key) {
            if (distribution[key] > 0) {
              hasData = true;
            }
          });
          return hasData;
        }
      }
      return false;
    }
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      this.hasError = false;
      this.hasErrorNoData = false;
      CompanySettings.fetchCompanyStatistics(this.$store.state.general.companyId).then(function (response) {
        _this.statistics = response.data;
        _this.isLoading = false;
      }, function (error) {
        _this.isLoading = false;
        if (error.response.status === 404) {
          _this.hasErrorNoData = true;
        } else {
          _this.hasError = true;
        }
      });
    },
    moment: moment
  }
};