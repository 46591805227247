var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "chart",
    staticClass: "change-over-time-chart-loading",
    style: { height: _vm.heightInPixel + "px" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }