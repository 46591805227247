var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-input",
    {
      staticClass: "form-input",
      attrs: {
        value: _vm.value,
        autocomplete: _vm.autocomplete,
        disabled: _vm.disabled,
        maxlength: _vm.maxLength,
        placeholder: _vm.placeholder,
        state: _vm.invalid ? false : null,
      },
      on: { input: _vm.changeValue, blur: _vm.onBlur },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }