//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CsvExport from '@/components/raw-export/CsvExport';
export default {
  name: 'RawExport',
  components: {
    CsvExport: CsvExport
  },
  data: function data() {
    return {};
  }
};