var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-date-and-locations" },
    [
      _c("alert", {
        attrs: {
          icon: ["fas", "exclamation-triangle"],
          type: "warning",
          title: _vm.$t("noDataError.title"),
          message: _vm.$t("noDataError.message"),
          show: _vm.noData,
          closeable: false,
        },
      }),
      _vm._v(" "),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                _c(
                  "label",
                  {
                    staticClass: "form-label",
                    attrs: { for: "input-date-range" },
                  },
                  [_vm._v(_vm._s(_vm.$t("label.dateRange")))]
                ),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { sm: "7", xl: "9" } }, [
                _c(
                  "div",
                  [
                    _c("opn-date-range-picker", {
                      attrs: {
                        value: [_vm.startDate, _vm.endDate],
                        id: "input-date-range",
                        "is-loading": _vm.isLoading,
                        disabled: _vm.isLoading,
                        "is-in-form": true,
                        "with-limited-ranges": false,
                      },
                      on: { "change-daterange": _vm.dateRangeChanged },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                _c(
                  "label",
                  {
                    staticClass: "form-label",
                    attrs: { for: "input-locations" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.locations")) +
                        " \n          "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.d200",
                            modifiers: { hover: true, d200: true },
                          },
                        ],
                        staticClass: "info-circle-wrapper",
                        attrs: { title: _vm.$t("tooltip.locations") },
                      },
                      [
                        _c("font-awesome", {
                          staticClass: "info-circle",
                          attrs: { icon: ["far", "info-circle"] },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "7", xl: "9" } },
                [
                  _c("location-group-select", {
                    attrs: {
                      id: "input-locations",
                      "pre-selected-ids": _vm.form.locations,
                      "is-loading": _vm.isLoading,
                      limit: 999,
                      "is-in-form": true,
                    },
                    on: { "change-locations": _vm.changeLocations },
                  }),
                  _vm._v(" "),
                  !_vm.currentUserIsSuperAdmin
                    ? _c("search-filter-selector", {
                        staticClass: "mt-1",
                        attrs: {
                          "is-loading": _vm.isLoading,
                          "pre-selected-search-filter-id":
                            _vm.form.locations.selectedSearchFilterId,
                        },
                        on: { "change-locations": _vm.changeLocations },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "b-button",
            {
              staticClass: "next-button",
              attrs: {
                variant: "primary",
                disabled: _vm.noData || _vm.isLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("next-step")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("nextStep")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }