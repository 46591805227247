var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      attrs: {
        "panel-title": _vm.title,
        "is-loading": _vm.isLoading,
        "loading-title-class": "w-25",
        "loading-min-height": 450,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.isLoading
              ? _c("div", { staticClass: "loading-placeholder" }, [
                  _c("div", { staticClass: "data w-75" }),
                ])
              : _c("div", [
                  _c("div", { staticClass: "matrix-table-wrapper" }, [
                    _c(
                      "table",
                      { staticClass: "matrix-table" },
                      _vm._l(_vm.tableData, function (row) {
                        return _c(
                          "tr",
                          { key: row[0].locationId },
                          _vm._l(row, function (cell) {
                            return _c(
                              "td",
                              {
                                key: cell.cellId,
                                staticClass: "cell",
                                class: {
                                  "top-left-cell": cell.isTopLeftCell,
                                  "category-cell": cell.isCategoryCell,
                                  "location-cell": cell.isLocationCell,
                                  "value-cell": cell.isValueCell,
                                },
                              },
                              [
                                cell.isTopLeftCell
                                  ? _c("div", { staticClass: "inner-div" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "cursor-pointer",
                                          on: { click: _vm.changeOrder },
                                        },
                                        [
                                          _c("span", { staticClass: "value" }, [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.groupBy === "location"
                                                      ? "locationHeader"
                                                      : "locationGroupHeader"
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("font-awesome", {
                                            attrs: {
                                              icon: [
                                                "far",
                                                "sort-alpha-" +
                                                  (_vm.order === "asc"
                                                    ? "up"
                                                    : "down"),
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : cell.isCategoryCell
                                  ? _c("div", { staticClass: "inner-div" }, [
                                      _c("span", {
                                        staticClass: "value",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$options.filters.languageAwareString(
                                              cell.categoryName
                                            )
                                          ),
                                        },
                                      }),
                                    ])
                                  : cell.isLocationCell
                                  ? _c(
                                      "div",
                                      { staticClass: "inner-div" },
                                      [
                                        _vm.groupBy === "location"
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "value cursor-pointer",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.routeToDashboardWithLocation(
                                                        cell.locationId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        cell.locationName
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm
                                                .locationIdToLocationGroupMapping[
                                                cell.locationId
                                              ]
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "location-group-name",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm
                                                              .locationIdToLocationGroupMapping[
                                                              cell.locationId
                                                            ].locationGroupName
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "value cursor-pointer",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.routeToDashboardWithLocationGroupByLocationGroupId(
                                                      cell.locationId
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(cell.locationName) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                      ],
                                      2
                                    )
                                  : cell.isValueCell && _vm.hasIndexCalculation
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "inner-div average-index",
                                        class: [
                                          _vm.getColorNameByIndex(
                                            cell.averageIndex,
                                            _vm.$store.state.general
                                              .companyProperties
                                          ),
                                        ],
                                      },
                                      [
                                        cell.averageIndex === null
                                          ? _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.bottom.d200",
                                                    modifiers: {
                                                      hover: true,
                                                      bottom: true,
                                                      d200: true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "not-available",
                                                attrs: {
                                                  title: _vm.$t("noData"),
                                                },
                                              },
                                              [_vm._v("n/a")]
                                            )
                                          : _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.bottom.d200",
                                                      modifiers: {
                                                        hover: true,
                                                        bottom: true,
                                                        d200: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "value",
                                                  attrs: {
                                                    title:
                                                      _vm.$options.filters.groupedNumber(
                                                        cell.averageIndex,
                                                        true,
                                                        4
                                                      ),
                                                  },
                                                },
                                                [
                                                  _vm.showFullMatrix
                                                    ? _c("font-awesome", {
                                                        staticClass:
                                                          "font-awesome",
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "smile",
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm._f("groupedNumber")(
                                                          cell.averageIndex,
                                                          true,
                                                          1
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.showFullMatrix
                                                ? _c(
                                                    "span",
                                                    { staticClass: "counter" },
                                                    [
                                                      _c("font-awesome", {
                                                        staticClass:
                                                          "font-awesome",
                                                        attrs: {
                                                          icon: ["fas", "user"],
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "groupedNumber"
                                                            )(
                                                              cell.counter,
                                                              false,
                                                              0
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                      ]
                                    )
                                  : cell.isValueCell && !_vm.hasIndexCalculation
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "inner-div satisfaction-rate",
                                        class:
                                          _vm.getColorNameBySatisfactionRate(
                                            cell.satisfactionRate,
                                            _vm.$store.state.general
                                              .companyProperties
                                          ),
                                      },
                                      [
                                        cell.satisfactionRate === null
                                          ? _c(
                                              "span",
                                              { staticClass: "not-available" },
                                              [_vm._v("n/a")]
                                            )
                                          : _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.bottom.d200",
                                                      modifiers: {
                                                        hover: true,
                                                        bottom: true,
                                                        d200: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass: "value",
                                                  attrs: {
                                                    title:
                                                      _vm.$options.filters.groupedNumber(
                                                        cell.satisfactionRate,
                                                        true,
                                                        4
                                                      ) + "%",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm._f("groupedNumber")(
                                                          cell.satisfactionRate,
                                                          true,
                                                          1
                                                        )
                                                      ) +
                                                      "%\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.hasIndexCalculation
                    ? _c("div", { staticClass: "legend" }, [
                        _c("div", { staticClass: "entry" }, [
                          _c("div", { staticClass: "color-box red" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n              <\n              " +
                                _vm._s(
                                  _vm.$store.state.general.companyProperties
                                    .colorClassificationThresholdLow
                                ) +
                                "%\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "entry" }, [
                          _c("div", { staticClass: "color-box orange" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n              <\n              " +
                                _vm._s(
                                  _vm.$store.state.general.companyProperties
                                    .colorClassificationThresholdHigh
                                ) +
                                "% " +
                                _vm._s(_vm.$t("and")) +
                                " ≥\n              " +
                                _vm._s(
                                  _vm.$store.state.general.companyProperties
                                    .colorClassificationThresholdLow
                                ) +
                                "%\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "entry" }, [
                          _c("div", { staticClass: "color-box green" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n              ≥\n              " +
                                _vm._s(
                                  _vm.$store.state.general.companyProperties
                                    .colorClassificationThresholdHigh
                                ) +
                                "%\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "entry" }, [
                          _c("div", { staticClass: "color-box grey" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t("noData"))),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "float-right top-right-header",
          attrs: { slot: "dropdown" },
          slot: "dropdown",
        },
        [
          _vm.isLoading || _vm.noData
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary disabled mr-1",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("font-awesome", {
                    staticClass: "font-awesome",
                    attrs: { icon: ["fas", "cloud-download-alt"] },
                  }),
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("exportButton")) + "\n    "
                  ),
                ],
                1
              )
            : _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-1",
                  on: { click: _vm.exportMatrix },
                },
                [
                  _c("font-awesome", {
                    staticClass: "font-awesome",
                    attrs: { icon: ["fas", "cloud-download-alt"] },
                  }),
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("exportButton")) + "\n    "
                  ),
                ],
                1
              ),
          _vm._v(" "),
          _vm.hasIndexCalculation
            ? _c(
                "label",
                { staticClass: "show-full-matrix" },
                [
                  _c("b-form-checkbox", {
                    attrs: { value: true, "unchecked-value": false },
                    model: {
                      value: _vm.showFullMatrix,
                      callback: function ($$v) {
                        _vm.showFullMatrix = $$v
                      },
                      expression: "showFullMatrix",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$t("showFullMatrix"))),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-form-select", {
            staticClass: "group-select",
            attrs: { options: _vm.groupingOptions },
            on: { input: _vm.onChangeGrouping },
            model: {
              value: _vm.groupBy,
              callback: function ($$v) {
                _vm.groupBy = $$v
              },
              expression: "groupBy",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }