var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    {
      staticClass: "search-filter-selector float-right",
      class: { "filter-applied": _vm.searchFiltersIsApplied },
      attrs: {
        size: "sm",
        disabled:
          _vm.isLoading || _vm.isInternallyLoading || _vm.isInternallyDisabled,
        right: "",
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c(
                "small",
                [
                  _c("font-awesome", {
                    staticClass: "font-awesome",
                    attrs: { icon: ["fas", "filter"] },
                  }),
                ],
                1
              ),
              _vm._v("\n    " + _vm._s(_vm.dropdownButtonText) + "\n  "),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("b-dropdown-header", { attrs: { id: "dropdown-header-label" } }, [
        _vm._v("\n    " + _vm._s(_vm.$t("searchFilterHeader")) + "\n  "),
      ]),
      _vm._v(" "),
      _vm._l(_vm.searchFilters, function (filter) {
        return _c(
          "b-dropdown-item",
          {
            key: filter.id,
            attrs: { active: filter.id === _vm.preSelectedSearchFilterId },
            on: {
              click: function ($event) {
                return _vm.onFilterSelected(filter)
              },
            },
          },
          [_vm._v("\n    " + _vm._s(filter.name) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _c("b-dropdown-divider"),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { on: { click: _vm.showCreateNewSearchFilterModal } },
        [_vm._v("\n    " + _vm._s(_vm.$t("saveNewFilter")) + "\n  ")]
      ),
      _vm._v(" "),
      _c("b-dropdown-item", { attrs: { to: { name: "filter-management" } } }, [
        _vm._v(_vm._s(_vm.$t("manageSearchFilters"))),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }