var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "location-edit" }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("panel", { attrs: { "is-loading": false } }, [
            _c("div", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.isCreation
                      ? _vm.$t("newLocationTitle")
                      : _vm.$t("editLocationTitle")
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("alert", {
                  staticClass: "positioned-alert",
                  attrs: {
                    type: "success",
                    show: _vm.successfullySaved && !_vm.isSubmitting,
                    icon: ["fas", "check"],
                    title: _vm.$t("success.title"),
                    message: _vm.$t(
                      _vm.isCreation
                        ? "success.createMessage"
                        : "success.editMessage"
                    ),
                  },
                }),
                _vm._v(" "),
                _c("alert", {
                  staticClass: "positioned-alert",
                  attrs: {
                    type: "danger",
                    show: _vm.validationErrorOccurred && !_vm.isSubmitting,
                    icon: ["fas", "exclamation-triangle"],
                    title: _vm.$t("validationError.title"),
                    message: _vm.$t("validationError.message"),
                  },
                }),
                _vm._v(" "),
                _c("alert", {
                  staticClass: "positioned-alert",
                  attrs: {
                    type: "danger",
                    show: _vm.serverErrorOccurred && !_vm.isSubmitting,
                    icon: ["fas", "exclamation-triangle"],
                    title: _vm.$t("serverError.title"),
                    message: _vm.$t("serverError.message"),
                  },
                }),
                _vm._v(" "),
                !_vm.isLoading && !_vm.serverErrorOccurred
                  ? _c("location-administration-form", {
                      attrs: {
                        "form-data": _vm.editedLocationData,
                        "validation-errors": _vm.validationErrors,
                        "foreign-key-values": _vm.foreignKeyValues,
                        disabled:
                          _vm.isSubmitting || _vm.isReloadingForeignKeyValues,
                      },
                      on: {
                        createLocationGroup: _vm.openLocationGroupModal,
                        submit: _vm.submitForm,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isLoading && !_vm.serverErrorOccurred
                  ? _c(
                      "div",
                      { staticClass: "submit-button-spinner-wrapper" },
                      [
                        _vm.isSubmitting || _vm.isReloadingForeignKeyValues
                          ? _c("div", { staticClass: "spinner-wrapper" }, [
                              _c("div", { staticClass: "opn-spinner" }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "submit-button",
                            attrs: {
                              variant: "primary",
                              disabled:
                                _vm.isSubmitting ||
                                _vm.isReloadingForeignKeyValues,
                            },
                            on: { click: _vm.submitForm },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.isCreation
                                    ? _vm.$t("create")
                                    : _vm.$t("save")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isLoading
                  ? _c("div", { staticClass: "loading-spinner-wrapper" }, [
                      _c("div", { staticClass: "opn-spinner" }),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }