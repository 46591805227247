//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import ModalIconButton from '@/components/shared/ModalIconButton';
import { Comparison } from '@/services/ResourceService';
import FilterCriteriaDate from '@/components/comparison/filter-criteria/FilterCriteriaDate';
import FilterCriteriaLocation from '@/components/comparison/filter-criteria/FilterCriteriaLocation';
import FilterCriteriaSurvey from '@/components/comparison/filter-criteria/FilterCriteriaSurvey';
import FilterCriteriaChoice from '@/components/comparison/filter-criteria/FilterCriteriaChoice';
import moment from 'moment';
export default {
  name: 'SelectionBox',
  props: {
    parentConfig: {
      type: Object,
      required: true
    }
  },
  components: {
    Panel: Panel,
    ModalIconButton: ModalIconButton,
    FilterCriteriaDate: FilterCriteriaDate,
    FilterCriteriaLocation: FilterCriteriaLocation,
    FilterCriteriaSurvey: FilterCriteriaSurvey,
    FilterCriteriaChoice: FilterCriteriaChoice
  },
  data: function data() {
    return {
      isLoading: true,
      data: {},
      config: {}
    };
  },
  created: function created() {
    this.initialize();
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      if (!this.config.dirty) {
        Comparison.getSelectionData(this.$store.state.general.companyId, this.config.locations.queryLocations, this.config.surveyId, this.config.choiceAttributeIds, moment(this.config.startInSeconds).format('DD.MM.YYYY'), moment(this.config.endInSeconds).format('DD.MM.YYYY')).then(function (response) {
          _this.data = response.data;
          _this.isLoading = false;
        }, function () {
          _this.data = {};
          _this.isLoading = false;
        });
      }
    },
    initialize: function initialize() {
      this.config = Object.assign({}, this.parentConfig);
    }
  },
  computed: {
    startDate: function startDate() {
      return moment(this.config.startInSeconds).format('DD.MM.YYYY');
    },
    endDate: function endDate() {
      return moment(this.config.endInSeconds).format('DD.MM.YYYY');
    }
  },
  watch: {
    parentConfig: {
      deep: true,
      handler: function handler() {
        this.initialize();
        this.loadData();
      }
    }
  }
};