import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Goals',
  methods: {
    createGoal: function createGoal() {
      this.$router.push({
        name: 'goal-create'
      });
    },
    navigateToOverview: function navigateToOverview() {
      this.$router.push({
        name: 'goal-overview'
      });
    }
  },
  data: function data() {
    return {
      routerKey: 0
    };
  },
  computed: {
    isInOverview: function isInOverview() {
      return this.$route.name === 'goal-overview';
    }
  },
  watch: {
    $route: function $route(to, from) {
      // Reset component when going from goal creation to editing (to force props to update)
      if (from.name === 'goal-create' && to.name === 'goal-edit') {
        this.routerKey++;
      }
    }
  }
};