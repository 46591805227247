var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internalCurrentLanguage !== null
    ? _c(
        "div",
        {
          staticClass: "survey-management-edit-attribute",
          class: {
            disabled: _vm.disabled,
            "has-index": _vm.index !== null,
            "has-dragging": _vm.hasDragging,
            deleted: _vm.deleted,
          },
        },
        [
          _vm.index !== null
            ? _c("div", { staticClass: "number-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "number" },
                  [
                    _vm.index === "disabled"
                      ? _c("font-awesome", {
                          attrs: { icon: ["fas", "times"] },
                        })
                      : !isNaN(_vm.index)
                      ? [_vm._v(_vm._s(_vm.index + 1))]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.isNew
                  ? _c(
                      "div",
                      { staticClass: "asterisk" },
                      [
                        _c("font-awesome", {
                          attrs: { icon: ["fas", "star-of-life"] },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _vm.hasDragging
                ? _c("div", { staticClass: "drop-here" }, [
                    _vm._v(_vm._s(_vm.$t("dropHere"))),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasDragging
                ? _c(
                    "div",
                    { staticClass: "drag-icon" },
                    _vm._l(8, function (point) {
                      return _c("div", {
                        key: point,
                        staticClass: "point",
                        class: {
                          "last-row": [7, 8].includes(point),
                          even: point % 2 === 0,
                        },
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.custom
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.top.d200",
                          modifiers: { hover: true, top: true, d200: true },
                        },
                      ],
                      staticClass: "custom",
                      attrs: { title: _vm.$t("tooltip.customInfo") },
                    },
                    [
                      _c("font-awesome", {
                        attrs: { icon: ["fas", "comment-edit"] },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.deleted
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.top.d200",
                          modifiers: { hover: true, top: true, d200: true },
                        },
                      ],
                      staticClass: "deleted",
                      class: { "with-custom": _vm.custom },
                      attrs: { title: _vm.$t("tooltip.deletedInfo") },
                    },
                    [
                      _c("font-awesome", {
                        attrs: { icon: ["fas", "trash-alt"] },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("survey-management-las-wrapper", {
                attrs: {
                  disabled: _vm.disabled || _vm.disabledLas,
                  optional: _vm.optionalLas,
                  las: _vm.internalLas,
                  "enabled-languages":
                    _vm.$store.state.surveyManagement.fullSurvey.data
                      .enabledLanguages,
                  "current-language": _vm.internalCurrentLanguage,
                  invalid: _vm.invalid,
                },
                on: {
                  change: function ($event) {
                    _vm.internalLas = $event
                  },
                  "change-language": function ($event) {
                    _vm.internalCurrentLanguage = $event
                  },
                },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }