//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalIconButton from '@/components/shared/ModalIconButton';
import FormInput from '@/components/shared/form-input/FormInput';
export default {
  name: 'Confirm',
  props: {
    okayButton: {
      type: String,
      required: false,
      default: ''
    },
    dismissButton: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: true
    },
    textIsHtml: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    requireMessage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    FormInput: FormInput,
    ModalIconButton: ModalIconButton
  },
  computed: {
    // not possible to use default props i18n values
    okayDefaultInternally: function okayDefaultInternally() {
      if (this.okayButton) {
        return this.okayButton;
      }
      return this.$t('okayDefault');
    },
    dismissDefaultInternally: function dismissDefaultInternally() {
      if (this.dismissButton) {
        return this.dismissButton;
      }
      return this.$t('dismissDefault');
    }
  },
  data: function data() {
    return {
      message: ''
    };
  },
  methods: {
    closeModal: function closeModal() {
      this.$vuedals.close();
    },
    submit: function submit() {
      this.$vuedals.close({
        okay: true,
        message: this.requireMessage ? this.message : undefined
      });
    }
  }
};