//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SurveyManagementNewAttribute',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: null
    }
  }
};