//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Component as Vuedal } from 'vuedals';
import ProgressBar from '@/components/main/ProgressBar';
import Navbar from '@/components/main/Navbar';
import Sidebar from '@/components/main/Sidebar';
import { EventBus } from '@/config/EventBus';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
export default {
  name: 'App',
  components: {
    LoadingIndicator: LoadingIndicator,
    Vuedal: Vuedal,
    Navbar: Navbar,
    Sidebar: Sidebar,
    ProgressBar: ProgressBar
  },
  data: function data() {
    return {
      initialLoadingCompleted: false,
      blockResizeEventThrowing: false
    };
  },
  computed: {
    isAuthenticated: function isAuthenticated() {
      return this.$store.state.general.isAuthenticated;
    },
    underMaintenance: function underMaintenance() {
      return this.$store.state.general.underMaintenance;
    },
    printView: function printView() {
      return this.$store.state.general.printView;
    }
  },
  created: function created() {
    var _this = this;
    window.addEventListener('resize', function () {
      // limits the event to be emitted to the EventBus only every 300ms
      if (!_this.blockResizeEventThrowing) {
        _this.blockResizeEventThrowing = true;
        var size = {
          width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
          height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        };
        EventBus.$emit('window-resize', size);
        window.setTimeout(function () {
          if (_this) {
            _this.blockResizeEventThrowing = false;
          }
        }, 300);
      }
    });
  },
  mounted: function mounted() {
    this.initialLoadingCompleted = true;
  }
};