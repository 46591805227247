import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4langEn from '@amcharts/amcharts4/lang/en';
import am4langDeCh from '@amcharts/amcharts4/lang/de_CH';
import am4langFrFr from '@amcharts/amcharts4/lang/fr_FR';
import am4langItIt from '@amcharts/amcharts4/lang/it_IT';
import moment from 'moment';
import colorVariables from '@/styles/_variables.scss';
export default {
  name: 'CompanyStatisticsChart',
  props: {
    data: {
      type: Object,
      required: true,
      validator: function validator(item) {
        return item.year !== undefined && item.oneYear !== undefined && item.monthlyDistribution !== undefined;
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  computed: {
    enrichedData: function enrichedData() {
      var _this = this;
      return Object.keys(this.data.monthlyDistribution).map(function (key) {
        var item = _this.data.monthlyDistribution[key];
        var newItem = {};
        newItem.date = Date.parse(item.firstDayOfMonth);
        newItem.feedbacks = item.numberOfFeedbacksWithoutSpam;
        newItem.comments = item.numberOfCommentsWithoutSpam;
        return newItem;
      });
    }
  },
  mounted: function mounted() {
    this.initChart();
  },
  methods: {
    initChart: function initChart() {
      this.chart = this.createChart(this.$refs.chart, this.enrichedData, this.data.oneYear, this.$store.state.general.language, {
        month: this.$t('tooltip.month'),
        numberOfFeedbacks: this.$t('tooltip.numberOfFeedbacks'),
        numberOfComments: this.$t('tooltip.numberOfComments')
      });
    },
    createChart: function createChart(element, data, oneYear, language, translatedStrings) {
      var chart = am4core.create(element, am4charts.XYChart);
      chart.tooltip.numberFormatter.numberFormat = '#,###.#';
      chart.data = data;
      chart.language.locale = function () {
        if (language === 'de') {
          return am4langDeCh;
        } else if (language === 'fr') {
          return am4langFrFr;
        } else if (language === 'it') {
          return am4langItIt;
        } else {
          return am4langEn;
        }
      }();
      chart.paddingTop = 45;
      chart.paddingLeft = 10;
      chart.paddingBottom = 5;
      chart.paddingRight = 25;
      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.cursorTooltipEnabled = false;
      dateAxis.baseInterval = {
        timeUnit: 'month',
        count: 1
      };
      dateAxis.renderer.line.strokeOpacity = 1;
      dateAxis.renderer.line.strokeWidth = 1;
      dateAxis.renderer.line.stroke = am4core.color('#b5b5b5');
      dateAxis.renderer.labels.template.fontSize = 12;
      dateAxis.renderer.grid.template.stroke = am4core.color('#f2f2f2');
      dateAxis.renderer.grid.template.strokeOpacity = 1;
      dateAxis.renderer.grid.template.strokeWidth = 1;
      if (oneYear) {
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.dateFormats.setKey('month', 'MM');
        dateAxis.periodChangeDateFormats.setKey('month', 'MM');
      } else {
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.dateFormats.setKey('month', 'MM.yyyy');
        dateAxis.periodChangeDateFormats.setKey('month', 'MM.yyyy');
      }
      var amountAxis = chart.yAxes.push(new am4charts.ValueAxis());
      amountAxis.min = 0;
      amountAxis.maxPrecision = 0;
      amountAxis.cursorTooltipEnabled = false;
      amountAxis.renderer.labels.template.fontSize = 12;
      amountAxis.renderer.ticks.template.disabled = false;
      amountAxis.renderer.ticks.template.strokeOpacity = 1;
      amountAxis.renderer.ticks.template.stroke = am4core.color('#b5b5b5');
      amountAxis.renderer.ticks.template.strokeWidth = 1;
      amountAxis.renderer.ticks.template.length = 5;
      amountAxis.renderer.line.strokeOpacity = 1;
      amountAxis.renderer.line.strokeWidth = 1;
      amountAxis.renderer.line.stroke = am4core.color('#b5b5b5');
      amountAxis.renderer.grid.template.stroke = am4core.color('#f2f2f2');
      amountAxis.renderer.grid.template.strokeOpacity = 1;
      amountAxis.renderer.grid.template.strokeWidth = 1;
      var feedbackSeries = chart.series.push(new am4charts.StepLineSeries());
      feedbackSeries.dataFields.dateX = 'date';
      feedbackSeries.dataFields.valueY = 'feedbacks';
      feedbackSeries.fill = am4core.color(colorVariables['primaryColor']);
      feedbackSeries.fillOpacity = 0.4;
      feedbackSeries.stroke = am4core.color(colorVariables['primaryColor']);
      feedbackSeries.strokeWidth = 2;
      feedbackSeries.tooltipText = "".concat(translatedStrings.month, ": [bold]{date.formatDate('MMMM yyyy')}[/]\n").concat(translatedStrings.numberOfFeedbacks, ": [bold]{feedbacks}[/]\n").concat(translatedStrings.numberOfComments, ": [bold]{comments}[/]");
      feedbackSeries.tooltip.fontSize = 12;
      feedbackSeries.tooltip.getFillFromObject = false;
      feedbackSeries.tooltip.background.fill = am4core.color('#333');
      feedbackSeries.tooltip.background.strokeWidth = 0;
      feedbackSeries.tooltip.background.cornerRadius = 3;
      feedbackSeries.tooltip.background.pointerLength = 4;
      var commentSeries = chart.series.push(new am4charts.StepLineSeries());
      commentSeries.dataFields.dateX = 'date';
      commentSeries.dataFields.valueY = 'comments';
      commentSeries.fill = am4core.color(colorVariables['primaryColorDark']).lighten(0.1);
      commentSeries.fillOpacity = 1;
      commentSeries.stroke = am4core.color(colorVariables['primaryColorDark']);
      commentSeries.strokeWidth = 2;
      var today = moment();
      if (today.isAfter(data[0].date) && today.isBefore(data[data.length - 1].date)) {
        var midCurrentMonth = moment().startOf('month').add(moment().daysInMonth() / 2, 'days');
        var todayLine = dateAxis.axisRanges.create();
        todayLine.value = midCurrentMonth;
        todayLine.grid.stroke = am4core.color('black');
        todayLine.grid.strokeWidth = 1;
        todayLine.label.text = midCurrentMonth.format('MMMM YYYY').toUpperCase();
        todayLine.label.inside = true;
        todayLine.label.verticalCenter = 'middle';
        todayLine.label.fill = todayLine.grid.stroke;
        todayLine.label.fontSize = 10;
        todayLine.label.rotation = -90;
        todayLine.label.dx = -8;
        todayLine.label.valign = 'middle';
      }
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = 'none';
      chart.cursor.xAxis = dateAxis;
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.strokeWidth = 0;
      chart.cursor.lineX.fill = am4core.color('black');
      chart.cursor.lineX.fillOpacity = 0.05;
      chart.cursor.fullWidthLineX = true;
      return chart;
    },
    resetChart: function resetChart() {
      if (this.chart !== null) {
        this.chart.dispose();
        this.chart = null;
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.resetChart();
  },
  watch: {
    data: {
      deep: true,
      handler: function handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.resetChart();
          this.initChart();
        }
      }
    }
  }
};