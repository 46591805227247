import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import FormInput from '@/components/shared/form-input/FormInput';
import FormMultiSelect from '@/components/shared/form-input/FormMultiSelect';
import ModalIconButton from '@/components/shared/ModalIconButton';
import { SurveyManagement } from '@/services/ResourceService';
export default {
  name: 'SurveyManagementCreateEditSurveyModal',
  components: {
    Alert: Alert,
    FormInput: FormInput,
    FormMultiSelect: FormMultiSelect,
    ModalIconButton: ModalIconButton
  },
  data: function data() {
    return {
      isLoading: false,
      errorOccurred: false,
      surveyName: null,
      selectedLanguages: [],
      initiallySelectedLanguageValues: [],
      showUpdateTranslationsAlert: false
    };
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.data) {
      this.selectedLanguages = this.data.enabledLanguages.map(function (languageAbbr) {
        return {
          value: languageAbbr,
          name: _this.$t('languages.' + languageAbbr.toLowerCase())
        };
      });
      this.surveyName = this.data.surveyName;
      this.initiallySelectedLanguageValues = this.selectedLanguages.map(function (l) {
        return l.value;
      });
    }
  },
  computed: {
    availableLanguages: function availableLanguages() {
      var _this2 = this;
      return this.$store.state.general.possibleLanguagesLas.map(function (language) {
        return {
          value: language,
          name: _this2.$t('languages.' + language.toLowerCase())
        };
      });
    },
    isCreateSurveyModal: function isCreateSurveyModal() {
      return this.data === null;
    },
    isValid: function isValid() {
      if (this.surveyName === null) {
        return false;
      }
      return this.surveyName.trim().length > 0 && this.selectedLanguages.length > 0;
    }
  },
  methods: {
    createOrSaveSurvey: function createOrSaveSurvey() {
      this.isLoading = true;
      this.errorOccurred = false;
      var languageCodes = [];
      this.selectedLanguages.forEach(function (language) {
        languageCodes.push(language.value);
      });
      var dto = {
        surveyName: this.surveyName.trim(),
        enabledLanguages: languageCodes
      };
      if (this.isCreateSurveyModal) {
        // Create a new survey
        SurveyManagement.createNewSurvey(this.$store.state.general.companyId, dto).then(this.handleCreateSurveySuccess, this.handleCreateUpdateSurveyError);
      } else {
        // Update existing survey
        SurveyManagement.updateSurveyNameAndLanguages(this.$store.state.general.companyId, this.data.surveyId, dto).then(this.handleUpdateSurveySuccess, this.handleCreateUpdateSurveyError);
      }
    },
    handleCreateSurveySuccess: function handleCreateSurveySuccess(response) {
      var newSurveyId = response.data.surveyId;
      this.isLoading = false;
      this.$vuedals.close();
      this.$router.push({
        name: 'survey-management-edit',
        params: {
          surveyId: newSurveyId
        }
      });
    },
    handleUpdateSurveySuccess: function handleUpdateSurveySuccess() {
      var _this3 = this;
      this.$vuedals.close();
      this.$store.dispatch('surveyManagement/fetchFullSurvey').then(function () {
        _this3.isLoading = false;
      });
    },
    handleCreateUpdateSurveyError: function handleCreateUpdateSurveyError() {
      this.isLoading = false;
      this.errorOccurred = true;
    },
    surveyLanguagesChanged: function surveyLanguagesChanged(newSelectedLanguages) {
      var _this4 = this;
      this.showUpdateTranslationsAlert = !newSelectedLanguages.every(function (l) {
        return _this4.initiallySelectedLanguageValues.includes(l.value);
      });
      this.selectedLanguages = newSelectedLanguages;
    },
    closeModal: function closeModal() {
      this.$vuedals.close({
        adapted: this.adapted
      });
    }
  }
};