import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ValidationErrorList from '@/components/shared/ValidationErrorList';
import ValidationHelperService from '@/services/ValidationHelperService';
import LocationsGroupSelect from '@/components/shared/LocationGroupSelect';
import FormImageUploader from '@/components/shared/FormImageUploader';
import LanguageAwareStringInput from '@/components/shared/form-input/LanguageAwareStringInput';
import { fieldNames as _fieldNames } from '@/constants/web-source-management/webSourceFieldNames.const';
import _ from 'lodash';
import DataUtils from '@/services/utils/DataUtils';
import { EMBEDDED_TYPE } from '@/constants/embeddedTypes.const';
export default {
  name: 'WebSourceEditForm',
  components: {
    FormImageUploader: FormImageUploader,
    LocationsGroupSelect: LocationsGroupSelect,
    ValidationErrorList: ValidationErrorList,
    LanguageAwareStringInput: LanguageAwareStringInput
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    isCreation: {
      type: Boolean,
      required: true
    },
    validationErrors: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    activeSurveyLanguages: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      internalCurrentLanguage: 'DE',
      languagesShort: ['EN', 'DE', 'FR', 'IT', 'ES', 'PT', 'MK', 'SQ', 'TR'],
      embeddedTypes: [{
        value: EMBEDDED_TYPE.NOT_EMBEDDED,
        text: this.$t('embeddedTypes.notEmbedded')
      }, {
        value: EMBEDDED_TYPE.EMBEDDED_IFRAME,
        text: this.$t('embeddedTypes.iframe')
      }]
    };
  },
  computed: {
    hasMultipleLocations: function hasMultipleLocations() {
      var _this$formData, _this$formData$fieldN;
      return ((_this$formData = this.formData) === null || _this$formData === void 0 ? void 0 : (_this$formData$fieldN = _this$formData[_fieldNames.LOCATIONS]) === null || _this$formData$fieldN === void 0 ? void 0 : _this$formData$fieldN.length) > 1;
    },
    fieldNames: function fieldNames() {
      return _fieldNames;
    },
    languages: function languages() {
      var _this = this;
      var languages = [];
      this.languagesShort.forEach(function (language) {
        var value = language;
        var text = _this.$t('languages.' + language.toLowerCase());
        var disabled = !_this.isLanguageInActiveSurvey(language);
        languages.push({
          value: value,
          text: text,
          disabled: disabled
        });
      });
      return _.sortBy(languages, ['text']);
    },
    enabledLanguages: function enabledLanguages() {
      return this.formData.enabledLanguages ? this.formData.enabledLanguages : this.activeSurveyLanguages;
    },
    preselectedIds: function preselectedIds() {
      var _this$formData2;
      return {
        locations: (_this$formData2 = this.formData) === null || _this$formData2 === void 0 ? void 0 : _this$formData2[_fieldNames.LOCATIONS]
      };
    },
    parsedValidationErrors: function parsedValidationErrors() {
      var validationErrorsCopy = _.cloneDeep(this.validationErrors);
      if (validationErrorsCopy != null) {
        validationErrorsCopy.forEach(function (error) {
          if (error.fields.includes(_fieldNames.LOCATION_PICKER.LOGO_DARK) || error.fields.includes(_fieldNames.LOCATION_PICKER.LOGO_BRIGHT)) {
            if (error.errorType === 'FILE_TOO_LARGE') {
              error.errorPayload = DataUtils.formatNumberOfBytes(error.errorPayload);
            } else if (error.errorType === 'INVALID_FILE_TYPE') {
              error.errorPayload = error.errorPayload.join(', ');
            }
          }
        });
        return ValidationHelperService.parseValidationErrorsWithTranslations(validationErrorsCopy, this);
      }
      return null;
    },
    hasValidationErrorsPickerText: function hasValidationErrorsPickerText() {
      return this.parsedValidationErrors.hasOwnProperty(_fieldNames.LOCATION_PICKER.LOCATION_PICKER_TEXT);
    },
    hasValidationErrorsGroupPickerText: function hasValidationErrorsGroupPickerText() {
      return this.parsedValidationErrors.hasOwnProperty(_fieldNames.LOCATION_PICKER.LOCATION_GROUP_PICKER_TEXT) && this.formData[_fieldNames.LOCATION_PICKER.LOCATION_GROUP_PICKER_ENABLED];
    }
  },
  methods: {
    getFieldErrors: function getFieldErrors(fieldName) {
      return ValidationHelperService.getFieldErrors(fieldName, this.parsedValidationErrors);
    },
    getFieldState: function getFieldState(fieldName) {
      return ValidationHelperService.getFieldState(fieldName, this.parsedValidationErrors);
    },
    locationsChanged: function locationsChanged(newFilter) {
      this.formData[_fieldNames.LOCATIONS] = newFilter.queryLocations;
    },
    isLanguageInActiveSurvey: function isLanguageInActiveSurvey(language) {
      var _this$formData$enable;
      return ((_this$formData$enable = this.formData.enabledLanguages) === null || _this$formData$enable === void 0 ? void 0 : _this$formData$enable.length) > 0 ? this.formData.enabledLanguages.includes(language) : this.activeSurveyLanguages.includes(language);
    },
    changeLanguage: function changeLanguage(payload) {
      if (payload) {
        this.internalCurrentLanguage = payload;
      }
    }
  }
};