//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Alert',
  data: function data() {
    return {
      internallyShow: true
    };
  },
  props: {
    icon: {
      type: Array,
      required: false,
      default: function _default() {
        return null;
      }
    },
    iconTransform: {
      type: String,
      required: false,
      default: ''
    },
    iconMask: {
      type: Array,
      required: false,
      default: function _default() {
        return null;
      }
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    closeable: {
      type: Boolean,
      required: false,
      default: true
    },
    show: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    hasIconSlot: function hasIconSlot() {
      return !!this.$slots['icon'];
    }
  },
  watch: {
    show: function show() {
      // if the show parameter changes from outside, the closing with
      // the X symbol is ignored and the message is shown again
      if (this.show) {
        this.internallyShow = true;
      }
    }
  }
};