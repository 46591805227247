import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import Translation from '@/config/Translation';
import SurveyManagementQuestionTypeAlphaNumericIcon from '@/components/survey-management/icons/SurveyManagementQuestionTypeAlphaNumericIcon';
export var getAllQuestionTypes = function getAllQuestionTypes() {
  return [{
    value: 'RATING_QUESTION_SMILEY',
    text: Translation.t('surveyManagementQuestionType.RATING_QUESTION_SMILEY'),
    icon: {
      fa: ['fal', 'smile']
    },
    visibleInSelect: true
  }, {
    value: 'RATING_QUESTION_SLIDER',
    text: Translation.t('surveyManagementQuestionType.RATING_QUESTION_SLIDER'),
    icon: {
      fa: ['fal', 'sliders-h-square']
    },
    visibleInSelect: true
  }, {
    value: 'CHOICE_QUESTION_SINGLE_CHOICE',
    text: Translation.t('surveyManagementQuestionType.CHOICE_QUESTION_SINGLE_CHOICE'),
    icon: {
      fa: ['fal', 'check-circle']
    },
    visibleInSelect: true
  }, {
    value: 'CHOICE_QUESTION_MULTIPLE_CHOICE',
    text: Translation.t('surveyManagementQuestionType.CHOICE_QUESTION_MULTIPLE_CHOICE'),
    icon: {
      fa: ['fal', 'check-square']
    },
    visibleInSelect: true
  }, {
    value: 'COMMENT_QUESTION',
    text: Translation.t('surveyManagementQuestionType.COMMENT_QUESTION'),
    icon: {
      fa: ['fal', 'comment-lines']
    },
    visibleInSelect: true
  }, {
    value: 'EMAIL_QUESTION',
    text: Translation.t('surveyManagementQuestionType.EMAIL_QUESTION'),
    icon: {
      fa: ['fal', 'at']
    },
    visibleInSelect: true
  }, {
    value: 'TEXT_QUESTION_SHORT_TEXT',
    text: Translation.t('surveyManagementQuestionType.TEXT_QUESTION_SHORT_TEXT'),
    icon: {
      component: SurveyManagementQuestionTypeAlphaNumericIcon,
      componentType: 'alpha'
    },
    visibleInSelect: true
  }, {
    value: 'TEXT_QUESTION_LONG_TEXT',
    text: Translation.t('surveyManagementQuestionType.TEXT_QUESTION_LONG_TEXT'),
    icon: {
      component: SurveyManagementQuestionTypeAlphaNumericIcon,
      componentType: 'alpha'
    },
    visibleInSelect: true
  }, {
    value: 'NUMBER_QUESTION',
    text: Translation.t('surveyManagementQuestionType.NUMBER_QUESTION'),
    icon: {
      component: SurveyManagementQuestionTypeAlphaNumericIcon,
      componentType: 'numeric'
    },
    visibleInSelect: true
  }, {
    value: 'DATE_QUESTION',
    text: Translation.t('surveyManagementQuestionType.DATE_QUESTION'),
    icon: {
      fa: ['fal', 'calendar-alt']
    },
    visibleInSelect: true
  }, {
    value: 'BARCODE_QUESTION',
    text: Translation.t('surveyManagementQuestionType.BARCODE_QUESTION'),
    icon: {
      fa: ['far', 'barcode-read']
    },
    visibleInSelect: false
  }];
};
export var getQuestionType = function getQuestionType(value) {
  var allTypes = getAllQuestionTypes();
  var type = allTypes.find(function (item) {
    return item.value === value;
  });
  if (typeof type !== 'undefined') {
    return type;
  }
  return null;
};