var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "filter-criteria-choice-wrapper",
      class: { disabled: _vm.disabled, short: _vm.short },
      on: { click: _vm.clicked },
    },
    [
      _c(
        "filter-criteria",
        { attrs: { variant: _vm.active ? "light-blue" : "white" } },
        [
          _c("font-awesome", {
            staticClass: "font-awesome",
            attrs: { icon: ["fas", "filter"] },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [_vm._t("default")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }