import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GoalDetailPanel from '@/components/goal/GoalDetailPanel';
import GoalDetailChart from '@/components/goal/GoalDetailChart';
import { Goal as GoalResource } from '@/services/ResourceService';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
export default {
  name: 'GoalDetails',
  components: {
    GoalDetailPanel: GoalDetailPanel,
    GoalDetailChart: GoalDetailChart
  },
  props: {
    goalId: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      hasError: false,
      isLoading: true,
      isLoadingChart: true,
      hasUserSelected: false,
      goalData: {},
      chartData: []
    };
  },
  mounted: function mounted() {
    this.initializePage();
  },
  computed: {
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    }
  },
  methods: {
    initializePage: function initializePage() {
      this.isLoading = true;
      this.isLoadingChart = true;
      if (this.$store.state.general.selectedUser && this.$store.state.general.selectedUser.id) {
        this.hasUserSelected = true;
        this.loadData();
      }
    },
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      this.isLoadingChart = true;
      this.hasError = false;
      this.goalData = {};
      this.chartData = [];
      var userId = this.$store.state.general.selectedUser.id;
      Promise.all([GoalResource.getDetails(userId, this.goalId), CompanyPropertiesService.update()]).then(function (response) {
        _this.goalData = response[0].data.goalData;
        _this.chartData = response[0].data.chartData.data;
        _this.isLoading = false;
        setTimeout(function () {
          // Delay loading chart to prevent lag during transition
          _this.isLoadingChart = false;
        }, 500);
      }, function () {
        _this.isLoading = false;
        _this.isLoadingChart = false;
        _this.hasError = true;
      });
    }
  }
};