var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    { attrs: { "panel-title": _vm.$t("changeUserData"), "is-loading": false } },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("serverError.title"),
              message: _vm.$t("serverError.message"),
              show: _vm.serverErrorOccurred && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "check"],
              type: "success",
              title: _vm.$t("success.title"),
              message: _vm.$t("success.message"),
              show: _vm.successfullySaved && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("validationError.title"),
              message: _vm.$t("validationError.message"),
              show: _vm.validationErrorOccurred && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "input-first-name" },
                      },
                      [_vm._v(_vm._s(_vm.$t("label.firstName")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { xl: "9", sm: "7" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-first-name",
                          type: "text",
                          autocomplete: "given-name",
                          state: _vm.getFieldState("FIRST_NAME"),
                          placeholder: _vm.$t("placeholder.firstName"),
                          disabled: _vm.isLoading,
                        },
                        model: {
                          value: _vm.form.firstName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "firstName", $$v)
                          },
                          expression: "form.firstName",
                        },
                      }),
                      _vm._v(" "),
                      _c("validation-error-list", {
                        attrs: { errors: _vm.getFieldErrors("FIRST_NAME") },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "input-last-name" },
                      },
                      [_vm._v(_vm._s(_vm.$t("label.lastName")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { xl: "9", sm: "7" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-last-name",
                          type: "text",
                          autocomplete: "last-name",
                          state: _vm.getFieldState("LAST_NAME"),
                          placeholder: _vm.$t("placeholder.lastName"),
                          disabled: _vm.isLoading,
                        },
                        model: {
                          value: _vm.form.lastName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "lastName", $$v)
                          },
                          expression: "form.lastName",
                        },
                      }),
                      _vm._v(" "),
                      _c("validation-error-list", {
                        attrs: { errors: _vm.getFieldErrors("LAST_NAME") },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.login !== _vm.form.email
                ? _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "input-login" },
                          },
                          [_vm._v(_vm._s(_vm.$t("label.login")))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { xl: "9", sm: "7" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-login",
                              type: "text",
                              placeholder: _vm.$t("placeholder.login"),
                              disabled: true,
                            },
                            model: {
                              value: _vm.form.login,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "login", $$v)
                              },
                              expression: "form.login",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "input-email" },
                      },
                      [_vm._v(_vm._s(_vm.$t("label.email")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { xl: "9", sm: "7" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-email",
                          type: "email",
                          placeholder: _vm.$t("placeholder.email"),
                          disabled: true,
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "select-language" },
                      },
                      [_vm._v(_vm._s(_vm.$t("label.language")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { xl: "9", sm: "7" } },
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: {
                            id: "select-language",
                            disabled: _vm.isLoading,
                          },
                          model: {
                            value: _vm.form.language,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "language", $$v)
                            },
                            expression: "form.language",
                          },
                        },
                        [
                          _c("option", { attrs: { value: "DE" } }, [
                            _vm._v(_vm._s(_vm.$t("languages.de"))),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "EN" } }, [
                            _vm._v(_vm._s(_vm.$t("languages.en"))),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "FR" } }, [
                            _vm._v(_vm._s(_vm.$t("languages.fr"))),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "IT" } }, [
                            _vm._v(_vm._s(_vm.$t("languages.it"))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-right",
                  attrs: { variant: "primary", disabled: _vm.isLoading },
                  on: { click: _vm.saveInfo },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("saveInfo")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }