import { render, staticRenderFns } from "./SurveyManagementEditPlaceholder.vue?vue&type=template&id=43e5c10b&"
import script from "./SurveyManagementEditPlaceholder.vue?vue&type=script&lang=js&"
export * from "./SurveyManagementEditPlaceholder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SurveyManagementEditPlaceholder.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43e5c10b')) {
      api.createRecord('43e5c10b', component.options)
    } else {
      api.reload('43e5c10b', component.options)
    }
    module.hot.accept("./SurveyManagementEditPlaceholder.vue?vue&type=template&id=43e5c10b&", function () {
      api.rerender('43e5c10b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/survey-management/edit-question/questions/SurveyManagementEditPlaceholder.vue"
export default component.exports