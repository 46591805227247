var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "survey-select" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("div", { staticClass: "survey-select-title" }, [
          _vm._v(_vm._s(_vm.$t("title")) + ":"),
        ]),
        _vm._v(" "),
        _c(
          "b-dropdown",
          {
            staticClass: "dropdown",
            attrs: {
              variant: "primary",
              "no-caret": _vm.list.length === 1,
              disabled: _vm.isLoading,
            },
          },
          [
            _c("template", { slot: "button-content" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.selectedSurvey.surveyName) +
                  "\n        "
              ),
              _c(
                "span",
                { staticClass: "answer-set-counter" },
                [
                  _c("font-awesome", {
                    staticClass: "font-awesome",
                    attrs: { icon: ["fas", "user"] },
                  }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.selectedSurvey.answerSetCounter) +
                      "\n        "
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.list, function (item) {
              return _c(
                "b-dropdown-item",
                {
                  key: item.surveyId,
                  attrs: { disabled: item.surveyId === _vm.selectedSurveyId },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("change-survey-id", item.surveyId)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(item.surveyName) + "\n\n        "
                  ),
                  _c(
                    "span",
                    { staticClass: "answer-set-counter" },
                    [
                      _c("font-awesome", {
                        staticClass: "font-awesome",
                        attrs: { icon: ["fas", "user"] },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(item.answerSetCounter) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }