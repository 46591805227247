//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FormSelectItemGroup',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    }
  }
};