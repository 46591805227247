var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-management-editing-question-wrapper" },
    [
      _c(
        "survey-management-panel",
        {
          staticClass: "survey-management-editing-question",
          attrs: { padded: true },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm.editPageState.data.subpage === "EDIT" &&
                  !_vm.editPageState.data.isNew
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("title.edit")) +
                            "\n      "
                        ),
                      ]
                    : _vm.editPageState.data.subpage === "EDIT" &&
                      _vm.editPageState.data.isNew
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("title.editNew")) +
                            "\n      "
                        ),
                      ]
                    : _vm.editPageState.data.subpage === "RATING_REASONS" &&
                      !_vm.editPageState.data.isNew
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("title.reasons")) +
                            "\n      "
                        ),
                      ]
                    : _vm.editPageState.data.subpage === "RATING_REASONS" &&
                      _vm.editPageState.data.isNew
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("title.reasonsNew")) +
                            "\n      "
                        ),
                      ]
                    : _vm.editPageState.data.subpage === "CHOICE_ATTRIBUTES" &&
                      !_vm.editPageState.data.isNew
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("title.choiceAttributes")) +
                            "\n      "
                        ),
                      ]
                    : _vm.editPageState.data.subpage === "CHOICE_ATTRIBUTES" &&
                      _vm.editPageState.data.isNew
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("title.choiceAttributesNew")) +
                            "\n      "
                        ),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.isLoadingCategories
            ? _c("loading-indicator", { attrs: { "has-overlay": "" } })
            : _vm._e(),
          _vm._v(" "),
          _vm._v(" "),
          _c("modal-icon-button", {
            attrs: {
              index: 0,
              icon: ["far", "times"],
              "color-type": "danger",
              message: _vm.$t("button.cancel"),
              disabled: _vm.initial,
              "horizontal-in-box": "",
            },
            on: { click: _vm.cancel },
          }),
          _vm._v(" "),
          _vm.hasCategoryError
            ? _c("alert", {
                attrs: {
                  icon: ["fas", "exclamation-triangle"],
                  title: _vm.$t("alert.error.loadingCategories.title"),
                  message: _vm.$t("alert.error.loadingCategories.message"),
                  show: true,
                  closeable: false,
                  type: "danger",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "negative-margin-content" },
            [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _vm.editPageState.data.subpage === "EDIT"
                            ? [
                                !_vm.question
                                  .isEditableWithoutSideEffectsToHistoricData ||
                                !_vm.question
                                  .isEditableWithoutSideEffectsToOtherSurveys
                                  ? _c(
                                      "div",
                                      { staticClass: "alert alert-warning" },
                                      [
                                        _c("font-awesome", {
                                          attrs: {
                                            icon: [
                                              "fas",
                                              "exclamation-triangle",
                                            ],
                                          },
                                        }),
                                        _vm._v(" "),
                                        !_vm.question
                                          .isEditableWithoutSideEffectsToHistoricData &&
                                        !_vm.question
                                          .isEditableWithoutSideEffectsToOtherSurveys
                                          ? _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "alert.notEditableWithoutSideEffectsBoth",
                                                    {
                                                      answers: _vm.escapeHTML(
                                                        _vm.question
                                                          .numberOfAnswersToThisQuestionInAllSurveys
                                                      ),
                                                      surveys: _vm.escapeHTML(
                                                        _vm.question
                                                          .numberOfSurveysThisQuestionIsIn -
                                                          1
                                                      ),
                                                    }
                                                  )
                                                ),
                                              },
                                            })
                                          : _vm.question
                                              .isEditableWithoutSideEffectsToHistoricData &&
                                            !_vm.question
                                              .isEditableWithoutSideEffectsToOtherSurveys
                                          ? _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "alert.notEditableWithoutSideEffectsOtherSurveys",
                                                    {
                                                      surveys: _vm.escapeHTML(
                                                        _vm.question
                                                          .numberOfSurveysThisQuestionIsIn -
                                                          1
                                                      ),
                                                    }
                                                  )
                                                ),
                                              },
                                            })
                                          : !_vm.question
                                              .isEditableWithoutSideEffectsToHistoricData &&
                                            _vm.question
                                              .isEditableWithoutSideEffectsToOtherSurveys
                                          ? _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "alert.notEditableWithoutSideEffectsHistoricData",
                                                    {
                                                      answers: _vm.escapeHTML(
                                                        _vm.question
                                                          .numberOfAnswersToThisQuestionInAllSurveys
                                                      ),
                                                    }
                                                  )
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "bold-uppercase-legend",
                                    attrs: {
                                      label: _vm.$t("label.questionType"),
                                      description: _vm.$t(
                                        "description.questionType"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "form-select",
                                      { attrs: { disabled: true } },
                                      [
                                        _c("template", { slot: "active" }, [
                                          _c(
                                            "div",
                                            { staticClass: "question-type" },
                                            [
                                              _vm.questionTypeObject.icon.fa
                                                ? _c("font-awesome", {
                                                    attrs: {
                                                      icon: _vm
                                                        .questionTypeObject.icon
                                                        .fa,
                                                    },
                                                  })
                                                : _vm.questionTypeObject.icon
                                                    .component
                                                ? _c(
                                                    _vm.questionTypeObject.icon
                                                      .component,
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        type: _vm
                                                          .questionTypeObject
                                                          .icon.componentType,
                                                      },
                                                    }
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.questionTypeObject.text
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "bold-uppercase-legend",
                                    attrs: {
                                      label: _vm.$t("label.questionText"),
                                    },
                                  },
                                  [
                                    _c("survey-management-las-wrapper", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "placeholder.questionText"
                                        ),
                                        las: _vm.editQuestion.text,
                                        "enabled-languages":
                                          _vm.$store.state.surveyManagement
                                            .fullSurvey.data.enabledLanguages,
                                        "current-language": _vm.currentLanguage,
                                        invalid:
                                          _vm.editPageState.data
                                            .showValidation &&
                                          !_vm.validation.text.valid,
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.editQuestion.text = $event
                                        },
                                        "change-language": function ($event) {
                                          _vm.currentLanguage = $event
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                [
                                  "EMAIL_QUESTION",
                                  "NUMBER_QUESTION",
                                  "DATE_QUESTION",
                                  "TEXT_QUESTION_LONG_TEXT",
                                  "TEXT_QUESTION_SHORT_TEXT",
                                ].includes(_vm.question.questionType)
                                  ? _c("survey-management-edit-placeholder", {
                                      attrs: {
                                        "edit-question": _vm.editQuestion,
                                        question: _vm.question,
                                        "current-language": _vm.currentLanguage,
                                        optional: true,
                                      },
                                      on: {
                                        "update-edit-question": function (
                                          $event
                                        ) {
                                          _vm.editQuestion = $event
                                        },
                                        "change-language": function ($event) {
                                          _vm.currentLanguage = $event
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                [
                                  "RATING_QUESTION_SMILEY",
                                  "RATING_QUESTION_SLIDER",
                                ].includes(_vm.question.questionType)
                                  ? _c(
                                      "survey-management-edit-rating-question",
                                      {
                                        attrs: {
                                          "edit-question": _vm.editQuestion,
                                          question: _vm.question,
                                          "current-language":
                                            _vm.currentLanguage,
                                        },
                                        on: {
                                          "update-edit-question": function (
                                            $event
                                          ) {
                                            _vm.editQuestion = $event
                                          },
                                          "change-language": function ($event) {
                                            _vm.currentLanguage = $event
                                          },
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.question.questionType === "EMAIL_QUESTION"
                                  ? _c(
                                      "survey-management-edit-email-question",
                                      {
                                        attrs: {
                                          "edit-question": _vm.editQuestion,
                                          question: _vm.question,
                                          "current-language":
                                            _vm.currentLanguage,
                                        },
                                        on: {
                                          "update-edit-question": function (
                                            $event
                                          ) {
                                            _vm.editQuestion = $event
                                          },
                                          "change-language": function ($event) {
                                            _vm.currentLanguage = $event
                                          },
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.question.questionType === "NUMBER_QUESTION"
                                  ? _c(
                                      "survey-management-edit-number-question",
                                      {
                                        attrs: {
                                          "edit-question": _vm.editQuestion,
                                          question: _vm.question,
                                          "current-language":
                                            _vm.currentLanguage,
                                        },
                                        on: {
                                          "update-edit-question": function (
                                            $event
                                          ) {
                                            _vm.editQuestion = $event
                                          },
                                          "change-language": function ($event) {
                                            _vm.currentLanguage = $event
                                          },
                                        },
                                      }
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.question.questionType === "DATE_QUESTION"
                                  ? _c("survey-management-edit-date-question", {
                                      attrs: {
                                        "edit-question": _vm.editQuestion,
                                        question: _vm.question,
                                        "current-language": _vm.currentLanguage,
                                      },
                                      on: {
                                        "update-edit-question": function (
                                          $event
                                        ) {
                                          _vm.editQuestion = $event
                                        },
                                        "change-language": function ($event) {
                                          _vm.currentLanguage = $event
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editPageState.data.subpage === "RATING_REASONS"
                            ? [
                                _vm.question
                                  .hasRatingAnswersWithCustomOrRegularReasons
                                  ? _c(
                                      "div",
                                      { staticClass: "alert alert-warning" },
                                      [
                                        _c("font-awesome", {
                                          attrs: {
                                            icon: [
                                              "fas",
                                              "exclamation-triangle",
                                            ],
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "alert.doNotChangeMeaningOfExistingRatingReasons"
                                              )
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("survey-management-edit-rating-reasons", {
                                  attrs: {
                                    "edit-question": _vm.editQuestion,
                                    question: _vm.question,
                                    "current-language": _vm.currentLanguage,
                                  },
                                  on: {
                                    "update-edit-question": function ($event) {
                                      _vm.editQuestion = $event
                                    },
                                    "change-language": function ($event) {
                                      _vm.currentLanguage = $event
                                    },
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editPageState.data.subpage === "CHOICE_ATTRIBUTES"
                            ? [
                                _vm.question
                                  .hasRatingAnswersWithCustomOrRegularReasons
                                  ? _c(
                                      "div",
                                      { staticClass: "alert alert-warning" },
                                      [
                                        _c("font-awesome", {
                                          attrs: {
                                            icon: [
                                              "fas",
                                              "exclamation-triangle",
                                            ],
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "alert.doNotChangeMeaningOfExistingChoiceAttributes"
                                              )
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("survey-management-edit-choice-attributes", {
                                  attrs: {
                                    "edit-question": _vm.editQuestion,
                                    question: _vm.question,
                                    "current-language": _vm.currentLanguage,
                                  },
                                  on: {
                                    "update-edit-question": function ($event) {
                                      _vm.editQuestion = $event
                                    },
                                    "change-language": function ($event) {
                                      _vm.currentLanguage = $event
                                    },
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [_c("survey-management-preview-wrapper")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "form-step-indicator",
            {
              staticClass: "form-step",
              attrs: { "step-index": _vm.currentStepIndex, steps: _vm.steps },
            },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    slot: "previous",
                    variant: "outline-secondary",
                    disabled: _vm.previousStepDisabled,
                  },
                  on: { click: _vm.previousStep },
                  slot: "previous",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("button.previous")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    slot: "next",
                    variant: "primary",
                    disabled: _vm.nextStepDisabled,
                  },
                  on: { click: _vm.nextStep },
                  slot: "next",
                },
                [
                  _vm.editPageState.data.showValidation
                    ? _c("font-awesome", {
                        attrs: { icon: ["fas", "exclamation-triangle"] },
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.next")) + "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }