var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "multiselect",
    _vm._b(
      {
        staticClass: "form-multi-select",
        attrs: {
          label: "name",
          "track-by": "name",
          options: _vm.options,
          placeholder: _vm.placeholder,
        },
        on: { input: _vm.onInputChanged },
        scopedSlots: _vm._u([
          {
            key: "tag",
            fn: function (ref) {
              var option = ref.option
              return [_vm._v("\n    " + _vm._s(option.name) + "\n  ")]
            },
          },
          {
            key: "option",
            fn: function (ref) {
              var option = ref.option
              return [
                _c("span", { staticClass: "custom-option" }, [
                  _c("span", { staticClass: "custom-checkbox" }),
                  _vm._v(_vm._s(option.name) + "\n    "),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.internalValue,
          callback: function ($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue",
        },
      },
      "multiselect",
      _vm.$attrs,
      false
    ),
    [
      _vm._v(" "),
      _c("template", { slot: "limit" }, [
        _c("i", [_vm._v(_vm._s(_vm.itemCount))]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }