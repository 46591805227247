var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      staticClass: "form-box",
      attrs: { "panel-title": _vm.$t("formTitle"), "is-loading": false },
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c("modal-icon-button", {
            attrs: {
              index: 0,
              icon: ["far", "times"],
              message: _vm.$t("tooltip.closeModal"),
              "color-type": "danger",
              "tooltip-placement": "left",
              "horizontal-in-box": "",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "forms" },
            [
              _vm.stepIndex === 0
                ? _c("form-date-and-locations", {
                    attrs: { "parent-form": _vm.form },
                    on: {
                      "change-form": _vm.changeForm,
                      "next-step": function ($event) {
                        _vm.stepIndex = 1
                      },
                    },
                  })
                : _vm.stepIndex === 1
                ? _c("form-survey", {
                    attrs: { "parent-form": _vm.form },
                    on: {
                      "change-form": _vm.changeForm,
                      "next-step": function ($event) {
                        _vm.stepIndex = 2
                      },
                      "prev-step": function ($event) {
                        _vm.stepIndex = 0
                        _vm.clearValuesOfSubsequentSteps()
                      },
                    },
                  })
                : _vm.stepIndex === 2
                ? _c("form-filters", {
                    attrs: { "parent-form": _vm.form },
                    on: {
                      "change-form": _vm.changeForm,
                      submit: _vm.saveFormAndClose,
                      "prev-step": function ($event) {
                        _vm.stepIndex = 1
                        _vm.clearValuesOfSubsequentSteps()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("form-step-indicator", {
            staticClass: "form-step",
            attrs: { "step-index": _vm.stepIndex, steps: _vm.steps },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }