var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "sidebar" },
    [
      _c(
        "div",
        { staticClass: "logo hide-when-small", style: _vm.customDomainStyles },
        [_c("img", { attrs: { src: _vm.logoSrc } })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "nav-pill-title hide-when-small" }, [
        _vm._v(_vm._s(_vm.$t("titles.analyze"))),
      ]),
      _vm._v(" "),
      _c(
        "b-nav",
        { attrs: { pills: "", vertical: "" } },
        [
          _c(
            "b-nav-item",
            { attrs: { to: { name: "dashboard", query: _vm.fullQuery } } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right.d200.window",
                      modifiers: {
                        hover: true,
                        right: true,
                        d200: true,
                        window: true,
                      },
                    },
                  ],
                  staticClass: "tooltip-wrapper show-when-small font-awesome",
                  attrs: { title: _vm.$t("dashboard") },
                },
                [
                  _c("font-awesome", {
                    attrs: { icon: ["fas", "chart-line"], "fixed-width": "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("font-awesome", {
                staticClass: "font-awesome hide-when-small",
                attrs: { icon: ["fas", "chart-line"], "fixed-width": "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hide-when-small" }, [
                _vm._v(_vm._s(_vm.$t("dashboard"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            {
              attrs: { to: { name: "survey-analysis", query: _vm.fullQuery } },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right.d200.window",
                      modifiers: {
                        hover: true,
                        right: true,
                        d200: true,
                        window: true,
                      },
                    },
                  ],
                  staticClass: "tooltip-wrapper show-when-small font-awesome",
                  attrs: { title: _vm.$t("surveyAnalysis") },
                },
                [
                  _c("font-awesome", {
                    attrs: { icon: ["fas", "poll-h"], "fixed-width": "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("font-awesome", {
                staticClass: "font-awesome hide-when-small",
                attrs: { icon: ["fas", "poll-h"], "fixed-width": "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hide-when-small" }, [
                _vm._v(_vm._s(_vm.$t("surveyAnalysis"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { attrs: { to: { name: "feedback", query: _vm.fullQuery } } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right.d200.window",
                      modifiers: {
                        hover: true,
                        right: true,
                        d200: true,
                        window: true,
                      },
                    },
                  ],
                  staticClass: "tooltip-wrapper show-when-small font-awesome",
                  attrs: { title: _vm.$t("feedback") },
                },
                [
                  _c("font-awesome", {
                    attrs: { icon: ["fas", "comments"], "fixed-width": "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("font-awesome", {
                staticClass: "font-awesome hide-when-small",
                attrs: { icon: ["fas", "comments"], "fixed-width": "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hide-when-small" }, [
                _vm._v(_vm._s(_vm.$t("feedback"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { attrs: { to: { name: "locations", query: _vm.fullQuery } } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right.d200.window",
                      modifiers: {
                        hover: true,
                        right: true,
                        d200: true,
                        window: true,
                      },
                    },
                  ],
                  staticClass: "tooltip-wrapper show-when-small font-awesome",
                  attrs: { title: _vm.$t("locations") },
                },
                [
                  _c("font-awesome", {
                    attrs: { icon: ["fas", "list-ol"], "fixed-width": "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("font-awesome", {
                staticClass: "font-awesome hide-when-small",
                attrs: { icon: ["fas", "list-ol"], "fixed-width": "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hide-when-small" }, [
                _vm._v(_vm._s(_vm.$t("locations"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { attrs: { to: { name: "matrix", query: _vm.fullQuery } } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right.d200.window",
                      modifiers: {
                        hover: true,
                        right: true,
                        d200: true,
                        window: true,
                      },
                    },
                  ],
                  staticClass: "tooltip-wrapper show-when-small font-awesome",
                  attrs: { title: _vm.$t("matrix") },
                },
                [
                  _c("font-awesome", {
                    attrs: { icon: ["far", "th"], "fixed-width": "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("font-awesome", {
                staticClass: "font-awesome hide-when-small",
                attrs: { icon: ["far", "th"], "fixed-width": "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hide-when-small" }, [
                _vm._v(_vm._s(_vm.$t("matrix"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { attrs: { to: { name: "comparison", query: null } } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right.d200.window",
                      modifiers: {
                        hover: true,
                        right: true,
                        d200: true,
                        window: true,
                      },
                    },
                  ],
                  staticClass: "tooltip-wrapper show-when-small font-awesome",
                  attrs: { title: _vm.$t("comparison") },
                },
                [
                  _c("font-awesome", {
                    attrs: { icon: ["fas", "exchange"], "fixed-width": "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("font-awesome", {
                staticClass: "font-awesome hide-when-small",
                attrs: { icon: ["fas", "exchange"], "fixed-width": "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hide-when-small" }, [
                _vm._v(_vm._s(_vm.$t("comparison"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "separator show-when-small" }),
      _vm._v(" "),
      _c("div", { staticClass: "nav-pill-title hide-when-small" }, [
        _vm._v(_vm._s(_vm.$t("titles.verify"))),
      ]),
      _vm._v(" "),
      _c(
        "b-nav",
        { attrs: { pills: "", vertical: "" } },
        [
          _c(
            "b-nav-item",
            { attrs: { to: { name: "goal-overview", query: null } } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right.d200.window",
                      modifiers: {
                        hover: true,
                        right: true,
                        d200: true,
                        window: true,
                      },
                    },
                  ],
                  staticClass: "tooltip-wrapper show-when-small font-awesome",
                  attrs: { title: _vm.$t("goalOverview") },
                },
                [
                  _c("font-awesome", {
                    attrs: {
                      icon: ["far", "bullseye-arrow"],
                      "fixed-width": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("font-awesome", {
                staticClass: "font-awesome hide-when-small",
                attrs: { icon: ["far", "bullseye-arrow"], "fixed-width": "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hide-when-small" }, [
                _vm._v(_vm._s(_vm.$t("goalOverview"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "separator show-when-small" }),
      _vm._v(" "),
      _c("div", { staticClass: "nav-pill-title hide-when-small" }, [
        _vm._v(_vm._s(_vm.$t("titles.export"))),
      ]),
      _vm._v(" "),
      _c(
        "b-nav",
        { attrs: { pills: "", vertical: "" } },
        [
          _c(
            "b-nav-item",
            { attrs: { to: { name: "report", query: null } } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right.d200.window",
                      modifiers: {
                        hover: true,
                        right: true,
                        d200: true,
                        window: true,
                      },
                    },
                  ],
                  staticClass: "tooltip-wrapper show-when-small font-awesome",
                  attrs: { title: _vm.$t("report") },
                },
                [
                  _c("font-awesome", {
                    attrs: { icon: ["fas", "file-pdf"], "fixed-width": "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("font-awesome", {
                staticClass: "font-awesome hide-when-small",
                attrs: { icon: ["fas", "file-pdf"], "fixed-width": "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hide-when-small" }, [
                _vm._v(_vm._s(_vm.$t("report"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { attrs: { to: { name: "raw-export", query: null } } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right.d200.window",
                      modifiers: {
                        hover: true,
                        right: true,
                        d200: true,
                        window: true,
                      },
                    },
                  ],
                  staticClass: "tooltip-wrapper show-when-small font-awesome",
                  attrs: { title: _vm.$t("rawExport") },
                },
                [
                  _c("font-awesome", {
                    attrs: { icon: ["fas", "file-csv"], "fixed-width": "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("font-awesome", {
                staticClass: "font-awesome hide-when-small",
                attrs: { icon: ["fas", "file-csv"], "fixed-width": "" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hide-when-small" }, [
                _vm._v(_vm._s(_vm.$t("rawExport"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "separator show-when-small" }),
      _vm._v(" "),
      _c("div", { staticClass: "nav-pill-title hide-when-small" }, [
        _vm._v(_vm._s(_vm.$t("titles.admin"))),
      ]),
      _vm._v(" "),
      _c(
        "b-nav",
        { attrs: { pills: "", vertical: "" } },
        [
          _c(
            "b-nav-item",
            { attrs: { to: { name: "tablet-migration", query: null } } },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.right.d200.window",
                      modifiers: {
                        hover: true,
                        right: true,
                        d200: true,
                        window: true,
                      },
                    },
                  ],
                  staticClass: "tooltip-wrapper show-when-small font-awesome",
                  attrs: { title: _vm.$t("tabletMigration") },
                },
                [
                  _c("font-awesome", {
                    attrs: {
                      icon: ["fas", "tablet-android-alt"],
                      "fixed-width": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("font-awesome", {
                staticClass: "font-awesome hide-when-small",
                attrs: {
                  icon: ["fas", "tablet-android-alt"],
                  "fixed-width": "",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hide-when-small" }, [
                _vm._v(_vm._s(_vm.$t("tabletMigration"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.currentUserIsSuperAdmin
            ? _c(
                "b-nav-item",
                { attrs: { to: { name: "filter-management" } } },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right.d200.window",
                          modifiers: {
                            hover: true,
                            right: true,
                            d200: true,
                            window: true,
                          },
                        },
                      ],
                      staticClass:
                        "tooltip-wrapper show-when-small font-awesome",
                      attrs: { title: _vm.$t("filterManagement") },
                    },
                    [
                      _c("font-awesome", {
                        attrs: { icon: ["fas", "filter"], "fixed-width": "" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("font-awesome", {
                    staticClass: "font-awesome hide-when-small",
                    attrs: { icon: ["fas", "filter"], "fixed-width": "" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "hide-when-small" }, [
                    _vm._v(_vm._s(_vm.$t("filterManagement"))),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.userIsAdmin
            ? _c(
                "b-nav-item",
                {
                  attrs: { to: { name: "web-source-management", query: null } },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right.d200.window",
                          modifiers: {
                            hover: true,
                            right: true,
                            d200: true,
                            window: true,
                          },
                        },
                      ],
                      staticClass:
                        "tooltip-wrapper show-when-small font-awesome",
                      attrs: { title: _vm.$t("webSourceManagement") },
                    },
                    [
                      _c("font-awesome", {
                        attrs: { icon: ["fas", "browser"], "fixed-width": "" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("font-awesome", {
                    staticClass: "font-awesome hide-when-small",
                    attrs: { icon: ["fas", "browser"], "fixed-width": "" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "hide-when-small" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("webSourceManagement")) + " "
                    ),
                    _c("span", { staticClass: "admin-badge" }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.userHasAccessToSurveyManagement
            ? _c(
                "b-nav-item",
                { attrs: { to: { name: "survey-management", query: null } } },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right.d200.window",
                          modifiers: {
                            hover: true,
                            right: true,
                            d200: true,
                            window: true,
                          },
                        },
                      ],
                      staticClass:
                        "tooltip-wrapper show-when-small font-awesome",
                      attrs: { title: _vm.$t("surveyManagement") },
                    },
                    [
                      _c("font-awesome", {
                        attrs: {
                          icon: ["fas", "clipboard-list"],
                          "fixed-width": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("font-awesome", {
                    staticClass: "font-awesome hide-when-small",
                    attrs: {
                      icon: ["fas", "clipboard-list"],
                      "fixed-width": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "hide-when-small" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("surveyManagement")) + " "
                    ),
                    _c("span", { staticClass: "admin-badge" }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.userIsAdmin
            ? _c(
                "b-nav-item",
                { attrs: { to: { name: "user-management", query: null } } },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right.d200.window",
                          modifiers: {
                            hover: true,
                            right: true,
                            d200: true,
                            window: true,
                          },
                        },
                      ],
                      staticClass:
                        "tooltip-wrapper show-when-small font-awesome",
                      attrs: { title: _vm.$t("userManagement") },
                    },
                    [
                      _c("font-awesome", {
                        attrs: { icon: ["fas", "users"], "fixed-width": "" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("font-awesome", {
                    staticClass: "font-awesome hide-when-small",
                    attrs: { icon: ["fas", "users"], "fixed-width": "" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "hide-when-small" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("userManagement")) + " "
                    ),
                    _c("span", { staticClass: "admin-badge" }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.userIsAdmin
            ? _c(
                "b-nav-item",
                {
                  attrs: {
                    to: { name: "location-administration", query: null },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right.d200.window",
                          modifiers: {
                            hover: true,
                            right: true,
                            d200: true,
                            window: true,
                          },
                        },
                      ],
                      staticClass:
                        "tooltip-wrapper show-when-small font-awesome",
                      attrs: { title: _vm.$t("locationAdministration") },
                    },
                    [
                      _c("font-awesome", {
                        attrs: {
                          icon: ["fas", "map-marker-alt"],
                          "fixed-width": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("font-awesome", {
                    staticClass: "font-awesome hide-when-small",
                    attrs: {
                      icon: ["fas", "map-marker-alt"],
                      "fixed-width": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "hide-when-small" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("locationAdministration")) +
                        " "
                    ),
                    _c("span", { staticClass: "admin-badge" }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.userIsAdmin
            ? _c(
                "b-nav-item",
                { attrs: { to: { name: "company-settings", query: null } } },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right.d200.window",
                          modifiers: {
                            hover: true,
                            right: true,
                            d200: true,
                            window: true,
                          },
                        },
                      ],
                      staticClass:
                        "tooltip-wrapper show-when-small font-awesome",
                      attrs: { title: _vm.$t("companySettings") },
                    },
                    [
                      _c("font-awesome", {
                        attrs: { icon: ["fas", "building"], "fixed-width": "" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("font-awesome", {
                    staticClass: "font-awesome hide-when-small",
                    attrs: { icon: ["fas", "building"], "fixed-width": "" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "hide-when-small" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("companySettings")) + " "
                    ),
                    _c("span", { staticClass: "admin-badge" }),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }