var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "print-location-performance print-section" },
    [
      _c("div", { staticClass: "print-h2" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("title")) + "\n    "),
        _c("span", { staticClass: "badge badge-pill badge-info" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("locationGrouping.location")) + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: { "has-not-index-calculation": !_vm.hasIndexCalculation } },
        [
          _c("div", { staticClass: "row location-list-header" }, [
            _c("div", { staticClass: "col-3" }, [
              _c("span", { staticClass: "header-item" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("locations")) + "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _c("span", { staticClass: "header-item" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        _vm.hasIndexCalculation
                          ? "averageIndex"
                          : "satisfactionInPercentage"
                      )
                    ) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-3 text-right" }, [
              _c("span", { staticClass: "header-item" }, [
                _vm._v(_vm._s(_vm.$t("totalCounter"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.data, function (location) {
            return _c(
              "div",
              { key: location.id, staticClass: "row location" },
              [
                _c("div", { staticClass: "col-3" }, [
                  _c(
                    "div",
                    { staticClass: "location-name overflow-ellipsis" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(location.locationName) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "location-group-name overflow-ellipsis" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(location.locationGroupName) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6 pt-3" },
                  [
                    _vm.hasIndexCalculation
                      ? _c("opn-progress", {
                          attrs: {
                            variant: _vm.getColorNameByIndex(
                              location.averageIndex,
                              _vm.$store.state.general.companyProperties
                            ),
                            precision: 1,
                            "show-progress": "",
                            "is-pdf": "",
                          },
                          model: {
                            value: location.averageIndex,
                            callback: function ($$v) {
                              _vm.$set(location, "averageIndex", $$v)
                            },
                            expression: "location.averageIndex",
                          },
                        })
                      : _c("opn-progress", {
                          attrs: {
                            precision: 1,
                            "show-progress": "",
                            "is-percentage": "",
                            "is-pdf": "",
                          },
                          model: {
                            value: location.satisfactionRate,
                            callback: function ($$v) {
                              _vm.$set(location, "satisfactionRate", $$v)
                            },
                            expression: "location.satisfactionRate",
                          },
                        }),
                    _vm._v(" "),
                    !_vm.hasIndexCalculation
                      ? _c("div", { staticClass: "location-data" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("satisfied", [
                                  location.satisfactionCounter,
                                  location.satisfactionCounter +
                                    location.nonSatisfactionCounter,
                                ])
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-3 text-right location-counter" },
                  [
                    _c(
                      "small",
                      [
                        _c("font-awesome", {
                          attrs: { icon: ["fas", "user"] },
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("groupedNumber")(location.totalCounter)) +
                        "\n        "
                    ),
                    _vm.hasIndexCalculation &&
                    location.countOfAllFeedbacksWithoutSatisfactionData > 0
                      ? _c(
                          "span",
                          { staticClass: "location-counter-info-circle" },
                          [
                            _c("font-awesome", {
                              staticClass: "font-awesome",
                              attrs: { icon: ["far", "info-circle"] },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.hasIndexCalculation && _vm.locationsWithoutSatisfactionDataCount > 0
        ? _c("print-info-box", {
            attrs: { text: _vm.locationWithoutSatisfactionDataInfoText },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }