import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import Alert from '@/components/shared/Alert';
import UserManagementOverviewTable from '@/components/user-management/UserManagementOverviewTable';
import { UserManagement } from '@/services/ResourceService';
import ComparisonService from '@/services/ComparisonService';
import ConfirmService from '@/services/ConfirmService';
import { mapState } from 'vuex';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
export default {
  name: 'UserManagementOverview',
  components: {
    Alert: Alert,
    UserManagementOverviewTable: UserManagementOverviewTable
  },
  data: function data() {
    return {
      isLoading: false,
      errorOccurred: false,
      filter: '',
      activeUsers: [],
      deactivatedUsers: []
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  computed: mapState({
    companyId: function companyId(state) {
      return state.general.companyId;
    },
    displayUserNamesConfiguration: function displayUserNamesConfiguration(state) {
      var _state$general$compan;
      return (_state$general$compan = state.general.companyProperties) === null || _state$general$compan === void 0 ? void 0 : _state$general$compan.displayUserNamesConfiguration;
    }
  }),
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      Promise.all([UserManagement.getUsers(this.$store.state.general.companyId), CompanyPropertiesService.update()]).then(function (response) {
        _this.users = [];
        response[0].data.forEach(function (data) {
          var user = {};
          user.id = data.id;
          user.firstName = data.firstName;
          user.lastName = data.lastName;
          user.email = data.email;
          var roles = data.authorities;
          roles.sort(ComparisonService.compareRoles);
          user.role = roles[0];
          user.locations = data.locationNames;
          user.activated = data.activated;
          user['last-login'] = data.lastLoginDate === null ? null : moment(data.lastLoginDate);
          if (user.activated) {
            _this.activeUsers.push(user);
          } else {
            _this.deactivatedUsers.push(user);
          }
        });
        _this.isLoading = false;
      }, function () {
        _this.errorOccurred = true;
        _this.isLoading = false;
      });
    },
    reloadUsers: function reloadUsers() {
      this.deactivatedUsers = [];
      this.activeUsers = [];
      this.loadData();
    },
    activateUser: function activateUser(item) {
      var _this2 = this;
      this.isLoading = true;
      UserManagement.activateUser(this.companyId, item.id).then(function () {
        _this2.reloadUsers();
        _this2.isLoading = false;
      }).catch(function () {
        _this2.errorOccurred = true;
        _this2.isLoading = false;
      });
    },
    deactivateUser: function deactivateUser(item) {
      var _this3 = this;
      this.isLoading = true;
      UserManagement.deactivateUser(this.companyId, item.id).then(function () {
        _this3.reloadUsers();
        _this3.isLoading = false;
      }).catch(function () {
        _this3.errorOccurred = true;
        _this3.isLoading = false;
      });
    },
    deleteUser: function deleteUser(item) {
      var _this4 = this;
      this.isLoading = true;
      UserManagement.deleteUser(this.companyId, item.id).then(function () {
        _this4.reloadUsers();
        _this4.isLoading = false;
      }).catch(function () {
        _this4.errorOccurred = true;
        _this4.isLoading = false;
      });
    },
    requestDeactivateUser: function requestDeactivateUser(user) {
      var _this5 = this;
      ConfirmService.createModal({
        name: 'confirm-deactivate-user',
        type: 'warning',
        title: this.$t('requestDeactivateUser.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestDeactivateUser.text', {
          userName: "".concat(user.firstName, " ").concat(user.lastName, " (").concat(user.email, ")")
        }),
        textIsHtml: true,
        okayButton: this.$t('requestDeactivateUser.okay'),
        dismissButton: this.$t('requestDeactivateUser.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this5.deactivateUser(user);
          }
        }
      });
    },
    requestActivateUser: function requestActivateUser(user) {
      var _this6 = this;
      ConfirmService.createModal({
        name: 'confirm-activate-user',
        type: 'warning',
        title: this.$t('requestActivateUser.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestActivateUser.text', {
          userName: "".concat(user.firstName, " ").concat(user.lastName, " (").concat(user.email, ")")
        }),
        textIsHtml: true,
        okayButton: this.$t('requestActivateUser.okay'),
        dismissButton: this.$t('requestActivateUser.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this6.activateUser(user);
          }
        }
      });
    },
    requestDeleteUser: function requestDeleteUser(user) {
      var _this7 = this;
      ConfirmService.createModal({
        name: 'confirm-delete-user',
        type: 'warning',
        title: this.$t('requestDeleteUser.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestDeleteUser.text', {
          userName: "".concat(user.firstName, " ").concat(user.lastName, " (").concat(user.email, ")")
        }),
        textIsHtml: true,
        okayButton: this.$t('requestDeleteUser.okay'),
        dismissButton: this.$t('requestDeleteUser.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this7.deleteUser(user);
          }
        }
      });
    }
  }
};