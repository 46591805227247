import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpnnLogo from '@/assets/opiniooon-logo-dark.svg';
import SurveyManagementPreviewRatingSmiley from '@/components/survey-management/edit-question/question-preview/SurveyManagementPreviewRatingSmiley';
import SurveyManagementPreviewChoiceQuestion from '@/components/survey-management/edit-question/question-preview/SurveyManagementPreviewChoiceQuestion';
import SurveyManagementPreviewRatingSlider from './SurveyManagementPreviewRatingSlider';
import SurveyManagementPreviewTextInputQuestion from './SurveyManagementPreviewTextInputQuestion';
export default {
  name: 'SurveyManagementQuestionPreview',
  components: {
    SurveyManagementPreviewTextInputQuestion: SurveyManagementPreviewTextInputQuestion,
    SurveyManagementPreviewRatingSlider: SurveyManagementPreviewRatingSlider,
    SurveyManagementPreviewRatingSmiley: SurveyManagementPreviewRatingSmiley,
    SurveyManagementPreviewChoiceQuestion: SurveyManagementPreviewChoiceQuestion
  },
  computed: {
    editPageState: function editPageState() {
      return this.$store.state.surveyManagement.editPageState || {};
    },
    editQuestion: function editQuestion() {
      return this.editPageState.data.editQuestion;
    },
    questionType: function questionType() {
      return this.editPageState.data.question.questionType;
    },
    questionIsOfTextInputType: function questionIsOfTextInputType() {
      return ['COMMENT_QUESTION', 'EMAIL_QUESTION', 'TEXT_QUESTION_SHORT_TEXT', 'TEXT_QUESTION_LONG_TEXT', 'NUMBER_QUESTION', 'DATE_QUESTION'].includes(this.questionType);
    },
    questionNr: function questionNr() {
      return this.editPageState.data.questionNr;
    },
    numberOfQuestions: function numberOfQuestions() {
      return this.$store.state.surveyManagement.fullSurvey.data.questions.length + (this.editPageState.data.isNew ? 1 : 0);
    },
    progress: function progress() {
      var progressMarginMovement = 0.5 / this.numberOfQuestions;
      var progressInPercent = this.questionNr / this.numberOfQuestions;
      if (progressInPercent < 0) {
        progressInPercent = 0;
      }
      if (progressInPercent > 100) {
        progressInPercent = 100;
      }
      var result = (progressInPercent + progressMarginMovement) * 100;
      if (result < 0) {
        return 0;
      } else if (result > 100) {
        return 100;
      } else {
        return result;
      }
    },
    currentLanguage: function currentLanguage() {
      return this.editPageState.data.currentLanguage;
    },
    companyLogo: function companyLogo() {
      var companyLogo = this.$store.state.surveyManagement.fullSurvey.data.companyLogo;
      if (companyLogo === null) {
        return OpnnLogo;
      }
      return "data:".concat(companyLogo.imageContentType, ";base64,").concat(companyLogo.image);
    },
    hideCompanyLogo: function hideCompanyLogo() {
      var question = this.editQuestion;
      if (['RATING_QUESTION_SMILEY', 'RATING_QUESTION_SLIDER'].includes(this.questionType)) {
        var hasRatingReasons = question.hasOwnProperty('activatedRatingReasons') && question.activatedRatingReasons.length > 0;
        var hasCustomRatingReasons = question.hasOwnProperty('customRatingReasonEnabled') && question.customRatingReasonEnabled;
        return hasRatingReasons || hasCustomRatingReasons;
      } else if (['CHOICE_QUESTION_SINGLE_CHOICE', 'CHOICE_QUESTION_MULTIPLE_CHOICE'].includes(this.questionType)) {
        var hasRegularChoices = question.hasOwnProperty('activatedRegularChoiceAttributes') && question.activatedRegularChoiceAttributes.length > 0;
        var hasCustomChoices = question.hasOwnProperty('activatedCustomChoiceAttributes') && question.activatedCustomChoiceAttributes.length > 0;
        return hasRegularChoices || hasCustomChoices;
      }
      return false;
    },
    currentFullQuestion: function currentFullQuestion() {
      return this.$store.state.surveyManagement.editPageState.data.question;
    },
    visibleInPoolCategories: function visibleInPoolCategories() {
      if (this.$store.state.surveyManagement.categories.hasError || this.$store.state.surveyManagement.categories.isLoading || this.$store.state.surveyManagement.categories.data === null) {
        return [];
      }
      return this.$store.state.surveyManagement.categories.data;
    },
    questionCategoryName: function questionCategoryName() {
      var _this = this;
      if (this.editQuestion.categoryId === null) {
        return null;
      }
      var currentlySelectedCategory = this.visibleInPoolCategories.find(function (category) {
        return category.categoryId === _this.editQuestion.categoryId;
      });

      // If editQuestion has a not null categoryId, the corresponding category has to be either
      // in visibleInPoolCategories or in the fullQuestion itself
      if (currentlySelectedCategory !== undefined) {
        return currentlySelectedCategory.categoryName;
      } else {
        return this.currentFullQuestion.categoryName;
      }
    }
  },
  methods: {
    lasPreviewTranslation: function lasPreviewTranslation(las) {
      if (las === null || typeof las === 'undefined') {
        return null;
      }
      if (las.hasOwnProperty(this.currentLanguage)) {
        return las[this.currentLanguage];
      }
      return null;
    },
    staticPreviewTranslation: function staticPreviewTranslation(key, object) {
      var staticTranslations = {
        questionOf: {
          DE: 'Frage {current} von {total}',
          EN: 'Question {current} of {total}',
          ES: 'Pregunta {current} de {total}',
          FR: 'Question {current} sur {total}',
          IT: 'Domanda {current} di {total}',
          MK: 'Question {current} of {total}',
          PT: 'Questão {current} de {total}',
          SQ: 'Question {current} of {total}',
          TR: 'Question {current} of {total}'
        }
      };
      if (staticTranslations[key] !== undefined && staticTranslations[key][this.currentLanguage] !== undefined) {
        var text = staticTranslations[key][this.currentLanguage];
        if (object !== undefined && object !== null) {
          Object.keys(object).forEach(function (k) {
            text = text.replace('{' + k + '}', object[k]);
          });
        }
        return text;
      }
      return null;
    }
  }
};