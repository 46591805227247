//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilterCriteria from '@/components/comparison/filter-criteria/FilterCriteria';
export default {
  name: 'FilterCriteriaLocation',
  components: {
    FilterCriteria: FilterCriteria
  },
  props: {
    locationIds: {
      type: Array,
      required: true
    }
  }
};