import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Store from '@/store/Store';
import PrintContainer from '@/components/print/PrintContainer';
import PrintBasicInformation from '@/components/print/report/PrintBasicInformation';
import PrintQuickStatistics from '@/components/print/report/PrintQuickStatistics';
import PrintChangeOverTime from '@/components/print/report/PrintChangeOverTime';
import PrintLocationPerformance from '@/components/print/report/PrintLocationPerformance';
import PrintMatrix from '@/components/print/report/PrintMatrix';
import PrintSurveyAnalysis from '@/components/print/report/PrintSurveyAnalysis';
import PrintComments from '@/components/print/report/PrintComments';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import { QuickStatistics, LocationPerformance, ChangeOverTime, Pdf, Matrix, SurveyAnalysis } from '@/services/ResourceService';
export default {
  name: 'PrintReport',
  components: {
    PrintContainer: PrintContainer,
    PrintBasicInformation: PrintBasicInformation,
    PrintQuickStatistics: PrintQuickStatistics,
    PrintChangeOverTime: PrintChangeOverTime,
    PrintLocationPerformance: PrintLocationPerformance,
    PrintMatrix: PrintMatrix,
    PrintSurveyAnalysis: PrintSurveyAnalysis,
    PrintComments: PrintComments
  },
  data: function data() {
    return {
      isLoading: false,
      hasError: false,
      hasParamsError: false,
      locationsWithActualData: [],
      quickStatistics: {},
      performance: [],
      changeOverTime: {},
      matrix: {},
      surveyAnalysis: [],
      comments: [],
      customAnswers: [],
      textAnswers: []
    };
  },
  computed: {
    queryParams: function queryParams() {
      var result = {};
      var queryObject = this.$route.query;
      Object.keys(this.$route.query).forEach(function (queryKey) {
        try {
          // parse arrays or use as string, if invalid JSON
          result[queryKey] = JSON.parse(queryObject[queryKey]);
        } catch (e) {
          result[queryKey] = queryObject[queryKey];
        }
      });
      return result;
    },
    missingQueryParams: function missingQueryParams() {
      var _this = this;
      var params = ['companyId', 'locationIds', 'start', 'end', 'language', 'components'];
      var arrays = ['locationIds', 'components'];
      var dates = ['start', 'end'];
      var languages = ['language'];
      var result = {};
      params.forEach(function (param) {
        if (!_this.queryParams.hasOwnProperty(param)) {
          result[param] = true;
        }
      });
      arrays.forEach(function (array) {
        if (!_this.queryParams.hasOwnProperty(array) || !Array.isArray(_this.queryParams[array])) {
          result[array] = true;
        }
      });
      dates.forEach(function (date) {
        if (!/^\d{2}\.\d{2}\.\d{4}$/.test(_this.queryParams[date])) {
          result[date] = true;
        }
      });
      languages.forEach(function (language) {
        if (!['DE', 'FR', 'IT', 'EN'].includes(_this.queryParams[language])) {
          result[language] = true;
        }
      });
      return Object.keys(result);
    },
    validQueryParams: function validQueryParams() {
      return this.missingQueryParams.length === 0;
    }
  },
  created: function created() {
    if (this.validQueryParams) {
      this.$store.commit('general/setCompanyId', this.queryParams.companyId);
      this.$locale.change(this.queryParams.language.toLowerCase());
      this.loadData();
    } else {
      this.hasParamsError = true;
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$locale.change(this.$store.state.general.user.language.toLowerCase());
  },
  methods: {
    loadData: function loadData() {
      var _this2 = this;
      this.isLoading = true;
      this.hasError = false;
      var params = [this.queryParams.companyId, this.queryParams.locationIds, this.queryParams.start, this.queryParams.end];
      var locationsWithActualDataParams = params.slice();
      var quickStatisticsParams = params.slice();
      var performanceParams = params.slice();
      var changeOverTimeParams = params.slice();
      var matrixParams = params.slice();
      var surveyAnalysis = params.slice();
      var customAnswers = params.slice();
      var textAnswers = params.slice();
      var commentParams = params.slice();
      performanceParams.push('location');
      Promise.all([Pdf.getLocationsWithActualData.apply(null, locationsWithActualDataParams), this.queryParams.components.includes('QUICK_STATISTICS') ? QuickStatistics.getData.apply(null, quickStatisticsParams) : Promise.resolve({
        data: null
      }), this.queryParams.components.includes('LOCATION_PERFORMANCE') ? LocationPerformance.getLocationData.apply(null, performanceParams) : Promise.resolve({
        data: null
      }), this.queryParams.components.includes('CHANGE_OVER_TIME') ? ChangeOverTime.getData.apply(null, changeOverTimeParams) : Promise.resolve({
        data: null
      }), this.queryParams.components.includes('MATRIX') ? this.getMatrixDataPromise(matrixParams) : Promise.resolve({
        data: null
      }), this.queryParams.components.includes('SURVEY_ANALYSIS') ? this.getSurveyAnalysisDataPromise(surveyAnalysis) : Promise.resolve({
        data: null
      }), this.queryParams.components.includes('CUSTOM_ANSWERS') ? Pdf.getCustomAnswers.apply(null, customAnswers) : Promise.resolve({
        data: null
      }), this.queryParams.components.includes('TEXT_ANSWERS') ? Pdf.getTextAnswers.apply(null, textAnswers) : Promise.resolve({
        data: null
      }), this.queryParams.components.includes('COMMENTS') ? Pdf.getComments.apply(null, commentParams) : Promise.resolve({
        data: null
      }), CompanyPropertiesService.update()]).then(function (responses) {
        _this2.locationsWithActualData = responses[0].data;
        _this2.quickStatistics = responses[1].data;
        _this2.performance = responses[2].data;
        _this2.changeOverTime = responses[3].data;
        _this2.matrix = responses[4].data;
        _this2.surveyAnalysis = responses[5].data;
        _this2.customAnswers = responses[6].data;
        _this2.textAnswers = responses[7].data;
        _this2.comments = responses[8].data;
        _this2.isLoading = false;
      }, function () {
        _this2.isLoading = false;
        _this2.hasError = true;
      });
    },
    getSurveyAnalysisDataPromise: function getSurveyAnalysisDataPromise(params) {
      return SurveyAnalysis.getSurveyOptions.apply(null, params).then(function (surveyListResponse) {
        var listOfSurveyPromises = surveyListResponse.data.map(function (surveyListItem) {
          var surveyId = surveyListItem.surveyId;
          var answerSetCounter = surveyListItem.answerSetCounter;
          return SurveyAnalysis.getData(params[0], params[1], surveyId, params[2], params[3]).then(function (surveyResponse) {
            surveyResponse.data.answerSetCounter = answerSetCounter;
            return surveyResponse.data;
          }).catch(function (e) {
            return Promise.reject(e);
          });
        });
        return Promise.all(listOfSurveyPromises).then(function (array) {
          return Promise.resolve({
            data: array
          });
        }).catch(function (e) {
          return Promise.reject(e);
        });
      }).catch(function (e) {
        return Promise.reject(e);
      });
    },
    getMatrixDataPromise: function getMatrixDataPromise(params) {
      return Matrix.getData.apply(null, params).then(function (response) {
        return Promise.resolve({
          data: response.data
        });
      }).catch(function (error) {
        var data = error.response.data;
        var errCode = data.status.code;

        // If there is no matrix data, return nothing without throwing an error
        if (errCode === 404) {
          return Promise.resolve({
            data: null
          });
        } else {
          return Promise.reject(error);
        }
      });
    }
  },
  beforeRouteEnter: function beforeRouteEnter(_to, _from, next) {
    Store.commit('general/setPrintView', true);
    next();
  },
  beforeRouteLeave: function beforeRouteLeave(_to, _from, next) {
    Store.commit('general/setPrintView', false);
    next();
  }
};