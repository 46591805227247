//
//
//
//
//
//
//
//
//
//
//

import { getColorsAndIcons } from '@/services/ColorService';
export default {
  name: 'ScaleOrderIcon',
  props: {
    scaleOrder: {
      type: String,
      required: true
    },
    scale: {
      type: Number,
      required: true,
      validator: function validator(item) {
        return item >= 3 && item <= 10;
      }
    }
  },
  computed: {
    points: function points() {
      var colorsAndIcons = getColorsAndIcons(this.scale);
      if (this.scaleOrder === 'LEFT_HIGH_RIGHT_LOW') {
        colorsAndIcons.reverse();
      }
      return colorsAndIcons;
    }
  }
};