var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "42px",
        height: "41px",
        viewBox: "0 0 42 41",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("defs", [
        _c("polygon", {
          attrs: {
            id: "path-1",
            points:
              "0.0810444523 40.9747362 40.8371392 40.9747362 40.8371392 0.232216426 0.0810444523 0.232216426 0.0810444523 40.9747362",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Smileys",
                transform: "translate(-43.000000, 0.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "unhappy",
                    transform: "translate(43.783784, 0.000000)",
                  },
                },
                [
                  _c("g", { attrs: { id: "Group-21" } }, [
                    _c("mask", { attrs: { id: "mask-2", fill: "white" } }, [
                      _c("use", { attrs: { "xlink:href": "#path-1" } }),
                    ]),
                    _vm._v(" "),
                    _c("g", { attrs: { id: "Clip-20" } }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M20.9163055,38.3033231 C20.7611992,38.3075137 20.6044625,38.3091433 20.4498219,38.3091433 C10.7764003,38.3091433 3.00314839,30.7327749 2.75325477,21.0606419 C2.63098622,16.3331817 4.35788388,11.8406278 7.61558374,8.41132178 C10.8737494,4.98201573 15.2723894,3.0259377 20.0015039,2.90394474 C20.1568432,2.89998695 20.3135798,2.89789165 20.4682204,2.89789165 C30.1414091,2.89789165 37.9146611,10.4744929 38.1650205,20.1463931 C38.417243,29.9058303 30.6793907,38.0509559 20.9163055,38.3033231 M40.8302419,20.0777138 C40.6921366,14.7309764 38.5048106,9.73298983 34.6714005,6.00452165 C30.7175851,2.15871673 25.4709836,0.0915878478 19.9325677,0.239655644 C14.491501,0.379807867 9.43051475,2.63039181 5.68211046,6.57607266 C1.93417196,10.5215207 -0.052633698,15.6901577 0.0880333533,21.1293211 C0.225905695,26.4762914 2.41323176,31.4740452 6.2466418,35.202979 C10.0730651,38.9249284 15.1175159,40.9748293 20.4498219,40.9748293 C20.627286,40.9748293 20.8068461,40.9725012 20.9852417,40.9673793 C32.2178784,40.6772968 41.1204259,31.3061884 40.8302419,20.0777138",
                        id: "Fill-19",
                        fill: "#333333",
                        mask: "url(#mask-2)",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M17.2257517,15.9291615 C17.1875574,14.457796 15.9634746,13.2953706 14.4913613,13.3333188 C13.019248,13.3714998 11.8568816,14.5951545 11.8948431,16.0667528 C11.9328046,17.5383511 13.1566545,18.7005437 14.6294665,18.6623626 C16.1013469,18.6244145 17.2637132,17.4007598 17.2257517,15.9291615",
                      id: "Fill-22",
                      fill: "#333333",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M26.75478,13.3334585 C25.2826667,13.3714067 24.1200675,14.5950613 24.1584948,16.0664269 C24.1962233,17.538258 25.420539,18.7006833 26.8924194,18.6625023 C28.3645327,18.6243213 29.5268991,17.4006667 29.4889376,15.9290684 C29.451209,14.45747 28.2266604,13.2955103 26.75478,13.3334585",
                      id: "Fill-24",
                      fill: "#333333",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M28.4983993,28.601204 C28.4983993,28.601204 25.5921342,27.7032519 20.7305039,27.7032519 C15.5486465,27.7032519 12.885754,28.601204 12.885754,28.601204 C12.084371,28.8964083 11.5568696,29.1960361 11.5568696,29.9293907 C11.5568696,30.6627454 12.1519098,31.2575775 12.885754,31.2575775 C12.885754,31.2575775 18.8268409,30.557282 20.80829,30.5582132 C22.7317489,30.5591445 28.4983993,31.2575775 28.4983993,31.2575775 C29.2320106,31.2575775 29.8270509,30.6627454 29.8270509,29.9293907 C29.8270509,29.1960361 29.3475253,28.8964083 28.4983993,28.601204",
                      id: "Fill-26",
                      fill: "#333333",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }