var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expanded-section", class: { narrow: _vm.isNarrow } },
    [
      _c("div", { staticClass: "inside-wrapper" }, [
        _c(
          "div",
          { staticClass: "expandable-content" },
          [
            _vm.hasRatingDistributionChart
              ? _c(
                  "generic-chart-with-title",
                  {
                    staticClass:
                      "content-wrapper rating-distribution-chart-wrapper",
                    class: "scale-" + _vm.question.scale,
                    attrs: {
                      title: _vm.$t("title.answerDistribution"),
                      "is-loading": !_vm.renderChart,
                      "center-title": "",
                    },
                  },
                  [
                    _vm.renderChart
                      ? _c("rating-distribution-chart", {
                          staticClass: "chart",
                          attrs: {
                            data: {
                              scale: _vm.question.scale,
                              distribution: _vm.question.ratingDistribution,
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasScatterChart
              ? _c(
                  "generic-chart-with-title",
                  {
                    staticClass: "content-wrapper scatter-chart",
                    attrs: {
                      title: _vm.$t("title.answerDistribution"),
                      "is-loading": !_vm.renderChart,
                      "center-title": "",
                    },
                  },
                  [
                    _vm.renderChart
                      ? _c("scatter-distribution-chart", {
                          staticClass: "chart",
                          attrs: {
                            type:
                              _vm.question.questionType === "DATE_QUESTION"
                                ? "date"
                                : "number",
                            distribution: _vm.question.distribution,
                            "is-pdf": false,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasAttributeDistributionChart
              ? _c(
                  "generic-chart-with-title",
                  {
                    staticClass: "content-wrapper attribute-chart",
                    attrs: { title: _vm.attributeDistributionChartTitle },
                  },
                  [
                    (_vm.question.questionType === "RATING_QUESTION" &&
                      _vm.question.ratingReasons.length > 0) ||
                    _vm.question.questionType === "CHOICE_QUESTION"
                      ? _c("attribute-distribution-chart", {
                          attrs: {
                            question: _vm.question,
                            counter: _vm.counter,
                            "survey-id": _vm.surveyId,
                            "query-parameters": _vm.queryParameters,
                            type:
                              _vm.question.questionType === "RATING_QUESTION"
                                ? "ratingReason"
                                : "choiceAttribute",
                            "is-narrow": _vm.isNarrow,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.attributeDistributionChartHasNoData
                      ? _c("div", { staticClass: "no-rating-reasons" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("noRatingReasons")) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasTextAnswers
              ? _c("text-answers", {
                  attrs: {
                    "survey-id": _vm.surveyId,
                    "query-parameters": _vm.queryParameters,
                    question: _vm.question,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }