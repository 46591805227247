import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import Panel from '@/components/shared/Panel';
import GoalTable from '@/components/goal/GoalTable';
import { Goal as GoalResource } from '@/services/ResourceService';
import ConfirmService from '@/services/ConfirmService';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
export default {
  name: 'GoalOverview',
  components: {
    Alert: Alert,
    Panel: Panel,
    GoalTable: GoalTable
  },
  data: function data() {
    return {
      isLoading: true,
      hasUserSelected: false,
      activeGoals: [],
      completedGoals: [],
      archivedGoals: []
    };
  },
  computed: {
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    }
  },
  mounted: function mounted() {
    this.initializePage();
  },
  methods: {
    initializePage: function initializePage() {
      this.isLoading = true;
      if (this.$store.state.general.selectedUser && this.$store.state.general.selectedUser.id) {
        this.hasUserSelected = true;
        this.loadData();
      }
    },
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      var userId = this.$store.state.general.selectedUser.id;
      Promise.all([GoalResource.getAllActive(userId), GoalResource.getAllCompleted(userId), GoalResource.getAllArchived(userId), CompanyPropertiesService.update()]).then(function (response) {
        _this.isLoading = false;
        _this.activeGoals = response[0].data;
        _this.completedGoals = response[1].data;
        _this.archivedGoals = response[2].data;
      }, function () {
        _this.isLoading = false;
      });
    },
    editGoal: function editGoal(goal) {
      this.$router.push({
        name: 'goal-edit',
        params: {
          goal: goal.id
        }
      });
    },
    viewDetails: function viewDetails(goal) {
      this.$router.push({
        name: 'goal-details',
        params: {
          goal: goal.id
        }
      });
    },
    requestDeleteGoal: function requestDeleteGoal(goal) {
      var _this2 = this;
      ConfirmService.createModal({
        name: 'confirm-delete-goal',
        type: 'danger',
        title: this.$t('requestDeleteModal.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestDeleteModal.text', {
          goalTitle: goal.title
        }),
        textIsHtml: true,
        okayButton: this.$t('requestDeleteModal.okay'),
        dismissButton: this.$t('requestDeleteModal.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this2.deleteGoal(goal);
          }
        }
      });
    },
    // Only archived or active goals can be deleted
    deleteGoal: function deleteGoal(goal) {
      var _this3 = this;
      this.isLoading = true;
      GoalResource.delete(goal.userId, goal.id).then(function () {
        _this3.loadData();
      }, function () {
        _this3.isLoading = false;
      });
    },
    // Only completed goals can be archived
    archiveGoal: function archiveGoal(goal) {
      var _this4 = this;
      this.isLoading = true;
      GoalResource.archive(goal.userId, goal.id).then(function () {
        _this4.loadData();
      }, function () {
        _this4.isLoading = false;
      });
    },
    // Only archived goals can be unarchived
    unarchiveGoal: function unarchiveGoal(goal) {
      var _this5 = this;
      this.isLoading = true;
      GoalResource.unarchive(goal.userId, goal.id).then(function () {
        _this5.loadData();
      }, function () {
        _this5.isLoading = false;
      });
    }
  }
};