var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goal-edit" },
    [
      _c("panel", { attrs: { "is-loading": false } }, [
        _c("div", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("panelTitle")) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { slot: "content" }, slot: "content" },
          [
            _c("alert", {
              staticClass: "positioned-alert",
              attrs: {
                show:
                  _vm.successfullySaved && !_vm.isLoading && !_vm.isSubmitting,
                icon: ["fas", "check"],
                type: "success",
                title: _vm.$t("success.title"),
                message: _vm.$t(
                  _vm.isCreation
                    ? "success.createMessage"
                    : "success.editMessage"
                ),
              },
            }),
            _vm._v(" "),
            _c("alert", {
              staticClass: "positioned-alert",
              attrs: {
                show:
                  _vm.serverErrorOccurred &&
                  !_vm.isLoading &&
                  !_vm.isSubmitting,
                icon: ["fas", "exclamation-triangle"],
                type: "danger",
                title: _vm.$t("serverError.title"),
                message: _vm.$t("serverError.message"),
              },
            }),
            _vm._v(" "),
            _c("alert", {
              staticClass: "positioned-alert",
              attrs: {
                show:
                  _vm.validationErrorOccurred &&
                  !_vm.isLoading &&
                  !_vm.isSubmitting,
                icon: ["fas", "exclamation-triangle"],
                type: "danger",
                title: _vm.$t("validationError.title"),
                message: _vm.$t("validationError.message"),
              },
            }),
            _vm._v(" "),
            (!_vm.isLoading || _vm.isSubmitting) && !_vm.serverErrorOccurred
              ? _c(
                  "b-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-3" },
                      [
                        _c("b-col", { attrs: { xl: "3", sm: "3" } }, [
                          _c("label", { attrs: { for: "input-title" } }, [
                            _vm._v(_vm._s(_vm.$t("form.title"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { xl: "9", sm: "9" } },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "input-title",
                                state: _vm.getFieldState("TITLE"),
                                disabled: _vm.inputsDisabled,
                                placeholder: _vm.$t("form.titlePlaceholder"),
                              },
                              model: {
                                value: _vm.formData.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "title", $$v)
                                },
                                expression: "formData.title",
                              },
                            }),
                            _vm._v(" "),
                            _c("validation-error-list", {
                              attrs: { errors: _vm.getFieldErrors("TITLE") },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      { staticClass: "mb-3" },
                      [
                        _c("b-col", { attrs: { xl: "3", sm: "3" } }, [
                          _c("label", { attrs: { for: "input-description" } }, [
                            _vm._v(_vm._s(_vm.$t("form.description"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { xl: "9", sm: "9" } },
                          [
                            _c("b-form-textarea", {
                              attrs: {
                                id: "input-description",
                                state: _vm.getFieldState("DESCRIPTION"),
                                disabled: _vm.inputsDisabled,
                                "no-resize": "",
                                placeholder: _vm.$t(
                                  "form.descriptionPlaceholder"
                                ),
                                rows: 6,
                                "max-rows": 6,
                              },
                              model: {
                                value: _vm.formData.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "description", $$v)
                                },
                                expression: "formData.description",
                              },
                            }),
                            _vm._v(" "),
                            _c("validation-error-list", {
                              attrs: {
                                errors: _vm.getFieldErrors("DESCRIPTION"),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      { staticClass: "mb-3" },
                      [
                        _c("b-col", { attrs: { xl: "3", sm: "3" } }, [
                          _c("label", { attrs: { for: "input-date-range" } }, [
                            _vm._v(_vm._s(_vm.$t("form.dateRange"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("b-col", { attrs: { xl: "9", sm: "9" } }, [
                          _c(
                            "div",
                            [
                              _c("opn-date-range-picker", {
                                attrs: {
                                  value: [
                                    _vm.formData.startDate,
                                    _vm.formData.endDate,
                                  ],
                                  "is-loading": false,
                                  "is-in-form": true,
                                  "has-preset-ranges": false,
                                  disabled: _vm.inputsDisabled,
                                },
                                on: {
                                  "change-daterange": _vm.dateRangeChanged,
                                },
                              }),
                              _vm._v(" "),
                              _c("validation-error-list", {
                                attrs: {
                                  errors: _vm.getFieldErrors("DATE_RANGE"),
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showGoalTypeSelect
                      ? _c(
                          "b-row",
                          { staticClass: "mb-3" },
                          [
                            _c("b-col", { attrs: { xl: "3", sm: "3" } }, [
                              _c(
                                "label",
                                { attrs: { for: "input-goal-type" } },
                                [_vm._v(_vm._s(_vm.$t("form.goalType")))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { attrs: { xl: "9", sm: "9" } },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    disabled: _vm.inputsDisabled,
                                    options: _vm.goalTypes,
                                    state: _vm.getFieldState("GOAL_TYPE"),
                                  },
                                  model: {
                                    value: _vm.formData.goalType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "goalType", $$v)
                                    },
                                    expression: "formData.goalType",
                                  },
                                }),
                                _vm._v(" "),
                                _c("validation-error-list", {
                                  attrs: {
                                    errors: _vm.getFieldErrors("GOAL_TYPE"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      { staticClass: "mb-3" },
                      [
                        _c("b-col", { attrs: { xl: "3", sm: "3" } }, [
                          _c("label", { attrs: { for: "input-goal-value" } }, [
                            _vm._v(_vm._s(_vm.$t("form.goalValue"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { xl: "9", sm: "9" } },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "input-goal-value",
                                type: "number",
                                min: "0",
                                max: "100",
                                placeholder: _vm.$t("form.valuePlaceholder"),
                                disabled: _vm.inputsDisabled,
                                state: _vm.getFieldState("GOAL_VALUE"),
                              },
                              model: {
                                value: _vm.formData.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "value", $$v)
                                },
                                expression: "formData.value",
                              },
                            }),
                            _vm._v(" "),
                            _c("validation-error-list", {
                              attrs: {
                                errors: _vm.getFieldErrors("GOAL_VALUE"),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.userIsAdmin
                      ? _c(
                          "b-row",
                          { staticClass: "mb-3" },
                          [
                            _c("b-col", { attrs: { xl: "3", sm: "3" } }, [
                              _c(
                                "label",
                                { attrs: { for: "input-goal-domain-type" } },
                                [_vm._v(_vm._s(_vm.$t("form.goalDomainType")))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { attrs: { xl: "9", sm: "9" } },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    disabled: _vm.inputsDisabled,
                                    options: _vm.goalDomainTypes,
                                    state:
                                      _vm.getFieldState("GOAL_DOMAIN_TYPE"),
                                  },
                                  on: { input: _vm.resetLocationsAndGroups },
                                  model: {
                                    value: _vm.formData.goalDomainType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "goalDomainType",
                                        $$v
                                      )
                                    },
                                    expression: "formData.goalDomainType",
                                  },
                                }),
                                _vm._v(" "),
                                _c("validation-error-list", {
                                  attrs: {
                                    errors:
                                      _vm.getFieldErrors("GOAL_DOMAIN_TYPE"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showLocationGroups || _vm.showLocations
                      ? _c(
                          "b-row",
                          { staticClass: "mb-3" },
                          [
                            _c("b-col", { attrs: { xl: "3", sm: "3" } }, [
                              _vm.showLocations
                                ? _c(
                                    "label",
                                    { attrs: { for: "input-locations" } },
                                    [_vm._v(_vm._s(_vm.$t("form.locations")))]
                                  )
                                : _vm.showLocationGroups
                                ? _c(
                                    "label",
                                    { attrs: { for: "input-locations" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("form.locationGroups"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { attrs: { xl: "9", sm: "9" } },
                              [
                                _c("location-group-select", {
                                  attrs: {
                                    id: "input-locations",
                                    "is-loading": false,
                                    limit: 999,
                                    "is-in-form": true,
                                    "pre-selected-ids": _vm.preselectedIds,
                                    "show-location-groups":
                                      _vm.showLocationGroups,
                                    "show-locations": _vm.showLocations,
                                    disabled: _vm.inputsDisabled,
                                    state: _vm.getFieldState("LOCATION_SELECT"),
                                    "show-archived": false,
                                  },
                                  on: {
                                    "change-locations": _vm.locationsChanged,
                                  },
                                }),
                                _vm._v(" "),
                                _c("validation-error-list", {
                                  attrs: {
                                    errors:
                                      _vm.getFieldErrors("LOCATION_SELECT"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      { staticClass: "mb-3" },
                      [
                        _c("b-col", { attrs: { xl: "3", sm: "3" } }, [
                          _c("label", { attrs: { for: "input-priority" } }, [
                            _vm._v(_vm._s(_vm.$t("form.priority"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { xl: "9", sm: "9" } },
                          [
                            _c("b-form-select", {
                              attrs: {
                                disabled: _vm.inputsDisabled,
                                options: _vm.priorities,
                                state: _vm.getFieldState("PRIORITY"),
                              },
                              model: {
                                value: _vm.formData.priority,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "priority", $$v)
                                },
                                expression: "formData.priority",
                              },
                            }),
                            _vm._v(" "),
                            _c("validation-error-list", {
                              attrs: { errors: _vm.getFieldErrors("PRIORITY") },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "float-right",
                                attrs: {
                                  variant: "primary",
                                  disabled:
                                    _vm.isOriginalData || _vm.inputsDisabled,
                                },
                                on: { click: _vm.saveGoal },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.isCreation
                                      ? _vm.$t("actions.create")
                                      : _vm.$t("actions.save")
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : !_vm.serverErrorOccurred
              ? _c("div", { staticClass: "spinner-wrapper" }, [
                  _c("div", { staticClass: "opn-spinner" }),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }