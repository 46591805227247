var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-management-preview-rating-slider" },
    [
      _vm.question.showCategoryInSurvey
        ? _c("div", { staticClass: "question-category" }, [
            _vm._v("\n    " + _vm._s(_vm.questionCategoryName) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "slider",
          class: { "has-scale-label": _vm.hasAtLeastOneScaleLabel },
        },
        [
          _c("div", { staticClass: "slider-base" }),
          _vm._v(" "),
          _vm._l(_vm.sliderBullets, function (bullet, index) {
            return _c(
              "div",
              {
                staticClass: "bullet",
                class: bullet.color,
                style: _vm.sliderBulletStyle(index),
              },
              [
                _c("div", { staticClass: "bullet-number" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.isLeftHighRightLow
                          ? _vm.question.scale - index
                          : index + 1
                      ) +
                      "\n      "
                  ),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "scale-labels" }, [
            _c(
              "div",
              {
                staticClass: "scale-label",
                class: _vm.isLeftHighRightLow ? "right" : "left",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.lasPreviewTranslation(_vm.question.scaleLabelMin)
                    ) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "scale-label",
                class: _vm.isLeftHighRightLow ? "left" : "right",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.lasPreviewTranslation(_vm.question.scaleLabelMax)
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _vm.question.ratingReasonThreshold > 0
        ? _c("survey-management-preview-rating-reasons", {
            attrs: { question: _vm.question },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }