var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-answers-loading" },
    _vm._l(5, function (item) {
      return _c("div", { key: item, staticClass: "text-answer-loading" }, [
        _vm._m(0, true),
        _vm._v(" "),
        _vm._m(1, true),
        _vm._v(" "),
        _c("span", { staticClass: "timestamp-loading line-segment" }),
        _vm._v(" "),
        _c("span", { staticClass: "full-feedback-loading line-segment" }),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "answer-loading line1" }, [
      _c("span", { staticClass: "line-segment" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "answer-loading line2" }, [
      _c("span", { staticClass: "line-segment" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }