import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import Toasted from 'vue-toasted';
import moment from 'moment';
var CLEAR_CACHE_INTERVAL = 1000;
var MAX_LIVEABLE_IN_CACHE = 3000;
var cachedToasts = {
  ERROR: [],
  INFO: [],
  SUCCESS: []
};
var clearCachedToasts = function clearCachedToasts() {
  Object.keys(cachedToasts).forEach(function (level) {
    if (cachedToasts[level].length > 0) {
      var newToastsOfThatLevel = [];
      cachedToasts[level].forEach(function (cachedToast) {
        var date = moment(cachedToast.date);
        var datePlusThreshold = moment(date).clone().add(MAX_LIVEABLE_IN_CACHE, 'milliseconds');
        var now = moment();
        if (datePlusThreshold.isSameOrAfter(now)) {
          newToastsOfThatLevel.push(cachedToast);
        }
      });
      cachedToasts[level] = newToastsOfThatLevel;
    }
  });
};
var isInCache = function isInCache(payload, level) {
  var returningStatement = false;
  if (cachedToasts[level].length > 0) {
    cachedToasts[level].forEach(function (cachedToast) {
      if (cachedToast.payload === payload) {
        returningStatement = true;
      }
    });
  }
  return returningStatement;
};
var putInCache = function putInCache(payload, level) {
  cachedToasts[level].push({
    date: new Date(),
    payload: payload
  });
};
var Toaster = {
  initToaster: function initToaster() {
    window.setInterval(clearCachedToasts, CLEAR_CACHE_INTERVAL);
    Vue.use(Toasted, {
      duration: 5000,
      closeOnSwipe: false,
      className: 'toaster-toast',
      globalToasts: {
        error: function error(payload, initiate) {
          if (!isInCache(payload, 'ERROR')) {
            putInCache(payload, 'ERROR');
            return initiate(payload, 'error');
          }
        },
        info: function info(payload, initiate) {
          if (!isInCache(payload, 'INFO')) {
            putInCache(payload, 'INFO');
            return initiate(payload, 'info');
          }
        },
        success: function success(payload, initiate) {
          if (!isInCache(payload, 'SUCCESS')) {
            putInCache(payload, 'SUCCESS');
            return initiate(payload, 'success');
          }
        }
      }
    });
  }
};
export default Toaster;