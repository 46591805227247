import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ComparisonService from '@/services/ComparisonService';
import LocationGroupTableEntry from '@/components/shared/LocationGroupTableEntry';
import moment from 'moment';
import { Bus as VuedalsBus } from 'vuedals';
import WebSourceQRCodeModal from '@/components/web-source-management/WebSourceQRCodeModal';
var dateFormat = 'DD.MM.YYYY HH:mm';
export default {
  name: 'WebSourceManagementOverviewTable',
  components: {
    LocationGroupTableEntry: LocationGroupTableEntry
  },
  props: {
    webSources: {
      type: Array,
      required: true
    },
    webSourceStatus: {
      type: String,
      required: true
    },
    filter: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    isTableActive: function isTableActive() {
      return this.type === 'active';
    },
    isTableInactive: function isTableInactive() {
      return this.type === 'inactive';
    },
    fields: function fields() {
      // fields in computed allows translation to adapt
      return [{
        key: 'createdDate',
        label: this.$t('fields.createdDate'),
        sortable: true
      }, {
        key: 'description',
        label: this.$t('fields.description'),
        sortable: true
      }, {
        key: 'locations',
        label: this.$t('fields.locations'),
        sortable: true,
        filterByFormatted: true,
        sortByFormatted: true,
        formatter: function formatter(value) {
          var locationNames = '';
          if (value.length > 0) {
            value.forEach(function (location) {
              locationNames = locationNames.concat(location.name);
            });
          }
          return locationNames;
        }
      }, {
        key: 'lastAnswerSetNotSpamWithCurrentLocations',
        label: this.$t('fields.lastFeedbackTimestamp'),
        sortable: true
      }, {
        key: 'link',
        label: this.$t('fields.link'),
        sortable: false
      }, {
        key: 'actions',
        label: ' ',
        sortable: false
      }];
    }
  },
  methods: {
    sortCompare: function sortCompare(aRow, bRow, key) {
      var a = aRow[key];
      var b = bRow[key];
      if (key === 'createdDate' || key === 'description' || key === 'lastAnswerSetNotSpamWithCurrentLocations') {
        return ComparisonService.compareStrings(a, b);
      }
      if (key === 'locations') {
        return ComparisonService.compareStrings(a[0].name, b[0].name);
      }
      throw new Error("Sorting by key '".concat(key, "' is not enabled."));
    },
    parseLocations: function parseLocations(locations) {
      var parsedLocations = [];
      locations.forEach(function (location) {
        parsedLocations.push(location.name);
      });
      return parsedLocations;
    },
    copyLink: function copyLink(item) {
      try {
        var surveyLink = item.webSourceLink;
        navigator.clipboard.writeText(surveyLink);
        this.$toasted.global.info(this.$t('copiedNotification'));
      } catch (e) {
        this.$toasted.global.error(this.$t('notCopiedNotification'));
      }
    },
    openQRCodeDialog: function openQRCodeDialog(item) {
      var webSourceLink = item.webSourceLink;
      VuedalsBus.$emit('new', {
        title: this.$t('qrCodeModalTitle'),
        name: 'qr-code-generator-modal',
        size: 'lg',
        closeOnBackdrop: false,
        props: {
          webSourceLink: webSourceLink
        },
        component: WebSourceQRCodeModal
      });
    },
    formatDate: function formatDate(timeStamp) {
      return moment(timeStamp).format(dateFormat);
    },
    disableWebSource: function disableWebSource(item) {
      this.$emit('disable-web-source', item);
    },
    enableWebSource: function enableWebSource(item) {
      this.$emit('enable-web-source', item);
    },
    deleteWebSource: function deleteWebSource(item) {
      this.$emit('delete-web-source', item);
    }
  }
};