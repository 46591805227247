import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.symbol.description.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import WebSourceManagementOverviewTable from '@/components/web-source-management/WebSourceManagementOverviewTable';
import { WebSourceManagement } from '@/services/ResourceService';
import ConfirmService from '@/services/ConfirmService';
export default {
  name: 'WebSourceManagementOverviewPanel',
  components: {
    Alert: Alert,
    WebSourceManagementOverviewTable: WebSourceManagementOverviewTable
  },
  data: function data() {
    return {
      isLoading: false,
      errorOccurred: false,
      filter: '',
      companyId: null,
      enabledWebSources: [],
      disabledWebSources: []
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      this.companyId = this.$store.state.general.companyId;
      Promise.all([WebSourceManagement.getEnabledWebSources(this.companyId), WebSourceManagement.getDisabledWebSources(this.companyId)]).then(function (response) {
        _this.enabledWebSources = response[0].data;
        _this.disabledWebSources = response[1].data;
        _this.isLoading = false;
      }, function () {
        _this.errorOccurred = true;
        _this.isLoading = false;
      });
    },
    reloadWebSources: function reloadWebSources() {
      this.disabledWebSources = [];
      this.enabledWebSources = [];
      this.loadData();
    },
    enableWebSource: function enableWebSource(item) {
      var _this2 = this;
      this.isLoading = true;
      WebSourceManagement.enableWebSource(this.companyId, item.id).then(function () {
        _this2.reloadWebSources();
        _this2.isLoading = false;
      }).catch(function () {
        _this2.errorOccurred = true;
        _this2.isLoading = false;
      });
    },
    disableWebSource: function disableWebSource(item) {
      var _this3 = this;
      this.isLoading = true;
      WebSourceManagement.disableWebSource(this.companyId, item.id).then(function () {
        _this3.reloadWebSources();
        _this3.isLoading = false;
      }).catch(function () {
        _this3.errorOccurred = true;
        _this3.isLoading = false;
      });
    },
    deleteWebSource: function deleteWebSource(item) {
      var _this4 = this;
      this.isLoading = true;
      WebSourceManagement.deleteWebSource(this.companyId, item.id).then(function () {
        _this4.reloadWebSources();
        _this4.isLoading = false;
      }).catch(function () {
        _this4.errorOccurred = true;
        _this4.isLoading = false;
      });
    },
    requestDisableWebSource: function requestDisableWebSource(webSource) {
      var _this5 = this;
      ConfirmService.createModal({
        name: 'confirm-disable-web-source',
        type: 'warning',
        title: this.$t('requestDisableWebSource.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestDisableWebSource.text', {
          webSource: webSource.description || webSource.webSourceLink
        }),
        textIsHtml: true,
        okayButton: this.$t('requestDisableWebSource.okay'),
        dismissButton: this.$t('requestDisableWebSource.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this5.disableWebSource(webSource);
          }
        }
      });
    },
    requestEnableWebSource: function requestEnableWebSource(webSource) {
      var _this6 = this;
      ConfirmService.createModal({
        name: 'confirm-enable-web-source',
        type: 'warning',
        title: this.$t('requestEnableWebSource.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestEnableWebSource.text', {
          webSource: webSource.description || webSource.webSourceLink
        }),
        textIsHtml: true,
        okayButton: this.$t('requestEnableWebSource.okay'),
        dismissButton: this.$t('requestEnableWebSource.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this6.enableWebSource(webSource);
          }
        }
      });
    },
    requestDeleteWebSource: function requestDeleteWebSource(webSource) {
      var _this7 = this;
      ConfirmService.createModal({
        name: 'confirm-delete-web-source',
        type: 'warning',
        title: this.$t('requestDeleteWebSource.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestDeleteWebSource.text', {
          webSource: webSource.description || webSource.webSourceLink
        }),
        textIsHtml: true,
        okayButton: this.$t('requestDeleteWebSource.okay'),
        dismissButton: this.$t('requestDeleteWebSource.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this7.deleteWebSource(webSource);
          }
        }
      });
    }
  }
};