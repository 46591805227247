import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import ColorLegend from '@/components/shared/ColorLegend';
import PrintInfoBox from '@/components/print/report/shared/PrintInfoBox';
export default {
  name: 'PrintBasicInformation',
  components: {
    PrintInfoBox: PrintInfoBox,
    ColorLegend: ColorLegend
  },
  props: {
    locationsWithActualData: {
      type: Array,
      required: true
    },
    generationDate: {
      type: Date,
      required: true
    },
    requestDateStart: {
      type: String,
      required: true
    },
    requestDateEnd: {
      type: String,
      required: true
    }
  },
  computed: {
    generationDateFormatted: function generationDateFormatted() {
      return moment(this.generationDate).format("DD.MM.YYYY [".concat(this.$t('dateAtHour'), "] HH.mm"));
    },
    groupedData: function groupedData() {
      var groupedData = {};
      this.locationsWithActualData.forEach(function (l) {
        if (!groupedData.hasOwnProperty(l.locationGroupId)) {
          groupedData[l.locationGroupId] = [];
        }
        groupedData[l.locationGroupId].push(l);
      });
      return groupedData;
    },
    currentUser: function currentUser() {
      if (this.$store.state.general.user !== null) {
        return "".concat(this.$store.state.general.user.firstName, " ").concat(this.$store.state.general.user.lastName);
      }
      return null;
    }
  }
};