//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Panel',
  props: {
    panelTitle: {
      type: String,
      required: false,
      default: ''
    },
    loadingTitleClass: {
      type: String,
      required: false,
      default: 'w-75'
    },
    loadingMinHeight: {
      type: Number,
      required: false,
      default: 150
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    hover: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    panelClass: function panelClass() {
      return {
        loaded: !this.isLoading,
        hover: this.hover
      };
    },
    panelStyle: function panelStyle() {
      return {
        'min-height': this.isLoading ? this.loadingMinHeight + 'px' : 'auto'
      };
    }
  }
};