//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LocationsGroupSelect from '@/components/shared/LocationGroupSelect';
import ValidationHelperService from '@/services/ValidationHelperService';
import ValidationErrorList from '@/components/shared/ValidationErrorList';
import _ from 'lodash';
export default {
  name: 'FilterEditForm',
  components: {
    ValidationErrorList: ValidationErrorList,
    LocationsGroupSelect: LocationsGroupSelect
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    isCreation: {
      type: Boolean,
      required: true
    },
    validationErrors: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    preselectedIds: function preselectedIds() {
      return {
        locations: this.formData.locationIds,
        locationGroups: this.formData.locationGroupIds
      };
    },
    parsedValidationErrors: function parsedValidationErrors() {
      var validationErrorsCopy = _.cloneDeep(this.validationErrors);
      if (validationErrorsCopy != null) {
        return ValidationHelperService.parseValidationErrorsWithTranslations(validationErrorsCopy, this);
      }
      return null;
    }
  },
  methods: {
    locationsChanged: function locationsChanged(newFilter) {
      this.formData.locationIds = newFilter.locations;
      this.formData.locationGroupIds = newFilter.locationGroups;
    },
    getFieldErrors: function getFieldErrors(fieldName) {
      return ValidationHelperService.getFieldErrors(fieldName, this.parsedValidationErrors);
    }
  }
};