//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import SurveyManagementLasWrapper from '@/components/survey-management/edit-question/questions/SurveyManagementLasWrapper';
export default {
  name: 'SurveyManagementEditEmailQuestion',
  components: {
    SurveyManagementLasWrapper: SurveyManagementLasWrapper
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    editQuestion: {
      type: Object,
      required: true
    },
    currentLanguage: {
      type: String,
      required: true
    }
  },
  created: function created() {
    this.setInternalEditQuestion();
    this.setInternalCurrentLanguage();
  },
  data: function data() {
    return {
      internalEditQuestion: null,
      internalCurrentLanguage: null
    };
  },
  computed: {
    editPageState: function editPageState() {
      return this.$store.state.surveyManagement.editPageState;
    },
    validation: function validation() {
      return this.$store.getters['surveyManagement/editQuestionValidation'];
    }
  },
  methods: {
    setInternalEditQuestion: function setInternalEditQuestion() {
      this.internalEditQuestion = this.cloneDeep(this.editQuestion);
    },
    setInternalCurrentLanguage: function setInternalCurrentLanguage() {
      this.internalCurrentLanguage = this.currentLanguage;
    },
    cloneDeep: _.cloneDeep
  },
  watch: {
    internalEditQuestion: {
      deep: true,
      handler: function handler() {
        if (this.internalEditQuestion && !this.internalEditQuestion.contactRequestEnabled && !_.isEqual(this.internalEditQuestion.contactRequestText, this.question.contactRequestText)) {
          this.internalEditQuestion.contactRequestText = this.cloneDeep(this.question.contactRequestText);
        }
        if (!_.isEqual(this.internalEditQuestion, this.editQuestion)) {
          this.$emit('update-edit-question', this.cloneDeep(this.internalEditQuestion));
        }
      }
    },
    editQuestion: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalEditQuestion, this.editQuestion)) {
          this.setInternalEditQuestion();
        }
      }
    },
    internalCurrentLanguage: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalCurrentLanguage, this.currentLanguage)) {
          this.$emit('change-language', this.internalCurrentLanguage);
        }
      }
    },
    currentLanguage: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalCurrentLanguage, this.currentLanguage)) {
          this.setInternalCurrentLanguage();
        }
      }
    }
  }
};