import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
export default {
  authorizeUser: function authorizeUser(state, user) {
    // a new non-super-admin user needs to set the company
    if (user !== null && user.authorities.indexOf('ROLE_SUPER_ADMIN') === -1) {
      state.companyId = user.companyId;
      state.selectedUser = user;
    }
    state.user = user;
    state.isAuthenticated = user !== null;
    if (user === null) {
      state.setComparison = null;
      state.allLocations = [];
    }
  },
  changeLanguage: function changeLanguage(state, language) {
    state.language = language;
  },
  setAllLocations: function setAllLocations(state, locations) {
    state.allLocations = locations;
  },
  filterByLocationsGroups: function filterByLocationsGroups(state, newFilter) {
    state.filter.locations = newFilter.locations;
    state.filter.locationGroups = newFilter.locationGroups;
  },
  changeDateRange: function changeDateRange(state, dateRange) {
    state.filter.dateRange.start = dateRange.start;
    state.filter.dateRange.end = dateRange.end;
  },
  setSelectedUser: function setSelectedUser(state, user) {
    state.selectedUser = user;
  },
  setCompanyId: function setCompanyId(state, companyId) {
    state.companyId = companyId;
  },
  updateAccount: function updateAccount(state, account) {
    if (!state.user) {
      state.user = {};
    }
    Object.keys(account).forEach(function (key) {
      var value = account[key];
      state.user[key] = value;
    });
  },
  setUnderMaintenance: function setUnderMaintenance(state, value) {
    state.underMaintenance = value;
  },
  setComparison: function setComparison(state, value) {
    state.comparison = value;
  },
  setPrintView: function setPrintView(state, value) {
    state.printView = value;
  },
  setCompanyProperties: function setCompanyProperties(state, value) {
    state.companyProperties = value;
  },
  setIE: function setIE(state, value) {
    state.ie = value;
  },
  setMainProperties: function setMainProperties(state, value) {
    state.mainProperties = value;
  }
};