//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import Alert from '@/components/shared/Alert';
import { Account } from '@/services/ResourceService';
import PasswordValidationErrorList from '@/components/shared/PasswordValidationErrorList';
import ScrollAnimatorService from '@/services/ScrollAnimatorService';
export default {
  name: 'ChangePassword',
  components: {
    PasswordValidationErrorList: PasswordValidationErrorList,
    Panel: Panel,
    Alert: Alert
  },
  data: function data() {
    return {
      form: {
        currentPassword: '',
        currentPasswordVisible: false,
        newPassword: '',
        newPasswordVisible: false
      },
      username: '',
      isLoading: false,
      serverErrorOccurred: false,
      passwordValidationErrorOccurred: false,
      passwordValidationErrors: [],
      successfullySaved: false
    };
  },
  mounted: function mounted() {
    // the username is used to indicate the browser which password to store
    this.username = this.$store.state.general.user.login;
  },
  methods: {
    toggleCurrentPasswordVisibility: function toggleCurrentPasswordVisibility() {
      this.form.currentPasswordVisible = !this.form.currentPasswordVisible;
    },
    toggleNewPasswordVisibility: function toggleNewPasswordVisibility() {
      this.form.newPasswordVisible = !this.form.newPasswordVisible;
    },
    saveInfo: function saveInfo() {
      var _this = this;
      this.isLoading = true;
      this.successfullySaved = false;
      this.serverErrorOccurred = false;
      Account.changePassword({
        currentPassword: this.form.currentPassword,
        newPassword: this.form.newPassword
      }).then(function () {
        _this.isLoading = false;
        _this.successfullySaved = true;
        _this.passwordValidationErrorOccurred = false;
        _this.passwordValidationErrors = [];
        _this.form.currentPassword = '';
        _this.form.newPassword = '';
        _this.form.currentPasswordVisible = false;
        _this.form.newPasswordVisible = false;
        ScrollAnimatorService.scrollToTopOfElement(_this.$el);
      }, function (error) {
        _this.isLoading = false;
        var data = error.response.data;
        var errCode = data.status.code;
        var payload = data.payload;
        if (errCode === 400 && payload && payload.length > 0) {
          _this.passwordValidationErrorOccurred = true;
          _this.passwordValidationErrors = payload;
        } else {
          _this.serverErrorOccurred = true;
        }
        ScrollAnimatorService.scrollToTopOfElement(_this.$el);
      });
    }
  }
};