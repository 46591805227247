var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "print-change-over-time print-section" },
    [
      _c("div", { staticClass: "print-h2" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              this.hasIndexCalculation
                ? _vm.$t("title.changeOverTime")
                : _vm.$t("title.changeOverTimePercent")
            ) +
            "\n    "
        ),
        _c("span", { staticClass: "badge badge-pill badge-info" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("intervals." + _vm.selectedInterval)) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.selectedInterval !== null
        ? _c("change-over-time-chart", {
            staticClass: "change-over-time-chart",
            attrs: {
              data: _vm.selectedData,
              interval: _vm.selectedInterval,
              "has-relevance-filter": false,
              "height-in-pixel": 260,
              small: true,
              "use-index-calculation": _vm.hasIndexCalculation,
              "is-pdf": "",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }