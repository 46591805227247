var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "question-wrapper",
      class: [
        _vm.question.questionType,
        {
          expanded: _vm.isExpanded,
          "no-answers": _vm.noAnswersInQuestion,
          narrow: _vm.isNarrow,
        },
        _vm.question.questionType === "RATING_QUESTION" &&
        _vm.hasIndexCalculation
          ? _vm.getColorNameByIndex(
              _vm.question.averageIndex,
              _vm.$store.state.general.companyProperties
            )
          : null,
      ],
    },
    [
      _c("div", { staticClass: "timeline-line" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.bottom.d200",
              modifiers: { hover: true, bottom: true, d200: true },
            },
          ],
          staticClass: "timeline-icon",
          attrs: { title: _vm.$t("questionLong") + " " + _vm.counter },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("questionShort")) +
              _vm._s(_vm.counter) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "question-info" }, [
          _c("div", { staticClass: "question-info-header" }, [
            _vm.question.category
              ? _c("span", {
                  staticClass: "badge badge-pill badge-category",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$options.filters.languageAwareString(
                        _vm.question.category.name
                      )
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "question-type" }, [
              _vm.question.questionType === "RATING_QUESTION"
                ? _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("scale", { scale: _vm.question.scale })) +
                        "\n          "
                    ),
                  ])
                : _vm.question.questionType === "CHOICE_QUESTION"
                ? _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("choiceType." + _vm.question.choiceType)
                        ) +
                        "\n          "
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("questionType." + _vm.question.questionType)
                        ) +
                        "\n          "
                    ),
                  ]),
            ]),
          ]),
          _vm._v(" "),
          _c("h2", {
            staticClass: "question-text",
            domProps: {
              innerHTML: _vm._s(
                _vm.$options.filters.languageAwareString(_vm.question.text)
              ),
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "button-row" }, [
            _vm.hasExpandedSection
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left.d200",
                        modifiers: { hover: true, left: true, d200: true },
                      },
                    ],
                    staticClass:
                      "btn btn-sm btn-outline-primary toggle-expandable",
                    class: { expanded: _vm.isExpanded },
                    attrs: {
                      disabled: _vm.noAnswersInQuestion,
                      title: _vm.noAnswersInQuestion
                        ? null
                        : _vm.toggleExpandableButtonText,
                    },
                    on: { click: _vm.toggleExpansion },
                  },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["far", "angle-down"] },
                    }),
                  ],
                  1
                )
              : _vm.hasInspectionModal
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left.d200",
                        modifiers: { hover: true, left: true, d200: true },
                      },
                    ],
                    staticClass:
                      "btn btn-sm btn-outline-primary open-inspection-modal",
                    attrs: {
                      disabled: _vm.noAnswersInQuestion,
                      title: _vm.noAnswersInQuestion
                        ? null
                        : _vm.$t("title.showAnswers"),
                    },
                    on: { click: _vm.openInspectionModal },
                  },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["far", "angle-down"] },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "calculated-data" }, [
          _vm.question.questionType === "RATING_QUESTION" &&
          !_vm.$store.getters["general/hasIndexCalculation"]
            ? _c("span", { staticClass: "data-value" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left.d200",
                        modifiers: { hover: true, left: true, d200: true },
                      },
                    ],
                    staticClass: "data-value-content",
                    attrs: {
                      title: _vm.noAnswersInQuestion
                        ? null
                        : _vm.$t("tooltip.averageRating"),
                    },
                  },
                  [
                    _c("span", { staticClass: "icon unicode" }, [_vm._v("ø")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.question.averageRating
                              ? _vm.$options.filters.number(
                                  _vm.question.averageRating,
                                  2
                                )
                              : 0.0
                          ) +
                          "\n            /\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.question.scale)),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.question.questionType === "RATING_QUESTION" &&
          _vm.$store.getters["general/hasIndexCalculation"]
            ? _c(
                "span",
                {
                  staticClass: "data-value average-index",
                  class: _vm.getColorNameByIndex(
                    _vm.question.averageIndex,
                    _vm.$store.state.general.companyProperties
                  ),
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.left.d200",
                          modifiers: { hover: true, left: true, d200: true },
                        },
                      ],
                      staticClass: "data-value-content",
                      attrs: {
                        title: _vm.noAnswersInQuestion
                          ? null
                          : _vm.$t("tooltip.averageIndex"),
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "icon",
                          class: { "no-answers": _vm.noAnswersInQuestion },
                        },
                        [
                          _c("font-awesome", {
                            staticClass: "font-awesome",
                            attrs: { icon: ["far", "smile"] },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.question.averageIndex !== null
                        ? _c("span", { staticClass: "number" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("number")(_vm.question.averageIndex, 1)
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.question.questionType === "RATING_QUESTION"
            ? _c("span", { staticClass: "data-value" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left.d200",
                        modifiers: { hover: true, left: true, d200: true },
                      },
                    ],
                    staticClass: "data-value-content",
                    attrs: {
                      title: _vm.noAnswersInQuestion
                        ? null
                        : _vm.$t("tooltip.numberOfRatingReasons"),
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["fas", "tasks"] },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("groupedNumber")(
                              _vm.question.ratingReasonAnswerCounter
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.question.questionType === "CHOICE_QUESTION"
            ? _c("span", { staticClass: "data-value" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left.d200",
                        modifiers: { hover: true, left: true, d200: true },
                      },
                    ],
                    staticClass: "data-value-content",
                    attrs: {
                      title: _vm.noAnswersInQuestion
                        ? null
                        : _vm.$t("tooltip.numberOfChoiceAttributes"),
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["fas", "tasks"] },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("groupedNumber")(
                              _vm.question.choiceAttributeAnswerCounter
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.question.questionType === "EMAIL_QUESTION" &&
          _vm.question.contactRequestCounter > 0
            ? _c("span", { staticClass: "data-value" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.left.d200",
                        modifiers: { hover: true, left: true, d200: true },
                      },
                    ],
                    staticClass: "data-value-content",
                    attrs: {
                      title: _vm.noAnswersInQuestion
                        ? null
                        : _vm.$t("tooltip.numberOfContactRequests"),
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon" },
                      [_c("contact-request-icon")],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("groupedNumber")(
                              _vm.question.contactRequestCounter
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "data-value" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.left.d200",
                    modifiers: { hover: true, left: true, d200: true },
                  },
                ],
                staticClass: "data-value-content",
                attrs: {
                  title: _vm.noAnswersInQuestion
                    ? null
                    : _vm.$t("tooltip.numberOfFeedbacks"),
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["fas", "user"] },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("groupedNumber")(_vm.question.answerCounter)
                      ) +
                      "\n          "
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade-height" } },
        [
          _vm.isExpanded || _vm.renderChart
            ? _c("question-expanded-section", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isExpanded,
                    expression: "isExpanded",
                  },
                ],
                attrs: {
                  "survey-id": _vm.surveyId,
                  question: _vm.question,
                  "query-parameters": _vm.queryParameters,
                  "render-chart": _vm.renderChart,
                  counter: _vm.counter,
                  "is-narrow": _vm.isNarrow,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }