var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c("font-awesome", {
        staticClass: "font-awesome",
        attrs: { icon: ["far", _vm.icon], "aria-hidden": "true", size: "lg" },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }