import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.error.cause.js";
import { SurveyManagementOverview } from '@/services/ResourceService';
import { SurveyManagement } from '@/services/ResourceService';
export default {
  fetchOverviewTable: function fetchOverviewTable(_ref) {
    var rootState = _ref.rootState,
      commit = _ref.commit;
    commit('setOverviewTable', {
      isLoading: true,
      hasError: false,
      activeSurveys: null,
      archivedSurveys: null
    });
    return Promise.all([SurveyManagementOverview.getActiveSurveys(rootState.general.companyId), SurveyManagementOverview.getArchivedSurveys(rootState.general.companyId)]).then(function (response) {
      commit('setOverviewTable', {
        isLoading: false,
        hasError: false,
        activeSurveys: response[0].data,
        archivedSurveys: response[1].data
      });
    }, function () {
      commit('setOverviewTable', {
        isLoading: false,
        hasError: true,
        activeSurveys: null,
        archivedSurveys: null
      });
    });
  },
  archiveSurvey: function archiveSurvey(_ref2, _ref3) {
    var _this = this;
    var rootState = _ref2.rootState,
      commit = _ref2.commit;
    var surveyId = _ref3.surveyId;
    commit('setOverviewTable', {
      isLoading: true,
      hasError: false,
      activeSurveys: null,
      archivedSurveys: null
    });
    return SurveyManagementOverview.archiveSurvey(rootState.general.companyId, surveyId).then(function () {
      _this.dispatch('surveyManagement/fetchOverviewTable');
    }, function () {
      commit('setOverviewTable', {
        isLoading: false,
        hasError: true,
        activeSurveys: null,
        archivedSurveys: null
      });
    });
  },
  unarchiveSurvey: function unarchiveSurvey(_ref4, _ref5) {
    var _this2 = this;
    var rootState = _ref4.rootState,
      commit = _ref4.commit;
    var surveyId = _ref5.surveyId;
    commit('setOverviewTable', {
      isLoading: true,
      hasError: false,
      activeSurveys: null,
      archivedSurveys: null
    });
    return SurveyManagementOverview.unarchiveSurvey(rootState.general.companyId, surveyId).then(function () {
      _this2.dispatch('surveyManagement/fetchOverviewTable');
    }, function () {
      commit('setOverviewTable', {
        isLoading: false,
        hasError: true,
        activeSurveys: null,
        archivedSurveys: null
      });
    });
  },
  deleteSurvey: function deleteSurvey(_ref6, _ref7) {
    var _this3 = this;
    var rootState = _ref6.rootState,
      commit = _ref6.commit;
    var surveyId = _ref7.surveyId;
    commit('setOverviewTable', {
      isLoading: true,
      hasError: false,
      activeSurveys: null,
      archivedSurveys: null
    });
    return SurveyManagementOverview.deleteSurvey(rootState.general.companyId, surveyId).then(function () {
      _this3.dispatch('surveyManagement/fetchOverviewTable');
    }, function () {
      commit('setOverviewTable', {
        isLoading: false,
        hasError: true,
        activeSurveys: null,
        archivedSurveys: null
      });
    });
  },
  fetchFullSurvey: function fetchFullSurvey(_ref8) {
    var state = _ref8.state,
      rootState = _ref8.rootState,
      commit = _ref8.commit;
    if (state.selectedSurveyId !== null) {
      commit('setFullSurvey', {
        isLoading: true,
        hasError: false,
        data: null
      });
      return SurveyManagement.getFullSurvey(rootState.general.companyId, state.selectedSurveyId).then(function (response) {
        commit('setFullSurvey', {
          isLoading: false,
          hasError: false,
          data: response.data
        });
      }, function () {
        commit('setFullSurvey', {
          isLoading: false,
          hasError: true,
          data: null
        });
      });
    } else {
      return Promise.reject(new Error('No survey is selected.'));
    }
  },
  fetchCategories: function fetchCategories(_ref9) {
    var rootState = _ref9.rootState,
      commit = _ref9.commit;
    commit('setCategories', {
      isLoading: true,
      hasError: false,
      data: null
    });
    return SurveyManagement.getRatingQuestionCategoriesBySector(rootState.general.companyId).then(function (response) {
      commit('setCategories', {
        isLoading: false,
        hasError: false,
        data: response.data
      });
    }, function () {
      commit('setCategories', {
        isLoading: false,
        hasError: true,
        data: null
      });
    });
  },
  editPageStateInitial: function editPageStateInitial(_ref10) {
    var commit = _ref10.commit;
    commit('setEditPageState', {
      name: 'INITIAL'
    });
  },
  editPageStateAddCreateQuestion: function editPageStateAddCreateQuestion(_ref11, _ref12) {
    var commit = _ref11.commit;
    var questionNr = _ref12.questionNr;
    commit('setEditPageState', {
      name: 'ADD_CREATE_QUESTION',
      data: {
        questionNr: questionNr
      }
    });
  },
  editPageStateEditQuestion: function editPageStateEditQuestion(_ref13, _ref14) {
    var commit = _ref13.commit;
    var questionNr = _ref14.questionNr,
      question = _ref14.question,
      editQuestion = _ref14.editQuestion,
      currentLanguage = _ref14.currentLanguage,
      isNew = _ref14.isNew,
      anyLasIsFocused = _ref14.anyLasIsFocused,
      subpage = _ref14.subpage,
      showValidation = _ref14.showValidation;
    commit('setEditPageState', {
      name: 'EDIT_QUESTION',
      data: {
        questionNr: questionNr,
        question: question,
        editQuestion: editQuestion,
        currentLanguage: currentLanguage,
        isNew: isNew,
        anyLasIsFocused: anyLasIsFocused,
        subpage: subpage,
        showValidation: showValidation
      }
    });
  },
  editPageStateSavingEdit: function editPageStateSavingEdit(_ref15, _ref16) {
    var commit = _ref15.commit;
    var questionNr = _ref16.questionNr,
      question = _ref16.question,
      editQuestion = _ref16.editQuestion,
      isNew = _ref16.isNew,
      success = _ref16.success,
      error = _ref16.error;
    commit('setEditPageState', {
      name: 'SAVING_EDIT',
      data: {
        questionNr: questionNr,
        question: question,
        editQuestion: editQuestion,
        isNew: isNew,
        success: success,
        error: error
      }
    });
  },
  editPageStateSavingAdding: function editPageStateSavingAdding(_ref17, _ref18) {
    var commit = _ref17.commit;
    var questionNr = _ref18.questionNr,
      questionId = _ref18.questionId,
      questionType = _ref18.questionType,
      success = _ref18.success,
      error = _ref18.error;
    commit('setEditPageState', {
      name: 'SAVING_ADDING',
      data: {
        questionNr: questionNr,
        questionId: questionId,
        questionType: questionType,
        success: success,
        error: error
      }
    });
  }
};