var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comparison" },
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("h1", { staticClass: "site-title" }, [
            _vm._v(_vm._s(_vm.$t("title"))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "comparison-container" },
        [
          _c("one-side-comparison", {
            attrs: { "parent-config": _vm.config.first },
            on: {
              "change-config": _vm.setFirstConfig,
              "clear-config": _vm.clearFirstConfig,
            },
          }),
          _vm._v(" "),
          !_vm.config.first.dirty || !_vm.config.second.dirty
            ? _c("div", { staticClass: "separator" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.d200",
                        modifiers: { hover: true, d200: true },
                      },
                    ],
                    staticClass: "switch",
                    attrs: { title: _vm.$t("tooltip.switch") },
                    on: { click: _vm.switchSides },
                  },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["fas", "exchange"] },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.config.first.dirty || !_vm.config.second.dirty
            ? _c("one-side-comparison", {
                attrs: { "parent-config": _vm.config.second },
                on: {
                  "change-config": _vm.setSecondConfig,
                  "clear-config": _vm.clearSecondConfig,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$store.state.general.companyProperties !== null &&
      _vm.$store.getters["general/hasIndexCalculation"]
        ? _c("color-legend", {
            staticClass: "color-legend",
            attrs: {
              "is-loading": _vm.isLoadingCompanyProperties,
              "company-properties": _vm.$store.state.general.companyProperties,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }