var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: { disabled: _vm.disabled } },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "label",
          [
            _c("b-form-checkbox", {
              attrs: { disabled: _vm.disabled },
              on: { change: _vm.checkboxListener },
              model: {
                value: _vm.internalFilterByIndex,
                callback: function ($$v) {
                  _vm.internalFilterByIndex = $$v
                },
                expression: "internalFilterByIndex",
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.$t("title"))),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "legend" }, [
        _c(
          "div",
          {
            staticClass: "legend-item",
            style: { left: _vm.scaledIndicesInPercent.indexThresholdLow + "%" },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.companyProperties.indexThresholdLow) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "legend-item",
            style: {
              left: _vm.scaledIndicesInPercent.indexThresholdLowerMedium + "%",
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.companyProperties.indexThresholdLowerMedium) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "legend-item",
            style: {
              left: _vm.scaledIndicesInPercent.indexThresholdUpperMedium + "%",
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.companyProperties.indexThresholdUpperMedium) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "legend-item",
            style: {
              left: _vm.scaledIndicesInPercent.indexThresholdHigh + "%",
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.companyProperties.indexThresholdHigh) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "inputRange",
          staticClass: "satisfaction-input-range",
          attrs: { disabled: _vm.disabled },
        },
        [
          _c("div", {
            staticClass: "color-element",
            class: { shown: !_vm.internalFilterByIndex },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "color-element",
            class: { shown: _vm.internalFilterByIndex },
            style: _vm.backgroundStyle,
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }