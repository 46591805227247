var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading && _vm.companyProperties !== null
    ? _c("div", { staticClass: "color-legend", class: { small: _vm.small } }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.$t("title")))]),
        _vm._v(" "),
        _c("div", { staticClass: "legends" }, [
          _c("div", { staticClass: "legend legend-red" }, [
            _c("span", { staticClass: "color-box" }),
            _vm._v(" "),
            _c("span", { staticClass: "value" }, [
              _vm._v(
                _vm._s(0) +
                  "-" +
                  _vm._s(_vm.companyProperties.indexThresholdLow)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "legend legend-orange" }, [
            _c("span", { staticClass: "color-box" }),
            _vm._v(" "),
            _c("span", { staticClass: "value" }, [
              _vm._v(
                _vm._s(_vm.companyProperties.indexThresholdLow) +
                  "-" +
                  _vm._s(_vm.companyProperties.indexThresholdLowerMedium)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "legend legend-yellow" }, [
            _c("span", { staticClass: "color-box" }),
            _vm._v(" "),
            _c("span", { staticClass: "value" }, [
              _vm._v(
                _vm._s(_vm.companyProperties.indexThresholdLowerMedium) +
                  "-" +
                  _vm._s(_vm.companyProperties.indexThresholdUpperMedium)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "legend legend-green-light" }, [
            _c("span", { staticClass: "color-box" }),
            _vm._v(" "),
            _c("span", { staticClass: "value" }, [
              _vm._v(
                _vm._s(_vm.companyProperties.indexThresholdUpperMedium) +
                  "-" +
                  _vm._s(_vm.companyProperties.indexThresholdHigh)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "legend legend-green" }, [
            _c("span", { staticClass: "color-box" }),
            _vm._v(" "),
            _c("span", { staticClass: "value" }, [
              _vm._v(
                _vm._s(_vm.companyProperties.indexThresholdHigh) +
                  "-" +
                  _vm._s(100)
              ),
            ]),
          ]),
        ]),
      ])
    : _c(
        "div",
        { staticClass: "color-legend no-data", class: { small: _vm.small } },
        [
          _c("div", { staticClass: "title" }, [_vm._v(" ")]),
          _vm._v(" "),
          _vm._l(5, function (n) {
            return _c("div", { key: n, staticClass: "legend legend-grey" }, [
              _c("span", { staticClass: "color-box" }),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [_vm._v(" ")]),
            ])
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }