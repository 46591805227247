import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Http from '@/config/Http';
export default {
  name: 'Maintenance',
  data: function data() {
    return {
      interval: null,
      isLoading: false,
      statusCode: null
    };
  },
  mounted: function mounted() {
    this.intervalMethod();
    this.interval = window.setInterval(this.intervalMethod, 10000);
  },
  beforeDestroy: function beforeDestroy() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
  },
  methods: {
    intervalMethod: function intervalMethod() {
      var _this = this;
      var goToDashboard = function goToDashboard() {
        _this.isLoading = false;
        if (!_this.$store.state.general.underMaintenance) {
          _this.$router.push({
            name: 'dashboard'
          });
        }
      };
      if (!this.isLoading) {
        this.isLoading = true;
        Http.get('/account').then(goToDashboard, function (error) {
          if (_this.$store.state.general.underMaintenance) {
            if (error.response && error.response.status && parseInt(error.response.status, 10)) {
              _this.statusCode = parseInt(error.response.status, 10);
            }
          }
          goToDashboard();
        });
      }
    }
  }
};