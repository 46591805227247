//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QuestionsLoading from '@/components/survey-analysis/QuestionsLoading';
import { Comparison } from '@/services/ResourceService';
import Question from '@/components/survey-analysis/Question';
import moment from 'moment';
export default {
  name: 'SurveyBox',
  props: {
    parentConfig: {
      type: Object,
      required: true
    }
  },
  components: {
    Question: Question,
    QuestionsLoading: QuestionsLoading
  },
  data: function data() {
    return {
      isLoading: true,
      survey: {}
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      if (!this.parentConfig.dirty) {
        Comparison.getSurveyAnalysis(this.$store.state.general.companyId, this.parentConfig.locations.queryLocations, this.parentConfig.surveyId, this.parentConfig.choiceAttributeIds, moment(this.parentConfig.startInSeconds).format('DD.MM.YYYY'), moment(this.parentConfig.endInSeconds).format('DD.MM.YYYY')).then(function (response) {
          _this.survey = response.data;
          _this.isLoading = false;
        }, function () {
          _this.survey = {};
          _this.isLoading = false;
        });
      }
    }
  },
  computed: {
    queryParameters: function queryParameters() {
      return {
        companyId: this.$store.state.general.companyId,
        locationIds: this.parentConfig.locations.queryLocations,
        start: this.startDate,
        end: this.endDate,
        choiceAttributeIds: this.parentConfig.choiceAttributeIds
      };
    },
    startDate: function startDate() {
      return moment(this.parentConfig.startInSeconds).format('DD.MM.YYYY');
    },
    endDate: function endDate() {
      return moment(this.parentConfig.endInSeconds).format('DD.MM.YYYY');
    }
  },
  watch: {
    parentConfig: {
      deep: true,
      handler: function handler() {
        this.loadData();
      }
    }
  }
};