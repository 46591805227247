import _objectSpread from "/builds/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import QueryService from '@/services/QueryService';
import QueryUtils from '@/services/utils/QueryUtils';
import opnDateRangePicker from '@/components/shared/opnDateRangePicker';
import { Matrix as MatrixResource } from '@/services/ResourceService';
import LocationGroupSelect from '@/components/shared/LocationGroupSelect';
import ClearFilter from '@/components/shared/ClearFilter';
import EmptyState from '@/components/shared/EmptyState';
import MatrixView from '@/components/matrix/MatrixView';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import ColorLegend from '@/components/shared/ColorLegend';
import SearchFilterSelector from '@/components/shared/saved-search-filter/SearchFilterSelector';
import { mapState } from 'vuex';
export default {
  name: 'Matrix',
  components: {
    SearchFilterSelector: SearchFilterSelector,
    opnDateRangePicker: opnDateRangePicker,
    LocationGroupSelect: LocationGroupSelect,
    ClearFilter: ClearFilter,
    EmptyState: EmptyState,
    MatrixView: MatrixView,
    ColorLegend: ColorLegend
  },
  data: function data() {
    return {
      isLoading: true,
      matrix: {
        locations: {},
        locationGroups: {},
        locationIdToLocationGroupMapping: {}
      },
      filter: {
        location: {},
        dateRange: []
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    currentUser: function currentUser(state) {
      return state.general.user;
    }
  })), {}, {
    currentUserIsSuperAdmin: function currentUserIsSuperAdmin() {
      return this.currentUser.authorities.includes('ROLE_SUPER_ADMIN');
    },
    noData: function noData() {
      return Object.keys(this.matrix.locations).length === 0 && Object.keys(this.matrix.locationGroups).length === 0;
    }
  }),
  mounted: function mounted() {
    this.initializePage();
  },
  methods: {
    initializePage: function initializePage() {
      this.isLoading = true;
      var filter = QueryService.get('filter') || {};
      this.filter.dateRange = QueryUtils.getDateRangeFilter(filter.dateRange);
      this.filter.location = QueryUtils.getLocationFilter(filter.location);
      this.loadMatrix();
    },
    loadMatrix: function loadMatrix() {
      var _this = this;
      this.isLoading = true;
      this.resetMatrix();
      return Promise.all([MatrixResource.getData(this.$store.state.general.companyId, this.filter.location.queryLocations, moment(this.filter.dateRange[0]).format('DD.MM.YYYY'), moment(this.filter.dateRange[1]).format('DD.MM.YYYY')), CompanyPropertiesService.update()]).then(function (responses) {
        var data = responses[0].data;
        _this.isLoading = false;
        if (data.locations !== null && data.locationGroups !== null) {
          _this.matrix = data;
        } else {
          _this.resetMatrix();
        }
      }).catch(function () {
        _this.resetMatrix();
        _this.isLoading = false;
      });
    },
    resetMatrix: function resetMatrix() {
      this.matrix = {
        locations: {},
        locationGroups: {},
        locationIdToLocationGroupMapping: {}
      };
    },
    dateRangeChanged: function dateRangeChanged(dateRange) {
      this.filter.dateRange = dateRange;
      QueryService.put('filter', this.filter);
      this.loadMatrix();
    },
    locationsChanged: function locationsChanged(locationFilter) {
      this.filter.location = locationFilter;
      QueryService.put('filter', this.filter);
      this.loadMatrix();
    },
    filterReset: function filterReset() {
      this.initializePage();
    }
  }
};