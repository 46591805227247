import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Store from '@/store/Store';
import moment from 'moment';
Vue.use(VueI18n);

// improves the accessibility for screenreaders
var setHtmlLanguage = function setHtmlLanguage(language) {
  document.querySelector('html').setAttribute('lang', language);
};
var setMomentLanguage = function setMomentLanguage(language) {
  var momentLanguage = language === 'en' ? 'en-gb' : language;
  moment.locale(momentLanguage);
};
var messages = {
  de: require('@/i18n/de.yml'),
  en: require('@/i18n/en.yml'),
  fr: require('@/i18n/fr.yml'),
  it: require('@/i18n/it.yml')
};
var getBrowserLanguageOrGerman = function getBrowserLanguageOrGerman() {
  var browserLanguage = window.navigator.language || '';
  if (browserLanguage) {
    if (/^en/i.test(browserLanguage)) {
      return 'en';
    } else if (/^fr/i.test(browserLanguage)) {
      return 'fr';
    } else if (/^it/i.test(browserLanguage)) {
      return 'it';
    } else {
      return 'de';
    }
  } else {
    return 'de';
  }
};
var initialLanguage = function initialLanguage() {
  var language = getBrowserLanguageOrGerman();
  if (Store.state.general.language) {
    language = Store.state.general.language;
  } else {
    // if the language is not taken from the store,
    // it needs to be sent to the store
    Store.commit('general/changeLanguage', language);
  }
  setHtmlLanguage(language);
  setMomentLanguage(language);
  return language;
};
var i18n = new VueI18n({
  locale: initialLanguage(),
  fallbackLocale: 'de',
  silentTranslationWarn: true,
  messages: messages
});

// Setup hot reloading
if (module.hot) {
  module.hot.accept(['@/i18n/de.yml', '@/i18n/en.yml', '@/i18n/fr.yml', '@/i18n/it.yml'], function () {
    i18n.setLocaleMessage('de', require('@/i18n/de.yml'));
    i18n.setLocaleMessage('en', require('@/i18n/en.yml'));
    i18n.setLocaleMessage('fr', require('@/i18n/fr.yml'));
    i18n.setLocaleMessage('it', require('@/i18n/it.yml'));
  });
}
var change = function change(language) {
  if (!language) {
    language = getBrowserLanguageOrGerman();
  }
  i18n.locale = language;
  Store.commit('general/changeLanguage', language);
  setHtmlLanguage(language);
  setMomentLanguage(language);
};
var current = function current() {
  return i18n.locale;
};
Vue.prototype.$locale = {
  change: change,
  current: current
};
export { change, current };
export default i18n;