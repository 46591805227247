import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SurveySelect',
  props: {
    list: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    selectedSurveyId: {
      type: Number,
      required: true,
      validator: function validator(item) {
        return item > 0;
      }
    }
  },
  computed: {
    selectedSurvey: function selectedSurvey() {
      var _this = this;
      return this.list.find(function (item) {
        return item.surveyId === _this.selectedSurveyId;
      });
    }
  }
};