import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalIconButton from '@/components/shared/ModalIconButton';
import Alert from '@/components/shared/Alert';
import { TabletMigration } from '@/services/ResourceService';
export default {
  name: 'StartMeasuringModal',
  components: {
    Alert: Alert,
    ModalIconButton: ModalIconButton
  },
  data: function data() {
    return {
      isLoading: false,
      adapted: false,
      success: false,
      errorOccurred: false,
      noLocationsAvailable: false,
      locations: [],
      locationGroups: [],
      selectedLocation: null
    };
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.locationGroups = [];
    this.locations = [];
    this.data.locations.forEach(function (location) {
      _this.locations.push({
        value: {
          locationId: location.id,
          locationName: location.name
        },
        text: location.name,
        locationGroupId: location.locationGroup.id
      });
      if (!_this.locationGroups.filter(function (item) {
        return item.locationGroupId === location.locationGroup.id;
      }).length) {
        _this.locationGroups.push({
          locationGroupId: location.locationGroup.id,
          locationGroupName: location.locationGroup.name
        });
      }
    });
    this.locations.sort(function (a, b) {
      if (a.value && a.value.locationName && b.value && b.value.locationName) {
        return a.value.locationName.localeCompare(b.value.locationName);
      } else {
        return JSON.stringify(a).localeCompare(JSON.stringify(b));
      }
    });
    this.locationGroups.sort(function (a, b) {
      if (a.locationGroupName && b.locationGroupName) {
        return a.locationGroupName.localeCompare(b.locationGroupName);
      } else {
        return JSON.stringify(a).localeCompare(JSON.stringify(b));
      }
    });
    if (this.locations.length === 0) {
      this.noLocationsAvailable = true;
    }
  },
  computed: {
    locationsGroupedByLocationGroup: function locationsGroupedByLocationGroup() {
      var _grouped = {};
      this.locationGroups.forEach(function (lg) {
        if (!_grouped[lg.locationGroupId]) {
          _grouped[lg.locationGroupId] = [];
        }
      });
      this.locations.forEach(function (l) {
        _grouped[l.locationGroupId].push(l);
      });
      return _grouped;
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.$vuedals.close({
        adapted: this.adapted
      });
    },
    startMeasurement: function startMeasurement() {
      var _this2 = this;
      this.isLoading = true;
      TabletMigration.startMeasurement(this.$store.state.general.companyId, this.data.tabletSourceId, this.selectedLocation.locationId).then(function () {
        _this2.isLoading = false;
        _this2.adapted = true;
        _this2.success = true;
      }, function () {
        _this2.isLoading = false;
        _this2.errorOccurred = true;
      });
    }
  }
};