//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import Multiselect from 'vue-multiselect/src/Multiselect';
export default {
  name: 'FormMultiSelect',
  components: {
    Multiselect: Multiselect
  },
  data: function data() {
    return {
      internalValue: []
    };
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: true
    }
  },
  created: function created() {
    this.internalValue = _.cloneDeep(this.value);
  },
  watch: {
    value: {
      deep: true,
      handler: function handler() {
        this.internalValue = _.cloneDeep(this.value);
      }
    }
  },
  computed: {
    itemCount: function itemCount() {
      var andXMore = this.$t('andXMore', {
        count: this.internalValue.length - 1
      });
      return "(".concat(andXMore, ")");
    }
  },
  methods: {
    onInputChanged: function onInputChanged() {
      this.$emit('input', _.cloneDeep(this.internalValue));
    }
  }
};