var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "ref-preview-wrapper", staticClass: "preview-wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "preview-content float-right",
          style: _vm.previewContentStyle,
        },
        [
          _c("survey-management-question-preview", {
            ref: "ref-question-preview",
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }