//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SurveyManagementNewQuestionButton',
  props: {
    questionNr: {
      type: Number,
      required: true
    },
    siblingQuestionIsHovered: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    clicked: function clicked() {
      return this.$store.dispatch('surveyManagement/editPageStateAddCreateQuestion', {
        questionNr: this.questionNr
      });
    }
  }
};