// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/emptystate.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-6713c06e]:export {\n  green: #67c892;\n  green-light: #9ec867;\n  yellow: #edc84c;\n  orange: #e9ad6a;\n  red: #e96a6a;\n  secondaryColor: #30415d;\n  primaryColor: #5dbfe2;\n  primaryColorDark: #4886a5;\n  gray: #a1a1a1;\n}\n.empty-state[data-v-6713c06e] {\n  min-height: 250px;\n  width: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n  padding-top: 200px;\n  text-align: center;\n  color: rgba(0, 0, 0, 0.7);\n  font-style: italic;\n  font-size: 120%;\n}\n", ""]);
// Exports
module.exports = exports;
