var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internalEditQuestion !== null &&
    _vm.internalCurrentLanguage !== null
    ? _c(
        "div",
        { staticClass: "survey-management-edit-date-question" },
        [
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend",
              attrs: { label: _vm.$t("label.min") },
            },
            [
              _c("date-input", {
                attrs: {
                  "max-date": _vm.internalEditQuestion.max,
                  optional: true,
                  disabled: false,
                  placeholder: _vm.$t("placeholder.min"),
                  invalid:
                    _vm.editPageState.data.showValidation &&
                    !_vm.validation.min.valid,
                },
                model: {
                  value: _vm.internalEditQuestion.min,
                  callback: function ($$v) {
                    _vm.$set(_vm.internalEditQuestion, "min", $$v)
                  },
                  expression: "internalEditQuestion.min",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend",
              attrs: { label: _vm.$t("label.max") },
            },
            [
              _c("date-input", {
                attrs: {
                  "min-date": _vm.internalEditQuestion.min,
                  optional: true,
                  disabled: false,
                  placeholder: _vm.$t("placeholder.max"),
                  invalid:
                    _vm.editPageState.data.showValidation &&
                    !_vm.validation.max.valid,
                },
                model: {
                  value: _vm.internalEditQuestion.max,
                  callback: function ($$v) {
                    _vm.$set(_vm.internalEditQuestion, "max", $$v)
                  },
                  expression: "internalEditQuestion.max",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }