var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "panel mb-2", class: _vm.panelClass, style: _vm.panelStyle },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isLoading
          ? _c("div", { key: "loading", staticClass: "loading-placeholder" }, [
              _c("h2", { class: _vm.loadingTitleClass }),
            ])
          : _c(
              "div",
              { key: "loaded" },
              [
                _c("h2", { staticClass: "title" }, [
                  _vm.panelTitle
                    ? _c("span", [_vm._v(_vm._s(_vm.panelTitle))])
                    : _c("span", [_vm._t("title")], 2),
                ]),
                _vm._v(" "),
                _vm._t("filter"),
                _vm._v(" "),
                _vm._t("dropdown"),
              ],
              2
            ),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm._t("content"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }