import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import Http from '@/config/Http';
import Store from '@/store/Store';
import Router from '@/config/Router';
import Principal from '@/services/auth/Principal';
var Auth = {
  login: function login(credentials) {
    var config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    var data = 'username=' + encodeURIComponent(credentials.username) + '&' + 'password=' + encodeURIComponent(credentials.password) + '&' + 'remember-me=' + credentials.rememberMe + '&' + 'submit=Login';
    return Http.post('/authentication', data, config).then(loginThen, function (error) {
      var status = error.response && error.response.status && parseInt(error.response.status, 10) ? parseInt(error.response.status, 10) : 0;

      // probably csrf token not fresh anymore
      if (status === 403) {
        return new Promise(function (resolve, reject) {
          var retryLogin = function retryLogin() {
            Http.post('/authentication', data, config).then(function (newResponse) {
              loginThen(resolve).then(function () {
                resolve(newResponse);
              });
            }, function (newError) {
              var getAccount = function getAccount() {
                Http.get('/account').then(function () {
                  reject(newError);
                }, function () {
                  reject(newError);
                });
              };
              Http.post('/logout').then(getAccount, getAccount);
            });
          };
          Http.get('/account').then(retryLogin, retryLogin);
        });
      } else {
        return new Promise(function (resolve, reject) {
          var getAccount = function getAccount() {
            Http.get('/account').then(function () {
              reject(error);
            }, function () {
              reject(error);
            });
          };
          Http.post('/logout').then(getAccount, getAccount);
        });
      }
    });
    function loginThen(data) {
      return Principal.identity(this).then(function () {
        return Promise.resolve(data);
      });
    }
  },
  logout: function logout() {
    Store.commit('general/authorizeUser', null);
    Store.commit('general/setComparison', null);
    var redirect = function redirect() {
      Router.push({
        name: 'login'
      });
    };
    return Http.post('/logout').then(redirect, function () {
      return new Promise(function (resolve) {
        var logoutAgainAndRedirect = function logoutAgainAndRedirect() {
          Http.post('/logout').then(redirect, redirect);
          resolve();
        };
        // retrieve a new CSRF token and then logout again
        Http.get('/account').then(logoutAgainAndRedirect, logoutAgainAndRedirect);
      });
    });
  },
  passwordRecoveryInit: function passwordRecoveryInit(emailAddress) {
    return Http.post('/account/reset-password/init', emailAddress, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      params: {
        'new-dashboard': true
      }
    });
  },
  passwordRecoveryFinish: function passwordRecoveryFinish(dto) {
    return Http.post('/account/reset-password/finish', dto, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      params: {
        'new-dashboard': true
      }
    });
  }
};
export default Auth;