var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    { attrs: { "panel-title": _vm.$t("panelTitle"), "is-loading": false } },
    [
      !_vm.isLoading && !_vm.errorOccurred
        ? _c(
            "div",
            {
              staticClass: "search float-right",
              attrs: { slot: "filter" },
              slot: "filter",
            },
            [
              _c("b-form-input", {
                staticClass: "search-box",
                attrs: {
                  placeholder: _vm.$t("searchPlaceholder"),
                  type: "search",
                },
                model: {
                  value: _vm.filter,
                  callback: function ($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter",
                },
              }),
              _vm._v(" "),
              !_vm.filter
                ? _c("font-awesome", {
                    staticClass: "search-icon",
                    attrs: { icon: ["fas", "search"] },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("serverError.title"),
              message: _vm.$t("serverError.message"),
              show: _vm.errorOccurred,
            },
          }),
          _vm._v(" "),
          _vm.isLoading
            ? _c("div", { staticClass: "spinner-wrapper" }, [
                _c("div", { staticClass: "opn-spinner" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading && !_vm.errorOccurred
            ? _c(
                "div",
                [
                  _c("filter-management-table", {
                    attrs: { filters: _vm.filters, filter: _vm.filter },
                    on: { "delete-filter": _vm.requestDeleteFilter },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }