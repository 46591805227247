var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("print-container", [
        _vm.hasError || _vm.hasParamsError
          ? _c(
              "div",
              {
                staticClass:
                  "alert alert-danger error error-selector-for-node-server",
              },
              [
                _vm.hasError
                  ? _c("span", [_vm._v("The report data could not be loaded.")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasParamsError
                  ? _c("span", [
                      _vm._v(
                        "You did not pass all necessary parameters to the URL. The following are\n      missing or invalid: " +
                          _vm._s(_vm.missingQueryParams.join(", "))
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _c(
              "div",
              [
                _c("print-basic-information", {
                  attrs: {
                    "locations-with-actual-data": _vm.locationsWithActualData,
                    "generation-date": new Date(),
                    "request-date-start": _vm.queryParams.start,
                    "request-date-end": _vm.queryParams.end,
                  },
                }),
                _vm._v(" "),
                _vm.queryParams.components.includes("QUICK_STATISTICS")
                  ? _c("print-quick-statistics", {
                      attrs: { data: _vm.quickStatistics },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.queryParams.components.includes("CHANGE_OVER_TIME")
                  ? _c("print-change-over-time", {
                      attrs: { data: _vm.changeOverTime },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.queryParams.components.includes("LOCATION_PERFORMANCE")
                  ? _c("print-location-performance", {
                      attrs: { data: _vm.performance },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.queryParams.components.includes("MATRIX")
                  ? _c("print-matrix", { attrs: { data: _vm.matrix } })
                  : _vm._e(),
                _vm._v(" "),
                _vm.queryParams.components.includes("SURVEY_ANALYSIS")
                  ? _c("print-survey-analysis", {
                      attrs: {
                        "survey-analysis-data": _vm.surveyAnalysis,
                        "custom-answers": _vm.customAnswers,
                        "text-answers": _vm.textAnswers,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.queryParams.components.includes("COMMENTS")
                  ? _c("print-comments", { attrs: { data: _vm.comments } })
                  : _vm._e(),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }