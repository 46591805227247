//
//
//
//
//
//
//
//

import SurveyManagementQuestionPreview from '@/components/survey-management/edit-question/question-preview/SurveyManagementQuestionPreview';
var MAX_WIDTH = 1024;
var MAX_HEIGHT = 768;
export default {
  name: 'SurveyManagementPreviewWrapper',
  components: {
    SurveyManagementQuestionPreview: SurveyManagementQuestionPreview
  },
  data: function data() {
    return {
      previewContentHeight: 0,
      previewContentWidth: 0,
      ratio: 0
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      window.addEventListener('resize', _this.adaptToParentSize);
      _this.adaptToParentSize();
    });
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.adaptToParentSize);
  },
  computed: {
    previewContentStyle: function previewContentStyle() {
      return {
        transform: "scale(".concat(this.ratio, ")"),
        height: this.previewContentHeight + 'px'
      };
    }
  },
  methods: {
    adaptToParentSize: function adaptToParentSize() {
      var previewWrapperWidth = this.$refs['ref-preview-wrapper'].clientWidth;
      if (this.previewContentWidth !== previewWrapperWidth) {
        this.ratio = previewWrapperWidth / MAX_WIDTH;
        this.previewContentWidth = previewWrapperWidth;
        this.previewContentHeight = MAX_HEIGHT * this.ratio;
      }
    }
  }
};