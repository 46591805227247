var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box-shadow-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "question-wrapper print-bordered-box p-0",
        class: [
          _vm.question.questionType,
          {
            "no-answers": _vm.noAnswersInQuestion,
          },
          _vm.question.questionType === "RATING_QUESTION" &&
          _vm.hasIndexCalculation
            ? [
                "colored-right",
                _vm.getColorNameByIndex(
                  _vm.question.averageIndex,
                  _vm.$store.state.general.companyProperties
                ),
              ]
            : null,
        ],
      },
      [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "question-info" }, [
            _c("div", { staticClass: "question-info-header" }, [
              _c("div", { staticClass: "question-type" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("questionLong") + " " + _vm.counter) +
                    " —\n          "
                ),
              ]),
              _vm._v(" "),
              _vm.question.category
                ? _c("span", {
                    staticClass: "badge badge-pill badge-category",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.languageAwareString(
                          _vm.question.category.name
                        )
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "question-type" }, [
                _vm.question.questionType === "RATING_QUESTION"
                  ? _c("span", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("scale", { scale: _vm.question.scale })
                          ) +
                          "\n            "
                      ),
                    ])
                  : _vm.question.questionType === "CHOICE_QUESTION"
                  ? _c("span", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("choiceType." + _vm.question.choiceType)
                          ) +
                          "\n            "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("questionType." + _vm.question.questionType)
                          ) +
                          "\n            "
                      ),
                    ]),
              ]),
            ]),
            _vm._v(" "),
            _c("h2", {
              staticClass: "question-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$options.filters.languageAwareString(_vm.question.text)
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "calculated-data" }, [
            _vm.question.questionType === "RATING_QUESTION" &&
            !_vm.$store.getters["general/hasIndexCalculation"]
              ? _c("span", { staticClass: "data-value" }, [
                  _c("span", { staticClass: "data-value-content" }, [
                    _c("span", { staticClass: "icon unicode" }, [_vm._v("ø")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        _vm._s(
                          _vm.question.averageRating
                            ? _vm.$options.filters.number(
                                _vm.question.averageRating,
                                2
                              )
                            : 0.0
                        ) + "\n              /"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.question.scale)),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.question.questionType === "RATING_QUESTION" &&
            _vm.$store.getters["general/hasIndexCalculation"]
              ? _c(
                  "span",
                  {
                    staticClass: "data-value average-index",
                    class: _vm.getColorNameByIndex(
                      _vm.question.averageIndex,
                      _vm.$store.state.general.companyProperties
                    ),
                  },
                  [
                    _c("span", { staticClass: "data-value-content" }, [
                      _c(
                        "span",
                        {
                          staticClass: "icon",
                          class: { "no-answers": _vm.noAnswersInQuestion },
                        },
                        [
                          _c("font-awesome", {
                            staticClass: "font-awesome",
                            attrs: { icon: ["far", "smile"] },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.question.averageIndex !== null
                        ? _c("span", { staticClass: "number" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("number")(_vm.question.averageIndex, 1)
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.question.questionType === "RATING_QUESTION"
              ? _c("span", { staticClass: "data-value" }, [
                  _c("span", { staticClass: "data-value-content" }, [
                    _c(
                      "span",
                      { staticClass: "icon" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["fas", "tasks"] },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("groupedNumber")(
                            _vm.question.ratingReasonAnswerCounter
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.question.questionType === "CHOICE_QUESTION"
              ? _c("span", { staticClass: "data-value" }, [
                  _c("span", { staticClass: "data-value-content" }, [
                    _c(
                      "span",
                      { staticClass: "icon" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["fas", "tasks"] },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("groupedNumber")(
                            _vm.question.choiceAttributeAnswerCounter
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "data-value" }, [
              _c("span", { staticClass: "data-value-content" }, [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["fas", "user"] },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "number" }, [
                  _vm._v(
                    _vm._s(_vm._f("groupedNumber")(_vm.question.answerCounter))
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.question.questionType === "EMAIL_QUESTION" &&
            _vm.question.contactRequestCounter > 0
              ? _c("span", { staticClass: "data-value" }, [
                  _c("span", { staticClass: "data-value-content" }, [
                    _c(
                      "span",
                      { staticClass: "icon" },
                      [_c("contact-request-icon")],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("groupedNumber")(
                            _vm.question.contactRequestCounter
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.hasExpandedSection && !_vm.noAnswersInQuestion
          ? _c("div", { staticClass: "question-details" }, [
              _vm.question.questionType === "RATING_QUESTION"
                ? _c(
                    "div",
                    {
                      staticClass: "distribution-chart",
                      class: "scale-" + _vm.question.scale,
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm.question.questionType === "RATING_QUESTION" &&
                        _vm.question.scale <= 5
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("title.smileyDistribution")) +
                                  "\n          "
                              ),
                            ])
                          : _vm.question.questionType === "RATING_QUESTION" &&
                            _vm.question.scale > 5
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("title.answerDistribution")) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("print-rating-distribution-chart", {
                        staticClass: "chart",
                        attrs: {
                          data: {
                            scale: _vm.question.scale,
                            distribution: _vm.question.ratingDistribution,
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              ["NUMBER_QUESTION", "DATE_QUESTION"].includes(
                _vm.question.questionType
              )
                ? _c(
                    "div",
                    { staticClass: "scatter-chart" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("title.answerDistribution")) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("scatter-distribution-chart", {
                        staticClass: "chart",
                        attrs: {
                          type:
                            _vm.question.questionType === "DATE_QUESTION"
                              ? "date"
                              : "number",
                          distribution: _vm.question.distribution,
                          "is-pdf": "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              ["RATING_QUESTION", "CHOICE_QUESTION"].includes(
                _vm.question.questionType
              )
                ? _c(
                    "div",
                    {
                      staticClass: "attribute-chart",
                      class:
                        _vm.question.questionType === "RATING_QUESTION"
                          ? "rating-reason"
                          : "choice-attribute",
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm.question.questionType === "RATING_QUESTION"
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("title.reasons")) +
                                  "\n          "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("title.answerDistribution")) +
                                  "\n          "
                              ),
                            ]),
                      ]),
                      _vm._v(" "),
                      (_vm.question.questionType === "RATING_QUESTION" &&
                        _vm.question.ratingReasons.length > 0) ||
                      _vm.question.questionType === "CHOICE_QUESTION"
                        ? _c("print-attribute-distribution-chart", {
                            attrs: {
                              question: _vm.question,
                              "survey-id": _vm.surveyId,
                              type:
                                _vm.question.questionType === "RATING_QUESTION"
                                  ? "ratingReason"
                                  : "choiceAttribute",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.question.questionType === "RATING_QUESTION" &&
                      _vm.question.ratingReasons.length === 0
                        ? _c("div", { staticClass: "no-rating-reasons" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("noRatingReasons")) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.customAttributes !== null && _vm.customAttributes.length > 0
          ? _c("div", { staticClass: "custom-answers-wrapper" }, [
              _c("div", { staticClass: "title" }, [
                _vm.question.questionType === "RATING_QUESTION"
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("title.customReasons")) +
                          "\n        "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("title.customAnswers")) +
                          "\n        "
                      ),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "custom-answers" },
                _vm._l(_vm.customAttributes, function (customAttribute) {
                  return _c(
                    "div",
                    {
                      key: customAttribute.customAttributeId,
                      staticClass: "custom-attribute",
                    },
                    [
                      _c(
                        "h2",
                        { staticClass: "custom-attribute-text" },
                        [
                          _c("font-awesome", {
                            staticClass: "font-awesome icon-pencil",
                            attrs: { icon: ["fas", "comment-edit"] },
                          }),
                          _vm._v(" "),
                          _c("b", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.languageAwareString(
                                  customAttribute.attributeText
                                )
                              ),
                            },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                customAttribute.emptyAnswerCount > 0
                                  ? _vm.$t("emptyAnswerCount", {
                                      empty: customAttribute.emptyAnswerCount,
                                    })
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(
                        customAttribute.customAnswers,
                        function (customAnswer) {
                          return _c(
                            "div",
                            {
                              key: customAnswer.customAnswerId,
                              staticClass: "custom-answer",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(customAnswer.answer) +
                                  "\n            "
                              ),
                              _c(
                                "span",
                                { staticClass: "custom-answer-date" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.formatDateTime(
                                          customAnswer.timestamp
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }