import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import Panel from '@/components/shared/Panel';
import { TabletMigration } from '@/services/ResourceService';
import StopMeasuringModal from '@/components/tablet-migration/StopMeasuringModal';
import { Bus as VuedalsBus } from 'vuedals';
import moment from 'moment';
export default {
  name: 'TabletMigrationRunning',
  components: {
    Panel: Panel,
    Alert: Alert
  },
  data: function data() {
    return {
      isLoading: true,
      fatalErrorOccurred: false,
      items: []
    };
  },
  props: {
    updateData: {
      type: Number,
      required: true
    }
  },
  watch: {
    updateData: function updateData() {
      this.loadData();
    }
  },
  computed: {
    fields: function fields() {
      // fields in computed allows translation to adapt
      return [{
        key: 'number',
        label: this.$t('fields.number'),
        sortable: true
      }, {
        key: 'locationName',
        label: this.$t('fields.locationName'),
        sortable: true
      }, {
        key: 'locationGroup',
        label: this.$t('fields.locationGroup'),
        sortable: true
      }, {
        key: 'lastActiveTimestamp',
        label: this.$t('fields.lastActiveTimestamp'),
        sortable: true
      }, {
        key: 'type',
        label: this.$t('fields.type'),
        sortable: true
      }, {
        key: 'finish',
        label: ' '
      }];
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      TabletMigration.getRunningTablets(this.$store.state.general.companyId).then(function (response) {
        _this.items = [];
        response.data.forEach(function (item) {
          if (item.locations && item.locations.length === 1) {
            item.multiLocation = false;
            item.locationName = item.locations[0].name;
            item.locationGroup = item.locations[0].locationGroup.name;
          } else {
            item.locationNames = item.locations.map(function (location) {
              return location.name;
            }).join(', ');
            item.multiLocation = true;
          }
          item.locationCount = item.locations ? item.locations.length : 0;
          _this.items.push(item);
        });
        _this.isLoading = false;
      }, function () {
        _this.fatalErrorOccurred = true;
        _this.isLoading = false;
      });
    },
    showModal: function showModal(data) {
      var _this2 = this;
      VuedalsBus.$emit('new', {
        title: this.$t('modalTitle', {
          number: data.number
        }),
        name: 'stop-measuring-modal',
        closeOnBackdrop: false,
        props: {
          data: data
        },
        component: StopMeasuringModal,
        onClose: function onClose(response) {
          if (response.adapted) {
            _this2.$emit('data-updated');
          }
        }
      });
    },
    formatDateTime: function formatDateTime(date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY HH:mm');
      }
    }
  }
};