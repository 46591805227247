import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AttributeDistributionChart from '@/components/survey-analysis/charts/AttributeDistributionChart';
import RatingDistributionChart from '@/components/survey-analysis/charts/RatingDistributionChart';
import ScatterDistributionChart from '@/components/survey-analysis/charts/ScatterDistributionChart';
import GenericChartWithTitle from '@/components/survey-analysis/charts/GenericChartWithTitle';
import TextAnswers from '@/components/survey-analysis/TextAnswers';
export default {
  name: 'QuestionExpandedSection',
  components: {
    TextAnswers: TextAnswers,
    GenericChartWithTitle: GenericChartWithTitle,
    AttributeDistributionChart: AttributeDistributionChart,
    RatingDistributionChart: RatingDistributionChart,
    ScatterDistributionChart: ScatterDistributionChart
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    counter: {
      type: Number,
      required: true,
      validator: function validator(item) {
        return item > 0;
      }
    },
    isNarrow: {
      type: Boolean,
      default: false
    },
    surveyId: {
      type: Number,
      required: true
    },
    queryParameters: {
      type: Object,
      required: true
    },
    renderChart: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    hasRatingDistributionChart: function hasRatingDistributionChart() {
      return this.question.questionType === 'RATING_QUESTION';
    },
    hasScatterChart: function hasScatterChart() {
      return ['NUMBER_QUESTION', 'DATE_QUESTION'].includes(this.question.questionType);
    },
    hasAttributeDistributionChart: function hasAttributeDistributionChart() {
      return ['RATING_QUESTION', 'CHOICE_QUESTION'].includes(this.question.questionType);
    },
    hasTextAnswers: function hasTextAnswers() {
      return ['COMMENT_QUESTION', 'TEXT_QUESTION'].includes(this.question.questionType);
    },
    attributeDistributionChartTitle: function attributeDistributionChartTitle() {
      return this.question.questionType === 'RATING_QUESTION' ? this.$t('title.reasons') : this.$t('title.answerDistribution');
    },
    attributeDistributionChartHasNoData: function attributeDistributionChartHasNoData() {
      return this.question.questionType === 'RATING_QUESTION' && this.question.ratingReasons.length === 0;
    }
  }
};