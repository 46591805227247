import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DashedBox from '@/components/comparison/DashedBox';
import FormBox from '@/components/comparison/FormBox';
import SurveyBox from '@/components/comparison/SurveyBox';
import SelectionBox from '@/components/comparison/SelectionBox';
import SimpleQuickStatistics from '@/components/comparison/SimpleQuickStatistics';
import moment from 'moment';
import { Comparison } from '@/services/ResourceService';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import ChangeOverTimeBox from '@/components/comparison/ChangeOverTimeBox';
export default {
  name: 'OneSideComparison',
  components: {
    DashedBox: DashedBox,
    FormBox: FormBox,
    SurveyBox: SurveyBox,
    SelectionBox: SelectionBox,
    SimpleQuickStatistics: SimpleQuickStatistics,
    ChangeOverTimeBox: ChangeOverTimeBox
  },
  props: {
    parentConfig: {
      required: true,
      type: Object
    }
  },
  data: function data() {
    return {
      isValid: false,
      editMode: false,
      config: {}
    };
  },
  created: function created() {
    this.initialize();
    this.validate();
  },
  methods: {
    initialize: function initialize() {
      this.config = Object.assign({}, this.parentConfig);
    },
    validate: function validate() {
      var _this = this;
      /* This validity checks verifies that there is data available for the selection.
        If there is no data, it won't load the survey, etc. A scenario in which this
        could occur is, marking all feedbacks of a selection as spam. */
      this.isValid = false;
      if (!this.config.dirty) {
        Promise.all([Comparison.checkValidityOfSelection(this.$store.state.general.companyId, this.config.locations.queryLocations, this.config.surveyId, this.config.choiceAttributeIds, moment(this.config.startInSeconds).format('DD.MM.YYYY'), moment(this.config.endInSeconds).format('DD.MM.YYYY')), CompanyPropertiesService.update()]).then(function () {
          _this.isValid = true;
        }, function () {
          _this.isValid = false;
          _this.$toasted.global.info(_this.$t('corruptSelection'));
          _this.$emit('clear-config');
        });
      } else {
        this.isValid = false;
      }
    },
    updateForm: function updateForm(form) {
      this.config = form;
      this.$emit('change-config', this.config);
    }
  },
  watch: {
    parentConfig: {
      deep: true,
      handler: function handler() {
        this.initialize();
        this.validate();
      }
    },
    editMode: function editMode(newValue) {
      // reset form when reopening
      if (newValue) {
        this.initialize();
      }
    }
  }
};