//
//
//
//
//
//
//
//
//
//
//
//

import _opiniooonLogo from '@/assets/opiniooon-logo-dark.svg';
export default {
  name: 'PrintContainer',
  mounted: function mounted() {
    document.body.classList.add('white-background');
  },
  beforeDestroy: function beforeDestroy() {
    document.body.classList.remove('white-background');
  },
  computed: {
    opiniooonLogo: function opiniooonLogo() {
      return _opiniooonLogo;
    }
  }
};