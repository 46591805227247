var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "print-basic-information print-section" },
    [
      _c("div", { staticClass: "print-h1" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("mainTitle")) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "print-box" }, [
        _c("div", { staticClass: "row mb-2" }, [
          _c("div", { staticClass: "col-2 print-col-xs" }, [
            _vm._v("\n        " + _vm._s(_vm.$t("creationDate")) + "\n      "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-4 print-col-xs print-highlighted-blue" },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.generationDateFormatted) + "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-2 print-col-xs" }, [
            _vm._v("\n        " + _vm._s(_vm.$t("createdBy")) + "\n      "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-4 print-col-xs print-highlighted-blue" },
            [_vm._v("\n        " + _vm._s(_vm.currentUser) + "\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-2" }, [
            _vm._v("\n        " + _vm._s(_vm.$t("timePeriod")) + "\n      "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4 print-highlighted-blue" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.requestDateStart) +
                " – " +
                _vm._s(_vm.requestDateEnd) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("numberOfLocations")) + "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-4 print-highlighted-blue" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.locationsWithActualData.length) +
                "\n      "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.$store.getters["general/hasIndexCalculation"]
        ? [
            _c("print-info-box", {
              attrs: { text: _vm.$t("info.colorLegend") },
            }),
            _vm._v(" "),
            _c("color-legend", {
              staticClass: "color-legend",
              attrs: {
                "is-loading": false,
                "company-properties":
                  _vm.$store.state.general.companyProperties,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("print-info-box", { attrs: { text: _vm.$t("info.locations") } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "print-box" },
        _vm._l(_vm.groupedData, function (locations, lgId) {
          return _c(
            "div",
            { key: lgId, staticClass: "row mb-2 location-row" },
            [
              _c("div", { staticClass: "col-3 location-group" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(locations[0].locationGroupName) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-9 locations" },
                _vm._l(locations, function (location) {
                  return _c(
                    "div",
                    { key: location.locationId, staticClass: "location" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(location.locationName) +
                          "\n        "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }