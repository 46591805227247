//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TabletMigrationRunning from '@/components/tablet-migration/TabletMigrationRunning';
import TabletMigrationPool from '@/components/tablet-migration/TabletMigrationPool';
export default {
  name: 'TabletMigration',
  data: function data() {
    return {
      updateData: 0
    };
  },
  components: {
    TabletMigrationRunning: TabletMigrationRunning,
    TabletMigrationPool: TabletMigrationPool
  }
};