var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("h1", { staticClass: "site-title" }, [
          _vm._v(_vm._s(_vm.$t("siteTitle"))),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [_c("index-threshold-settings")],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12 mt-1" },
        [_c("company-statistics")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }