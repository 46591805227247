//
//
//
//
//
//
//
//
//
//
//

import FilterCriteria from '@/components/comparison/filter-criteria/FilterCriteria';
export default {
  name: 'FilterCriteriaSurvey',
  components: {
    FilterCriteria: FilterCriteria
  },
  props: {
    surveyName: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  }
};