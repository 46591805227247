//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChangeOverTime from '@/components/shared/change-over-time/ChangeOverTime';
import ChangeOverTimeLoading from '@/components/shared/change-over-time/ChangeOverTimeLoading';
import { Comparison } from '@/services/ResourceService';
import moment from 'moment';
export default {
  components: {
    ChangeOverTime: ChangeOverTime,
    ChangeOverTimeLoading: ChangeOverTimeLoading
  },
  props: {
    parentConfig: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      isLoading: true,
      data: {}
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      if (!this.parentConfig.dirty) {
        Comparison.getChangeOverTime(this.$store.state.general.companyId, this.parentConfig.locations.queryLocations, this.parentConfig.surveyId, this.parentConfig.choiceAttributeIds, this.startDate, this.endDate).then(function (response) {
          _this.data = response.data;
          _this.isLoading = false;
        }, function () {
          _this.data = {};
          _this.isLoading = false;
        });
      }
    }
  },
  computed: {
    queryParameters: function queryParameters() {
      return {
        companyId: this.$store.state.general.companyId,
        locationIds: this.parentConfig.locations.queryLocations,
        start: this.startDate,
        end: this.endDate,
        choiceAttributeIds: this.parentConfig.choiceAttributeIds
      };
    },
    startDate: function startDate() {
      return moment(this.parentConfig.startInSeconds).format('DD.MM.YYYY');
    },
    endDate: function endDate() {
      return moment(this.parentConfig.endInSeconds).format('DD.MM.YYYY');
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    }
  },
  watch: {
    parentConfig: {
      deep: true,
      handler: function handler() {
        this.loadData();
      }
    }
  }
};