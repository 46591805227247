var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "mb-1" },
    [
      _c("b-col", { attrs: { sm: "7" } }, [
        _c(
          "label",
          { staticClass: "form-label" },
          [_vm.title !== "" ? [_vm._v(_vm._s(_vm.title))] : _vm._t("title")],
          2
        ),
      ]),
      _vm._v(" "),
      _c("b-col", { attrs: { sm: "5" } }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }