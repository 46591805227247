import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//

import { getColorsAndIcons } from '@/services/ColorService';
import NoUiSlider from '@/config/NoUiSlider';
import colorVariables from '@/styles/_variables.scss';
export default {
  name: 'SliderInput',
  props: {
    value: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    isRatingReasonThreshold: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      slider: null
    };
  },
  mounted: function mounted() {
    this.createSlider();
  },
  computed: {
    backgroundStyle: function backgroundStyle() {
      if (this.isRatingReasonThreshold) {
        if (this.max > 10 || this.max < 3) {
          throw new Error('RatingReasonThreshold cannot have a max > 10 or < 3');
        }
        if (this.value < 0 || this.value > this.max) {
          throw new Error('RatingReasonThreshold cannot have a value which is < 0 or > max');
        }
        var scale = this.max;
        var colors = getColorsAndIcons(scale);
        colors.unshift({
          color: 'gray'
        });
        var groupedColors = [];
        colors.forEach(function (item) {
          if (groupedColors.find(function (groupedColor) {
            return groupedColor.color === item.color;
          }) === undefined) {
            groupedColors.push({
              color: item.color,
              colorCode: colorVariables[item.color],
              amount: 0
            });
          }
          groupedColors.find(function (groupedColor) {
            return groupedColor.color === item.color;
          }).amount++;
        });
        groupedColors.forEach(function (groupedColor) {
          groupedColor.ratio = groupedColor.amount / (scale + 1) * 100;
        });

        // the background is calculated by defining all available color sectors next to each other in a linear gradient
        // an example for the specification: orange from 20% to 40% is specified by defining red at 20%, then orange
        // at 20%, then orange at 40%, then yellow at 40%...
        var background = 'linear-gradient(to right, ';
        var previousRatio = 0;
        for (var i = 0; i < groupedColors.length; i++) {
          var groupedColor = groupedColors[i];
          var last = i === groupedColors.length - 1;

          // the following line can be skipped to achieve a non-stepped gradient (regular gradient)
          background += "".concat(groupedColor.colorCode, " ").concat(previousRatio, "%, ");
          background += "".concat(groupedColor.colorCode, " ").concat(groupedColor.ratio + previousRatio, "%").concat(last ? '' : ', ');
          previousRatio += groupedColor.ratio;
        }
        background += ')';
        return {
          'background-image': background,
          transform: "scaleX(".concat(scale / (scale - 1), ")")
        };
      }
      return {};
    }
  },
  methods: {
    createSlider: function createSlider() {
      var _this = this;
      this.slider = NoUiSlider.create(this.$refs.slider, {
        start: [this.value],
        orientation: 'horizontal',
        tooltips: {
          to: function to(value) {
            if (_this.isRatingReasonThreshold) {
              if (value === 0) {
                return _this.$t('neverShown');
              } else if (value === _this.max) {
                return _this.$t('alwaysShown');
              }
            }
            return Math.round(value) + '';
          }
        },
        behaviour: 'tap-drag',
        step: 1,
        connect: [false, true],
        format: {
          to: function to(value) {
            return Math.round(value) + '';
          },
          from: function from(value) {
            return window.parseInt(value, 10);
          }
        },
        range: {
          min: [this.min, 1],
          // from 1 always with 1 as a step size higher
          max: [this.max]
        }
      });
      this.slider.on('set', function (values) {
        if (!_this.setValueWithoutEmitting) {
          _this.$emit('input', window.parseInt(values[0], 10));
        }
      });
    },
    destroySlider: function destroySlider() {
      if (this.slider !== null) {
        this.slider.destroy();
        this.slider = null;
      }
    },
    recreateSlider: function recreateSlider() {
      this.destroySlider();
      this.createSlider();
    }
  },
  watch: {
    value: function value() {
      var _this2 = this;
      if (this.slider !== null) {
        this.setValueWithoutEmitting = true;
        this.slider.set([this.value]);
        this.$nextTick(function () {
          _this2.setValueWithoutEmitting = false;
        });
      }
    },
    scale: function scale() {
      this.recreateSlider();
    },
    min: function min() {
      this.recreateSlider();
    },
    max: function max() {
      this.recreateSlider();
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.destroySlider();
  }
};