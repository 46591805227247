import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import Store from '@/store/Store';
import Http from '@/config/Http';
import { change as changeLanguage } from '@/config/Translation';
var Principal = {
  identity: function identity() {
    return new Promise(function (resolve) {
      if (Store.state.general.user !== null) {
        return resolve(Store.state.general.user);
      }
      Http.get('/account').then(getAccountThen).catch(getAccountCatch);
      function getAccountThen(account) {
        Store.commit('general/authorizeUser', account.data);
        changeLanguage(account.data.language.toLowerCase());
        return resolve(Store.state.general.user);
      }
      function getAccountCatch() {
        Store.commit('general/authorizeUser', null);
        changeLanguage(null);
        return resolve(Store.state.general.user);
      }
    });
  },
  isAuthenticated: function isAuthenticated() {
    return Store.state.general.isAuthenticated;
  },
  hasAuthority: function hasAuthority(authority) {
    var _this = this;
    return new Promise(function (resolve) {
      if (!_this.isAuthenticated()) {
        return resolve(false);
      }
      _this.identity().then(function (_identity) {
        resolve(_identity.authorities.includes(authority));
      }, function () {
        resolve(false);
      });
    });
  }
};
export default Principal;