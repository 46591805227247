var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internalEditQuestion !== null &&
    _vm.internalCurrentLanguage !== null
    ? _c("div", { staticClass: "survey-management-edit-email-question" }, [
        _c(
          "div",
          {
            staticClass: "separating-box",
            class: {
              disabled: !_vm.internalEditQuestion.contactRequestEnabled,
            },
          },
          [
            _c(
              "label",
              { staticClass: "separating-box-title" },
              [
                _c("b-form-checkbox", {
                  model: {
                    value: _vm.internalEditQuestion.contactRequestEnabled,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.internalEditQuestion,
                        "contactRequestEnabled",
                        $$v
                      )
                    },
                    expression: "internalEditQuestion.contactRequestEnabled",
                  },
                }),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("label.enableContactRequest")) +
                    "\n      "
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.d200",
                        modifiers: { hover: true, d200: true },
                      },
                    ],
                    attrs: { title: _vm.$t("tooltip.contactRequestInfo") },
                  },
                  [
                    _c("font-awesome", {
                      staticClass: "info-circle",
                      attrs: { icon: ["far", "info-circle"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "bold-uppercase-legend",
                attrs: { label: _vm.$t("label.contactRequestText") },
              },
              [
                _c("survey-management-las-wrapper", {
                  attrs: {
                    optional: !_vm.internalEditQuestion.contactRequestEnabled,
                    disabled: !_vm.internalEditQuestion.contactRequestEnabled,
                    las: _vm.internalEditQuestion.contactRequestText,
                    "enabled-languages":
                      _vm.$store.state.surveyManagement.fullSurvey.data
                        .enabledLanguages,
                    "current-language": _vm.internalCurrentLanguage,
                    invalid:
                      _vm.editPageState.data.showValidation &&
                      !_vm.validation.contactRequestText.valid,
                  },
                  on: {
                    change: function ($event) {
                      _vm.internalEditQuestion.contactRequestText = $event
                    },
                    "change-language": function ($event) {
                      _vm.internalCurrentLanguage = $event
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }