//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import ChangeOverTimeChartLoading from '@/components/shared/change-over-time/ChangeOverTimeChartLoading';
export default {
  name: 'ChangeOverTimeLoading',
  components: {
    Panel: Panel,
    ChangeOverTimeChartLoading: ChangeOverTimeChartLoading
  },
  props: {
    heightInPixel: {
      type: Number,
      required: false,
      default: 300
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};