import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import opnProgress from '@/components/shared/opnProgress';
import { getColorNameByIndex } from '@/services/ColorService';
import PrintInfoBox from '@/components/print/report/shared/PrintInfoBox';
export default {
  name: 'PrintLocationPerformance',
  components: {
    PrintInfoBox: PrintInfoBox,
    opnProgress: opnProgress
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    locationWithoutSatisfactionDataInfoText: function locationWithoutSatisfactionDataInfoText() {
      if (this.locationsWithoutSatisfactionDataCount === 1) {
        var tParams = [this.singleLocationWithoutSatisfactionDataFeedbackCount, this.singleLocationWithoutSatisfactionDataTotalCount];
        if (this.singleLocationWithoutSatisfactionDataFeedbackCount > 1) {
          return this.$t('infoBox.notAllFeedbacksHaveSatisfactionPlural', tParams);
        } else {
          return this.$t('infoBox.notAllFeedbacksHaveSatisfaction', tParams);
        }
      }
      return this.$t('infoBox.multipleLocationsNotAllFeedbacksHaveSatisfaction');
    },
    locationsWithoutSatisfactionDataCount: function locationsWithoutSatisfactionDataCount() {
      var count = 0;
      this.data.forEach(function (l) {
        if (l.countOfAllFeedbacksWithoutSatisfactionData > 0) {
          count++;
        }
      });
      return count;
    },
    singleLocationWithoutSatisfactionDataFeedbackCount: function singleLocationWithoutSatisfactionDataFeedbackCount() {
      var count = null;
      if (this.locationsWithoutSatisfactionDataCount === 1) {
        this.data.forEach(function (l) {
          if (l.countOfAllFeedbacksWithoutSatisfactionData > 0) {
            count = l.countOfAllFeedbacksWithoutSatisfactionData;
          }
        });
      }
      return count;
    },
    singleLocationWithoutSatisfactionDataTotalCount: function singleLocationWithoutSatisfactionDataTotalCount() {
      var count = null;
      if (this.locationsWithoutSatisfactionDataCount === 1) {
        this.data.forEach(function (l) {
          if (l.countOfAllFeedbacksWithoutSatisfactionData > 0) {
            count = l.totalCounter;
          }
        });
      }
      return count;
    }
  },
  methods: {
    getColorNameByIndex: getColorNameByIndex
  }
};