import _slicedToArray from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QueryUtils from '@/services/utils/QueryUtils';
import NoUiSlider from '@/config/NoUiSlider';
import colorVariables from '@/styles/_variables.scss';
export default {
  name: 'SatisfactionInputRange',
  props: {
    value: {
      type: Object,
      required: true,
      validator: function validator(object) {
        return !(object.filterByIndex !== true && object.filterByIndex !== false || isNaN(object.minimalIndex) || object.minimalIndex < 0 || isNaN(object.maximalIndex) || object.maximalIndex > 100 || object.minimalIndex > object.maximalIndex);
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      slider: null,
      internalFilterByIndex: false,
      setValueWithoutEmitting: false
    };
  },
  computed: {
    companyProperties: function companyProperties() {
      return this.$store.state.general.companyProperties;
    },
    scaledIndicesInPercent: function scaledIndicesInPercent() {
      /*
        the first x% (let's say 30%) represent the indexThresholdLow, the rest must be calculated accordingly:
        1) the difference between the full width and all other colors is 70%.
        2) in those 70% must all colors except the red one take place.
        3) the scalingFactor calculates the indices up to percentage values
      */
      var percentageWhichRepresentsRed = this.companyProperties.indexThresholdLow / 2;
      var scalingFactor = (100 - percentageWhichRepresentsRed) / (100 - this.companyProperties.indexThresholdLow);
      var redPercentage = percentageWhichRepresentsRed;
      var orangePercentage = (this.companyProperties.indexThresholdLowerMedium - this.companyProperties.indexThresholdLow) * scalingFactor + redPercentage;
      var yellowPercentage = (this.companyProperties.indexThresholdUpperMedium - this.companyProperties.indexThresholdLowerMedium) * scalingFactor + orangePercentage;
      var greenLightPercentage = (this.companyProperties.indexThresholdHigh - this.companyProperties.indexThresholdUpperMedium) * scalingFactor + yellowPercentage;
      // greenPercentage would be at 100%

      return {
        indexThresholdLow: redPercentage,
        indexThresholdLowerMedium: orangePercentage,
        indexThresholdUpperMedium: yellowPercentage,
        indexThresholdHigh: greenLightPercentage
      };
    },
    backgroundStyle: function backgroundStyle() {
      return {
        'background-image': "linear-gradient(to right,\n          ".concat(colorVariables.red, " ").concat(this.scaledIndicesInPercent.indexThresholdLow, "%,\n          ").concat(colorVariables.orange, " ").concat(this.scaledIndicesInPercent.indexThresholdLow, "%,\n          ").concat(colorVariables.orange, " ").concat(this.scaledIndicesInPercent.indexThresholdLowerMedium, "%,\n          ").concat(colorVariables.yellow, " ").concat(this.scaledIndicesInPercent.indexThresholdLowerMedium, "%,\n          ").concat(colorVariables.yellow, " ").concat(this.scaledIndicesInPercent.indexThresholdUpperMedium, "%,\n          ").concat(colorVariables['green-light'], " ").concat(this.scaledIndicesInPercent.indexThresholdUpperMedium, "%,\n          ").concat(colorVariables['green-light'], " ").concat(this.scaledIndicesInPercent.indexThresholdHigh, "%,\n          ").concat(colorVariables.green, " ").concat(this.scaledIndicesInPercent.indexThresholdHigh, "%\n        )")
      };
    }
  },
  created: function created() {
    this.internalFilterByIndex = this.value.filterByIndex;
  },
  mounted: function mounted() {
    this.createSlider();
  },
  methods: {
    createSlider: function createSlider() {
      var _range,
        _this = this;
      this.slider = NoUiSlider.create(this.$refs.inputRange, {
        start: [this.value.minimalIndex, this.value.maximalIndex],
        orientation: 'horizontal',
        connect: [true, false, true],
        tooltips: true,
        behaviour: 'tap-drag',
        step: 1,
        format: {
          to: function to(value) {
            return Math.round(value) + '';
          },
          from: function from(value) {
            return window.parseInt(value, 10);
          }
        },
        range: (_range = {
          min: [0, 1]
        }, _defineProperty(_range, this.scaledIndicesInPercent.indexThresholdLow + '%', [this.companyProperties.indexThresholdLow, 1]), _defineProperty(_range, "max", [100]), _range)
      });
      this.slider.on('start', function () {
        _this.internalFilterByIndex = true;
      });
      this.slider.on('set', function (values) {
        if (!_this.setValueWithoutEmitting) {
          var minimalIndex = window.parseInt(values[0], 10);
          var maximalIndex = window.parseInt(values[1], 10);
          var defaultObject = QueryUtils.getTimelineDefaultIndexFilterObject();
          if (defaultObject.minimalIndex === minimalIndex && defaultObject.maximalIndex === maximalIndex) {
            _this.$emit('input', defaultObject);
          } else {
            // values is an array of two strings with the min and max value
            _this.$emit('input', {
              filterByIndex: true,
              minimalIndex: window.parseInt(values[0], 10),
              maximalIndex: window.parseInt(values[1], 10)
            });
          }
        }
      });
    },
    checkboxListener: function checkboxListener(checkboxValue) {
      if (!checkboxValue) {
        this.$emit('input', QueryUtils.getTimelineDefaultIndexFilterObject());
      } else {
        this.$emit('input', {
          filterByIndex: checkboxValue,
          minimalIndex: this.value.minimalIndex,
          maximalIndex: this.value.maximalIndex
        });
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function handler() {
        var _this2 = this;
        if (this.internalFilterByIndex !== this.value.filterByIndex) {
          this.internalFilterByIndex = this.value.filterByIndex;
        }
        if (this.slider !== null) {
          var _this$slider$get = this.slider.get(),
            _this$slider$get2 = _slicedToArray(_this$slider$get, 2),
            min = _this$slider$get2[0],
            max = _this$slider$get2[1];
          var minEqual = min === this.value.minimalIndex || min === this.value.minimalIndex + '';
          var maxEqual = max === this.value.maximalIndex || max === this.value.maximalIndex + '';
          if (!minEqual || !maxEqual) {
            this.setValueWithoutEmitting = true;
            this.slider.set([this.value.minimalIndex, this.value.maximalIndex]);
            this.$nextTick(function () {
              _this2.setValueWithoutEmitting = false;
            });
          }
        }
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.slider !== null) {
      this.slider.destroy();
      this.slider = null;
    }
  }
};