var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-md-5" }, [
          _c("h1", { staticClass: "site-title" }, [
            _vm._v(_vm._s(_vm.$t("siteTitle"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-7" }, [
          _c("span", { staticClass: "float-right" }, [
            _c(
              "span",
              [
                _c("opn-date-range-picker", {
                  attrs: {
                    "is-loading": _vm.isLoading,
                    value: _vm.filter.dateRange,
                  },
                  on: { "change-daterange": _vm.dateRangeChanged },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("location-group-select", {
              attrs: {
                "is-loading": _vm.isLoading,
                "pre-selected-ids": _vm.filter.location,
                limit: 999,
              },
              on: { "change-locations": _vm.locationsChanged },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            !_vm.currentUserIsSuperAdmin
              ? _c("search-filter-selector", {
                  staticClass: "mt-n3",
                  attrs: {
                    "is-loading": _vm.isLoading,
                    "pre-selected-search-filter-id":
                      _vm.filter.location.selectedSearchFilterId,
                  },
                  on: { "change-locations": _vm.locationsChanged },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("clear-filter", {
              attrs: { "is-loading": _vm.isLoading },
              on: { "reset-filter": _vm.filterReset },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.isLoading && _vm.noData ? _c("empty-state") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading || !_vm.noData,
              expression: "isLoading || !noData",
            },
          ],
          staticClass: "row",
        },
        [
          _c(
            "div",
            { staticClass: "mb-2 col-12" },
            [
              _c("matrix-view", {
                attrs: {
                  title: _vm.$t(
                    _vm.$store.getters["general/hasIndexCalculation"]
                      ? "matrixViewIndexTitle"
                      : "matrixViewSatisfactionTitle"
                  ),
                  "is-loading": _vm.isLoading,
                  "matrix-locations": _vm.matrix.locations,
                  "matrix-location-groups": _vm.matrix.locationGroups,
                  "location-id-to-location-group-mapping":
                    _vm.matrix.locationIdToLocationGroupMapping,
                  "date-range": _vm.filter.dateRange,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.$store.getters["general/hasIndexCalculation"] && !_vm.noData
        ? _c("color-legend", {
            attrs: {
              "is-loading": _vm.isLoading,
              "company-properties": _vm.$store.state.general.companyProperties,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }