import state from '@/store/modules/survey-management/state';
import getters from '@/store/modules/survey-management/getters';
import mutations from '@/store/modules/survey-management/mutations';
import actions from '@/store/modules/survey-management/actions';
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};