var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      staticClass: "tablet-migration-running",
      attrs: { "panel-title": _vm.$t("panelTitle"), "is-loading": false },
    },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("fatalError.title"),
              message: _vm.$t("fatalError.message"),
              show: _vm.fatalErrorOccurred,
            },
          }),
          _vm._v(" "),
          _vm.isLoading
            ? _c("div", { staticClass: "spinner-wrapper" }, [
                _c("div", { staticClass: "opn-spinner" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading && !_vm.fatalErrorOccurred
            ? _c(
                "div",
                [
                  _c("b-table", {
                    attrs: {
                      striped: "",
                      hover: "",
                      items: _vm.items,
                      fields: _vm.fields,
                      "sort-by": "number",
                      "show-empty": true,
                      "empty-text": _vm.$t("emptyTableText"),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(number)",
                          fn: function (data) {
                            return [
                              data.item.number
                                ? _c("span", [_vm._v(_vm._s(data.item.number))])
                                : _c("span", [_vm._v("—")]),
                            ]
                          },
                        },
                        {
                          key: "cell(locationName)",
                          fn: function (data) {
                            return [
                              !data.item.multiLocation
                                ? _c("span", [
                                    _vm._v(_vm._s(data.item.locationName)),
                                  ])
                                : _c(
                                    "b-badge",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.top.d200",
                                          modifiers: {
                                            hover: true,
                                            top: true,
                                            d200: true,
                                          },
                                        },
                                      ],
                                      staticClass: "multi-location-badge",
                                      attrs: {
                                        pill: "",
                                        variant: "secondary",
                                        title: data.item.locationNames,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t("locationCount", {
                                              count: data.item.locationCount,
                                            })
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                        {
                          key: "cell(locationGroup)",
                          fn: function (data) {
                            return [
                              !data.item.multiLocation
                                ? _c("span", [
                                    _vm._v(_vm._s(data.item.locationGroup)),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          },
                        },
                        {
                          key: "cell(lastActiveTimestamp)",
                          fn: function (data) {
                            return [
                              data.item.lastActiveTimestamp
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDateTime(
                                          data.item.lastActiveTimestamp
                                        )
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          },
                        },
                        {
                          key: "cell(type)",
                          fn: function (data) {
                            return [
                              data.item.type.os === "iOS"
                                ? _c("font-awesome", {
                                    staticClass: "apple",
                                    attrs: { icon: ["fab", "apple"] },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              data.item.type.os === "ANDROID"
                                ? _c("font-awesome", {
                                    staticClass: "android",
                                    attrs: { icon: ["fab", "android"] },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              data.item.type.os === "UNKNOWN"
                                ? _c("font-awesome", {
                                    staticClass: "unknown",
                                    attrs: { icon: ["fas", "question"] },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.languageAwareString(
                                      data.item.type.name
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "cell(finish)",
                          fn: function (data) {
                            return [
                              _c(
                                "b-button",
                                {
                                  staticClass: "float-right mr-3",
                                  attrs: {
                                    variant: "danger",
                                    size: "sm",
                                    disabled:
                                      _vm.isLoading || data.item.multiLocation,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showModal(data.item)
                                    },
                                  },
                                },
                                [
                                  data.item.multiLocation
                                    ? _c("font-awesome", {
                                        staticClass: "lock",
                                        attrs: { icon: ["fas", "lock"] },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("finishButton")) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1481256945
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }