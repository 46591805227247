var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "print-report-wrapper" }, [
    _c(
      "div",
      { staticClass: "print-report" },
      [
        _c("div", { staticClass: "opiniooon-logo" }, [
          _c("img", { attrs: { src: _vm.opiniooonLogo } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-numbers" }, [_vm._v("0 / 0")]),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }