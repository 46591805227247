var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "survey-management-panel",
      class: { padded: _vm.padded, disabled: _vm.disabled },
    },
    [
      _vm.hasTitle
        ? _c(
            "h2",
            { staticClass: "title" },
            [_vm._t("title"), _vm._v(" "), _c("hr")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }