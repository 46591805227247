var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "opn-date-range-picker",
      class: {
        disabled: _vm.disabled,
        "is-in-form": _vm.isInForm,
        "has-no-preset-ranges": !_vm.hasPresetRanges,
      },
    },
    [
      _c(
        "div",
        { staticClass: "opn-date-picker-content" },
        [
          _vm.hasPresetRanges === false
            ? [
                _c(
                  "div",
                  {
                    staticClass: "date-picker-toggle",
                    attrs: { "data-toggle": "", disabled: _vm.disabled },
                  },
                  [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.displayDateText) },
                    }),
                    _vm._v(" "),
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["far", "angle-down"] },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPresetRanges === true
            ? [
                _c(
                  "b-dropdown",
                  {
                    staticClass: "dropdown-label",
                    attrs: {
                      variant: "link",
                      "no-flip": true,
                      right: _vm.isInForm ? false : true,
                      disabled: _vm.isLoading,
                    },
                    on: {
                      shown: function ($event) {
                        _vm.fp ? _vm.fp.close() : function () {}
                      },
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "button-content" },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.displayDateText) },
                        }),
                        _vm._v(" "),
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["far", "angle-down"] },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.ranges, function (range) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: range.name,
                          attrs: {
                            active:
                              range.start.isSame(_vm.dateRange[0], "day") &&
                              range.end.isSame(_vm.dateRange[1], "day"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setDateRange(range)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t(range.name)) +
                              "\n        "
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("b-dropdown-divider"),
                    _vm._v(" "),
                    _c("b-dropdown-item", { attrs: { "data-toggle": "" } }, [
                      _vm._v(_vm._s(_vm.$t("userDefined"))),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "switch-buttons" }, [
                  _c("button", {
                    staticClass: "prev",
                    attrs: { disabled: _vm.isLoading },
                    on: {
                      click: function ($event) {
                        return _vm.changePeriod("prev")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "next",
                    attrs: {
                      disabled: _vm.isLoading || _vm.nextButtonDisabled,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changePeriod("next")
                      },
                    },
                  }),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mutableValue,
                expression: "mutableValue",
              },
            ],
            staticClass: "form-control input date-range-input",
            staticStyle: { display: "none" },
            attrs: {
              name: "date-range",
              required: "true",
              "data-input": "",
              disabled: _vm.disabled || _vm.isLoading,
            },
            domProps: { value: _vm.mutableValue },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.mutableValue = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "clearfix" }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }