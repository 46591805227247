import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import _ from 'lodash';
import { stringIsEmpty, stringContainsInvalidCharacters } from '@/services/utils/StringUtils';
import moment from 'moment';
var HIGHEST_INTEGER_VALUE = 999999999;
var LOWEST_INTEGER_VALUE = -999999999;
export var convertQuestionToEditQuestion = function convertQuestionToEditQuestion(question) {
  var questionType = question.questionType;
  var editQuestion = {
    text: question.text
  };
  if (questionType === 'RATING_QUESTION_SMILEY' || questionType === 'RATING_QUESTION_SLIDER') {
    _.merge(editQuestion, {
      categoryId: question.categoryId,
      scale: question.scale,
      scaleOrder: question.scaleOrder,
      showCategoryInSurvey: question.showCategoryInSurvey,
      ratingReasonThreshold: question.ratingReasonThreshold,
      ratingReasonTitle: question.ratingReasonTitle,
      activatedRatingReasons: question.activatedRatingReasons.map(function (rr) {
        return mapRegularRatingReason(rr);
      }),
      disabledRatingReasons: question.disabledRatingReasons.map(function (rr) {
        return mapRegularRatingReason(rr);
      }),
      customRatingReasonEnabled: question.customRatingReasonEnabled,
      customRatingReason: mapCustomRatingReason(question.customRatingReasonEnabled, question.customRatingReasonText)
    });
    if (questionType === 'RATING_QUESTION_SLIDER') {
      _.merge(editQuestion, {
        scaleLabelMin: question.scaleLabelMin,
        scaleLabelMax: question.scaleLabelMax
      });
    }
  } else if (questionType === 'CHOICE_QUESTION_MULTIPLE_CHOICE' || questionType === 'CHOICE_QUESTION_SINGLE_CHOICE') {
    _.merge(editQuestion, {
      activatedRegularChoiceAttributes: question.regularChoiceAttributes.filter(function (chattr) {
        return chattr.enabled;
      }).map(function (chattr) {
        return mapRegularChoiceAttribute(chattr);
      }),
      disabledRegularChoiceAttributes: question.regularChoiceAttributes.filter(function (chattr) {
        return !chattr.enabled;
      }).map(function (chattr) {
        return mapRegularChoiceAttribute(chattr);
      }),
      activatedCustomChoiceAttributes: question.customChoiceAttributes.filter(function (chattr) {
        return chattr.enabled;
      }).map(function (chattr) {
        return mapCustomChoiceAttribute(chattr);
      }),
      disabledCustomChoiceAttributes: question.customChoiceAttributes.filter(function (chattr) {
        return !chattr.enabled;
      }).map(function (chattr) {
        return mapCustomChoiceAttribute(chattr);
      })
    });
  } else if (questionType === 'COMMENT_QUESTION') {
    // no additional flags
  } else if (questionType === 'EMAIL_QUESTION') {
    _.merge(editQuestion, {
      placeholder: question.placeholder,
      contactRequestEnabled: question.contactRequestEnabled,
      contactRequestText: question.contactRequestText
    });
  } else if (questionType === 'NUMBER_QUESTION') {
    _.merge(editQuestion, {
      min: question.min === null ? null : question.min + '',
      // make sure to always have here a string to bind to input fields
      max: question.max === null ? null : question.max + '',
      // make sure to always have here a string to bind to input fields
      placeholder: question.placeholder
    });
  } else if (questionType === 'DATE_QUESTION') {
    _.merge(editQuestion, {
      min: question.min,
      max: question.max,
      placeholder: question.placeholder
    });
  } else if (questionType === 'TEXT_QUESTION_LONG_TEXT' || questionType === 'TEXT_QUESTION_SHORT_TEXT') {
    _.merge(editQuestion, {
      placeholder: question.placeholder
    });
  } else {
    throw new Error('QuestionType not supported');
  }

  // make sure to not use the same objects (editQuestion would otherwise change question) -> cloneDeep
  return _.cloneDeep(editQuestion);
};
function mapRegularRatingReason(rr) {
  return {
    id: rr.ratingReasonId,
    text: rr.text,
    isNew: false,
    type: 'REGULAR',
    key: "REGULAR-".concat(rr.ratingReasonId)
  };
}
function mapCustomRatingReason(initiallyEnabled, text) {
  return {
    id: null,
    text: text,
    isNew: !initiallyEnabled,
    type: 'CUSTOM',
    key: 'CUSTOM-RATING-REASON'
  };
}
function mapRegularChoiceAttribute(chattr) {
  return {
    id: chattr.choiceAttributeId,
    text: chattr.text,
    isNew: false,
    key: "REGULAR-".concat(chattr.choiceAttributeId)
  };
}
function mapCustomChoiceAttribute(chattr) {
  return {
    id: chattr.customChoiceAttributeId,
    text: chattr.text,
    isNew: false,
    key: "CUSTOM-".concat(chattr.customChoiceAttributeId)
  };
}
export var getInitialLanguage = function getInitialLanguage(enabledLanguages, userLanguage) {
  if (enabledLanguages.includes(userLanguage)) {
    return userLanguage;
  } else {
    if (enabledLanguages.length > 0) {
      return enabledLanguages[0];
    }
  }
  return 'DE';
};
var mapRatingReasonToSubmitStructure = function mapRatingReasonToSubmitStructure(rr, index) {
  return {
    ratingReasonId: rr.id,
    text: rr.text,
    expectedReasonNrZeroBased: index
  };
};
var mapRegularChoiceAttributeToSubmitStructure = function mapRegularChoiceAttributeToSubmitStructure(cha, index) {
  return {
    choiceAttributeId: cha.id,
    text: cha.text,
    expectedAttributeNrZeroBased: index
  };
};
var mapCustomChoiceAttributeToSubmitStructure = function mapCustomChoiceAttributeToSubmitStructure(cha, index) {
  return {
    customChoiceAttributeId: cha.id,
    text: cha.text,
    expectedAttributeNrZeroBased: index
  };
};
export var convertEditQuestionToSubmitStructure = function convertEditQuestionToSubmitStructure(editQuestion, questionType, questionId, questionNr) {
  var submitStructure = {
    questionId: questionId,
    text: editQuestion.text,
    expectedQuestionNrZeroBased: questionId === null ? questionNr : null,
    questionType: questionType
  };
  if (questionType === 'RATING_QUESTION_SMILEY' || questionType === 'RATING_QUESTION_SLIDER') {
    _.merge(submitStructure, {
      categoryId: editQuestion.categoryId,
      scale: editQuestion.scale,
      scaleOrder: editQuestion.scaleOrder,
      showCategoryInSurvey: editQuestion.showCategoryInSurvey,
      ratingReasonThreshold: editQuestion.ratingReasonThreshold,
      ratingReasonTitle: editQuestion.ratingReasonTitle,
      createRatingReasons: editQuestion.activatedRatingReasons.filter(function (rr) {
        return rr.isNew;
      }).map(function (rr) {
        return mapRatingReasonToSubmitStructure(rr, editQuestion.activatedRatingReasons.indexOf(rr));
      }),
      updateRatingReasons: editQuestion.activatedRatingReasons.filter(function (rr) {
        return !rr.isNew;
      }).map(function (rr) {
        return mapRatingReasonToSubmitStructure(rr, editQuestion.activatedRatingReasons.indexOf(rr));
      }),
      disableRatingReasonIds: editQuestion.disabledRatingReasons.filter(function (rr) {
        return !rr.isNew;
      }).map(function (rr) {
        return rr.id;
      }),
      customRatingReasonEnabled: editQuestion.customRatingReasonEnabled,
      customRatingReasonText: editQuestion.customRatingReasonEnabled ? editQuestion.customRatingReason.text : null
    });
    if (questionType === 'RATING_QUESTION_SLIDER') {
      _.merge(submitStructure, {
        scaleLabelMin: editQuestion.scaleLabelMin,
        scaleLabelMax: editQuestion.scaleLabelMax
      });
    }
  } else if (questionType === 'CHOICE_QUESTION_MULTIPLE_CHOICE' || questionType === 'CHOICE_QUESTION_SINGLE_CHOICE') {
    _.merge(submitStructure, {
      createRegularChoiceAttributes: editQuestion.activatedRegularChoiceAttributes.filter(function (chattr) {
        return chattr.isNew;
      }).map(function (chattr) {
        return mapRegularChoiceAttributeToSubmitStructure(chattr, editQuestion.activatedRegularChoiceAttributes.indexOf(chattr));
      }),
      updateRegularChoiceAttributes: editQuestion.activatedRegularChoiceAttributes.filter(function (chattr) {
        return !chattr.isNew;
      }).map(function (chattr) {
        return mapRegularChoiceAttributeToSubmitStructure(chattr, editQuestion.activatedRegularChoiceAttributes.indexOf(chattr));
      }),
      disableRegularChoiceAttributeIds: editQuestion.disabledRegularChoiceAttributes.filter(function (chattr) {
        return !chattr.isNew;
      }).map(function (chattr) {
        return chattr.id;
      }),
      createCustomChoiceAttributes: editQuestion.activatedCustomChoiceAttributes.filter(function (chattr) {
        return chattr.isNew;
      }).map(function (chattr) {
        return mapCustomChoiceAttributeToSubmitStructure(chattr, editQuestion.activatedCustomChoiceAttributes.indexOf(chattr));
      }),
      updateCustomChoiceAttributes: editQuestion.activatedCustomChoiceAttributes.filter(function (chattr) {
        return !chattr.isNew;
      }).map(function (chattr) {
        return mapCustomChoiceAttributeToSubmitStructure(chattr, editQuestion.activatedCustomChoiceAttributes.indexOf(chattr));
      }),
      disableCustomChoiceAttributeIds: editQuestion.disabledCustomChoiceAttributes.filter(function (chattr) {
        return !chattr.isNew;
      }).map(function (chattr) {
        return chattr.id;
      })
    });
  } else if (questionType === 'COMMENT_QUESTION') {
    // no additional flags
  } else if (questionType === 'EMAIL_QUESTION') {
    _.merge(submitStructure, {
      placeholder: editQuestion.placeholder,
      contactRequestEnabled: editQuestion.contactRequestEnabled,
      contactRequestText: editQuestion.contactRequestEnabled ? editQuestion.contactRequestText : null
    });
  } else if (questionType === 'NUMBER_QUESTION') {
    _.merge(submitStructure, {
      placeholder: editQuestion.placeholder,
      min: editQuestion.min === null || isNaN(editQuestion.min) ? null : window.parseFloat(editQuestion.min),
      max: editQuestion.max === null || isNaN(editQuestion.max) ? null : window.parseFloat(editQuestion.max)
    });
  } else if (questionType === 'DATE_QUESTION') {
    _.merge(submitStructure, {
      placeholder: editQuestion.placeholder,
      min: editQuestion.min === null || !moment(editQuestion.min).isValid() ? null : editQuestion.min,
      max: editQuestion.max === null || !moment(editQuestion.max).isValid() ? null : editQuestion.max
    });
  } else if (questionType === 'TEXT_QUESTION_LONG_TEXT' || questionType === 'TEXT_QUESTION_SHORT_TEXT') {
    _.merge(submitStructure, {
      placeholder: editQuestion.placeholder
    });
  } else {
    throw new Error('QuestionType not supported');
  }

  // remove bindings of editQuestion when converting to submit structure
  return _.cloneDeep(submitStructure);
};
var getValidObject = function getValidObject(valid, details, single) {
  return {
    valid: valid,
    details: typeof details === 'undefined' || details === null ? undefined : details,
    single: typeof single === 'undefined' || single === null ? undefined : single
  };
};
var isValidLas = function isValidLas(las, enabledLanguages, optional) {
  if (las === null) {
    return !!optional;
  }
  var hasMissingLanguages = false;
  var hasSpecialChars = false;
  enabledLanguages.forEach(function (language) {
    if (stringContainsInvalidCharacters(las[language])) {
      hasSpecialChars = true;
    }
    if (!las.hasOwnProperty(language) || stringIsEmpty(las[language])) {
      hasMissingLanguages = true;
    }
  });
  return !hasMissingLanguages && !hasSpecialChars;
};
var getInvalidLanguagesFromLas = function getInvalidLanguagesFromLas(las, enabledLanguages, optional) {
  if (isValidLas(las, enabledLanguages, optional)) {
    return [];
  }
  if (las === null && !optional) {
    return enabledLanguages;
  }
  var invalidLanguages = [];
  enabledLanguages.forEach(function (language) {
    if (!las.hasOwnProperty(language) || stringIsEmpty(las[language]) || stringContainsInvalidCharacters(las[language])) {
      invalidLanguages.push(language);
    }
  });
  return invalidLanguages;
};
var getLasValidObject = function getLasValidObject(las, enabledLanguages, optional) {
  return getValidObject(isValidLas(las, enabledLanguages, optional), getInvalidLanguagesFromLas(las, enabledLanguages, optional));
};
export var getValidationStateFromEditQuestion = function getValidationStateFromEditQuestion(editQuestion, questionType, enabledLanguages) {
  var validation = {
    text: getLasValidObject(editQuestion.text, enabledLanguages, false)
  };
  if (questionType === 'RATING_QUESTION_SMILEY' || questionType === 'RATING_QUESTION_SLIDER') {
    _.merge(validation, {
      categoryId: getValidObject(editQuestion.categoryId !== null),
      scale: getValidObject(true),
      scaleOrder: getValidObject(editQuestion.scaleOrder !== null),
      showCategoryInSurvey: getValidObject(editQuestion.showCategoryInSurvey !== null),
      ratingReasonThreshold: getValidObject(editQuestion.scale >= editQuestion.ratingReasonThreshold && editQuestion.ratingReasonThreshold >= 0),
      ratingReasonTitle: getLasValidObject(editQuestion.ratingReasonTitle, enabledLanguages, true),
      activatedRatingReasons: function () {
        var single = editQuestion.activatedRatingReasons.map(function (rr) {
          return getLasValidObject(rr.text, enabledLanguages, false);
        });
        return getValidObject(single.every(function (item) {
          return item.valid;
        }), null, single);
      }(),
      disabledRatingReasons: getValidObject(true),
      customRatingReasonEnabled: getValidObject(true),
      customRatingReason: editQuestion.customRatingReasonEnabled ? getLasValidObject(editQuestion.customRatingReason.text, enabledLanguages, false) : getValidObject(true)
    });
    if (questionType === 'RATING_QUESTION_SLIDER') {
      _.merge(validation, {
        scaleLabelMin: getLasValidObject(editQuestion.scaleLabelMin, enabledLanguages, true),
        scaleLabelMax: getLasValidObject(editQuestion.scaleLabelMax, enabledLanguages, true)
      });
    }
  } else if (questionType === 'CHOICE_QUESTION_MULTIPLE_CHOICE' || questionType === 'CHOICE_QUESTION_SINGLE_CHOICE') {
    _.merge(validation, {
      activatedRegularChoiceAttributes: function () {
        var single = editQuestion.activatedRegularChoiceAttributes.map(function (chattr) {
          return getLasValidObject(chattr.text, enabledLanguages, false);
        });
        return getValidObject(single.every(function (item) {
          return item.valid;
        }), null, single);
      }(),
      disabledRegularChoiceAttributes: getValidObject(true),
      activatedCustomChoiceAttributes: function () {
        var single = editQuestion.activatedCustomChoiceAttributes.map(function (chattr) {
          return getLasValidObject(chattr.text, enabledLanguages, false);
        });
        return getValidObject(single.every(function (item) {
          return item.valid;
        }), null, single);
      }(),
      disabledCustomChoiceAttributes: getValidObject(true),
      numberOfActivatedChoiceAttributes: function () {
        return getValidObject(editQuestion.activatedRegularChoiceAttributes.length + editQuestion.activatedCustomChoiceAttributes.length > 0);
      }()
    });
  } else if (questionType === 'COMMENT_QUESTION') {
    // no additional flags
  } else if (questionType === 'EMAIL_QUESTION') {
    _.merge(validation, {
      placeholder: getLasValidObject(editQuestion.placeholder, enabledLanguages, true),
      contactRequestEnabled: getValidObject(true),
      contactRequestText: editQuestion.contactRequestEnabled ? getLasValidObject(editQuestion.contactRequestText, enabledLanguages, false) : getValidObject(true)
    });
  } else if (questionType === 'NUMBER_QUESTION') {
    _.merge(validation, {
      placeholder: getLasValidObject(editQuestion.placeholder, enabledLanguages, true),
      min: getValidObject(function () {
        if (editQuestion.min === null) {
          return true;
        } else {
          if (isNaN(editQuestion.min) || window.parseFloat(editQuestion.min) > HIGHEST_INTEGER_VALUE || window.parseFloat(editQuestion.min) < LOWEST_INTEGER_VALUE) {
            return false;
          } else {
            if (editQuestion.max !== null && !isNaN(editQuestion.max) && window.parseFloat(editQuestion.min) > window.parseFloat(editQuestion.max)) {
              return false;
            } else {
              return true;
            }
          }
        }
      }()),
      max: getValidObject(function () {
        if (editQuestion.max === null) {
          return true;
        } else {
          if (isNaN(editQuestion.max) || window.parseFloat(editQuestion.max) > HIGHEST_INTEGER_VALUE || window.parseFloat(editQuestion.max) < LOWEST_INTEGER_VALUE) {
            return false;
          } else {
            if (editQuestion.min !== null && !isNaN(editQuestion.min) && window.parseFloat(editQuestion.min) > window.parseFloat(editQuestion.max)) {
              return false;
            } else {
              return true;
            }
          }
        }
      }())
    });
  } else if (questionType === 'DATE_QUESTION') {
    var minDate = editQuestion.min !== null ? moment(editQuestion.min, 'YYYY-MM-DD') : null;
    var maxDate = editQuestion.max !== null ? moment(editQuestion.max, 'YYYY-MM-DD') : null;
    var validMinMaxDateCombination = function () {
      if (minDate === null || maxDate === null || !minDate.isValid() || !maxDate.isValid()) {
        return true;
      }
      return !(minDate.isAfter(maxDate) || minDate.isSame(maxDate));
    }();
    _.merge(validation, {
      placeholder: getLasValidObject(editQuestion.placeholder, enabledLanguages, true),
      min: getValidObject(minDate === null || minDate.isValid() && validMinMaxDateCombination),
      max: getValidObject(maxDate === null || maxDate.isValid() && validMinMaxDateCombination)
    });
  } else if (questionType === 'TEXT_QUESTION_LONG_TEXT' || questionType === 'TEXT_QUESTION_SHORT_TEXT') {
    _.merge(validation, {
      placeholder: getLasValidObject(editQuestion.placeholder, enabledLanguages, true)
    });
  } else {
    throw new Error('QuestionType not supported');
  }
  return validation;
};
export var getValidationFieldsPerSubpage = function getValidationFieldsPerSubpage(questionType) {
  var result = {
    EDIT: ['text']
  };
  if (questionType === 'RATING_QUESTION_SMILEY' || questionType === 'RATING_QUESTION_SLIDER') {
    result.EDIT.push('categoryId', 'scale', 'scaleOrder', 'showCategoryInSurvey');
    result.RATING_REASONS = ['ratingReasonThreshold', 'ratingReasonTitle', 'activatedRatingReasons', 'disabledRatingReasons', 'customRatingReasonEnabled', 'customRatingReason'];
    if (questionType === 'RATING_QUESTION_SLIDER') {
      result.EDIT.push('scaleLabelMin', 'scaleLabelMax');
    }
  } else if (questionType === 'CHOICE_QUESTION_MULTIPLE_CHOICE' || questionType === 'CHOICE_QUESTION_SINGLE_CHOICE') {
    // no additional flags for edit subpage
    result.CHOICE_ATTRIBUTES = ['activatedRegularChoiceAttributes', 'disabledRegularChoiceAttributes', 'activatedCustomChoiceAttributes', 'disabledCustomChoiceAttributes', 'numberOfActivatedChoiceAttributes'];
  } else if (questionType === 'COMMENT_QUESTION') {
    // no additional flags
  } else if (questionType === 'EMAIL_QUESTION') {
    result.EDIT.push('placeholder', 'contactRequestEnabled', 'contactRequestText');
  } else if (questionType === 'NUMBER_QUESTION') {
    result.EDIT.push('min', 'max', 'placeholder');
  } else if (questionType === 'DATE_QUESTION') {
    result.EDIT.push('min', 'max', 'placeholder');
  } else if (questionType === 'TEXT_QUESTION_LONG_TEXT' || questionType === 'TEXT_QUESTION_SHORT_TEXT') {
    result.EDIT.push('placeholder');
  } else {
    throw new Error('QuestionType not supported');
  }
  return result;
};