//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import opnProgress from '@/components/shared/opnProgress';
import Panel from '@/components/shared/Panel';
import SortIcon from '@/components/shared/SortIcon';
import RoutingUtils from '@/services/utils/RoutingUtils';
import { getColorNameByIndex } from '@/services/ColorService';
export default {
  name: 'LocationPerformanceList',
  components: {
    SortIcon: SortIcon,
    Panel: Panel,
    opnProgress: opnProgress
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      sortBy: null,
      sortOrder: 'desc',
      groupBy: 'location',
      groupingOptions: [{
        value: 'location',
        text: this.$t('groupByLocation')
      }, {
        value: 'locationgroup',
        text: this.$t('groupByLocationGroup')
      }]
    };
  },
  computed: {
    sortedLocations: function sortedLocations() {
      return _.orderBy(this.data, this.sortBy, this.sortOrder);
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    }
  },
  methods: {
    changeSort: function changeSort(sortBy) {
      if (this.sortBy === sortBy) {
        if (this.sortOrder === 'desc') {
          this.sortOrder = 'asc';
        } else {
          this.sortOrder = 'desc';
        }
      }
      this.sortBy = sortBy;
    },
    onChangeGrouping: function onChangeGrouping(value) {
      this.$emit('change-grouping', value);
    },
    routeToDashboardWithLocation: RoutingUtils.routeToDashboardWithLocation,
    routeToDashboardWithLocationGroupByLocationId: RoutingUtils.routeToDashboardWithLocationGroupByLocationId,
    routeToDashboardWithLocationGroupByLocationGroupId: RoutingUtils.routeToDashboardWithLocationGroupByLocationGroupId,
    getColorNameByIndex: getColorNameByIndex
  },
  watch: {
    hasIndexCalculation: function hasIndexCalculation() {
      if (this.sortBy === null || this.hasIndexCalculation && this.sortBy === 'satisfactionRate' || !this.hasIndexCalculation && this.sortBy === 'averageIndex') {
        this.sortBy = this.hasIndexCalculation ? 'averageIndex' : 'satisfactionRate';
      }
    }
  }
};