var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-filters" },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c("b-col", { attrs: { sm: "5", xl: "4" } }, [
                _c("label", { staticClass: "form-label small-value" }, [
                  _vm._v(_vm._s(_vm.$t("label.dateRange"))),
                ]),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { sm: "7", xl: "8" } }, [
                _c(
                  "div",
                  [
                    _c("filter-criteria-date", {
                      attrs: {
                        "start-date": _vm.startDate,
                        "end-date": _vm.endDate,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c("b-col", { attrs: { sm: "5", xl: "4" } }, [
                _c("label", { staticClass: "form-label small-value" }, [
                  _vm._v(_vm._s(_vm.$t("label.locations"))),
                ]),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { sm: "7", xl: "8" } }, [
                _c(
                  "div",
                  [
                    _c("filter-criteria-location", {
                      attrs: {
                        "location-ids": _vm.form.locations.queryLocations,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { sm: "5", xl: "4" } }, [
                _c("label", { staticClass: "form-label small-value" }, [
                  _vm._v(_vm._s(_vm.$t("label.survey"))),
                ]),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { sm: "7", xl: "8" } }, [
                _c(
                  "div",
                  [
                    _c("filter-criteria-survey", {
                      attrs: {
                        "survey-name": _vm.surveyName,
                        "is-loading": _vm.initialLoading,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12" }, [
        _c(
          "div",
          {
            staticClass: "number-of-feedbacks",
            class: {
              loading: _vm.isLoading,
              "no-feedbacks": _vm.numberOfFeedbacks === 0 && !_vm.isLoading,
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("groupedNumber")(_vm.numberOfFeedbacks)) +
                "\n      "
            ),
            _c(
              "small",
              [_c("font-awesome", { attrs: { icon: ["fas", "user"] } })],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("h2", { staticClass: "filter-title" }, [
          _vm._v(_vm._s(_vm.$t("filterTitle"))),
        ]),
      ]),
      _vm._v(" "),
      _vm.hasAnyFilter
        ? _c(
            "b-container",
            { attrs: { fluid: "" } },
            _vm._l(
              _vm.possibleActiveChoiceQuestions,
              function (choiceQuestion) {
                return _c(
                  "b-row",
                  {
                    key: choiceQuestion.choiceQuestionId,
                    staticClass: "mb-3 choice-question",
                  },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "choice-question-title",
                        attrs: { sm: "5", xl: "4" },
                      },
                      [
                        _c("label", {
                          staticClass: "form-label small-value",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$options.filters.languageAwareString(
                                choiceQuestion.text
                              )
                            ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      {
                        staticClass: "choice-question-value",
                        attrs: { sm: "7", xl: "8" },
                      },
                      _vm._l(
                        choiceQuestion.choiceAttributes,
                        function (choiceAttribute) {
                          return _c(
                            "div",
                            {
                              key: choiceAttribute.choiceAttributeId,
                              staticClass: "choice-attribute",
                            },
                            [
                              _c(
                                "filter-criteria-choice",
                                {
                                  attrs: {
                                    active:
                                      _vm.form.choiceAttributeIds.indexOf(
                                        choiceAttribute.choiceAttributeId
                                      ) !== -1,
                                    disabled:
                                      !choiceAttribute.activeInSelection ||
                                      _vm.isLoading ||
                                      _vm.initialLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectChoiceAttribute(
                                        choiceAttribute.choiceAttributeId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$options.filters.languageAwareString(
                                          choiceAttribute.text
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ],
                  1
                )
              }
            ),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAnyFilter
        ? _c("div", { staticClass: "col-sm-12" }, [
            _c("p", { staticClass: "no-filters" }, [
              _vm._v(_vm._s(_vm.$t("noFiltersAvailable"))),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "b-button",
            {
              staticClass: "prev-button",
              attrs: {
                variant: "outline-secondary",
                disabled: _vm.isLoading || _vm.initialLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("prev-step")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("prevStep")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "next-button",
              attrs: {
                variant: "primary",
                disabled:
                  _vm.isLoading ||
                  _vm.initialLoading ||
                  _vm.numberOfFeedbacks === 0,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("submit")
                },
              },
            },
            [
              _c(
                "small",
                [
                  _vm.numberOfFeedbacks === 0 && !_vm.isLoading
                    ? _c("font-awesome", {
                        attrs: { icon: ["fas", "exclamation-triangle"] },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v("\n      " + _vm._s(_vm.$t("submit")) + "\n    "),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }