import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import FormatUtils from '@/services/utils/FormatUtils';
import ModalIconButton from '@/components/shared/ModalIconButton';
import Alert from '@/components/shared/Alert';
import SurveyManagementLasWrapper from '@/components/survey-management/edit-question/questions/SurveyManagementLasWrapper';
import FormSelect from '@/components/shared/form-input/FormSelect';
import SurveyManagementQuestionPreview from '@/components/survey-management/edit-question/question-preview/SurveyManagementQuestionPreview';
import SurveyManagementPanel from '@/components/survey-management/SurveyManagementPanel';
import { getValidationFieldsPerSubpage } from '@/services/SurveyManagementService';
import { getQuestionType } from '@/services/SurveyManagementUtilService';
import FormStepIndicator from '@/components/shared/FormStepIndicator';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import SurveyManagementEditPlaceholder from '@/components/survey-management/edit-question/questions/SurveyManagementEditPlaceholder';
import SurveyManagementEditRatingQuestion from '@/components/survey-management/edit-question/questions/SurveyManagementEditRatingQuestion';
import SurveyManagementEditRatingReasons from '@/components/survey-management/edit-question/questions/SurveyManagementEditRatingReasons';
import SurveyManagementEditEmailQuestion from '@/components/survey-management/edit-question/questions/SurveyManagementEditEmailQuestion';
import SurveyManagementEditNumberQuestion from '@/components/survey-management/edit-question/questions/SurveyManagementEditNumberQuestion';
import SurveyManagementEditDateQuestion from '@/components/survey-management/edit-question/questions/SurveyManagementEditDateQuestion';
import SurveyManagementPreviewWrapper from '@/components/survey-management/edit-question/question-preview/SurveyManagementPreviewWrapper';
import SurveyManagementEditChoiceAttributes from '@/components/survey-management/edit-question/questions/SurveyManagementEditChoiceAttributes';
export default {
  name: 'SurveyManagementEditingQuestion',
  components: {
    SurveyManagementPreviewWrapper: SurveyManagementPreviewWrapper,
    Alert: Alert,
    LoadingIndicator: LoadingIndicator,
    SurveyManagementLasWrapper: SurveyManagementLasWrapper,
    FormSelect: FormSelect,
    FormStepIndicator: FormStepIndicator,
    SurveyManagementPanel: SurveyManagementPanel,
    ModalIconButton: ModalIconButton,
    SurveyManagementQuestionPreview: SurveyManagementQuestionPreview,
    SurveyManagementEditPlaceholder: SurveyManagementEditPlaceholder,
    SurveyManagementEditRatingQuestion: SurveyManagementEditRatingQuestion,
    SurveyManagementEditRatingReasons: SurveyManagementEditRatingReasons,
    SurveyManagementEditEmailQuestion: SurveyManagementEditEmailQuestion,
    SurveyManagementEditNumberQuestion: SurveyManagementEditNumberQuestion,
    SurveyManagementEditDateQuestion: SurveyManagementEditDateQuestion,
    SurveyManagementEditChoiceAttributes: SurveyManagementEditChoiceAttributes
  },
  computed: {
    editPageState: function editPageState() {
      return this.$store.state.surveyManagement.editPageState || {};
    },
    question: function question() {
      return this.editPageState.data.question;
    },
    questionTypeObject: function questionTypeObject() {
      return getQuestionType(this.question.questionType);
    },
    currentStepIndex: function currentStepIndex() {
      var stepIndex = 0;
      if (this.editPageState.data.isNew) {
        stepIndex++;
      }
      if (['RATING_REASONS', 'CHOICE_ATTRIBUTES'].includes(this.editPageState.data.subpage)) {
        stepIndex++;
      }
      return stepIndex;
    },
    steps: function steps() {
      var steps = [];
      if (this.editPageState.data.isNew) {
        steps.push(this.$t('pageName.createNew'));
      }
      steps.push(this.$t('pageName.edit'));
      if (['RATING_QUESTION_SMILEY', 'RATING_QUESTION_SLIDER'].includes(this.question.questionType)) {
        steps.push(this.$t('pageName.reasons'));
      }
      if (['CHOICE_QUESTION_SINGLE_CHOICE', 'CHOICE_QUESTION_MULTIPLE_CHOICE'].includes(this.question.questionType)) {
        steps.push(this.$t('pageName.choiceAttributes'));
      }
      steps.push(this.$t('pageName.success'));
      return steps;
    },
    previousStepDisabled: function previousStepDisabled() {
      if (this.isLoadingCategories || this.hasCategoryError) {
        return true;
      }
      if (['RATING_REASONS', 'CHOICE_ATTRIBUTES'].includes(this.editPageState.data.subpage) || this.editPageState.data.subpage === 'EDIT' && this.editPageState.data.isNew) {
        return false;
      }
      return true;
    },
    nextStepDisabled: function nextStepDisabled() {
      if (this.isLoadingCategories || this.hasCategoryError) {
        return true;
      }
      return false;
    },
    needsToLoadCategories: function needsToLoadCategories() {
      return ['RATING_QUESTION_SMILEY', 'RATING_QUESTION_SLIDER'].includes(this.question.questionType);
    },
    isLoadingCategories: function isLoadingCategories() {
      if (this.needsToLoadCategories) {
        return this.$store.state.surveyManagement.categories.isLoading;
      }
      return false;
    },
    hasCategoryError: function hasCategoryError() {
      if (this.needsToLoadCategories) {
        return this.$store.state.surveyManagement.categories.hasError;
      }
      return false;
    },
    validation: function validation() {
      return this.$store.getters['surveyManagement/editQuestionValidation'];
    },
    initial: function initial() {
      return this.$store.state.surveyManagement.fullSurvey.data.questions.length === 0;
    }
  },
  data: function data() {
    return {
      currentLanguage: null,
      editQuestion: null
    };
  },
  created: function created() {
    this.editQuestion = _.cloneDeep(this.editPageState.data.editQuestion);
    this.currentLanguage = _.cloneDeep(this.editPageState.data.currentLanguage);
    this.fetchCategoriesIfRequired();
  },
  methods: {
    cancel: function cancel() {
      this.$store.dispatch('surveyManagement/editPageStateInitial');
    },
    fetchCategoriesIfRequired: function fetchCategoriesIfRequired() {
      if (this.needsToLoadCategories) {
        this.$store.dispatch('surveyManagement/fetchCategories');
      }
    },
    previousStep: function previousStep() {
      if (['RATING_REASONS', 'CHOICE_ATTRIBUTES'].includes(this.editPageState.data.subpage)) {
        this.$store.dispatch('surveyManagement/editPageStateEditQuestion', {
          questionNr: this.editPageState.data.questionNr,
          question: this.editPageState.data.question,
          editQuestion: this.editPageState.data.editQuestion,
          currentLanguage: this.editPageState.data.currentLanguage,
          isNew: this.editPageState.data.isNew,
          anyLasIsFocused: this.editPageState.data.anyLasIsFocused,
          subpage: 'EDIT',
          showValidation: false
        });
      } else if (this.editPageState.data.subpage === 'EDIT' && this.editPageState.data.isNew) {
        this.$store.dispatch('surveyManagement/editPageStateAddCreateQuestion', {
          questionNr: this.editPageState.data.questionNr
        });
      } else {
        throw new Error('Invalid State: Cannot go back on this step.');
      }
    },
    nextStep: function nextStep() {
      var _this = this;
      var fields = getValidationFieldsPerSubpage(this.question.questionType)[this.editPageState.data.subpage];
      if (fields.every(function (item) {
        return _this.validation[item].valid;
      })) {
        if (this.editPageState.data.subpage === 'EDIT' && ['RATING_QUESTION_SMILEY', 'RATING_QUESTION_SLIDER'].includes(this.question.questionType)) {
          this.$store.dispatch('surveyManagement/editPageStateEditQuestion', {
            questionNr: this.editPageState.data.questionNr,
            question: this.editPageState.data.question,
            editQuestion: this.editPageState.data.editQuestion,
            currentLanguage: this.editPageState.data.currentLanguage,
            isNew: this.editPageState.data.isNew,
            anyLasIsFocused: this.editPageState.data.anyLasIsFocused,
            subpage: 'RATING_REASONS',
            showValidation: false
          });
        } else if (this.editPageState.data.subpage === 'EDIT' && ['CHOICE_QUESTION_SINGLE_CHOICE', 'CHOICE_QUESTION_MULTIPLE_CHOICE'].includes(this.question.questionType)) {
          this.$store.dispatch('surveyManagement/editPageStateEditQuestion', {
            questionNr: this.editPageState.data.questionNr,
            question: this.editPageState.data.question,
            editQuestion: this.editPageState.data.editQuestion,
            currentLanguage: this.editPageState.data.currentLanguage,
            isNew: this.editPageState.data.isNew,
            anyLasIsFocused: this.editPageState.data.anyLasIsFocused,
            subpage: 'CHOICE_ATTRIBUTES',
            showValidation: false
          });
        } else {
          this.$store.dispatch('surveyManagement/editPageStateSavingEdit', {
            questionNr: this.editPageState.data.questionNr,
            question: this.editPageState.data.question,
            editQuestion: this.editPageState.data.editQuestion,
            isNew: this.editPageState.data.isNew,
            anyLasIsFocused: this.editPageState.data.anyLasIsFocused,
            success: false,
            error: false
          });
        }
      } else {
        this.$store.dispatch('surveyManagement/editPageStateEditQuestion', {
          questionNr: this.editPageState.data.questionNr,
          question: this.editPageState.data.question,
          editQuestion: this.editPageState.data.editQuestion,
          currentLanguage: this.editPageState.data.currentLanguage,
          isNew: this.editPageState.data.isNew,
          anyLasIsFocused: this.editPageState.data.anyLasIsFocused,
          subpage: this.editPageState.data.subpage,
          showValidation: true
        });
      }
    },
    escapeHTML: FormatUtils.escapeHTML
  },
  watch: {
    editQuestion: {
      deep: true,
      handler: function handler() {
        if (this.editQuestion !== null && this.editQuestion.ratingReasonThreshold !== null && this.editQuestion.scale !== null) {
          if (this.editQuestion.scale < this.editQuestion.ratingReasonThreshold) {
            this.editQuestion.ratingReasonThreshold = this.editQuestion.scale;
          }
        }
        if (!_.isEqual(this.editPageState.data.editQuestion, this.editQuestion)) {
          this.$store.dispatch('surveyManagement/editPageStateEditQuestion', {
            questionNr: this.editPageState.data.questionNr,
            question: this.editPageState.data.question,
            editQuestion: _.cloneDeep(this.editQuestion),
            currentLanguage: this.editPageState.data.currentLanguage,
            isNew: this.editPageState.data.isNew,
            anyLasIsFocused: this.editPageState.data.anyLasIsFocused,
            subpage: this.editPageState.data.subpage,
            showValidation: false
          });
        }
      }
    },
    currentLanguage: function currentLanguage() {
      if (!_.isEqual(this.editPageState.data.currentLanguage, this.currentLanguage)) {
        this.$store.dispatch('surveyManagement/editPageStateEditQuestion', {
          questionNr: this.editPageState.data.questionNr,
          question: this.editPageState.data.question,
          editQuestion: this.editPageState.data.editQuestion,
          currentLanguage: this.currentLanguage,
          isNew: this.editPageState.data.isNew,
          anyLasIsFocused: this.editPageState.data.anyLasIsFocused,
          subpage: this.editPageState.data.subpage,
          showValidation: this.editPageState.data.showValidation
        });
      }
    },
    editPageState: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.editPageState.data.editQuestion, this.editQuestion)) {
          this.editQuestion = _.cloneDeep(this.editPageState.data.editQuestion);
        }
        if (!_.isEqual(this.editPageState.data.currentLanguage, this.currentLanguage)) {
          this.currentLanguage = _.cloneDeep(this.editPageState.data.currentLanguage);
        }
      }
    }
  }
};