//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PrintQuickStat from '@/components/print/report/PrintQuickStat';
export default {
  name: 'PrintQuickStatistics',
  components: {
    PrintQuickStat: PrintQuickStat
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    }
  }
};