import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PrintQuestion from '@/components/print/report/survey-analysis/PrintQuestion';
export default {
  name: 'PrintSurveyAnalysis',
  components: {
    PrintQuestion: PrintQuestion
  },
  props: {
    surveyAnalysisData: {
      type: Array,
      required: true
    },
    customAnswers: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    textAnswers: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    getCustomAttributes: function getCustomAttributes(surveyId, questionId, questionType) {
      var _this$customAnswers, _this$textAnswers;
      var customAnswers = (_this$customAnswers = this.customAnswers) === null || _this$customAnswers === void 0 ? void 0 : _this$customAnswers.find(function (customAnswer) {
        return customAnswer.surveyId === surveyId && customAnswer.questionId === questionId && customAnswer.questionType === questionType;
      });
      var textAnswers = (_this$textAnswers = this.textAnswers) === null || _this$textAnswers === void 0 ? void 0 : _this$textAnswers.find(function (textAnswer) {
        return textAnswer.surveyId === surveyId && textAnswer.questionId === questionId && textAnswer.questionType === questionType;
      });
      return (customAnswers === null || customAnswers === void 0 ? void 0 : customAnswers.customAttributes) || (textAnswers === null || textAnswers === void 0 ? void 0 : textAnswers.customAttributes);
    }
  }
};