import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import { getColorNameByIndex } from '@/services/ColorService';
import PrintAttributeDistributionChart from '@/components/print/report/survey-analysis/charts/PrintAttributeDistributionChart';
import PrintRatingDistributionChart from '@/components/print/report/survey-analysis/charts/PrintRatingDistributionChart';
import ScatterDistributionChart from '@/components/survey-analysis/charts/ScatterDistributionChart';
import ContactRequestIcon from '@/components/shared/ContactRequestIcon';
export default {
  name: 'PrintQuestion',
  components: {
    ContactRequestIcon: ContactRequestIcon,
    PrintAttributeDistributionChart: PrintAttributeDistributionChart,
    PrintRatingDistributionChart: PrintRatingDistributionChart,
    ScatterDistributionChart: ScatterDistributionChart
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    counter: {
      type: Number,
      required: true,
      validator: function validator(item) {
        return item > 0;
      }
    },
    surveyId: {
      type: Number,
      required: true
    },
    customAttributes: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    hasExpandedSection: function hasExpandedSection() {
      return ['RATING_QUESTION', 'CHOICE_QUESTION', 'NUMBER_QUESTION', 'DATE_QUESTION'].includes(this.question.questionType);
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    noAnswersInQuestion: function noAnswersInQuestion() {
      return this.question.answerCounter === 0;
    }
  },
  methods: {
    getColorNameByIndex: getColorNameByIndex,
    formatDateTime: function formatDateTime(timestamp) {
      return moment(timestamp).format('DD.MM.YYYY');
    }
  }
};