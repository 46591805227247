var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "web-source-table" },
    [
      _c("b-table", {
        attrs: {
          items: _vm.webSources,
          fields: _vm.fields,
          filter: _vm.filter,
          "filter-included-fields": ["description", "locations"],
          "show-empty": true,
          "empty-text": _vm.$t("emptyTableText", {
            status: _vm.$t("webSourceStatus." + _vm.webSourceStatus),
          }),
          "empty-filtered-text": _vm.$t("emptyTableText", {
            status: _vm.$t("webSourceStatus." + _vm.webSourceStatus),
          }),
          "sort-compare": _vm.sortCompare,
          "sort-by": "createdDate",
          hover: "",
          "no-sort-reset": "",
          responsive: "",
          striped: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(createdDate)",
            fn: function (data) {
              return [
                data.item.createdDate
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.formatDate(data.item.createdDate))),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(description)",
            fn: function (data) {
              return [
                data.item.description
                  ? _c("span", [_vm._v(_vm._s(data.item.description))])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(locations)",
            fn: function (data) {
              return [
                _c("location-group-table-entry", {
                  attrs: { items: _vm.parseLocations(data.item.locations) },
                }),
              ]
            },
          },
          {
            key: "cell(lastAnswerSetNotSpamWithCurrentLocations)",
            fn: function (data) {
              return [
                data.item.lastAnswerSetNotSpamWithCurrentLocations
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formatDate(
                              data.item.lastAnswerSetNotSpamWithCurrentLocations
                            )
                          ) +
                          "\n      "
                      ),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(link)",
            fn: function (data) {
              return [
                data.item.webSourceLink
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top.d200",
                            modifiers: { hover: true, top: true, d200: true },
                          },
                        ],
                        staticClass: "table-link overflow-ellipsis",
                        attrs: { title: _vm.$t("tooltip.copyLink") },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "clickable-link ",
                            on: {
                              click: function ($event) {
                                return _vm.copyLink(data.item)
                              },
                            },
                          },
                          [
                            _c("font-awesome", {
                              attrs: { icon: ["far", "clone"] },
                            }),
                            _vm._v(
                              "\n          " +
                                _vm._s(data.item.webSourceLink) +
                                "\n        "
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _c("span", [_vm._v("-")]),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex float-right" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "web-source-edit",
                            params: { source: data.item.id },
                          },
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "primary", size: "sm" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("editButton")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top.d200",
                            modifiers: { hover: true, top: true, d200: true },
                          },
                        ],
                        staticClass: "mr-1",
                        attrs: {
                          title: _vm.$t("tooltip.generateQRCode"),
                          variant: "primary",
                          size: "sm",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openQRCodeDialog(data.item)
                          },
                        },
                      },
                      [
                        _c("font-awesome", {
                          attrs: { icon: ["far", "qrcode"] },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isTableActive
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.disableWebSource(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("disableButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isTableInactive
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.enableWebSource(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("enableButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isTableInactive
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "danger", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteWebSource(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("deleteButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }