var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-edit" },
    [
      _c(
        "panel",
        { attrs: { "is-loading": _vm.isLoading && !_vm.isSubmitting } },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _vm.isCreation
                ? [_vm._v(_vm._s(_vm.$t("newUser")))]
                : [_vm._v(_vm._s(_vm.$t("editUser")))],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c("alert", {
                staticClass: "positioned-alert",
                attrs: {
                  show: _vm.successfullySaved && !_vm.isSubmitting,
                  icon: ["fas", "check"],
                  type: "success",
                  title: _vm.$t("success.title"),
                  message: _vm.$t(
                    _vm.isCreation
                      ? "success.createMessage"
                      : "success.editMessage"
                  ),
                },
              }),
              _vm._v(" "),
              _c("alert", {
                staticClass: "positioned-alert",
                attrs: {
                  show:
                    _vm.serverErrorOccurred &&
                    !_vm.isLoading &&
                    !_vm.isSubmitting,
                  icon: ["fas", "exclamation-triangle"],
                  type: "danger",
                  title: _vm.$t("serverError.title"),
                  message: _vm.$t("serverError.message"),
                },
              }),
              _vm._v(" "),
              _c("alert", {
                staticClass: "positioned-alert",
                attrs: {
                  show:
                    _vm.validationErrorOccurred &&
                    !_vm.isLoading &&
                    !_vm.isSubmitting,
                  icon: ["fas", "exclamation-triangle"],
                  type: "danger",
                  title: _vm.$t("validationError.title"),
                  message: _vm.$t("validationError.message"),
                },
              }),
              _vm._v(" "),
              ((!_vm.isLoading && _vm.formData) || _vm.isSubmitting) &&
              !_vm.serverErrorOccurred
                ? _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "col-3 col-form-label",
                              attrs: { for: "input-first-name" },
                            },
                            [_vm._v(_vm._s(_vm.$t("form.firstName")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-first-name",
                                  state: _vm.getFieldState("FIRST_NAME"),
                                  disabled: _vm.inputsDisabled,
                                },
                                model: {
                                  value: _vm.formData.firstName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "firstName", $$v)
                                  },
                                  expression: "formData.firstName",
                                },
                              }),
                              _vm._v(" "),
                              _c("validation-error-list", {
                                attrs: {
                                  errors: _vm.getFieldErrors("FIRST_NAME"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "col-3 col-form-label",
                              attrs: { for: "input-last-name" },
                            },
                            [_vm._v(_vm._s(_vm.$t("form.lastName")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-last-name",
                                  state: _vm.getFieldState("LAST_NAME"),
                                  disabled: _vm.inputsDisabled,
                                },
                                model: {
                                  value: _vm.formData.lastName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "lastName", $$v)
                                  },
                                  expression: "formData.lastName",
                                },
                              }),
                              _vm._v(" "),
                              _c("validation-error-list", {
                                attrs: {
                                  errors: _vm.getFieldErrors("LAST_NAME"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "col-3 col-form-label",
                              attrs: { for: "input-email" },
                            },
                            [_vm._v(_vm._s(_vm.$t("form.email")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-email",
                                  autocomplete: "new-password",
                                  state: _vm.getFieldState("EMAIL"),
                                  disabled:
                                    !_vm.isCreation || _vm.inputsDisabled,
                                },
                                model: {
                                  value: _vm.formData.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "email", $$v)
                                  },
                                  expression: "formData.email",
                                },
                              }),
                              _vm._v(" "),
                              _c("validation-error-list", {
                                attrs: { errors: _vm.getFieldErrors("EMAIL") },
                              }),
                              _vm._v(" "),
                              !_vm.showLogin &&
                              _vm.getFieldState("EMAIL") === null
                                ? [
                                    _c("validation-error-list", {
                                      attrs: {
                                        errors: _vm.getFieldErrors("LOGIN"),
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showLogin
                        ? _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "col-3 col-form-label",
                                  attrs: { for: "input-login" },
                                },
                                [_vm._v(_vm._s(_vm.$t("form.login")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "input-login",
                                      state: _vm.getFieldState("LOGIN"),
                                      disabled:
                                        !_vm.isCreation || _vm.inputsDisabled,
                                    },
                                    model: {
                                      value: _vm.formData.login,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "login", $$v)
                                      },
                                      expression: "formData.login",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("validation-error-list", {
                                    attrs: {
                                      errors: _vm.getFieldErrors("LOGIN"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "col-3 col-form-label",
                              attrs: { for: "input-login" },
                            },
                            [_vm._v(_vm._s(_vm.$t("form.role")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  disabled: _vm.isSelf || _vm.inputsDisabled,
                                  options: _vm.roles,
                                  state: _vm.getFieldState("AUTHORITIES"),
                                },
                                on: { input: _vm.roleChanged },
                                model: {
                                  value: _vm.formData.role,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "role", $$v)
                                  },
                                  expression: "formData.role",
                                },
                              }),
                              _vm._v(" "),
                              _c("validation-error-list", {
                                attrs: {
                                  errors: _vm.getFieldErrors("AUTHORITIES"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formData.role === "ROLE_MANAGER" && !_vm.isSelf
                        ? _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "col-3 col-form-label",
                                  attrs: { for: "input-locations" },
                                },
                                [_vm._v(_vm._s(_vm.$t("form.locations")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("location-group-select", {
                                    attrs: {
                                      id: "input-locations",
                                      "is-loading": false,
                                      "show-location-groups": true,
                                      "is-in-form": true,
                                      "pre-selected-ids": this.formData,
                                      state: _vm.getFieldState("LOCATIONS"),
                                      disabled: _vm.inputsDisabled,
                                      limit: 999,
                                    },
                                    on: {
                                      "change-locations": _vm.locationsChanged,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("validation-error-list", {
                                    attrs: {
                                      errors: _vm.getFieldErrors("LOCATIONS"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "col-3 col-form-label",
                              attrs: { for: "input-language" },
                            },
                            [_vm._v(_vm._s(_vm.$t("form.language")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.languages,
                                  state: _vm.getFieldState("LANGUAGE"),
                                  disabled: _vm.inputsDisabled,
                                },
                                model: {
                                  value: _vm.formData.language,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "language", $$v)
                                  },
                                  expression: "formData.language",
                                },
                              }),
                              _vm._v(" "),
                              _c("validation-error-list", {
                                attrs: {
                                  errors: _vm.getFieldErrors("LANGUAGE"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isCreation
                        ? _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "col-3 col-form-label",
                                  attrs: { for: "input-activated" },
                                },
                                [_vm._v(_vm._s(_vm.$t("form.activated")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      id: "input-activated",
                                      disabled:
                                        _vm.inputsDisabled || _vm.isSelf,
                                    },
                                    model: {
                                      value: _vm.formData.activated,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "activated", $$v)
                                      },
                                      expression: "formData.activated",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isCreation
                        ? _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "col-3 col-form-label",
                                  attrs: { for: "input-send-activation-email" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("form.sendActivationEmail"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c(
                                    "b-form-radio-group",
                                    {
                                      attrs: {
                                        id: "input-send-activation-email",
                                      },
                                      model: {
                                        value: _vm.formData.sendActivationEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "sendActivationEmail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData.sendActivationEmail",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-radio",
                                        {
                                          attrs: {
                                            value: true,
                                            disabled: _vm.inputsDisabled,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "form.sendActivationEmailRadio"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-radio",
                                        {
                                          attrs: {
                                            value: false,
                                            disabled: _vm.inputsDisabled,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("form.setPasswordRadio")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isCreation && !_vm.formData.sendActivationEmail
                        ? _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "col-3 col-form-label",
                                  attrs: { for: "input-password" },
                                },
                                [_vm._v(_vm._s(_vm.$t("form.password")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { cols: "9" } },
                                [
                                  _c(
                                    "b-input-group",
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "append",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "pos-rel" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "password-visibility-toggle increase-focus",
                                                        on: {
                                                          click:
                                                            _vm.togglePasswordVisibility,
                                                        },
                                                      },
                                                      [
                                                        _c("font-awesome", {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                !_vm.passwordVisible,
                                                              expression:
                                                                "!passwordVisible",
                                                            },
                                                          ],
                                                          attrs: {
                                                            icon: [
                                                              "far",
                                                              "eye",
                                                            ],
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("font-awesome", {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.passwordVisible,
                                                              expression:
                                                                "passwordVisible",
                                                            },
                                                          ],
                                                          attrs: {
                                                            icon: [
                                                              "far",
                                                              "eye-slash",
                                                            ],
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "password-generate",
                                                        attrs: {
                                                          disabled:
                                                            _vm.isGeneratingPassword ||
                                                            _vm.inputsDisabled,
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.generatePassword,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "form.generatePassword"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2513197410
                                      ),
                                    },
                                    [
                                      _c("b-form-input", {
                                        staticClass: "password-input",
                                        attrs: {
                                          id: "input-password",
                                          autocomplete: "new-password",
                                          type: _vm.passwordVisible
                                            ? "text"
                                            : "password",
                                          disabled:
                                            _vm.isGeneratingPassword ||
                                            _vm.inputsDisabled,
                                          state: _vm.getFieldState("PASSWORD"),
                                        },
                                        model: {
                                          value: _vm.formData.password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "formData.password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("validation-error-list", {
                                    attrs: {
                                      errors: _vm.getFieldErrors("PASSWORD"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "b-col",
                            [
                              !_vm.isCreation
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "float-right submit-button",
                                      attrs: {
                                        disabled:
                                          _vm.inputsDisabled ||
                                          _vm.isOriginalData,
                                        variant: "primary",
                                      },
                                      on: { click: _vm.saveChanges },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("form.save")))]
                                  )
                                : _c(
                                    "b-button",
                                    {
                                      staticClass: "float-right submit-button",
                                      attrs: {
                                        disabled: _vm.inputsDisabled,
                                        variant: "primary",
                                      },
                                      on: { click: _vm.createUser },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("form.create")))]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : !_vm.serverErrorOccurred
                ? _c("div", { staticClass: "spinner-wrapper" }, [
                    _c("div", { staticClass: "opn-spinner" }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isCreation &&
              (!_vm.isLoading || _vm.isSubmitting) &&
              _vm.formData !== null
                ? [
                    _c("hr"),
                    _vm._v(" "),
                    _c("span", { staticClass: "created-text" }, [
                      _c("i", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("form.userCreated", {
                              createdDate: _vm.formattedCreatedDate,
                              createdBy: _vm.rawData.createdBy,
                            })
                          )
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.isCreation
        ? _c("reset-password-panel", {
            attrs: {
              "user-id": _vm.userId,
              "user-activated": _vm.userIsActivated,
              disabled:
                _vm.isSelf || _vm.inputsDisabled || _vm.serverErrorOccurred,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }