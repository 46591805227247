var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-md-5" }, [
          _c("h1", { staticClass: "site-title" }, [
            _vm._v(_vm._s(_vm.$t("siteTitle"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-7" }, [
          _c("span", { staticClass: "float-right" }, [
            _c(
              "span",
              [
                _c("opn-date-range-picker", {
                  attrs: {
                    "is-loading": _vm.isLoading,
                    value: _vm.filter.dateRange,
                  },
                  on: { "change-daterange": _vm.dateRangeChanged },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("location-group-select", {
              attrs: {
                "is-loading": _vm.isLoading,
                "pre-selected-ids": _vm.filter.location,
                limit: 999,
              },
              on: { "change-locations": _vm.locationsChanged },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            !_vm.currentUserIsSuperAdmin
              ? _c("search-filter-selector", {
                  staticClass: "mt-n3",
                  attrs: {
                    "is-loading": _vm.isLoading,
                    "pre-selected-search-filter-id":
                      _vm.filter.location.selectedSearchFilterId,
                  },
                  on: { "change-locations": _vm.locationsChanged },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("clear-filter", { attrs: { "is-loading": _vm.isLoading } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.dashboardIsEmpty
        ? _c("empty-state")
        : _c(
            "div",
            [
              _c("div", { staticClass: "row mb-4" }, [
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("quick-stat", {
                      class: { "has-color-legend": _vm.hasIndexCalculation },
                      attrs: {
                        "show-additional-characters": "",
                        "has-index-coloration": _vm.hasIndexCalculation,
                        "is-loading": _vm.isLoading,
                        "is-percentage": !_vm.hasIndexCalculation,
                        "is-change-percentage": !_vm.hasIndexCalculation,
                        title: _vm.hasIndexCalculation
                          ? _vm.$t("overallAverageIndex")
                          : _vm.$t("overallSatisfaction"),
                        data: _vm.hasIndexCalculation
                          ? _vm.dashboard.quickStatistics.averageIndex
                          : _vm.dashboard.quickStatistics.satisfactionRate,
                        change: _vm.hasIndexCalculation
                          ? _vm.dashboard.quickStatistics.change
                              .averageIndexAbsolute
                          : _vm.dashboard.quickStatistics.change
                              .satisfactionRateAbsolute,
                        "date-range": _vm.filter.dateRange,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("quick-stat", {
                      attrs: {
                        "is-loading": _vm.isLoading,
                        "is-change-percentage": true,
                        title: _vm.$t("feedbacks"),
                        data: _vm.dashboard.quickStatistics.numberOfFeedbacks,
                        change:
                          _vm.dashboard.quickStatistics.change
                            .numberOfFeedbacksPercent,
                        "date-range": _vm.filter.dateRange,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "cursor-pointer",
                      on: { click: _vm.routeToFeedbackWithComments },
                    },
                    [
                      _c("quick-stat", {
                        attrs: {
                          hover: "",
                          "is-loading": _vm.isLoading,
                          "is-change-percentage": true,
                          title: _vm.$t("comments"),
                          data: _vm.dashboard.quickStatistics.numberOfComments,
                          change:
                            _vm.dashboard.quickStatistics.change
                              .numberOfCommentsPercent,
                          "date-range": _vm.filter.dateRange,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-4" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("location-list", {
                      attrs: {
                        "is-loading": _vm.isLoading,
                        title:
                          _vm.$t("locationList") +
                          (_vm.dashboard.performance.length > 5
                            ? " (" + _vm.$t("top5") + ")"
                            : ""),
                        data: _vm.dashboard.performance,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-4" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    !_vm.isLoading && !_vm.isWaitingForChartToRender
                      ? _c("change-over-time", {
                          attrs: {
                            data: _vm.dashboard.changeOverTime,
                            title: _vm.hasIndexCalculation
                              ? _vm.$t("changeOverTimeTitle")
                              : _vm.$t("changeOverTimeTitlePercent"),
                          },
                        })
                      : _c("change-over-time-loading"),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.$store.getters["general/hasIndexCalculation"]
                ? _c("color-legend", {
                    attrs: {
                      "is-loading": _vm.isLoading,
                      "company-properties":
                        _vm.$store.state.general.companyProperties,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }