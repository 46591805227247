var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "change-over-time-chart-legend",
      class: { small: _vm.small },
    },
    [
      _vm.useIndexCalculation
        ? _c(
            "div",
            {
              staticClass: "legend-label legend-label-satisfaction-score",
              class: { show: _vm.showLegend },
            },
            [
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.$t("satisfactionScore"))),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "legend-label legend-label-satisfaction-percent",
              class: { show: _vm.showLegend },
            },
            [
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.$t("satisfactionPercent"))),
              ]),
              _vm._v(" "),
              _vm._m(1),
            ]
          ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "legend-label legend-label-number",
          class: { show: _vm.showLegend },
        },
        [
          _c("div", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.$t("numberOfFeedbacks"))),
          ]),
          _vm._v(" "),
          _vm._m(2),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bar" }, [
      _c("span", { staticClass: "element red" }),
      _vm._v(" "),
      _c("span", { staticClass: "element orange" }),
      _vm._v(" "),
      _c("span", { staticClass: "element yellow" }),
      _vm._v(" "),
      _c("span", { staticClass: "element green-light" }),
      _vm._v(" "),
      _c("span", { staticClass: "element green" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bar" }, [
      _c("span", { staticClass: "element dark-blue" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bar" }, [
      _c("span", { staticClass: "element blue" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }