import _createForOfIteratorHelper from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { stringIsEmpty, stringIsEmptyOrContainsInvalidCharacters } from '@/services/utils/StringUtils';
import FormInput from '@/components/shared/form-input/FormInput';
import Store from '@/store/Store';
import _ from 'lodash';
export default {
  name: 'LanguageAwareStringInput',
  components: {
    FormInput: FormInput
  },
  props: {
    enabledLanguages: {
      type: Array,
      required: true,
      validator: function validator(items) {
        return items.length > 0 && items.every(function (item) {
          return Store.state.general.possibleLanguagesLas.includes(item);
        });
      }
    },
    currentLanguage: {
      type: String,
      required: true,
      validator: function validator(item) {
        return Store.state.general.possibleLanguagesLas.includes(item);
      }
    },
    las: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    optional: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    anyLasIsFocused: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      internalLas: null
    };
  },
  computed: {
    lasIsEmpty: function lasIsEmpty() {
      return this.las === null;
    }
  },
  methods: {
    stringIsEmpty: stringIsEmpty,
    stringIsEmptyOrContainsInvalidCharacters: stringIsEmptyOrContainsInvalidCharacters,
    setInternalLasFromParent: function setInternalLasFromParent() {
      var _this = this;
      this.internalLas = {};
      this.enabledLanguages.forEach(function (lang) {
        _this.$set(_this.internalLas, lang, '');
      });
      if (this.las !== null) {
        Object.keys(this.las).forEach(function (lang) {
          _this.$set(_this.internalLas, lang, _this.las[lang] !== null ? _this.las[lang] : '');
        });
      }
    },
    convertInternalLasToLas: function convertInternalLasToLas() {
      var _this2 = this;
      if (this.internalLas === null) {
        return null;
      }
      var internalLasKeys = Object.keys(this.internalLas).filter(function (lang) {
        return !_this2.stringIsEmpty(_this2.internalLas[lang]);
      });
      if (internalLasKeys.length === 0) {
        return null;
      }

      // Creates a deep copy here
      var newLas = {};
      internalLasKeys.forEach(function (language) {
        newLas[language] = _this2.internalLas[language];
      });
      return newLas;
    },
    onBlur: function onBlur() {
      this.$emit('setLasFocus', false);
    },
    clickChangeLanguage: function clickChangeLanguage(language) {
      if (!this.disabled) {
        this.$emit('change-language', language);
        this.$refs.input.$el.focus();
      }
    },
    getFirstInvalidLanguage: function getFirstInvalidLanguage() {
      if (this.optional && this.lasIsEmpty) {
        return null;
      }
      var _iterator = _createForOfIteratorHelper(this.enabledLanguages),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var language = _step.value;
          if (this.stringIsEmptyOrContainsInvalidCharacters(this.internalLas[language])) {
            return language;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return null;
    }
  },
  created: function created() {
    this.setInternalLasFromParent();
  },
  watch: {
    invalid: function invalid(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        if (!this.anyLasIsFocused) {
          this.clickChangeLanguage(this.getFirstInvalidLanguage());
          this.$emit('setLasFocus', true);
        }
      }
    },
    las: {
      deep: true,
      handler: function handler() {
        var internalLasMappedToLas = this.convertInternalLasToLas(this.internalLas);
        if (!_.isEqual(this.las, internalLasMappedToLas)) {
          this.setInternalLasFromParent();
        }
      }
    },
    internalLas: {
      deep: true,
      handler: function handler() {
        var internalLasMappedToLas = this.convertInternalLasToLas(this.internalLas);
        if (!_.isEqual(this.las, internalLasMappedToLas)) {
          this.$emit('change', internalLasMappedToLas);
        }
      }
    }
  }
};