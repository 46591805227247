import _objectSpread from "/builds/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import QueryService from '@/services/QueryService';
import QueryUtils from '@/services/utils/QueryUtils';
import { LocationPerformance as LocationResource } from '@/services/ResourceService';
import opnDateRangePicker from '@/components/shared/opnDateRangePicker';
import ClearFilter from '@/components/shared/ClearFilter';
import EmptyState from '@/components/shared/EmptyState';
import LocationGroupSelect from '@/components/shared/LocationGroupSelect';
import LocationPerformanceList from '@/components/locations/LocationPerformanceList';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import ColorLegend from '@/components/shared/ColorLegend';
import SearchFilterSelector from '@/components/shared/saved-search-filter/SearchFilterSelector';
import { mapState } from 'vuex';
export default {
  name: 'LocationPerformance',
  components: {
    SearchFilterSelector: SearchFilterSelector,
    opnDateRangePicker: opnDateRangePicker,
    LocationGroupSelect: LocationGroupSelect,
    LocationPerformanceList: LocationPerformanceList,
    ClearFilter: ClearFilter,
    EmptyState: EmptyState,
    ColorLegend: ColorLegend
  },
  data: function data() {
    return {
      locationPerformanceData: [],
      groupBy: 'location',
      isLoading: true,
      filter: {
        location: {},
        dateRange: []
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    currentUser: function currentUser(state) {
      return state.general.user;
    }
  })), {}, {
    currentUserIsSuperAdmin: function currentUserIsSuperAdmin() {
      return this.currentUser.authorities.includes('ROLE_SUPER_ADMIN');
    }
  }),
  watch: {
    $route: function $route() {
      this.initializePage();
    }
  },
  mounted: function mounted() {
    this.initializePage();
  },
  methods: {
    initializePage: function initializePage() {
      this.isLoading = true;
      var filter = QueryService.get('filter') || {};
      this.filter.dateRange = QueryUtils.getDateRangeFilter(filter.dateRange);
      this.filter.location = QueryUtils.getLocationFilter(filter.location);
      this.loadLocationPerformance();
    },
    loadLocationPerformance: function loadLocationPerformance() {
      var _this = this;
      this.isLoading = true;
      Promise.all([LocationResource.getLocationData(this.$store.state.general.companyId, this.filter.location.queryLocations, moment(this.filter.dateRange[0]).format('DD.MM.YYYY'), moment(this.filter.dateRange[1]).format('DD.MM.YYYY'), this.groupBy), CompanyPropertiesService.update()]).then(function (responses) {
        var response = responses[0];
        _this.isLoading = false;
        _this.locationPerformanceData = response.data;
      }).catch(function () {
        _this.isLoading = false;
        _this.locationPerformanceData = [];
      });
    },
    dateRangeChanged: function dateRangeChanged(dateRange) {
      this.filter.dateRange = dateRange;
      QueryService.put('filter', this.filter);
    },
    locationsChanged: function locationsChanged(locationFilter) {
      this.filter.location = locationFilter;
      QueryService.put('filter', this.filter);
    },
    groupByChanged: function groupByChanged(groupBy) {
      this.groupBy = groupBy;
      this.loadLocationPerformance();
    }
  }
};