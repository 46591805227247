//
//
//
//
//
//

export default {
  name: 'FilterCriteria',
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    variant: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function data() {
    return {};
  }
};