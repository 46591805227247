export default {
  overviewTable: {
    isLoading: false,
    hasError: false,
    activeSurveys: null,
    archivedSurveys: null,
    refreshDate: null
  },
  fullSurvey: {
    isLoading: false,
    hasError: false,
    data: null,
    refreshDate: null
  },
  categories: {
    isLoading: false,
    hasError: false,
    data: null,
    refreshDate: null
  },
  selectedSurveyId: null,
  editPageState: null
};