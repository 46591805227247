// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".print-h1,\n.print-h2,\n.print-h3 {\n  font-weight: 300;\n  margin-bottom: 20px;\n  position: relative;\n}\n.print-h1:before,\n  .print-h2:before,\n  .print-h3:before {\n    content: '';\n    display: block;\n    width: 100%;\n    height: 2px;\n    border-radius: 100px;\n    background: #e1e1e1;\n    position: absolute;\n    bottom: -4px;\n}\n.print-h1 {\n  font-size: 30px;\n}\n.print-h2 {\n  font-size: 24px;\n  margin-bottom: 24px;\n}\n.print-h2 .badge {\n    font-size: 9px;\n    display: block;\n    float: right;\n    padding-top: 3px;\n    -webkit-transform: translateY(9px);\n            transform: translateY(9px);\n    border: none;\n}\n.print-h3 {\n  font-size: 20px;\n  font-weight: 400;\n}\n.print-h3:before {\n    content: none;\n}\n.print-highlighted-blue {\n  color: #0363dc;\n  font-weight: 700;\n}\n.print-section {\n  margin-bottom: 40px;\n  page-break-inside: avoid;\n}\n.print-bordered-box {\n  padding: 12px 15px;\n  border-radius: 4px;\n  page-break-inside: avoid;\n  position: relative;\n}\n.print-bordered-box::before {\n    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.3) !important;\n            box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.3) !important;\n    border-radius: 4px;\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    -webkit-print-color-adjust: exact;\n    -webkit-filter: opacity(1);\n}\n.print-bordered-box.colored::before {\n    margin-left: -5px;\n}\n.print-bordered-box.colored-right::before {\n    margin-right: -5px;\n}\n@media print {\n.badge {\n    border: none !important;\n}\n}\n", ""]);
// Exports
exports.locals = {
	"green": "#67c892",
	"green-light": "#9ec867",
	"yellow": "#edc84c",
	"orange": "#e9ad6a",
	"red": "#e96a6a",
	"secondaryColor": "#30415d",
	"primaryColor": "#5dbfe2",
	"primaryColorDark": "#4886a5",
	"gray": "#a1a1a1"
};
module.exports = exports;
