//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ColorLegend',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    small: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  computed: {
    companyProperties: function companyProperties() {
      return this.$store.state.general.companyProperties;
    }
  }
};