import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import draggable from 'vuedraggable';
import Alert from '@/components/shared/Alert';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import SurveyManagementQuestionWrapper from '@/components/survey-management/edit-question/SurveyManagementQuestionWrapper';
import SurveyManagementBasicQuestion from '@/components/survey-management/edit-question/SurveyManagementBasicQuestion';
import SurveyManagementEditingQuestion from '@/components/survey-management/edit-question/SurveyManagementEditingQuestion';
import SurveyManagementNewQuestion from '@/components/survey-management/edit-question/SurveyManagementNewQuestion';
import SurveyManagementNewQuestionButton from '@/components/survey-management/edit-question/SurveyManagementNewQuestionButton';
import SurveyManagementSuccess from '@/components/survey-management/edit-question/SurveyManagementSuccess';
import { SurveyManagement } from '@/services/ResourceService';
export default {
  name: 'SurveyManagementEdit',
  components: {
    Alert: Alert,
    LoadingIndicator: LoadingIndicator,
    draggable: draggable,
    SurveyManagementQuestionWrapper: SurveyManagementQuestionWrapper,
    SurveyManagementBasicQuestion: SurveyManagementBasicQuestion,
    SurveyManagementEditingQuestion: SurveyManagementEditingQuestion,
    SurveyManagementNewQuestion: SurveyManagementNewQuestion,
    SurveyManagementNewQuestionButton: SurveyManagementNewQuestionButton,
    SurveyManagementSuccess: SurveyManagementSuccess
  },
  data: function data() {
    return {
      isDragging: false,
      isLoadingAfterSortingUpdate: false,
      hoveredQuestionWrapperIndex: null,
      recentlySortedItemIndex: null,
      sortableSurvey: [],
      errorOccurredAfterSorting: false
    };
  },
  created: function created() {
    var _this = this;
    this.$store.dispatch('surveyManagement/fetchFullSurvey').then(function () {
      if (_this.fullSurvey.data.questions.length > 0) {
        return _this.$store.dispatch('surveyManagement/editPageStateInitial');
      } else {
        return _this.$store.dispatch('surveyManagement/editPageStateAddCreateQuestion', {
          questionNr: 0
        });
      }
    });
  },
  watch: {
    fullSurvey: {
      deep: true,
      handler: function handler(newValue) {
        if (newValue.isLoading === false && newValue.hasError === false && newValue.data) {
          if (!_.isEqual(newValue, this.sortableSurvey)) {
            this.sortableSurvey = _.cloneDeep(newValue.data.questions);
          }
        }
      }
    }
  },
  computed: {
    fullSurvey: function fullSurvey() {
      return this.$store.state.surveyManagement.fullSurvey;
    },
    editPageState: function editPageState() {
      return this.$store.state.surveyManagement.editPageState || {};
    },
    isEditingExistingQuestion: function isEditingExistingQuestion() {
      return ['EDIT_QUESTION', 'SAVING_EDIT'].includes(this.editPageState.name);
    },
    isCreatingNewQuestion: function isCreatingNewQuestion() {
      if (['ADD_CREATE_QUESTION', 'SAVING_ADDING'].includes(this.editPageState.name)) {
        return true;
      }
      if (['EDIT_QUESTION', 'SAVING_EDIT'].includes(this.editPageState.name)) {
        if (this.editPageState.data.isNew) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    onStartDragging: function onStartDragging(event) {
      this.isDragging = true;
      this.recentlySortedItemIndex = null;
      var startLeft = event.item.getBoundingClientRect().left;
      var draggingElementMarginLeft = event.originalEvent.clientX - startLeft + -150 + 'px';
      event.srcElement.getElementsByClassName('sortable-item-dragging')[0].style.marginLeft = draggingElementMarginLeft;
    },
    onEndDragging: function onEndDragging(event) {
      this.isDragging = false;
      event.item.style.marginLeft = '0px';
    },
    onMouseOver: function onMouseOver(elementIndex) {
      // Don't update value while dragging a question
      if (!this.isDragging) {
        this.hoveredQuestionWrapperIndex = elementIndex;
      }
    },
    onMouseLeave: function onMouseLeave() {
      // Don't update value while dragging a question
      if (!this.isDragging) {
        this.hoveredQuestionWrapperIndex = null;
      }
    },
    onChange: function onChange(event) {
      var _this2 = this;
      if (event.moved) {
        var el = event.moved.element;
        var newIndex = event.moved.newIndex;
        this.recentlySortedItemIndex = newIndex;
        this.isLoadingAfterSortingUpdate = true;
        this.errorOccurredAfterSorting = false;

        // Make sure to update the animation duration in SurveyManagementQuestionWrapper when making changes here
        setTimeout(function () {
          _this2.recentlySortedItemIndex = null;
        }, 1000);
        SurveyManagement.sortQuestionInSurvey(this.$store.state.general.companyId, this.$store.state.surveyManagement.selectedSurveyId, {
          questionId: el.questionId,
          questionType: el.questionType,
          expectedQuestionNrZeroBased: newIndex
        }).then(this.handleSortSuccess, this.handleSortError);
      }
    },
    handleSortSuccess: function handleSortSuccess() {
      var _this3 = this;
      this.errorOccurredAfterSorting = false;
      this.$store.dispatch('surveyManagement/fetchFullSurvey').then(function () {
        _this3.isLoadingAfterSortingUpdate = false;
      });
    },
    handleSortError: function handleSortError() {
      var _this4 = this;
      this.errorOccurredAfterSorting = true;
      this.$store.dispatch('surveyManagement/fetchFullSurvey').then(function () {
        _this4.isLoadingAfterSortingUpdate = false;
      });
    }
  }
};