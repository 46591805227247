var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    { attrs: { "is-loading": false } },
    [
      _c("template", { slot: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("panelTitle")) + "\n  "),
      ]),
      _vm._v(" "),
      _c("template", { slot: "filter" }, [
        !_vm.chartDataIsEmpty
          ? _c("div", { staticClass: "controls float-right" }, [
              _c("div", { staticClass: "relevance-filter" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottom.d200",
                        modifiers: { hover: true, bottom: true, d200: true },
                      },
                    ],
                    staticClass: "filter-icon",
                    attrs: { title: _vm.$t("tooltip.relevanceFilter") },
                  },
                  [
                    _c("font-awesome", {
                      attrs: { icon: ["far", "info-circle"] },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.$t("relevanceFilter"))),
                    ]),
                    _vm._v(" "),
                    _c("b-form-checkbox", {
                      staticClass: "checkbox",
                      model: {
                        value: _vm.hasRelevanceFilter,
                        callback: function ($$v) {
                          _vm.hasRelevanceFilter = $$v
                        },
                        expression: "hasRelevanceFilter",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("error.title"),
              message: _vm.$t("error.message"),
              show: _vm.hasError && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _vm.isLoading && !_vm.hasError
            ? _c("div", { staticClass: "spinner-wrapper" }, [
                _c("div", { staticClass: "opn-spinner" }),
              ])
            : _vm.chartDataIsEmpty && !_vm.hasError
            ? _c("div", { staticClass: "no-data" }, [
                _c(
                  "div",
                  { staticClass: "no-data-text" },
                  [
                    _c("font-awesome", {
                      staticClass: "icon",
                      attrs: { icon: ["fas", "info-circle"] },
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("chartNotPossibleToDisplay")) +
                        "\n      "
                    ),
                  ],
                  1
                ),
              ])
            : !_vm.hasError
            ? _c("change-over-time-chart", {
                attrs: {
                  data: _vm.chartData,
                  "use-index-calculation": _vm.useIndexCalculation,
                  interval: "DAILY",
                  "has-relevance-filter": _vm.hasRelevanceFilter,
                  "height-in-pixel": 300,
                  small: false,
                  "goal-value": _vm.goalValue,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }