var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    { attrs: { "panel-title": _vm.$t("notifications"), "is-loading": false } },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _vm.initialLoading && !_vm.isSuperAdmin
            ? _c("div", { staticClass: "spinner-wrapper" }, [
                _c("div", { staticClass: "opn-spinner" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("error.title"),
              message: _vm.$t("error.message"),
              show: _vm.errorOccurred && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("fatalError.title"),
              message: _vm.$t("fatalError.message"),
              show: _vm.fatalErrorOccurred,
              closeable: false,
            },
          }),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "check"],
              type: "success",
              title: _vm.$t("success.title"),
              message: _vm.$t("success.message"),
              show: _vm.successfullySaved && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          !_vm.initialLoading && !_vm.fatalErrorOccurred && !_vm.isSuperAdmin
            ? _c(
                "div",
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "input-enabled" },
                              },
                              [_vm._v(_vm._s(_vm.$t("label.REGULAR.enabled")))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "7", xl: "9" } },
                            [
                              _c("b-form-checkbox", {
                                staticClass: "checkbox-blue checkbox-strong",
                                attrs: {
                                  disabled: _vm.isLoading,
                                  value: true,
                                  "unchecked-value": false,
                                },
                                model: {
                                  value: _vm.form.REGULAR.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.REGULAR, "enabled", $$v)
                                  },
                                  expression: "form.REGULAR.enabled",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "input-interval" },
                              },
                              [_vm._v(_vm._s(_vm.$t("label.REGULAR.interval")))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "7", xl: "9" } },
                            [
                              _c(
                                "b-form-select",
                                {
                                  attrs: {
                                    id: "input-interval",
                                    disabled:
                                      !_vm.form.REGULAR.enabled ||
                                      _vm.isLoading,
                                  },
                                  model: {
                                    value: _vm.form.REGULAR.interval,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.REGULAR,
                                        "interval",
                                        $$v
                                      )
                                    },
                                    expression: "form.REGULAR.interval",
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { value: "EVERY_WEEK" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("intervalOption.EVERY_WEEK")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "EVERY_DAY" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("intervalOption.EVERY_DAY")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "input-enabled" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("label.CONTACT_REQUEST.enabled")
                                  ) + "\n              "
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.d200",
                                        modifiers: { hover: true, d200: true },
                                      },
                                    ],
                                    staticClass: "info-circle",
                                    attrs: {
                                      title: _vm.$t("tooltip.contactRequest"),
                                    },
                                  },
                                  [
                                    _c("font-awesome", {
                                      attrs: { icon: ["far", "info-circle"] },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "7", xl: "9" } },
                            [
                              _c("b-form-checkbox", {
                                staticClass: "checkbox-blue checkbox-strong",
                                attrs: {
                                  disabled: _vm.isLoading,
                                  value: true,
                                  "unchecked-value": false,
                                },
                                model: {
                                  value: _vm.form.CONTACT_REQUEST.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.CONTACT_REQUEST,
                                      "enabled",
                                      $$v
                                    )
                                  },
                                  expression: "form.CONTACT_REQUEST.enabled",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: {
                            variant: "primary",
                            disabled: _vm.isLoading,
                          },
                          on: { click: _vm.saveInfo },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("saveInfo")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSuperAdmin
            ? _c(
                "div",
                [
                  _c("alert", {
                    staticClass: "positioned-alert",
                    attrs: {
                      icon: ["fas", "ban"],
                      type: "info",
                      title: _vm.$t("SUPERADMIN.title"),
                      message: _vm.$t("SUPERADMIN.message"),
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }