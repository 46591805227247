var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-6" }, [
        _c(
          "h1",
          { staticClass: "site-title" },
          [
            _vm.isOnEditPage &&
            !_vm.$store.state.surveyManagement.fullSurvey.isLoading &&
            !_vm.$store.state.surveyManagement.fullSurvey.hasError &&
            _vm.$store.state.surveyManagement.fullSurvey.data !== null
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$store.state.surveyManagement.fullSurvey.data
                          .surveyName
                      ) +
                      "\n          "
                  ),
                  _vm._l(
                    _vm.$store.state.surveyManagement.fullSurvey.data
                      .enabledLanguages,
                    function (lang) {
                      return _c(
                        "b-badge",
                        {
                          key: lang,
                          staticClass: "language-badge",
                          attrs: { variant: "info" },
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(lang) + "\n          "
                          ),
                        ]
                      )
                    }
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "edit-icon",
                      on: { click: _vm.showEditSurveyModal },
                    },
                    [
                      _c("font-awesome", {
                        staticClass: "icon",
                        attrs: { icon: ["far", "pencil"] },
                      }),
                    ],
                    1
                  ),
                ]
              : [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("siteTitle")) + "\n        "
                  ),
                ],
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "float-right" },
          [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [
                _vm.isInOverview
                  ? _c(
                      "b-button",
                      {
                        attrs: {
                          disabled: _vm.overviewIsLoading,
                          variant: "primary",
                        },
                        on: { click: _vm.showCreateSurveyModal },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("createSurvey")) +
                            "\n          "
                        ),
                      ]
                    )
                  : _c(
                      "b-button",
                      {
                        attrs: { variant: "secondary" },
                        on: { click: _vm.navigateToOverview },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("backToOverview")) +
                            "\n          "
                        ),
                      ]
                    ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }