var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-management-preview-rating-smiley" },
    [
      _vm.question.showCategoryInSurvey
        ? _c("div", { staticClass: "question-category" }, [
            _vm._v("\n    " + _vm._s(_vm.questionCategoryName) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "smiley-scale" },
        _vm._l(_vm.smileys, function (smiley) {
          return _c(
            "div",
            { key: smiley.rating, staticClass: "smiley", class: smiley.color },
            [_c(smiley.icon, { tag: "component", staticClass: "icon" })],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.question.ratingReasonThreshold > 0
        ? _c("survey-management-preview-rating-reasons", {
            attrs: { question: _vm.question },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }