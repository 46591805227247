import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getColorAndIcon } from '@/services/ColorService';
export default {
  name: 'PrintRatingDistribution',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    enrichedData: function enrichedData() {
      var _this = this;
      var distributionData = function () {
        var distributionData = [];
        for (var i = 1; i <= _this.data.scale; i++) {
          var colorAndIcon = getColorAndIcon(i, _this.data.scale);
          distributionData.push({
            colorName: colorAndIcon.color,
            distributionIndex: i,
            ratingPercent: 0,
            ratingAbsolute: 0
          });
        }
        return distributionData;
      }();
      distributionData.forEach(function (item) {
        if (_this.data.distribution.hasOwnProperty(item.distributionIndex)) {
          item.ratingPercent = _this.data.distribution[item.distributionIndex].percent;
          item.ratingAbsolute = _this.data.distribution[item.distributionIndex].absolute;
        }
      });
      return {
        scale: this.data.scale,
        distribution: distributionData
      };
    },
    maxAbsoluteValue: function maxAbsoluteValue() {
      return Math.max.apply(null, this.enrichedData.distribution.map(function (item) {
        return item.ratingAbsolute;
      }));
    },
    gridData: function gridData() {
      var maxPercentage = Math.max.apply(null, this.enrichedData.distribution.map(function (item) {
        return item.ratingPercent;
      }));
      return {
        bottom: '0%',
        middle: this.$options.filters.groupedNumber(maxPercentage / 2.0, true, 0) + '%',
        top: this.$options.filters.groupedNumber(maxPercentage, true, 0) + '%'
      };
    }
  }
};