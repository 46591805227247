import { render, staticRenderFns } from "./QuickStat.vue?vue&type=template&id=5f189326&scoped=true&"
import script from "./QuickStat.vue?vue&type=script&lang=js&"
export * from "./QuickStat.vue?vue&type=script&lang=js&"
import style0 from "./QuickStat.vue?vue&type=style&index=0&id=5f189326&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f189326",
  null
  
)

/* custom blocks */
import block0 from "./QuickStat.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f189326')) {
      api.createRecord('5f189326', component.options)
    } else {
      api.reload('5f189326', component.options)
    }
    module.hot.accept("./QuickStat.vue?vue&type=template&id=5f189326&scoped=true&", function () {
      api.rerender('5f189326', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/QuickStat.vue"
export default component.exports