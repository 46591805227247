import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var WEEKLY = 'WEEKLY';
var DAILY = 'DAILY';
var HOURLY = 'HOURLY';
var OPTIMAL_MINIMAL_DATAPOINTS = 12;
var OPTIMAL_MAXIMAL_DATAPOINTS = 100;
export var getDefaultSelectedInterval = function getDefaultSelectedInterval(changeOverTimeData) {
  if (changeOverTimeData === null || !changeOverTimeData[WEEKLY] || !changeOverTimeData[DAILY] || !changeOverTimeData[HOURLY]) {
    return null;
  }
  var weeklyDataCountIncludingEmptyOnes = changeOverTimeData[WEEKLY].data.length;
  var dailyDataCountIncludingEmptyOnes = changeOverTimeData[DAILY].data.length;
  var hourlyDataCountIncludingEmptyOnes = changeOverTimeData[HOURLY].data.length;
  var weeklyDataCount = changeOverTimeData[WEEKLY].data.filter(function (item) {
    return item.feedbacks > 0;
  }).length;
  var dailyDataCount = changeOverTimeData[DAILY].data.filter(function (item) {
    return item.feedbacks > 0;
  }).length;
  var hourlyDataCount = changeOverTimeData[HOURLY].data.filter(function (item) {
    return item.feedbacks > 0;
  }).length;
  if (dailyDataCount > 0 && dailyDataCountIncludingEmptyOnes >= OPTIMAL_MINIMAL_DATAPOINTS && dailyDataCountIncludingEmptyOnes <= OPTIMAL_MAXIMAL_DATAPOINTS) {
    return DAILY;
  }
  if (hourlyDataCount > 0 && hourlyDataCountIncludingEmptyOnes >= OPTIMAL_MINIMAL_DATAPOINTS && hourlyDataCountIncludingEmptyOnes <= OPTIMAL_MAXIMAL_DATAPOINTS) {
    return HOURLY;
  }
  if (weeklyDataCount > 0 && weeklyDataCountIncludingEmptyOnes >= OPTIMAL_MINIMAL_DATAPOINTS) {
    return WEEKLY;
  }
  if (dailyDataCount > 0) {
    return DAILY;
  }
  if (hourlyDataCount > 0) {
    return HOURLY;
  }
  if (weeklyDataCount > 0) {
    return WEEKLY;
  }

  // no data available..
  return null;
};