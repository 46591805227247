var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "question-preview" }, [
    _c("div", { staticClass: "content" }, [
      !_vm.hideCompanyLogo
        ? _c("div", { staticClass: "logo" }, [
            _c("img", { attrs: { src: _vm.companyLogo } }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "abstract-question" },
        [
          _c(
            "div",
            {
              staticClass: "question-text",
              class: {
                "question-category-shown":
                  _vm.editQuestion.showCategoryInSurvey,
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.lasPreviewTranslation(_vm.editQuestion.text)) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.questionType === "RATING_QUESTION_SMILEY"
            ? _c("survey-management-preview-rating-smiley", {
                attrs: {
                  question: _vm.editQuestion,
                  "question-category-name": _vm.lasPreviewTranslation(
                    _vm.questionCategoryName
                  ),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.questionType === "RATING_QUESTION_SLIDER"
            ? _c("survey-management-preview-rating-slider", {
                attrs: {
                  question: _vm.editQuestion,
                  "question-category-name": _vm.lasPreviewTranslation(
                    _vm.questionCategoryName
                  ),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.questionIsOfTextInputType
            ? _c("survey-management-preview-text-input-question", {
                attrs: {
                  question: _vm.editQuestion,
                  "question-type": _vm.questionType,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          [
            "CHOICE_QUESTION_SINGLE_CHOICE",
            "CHOICE_QUESTION_MULTIPLE_CHOICE",
          ].includes(_vm.questionType)
            ? _c("survey-management-preview-choice-question", {
                attrs: {
                  question: _vm.editQuestion,
                  "question-type": _vm.questionType,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "question-footer" }, [
        _c("div", { staticClass: "loading-line-wrapper" }, [
          _c("div", { staticClass: "text" }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.staticPreviewTranslation("questionOf", {
                    current: _vm.questionNr + 1,
                    total: _vm.numberOfQuestions,
                  })
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "loading-line" }, [
            _c("div", { staticClass: "line-wrapper" }, [
              _c("div", {
                staticClass: "line",
                style: {
                  transform: "translateX(" + (100 - this.progress) * -1 + "%)",
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }