var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "print-survey-analysis print-section" },
    [
      _c("div", { staticClass: "print-h2" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("title")) + "\n  "),
      ]),
      _vm._v(" "),
      _vm._l(_vm.surveyAnalysisData, function (survey) {
        return _c(
          "div",
          { key: survey.surveyId, staticClass: "print-survey" },
          [
            _c("div", { staticClass: "print-h3" }, [
              _vm._v(_vm._s(survey.surveyName)),
            ]),
            _vm._v(" "),
            _vm._l(survey.questions, function (q, index) {
              return _c(
                "div",
                {
                  key: q.questionType + "_" + q.questionId,
                  staticClass: "print-question",
                },
                [
                  _c("print-question", {
                    attrs: {
                      question: q,
                      "survey-id": survey.surveyId,
                      counter: index + 1,
                      "custom-attributes": _vm.getCustomAttributes(
                        survey.surveyId,
                        q.questionId,
                        q.questionType
                      ),
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }