var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "simple-quick-statistics" },
    [
      _c(
        "panel",
        {
          staticClass: "one-side-panel mb-4",
          class: [_vm.hasIndexCalculation ? "colored" : null, _vm.colorClass],
          attrs: {
            "panel-title": _vm.$t(
              _vm.hasIndexCalculation
                ? "titleSatisfactionScore"
                : "titleSatisfactionRateInPercent"
            ),
            "is-loading": _vm.isLoading,
          },
        },
        [
          _c("template", { slot: "content" }, [
            !_vm.isLoading
              ? _c(
                  "div",
                  [
                    _vm.hasIndexCalculation
                      ? [
                          _vm.simpleQuickStatistics.averageIndex !== null
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top.d200",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d200: true,
                                      },
                                    },
                                  ],
                                  staticClass: "value",
                                  attrs: {
                                    title: _vm.$options.filters.groupedNumber(
                                      _vm.simpleQuickStatistics.averageIndex,
                                      true,
                                      4
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("groupedNumber")(
                                          _vm.simpleQuickStatistics
                                            .averageIndex,
                                          true,
                                          1
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top.d200",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d200: true,
                                      },
                                    },
                                  ],
                                  staticClass: "value inactive",
                                  attrs: { title: _vm.$t("noDataAvailable") },
                                },
                                [_vm._v("\n            n/a\n          ")]
                              ),
                        ]
                      : [
                          _vm.simpleQuickStatistics.satisfactionRate !== null
                            ? _c(
                                "div",
                                {
                                  staticClass: "value",
                                  attrs: {
                                    title:
                                      _vm.simpleQuickStatistics
                                        .satisfactionRate + "%",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("groupedNumber")(
                                          _vm.simpleQuickStatistics
                                            .satisfactionRate,
                                          true,
                                          1
                                        )
                                      ) +
                                      "%\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.simpleQuickStatistics.satisfactionRate === null
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top.d200",
                                      modifiers: {
                                        hover: true,
                                        top: true,
                                        d200: true,
                                      },
                                    },
                                  ],
                                  staticClass: "value inactive",
                                  attrs: { title: _vm.$t("noDataAvailable") },
                                },
                                [_vm._v("\n            n/a\n          ")]
                              )
                            : _vm._e(),
                        ],
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "panel",
        {
          staticClass: "one-side-panel mb-4",
          attrs: {
            "panel-title": _vm.$t("titleNumberOfFeedbacks"),
            "is-loading": _vm.isLoading,
          },
        },
        [
          _c("template", { slot: "content" }, [
            _c("div", { staticClass: "value" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.simpleQuickStatistics.numberOfFeedbacks) +
                  "\n      "
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }