var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pos-rel", class: { "is-pdf": _vm.isPdf } },
    [
      _vm.showLegend
        ? _c("change-over-time-chart-legend", {
            attrs: {
              small: _vm.small,
              "use-index-calculation": _vm.useIndexCalculation,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        ref: "chart",
        staticClass: "change-over-time-chart",
        class: { small: _vm.small },
        style: { height: _vm.heightInPixel + "px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }