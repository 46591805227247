var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: _vm.fieldNames.DESCRIPTION },
            },
            [_vm._v(_vm._s(_vm.$t("labels.description")))]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("b-form-input", {
                attrs: {
                  id: _vm.fieldNames.DESCRIPTION,
                  state: _vm.getFieldState(_vm.fieldNames.DESCRIPTION),
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.formData[_vm.fieldNames.DESCRIPTION],
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, _vm.fieldNames.DESCRIPTION, $$v)
                  },
                  expression: "formData[fieldNames.DESCRIPTION]",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: {
                  errors: _vm.getFieldErrors(_vm.fieldNames.DESCRIPTION),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: _vm.fieldNames.LOCATIONS },
            },
            [_vm._v(_vm._s(_vm.$t("labels.locations")))]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("locations-group-select", {
                attrs: {
                  id: _vm.fieldNames.LOCATIONS,
                  "is-loading": false,
                  "show-location-groups": true,
                  "is-in-form": true,
                  "pre-selected-ids": _vm.preselectedIds,
                  state: _vm.getFieldState(_vm.fieldNames.LOCATIONS),
                  disabled: _vm.disabled,
                  "show-archived": true,
                },
                on: { "change-locations": _vm.locationsChanged },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors(_vm.fieldNames.LOCATIONS) },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: _vm.fieldNames.EMBEDDED_TYPE },
            },
            [_vm._v(_vm._s(_vm.$t("labels.role")))]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("b-form-select", {
                attrs: {
                  disabled: _vm.disabled,
                  options: _vm.embeddedTypes,
                  state: _vm.getFieldState(_vm.fieldNames.EMBEDDED_TYPE),
                },
                model: {
                  value: _vm.formData[_vm.fieldNames.EMBEDDED_TYPE],
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, _vm.fieldNames.EMBEDDED_TYPE, $$v)
                  },
                  expression: "formData[fieldNames.EMBEDDED_TYPE]",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: {
                  errors: _vm.getFieldErrors(_vm.fieldNames.EMBEDDED_TYPE),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: _vm.fieldNames.USE_RECAPTCHA },
            },
            [_vm._v(_vm._s(_vm.$t("labels.useReCaptcha")))]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  id: _vm.fieldNames.USE_RECAPTCHA,
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.formData[_vm.fieldNames.USE_RECAPTCHA],
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, _vm.fieldNames.USE_RECAPTCHA, $$v)
                  },
                  expression: "formData[fieldNames.USE_RECAPTCHA]",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasMultipleLocations ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.hasMultipleLocations
        ? _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "label",
                {
                  staticClass: "col-3 col-form-label",
                  attrs: { for: "input-default-language" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("labels.defaultLanguage")) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c("b-form-select", {
                    class: {
                      warning: !_vm.isLanguageInActiveSurvey(
                        _vm.formData[
                          _vm.fieldNames.LOCATION_PICKER.DEFAULT_LANGUAGE
                        ]
                      ),
                    },
                    attrs: {
                      id: "input-default-language",
                      options: _vm.languages,
                      disabled: _vm.disabled,
                      state: _vm.getFieldState(
                        _vm.fieldNames.LOCATION_PICKER.DEFAULT_LANGUAGE
                      ),
                    },
                    model: {
                      value:
                        _vm.formData[
                          _vm.fieldNames.LOCATION_PICKER.DEFAULT_LANGUAGE
                        ],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          _vm.fieldNames.LOCATION_PICKER.DEFAULT_LANGUAGE,
                          $$v
                        )
                      },
                      expression:
                        "formData[fieldNames.LOCATION_PICKER.DEFAULT_LANGUAGE]",
                    },
                  }),
                  _vm._v(" "),
                  _c("validation-error-list", {
                    attrs: {
                      errors: _vm.getFieldErrors(
                        _vm.fieldNames.LOCATION_PICKER.DEFAULT_LANGUAGE
                      ),
                    },
                  }),
                  _vm._v(" "),
                  !_vm.isLanguageInActiveSurvey(
                    _vm.formData[
                      _vm.fieldNames.LOCATION_PICKER.DEFAULT_LANGUAGE
                    ]
                  )
                    ? _c(
                        "div",
                        { staticClass: "warning-message" },
                        [
                          _c("font-awesome", {
                            staticClass: "exclamation-triangle",
                            attrs: { icon: ["far", "exclamation-triangle"] },
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t("defaultLanguageNotEnabledInWebSource")
                              ) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasMultipleLocations
        ? _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c(
                "label",
                {
                  staticClass: "col-3 col-form-label",
                  attrs: {
                    for: _vm.fieldNames.LOCATION_PICKER.LOCATION_PICKER_TEXT,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("labels.locationPickerText")))]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c("language-aware-string-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      las: _vm.formData[
                        _vm.fieldNames.LOCATION_PICKER.LOCATION_PICKER_TEXT
                      ],
                      "enabled-languages": _vm.enabledLanguages,
                      "current-language": _vm.internalCurrentLanguage,
                      invalid: _vm.hasValidationErrorsPickerText,
                    },
                    on: {
                      change: function ($event) {
                        _vm.formData[
                          _vm.fieldNames.LOCATION_PICKER.LOCATION_PICKER_TEXT
                        ] = $event
                      },
                      "change-language": _vm.changeLanguage,
                    },
                  }),
                  _vm._v(" "),
                  _c("validation-error-list", {
                    attrs: {
                      errors: _vm.getFieldErrors(
                        _vm.fieldNames.LOCATION_PICKER.LOCATION_PICKER_TEXT
                      ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasMultipleLocations ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.hasMultipleLocations
        ? _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "label",
                {
                  staticClass: "col-3 col-form-label",
                  attrs: {
                    for: _vm.fieldNames.LOCATION_PICKER
                      .LOCATION_GROUP_PICKER_ENABLED,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("labels.locationGroupPickerEnabled")))]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c("b-form-checkbox", {
                    attrs: {
                      id: _vm.fieldNames.LOCATION_PICKER
                        .LOCATION_GROUP_PICKER_ENABLED,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value:
                        _vm.formData[
                          _vm.fieldNames.LOCATION_PICKER
                            .LOCATION_GROUP_PICKER_ENABLED
                        ],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          _vm.fieldNames.LOCATION_PICKER
                            .LOCATION_GROUP_PICKER_ENABLED,
                          $$v
                        )
                      },
                      expression:
                        "\n          formData[fieldNames.LOCATION_PICKER.LOCATION_GROUP_PICKER_ENABLED]\n        ",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasMultipleLocations
        ? _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "label",
                {
                  staticClass: "col-3 col-form-label",
                  attrs: {
                    for: _vm.fieldNames.LOCATION_PICKER
                      .LOCATION_GROUP_PICKER_TEXT,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("labels.locationGroupPickerText")))]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c("language-aware-string-input", {
                    attrs: {
                      optional: true,
                      disabled:
                        _vm.disabled ||
                        !_vm.formData[
                          _vm.fieldNames.LOCATION_PICKER
                            .LOCATION_GROUP_PICKER_ENABLED
                        ],
                      las: _vm.formData[
                        _vm.fieldNames.LOCATION_PICKER
                          .LOCATION_GROUP_PICKER_TEXT
                      ],
                      "enabled-languages": _vm.enabledLanguages,
                      "current-language": _vm.internalCurrentLanguage,
                      invalid: _vm.hasValidationErrorsGroupPickerText,
                    },
                    on: {
                      change: function ($event) {
                        _vm.formData[
                          _vm.fieldNames.LOCATION_PICKER.LOCATION_GROUP_PICKER_TEXT
                        ] = $event
                      },
                      "change-language": _vm.changeLanguage,
                    },
                  }),
                  _vm._v(" "),
                  _c("validation-error-list", {
                    attrs: {
                      errors: _vm.getFieldErrors(
                        _vm.fieldNames.LOCATION_PICKER
                          .LOCATION_GROUP_PICKER_TEXT
                      ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasMultipleLocations ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm.hasMultipleLocations
        ? _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "label",
                {
                  staticClass: "col-3 col-form-label",
                  attrs: { for: _vm.fieldNames.LOCATION_PICKER.LOGO_DARK },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("labels.customLogoDark")) +
                      "\n      "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.d200",
                          modifiers: { hover: true, d200: true },
                        },
                      ],
                      staticClass: "info-circle-wrapper",
                      attrs: { title: _vm.$t("tooltip.customLogoDark") },
                    },
                    [
                      _c("font-awesome", {
                        staticClass: "info-circle",
                        attrs: { icon: ["far", "info-circle"] },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c("form-image-uploader", {
                    attrs: {
                      id: "input-custom-logo-dark",
                      accept: "image/png, image/svg+xml, image/jpeg",
                      disabled: _vm.disabled,
                      state: _vm.getFieldState(
                        _vm.fieldNames.LOCATION_PICKER.LOGO_DARK
                      ),
                    },
                    model: {
                      value:
                        _vm.formData[_vm.fieldNames.LOCATION_PICKER.LOGO_DARK],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          _vm.fieldNames.LOCATION_PICKER.LOGO_DARK,
                          $$v
                        )
                      },
                      expression:
                        "formData[fieldNames.LOCATION_PICKER.LOGO_DARK]",
                    },
                  }),
                  _vm._v(" "),
                  _c("validation-error-list", {
                    attrs: {
                      errors: _vm.getFieldErrors(
                        _vm.fieldNames.LOCATION_PICKER.LOGO_DARK
                      ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasMultipleLocations
        ? _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "label",
                {
                  staticClass: "col-3 col-form-label",
                  attrs: { for: _vm.fieldNames.LOCATION_PICKER.LOGO_BRIGHT },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("labels.customLogoBright")) +
                      "\n      "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.d200",
                          modifiers: { hover: true, d200: true },
                        },
                      ],
                      staticClass: "info-circle-wrapper",
                      attrs: { title: _vm.$t("tooltip.customLogoBright") },
                    },
                    [
                      _c("font-awesome", {
                        staticClass: "info-circle",
                        attrs: { icon: ["far", "info-circle"] },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c("form-image-uploader", {
                    attrs: {
                      id: _vm.fieldNames.LOCATION_PICKER.LOGO_BRIGHT,
                      accept: "image/png, image/svg+xml, image/jpeg",
                      state: _vm.getFieldState(
                        _vm.fieldNames.LOCATION_PICKER.LOGO_BRIGHT
                      ),
                    },
                    model: {
                      value:
                        _vm.formData[
                          _vm.fieldNames.LOCATION_PICKER.LOGO_BRIGHT
                        ],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          _vm.fieldNames.LOCATION_PICKER.LOGO_BRIGHT,
                          $$v
                        )
                      },
                      expression:
                        "formData[fieldNames.LOCATION_PICKER.LOGO_BRIGHT]",
                    },
                  }),
                  _vm._v(" "),
                  _c("validation-error-list", {
                    attrs: {
                      errors: _vm.getFieldErrors(
                        _vm.fieldNames.LOCATION_PICKER.LOGO_BRIGHT
                      ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasMultipleLocations
        ? _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "label",
                {
                  staticClass: "col-3 col-form-label",
                  attrs: {
                    for: _vm.fieldNames.LOCATION_PICKER.BACKGROUND_IMAGE,
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("labels.customBackgroundImage")) +
                      "\n      "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.d200",
                          modifiers: { hover: true, d200: true },
                        },
                      ],
                      staticClass: "info-circle-wrapper",
                      attrs: { title: _vm.$t("tooltip.backgroundImage") },
                    },
                    [
                      _c("font-awesome", {
                        staticClass: "info-circle",
                        attrs: { icon: ["far", "info-circle"] },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c("form-image-uploader", {
                    attrs: {
                      id: "input-custom-logo-dark",
                      accept: "image/png, image/svg+xml, image/jpeg",
                      disabled: _vm.disabled,
                      state: _vm.getFieldState(
                        _vm.fieldNames.LOCATION_PICKER.BACKGROUND_IMAGE
                      ),
                    },
                    model: {
                      value:
                        _vm.formData[
                          _vm.fieldNames.LOCATION_PICKER.BACKGROUND_IMAGE
                        ],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          _vm.fieldNames.LOCATION_PICKER.BACKGROUND_IMAGE,
                          $$v
                        )
                      },
                      expression:
                        "formData[fieldNames.LOCATION_PICKER.BACKGROUND_IMAGE]",
                    },
                  }),
                  _vm._v(" "),
                  _c("validation-error-list", {
                    attrs: {
                      errors: _vm.getFieldErrors(
                        _vm.fieldNames.LOCATION_PICKER.BACKGROUND_IMAGE
                      ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }