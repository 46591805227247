var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "font-awesome-layers",
    [
      _c("font-awesome", { attrs: { icon: ["fas", "envelope"] } }),
      _vm._v(" "),
      _c("font-awesome", {
        style: { color: _vm.circleColor },
        attrs: { icon: ["fas", "circle"], transform: "shrink-4 up-4 right-7" },
      }),
      _vm._v(" "),
      _c("font-awesome", {
        attrs: {
          icon: ["fas", "exclamation"],
          transform: "shrink-6 up-5 right-7",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }