//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getColorsAndIcons } from '@/services/ColorService';
import SurveyManagementPreviewRatingReasons from '@/components/survey-management/edit-question/question-preview/SurveyManagementPreviewRatingReasons';
export default {
  name: 'SurveyManagementPreviewRatingSmiley',
  components: {
    SurveyManagementPreviewRatingReasons: SurveyManagementPreviewRatingReasons
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    questionCategoryName: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    smileys: function smileys() {
      var colorsAndIcons = getColorsAndIcons(this.question.scale);
      if (this.question.scaleOrder === 'LEFT_HIGH_RIGHT_LOW') {
        return colorsAndIcons.reverse();
      }
      return colorsAndIcons;
    }
  }
};