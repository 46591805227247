var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoadingAfterSortingUpdate ||
    (!_vm.fullSurvey.isLoading &&
      !_vm.fullSurvey.hasError &&
      _vm.fullSurvey.data !== null)
    ? _c(
        "div",
        [
          _vm.fullSurvey.isLoading || _vm.isLoadingAfterSortingUpdate
            ? _c("div", { staticClass: "spinner-wrapper" }, [
                _c("div", { staticClass: "opn-spinner" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errorOccurredAfterSorting && !_vm.isLoadingAfterSortingUpdate
            ? _c("alert", {
                attrs: {
                  title: _vm.$t("errorSortingQuestion.title"),
                  message: _vm.$t("errorSortingQuestion.message"),
                  icon: ["fas", "times"],
                  type: "danger",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.sortableSurvey.length === 0
            ? _c(
                "survey-management-question-wrapper",
                {
                  attrs: {
                    "question-nr": 0,
                    "hide-number": false,
                    "is-first-question": true,
                    "is-last-question": true,
                    "is-new": true,
                    darkened: false,
                  },
                },
                [
                  _vm.editPageState.name === "ADD_CREATE_QUESTION" &&
                  _vm.editPageState.data.questionNr === 0
                    ? _c("survey-management-new-question", {
                        attrs: { "is-last": false },
                      })
                    : _vm.editPageState.name === "EDIT_QUESTION" &&
                      _vm.editPageState.data.isNew &&
                      _vm.editPageState.data.questionNr === 0
                    ? _c("survey-management-editing-question")
                    : ((_vm.editPageState.name === "SAVING_EDIT" &&
                        _vm.editPageState.data.isNew) ||
                        _vm.editPageState.name === "SAVING_ADDING") &&
                      _vm.editPageState.data.questionNr === 0
                    ? _c("survey-management-success")
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "draggable",
            {
              attrs: {
                disabled:
                  _vm.editPageState.name !== "INITIAL" ||
                  _vm.fullSurvey.isLoading ||
                  _vm.isLoadingAfterSortingUpdate ||
                  _vm.recentlySortedItemIndex !== null ||
                  _vm.sortableSurvey.length < 2,
                "force-fallback": true,
                swap: true,
                animation: 150,
                "swap-threshold": 1,
                "drag-class": "sortable-item-dragging",
                handle: ".survey-management-panel.survey-management-question",
              },
              on: {
                start: _vm.onStartDragging,
                end: _vm.onEndDragging,
                change: _vm.onChange,
              },
              model: {
                value: _vm.sortableSurvey,
                callback: function ($$v) {
                  _vm.sortableSurvey = $$v
                },
                expression: "sortableSurvey",
              },
            },
            _vm._l(
              _vm.sortableSurvey,
              function (question, existingQuestionIndex) {
                return _c(
                  "div",
                  {
                    key: question.questionType + "_" + question.questionId,
                    on: {
                      mouseover: function ($event) {
                        return _vm.onMouseOver(existingQuestionIndex)
                      },
                      mouseleave: function ($event) {
                        return _vm.onMouseLeave(existingQuestionIndex)
                      },
                    },
                  },
                  [
                    _c(
                      "survey-management-question-wrapper",
                      {
                        class: { "dragging-any-question": _vm.isDragging },
                        attrs: {
                          "question-nr":
                            _vm.isCreatingNewQuestion &&
                            _vm.editPageState.data.questionNr <=
                              existingQuestionIndex
                              ? existingQuestionIndex + 1
                              : existingQuestionIndex,
                          "is-first-question": existingQuestionIndex === 0,
                          "is-last-question": false,
                          darkened:
                            ["EDIT_QUESTION", "SAVING_EDIT"].includes(
                              _vm.editPageState.name
                            ) &&
                            !_vm.editPageState.data.isNew &&
                            _vm.editPageState.data.questionNr ===
                              existingQuestionIndex,
                          "was-recently-sorted":
                            existingQuestionIndex ===
                            _vm.recentlySortedItemIndex,
                        },
                      },
                      [
                        _vm.editPageState.name === "EDIT_QUESTION" &&
                        !_vm.editPageState.data.isNew &&
                        _vm.editPageState.data.questionNr ===
                          existingQuestionIndex
                          ? _c("survey-management-editing-question")
                          : _vm.editPageState.name === "SAVING_EDIT" &&
                            !_vm.editPageState.data.isNew &&
                            _vm.editPageState.data.questionNr ===
                              existingQuestionIndex
                          ? _c("survey-management-success")
                          : _c("survey-management-basic-question", {
                              attrs: {
                                question: question,
                                "question-nr":
                                  _vm.isCreatingNewQuestion &&
                                  _vm.editPageState.data.questionNr <=
                                    existingQuestionIndex
                                    ? existingQuestionIndex + 1
                                    : existingQuestionIndex,
                              },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.isDragging
                      ? _c(
                          "survey-management-question-wrapper",
                          {
                            attrs: {
                              "question-nr": existingQuestionIndex + 1,
                              "hide-number": !(
                                _vm.isCreatingNewQuestion &&
                                _vm.editPageState.data.questionNr ===
                                  existingQuestionIndex + 1
                              ),
                              "is-first-question": false,
                              "is-last-question":
                                existingQuestionIndex ===
                                _vm.sortableSurvey.length - 1,
                              "is-new": true,
                              darkened:
                                _vm.isCreatingNewQuestion &&
                                _vm.editPageState.data.questionNr ===
                                  existingQuestionIndex + 1,
                            },
                          },
                          [
                            _vm.editPageState.name === "ADD_CREATE_QUESTION" &&
                            _vm.editPageState.data.questionNr ===
                              existingQuestionIndex + 1
                              ? _c("survey-management-new-question", {
                                  attrs: {
                                    "is-last":
                                      existingQuestionIndex ===
                                      _vm.sortableSurvey.length - 1,
                                  },
                                })
                              : _vm.editPageState.name === "EDIT_QUESTION" &&
                                _vm.editPageState.data.isNew &&
                                _vm.editPageState.data.questionNr ===
                                  existingQuestionIndex + 1
                              ? _c("survey-management-editing-question")
                              : ((_vm.editPageState.name === "SAVING_EDIT" &&
                                  _vm.editPageState.data.isNew) ||
                                  _vm.editPageState.name === "SAVING_ADDING") &&
                                _vm.editPageState.data.questionNr ===
                                  existingQuestionIndex + 1
                              ? _c("survey-management-success")
                              : _c("survey-management-new-question-button", {
                                  attrs: {
                                    "question-nr": existingQuestionIndex + 1,
                                    "sibling-question-is-hovered":
                                      !_vm.isCreatingNewQuestion &&
                                      !_vm.isEditingExistingQuestion &&
                                      (_vm.hoveredQuestionWrapperIndex ===
                                        existingQuestionIndex ||
                                        _vm.hoveredQuestionWrapperIndex ===
                                          existingQuestionIndex + 1),
                                  },
                                }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }
            ),
            0
          ),
        ],
        1
      )
    : !_vm.fullSurvey.isLoading && _vm.fullSurvey.hasError
    ? _c("alert", {
        attrs: {
          icon: ["fas", "exclamation-triangle"],
          title: _vm.$t("errorLoadingSurvey.title"),
          message: _vm.$t("errorLoadingSurvey.message"),
          type: "danger",
        },
      })
    : _vm.fullSurvey.isLoading || _vm.isLoadingAfterSortingUpdate
    ? _c("loading-indicator")
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }