import state from '@/store/modules/general/state';
import getters from '@/store/modules/general/getters';
import mutations from '@/store/modules/general/mutations';
import actions from '@/store/modules/general/actions';
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};