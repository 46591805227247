import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModalIconButton',
  data: function data() {
    return {};
  },
  props: {
    icon: {
      type: Array,
      required: true
    },
    colorType: {
      type: String,
      required: false,
      default: ''
    },
    strikeThrough: {
      type: Boolean,
      required: false,
      default: false
    },
    index: {
      type: Number,
      required: false,
      default: 0
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    messageStrikeThrough: {
      type: String,
      required: false,
      default: ''
    },
    horizontalInBox: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltipPlacement: {
      type: String,
      required: false,
      default: 'top',
      validator: function validator(placement) {
        return ['top', 'right', 'bottom', 'left'].includes(placement);
      }
    }
  },
  computed: {
    finalMessage: function finalMessage() {
      if (!this.message) {
        return null;
      }
      if (this.message && !this.messageStrikeThrough) {
        return this.message;
      }
      if (this.message && this.messageStrikeThrough) {
        if (this.strikeThrough) {
          return this.messageStrikeThrough;
        } else {
          return this.message;
        }
      }
      return null;
    },
    styleObject: function styleObject() {
      var style = {};
      if (this.horizontalInBox === true) {
        style.right = this.index + 'em';
      } else {
        style.top = this.index + 'em';
      }
      return style;
    }
  },
  methods: {
    clickFunction: function clickFunction() {
      this.$root.$emit('bv::hide::tooltip'); // hides all tooltips
      this.$emit('click');
    }
  }
};