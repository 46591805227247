//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndexThresholdSettings from '@/components/company-settings/IndexThresholdSettings';
import CompanyStatistics from '@/components/company-settings/CompanyStatistics';
export default {
  name: 'CompanySettings',
  components: {
    IndexThresholdSettings: IndexThresholdSettings,
    CompanyStatistics: CompanyStatistics
  }
};