//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FormSelect',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    dropup: {
      type: Boolean,
      required: false,
      default: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};