import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect/src/Multiselect';
import Auth from '@/services/auth/Auth';
import Http from '@/config/Http';
import moment from 'moment';
import _ from 'lodash';
export default {
  name: 'Navbar',
  components: {
    Multiselect: Multiselect
  },
  data: function data() {
    return {
      users: [],
      companies: [],
      timeFormat: 'HH:mm:ss',
      currentTime: null
    };
  },
  computed: {
    user: function user() {
      return this.$store.state.general.user;
    },
    isGoalView: function isGoalView() {
      var r = this.$route;
      if (r.name === null) {
        return false;
      }
      var matchRoute = r.name.match(/goal-/);
      return matchRoute !== null && matchRoute.length > 0;
    },
    showUserSelector: function showUserSelector() {
      return this.isGoalView && this.currentUserIsSuperAdmin && this.users.length;
    },
    orderedUsers: function orderedUsers() {
      return _.orderBy(this.users, 'email');
    },
    defaultInstructionLink: function defaultInstructionLink() {
      var isGerman = this.$store.state.general.language === 'de';
      if (isGerman) {
        return 'https://www.jls.ch/opiniooon';
      } else {
        return 'https://www.jls.ch/en/opiniooon';
      }
    },
    currentUserIsSuperAdmin: function currentUserIsSuperAdmin() {
      return this.userHasRole(this.user, 'ROLE_SUPER_ADMIN');
    },
    selectedUser: function selectedUser() {
      return this.$store.state.general.selectedUser;
    },
    selectedCompanyId: function selectedCompanyId() {
      return this.$store.state.general.companyId;
    },
    selectedCompanyName: function selectedCompanyName() {
      var _this = this;
      var companyName = null;
      if (this.selectedCompanyId !== null && this.companies && this.companies.length > 0) {
        this.companies.forEach(function (company) {
          if (company.id === _this.selectedCompanyId) {
            companyName = company.name;
          }
        });
      }
      return companyName;
    },
    firstName: function firstName() {
      return this.user.firstName.length > 1 ? this.user.firstName : '';
    },
    lastName: function lastName() {
      return this.user.lastName.length > 1 ? this.user.lastName : '';
    },
    salutation: function salutation() {
      if (this.currentTime) {
        if (this.currentTime.isBetween(moment('00:00:00', this.timeFormat), moment('12:00:00', this.timeFormat))) {
          return this.$t('goodMorning');
        } else if (this.currentTime.isBetween(moment('11:59:59', this.timeFormat), moment('18:00:00', this.timeFormat))) {
          return this.$t('goodAfternoon');
        } else {
          return this.$t('goodEvening');
        }
      } else {
        return '';
      }
    },
    isCustomDomain: function isCustomDomain() {
      return this.$store.getters['general/isCustomDomain'];
    },
    customDomain: function customDomain() {
      return this.$store.getters['general/customDomain'];
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    if (this.currentUserIsSuperAdmin) {
      Http.get('/super-admin/navbar/companies').then(function (response) {
        _this2.companies = response.data;
      });
      Http.get('/super-admin/navbar/users').then(function (response) {
        _this2.users = response.data;
      });
    }
    this.updateCurrentTime();
    window.setInterval(this.updateCurrentTime, 1000 * 60 * 30);
  },
  destroyed: function destroyed() {
    window.clearInterval(this.updateCurrentTime);
  },
  methods: {
    userHasRole: function userHasRole(user, role) {
      return user.authorities.indexOf(role) > -1;
    },
    logout: function logout() {
      Auth.logout();
    },
    setSelectedUser: function setSelectedUser(user) {
      this.$store.commit('general/setSelectedUser', user);
      this.setCompanyId(user.companyId);
    },
    setCompanyId: function setCompanyId(companyOrCompanyId) {
      var companyId = typeof companyOrCompanyId === 'number' ? companyOrCompanyId : companyOrCompanyId.id;
      if (companyId === this.selectedCompanyId) {
        return;
      }
      this.$store.commit('general/setCompanyId', companyId);
      this.$store.commit('general/setComparison', null);
      if (this.selectedUser !== null && companyId !== this.selectedUser.companyId) {
        this.$store.commit('general/setSelectedUser', null);
      }
      if (this.$route.path !== '/dashboard' || this.$route.query && Object.keys(this.$route.query).length !== 0) {
        this.$router.replace({
          path: '/dashboard'
        });
      }
    },
    updateCurrentTime: function updateCurrentTime() {
      this.currentTime = moment(moment().format(this.timeFormat), this.timeFormat);
    }
  }
};