import "core-js/modules/es.number.is-integer.js";
//
//
//
//
//
//
//
//

import Store from '@/store/Store';
import FeedbackModal from '@/components/shared/FeedbackModal';
export default {
  name: 'PrintSingleFeedback',
  components: {
    FeedbackModal: FeedbackModal
  },
  props: {},
  data: function data() {
    return {
      answerSetId: null
    };
  },
  mounted: function mounted() {
    var receivedCompanyId = this.$route.query.companyId;
    var receivedAnswerSetId = this.$route.query.answerSetId;
    var receivedLanguage = this.$route.query.language;
    if (Number.isInteger(Number(receivedAnswerSetId)) && Number.isInteger(Number(receivedCompanyId))) {
      this.answerSetId = parseInt(receivedAnswerSetId, 10);
      Store.commit('general/setCompanyId', parseInt(receivedCompanyId, 10));
      Store.commit('general/changeLanguage', receivedLanguage);
    }
  },
  beforeRouteEnter: function beforeRouteEnter(_to, _from, next) {
    Store.commit('general/setPrintView', true);
    next();
  },
  beforeRouteLeave: function beforeRouteLeave(_to, _from, next) {
    Store.commit('general/setPrintView', false);
    next();
  }
};