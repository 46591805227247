var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "question-wrapper",
      class: {
        "first-question": _vm.isFirstQuestion,
        "last-question": _vm.isLastQuestion,
        "only-question": _vm.isOnlyQuestion,
        "question-margin": _vm.questionHasMargin,
      },
    },
    [
      !_vm.hideNumber
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.bottom.d200",
                  modifiers: { hover: true, bottom: true, d200: true },
                },
              ],
              staticClass: "number-wrapper",
              attrs: {
                title: _vm.$t("questionLong", {
                  number: _vm.questionNr + 1,
                  new: _vm.isNew ? " (" + _vm.$t("new") + ")" : null,
                }),
              },
            },
            [
              _vm.isNew
                ? _c("font-awesome", {
                    staticClass: "star",
                    attrs: { icon: ["fas", "star-of-life"] },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "number" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("questionShort", { number: _vm.questionNr + 1 })
                    ) +
                    "\n    "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "box",
          class: {
            darkened: _vm.darkened,
            "highlight-fade-animation": _vm.wasRecentlySorted,
          },
        },
        [_c("div", { staticClass: "box-content" }, [_vm._t("default")], 2)]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }