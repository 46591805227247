var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-survey" },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                _c("label", { staticClass: "form-label small-value" }, [
                  _vm._v(_vm._s(_vm.$t("label.dateRange"))),
                ]),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { sm: "7", xl: "9" } }, [
                _c(
                  "div",
                  [
                    _c("filter-criteria-date", {
                      attrs: {
                        "start-date": _vm.startDate,
                        "end-date": _vm.endDate,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                _c("label", { staticClass: "form-label small-value" }, [
                  _vm._v(_vm._s(_vm.$t("label.locations"))),
                ]),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { sm: "7", xl: "9" } }, [
                _c(
                  "div",
                  [
                    _c("filter-criteria-location", {
                      attrs: {
                        "location-ids": _vm.form.locations.queryLocations,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c("b-col", { attrs: { sm: "5", xl: "3" } }, [
                _c(
                  "label",
                  { staticClass: "form-label", attrs: { for: "input-survey" } },
                  [_vm._v(_vm._s(_vm.$t("label.survey")))]
                ),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { sm: "7", xl: "9" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "b-form-select",
                      {
                        attrs: {
                          id: "select-language",
                          disabled: _vm.isLoading,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$emit("change-form", _vm.form)
                          },
                        },
                        model: {
                          value: _vm.form.surveyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "surveyId", $$v)
                          },
                          expression: "form.surveyId",
                        },
                      },
                      _vm._l(_vm.possibleSurveys, function (possibleSurvey) {
                        return _c(
                          "option",
                          {
                            key: possibleSurvey.surveyId,
                            domProps: { value: possibleSurvey.surveyId },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(possibleSurvey.surveyName) +
                                "\n              " +
                                _vm._s(
                                  _vm.$t("feedbackCounter", {
                                    feedbacks: possibleSurvey.answerSetCounter,
                                  })
                                ) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "b-button",
            {
              staticClass: "prev-button",
              attrs: { variant: "outline-secondary", disabled: _vm.isLoading },
              on: {
                click: function ($event) {
                  return _vm.$emit("prev-step")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("prevStep")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "next-button",
              attrs: {
                variant: "primary",
                disabled: _vm.isLoading || _vm.form.surveyId === null,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("next-step")
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("nextStep")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }