var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "location-table" },
    [
      _c("b-table", {
        attrs: {
          items: _vm.locations,
          fields: _vm.fields,
          filter: _vm.filter,
          "filter-included-fields": ["name"],
          "show-empty": true,
          "empty-text": _vm.$t("emptyTableText"),
          "empty-filtered-text": _vm.$t("emptyTableText"),
          "sort-compare": _vm.sortCompare,
          "sort-by": "name",
          hover: "",
          "no-sort-reset": "",
          responsive: "",
          striped: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(name)",
            fn: function (data) {
              return [
                data.item.name
                  ? _c(
                      "span",
                      [
                        _vm.type === "active"
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "location-edit",
                                    params: { location: data.item.id },
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "clickable-link" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(data.item.name) +
                                      "\n          "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type === "archived"
                          ? [_vm._v(_vm._s(data.item.name))]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(surveyName)",
            fn: function (data) {
              return [
                data.item.surveyName
                  ? _c("span", [_vm._v(_vm._s(data.item.surveyName))])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(defaultLanguage)",
            fn: function (data) {
              return [
                !data.item.defaultLanguageEnabledInSurvey
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.d200",
                            modifiers: { hover: true, d200: true },
                          },
                        ],
                        staticClass: "warning",
                        attrs: {
                          title: _vm.$t("defaultLanguageNotEnabledInSurvey"),
                        },
                      },
                      [
                        _c("font-awesome", {
                          attrs: { icon: ["far", "exclamation-triangle"] },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(
                          "languages." + data.item.defaultLanguage.toLowerCase()
                        )
                      ) +
                      "\n      "
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(locationGroupName)",
            fn: function (data) {
              return [_c("span", [_vm._v(_vm._s(data.item.locationGroupName))])]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex float-right" },
                  [
                    _vm.type === "active"
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "location-edit",
                                params: { location: data.item.id },
                              },
                            },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mr-1",
                                attrs: { variant: "primary", size: "sm" },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("editButton")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "active"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.archiveLocation(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("archiveButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "archived"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.unarchiveLocation(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("unarchiveButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "archived"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "danger", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteLocation(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("deleteButton")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }