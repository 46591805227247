import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4langEn from '@amcharts/amcharts4/lang/en';
import am4langDeCh from '@amcharts/amcharts4/lang/de_CH';
import am4langFrFr from '@amcharts/amcharts4/lang/fr_FR';
import am4langItIt from '@amcharts/amcharts4/lang/it_IT';
import { getColorAndIcon } from '@/services/ColorService';
import colorVariables from '@/styles/_variables.scss';
import user from '@/assets/user.svg';
am4core.options.commercialLicense = true;
export default {
  name: 'RatingDistributionChart',
  props: {
    data: {
      type: Object,
      required: true
    },
    isPdf: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  computed: {
    enrichedData: function enrichedData() {
      var _this = this;
      var distributionData = function () {
        var distributionData = [];
        for (var i = 1; i <= _this.data.scale; i++) {
          var colorAndIcon = getColorAndIcon(i, _this.data.scale);
          distributionData.push({
            colorName: colorAndIcon.color,
            colorCode: am4core.color(colorVariables[colorAndIcon.color]),
            distributionIndex: i,
            ratingPercent: 0,
            ratingAbsolute: 0
          });
        }
        return distributionData;
      }();
      distributionData.forEach(function (item) {
        if (_this.data.distribution.hasOwnProperty(item.distributionIndex)) {
          item.ratingPercent = _this.data.distribution[item.distributionIndex].percent;
          item.ratingAbsolute = _this.data.distribution[item.distributionIndex].absolute;
        }
      });
      return {
        scale: this.data.scale,
        distribution: distributionData
      };
    }
  },
  methods: {
    createChart: function createChart(element, data, language, translatedStrings) {
      var maxValueInData = data.distribution.reduce(function (acc, cur) {
        return {
          ratingPercent: Math.max(acc.ratingPercent, cur.ratingPercent)
        };
      }).ratingPercent;
      var chart = am4core.create(element, am4charts.XYChart);
      chart.paddingBottom = 0;
      chart.paddingTop = 30;
      chart.paddingRight = 20;
      chart.tooltip.numberFormatter.numberFormat = '#,###.#';
      chart.data = data.distribution;
      chart.language.locale = function () {
        if (language === 'de') {
          return am4langDeCh;
        } else if (language === 'fr') {
          return am4langFrFr;
        } else if (language === 'it') {
          return am4langItIt;
        } else {
          return am4langEn;
        }
      }();
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'distributionIndex';
      categoryAxis.renderer.minGridDistance = this.isPdf ? 10 : 20;
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.dy = -3;
      categoryAxis.renderer.labels.template.fill = am4core.color('#666');
      categoryAxis.renderer.labels.template.fontSize = this.isPdf ? 10 : 12;
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      if (maxValueInData > 60) {
        valueAxis.max = Math.ceil(maxValueInData / 20) * 20;
      } else if (maxValueInData > 30) {
        valueAxis.max = Math.ceil(maxValueInData / 10) * 10;
      } else {
        valueAxis.max = Math.ceil(maxValueInData / 5) * 5;
      }
      valueAxis.strictMinMax = true;
      valueAxis.maxPrecision = 0;
      valueAxis.renderer.labels.template.fontSize = 10;
      valueAxis.renderer.grid.template.strokeOpacity = 1;
      valueAxis.renderer.grid.template.stroke = am4core.color('#e7e7e7');
      valueAxis.renderer.grid.template.strokeWidth = 1;
      valueAxis.renderer.minGridDistance = 20;
      valueAxis.numberFormatter = new am4core.NumberFormatter();
      valueAxis.numberFormatter.numberFormat = "#'%'";
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = 'ratingPercent';
      series.dataFields.categoryX = 'distributionIndex';
      series.columns.template.propertyFields.fill = 'colorCode';
      series.columns.template.fillOpacity = 0.75;
      series.columns.template.propertyFields.stroke = 'colorCode';
      series.columns.template.column.cornerRadiusTopLeft = 3;
      series.columns.template.column.cornerRadiusTopRight = 3;

      // IE11 fallback text
      series.columns.template.tooltipText = "[font-size:12px]".concat(translatedStrings.answer, ": [/][font-size:12px bold]{distributionIndex} / ").concat(data.scale, "[/]\n[font-size:12px]").concat(translatedStrings.distribution, ": [/][font-size:12px bold]{ratingPercent}%[/]\n[font-size:12px]").concat(translatedStrings.amount, ": [/][font-size:12px bold]{ratingAbsolute}[/]");
      series.columns.template.tooltipHTML = "\n        <div class=\"html-tooltip\">\n          <div class=\"distribution-index\">\n            <span class=\"before-slash\">".concat(translatedStrings.answer, " {distributionIndex}</span>\n            <span class=\"slash\">/</span>\n            <span class=\"after-slash\">").concat(data.scale, "</span>\n          </div>\n          <div>\n            <span class=\"percent\">\n              <span class=\"text\">{ratingPercent}%</span>\n            </span>\n            <span class=\"separator\">&ndash;</span>\n            <span class=\"absolute\">\n              <span class=\"text\">{ratingAbsolute}</span>\n              <img class=\"user-icon\" src=\"").concat(user, "\">\n            </span>\n          </div>\n        </div>");
      series.tooltip.getFillFromObject = false;
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.background.fill = am4core.color('#333');
      series.tooltip.background.strokeWidth = 0;
      series.tooltip.background.cornerRadius = 3;
      series.tooltip.background.pointerLength = 4;
      return chart;
    },
    initChart: function initChart() {
      this.chart = this.createChart(this.$refs.chart, this.enrichedData, this.$store.state.general.language, {
        answer: this.$t('answer'),
        distribution: this.$t('distribution'),
        amount: this.$t('amount')
      });
    },
    resetChart: function resetChart() {
      if (this.chart !== null) {
        this.chart.dispose();
        this.chart = null;
      }
    }
  },
  mounted: function mounted() {
    this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    this.resetChart();
  },
  watch: {
    data: {
      deep: true,
      handler: function handler(newData, oldData) {
        if (JSON.stringify(newData) !== JSON.stringify(oldData)) {
          this.resetChart();
          this.initChart();
        }
      }
    }
  }
};