//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import { getColorNameByIndex } from '@/services/ColorService';
export default {
  name: 'PrintSingleComment',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    satisfactionLabel: function satisfactionLabel() {
      if (this.data.isSatisfied === true) {
        return this.$t('satisfied');
      } else if (this.data.isSatisfied === false) {
        return this.$t('dissatisfied');
      } else {
        return this.$t('neutral');
      }
    },
    colorClass: function colorClass() {
      if (this.hasIndexCalculation) {
        return getColorNameByIndex(this.data.averageIndex, this.$store.state.general.companyProperties);
      } else {
        return {
          green: this.data.isSatisfied === true,
          red: this.data.isSatisfied === false,
          grey: this.data.isSatisfied === null
        };
      }
    }
  },
  methods: {
    getColorNameByIndex: getColorNameByIndex,
    formatDateTime: function formatDateTime(date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY HH:mm');
      }
    }
  }
};