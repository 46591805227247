import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
export default {
  parseValidationErrorsWithTranslations: function parseValidationErrorsWithTranslations(errors, vueComponent) {
    var validationErrors = {};
    errors.forEach(function (error) {
      error.fields.forEach(function (field) {
        if (!validationErrors[field] || validationErrors[field].length === 0) {
          validationErrors[field] = [];
        }
        if (typeof vueComponent !== 'undefined' && vueComponent !== null && vueComponent.$i18n !== null) {
          validationErrors[field].push({
            message: vueComponent.$t("errors.".concat(field, ".").concat(error.errorType), {
              payload: error.errorPayload
            })
          });
        }
      });
    });
    return validationErrors;
  },
  parseValidationErrors: function parseValidationErrors(errors) {
    var validationErrors = {};
    errors.forEach(function (error) {
      error.fields.forEach(function (field) {
        if (!validationErrors[field] || validationErrors[field].length === 0) {
          validationErrors[field] = [];
        }
        validationErrors[field].push({
          message: "errors.".concat(field, ".").concat(error.errorType),
          payload: error.errorPayload
        });
      });
    });
    return validationErrors;
  },
  getFieldState: function getFieldState(fieldName, validationErrors) {
    if (typeof validationErrors === 'undefined' || validationErrors === null) {
      return null;
    }
    if (validationErrors[fieldName] && validationErrors[fieldName].length > 0) {
      return false;
    }
    return null;
  },
  getFieldErrors: function getFieldErrors(fieldName, validationErrors) {
    if (typeof validationErrors === 'undefined' || validationErrors === null) {
      return null;
    }
    if (validationErrors[fieldName] && validationErrors[fieldName].length > 0) {
      return validationErrors[fieldName];
    }
    return null;
  }
};