import "core-js/modules/es.error.cause.js";
export default {
  compare: function compare(a, b) {
    var aIsNull = typeof a === 'undefined' || a === null;
    var bIsNull = typeof b === 'undefined' || b === null;
    if (a === b || aIsNull && bIsNull) {
      return 0;
    }
    if (!aIsNull && bIsNull || a > b) {
      return 1;
    }
    return -1;
  },
  compareStrings: function compareStrings(a, b) {
    var aIsNull = typeof a === 'undefined' || a === null;
    var bIsNull = typeof b === 'undefined' || b === null;
    if (a === b || aIsNull && bIsNull) {
      return 0;
    }
    if (!aIsNull) {
      return a.localeCompare(b);
    }
    return -b.localeCompare(a);
  },
  compareRoles: function compareRoles(firstRole, secondRole) {
    var hierarchy = ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_MANAGER'];
    var firstIndex = hierarchy.indexOf(firstRole);
    var secondIndex = hierarchy.indexOf(secondRole);
    if (hierarchy.indexOf(firstRole) === -1 || hierarchy.indexOf(secondRole) === -1) {
      throw new Error('Invalid role!');
    }
    return firstIndex - secondIndex;
  }
};