var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internalEditQuestion !== null &&
    _vm.internalCurrentLanguage !== null
    ? _c(
        "div",
        { staticClass: "survey-management-edit-rating-question" },
        [
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend",
              attrs: {
                label: _vm.$t("label.scale"),
                description: _vm.$t("description.scale"),
              },
            },
            [
              _c(
                "form-select",
                {
                  attrs: {
                    disabled:
                      !_vm.$store.state.surveyManagement.editPageState.data
                        .isNew,
                  },
                },
                [
                  _c("template", { slot: "active" }, [
                    _vm.internalEditQuestion.scale !== null
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("scaleOptions.generic", {
                                  number: _vm.internalEditQuestion.scale,
                                })
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.question.questionType === "RATING_QUESTION_SMILEY"
                      ? [3, 4, 5]
                      : [6, 7, 8, 9, 10],
                    function (scaleOption) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: scaleOption,
                          attrs: {
                            active:
                              _vm.internalEditQuestion.scale === scaleOption,
                          },
                          on: {
                            click: function ($event) {
                              _vm.internalEditQuestion.scale = scaleOption
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t("scaleOptions.generic", {
                                  number: scaleOption,
                                })
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend",
              attrs: {
                label: _vm.$t("label.category"),
                disabled:
                  _vm.$store.state.surveyManagement.categories.isLoading ||
                  _vm.$store.state.surveyManagement.categories.hasError,
                description: _vm.$t("description.category"),
              },
            },
            [
              _c(
                "form-select",
                {
                  attrs: {
                    disabled:
                      _vm.$store.state.surveyManagement.categories.isLoading ||
                      _vm.$store.state.surveyManagement.categories.hasError,
                    invalid:
                      _vm.editPageState.data.showValidation &&
                      !_vm.validation.categoryId.valid,
                  },
                },
                [
                  _c("template", { slot: "active" }, [
                    _vm.internalEditQuestion.categoryId !== null
                      ? _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$options.filters.languageAwareString(
                                _vm.currentlySelectedCategoryName
                              )
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.visibleInPoolSectorCategoriesWithQuestionCategory,
                    function (category) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: category.categoryId,
                          attrs: {
                            active:
                              category.categoryId ===
                              _vm.internalEditQuestion.categoryId,
                          },
                          on: {
                            click: function ($event) {
                              _vm.internalEditQuestion.categoryId =
                                category.categoryId
                            },
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$options.filters.languageAwareString(
                                  category.categoryName
                                )
                              ),
                            },
                          }),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend",
              attrs: {
                label: _vm.$t("label.showCategoryInSurvey"),
                description: !_vm.question
                  .isEditableWithoutSideEffectsToOtherSurveys
                  ? _vm.$t("description.onlyInThisSurvey")
                  : null,
              },
            },
            [
              _c(
                "form-select",
                {
                  attrs: {
                    invalid:
                      _vm.editPageState.data.showValidation &&
                      !_vm.validation.showCategoryInSurvey.valid,
                  },
                },
                [
                  _c("template", { slot: "active" }, [
                    _vm.internalEditQuestion.showCategoryInSurvey !== null
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "showCategoryInSurvey." +
                                    _vm.internalEditQuestion
                                      .showCategoryInSurvey
                                )
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._l([true, false], function (option) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: option + "",
                        attrs: {
                          active:
                            option ===
                            _vm.internalEditQuestion.showCategoryInSurvey,
                        },
                        on: {
                          click: function ($event) {
                            _vm.internalEditQuestion.showCategoryInSurvey =
                              option
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("showCategoryInSurvey." + option)) +
                            "\n      "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend",
              attrs: {
                label: _vm.$t("label.scaleOrder"),
                description: !_vm.question
                  .isEditableWithoutSideEffectsToOtherSurveys
                  ? _vm.$t("description.onlyInThisSurvey")
                  : null,
              },
            },
            [
              _c(
                "form-select",
                {
                  attrs: {
                    invalid:
                      _vm.editPageState.data.showValidation &&
                      !_vm.validation.scaleOrder.valid,
                  },
                },
                [
                  _c("template", { slot: "active" }, [
                    _vm.internalEditQuestion.scaleOrder !== null
                      ? _c(
                          "span",
                          [
                            _c("scale-order-icon", {
                              attrs: {
                                "scale-order":
                                  _vm.internalEditQuestion.scaleOrder,
                                scale: _vm.internalEditQuestion.scale,
                              },
                            }),
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "scaleOrder." +
                                      _vm.internalEditQuestion.scaleOrder
                                  )
                                ) +
                                "\n        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    ["LEFT_LOW_RIGHT_HIGH", "LEFT_HIGH_RIGHT_LOW"],
                    function (option) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: option,
                          staticClass: "scale-dropdown",
                          attrs: {
                            active:
                              option === _vm.internalEditQuestion.scaleOrder,
                          },
                          on: {
                            click: function ($event) {
                              _vm.internalEditQuestion.scaleOrder = option
                            },
                          },
                        },
                        [
                          _c("scale-order-icon", {
                            staticClass: "scale-order-icon",
                            attrs: {
                              "scale-order": option,
                              scale: _vm.internalEditQuestion.scale,
                            },
                          }),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("scaleOrder." + option)) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.question.questionType === "RATING_QUESTION_SLIDER"
            ? _c(
                "b-form-group",
                {
                  staticClass: "bold-uppercase-legend",
                  attrs: { label: _vm.$t("label.scaleLabelMin") },
                },
                [
                  _c("survey-management-las-wrapper", {
                    attrs: {
                      optional: true,
                      las: _vm.internalEditQuestion.scaleLabelMin,
                      "enabled-languages":
                        _vm.$store.state.surveyManagement.fullSurvey.data
                          .enabledLanguages,
                      "current-language": _vm.internalCurrentLanguage,
                      invalid:
                        _vm.editPageState.data.showValidation &&
                        !_vm.validation.scaleLabelMin.valid,
                    },
                    on: {
                      change: function ($event) {
                        _vm.internalEditQuestion.scaleLabelMin = $event
                      },
                      "change-language": function ($event) {
                        _vm.internalCurrentLanguage = $event
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.question.questionType === "RATING_QUESTION_SLIDER"
            ? _c(
                "b-form-group",
                {
                  staticClass: "bold-uppercase-legend",
                  attrs: { label: _vm.$t("label.scaleLabelMax") },
                },
                [
                  _c("survey-management-las-wrapper", {
                    attrs: {
                      optional: true,
                      las: _vm.internalEditQuestion.scaleLabelMax,
                      "enabled-languages":
                        _vm.$store.state.surveyManagement.fullSurvey.data
                          .enabledLanguages,
                      "current-language": _vm.internalCurrentLanguage,
                      invalid:
                        _vm.editPageState.data.showValidation &&
                        !_vm.validation.scaleLabelMax.valid,
                    },
                    on: {
                      change: function ($event) {
                        _vm.internalEditQuestion.scaleLabelMax = $event
                      },
                      "change-language": function ($event) {
                        _vm.internalCurrentLanguage = $event
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }