import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comparison } from '@/services/ResourceService';
import FilterCriteriaDate from '@/components/comparison/filter-criteria/FilterCriteriaDate';
import FilterCriteriaLocation from '@/components/comparison/filter-criteria/FilterCriteriaLocation';
import FilterCriteriaSurvey from '@/components/comparison/filter-criteria/FilterCriteriaSurvey';
import FilterCriteriaChoice from '@/components/comparison/filter-criteria/FilterCriteriaChoice';
import moment from 'moment';
import _ from 'lodash';
export default {
  name: 'FormFilters',
  components: {
    FilterCriteriaDate: FilterCriteriaDate,
    FilterCriteriaLocation: FilterCriteriaLocation,
    FilterCriteriaSurvey: FilterCriteriaSurvey,
    FilterCriteriaChoice: FilterCriteriaChoice
  },
  props: {
    parentForm: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      form: {},
      initialLoading: true,
      isLoading: true,
      possibleFilters: {},
      numberOfFeedbacks: 0
    };
  },
  computed: {
    startDate: function startDate() {
      return moment(this.form.startInSeconds).format('DD.MM.YYYY');
    },
    endDate: function endDate() {
      return moment(this.form.endInSeconds).format('DD.MM.YYYY');
    },
    surveyName: function surveyName() {
      if (this.initialLoading || !this.possibleFilters.surveyName) {
        return '';
      } else {
        return this.possibleFilters.surveyName;
      }
    },
    hasAnyFilter: function hasAnyFilter() {
      var filtersAvailable = this.possibleFilters.choiceQuestions && this.possibleFilters.choiceQuestions.length > 0;
      if (filtersAvailable) {
        var hasActiveFilter = false;
        this.possibleFilters.choiceQuestions.forEach(function (filter) {
          if (filter.activeInSelection) {
            hasActiveFilter = true;
          }
        });
        return hasActiveFilter;
      }
      return false;
    },
    possibleActiveChoiceQuestions: function possibleActiveChoiceQuestions() {
      if (!this.possibleFilters.choiceQuestions) {
        return [];
      }
      return this.possibleFilters.choiceQuestions.filter(function (cq) {
        return cq.activeInSelection;
      });
    }
  },
  created: function created() {
    this.initialize();
  },
  mounted: function mounted() {
    this.loadPossibleFilters();
    this.getNumberOfFeedbacksWithSelection();
  },
  methods: {
    initialize: function initialize() {
      this.form = _.cloneDeep(this.parentForm);
    },
    loadPossibleFilters: function loadPossibleFilters() {
      var _this = this;
      this.initialLoading = true;
      Comparison.getPossibleFiltersOfOneSurvey(this.$store.state.general.companyId, this.form.locations.queryLocations, this.form.surveyId, this.startDate, this.endDate).then(function (response) {
        _this.possibleFilters = response.data;
        _this.initialLoading = false;
      }, function () {
        _this.possibleFilters = {};
        _this.initialLoading = false;
      });
    },
    getNumberOfFeedbacksWithSelection: function getNumberOfFeedbacksWithSelection() {
      var _this2 = this;
      this.isLoading = true;
      Comparison.getNumberOfFeedbacksWithSelection(this.$store.state.general.companyId, this.form.locations.queryLocations, this.form.surveyId, this.form.choiceAttributeIds, this.startDate, this.endDate).then(function (response) {
        _this2.numberOfFeedbacks = response.data;
        _this2.isLoading = false;
      }, function () {
        _this2.numberOfFeedbacks = 0;
        _this2.isLoading = false;
      });
    },
    selectChoiceAttribute: function selectChoiceAttribute(choiceAttributeId) {
      if (this.form.choiceAttributeIds.indexOf(choiceAttributeId) === -1) {
        this.form.choiceAttributeIds.push(choiceAttributeId);
      } else {
        var tempAttributes = [];
        this.form.choiceAttributeIds.forEach(function (item) {
          if (item !== choiceAttributeId) {
            tempAttributes.push(item);
          }
        });
        this.form.choiceAttributeIds = tempAttributes;
      }
      this.$emit('change-form', this.form);
      this.getNumberOfFeedbacksWithSelection();
    }
  },
  watch: {
    parentForm: {
      deep: true,
      handler: function handler() {
        this.initialize();
      }
    }
  }
};