var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notification-dispatcher" }, [
    _c("div", { staticClass: "spinner-wrapper" }, [
      _c("div", { staticClass: "opn-spinner" }),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [
        _vm._v(_vm._s(_vm.$t("pleaseWait"))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }