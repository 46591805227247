var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-management-preview-rating-reasons" },
    [
      _c("div", { staticClass: "rating-reasons-title" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.lasPreviewTranslation(_vm.question.ratingReasonTitle)) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          staticClass: "rating-reasons-wrapper",
          attrs: { name: "rating-reasons-list", tag: "div" },
        },
        _vm._l(
          _vm.combinedRatingAndCustomRatingReasons,
          function (ratingReason, index) {
            return _c(
              "div",
              {
                key: ratingReason.id
                  ? ratingReason.type + "_" + ratingReason.id
                  : ratingReason.type + "_" + index,
                staticClass: "rating-reason",
                class: {
                  "custom-rating-reason": ratingReason.type === "CUSTOM",
                },
              },
              [
                _c("span", { staticClass: "checkbox" }),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.lasPreviewTranslation(ratingReason.text)) +
                    "\n\n      "
                ),
                ratingReason.type === "CUSTOM"
                  ? _c("survey-management-question-type-alpha-numeric-icon", {
                      staticClass: "custom-rating-reason-icon",
                      attrs: { type: "alpha" },
                    })
                  : _vm._e(),
              ],
              1
            )
          }
        ),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }