import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import ChangeOverTimeChart from '@/components/shared/change-over-time/ChangeOverTimeChart';
import { getDefaultSelectedInterval } from '@/services/ChangeOverTimeHelperService';
export default {
  name: 'ChangeOverTime',
  components: {
    Panel: Panel,
    ChangeOverTimeChart: ChangeOverTimeChart
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    heightInPixel: {
      type: Number,
      required: false,
      default: 300
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      hasRelevanceFilter: false,
      selectedInterval: null
    };
  },
  created: function created() {
    this.selectedInterval = getDefaultSelectedInterval(this.data);
  },
  computed: {
    availableIntervals: function availableIntervals() {
      var _this = this;
      var availableIntervals = [];
      if (this.data !== null) {
        // the array order is relevant
        ['HOURLY', 'DAILY', 'WEEKLY'].forEach(function (intervalName) {
          if (_this.data[intervalName].data.length > 0) {
            availableIntervals.push(intervalName);
          }
        });
      }
      return availableIntervals;
    }
  }
};