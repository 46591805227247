//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Auth from '@/services/auth/Auth';
import Alert from '@/components/shared/Alert';
import AuthView from '@/components/authentication/AuthView';
export default {
  name: 'PasswordRecovery',
  components: {
    Alert: Alert,
    AuthView: AuthView
  },
  data: function data() {
    return {
      inputEmail: '',
      submitting: false,
      errorOccurred: false,
      finished: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.submitting = true;
      this.errorOccurred = false;
      if (this.inputEmail === '') {
        this.submitting = false;
        this.$nextTick(function () {
          // toggle watch listener of alert box (when error message is manually closed,
          // it won't be shown again, if errorOccurred is changed in the same tick)
          _this.errorOccurred = true;
        });
      } else {
        Auth.passwordRecoveryInit(this.inputEmail).then(function () {
          _this.submitting = false;
          _this.errorOccurred = false;
          _this.finished = true;
        }).catch(function () {
          _this.submitting = false;
          _this.errorOccurred = true;
          _this.finished = false;
        });
      }
    }
  }
};