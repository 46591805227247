var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goal-details" }, [
    _c("div", { staticClass: "row mb-4" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("goal-detail-chart", {
            attrs: {
              "use-index-calculation":
                _vm.goalData.goalType === "AVERAGE_INDEX",
              "goal-value": _vm.goalData.value,
              "chart-data": _vm.chartData,
              "is-loading": _vm.isLoadingChart,
              "has-error": _vm.hasError,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("goal-detail-panel", {
            attrs: {
              goal: _vm.goalData,
              "is-loading": _vm.isLoading,
              "has-error": _vm.hasError,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }