var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid text-answers" },
    [
      _c(
        "transition-group",
        {
          attrs: { name: "text-answers-list", tag: "div" },
          on: { "after-leave": _vm.afterLeave },
        },
        _vm._l(_vm.answers, function (answer) {
          return _c(
            "div",
            { key: answer.answerId, staticClass: "row text-answer" },
            [
              _c("div", {
                staticClass: "col-12 answer",
                domProps: { innerHTML: _vm._s(answer.answerEscaped) },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6 timestamp" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.formatDateTime(answer.timestamp)) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-sm-6 full-feedback" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right mr-3",
                      attrs: { variant: "primary", size: "sm" },
                      on: {
                        click: function ($event) {
                          return _vm.openFeedbackModal(answer.answerSetId)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("showFeedback")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("text-answers-loading") : _vm._e(),
      _vm._v(" "),
      _c(
        "infinite-loading",
        {
          ref: "infiniteLoading",
          attrs: { identifier: _vm.infiniteScrollIdentifier },
          on: { infinite: _vm.onInfinite },
        },
        [
          _c("div", { attrs: { slot: "spinner" }, slot: "spinner" }),
          _vm._v(" "),
          _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
          _vm._v(" "),
          _c("div", { attrs: { slot: "no-results" }, slot: "no-results" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }