import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getColorAndIcon } from '@/services/ColorService';
import moment from 'moment';
import FormatUtils from '@/services/utils/FormatUtils';
export default {
  name: 'Question',
  props: {
    question: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    isPrintView: function isPrintView() {
      return this.$store.state.general.printView;
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    smileyIconAndColor: function smileyIconAndColor() {
      if (this.question.answer !== null && this.question.scale !== null) {
        return getColorAndIcon(this.question.answer, this.question.scale);
      }
      return null;
    },
    scaleTooltipText: function scaleTooltipText() {
      var answer = FormatUtils.escapeHTML(this.question.answer);
      var scale = FormatUtils.escapeHTML(this.question.scale);
      return "<b>".concat(answer, "</b> / ").concat(scale);
    },
    disabledQuestion: function disabledQuestion() {
      var defaultBehavior = ['RATING_QUESTION', 'COMMENT_QUESTION', 'EMAIL_QUESTION', 'TEXT_QUESTION', 'NUMBER_QUESTION', 'DATE_QUESTION', 'BARCODE_QUESTION'];
      if (defaultBehavior.indexOf(this.question.questionType) !== -1) {
        return this.question.answer === null;
      } else if (this.question.questionType === 'CHOICE_QUESTION') {
        return (this.question.choiceAttributes === null || this.question.choiceAttributes.length === 0) && (this.question.customChoiceAttributes === null || this.question.customChoiceAttributes.length === 0);
      } else {
        return false;
      }
    }
  },
  methods: {
    formatDate: function formatDate(date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY');
      }
    }
  }
};