import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FilterManagement',
  methods: {
    createFilter: function createFilter() {
      this.$router.push({
        name: 'filter-create'
      });
    },
    navigateToOverview: function navigateToOverview() {
      this.$router.push({
        name: 'filter-management'
      });
    }
  },
  data: function data() {
    return {
      routerKey: 0
    };
  },
  computed: {
    isInOverview: function isInOverview() {
      return this.$route.name === 'filter-management';
    }
  },
  watch: {
    $route: function $route(to, from) {
      // Reset component when going from filter creation to editing (to force props to update)
      if (from.name === 'filter-create' && to.name === 'filter-edit') {
        this.routerKey++;
      }
    }
  }
};