//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import SurveyManagementLasWrapper from '@/components/survey-management/edit-question/questions/SurveyManagementLasWrapper';
export default {
  name: 'SurveyManagementEditAttribute',
  components: {
    SurveyManagementLasWrapper: SurveyManagementLasWrapper
  },
  props: {
    las: {
      type: Object,
      required: false,
      default: null
    },
    currentLanguage: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledLas: {
      type: Boolean,
      required: false,
      default: false
    },
    optionalLas: {
      type: Boolean,
      required: false,
      default: false
    },
    hasDragging: {
      type: Boolean,
      required: false,
      default: true
    },
    index: {
      type: [Number, String],
      required: false,
      default: null
    },
    custom: {
      type: Boolean,
      required: false,
      default: false
    },
    deleted: {
      type: Boolean,
      required: false,
      default: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created: function created() {
    this.setInternalLas();
    this.setInternalCurrentLanguage();
  },
  data: function data() {
    return {
      internalLas: null,
      internalCurrentLanguage: null
    };
  },
  methods: {
    setInternalLas: function setInternalLas() {
      this.internalLas = this.cloneDeep(this.las);
    },
    setInternalCurrentLanguage: function setInternalCurrentLanguage() {
      this.internalCurrentLanguage = this.currentLanguage;
    },
    cloneDeep: _.cloneDeep
  },
  watch: {
    internalLas: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalLas, this.las)) {
          this.$emit('update-las', this.cloneDeep(this.internalLas));
        }
      }
    },
    las: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalLas, this.las)) {
          this.setInternalLas();
        }
      }
    },
    internalCurrentLanguage: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalCurrentLanguage, this.currentLanguage)) {
          this.$emit('change-language', this.internalCurrentLanguage);
        }
      }
    },
    currentLanguage: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalCurrentLanguage, this.currentLanguage)) {
          this.setInternalCurrentLanguage();
        }
      }
    }
  }
};