import _toConsumableArray from "/builds/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.array.filter.js";
export default {
  hasIndexCalculation: function hasIndexCalculation(state) {
    return state.companyProperties && state.companyProperties.calculationType === 'INDEX_CALCULATION';
  },
  isCustomDomain: function isCustomDomain(state) {
    return state.mainProperties !== null && state.mainProperties.isCustomDomain;
  },
  customDomain: function customDomain(state, getters) {
    if (getters.isCustomDomain) {
      return state.mainProperties.customDomain;
    }
    return null;
  },
  getLocationIdsByLocationGroupIds: function getLocationIdsByLocationGroupIds(state) {
    return function (locationGroupIds) {
      return _toConsumableArray(new Set(locationGroupIds.flatMap(function (id) {
        return state.allLocations.filter(function (l) {
          return l.locationGroup.id === id;
        });
      }).map(function (l) {
        return l.id;
      })));
    };
  }
};