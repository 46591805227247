import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//

import Flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de.js';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { Italian } from 'flatpickr/dist/l10n/it.js';
export default {
  name: 'DateInput',
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    optional: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    minDate: {
      type: String,
      required: false,
      default: '1000-01'
    },
    maxDate: {
      type: String,
      required: false,
      default: '9999-12'
    }
  },
  data: function data() {
    return {
      setValueWithoutEmitting: false,
      picker: null
    };
  },
  mounted: function mounted() {
    this.mountPicker();
  },
  methods: {
    mountPicker: function mountPicker() {
      var _this = this;
      this.picker = new Flatpickr(this.$refs.picker, {
        static: true,
        defaultDate: this.value,
        altInput: true,
        altFormat: 'j. F Y',
        dateFormat: 'Y-m-d',
        minDate: this.minDate,
        maxDate: this.maxDate,
        locale: function () {
          var lang = _this.$store.state.general.language.toUpperCase();
          if (lang === 'DE') {
            return German;
          }
          if (lang === 'FR') {
            return French;
          }
          if (lang === 'IT') {
            return Italian;
          }
          return null;
        }()
      });
      this.picker.config.onChange.push(function (event) {
        if (!_this.setValueWithoutEmitting) {
          if (event.length === 0 || event[0] === null) {
            _this.$emit('input', null);
          } else {
            _this.$emit('input', Flatpickr.formatDate(event[0], 'Y-m-d'));
          }
        }
      });
    },
    destroyPicker: function destroyPicker() {
      if (this.picker !== null) {
        this.picker.destroy();
        this.picker = null;
      }
    }
  },
  watch: {
    value: function value() {
      var _this2 = this;
      if (this.picker !== null) {
        this.setValueWithoutEmitting = true;
        this.picker.setDate(this.value);
        this.$nextTick(function () {
          _this2.setValueWithoutEmitting = false;
        });
      }
    },
    disabled: function disabled() {
      if (this.picker !== null) {
        this.picker.close();
        if (this.disabled) {
          this.picker._input.setAttribute('disabled', 'disabled');
        } else {
          this.picker._input.removeAttribute('disabled');
        }
      }
    },
    minDate: function minDate() {
      if (this.picker !== null) {
        this.picker.set('minDate', this.minDate);
      }
    },
    maxDate: function maxDate() {
      if (this.picker !== null) {
        this.picker.set('maxDate', this.maxDate);
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.destroyPicker();
  }
};