// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"green": "#67c892",
	"green-light": "#9ec867",
	"yellow": "#edc84c",
	"orange": "#e9ad6a",
	"red": "#e96a6a",
	"secondaryColor": "#30415d",
	"primaryColor": "#5dbfe2",
	"primaryColorDark": "#4886a5",
	"gray": "#a1a1a1"
};
module.exports = exports;
