var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "survey-management-panel",
    {
      staticClass: "survey-management-new-question",
      attrs: { padded: true },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c("font-awesome", { attrs: { icon: ["far", "plus"] } }),
              _vm._v("\n    " + _vm._s(_vm.$t("title")) + "\n  "),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("modal-icon-button", {
        attrs: {
          index: 0,
          icon: ["far", "times"],
          "color-type": "danger",
          message: _vm.$t("button.cancel"),
          "horizontal-in-box": true,
          disabled: _vm.initial || _vm.isLoading,
        },
        on: { click: _vm.cancel },
      }),
      _vm._v(" "),
      _c("alert", {
        staticClass: "positioned-alert",
        attrs: {
          icon: ["fas", "exclamation-triangle"],
          type: "danger",
          title: _vm.$t("error.title"),
          message: _vm.$t("error.message"),
          show: !_vm.isLoading && _vm.hasError,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "form" }, [
        _c(
          "div",
          { staticClass: "left-side" },
          [
            _c(
              "b-form-group",
              {
                staticClass: "bold-uppercase-legend",
                attrs: { label: _vm.$t("label.questionType") },
              },
              [
                _c(
                  "form-select",
                  { attrs: { disabled: _vm.isLoading, dropup: _vm.isLast } },
                  [
                    _c(
                      "template",
                      { slot: "active" },
                      [
                        _vm.selectedQuestionType !== null
                          ? [
                              _vm.selectedQuestionTypeOption.icon.fa
                                ? _c("font-awesome", {
                                    staticClass:
                                      "question-type-icon font-awesome-icon",
                                    attrs: {
                                      icon: _vm.selectedQuestionTypeOption.icon
                                        .fa,
                                      "fixed-width": "",
                                    },
                                  })
                                : _vm.selectedQuestionTypeOption.icon.component
                                ? _c(
                                    _vm.selectedQuestionTypeOption.icon
                                      .component,
                                    {
                                      tag: "component",
                                      staticClass:
                                        "question-type-icon component-icon",
                                      attrs: {
                                        type: _vm.selectedQuestionTypeOption
                                          .icon.componentType,
                                      },
                                    }
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "surveyManagementQuestionType." +
                                        _vm.selectedQuestionType
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("questionTypeOption.null")) +
                                  "\n            "
                              ),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.visibleQuestionTypeOptions,
                      function (questionTypeOption) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: questionTypeOption.value,
                            attrs: {
                              active:
                                questionTypeOption.value ===
                                _vm.selectedQuestionType,
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectedQuestionType =
                                  questionTypeOption.value
                              },
                            },
                          },
                          [
                            questionTypeOption.icon.fa
                              ? _c("font-awesome", {
                                  staticClass:
                                    "question-type-icon font-awesome-icon",
                                  attrs: {
                                    icon: questionTypeOption.icon.fa,
                                    "fixed-width": "",
                                  },
                                })
                              : questionTypeOption.icon.component
                              ? _c(questionTypeOption.icon.component, {
                                  tag: "component",
                                  staticClass:
                                    "question-type-icon component-icon",
                                  attrs: {
                                    type: questionTypeOption.icon.componentType,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(questionTypeOption.text) +
                                "\n          "
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right-side" },
          [
            _c(
              "b-form-group",
              {
                staticClass: "bold-uppercase-legend",
                attrs: { label: _vm.$t("label.applyQuestion") },
              },
              [
                _c(
                  "form-select",
                  {
                    attrs: {
                      disabled:
                        _vm.selectedQuestionType === null || _vm.isLoading,
                      dropup: _vm.isLast,
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "active" },
                      [
                        _vm.selectedQuestionId === null
                          ? [
                              _c("font-awesome", {
                                staticClass: "question-option-icon",
                                attrs: {
                                  icon: ["fal", "plus"],
                                  "fixed-width": "",
                                },
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("questionOption.null")) +
                                  "\n            "
                              ),
                            ]
                          : [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.languageAwareString(
                                      _vm.selectedQuestion.questionText
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _vm.selectedQuestion.hasDuplicatedText
                                ? [
                                    _vm._v(
                                      "[ID: " +
                                        _vm._s(
                                          _vm.selectedQuestion.questionId
                                        ) +
                                        "]"
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "form-select-item-group",
                      { attrs: { title: _vm.$t("optgroup.newQuestion") } },
                      [
                        _c(
                          "b-dropdown-item",
                          {
                            attrs: { active: _vm.selectedQuestionId === null },
                            on: {
                              click: function ($event) {
                                _vm.selectedQuestionId = null
                              },
                            },
                          },
                          [
                            _c("font-awesome", {
                              staticClass: "question-option-icon",
                              attrs: {
                                icon: ["fal", "plus"],
                                "fixed-width": "",
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("questionOption.null")) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.questionPool.length > 0
                      ? _c(
                          "form-select-item-group",
                          { attrs: { title: _vm.$t("optgroup.questionPool") } },
                          _vm._l(_vm.questionPool, function (question) {
                            return _c(
                              "b-dropdown-item",
                              {
                                key:
                                  question.questionType +
                                  "-" +
                                  question.questionId,
                                attrs: {
                                  active:
                                    _vm.selectedQuestionId !== null &&
                                    _vm.selectedQuestionId ===
                                      question.questionId,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.selectedQuestionId = question.questionId
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "existing-question-text" },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$options.filters.languageAwareString(
                                            question.questionText
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    question.hasDuplicatedText
                                      ? [
                                          _vm._v(
                                            "[ID: " +
                                              _vm._s(question.questionId) +
                                              "]"
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "existing-question-sub-text" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "feedback-count" },
                                      [
                                        _c("font-awesome", {
                                          staticClass: "answer-count-icon",
                                          attrs: { icon: ["fas", "user"] },
                                        }),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("feedbackCount", {
                                                feedbacks: question.answerCount,
                                              })
                                            ) +
                                            "\n                "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    question.surveys.length > 0
                                      ? _vm._l(
                                          question.surveys,
                                          function (survey, index) {
                                            return _c(
                                              "span",
                                              {
                                                key: survey.surveyId,
                                                staticClass: "survey",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(survey.surveyName)
                                                ),
                                                index !==
                                                question.surveys.length - 1
                                                  ? [_vm._v(",")]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "form-step-indicator",
        {
          staticClass: "form-step",
          attrs: { "step-index": 0, steps: _vm.pages },
        },
        [
          _c(
            "b-button",
            {
              attrs: {
                slot: "previous",
                variant: "outline-secondary",
                disabled: true,
              },
              slot: "previous",
            },
            [_vm._v(_vm._s(_vm.$t("button.back")))]
          ),
          _vm._v(" "),
          _vm.selectedQuestionId === null
            ? _c(
                "b-button",
                {
                  attrs: {
                    slot: "next",
                    variant: "primary",
                    disabled:
                      _vm.isLoading || _vm.selectedQuestionType === null,
                  },
                  on: { click: _vm.addNewQuestionToSurvey },
                  slot: "next",
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("button.create")) + "\n    "
                  ),
                ]
              )
            : _c(
                "b-button",
                {
                  attrs: {
                    slot: "next",
                    variant: "primary",
                    disabled:
                      _vm.isLoading || _vm.selectedQuestionType === null,
                  },
                  on: { click: _vm.addExistingQuestionToSurvey },
                  slot: "next",
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("button.add")) + "\n    ")]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }