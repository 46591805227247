import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { convertQuestionToEditQuestion, getInitialLanguage } from '@/services/SurveyManagementService';
import { getAllQuestionTypes } from '@/services/SurveyManagementUtilService';
import { SurveyManagement } from '@/services/ResourceService';
import ConfirmService from '@/services/ConfirmService';
import SurveyManagementPanel from '@/components/survey-management/SurveyManagementPanel';
import ModalIconButton from '@/components/shared/ModalIconButton';
export default {
  name: 'SurveyManagementBasicQuestion',
  components: {
    SurveyManagementPanel: SurveyManagementPanel,
    ModalIconButton: ModalIconButton
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    questionNr: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      allQuestionTypes: []
    };
  },
  created: function created() {
    this.allQuestionTypes = getAllQuestionTypes();
  },
  computed: {
    questionTypeIconText: function questionTypeIconText() {
      var _this = this;
      return this.allQuestionTypes.find(function (option) {
        return option.value === _this.question.questionType;
      });
    }
  },
  methods: {
    edit: function edit() {
      if (this.question.questionType === 'BARCODE_QUESTION') {
        this.showContactSupportModal();
        return;
      }
      this.$store.dispatch('surveyManagement/editPageStateEditQuestion', {
        questionNr: this.questionNr,
        question: this.question,
        editQuestion: convertQuestionToEditQuestion(this.question),
        currentLanguage: getInitialLanguage(this.$store.state.surveyManagement.fullSurvey.data.enabledLanguages, this.$store.state.general.language.toUpperCase()),
        isNew: false,
        anyLasIsFocused: false,
        subpage: 'EDIT',
        showValidation: false
      });
    },
    showContactSupportModal: function showContactSupportModal() {
      ConfirmService.createModal({
        name: 'contact-support-modal',
        type: 'info',
        title: this.$t('contactSupportModal.title'),
        icon: ['fas', 'info'],
        text: this.$t('contactSupportModal.text'),
        textIsHtml: true
      });
    },
    toggleQuestionActivation: function toggleQuestionActivation() {
      if (this.question.questionType === 'BARCODE_QUESTION') {
        this.showContactSupportModal();
        return;
      }
      SurveyManagement.toggleQuestionActivation(this.$store.state.general.companyId, this.$store.state.surveyManagement.selectedSurveyId, {
        questionId: this.question.questionId,
        questionType: this.question.questionType
      }).then(this.handleToggleQuestionActivationSuccess, this.handleToggleQuestionActivationError);
    },
    handleToggleQuestionActivationSuccess: function handleToggleQuestionActivationSuccess() {
      var _this2 = this;
      this.$store.dispatch('surveyManagement/fetchFullSurvey').then(function () {
        return _this2.$store.dispatch('surveyManagement/editPageStateInitial');
      });
    },
    handleToggleQuestionActivationError: function handleToggleQuestionActivationError() {
      var _this3 = this;
      // TODO: implement error handling (possibly in parent component on survey overview)
      this.$store.dispatch('surveyManagement/fetchFullSurvey').then(function () {
        return _this3.$store.dispatch('surveyManagement/editPageStateInitial');
      });
    }
  }
};