// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".confirm-header {\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  color: white;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  padding: 15px 20px;\n  font-size: 24px;\n  font-weight: 300;\n  border-top-left-radius: 2px;\n  border-top-right-radius: 2px;\n  background: #ccc;\n}\n.confirm-header.danger {\n    background: #ff5e5e;\n}\n.confirm-header.info {\n    background: #56b9ff;\n}\n.confirm-header.success {\n    background: #46d83f;\n}\n.confirm-header.warning {\n    background: #ffc15e;\n}\n.confirm-header.has-icon {\n    padding-left: 50px;\n}\n.confirm-header .font-awesome {\n    font-size: 78%;\n    position: absolute;\n    top: 50%;\n    left: 21px;\n    -webkit-transform: translateY(-50%);\n            transform: translateY(-50%);\n}\n", ""]);
// Exports
exports.locals = {
	"green": "#67c892",
	"green-light": "#9ec867",
	"yellow": "#edc84c",
	"orange": "#e9ad6a",
	"red": "#e96a6a",
	"secondaryColor": "#30415d",
	"primaryColor": "#5dbfe2",
	"primaryColorDark": "#4886a5",
	"gray": "#a1a1a1"
};
module.exports = exports;
