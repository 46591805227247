var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      attrs: {
        "panel-title": _vm.title,
        "is-loading": _vm.isLoading,
        "loading-min-height": 470,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.isLoading
              ? _c(
                  "div",
                  { key: "loading", staticClass: "loading-placeholder" },
                  [_c("div", { staticClass: "data w-75" })]
                )
              : _c(
                  "div",
                  {
                    key: "loaded",
                    staticClass: "content",
                    class: {
                      "has-not-index-calculation": !_vm.hasIndexCalculation,
                    },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.btnShowAllVisible,
                            expression: "btnShowAllVisible",
                          },
                        ],
                        staticClass: "btn-show-all",
                        attrs: { variant: "outline-secondary", size: "sm" },
                        on: { click: _vm.routeToAllLocations },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("showAllLocations")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row location-list-header" }, [
                      _c("div", { staticClass: "col-xs-6 col-md-3" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("locations")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xs-6 col-md-7" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.hasIndexCalculation
                                ? _vm.$t("averageIndex")
                                : _vm.$t("satisfactionInPercentage")
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-md-2 text-right" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("counter")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.locations, function (location) {
                      return _c(
                        "div",
                        { key: location.id, staticClass: "location row" },
                        [
                          _c("div", { staticClass: "col-xs-12 col-md-3" }, [
                            _c(
                              "div",
                              {
                                staticClass: "location-name cursor-pointer",
                                attrs: { title: location.name },
                                on: {
                                  click: function ($event) {
                                    return _vm.filterToLocation(location.id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(location.locationName) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.hasIndexCalculation
                              ? _c("div", { staticClass: "location-data" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("satisfied", [
                                          location.satisfactionCounter,
                                          location.satisfactionCounter +
                                            location.nonSatisfactionCounter,
                                        ])
                                      ) +
                                      "\n            "
                                  ),
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "location-group-name" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(location.locationGroupName) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-7 pt-4" },
                            [
                              _vm.hasIndexCalculation
                                ? _c("opn-progress", {
                                    attrs: {
                                      variant: _vm.getColorNameByIndex(
                                        location.averageIndex,
                                        _vm.$store.state.general
                                          .companyProperties
                                      ),
                                      precision: 1,
                                      "show-progress": "",
                                      animated: "",
                                    },
                                    model: {
                                      value: location.averageIndex,
                                      callback: function ($$v) {
                                        _vm.$set(location, "averageIndex", $$v)
                                      },
                                      expression: "location.averageIndex",
                                    },
                                  })
                                : _c("opn-progress", {
                                    attrs: {
                                      precision: 1,
                                      "show-progress": "",
                                      animated: "",
                                      "is-percentage": "",
                                    },
                                    model: {
                                      value: location.satisfactionRate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          location,
                                          "satisfactionRate",
                                          $$v
                                        )
                                      },
                                      expression: "location.satisfactionRate",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-md-2 text-right location-counter",
                            },
                            [
                              _c(
                                "small",
                                [
                                  _c("font-awesome", {
                                    attrs: { icon: ["fas", "user"] },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("groupedNumber")(
                                      location.totalCounter
                                    )
                                  ) +
                                  "\n            "
                              ),
                              _vm.hasIndexCalculation &&
                              location.countOfAllFeedbacksWithoutSatisfactionData >
                                0
                                ? _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.d200",
                                          modifiers: {
                                            hover: true,
                                            d200: true,
                                          },
                                        },
                                      ],
                                      staticClass:
                                        "location-counter-info-circle",
                                      attrs: {
                                        title: _vm.$t(
                                          location.countOfAllFeedbacksWithoutSatisfactionData >
                                            1
                                            ? "tooltip.notAllFeedbacksHaveSatisfactionPlural"
                                            : "tooltip.notAllFeedbacksHaveSatisfaction",
                                          [
                                            location.countOfAllFeedbacksWithoutSatisfactionData,
                                            location.totalCounter,
                                          ]
                                        ),
                                      },
                                    },
                                    [
                                      _c("font-awesome", {
                                        staticClass: "font-awesome",
                                        attrs: { icon: ["far", "info-circle"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }