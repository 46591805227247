//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Bus as VuedalsBus } from 'vuedals';
import Alert from '@/components/shared/Alert';
import SurveyManagementOverviewTable from '@/components/survey-management/SurveyManagementOverviewTable';
import SurveyManagementAssignModal from '@/components/survey-management/SurveyManagementAssignModal.vue';
import ConfirmService from '@/services/ConfirmService';
export default {
  name: 'SurveyManagementOverview',
  components: {
    Alert: Alert,
    SurveyManagementOverviewTable: SurveyManagementOverviewTable
  },
  data: function data() {
    return {
      filter: ''
    };
  },
  created: function created() {
    this.$store.dispatch('surveyManagement/fetchOverviewTable');
  },
  computed: {
    overviewTable: function overviewTable() {
      return this.$store.state.surveyManagement.overviewTable;
    }
  },
  methods: {
    archiveSurvey: function archiveSurvey(survey) {
      this.$store.dispatch('surveyManagement/archiveSurvey', {
        surveyId: survey.surveyId
      });
    },
    unarchiveSurvey: function unarchiveSurvey(survey) {
      this.$store.dispatch('surveyManagement/unarchiveSurvey', {
        surveyId: survey.surveyId
      });
    },
    deleteSurvey: function deleteSurvey(survey) {
      this.$store.dispatch('surveyManagement/deleteSurvey', {
        surveyId: survey.surveyId
      });
    },
    requestArchiveSurvey: function requestArchiveSurvey(survey) {
      var _this = this;
      ConfirmService.createModal({
        name: 'confirm-archive-survey',
        type: 'warning',
        title: this.$t('requestArchiveModal.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestArchiveModal.text', {
          surveyName: survey.surveyName
        }),
        textIsHtml: true,
        okayButton: this.$t('requestArchiveModal.okay'),
        dismissButton: this.$t('requestArchiveModal.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this.archiveSurvey(survey);
          }
        }
      });
    },
    requestDeleteSurvey: function requestDeleteSurvey(survey) {
      var _this2 = this;
      ConfirmService.createModal({
        name: 'confirm-delete-location',
        type: 'danger',
        title: this.$t('requestDeleteModal.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestDeleteModal.text', {
          surveyName: survey.surveyName
        }),
        textIsHtml: true,
        okayButton: this.$t('requestDeleteModal.okay'),
        dismissButton: this.$t('requestDeleteModal.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this2.deleteSurvey(survey);
          }
        }
      });
    },
    requestAssignSurvey: function requestAssignSurvey(survey) {
      VuedalsBus.$emit('new', {
        title: this.$t('assignModal.title', {}),
        name: 'SurveyManagementAssignModal',
        size: 'sm',
        props: {
          surveyId: survey.surveyId,
          surveyName: survey.surveyName
        },
        closeOnBackdrop: false,
        component: SurveyManagementAssignModal
      });
    }
  }
};