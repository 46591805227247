import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import Panel from '@/components/shared/Panel';
import moment from 'moment';
export default {
  name: 'GoalDetailPanel',
  components: {
    Alert: Alert,
    Panel: Panel
  },
  props: {
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    goal: {
      type: Object,
      required: true
    }
  },
  computed: {
    panelTitle: function panelTitle() {
      if (this.isLoading || this.goal === null || this.goal.title === null) {
        return this.$t('loadingTitle');
      }
      return this.goal.title;
    },
    formattedLocations: function formattedLocations() {
      if (this.isLoading || this.goal === null || this.goal.locations === null || this.goal.locations.length === 0) {
        return null;
      }
      return this.formatLocationOrLocationGroupList(this.goal.locations);
    },
    formattedLocationGroups: function formattedLocationGroups() {
      if (this.isLoading || this.goal === null || this.goal.locationGroups === null || this.goal.locationGroups.length === 0) {
        return null;
      }
      return this.formatLocationOrLocationGroupList(this.goal.locationGroups);
    }
  },
  methods: {
    editGoal: function editGoal() {
      this.$router.push({
        name: 'goal-edit',
        params: {
          goal: this.goal.id
        }
      });
    },
    formatDate: function formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('Do MMMM YYYY');
    },
    formatLocationOrLocationGroupList: function formatLocationOrLocationGroupList(list) {
      return list.map(function (l) {
        return l.name;
      }).join(', ');
    }
  }
};