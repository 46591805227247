var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "print-comment print-bordered-box" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "comments" },
        [
          _c("div", { staticClass: "index-indicator", class: _vm.colorClass }, [
            _vm.hasIndexCalculation && _vm.data.averageIndex !== null
              ? _c(
                  "span",
                  { staticClass: "average-index" },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["far", "smile"] },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("groupedNumber")(
                            _vm.data.averageIndex,
                            true,
                            1
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                )
              : _vm.hasIndexCalculation && _vm.data.averageIndex === null
              ? _c(
                  "span",
                  { staticClass: "no-average-index" },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["far", "smile"] },
                    }),
                  ],
                  1
                )
              : _c(
                  "span",
                  { staticClass: "satisfaction-label", class: _vm.colorClass },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.satisfactionLabel) +
                        "\n        "
                    ),
                  ]
                ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.data.commentAnswers, function (commentAnswer, index) {
            return _c("div", { staticClass: "comment" }, [
              _vm._v("\n        " + _vm._s(commentAnswer) + "\n        "),
              index + 1 !== _vm.data.commentAnswers.length
                ? _c("hr")
                : _vm._e(),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "comment-info" }, [
        _c(
          "span",
          { staticClass: "location-name" },
          [
            _c("font-awesome", {
              staticClass: "font-awesome",
              attrs: { icon: ["far", "map-marker-alt"] },
            }),
            _vm._v("\n        " + _vm._s(_vm.data.locationName) + "\n      "),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          [
            _c("font-awesome", {
              staticClass: "font-awesome",
              attrs: { icon: ["far", "calendar-alt"] },
            }),
            _vm._v(
              "\n        " +
                _vm._s(_vm.formatDateTime(_vm.data.timestamp)) +
                "\n      "
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }