var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-name" },
            },
            [_vm._v(_vm._s(_vm.$t("labels.name")))]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-name",
                  disabled: _vm.disabled,
                  placeholder: _vm.$t("placeholder.searchFilterName"),
                },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("NAME") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "label",
            {
              staticClass: "col-3 col-form-label",
              attrs: { for: "input-locations" },
            },
            [_vm._v(_vm._s(_vm.$t("labels.locationGroups")))]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c("locations-group-select", {
                attrs: {
                  id: "input-locations",
                  "is-loading": false,
                  "show-location-groups": true,
                  "is-in-form": true,
                  "pre-selected-ids": _vm.preselectedIds,
                  disabled: _vm.disabled,
                  limit: 999,
                },
                on: { "change-locations": _vm.locationsChanged },
              }),
              _vm._v(" "),
              _c("validation-error-list", {
                attrs: { errors: _vm.getFieldErrors("LOCATIONS_GROUPS") },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }