import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//

import NoUiSlider from '@/config/NoUiSlider';
export default {
  name: 'IndexThresholdSlider',
  props: {
    values: {
      type: Array,
      required: true,
      validator: function validator(values) {
        if (values.length === 4 && values.every(function (value) {
          return !isNaN(value);
        })) {
          for (var i = 0; i < values.length - 1; i++) {
            if (values[i] > values[i + 1]) {
              return false;
            }
          }
          if (values[0] < 0 || values[values.length - 1] > 100) {
            return false;
          }
          return true;
        }
        return false;
      }
    }
  },
  data: function data() {
    return {
      slider: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.slider = NoUiSlider.create(this.$refs.indexThresholdSlider, {
      start: this.values,
      orientation: 'horizontal',
      connect: [true, true, true, true, true],
      tooltips: true,
      behaviour: 'tap-drag',
      step: 1,
      margin: 1,
      // min margin between two handles
      padding: 1,
      // min margin to corner
      format: {
        to: function to(value) {
          return Math.round(value) + '';
        },
        from: function from(value) {
          return window.parseInt(value, 10);
        }
      },
      range: {
        min: 0,
        max: 100
      },
      pips: {
        mode: 'steps',
        density: 3,
        filter: function filter(value) {
          if (value === 0 || value === 100) {
            return 1;
          }
          if (value % 10 === 0) {
            return 1;
          }
          if (value % 5 === 0) {
            return 2;
          }
          return 0;
        }
      }
    });
    this.slider.on('set', function (values) {
      var mappedValues = values.map(function (item) {
        return window.parseInt(item, 10);
      });
      _this.$emit('input', mappedValues);
    });
  },
  watch: {
    values: {
      deep: true,
      handler: function handler() {
        if (this.slider !== null) {
          var currentValues = this.slider.get().map(function (value) {
            return window.parseInt(value, 10);
          });
          var hasChange = false;
          for (var i = 0; i < currentValues.length; i++) {
            if (currentValues[i] !== this.values[i]) {
              hasChange = true;
              break;
            }
          }
          if (hasChange) {
            this.slider.set(this.values);
          }
        }
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.slider !== null) {
      this.slider.destroy();
      this.slider = null;
    }
  }
};