var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    { attrs: { "panel-title": _vm.$t("changePassword"), "is-loading": false } },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("serverError.title"),
              message: _vm.$t("serverError.message"),
              show: _vm.serverErrorOccurred && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "check"],
              type: "success",
              title: _vm.$t("success.title"),
              message: _vm.$t("success.message"),
              show: _vm.successfullySaved && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("validationError.title"),
              message: _vm.$t("validationError.message"),
              show: _vm.passwordValidationErrorOccurred && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.username,
                    expression: "username",
                  },
                ],
                staticStyle: { display: "none" },
                attrs: { type: "text", name: "email" },
                domProps: { value: _vm.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.username = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "input-current-password" },
                          },
                          [_vm._v(_vm._s(_vm.$t("label.currentPassword")))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { xl: "9", sm: "7" } },
                        [
                          _c("b-form-input", {
                            staticClass: "password-input",
                            attrs: {
                              id: "input-current-password",
                              name: "current-password",
                              type: _vm.form.currentPasswordVisible
                                ? "text"
                                : "password",
                              placeholder: _vm.$t(
                                "placeholder.currentPassword"
                              ),
                              disabled: _vm.isLoading,
                              autocomplete: "current-password",
                            },
                            model: {
                              value: _vm.form.currentPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "currentPassword", $$v)
                              },
                              expression: "form.currentPassword",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "password-visibility-toggle increase-focus",
                              on: {
                                click: _vm.toggleCurrentPasswordVisibility,
                              },
                            },
                            [
                              _c("font-awesome", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.form.currentPasswordVisible,
                                    expression: "!form.currentPasswordVisible",
                                  },
                                ],
                                attrs: { icon: ["far", "eye"] },
                              }),
                              _vm._v(" "),
                              _c("font-awesome", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.form.currentPasswordVisible,
                                    expression: "form.currentPasswordVisible",
                                  },
                                ],
                                attrs: { icon: ["far", "eye-slash"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c("b-col", { attrs: { xl: "3", sm: "5" } }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "input-new-password" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("label.newPassword")) +
                                "\n              "
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.d200",
                                    modifiers: { hover: true, d200: true },
                                  },
                                ],
                                staticClass: "info-circle",
                                attrs: { title: _vm.$t("tooltip.newPassword") },
                              },
                              [
                                _c("font-awesome", {
                                  attrs: { icon: ["far", "info-circle"] },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { xl: "9", sm: "7" } },
                        [
                          _c("b-form-input", {
                            staticClass: "password-input",
                            attrs: {
                              id: "input-new-password",
                              name: "new-password",
                              type: _vm.form.newPasswordVisible
                                ? "text"
                                : "password",
                              placeholder: _vm.$t("placeholder.newPassword"),
                              disabled: _vm.isLoading,
                              autocomplete: "new-password",
                            },
                            model: {
                              value: _vm.form.newPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "newPassword", $$v)
                              },
                              expression: "form.newPassword",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "password-visibility-toggle increase-focus",
                              on: { click: _vm.toggleNewPasswordVisibility },
                            },
                            [
                              _c("font-awesome", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.form.newPasswordVisible,
                                    expression: "!form.newPasswordVisible",
                                  },
                                ],
                                attrs: { icon: ["far", "eye"] },
                              }),
                              _vm._v(" "),
                              _c("font-awesome", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.form.newPasswordVisible,
                                    expression: "form.newPasswordVisible",
                                  },
                                ],
                                attrs: { icon: ["far", "eye-slash"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.passwordValidationErrorOccurred,
                          expression: "passwordValidationErrorOccurred",
                        },
                      ],
                    },
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            sm: "7",
                            "offset-sm": "5",
                            xl: "9",
                            "offset-xl": "3",
                          },
                        },
                        [
                          _c(
                            "b-alert",
                            {
                              staticClass: "mb-0",
                              attrs: {
                                show: true,
                                variant: "danger",
                                fade: "",
                              },
                            },
                            [
                              _c("h1", { staticClass: "alert-heading" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("passwordValidationError.title")
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("password-validation-error-list", {
                                attrs: { errors: _vm.passwordValidationErrors },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: {
                        variant: "primary",
                        disabled: _vm.isLoading || _vm.form.newPassword === "",
                      },
                      on: { click: _vm.saveInfo },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("saveInfo")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }