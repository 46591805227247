var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "filter-criteria",
    {
      staticClass: "filter-criteria-survey",
      class: { "is-loading": _vm.isLoading },
      attrs: { variant: "primary" },
    },
    [
      _c("font-awesome", {
        staticClass: "font-awesome",
        attrs: { icon: ["fas", "poll-h"] },
      }),
      _vm._v(" "),
      !_vm.isLoading ? _c("span", [_vm._v(_vm._s(_vm.surveyName))]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }