var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-table" },
    [
      _c("b-table", {
        attrs: {
          items: _vm.filters,
          fields: _vm.fields,
          filter: _vm.filter,
          "filter-included-fields": ["name", "locations", "locationGroups"],
          "show-empty": true,
          "empty-text": _vm.$t("emptyTableText"),
          "empty-filtered-text": _vm.$t("emptyTableText"),
          "sort-compare": _vm.sortCompare,
          "sort-by": "name",
          "no-sort-reset": "",
          responsive: "",
          striped: "",
          hover: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(name)",
            fn: function (data) {
              return [
                data.item.name
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "filter-edit",
                            params: { filter: data.item.id },
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "clickable-link" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(data.item.name) +
                              "\n        "
                          ),
                        ]),
                      ]
                    )
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(locations)",
            fn: function (data) {
              return [
                _c("location-group-table-entry", {
                  attrs: { items: data.item.locations },
                }),
              ]
            },
          },
          {
            key: "cell(locationGroups)",
            fn: function (data) {
              return [
                _c("location-group-table-entry", {
                  attrs: { items: data.item.locationGroups, "is-group": "" },
                }),
              ]
            },
          },
          {
            key: "cell(createdDate)",
            fn: function (data) {
              return [
                data.item.createdDate
                  ? _c("span", [
                      _vm._v(
                        _vm._s(data.item.createdDate.format("DD.MM.YYYY HH:mm"))
                      ),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(lastModifiedDate)",
            fn: function (data) {
              return [
                data.item.lastModifiedDate
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          data.item.lastModifiedDate.format("DD.MM.YYYY HH:mm")
                        )
                      ),
                    ])
                  : _c("span", [_vm._v("—")]),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex float-right" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "filter-edit",
                            params: { filter: data.item.id },
                          },
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "primary", size: "sm" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("editButton")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "danger", size: "sm" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteFilter(data.item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("deleteButton")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }