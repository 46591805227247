import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import Http from '@/config/Http';
export var Feedback = {
  get: function get(companyId, answerSetId) {
    return Http.get('/company/' + companyId + '/analytics/feedback/' + answerSetId);
  },
  putSpam: function putSpam(companyId, answerSetId, isSpam, reasonSpam) {
    if (isSpam) {
      return Http.put("/company/".concat(companyId, "/analytics/feedback/").concat(answerSetId, "/spam"), {
        reasonSpam: reasonSpam
      });
    } else {
      return Http.put("/company/".concat(companyId, "/analytics/feedback/").concat(answerSetId, "/not-spam"));
    }
  }
};
export var Timeline = {
  getTimeline: function getTimeline(companyId, locationIds, start, end, page, additionalParams) {
    var params = Object.assign({
      l: locationIds,
      start: start,
      end: end,
      page: page,
      size: 20
    }, additionalParams !== null && typeof additionalParams !== 'undefined' ? additionalParams : {});
    return Http.get('/company/' + companyId + '/analytics/timeline', {
      params: params
    });
  }
};
export var CustomRatingReasonAnswers = {
  get: function get(companyId, locationIds, start, end, surveyId, ratingQuestionId, filter, page) {
    return Http.get("/company/".concat(companyId, "/analytics/custom-answer-inspection/surveys/").concat(surveyId, "/rating-question/").concat(ratingQuestionId, "/custom-rating-reason-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        page: page,
        size: 20,
        filter: filter
      }
    });
  },
  count: function count(companyId, locationIds, start, end, surveyId, ratingQuestionId) {
    return Http.get("/company/".concat(companyId, "/analytics/custom-answer-inspection/surveys/").concat(surveyId, "/rating-question/").concat(ratingQuestionId, "/count-custom-rating-reason-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  }
};
export var CustomChoiceAttributeAnswers = {
  get: function get(companyId, locationIds, start, end, surveyId, customChoiceAttributeId, filter, page) {
    return Http.get("/company/".concat(companyId, "/analytics/custom-answer-inspection/surveys/").concat(surveyId, "/custom-choice-attribute/").concat(customChoiceAttributeId, "/custom-choice-attribute-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        page: page,
        size: 20,
        filter: filter
      }
    });
  },
  count: function count(companyId, locationIds, start, end, surveyId, customChoiceAttributeId) {
    return Http.get("/company/".concat(companyId, "/analytics/custom-answer-inspection/surveys/").concat(surveyId, "/custom-choice-attribute/").concat(customChoiceAttributeId, "/count-custom-choice-attribute-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  }
};
export var CommentAnswers = {
  get: function get(companyId, locationIds, start, end, surveyId, questionId, filter, page) {
    return Http.get("/company/".concat(companyId, "/analytics/custom-answer-inspection/surveys/").concat(surveyId, "/comment-question/").concat(questionId, "/comment-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        page: page,
        size: 20,
        filter: filter
      }
    });
  },
  count: function count(companyId, locationIds, start, end, surveyId, questionId) {
    return Http.get("/company/".concat(companyId, "/analytics/custom-answer-inspection/surveys/").concat(surveyId, "/comment-question/").concat(questionId, "/count-comment-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  }
};
export var TextAnswers = {
  get: function get(companyId, locationIds, start, end, surveyId, questionId, filter, page) {
    return Http.get("/company/".concat(companyId, "/analytics/custom-answer-inspection/surveys/").concat(surveyId, "/text-question/").concat(questionId, "/text-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        page: page,
        size: 20,
        filter: filter
      }
    });
  },
  count: function count(companyId, locationIds, start, end, surveyId, questionId) {
    return Http.get("/company/".concat(companyId, "/analytics/custom-answer-inspection/surveys/").concat(surveyId, "/text-question/").concat(questionId, "/count-text-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  }
};
export var Location = {
  getAllowedLocations: function getAllowedLocations(companyId) {
    return Http.get("/company/".concat(companyId, "/shared/locations"));
  },
  getAllowedAndActiveLocations: function getAllowedAndActiveLocations(companyId) {
    return Http.get("/company/".concat(companyId, "/shared/locations"), {
      params: {
        filterArchived: true
      }
    });
  }
};
export var QuickStatistics = {
  getData: function getData(companyId, locationIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/quick-statistics"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  }
};
export var LocationPerformance = {
  getLocationData: function getLocationData(companyId, locationIds, start, end, groupBy) {
    return Http.get("/company/".concat(companyId, "/analytics/location-performance"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        groupBy: groupBy
      }
    });
  }
};
export var ChangeOverTime = {
  getData: function getData(companyId, locationIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/change-over-time"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  }
};
export var SurveyAnalysis = {
  getSurveyOptions: function getSurveyOptions(companyId, locationIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/survey-analysis/surveys"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  },
  getData: function getData(companyId, locationIds, surveyId, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/survey-analysis/surveys/").concat(surveyId), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  }
};
export var Comparison = {
  hasDataWithDateAndLocations: function hasDataWithDateAndLocations(companyId, locationIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/configure/has-data"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  },
  getPossibleSurveysInSelection: function getPossibleSurveysInSelection(companyId, locationIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/configure/surveys"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  },
  getPossibleFiltersOfOneSurvey: function getPossibleFiltersOfOneSurvey(companyId, locationIds, surveyId, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/configure/surveys/").concat(surveyId, "/filters"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  },
  getNumberOfFeedbacksWithSelection: function getNumberOfFeedbacksWithSelection(companyId, locationIds, surveyId, choiceAttributeIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/configure/surveys/").concat(surveyId, "/filters/number-of-feedbacks"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds
      }
    });
  },
  checkValidityOfSelection: function checkValidityOfSelection(companyId, locationIds, surveyId, choiceAttributeIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/check-validity"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds
      }
    });
  },
  getSelectionData: function getSelectionData(companyId, locationIds, surveyId, choiceAttributeIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/selection"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds
      }
    });
  },
  getSurveyAnalysis: function getSurveyAnalysis(companyId, locationIds, surveyId, choiceAttributeIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds
      }
    });
  },
  getChangeOverTime: function getChangeOverTime(companyId, locationIds, surveyId, choiceAttributeIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/change-over-time"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds
      }
    });
  },
  getSimpleQuickStatistics: function getSimpleQuickStatistics(companyId, locationIds, surveyId, choiceAttributeIds, start, end) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/simple-quick-statistics"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds
      }
    });
  },
  getCustomRatingReasons: function getCustomRatingReasons(companyId, locationIds, start, end, choiceAttributeIds, surveyId, ratingQuestionId, filter, page) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/rating-question/").concat(ratingQuestionId, "/custom-rating-reason-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds,
        page: page,
        size: 20,
        filter: filter
      }
    });
  },
  countCustomRatingReasons: function countCustomRatingReasons(companyId, locationIds, start, end, choiceAttributeIds, surveyId, ratingQuestionId) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/rating-question/").concat(ratingQuestionId, "/count-custom-rating-reason-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds
      }
    });
  },
  getCustomChoiceAttributes: function getCustomChoiceAttributes(companyId, locationIds, start, end, choiceAttributeIds, surveyId, customChoiceAttributeId, filter, page) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/custom-choice-attribute/").concat(customChoiceAttributeId, "/custom-choice-attribute-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds,
        page: page,
        size: 20,
        filter: filter
      }
    });
  },
  countCustomChoiceAttributes: function countCustomChoiceAttributes(companyId, locationIds, start, end, choiceAttributeIds, surveyId, customChoiceAttributeId) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/custom-choice-attribute/").concat(customChoiceAttributeId, "/count-custom-choice-attribute-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds
      }
    });
  },
  getCommentAnswers: function getCommentAnswers(companyId, locationIds, start, end, choiceAttributeIds, surveyId, questionId, filter, page) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/comment-question/").concat(questionId, "/comment-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds,
        page: page,
        size: 20,
        filter: filter
      }
    });
  },
  countCommentAnswers: function countCommentAnswers(companyId, locationIds, start, end, choiceAttributeIds, surveyId, questionId) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/comment-question/").concat(questionId, "/comment-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds
      }
    });
  },
  getTextAnswers: function getTextAnswers(companyId, locationIds, start, end, choiceAttributeIds, surveyId, questionId, filter, page) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/text-question/").concat(questionId, "/text-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds,
        page: page,
        size: 20,
        filter: filter
      }
    });
  },
  countTextAnswers: function countTextAnswers(companyId, locationIds, start, end, choiceAttributeIds, surveyId, questionId) {
    return Http.get("/company/".concat(companyId, "/analytics/comparison/surveys/").concat(surveyId, "/text-question/").concat(questionId, "/text-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        choiceAttributeIds: choiceAttributeIds
      }
    });
  }
};
export var Account = {
  update: function update(account) {
    return Http.put('/account', account);
  },
  changePassword: function changePassword(dto) {
    return Http.post('/account/change-password', dto, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    });
  }
};
export var Notification = {
  get: function get() {
    return Http.get('/user-settings/notifications');
  },
  update: function update(data) {
    return Http.put('/user-settings/notifications', data);
  }
};
export var TabletMigration = {
  getRunningTablets: function getRunningTablets(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/tablets/running"));
  },
  getTabletsInPool: function getTabletsInPool(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/tablets/pool"));
  },
  startMeasurement: function startMeasurement(companyId, tabletSourceId, locationId) {
    return Http.post("/company/".concat(companyId, "/administration/tablets/").concat(tabletSourceId, "/measurement/start/").concat(locationId));
  },
  stopMeasurement: function stopMeasurement(companyId, tabletSourceId) {
    return Http.post("/company/".concat(companyId, "/administration/tablets/").concat(tabletSourceId, "/measurement/stop"));
  }
};
export var WebSourceManagement = {
  getWebSourceById: function getWebSourceById(companyId, webSourceId) {
    return Http.get("/company/".concat(companyId, "/administration/web-source-management/web-sources/").concat(webSourceId));
  },
  getEnabledWebSources: function getEnabledWebSources(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/web-source-management/web-sources"));
  },
  getDisabledWebSources: function getDisabledWebSources(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/web-source-management/web-sources/disabled"));
  },
  createWebSource: function createWebSource(companyId, dto) {
    return Http.post("/company/".concat(companyId, "/administration/web-source-management/web-sources"), dto);
  },
  updateWebSource: function updateWebSource(companyId, dto) {
    return Http.put("/company/".concat(companyId, "/administration/web-source-management/web-sources/").concat(dto.webSourceId), dto);
  },
  enableWebSource: function enableWebSource(companyId, webSourceId) {
    return Http.put("/company/".concat(companyId, "/administration/web-source-management/web-sources/").concat(webSourceId, "/enable"));
  },
  disableWebSource: function disableWebSource(companyId, webSourceId) {
    return Http.put("/company/".concat(companyId, "/administration/web-source-management/web-sources/").concat(webSourceId, "/disable"));
  },
  deleteWebSource: function deleteWebSource(companyId, webSourceId) {
    return Http.put("/company/".concat(companyId, "/administration/web-source-management/web-sources/").concat(webSourceId, "/delete"));
  }
};
export var UserManagement = {
  getUsers: function getUsers(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/users"));
  },
  getUser: function getUser(companyId, userId) {
    return Http.get("/company/".concat(companyId, "/administration/user/").concat(userId));
  },
  putUser: function putUser(companyId, userId, userData) {
    return Http.put("/company/".concat(companyId, "/administration/user/").concat(userId), userData);
  },
  createUser: function createUser(companyId, userData) {
    return Http.post("/company/".concat(companyId, "/administration/create-user"), userData);
  },
  activateUser: function activateUser(companyId, userId) {
    return Http.put("/company/".concat(companyId, "/administration/user/").concat(userId, "/activate"));
  },
  deactivateUser: function deactivateUser(companyId, userId) {
    return Http.put("/company/".concat(companyId, "/administration/user/").concat(userId, "/deactivate"));
  },
  deleteUser: function deleteUser(companyId, userId) {
    return Http.delete("/company/".concat(companyId, "/administration/user/").concat(userId, "/delete"));
  },
  resetUserPassword: function resetUserPassword(companyId, userId) {
    return Http.put("/company/".concat(companyId, "/administration/user/").concat(userId, "/reset-password"));
  },
  generatePassword: function generatePassword(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/generate-password"));
  }
};
export var LocationAdministration = {
  getActiveLocations: function getActiveLocations(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/locations"));
  },
  getArchivedLocations: function getArchivedLocations(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/locations/archived"));
  },
  getLocation: function getLocation(companyId, locationId) {
    return Http.get("/company/".concat(companyId, "/administration/locations/").concat(locationId));
  },
  getForeignKeyValues: function getForeignKeyValues(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/locations/foreign-key-values"));
  },
  updateLocation: function updateLocation(companyId, locationId, locationData) {
    return Http.put("/company/".concat(companyId, "/administration/locations/").concat(locationId), locationData);
  },
  createLocation: function createLocation(companyId, locationData) {
    return Http.post("/company/".concat(companyId, "/administration/locations"), locationData);
  },
  archiveLocation: function archiveLocation(companyId, locationId) {
    return Http.put("/company/".concat(companyId, "/administration/locations/").concat(locationId, "/archive"));
  },
  unarchiveLocation: function unarchiveLocation(companyId, locationId) {
    return Http.put("/company/".concat(companyId, "/administration/locations/").concat(locationId, "/unarchive"));
  },
  deleteLocation: function deleteLocation(companyId, locationId) {
    return Http.delete("/company/".concat(companyId, "/administration/locations/").concat(locationId, "/delete"));
  },
  createLocationGroup: function createLocationGroup(companyId, locationGroupData) {
    return Http.post("/company/".concat(companyId, "/administration/location-groups"), locationGroupData);
  }
};
export var CompanySettings = {
  fetchSettings: function fetchSettings(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/company-settings"));
  },
  updateSettings: function updateSettings(companyId, settings) {
    return Http.put("/company/".concat(companyId, "/administration/company-settings"), settings);
  },
  fetchCompanyStatistics: function fetchCompanyStatistics(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/company-statistics"));
  }
};
export var Matrix = {
  getData: function getData(companyId, locationIds, from, to) {
    return Http.get("/company/".concat(companyId, "/analytics/matrix"), {
      params: {
        l: locationIds,
        start: from,
        end: to
      }
    });
  }
};
export var Goal = {
  getAllActive: function getAllActive(userId) {
    return Http.get("/users/".concat(userId, "/goals"));
  },
  getAllCompleted: function getAllCompleted(userId) {
    return Http.get("/users/".concat(userId, "/goals"), {
      params: {
        completed: true
      }
    });
  },
  getAllArchived: function getAllArchived(userId) {
    return Http.get("/users/".concat(userId, "/goals/archived"));
  },
  createGoal: function createGoal(userId, goal) {
    return Http.post("/users/".concat(userId, "/goals"), goal);
  },
  updateGoal: function updateGoal(userId, goal) {
    return Http.put("/users/".concat(userId, "/goals"), goal);
  },
  get: function get(userId, goalId) {
    return Http.get("/users/".concat(userId, "/goals/").concat(goalId));
  },
  getDetails: function getDetails(userId, goalId) {
    return Http.get("/users/".concat(userId, "/goals/").concat(goalId, "/details"));
  },
  archive: function archive(userId, goalId) {
    return Http.put("/users/".concat(userId, "/goals/").concat(goalId, "/archive"));
  },
  unarchive: function unarchive(userId, goalId) {
    return Http.put("/users/".concat(userId, "/goals/").concat(goalId, "/unarchive"));
  },
  delete: function _delete(userId, goalId) {
    return Http.delete("/users/".concat(userId, "/goals/").concat(goalId, "/delete"));
  }
};
export var CsvCheck = {
  check: function check(companyId, locationIds, from, to, language, selectedExportType) {
    var matrixExportAggregateByLocationGroup = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
    return Http.get("/company/".concat(companyId, "/export/csv/check"), {
      params: {
        l: locationIds,
        start: from,
        end: to,
        language: language,
        selectedExportType: selectedExportType.toUpperCase(),
        matrixExportAggregateByLocationGroup: matrixExportAggregateByLocationGroup
      }
    });
  }
};
export var Pdf = {
  check: function check(companyId, locationIds, start, end, components, language) {
    return Http.get("/company/".concat(companyId, "/export/pdf-report/check"), {
      params: {
        l: locationIds,
        start: start,
        end: end,
        components: components,
        language: language
      }
    });
  },
  getLocationsWithActualData: function getLocationsWithActualData(companyId, locationIds, start, end) {
    return Http.get("/company/".concat(companyId, "/export/pdf-report/data/locations-with-actual-data"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  },
  getCustomAnswers: function getCustomAnswers(companyId, locationIds, start, end) {
    return Http.get("/company/".concat(companyId, "/export/pdf-report/data/custom-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  },
  getTextAnswers: function getTextAnswers(companyId, locationIds, start, end) {
    return Http.get("/company/".concat(companyId, "/export/pdf-report/data/text-answers"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  },
  getComments: function getComments(companyId, locationIds, start, end) {
    return Http.get("/company/".concat(companyId, "/export/pdf-report/data/comments"), {
      params: {
        l: locationIds,
        start: start,
        end: end
      }
    });
  }
};
export var NotificationDispatcherResource = {
  visitLink: function visitLink(token) {
    return Http.put("/notifications/visit-link/token/".concat(token));
  }
};
export var CompanyProperties = {
  fetch: function fetch(companyId) {
    return Http.get("/company/".concat(companyId, "/shared/company-properties"));
  }
};
export var SurveyManagementOverview = {
  getActiveSurveys: function getActiveSurveys(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/survey-management/surveys"));
  },
  getArchivedSurveys: function getArchivedSurveys(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/survey-management/surveys/archived"));
  },
  archiveSurvey: function archiveSurvey(companyId, surveyId) {
    return Http.put("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/archive"));
  },
  unarchiveSurvey: function unarchiveSurvey(companyId, surveyId) {
    return Http.put("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/unarchive"));
  },
  deleteSurvey: function deleteSurvey(companyId, surveyId) {
    return Http.delete("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/delete"));
  },
  assignSurvey: function assignSurvey(companyId, surveyId, locationIds) {
    return Http.put("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/set-for-locations"), {
      locationIds: locationIds
    });
  }
};
export var SurveyManagement = {
  createNewSurvey: function createNewSurvey(companyId, dto) {
    return Http.post("/company/".concat(companyId, "/administration/survey-management/surveys"), dto);
  },
  getFullSurvey: function getFullSurvey(companyId, surveyId) {
    return Http.get("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId));
  },
  updateSurveyNameAndLanguages: function updateSurveyNameAndLanguages(companyId, surveyId, dto) {
    return Http.put("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId), dto);
  },
  createNewQuestion: function createNewQuestion(companyId, surveyId, dto) {
    return Http.post("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/create-new-question"), dto);
  },
  updateQuestion: function updateQuestion(companyId, surveyId, dto) {
    return Http.put("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/update-question"), dto);
  },
  sortQuestionInSurvey: function sortQuestionInSurvey(companyId, surveyId, dto) {
    return Http.post("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/sort-question"), dto);
  },
  toggleQuestionActivation: function toggleQuestionActivation(companyId, surveyId, dto) {
    return Http.post("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/toggle-question-activation"), dto);
  },
  getQuestionPoolOfTypeNotInSurvey: function getQuestionPoolOfTypeNotInSurvey(companyId, surveyId, type) {
    return Http.get("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/question-pool"), {
      params: {
        type: type
      }
    });
  },
  addExistingQuestionToSurvey: function addExistingQuestionToSurvey(companyId, surveyId, dto) {
    return Http.post("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/add-existing-question"), dto);
  },
  getEmptyQuestionShellByType: function getEmptyQuestionShellByType(companyId, surveyId, type) {
    return Http.get("/company/".concat(companyId, "/administration/survey-management/surveys/").concat(surveyId, "/empty-question-shell"), {
      params: {
        type: type
      }
    });
  },
  getRatingQuestionCategoriesBySector: function getRatingQuestionCategoriesBySector(companyId) {
    return Http.get("/company/".concat(companyId, "/administration/survey-management/rating-question-categories"));
  },
  getLocationSources: function getLocationSources(companyId, locationIds) {
    return Http.get("/company/".concat(companyId, "/administration/survey-management/check-locations-in-sources?locationIds=").concat(locationIds.join(',')));
  }
};
export var SearchFilter = {
  getAllSearchFiltersShort: function getAllSearchFiltersShort(userId) {
    return Http.get("/users/".concat(userId, "/search-filters/"));
  },
  createNewSearchFilter: function createNewSearchFilter(userId, dto) {
    return Http.post("/users/".concat(userId, "/search-filter-management/"), dto);
  },
  getSearchFilter: function getSearchFilter(userId, filterId) {
    return Http.get("/users/".concat(userId, "/search-filter-management/").concat(filterId));
  },
  updateSearchFilter: function updateSearchFilter(userId, searchFilterId, dto) {
    return Http.put("/users/".concat(userId, "/search-filter-management/").concat(searchFilterId), dto);
  },
  getAll: function getAll(userId) {
    return Http.get("/users/".concat(userId, "/search-filter-management/"));
  },
  deleteSearchFilter: function deleteSearchFilter(userId, filterId) {
    return Http.delete("/users/".concat(userId, "/search-filter-management/").concat(filterId, "/delete"));
  }
};