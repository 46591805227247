import "core-js/modules/es.array.push.js";
import "core-js/modules/es.string.small.js";
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
am4core.options.commercialLicense = true;
export default {
  name: 'ChangeOverTimeChartLoading',
  props: {
    heightInPixel: {
      type: Number,
      required: true
    },
    small: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  methods: {
    createChart: function createChart(element, small) {
      var chart = am4core.create(element, am4charts.XYChart);
      if (small) {
        chart.paddingLeft = 20;
        chart.paddingRight = 25;
        chart.paddingBottom = 5;
      }
      chart.data = [];
      var chartConstants = {
        gridColor: am4core.color('#f2f2f2'),
        axisColor: am4core.color('#ccc')
      };
      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.line.stroke = chartConstants.gridColor;
      dateAxis.renderer.grid.template.strokeOpacity = 1;
      dateAxis.renderer.grid.template.stroke = chartConstants.gridColor;
      dateAxis.renderer.grid.template.strokeWidth = 1;
      var amountAxis = chart.yAxes.push(new am4charts.ValueAxis());
      amountAxis.min = 0;
      amountAxis.max = 100;
      amountAxis.renderer.opposite = true;
      amountAxis.renderer.grid.template.disabled = true;
      amountAxis.renderer.labels.template.fontSize = 0;
      amountAxis.renderer.ticks.template.disabled = false;
      amountAxis.renderer.ticks.template.strokeOpacity = 1;
      amountAxis.renderer.ticks.template.stroke = chartConstants.axisColor;
      amountAxis.renderer.ticks.template.strokeWidth = 1;
      amountAxis.renderer.ticks.template.length = 5;
      amountAxis.renderer.line.strokeOpacity = 1;
      amountAxis.renderer.line.strokeWidth = 1;
      amountAxis.renderer.line.stroke = chartConstants.axisColor;
      var satisfactionAxis = chart.yAxes.push(new am4charts.ValueAxis());
      satisfactionAxis.min = 0;
      satisfactionAxis.max = 105;
      satisfactionAxis.strictMinMax = true;
      satisfactionAxis.renderer.labels.template.fontSize = 0;
      satisfactionAxis.renderer.ticks.template.disabled = false;
      satisfactionAxis.renderer.ticks.template.strokeOpacity = 1;
      satisfactionAxis.renderer.ticks.template.stroke = chartConstants.axisColor;
      satisfactionAxis.renderer.ticks.template.strokeWidth = 1;
      satisfactionAxis.renderer.ticks.template.length = 5;
      satisfactionAxis.renderer.line.strokeOpacity = 1;
      satisfactionAxis.renderer.line.strokeWidth = 1;
      satisfactionAxis.renderer.line.stroke = chartConstants.axisColor;
      satisfactionAxis.renderer.grid.template.strokeOpacity = 1;
      satisfactionAxis.renderer.grid.template.stroke = chartConstants.gridColor;
      satisfactionAxis.renderer.grid.template.strokeWidth = 1;
      return chart;
    },
    initChart: function initChart() {
      this.chart = this.createChart(this.$refs.chart, this.small);
    },
    resetChart: function resetChart() {
      if (this.chart !== null) {
        this.chart.dispose();
        this.chart = null;
      }
    }
  },
  mounted: function mounted() {
    this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    this.resetChart();
  }
};