var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    {
      staticClass: "form-select",
      class: { "is-invalid": _vm.invalid },
      attrs: { variant: "light", disabled: _vm.disabled, dropup: _vm.dropup },
    },
    [
      _c("template", { slot: "button-content" }, [_vm._t("active")], 2),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }