//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LanguageAwareStringInput from '@/components/shared/form-input/LanguageAwareStringInput';
export default {
  name: 'SurveyManagementLasWrapper',
  components: {
    LanguageAwareStringInput: LanguageAwareStringInput
  },
  props: {
    enabledLanguages: {
      type: Array,
      required: true
    },
    currentLanguage: {
      type: String,
      required: true
    },
    las: {
      type: Object,
      required: false,
      default: function _default() {}
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    optional: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    anyLasIsFocused: function anyLasIsFocused() {
      return this.$store.state.surveyManagement.editPageState.data.anyLasIsFocused;
    }
  },
  methods: {
    setLasFocus: function setLasFocus(payload) {
      this.$store.commit('surveyManagement/setEditPageStateLasFocus', payload);
    },
    onChange: function onChange(internalLasMappedToLas) {
      this.$emit('change', internalLasMappedToLas);
    },
    onChangeLanguage: function onChangeLanguage(language) {
      this.$emit('change-language', language);
    }
  }
};