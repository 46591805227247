var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-view" }, [
    _c(
      "div",
      {
        staticClass: "reload",
        class: { "is-loading": _vm.isLoading },
        on: { click: _vm.intervalMethod },
      },
      [_vm._v("\n    ↻\n  ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "error-box" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.$t("title")))]),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.$t("text")))]),
      _vm._v(" "),
      _c("div", { staticClass: "contact" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("contact")) + ":\n      "),
        _c("a", { attrs: { href: "mailto:hotline@jls.ch" } }, [
          _vm._v("hotline@jls.ch"),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "code" }, [
      _c("span", [
        _vm._v(_vm._s(_vm.statusCode ? _vm.statusCode + "XDASH" : "")),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: require("@/assets/opiniooon-logo-dark.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }