var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "survey-management-panel",
    {
      staticClass: "survey-management-question",
      attrs: { padded: true, disabled: !_vm.question.enabled },
    },
    [
      _c(
        "div",
        { staticClass: "button-row" },
        [
          _vm.question.enabled
            ? [
                _c("modal-icon-button", {
                  attrs: {
                    index: 0,
                    icon: ["far", "pencil"],
                    "color-type": "info",
                    message: _vm.$t("buttons.edit"),
                    "horizontal-in-box": "",
                  },
                  on: { click: _vm.edit },
                }),
                _vm._v(" "),
                _c("modal-icon-button", {
                  attrs: {
                    index: 1,
                    icon: ["far", "pause"],
                    "color-type": "danger",
                    message: _vm.$t("buttons.disable"),
                    "horizontal-in-box": "",
                  },
                  on: { click: _vm.toggleQuestionActivation },
                }),
              ]
            : [
                _c("modal-icon-button", {
                  attrs: {
                    index: 0,
                    icon: ["far", "pencil"],
                    "color-type": "info",
                    disabled: true,
                    message: _vm.$t("buttons.edit"),
                    "horizontal-in-box": "",
                  },
                  on: { click: _vm.edit },
                }),
                _vm._v(" "),
                _c("modal-icon-button", {
                  attrs: {
                    index: 1,
                    icon: ["far", "play"],
                    "color-type": "danger",
                    message: _vm.$t("buttons.enable"),
                    "horizontal-in-box": "",
                  },
                  on: { click: _vm.toggleQuestionActivation },
                }),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "question-header-info" }, [
        _c(
          "span",
          { staticClass: "question-type" },
          [
            _vm.questionTypeIconText.icon.fa
              ? _c("font-awesome", {
                  attrs: {
                    icon: _vm.questionTypeIconText.icon.fa,
                    "fixed-width": "",
                  },
                })
              : _vm.questionTypeIconText.icon.component
              ? _c(_vm.questionTypeIconText.icon.component, {
                  tag: "component",
                  attrs: { type: _vm.questionTypeIconText.icon.componentType },
                })
              : _vm._e(),
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t(
                    "surveyManagementQuestionType." + _vm.question.questionType
                  )
                ) +
                "\n    "
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("h2", {
        staticClass: "question-title mb-0",
        domProps: {
          innerHTML: _vm._s(
            _vm.$options.filters.languageAwareString(_vm.question.text)
          ),
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }