//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import Panel from '@/components/shared/Panel';
import ChangeOverTimeChart from '@/components/shared/change-over-time/ChangeOverTimeChart';
export default {
  name: 'GoalDetailPanel',
  components: {
    Alert: Alert,
    Panel: Panel,
    ChangeOverTimeChart: ChangeOverTimeChart
  },
  props: {
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    chartData: {
      type: Array,
      required: true
    },
    useIndexCalculation: {
      type: Boolean,
      required: false,
      default: null
    },
    goalValue: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: function data() {
    return {
      hasRelevanceFilter: false
    };
  },
  computed: {
    chartDataIsEmpty: function chartDataIsEmpty() {
      return typeof this.chartData === 'undefined' || this.chartData === null || typeof this.chartData.length === 'undefined' || this.chartData.length === null || this.chartData.length === 0;
    }
  }
};