var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-management-assign-modal" },
    [
      _c("alert", {
        staticClass: "positioned-alert",
        attrs: {
          icon: ["fas", "exclamation-triangle"],
          type: "danger",
          title: _vm.$t("error.title"),
          message: _vm.$t("error.message"),
          show: _vm.isErrorOccurred && !_vm.isLoading,
        },
      }),
      _vm._v(" "),
      _c("modal-icon-button", {
        attrs: {
          index: 0,
          icon: ["far", "times"],
          "color-type": "danger",
          message: _vm.$t("tooltip.closeModal"),
          "tooltip-placement": "top",
        },
        on: { click: _vm.cancel },
      }),
      _vm._v(" "),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "bold-uppercase-legend",
                          attrs: { label: _vm.$t("form.locations") },
                        },
                        [
                          !_vm.isLoading
                            ? _c("location-group-select", {
                                attrs: {
                                  id: "survey-locations",
                                  placeholder: _vm.$t("form.selectLocations"),
                                  "is-loading": _vm.isLoading,
                                  "is-in-form": true,
                                  "pre-selected-ids": _vm.preSelectedIds,
                                },
                                on: {
                                  "change-locations":
                                    _vm.onChangeSurveyLocations,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.removedItems.locations.length > 0
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "12" } }, [
                    _c(
                      "p",
                      { staticClass: "alert alert-warning" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["fas", "exclamation-triangle"] },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("warnings.removedLocations")) +
                              ":\n            " +
                              _vm._s(
                                _vm.removedItems.locations
                                  .map(function (location) {
                                    return location.name
                                  })
                                  .join(", ")
                              ) +
                              "\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.removedItems.sources.length > 0
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "12" } }, [
                    _c(
                      "p",
                      { staticClass: "alert alert-warning" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["fas", "exclamation-triangle"] },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("warnings.removedSources")) +
                              ":\n            "
                          ),
                          _c("ul", [
                            _vm.removedTabletsWithName.length > 0 ||
                            _vm.removedTabletsWithoutName.length > 0
                              ? _c("li", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("warnings.tablets")) +
                                      ":\n                " +
                                      _vm._s(
                                        _vm.removedTabletsWithName
                                          .map(function (item) {
                                            return item.name
                                          })
                                          .join(", ")
                                      ) +
                                      "\n                "
                                  ),
                                  _vm.removedTabletsWithoutName.length > 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("warnings.and")) +
                                            "\n                  " +
                                            _vm._s(
                                              _vm.removedTabletsWithoutName
                                                .length
                                            ) +
                                            "\n                  " +
                                            _vm._s(_vm.$t("warnings.more"))
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.removedTerminalsWithName.length > 0 ||
                            _vm.removedTerminalsWithoutName.length > 0
                              ? _c("li", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("warnings.terminals")) +
                                      ":\n                " +
                                      _vm._s(
                                        _vm.removedTerminalsWithName
                                          .map(function (item) {
                                            return item.name
                                          })
                                          .join(", ")
                                      ) +
                                      "\n                "
                                  ),
                                  _vm.removedTerminalsWithoutName.length > 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("warnings.and")) +
                                            "\n                  " +
                                            _vm._s(
                                              _vm.removedTerminalsWithoutName
                                                .length
                                            ) +
                                            "\n                  " +
                                            _vm._s(_vm.$t("warnings.more"))
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.removedWebsWithName.length > 0 ||
                            _vm.removedWebsWithoutName.length > 0
                              ? _c("li", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("warnings.surveyLinks")) +
                                      ":\n                " +
                                      _vm._s(
                                        _vm.removedWebsWithName
                                          .map(function (item) {
                                            return item.name
                                          })
                                          .join(", ")
                                      ) +
                                      "\n                "
                                  ),
                                  _vm.removedWebsWithoutName.length > 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("warnings.and")) +
                                            "\n                  " +
                                            _vm._s(
                                              _vm.removedWebsWithoutName.length
                                            ) +
                                            "\n                  " +
                                            _vm._s(_vm.$t("warnings.more"))
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.removedItems.sources.length > 0
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "12" } }, [
                    _c(
                      "p",
                      { staticClass: "alert alert-warning" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["fas", "exclamation-triangle"] },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("warnings.notShowSurvey"))),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "float-right",
              attrs: {
                type: "submit",
                variant: "primary",
                disabled: _vm.isLoading,
              },
              on: { click: _vm.submit },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("saveButton")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }