import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { API_URL } from '@/config/constants';
import Question from '@/components/shared/FeedbackModalQuestion';
import { Feedback } from '@/services/ResourceService';
import Alert from '@/components/shared/Alert';
import ModalIconButton from '@/components/shared/ModalIconButton';
import moment from 'moment';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import { getColorNameByIndex } from '@/services/ColorService';
import FormatUtils from '@/services/utils/FormatUtils';
import ContactRequestIcon from '@/components/shared/ContactRequestIcon';
import ConfirmService from '@/services/ConfirmService';
export default {
  name: 'FeedbackModal',
  components: {
    ContactRequestIcon: ContactRequestIcon,
    Question: Question,
    Alert: Alert,
    ModalIconButton: ModalIconButton
  },
  props: {
    answerSetId: {
      type: Number,
      required: true
    },
    isPrintView: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    var _this = this;
    var allowedLanguages = ['DE', 'EN', 'FR', 'IT'];
    return {
      isLoading: true,
      isMarkingAsSpam: false,
      spamStateChanged: false,
      feedbackData: null,
      language: function () {
        return _this.$store.state && _this.$store.state.general.user && _this.$store.state.general.user.language && allowedLanguages.includes(_this.$store.state.general.user.language) ? _this.$store.state.general.user.language : 'DE';
      }(),
      reasonSpam: null
    };
  },
  computed: {
    satisfactionColor: function satisfactionColor() {
      if (this.hasIndexCalculation) {
        return getColorNameByIndex(this.feedbackData.averageIndex, this.$store.state.general.companyProperties);
      } else {
        if (this.feedbackData.isSatisfied === true) {
          return 'green';
        } else if (this.feedbackData.isSatisfied === false) {
          return 'red';
        } else {
          return 'grey';
        }
      }
    },
    satisfactionLabel: function satisfactionLabel() {
      if (this.isLoading) {
        return null;
      } else if (this.hasIndexCalculation) {
        return this.feedbackData.averageIndex;
      } else {
        if (this.feedbackData.isSatisfied === true) {
          return this.$t('satisfied');
        } else if (this.feedbackData.isSatisfied === false) {
          return this.$t('dissatisfied');
        } else {
          return this.$t('neutral');
        }
      }
    },
    displayAsSpam: function displayAsSpam() {
      if (this.isLoading) {
        return false;
      }
      return this.feedbackData.isSpam;
    },
    contactRequest: function contactRequest() {
      var index = this.feedbackData.questions.findIndex(function (question) {
        return question.questionType === 'EMAIL_QUESTION' && question.contactRequestAnswer && question.contactRequestAnswer.contactRequest;
      });
      return index > -1;
    },
    user: function user() {
      return this.$store.state.general.user;
    },
    userIsAdminOrSuperAdmin: function userIsAdminOrSuperAdmin() {
      return this.user.authorities.indexOf('ROLE_SUPER_ADMIN') > -1 || this.user.authorities.indexOf('ROLE_ADMIN') > -1;
    },
    canMarkAsSpam: function canMarkAsSpam() {
      if (!this.isLoading && this.feedbackData.permissionManagerSpam === true) {
        return true;
      } else {
        return this.userIsAdminOrSuperAdmin;
      }
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    requestUrl: function requestUrl() {
      var companyId = this.$store.state.general.companyId;
      var url = "/company/".concat(companyId, "/export/pdf-single-feedback");
      var answerSetIdParam = 'answerSetId=' + this.answerSetId;
      var langParam = 'language=' + this.language;
      return "".concat(API_URL).concat(url, "?").concat(answerSetIdParam, "&").concat(langParam);
    },
    spamRequiresReason: function spamRequiresReason() {
      var _this$$store$state$ge;
      return (_this$$store$state$ge = this.$store.state.general.companyProperties) === null || _this$$store$state$ge === void 0 ? void 0 : _this$$store$state$ge.spamRequiresReason;
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    Promise.all([Feedback.get(this.$store.state.general.companyId, this.answerSetId), CompanyPropertiesService.update()]).then(function (responses) {
      _this2.feedbackData = responses[0].data;
      _this2.isLoading = false;
    });

    // add a padding to left side for the "left-bar"
    var parentElement = this.$el.parentElement;
    if (!this.isPrintView && this.hasIndexCalculation && parentElement.classList.contains('vuedal')) {
      parentElement.style.paddingLeft = '27px';
    }
    this.isPrintView && document.body.classList.add('white-background');
  },
  beforeDestroy: function beforeDestroy() {
    this.isPrintView && document.body.classList.remove('white-background');
  },
  methods: {
    checkToggleSpam: function checkToggleSpam() {
      var _this3 = this;
      if (!this.feedbackData.isSpam && this.spamRequiresReason) {
        ConfirmService.createModal({
          name: 'confirm-spam-reason',
          type: 'warning',
          title: this.$t('spamRequiresReason.modalTitle'),
          icon: ['fas', 'exclamation-triangle'],
          text: this.$t('spamRequiresReason.modalText'),
          textIsHtml: true,
          okayButton: this.$t('spamRequiresReason.saveText'),
          dismissButton: this.$t('spamRequiresReason.dismissText'),
          requireMessage: true,
          onClose: function onClose(param) {
            if (param !== null && param !== void 0 && param.okay && param !== null && param !== void 0 && param.message) {
              _this3.reasonSpam = param.message;
              _this3.toggleSpam();
            }
          }
        });
      } else {
        this.reasonSpam = null;
        this.toggleSpam();
      }
    },
    toggleSpam: function toggleSpam() {
      var _this4 = this;
      this.isMarkingAsSpam = true;
      Feedback.putSpam(this.$store.state.general.companyId, this.answerSetId, !this.feedbackData.isSpam, this.reasonSpam).then(function () {
        _this4.spamStateChanged = !_this4.spamStateChanged;
        _this4.feedbackData.isSpam = !_this4.feedbackData.isSpam;
        _this4.feedbackData.reasonSpam = _this4.reasonSpam;
        _this4.isMarkingAsSpam = false;
      }, function () {
        _this4.isMarkingAsSpam = false;
      });
    },
    closeModal: function closeModal() {
      this.$vuedals.close({
        spamStateChanged: this.spamStateChanged
      });
    },
    formatDateTime: function formatDateTime(date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY HH:mm');
      }
    },
    escapeHTML: FormatUtils.escapeHTML,
    exportAsPdf: function exportAsPdf() {
      window.open(this.requestUrl, '_blank');
    }
  }
};