import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getColorNameByIndex } from '@/services/ColorService';
import { Bus as VuedalsBus } from 'vuedals';
import ContactRequestIcon from '@/components/shared/ContactRequestIcon';
import AnswerInspectionModal from '@/components/shared/AnswerInspectionModal';
import QuestionExpandedSection from '@/components/survey-analysis/QuestionExpandedSection';
export default {
  name: 'Question',
  components: {
    QuestionExpandedSection: QuestionExpandedSection,
    ContactRequestIcon: ContactRequestIcon
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    counter: {
      type: Number,
      required: true,
      validator: function validator(item) {
        return item > 0;
      }
    },
    isNarrow: {
      type: Boolean,
      required: false,
      default: false
    },
    surveyId: {
      type: Number,
      required: true
    },
    queryParameters: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      isExpanded: false,
      renderChart: false
    };
  },
  computed: {
    hasExpandedSection: function hasExpandedSection() {
      return ['RATING_QUESTION', 'CHOICE_QUESTION', 'NUMBER_QUESTION', 'DATE_QUESTION', 'COMMENT_QUESTION', 'TEXT_QUESTION'].includes(this.question.questionType);
    },
    hasInspectionModal: function hasInspectionModal() {
      return ['COMMENT_QUESTION', 'TEXT_QUESTION'].includes(this.question.questionType);
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    noAnswersInQuestion: function noAnswersInQuestion() {
      return this.question.answerCounter === 0;
    },
    toggleExpandableButtonText: function toggleExpandableButtonText() {
      if (this.question.questionType === 'RATING_QUESTION') {
        if (this.question.ratingReasonAnswerCounter !== 0) {
          return this.$t('title.showDistributionAndExplanations');
        } else {
          return this.$t('title.showDistribution');
        }
      } else if (this.question.questionType === 'CHOICE_QUESTION') {
        if (this.question.choiceAttributeAnswerCounter !== 0) {
          return this.$t('title.showChoiceAttributes');
        }
      } else if (['DATE_QUESTION', 'NUMBER_QUESTION'].includes(this.question.questionType)) {
        return this.$t('title.showDistribution');
      }
      return null;
    }
  },
  methods: {
    getColorNameByIndex: getColorNameByIndex,
    toggleExpansion: function toggleExpansion() {
      var _this = this;
      if (this.noAnswersInQuestion) {
        this.isExpanded = false;
      } else {
        window.setTimeout(function () {
          _this.renderChart = true;
        }, 300);
        this.isExpanded = !this.isExpanded;
      }
    },
    openInspectionModal: function openInspectionModal() {
      VuedalsBus.$emit('new', {
        title: this.$t("modalTitle.".concat(this.question.questionType)),
        name: 'answer-inspection-modal',
        closeOnBackdrop: false,
        props: {
          surveyId: this.surveyId,
          counter: this.counter,
          question: this.question,
          queryParameters: this.queryParameters
        },
        component: AnswerInspectionModal
      });
    }
  }
};