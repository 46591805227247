var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "loading-indicator-overlay",
      class: {
        "has-overlay": _vm.hasOverlay,
        "position-fixed": _vm.positionFixed,
      },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading-indicator-wrapper" }, [
      _c("div", { staticClass: "opn-spinner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }