import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InfiniteLoading from 'vue-infinite-loading';
import FormatUtils from '@/services/utils/FormatUtils';
import TextAnswerService from '@/services/TextAnswerService';
import { Bus as VuedalsBus } from 'vuedals';
import FeedbackModal from '@/components/shared/FeedbackModal';
import moment from 'moment';
import TextAnswersLoading from '@/components/survey-analysis/TextAnswersLoading';
export default {
  name: 'TextAnswers',
  components: {
    TextAnswersLoading: TextAnswersLoading,
    InfiniteLoading: InfiniteLoading
  },
  props: {
    surveyId: {
      type: Number,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    queryParameters: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      isLoading: true,
      nextPage: 0,
      answers: [],
      infiniteScrollIdentifier: 0,
      isLast: false
    };
  },
  mounted: function mounted() {
    this.initialize();
  },
  methods: {
    initialize: function initialize() {
      var _this = this;
      this.answers = [];
      this.isLoading = false;
      this.nextPage = 0;
      this.isLast = false;
      this.$nextTick(function () {
        _this.infiniteScrollIdentifier += 1;
      });
    },
    onInfinite: function onInfinite(infiniteLoadingState) {
      var _this2 = this;
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      TextAnswerService.getPagedTextAnswers(this.surveyId, this.question, this.queryParameters, null, this.nextPage).then(function (response) {
        _this2.isLoading = false;
        _this2.processData(response, infiniteLoadingState);
      }).catch(function () {
        _this2.answers = [];
        _this2.isLoading = false;
        infiniteLoadingState.complete();
      });
    },
    processData: function processData(response, infiniteLoadingState) {
      var data = response.data;
      var escapedData = [];
      data.content.forEach(function (item) {
        var answerEscaped = FormatUtils.escapeHTML(item.answer);
        if (answerEscaped) {
          escapedData.push({
            answerId: item.answerId,
            answerSetId: item.answerSetId,
            answerEscaped: answerEscaped,
            timestamp: item.timestamp
          });
        }
      });
      if (escapedData.length > 0) {
        this.answers = this.answers.concat(escapedData);
        this.nextPage = data.number + 1;
        infiniteLoadingState.loaded();
      }
      if (data.last === true) {
        this.isLast = true;
        infiniteLoadingState.complete();
      }
    },
    openFeedbackModal: function openFeedbackModal(answerSetId) {
      var _this3 = this;
      VuedalsBus.$emit('new', {
        title: this.$t('modalTitle'),
        name: 'feedback-modal',
        closeOnBackdrop: false,
        props: {
          answerSetId: answerSetId
        },
        component: FeedbackModal,
        onClose: function onClose(options) {
          if (options.spamStateChanged) {
            _this3.answers = _this3.answers.filter(function (item) {
              return item.answerSetId !== answerSetId;
            });
          }
        }
      });
    },
    formatDateTime: function formatDateTime(date) {
      return moment(date).format('DD.MM.YYYY HH:mm');
    },
    afterLeave: function afterLeave() {
      var _this4 = this;
      // This fixes an issue with the transition-group where InfiniteLoading
      // would not work anymore after clearing the list (e.g. after marking an
      // AnswerSet as spam)
      this.$nextTick(function () {
        _this4.infiniteScrollIdentifier += 1;
      });
    }
  }
};