var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-image-uploader",
      class: { invalid: _vm.state === false },
    },
    [
      _vm.encodedFile !== null
        ? _c("div", { staticClass: "image-preview" }, [
            _c("img", {
              staticClass: "image",
              attrs: {
                src:
                  "data:" +
                  _vm.encodedFile.binaryFileContentType +
                  ";base64, " +
                  _vm.encodedFile.binaryFile,
                alt: "Uploaded Image",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "image-subtext" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("imageType")) + ":\n      "),
              _c("strong", [
                _vm._v(" " + _vm._s(_vm.encodedFile.binaryFileContentType)),
              ]),
              _vm._v(", " + _vm._s(_vm.$t("imageSize")) + ":\n      "),
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.formatByteSizeOfFile(_vm.encodedFile.binaryFile))
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "button-wrapper" },
        [
          _c("b-form-file", {
            ref: "file-input",
            staticClass: "hidden-file-input",
            attrs: {
              plain: "",
              tabindex: "-1",
              accept: _vm.accept,
              disabled: _vm.disabled,
              state: _vm.state,
            },
            model: {
              value: _vm.rawFile,
              callback: function ($$v) {
                _vm.rawFile = $$v
              },
              expression: "rawFile",
            },
          }),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { disabled: _vm.disabled, variant: "outline-secondary" },
              on: { click: _vm.openFileInput },
            },
            [
              _vm.encodedFile === null
                ? [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["far", "plus"] },
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("addImage")) + "\n      "),
                  ]
                : [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["fas", "exchange"] },
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("replaceImage")) + "\n      "),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _vm.encodedFile !== null
            ? _c(
                "b-button",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    variant: "outline-secondary",
                  },
                  on: { click: _vm.clearSelectedFile },
                },
                [
                  _c("font-awesome", {
                    staticClass: "font-awesome",
                    attrs: { icon: ["fas", "trash-alt"] },
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("removeImage")) + "\n    "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }