var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internalEditQuestion !== null &&
    _vm.internalCurrentLanguage !== null
    ? _c(
        "div",
        { staticClass: "survey-management-edit-rating-reasons" },
        [
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend",
              attrs: { label: _vm.$t("label.ratingReasonTitle") },
            },
            [
              _c("survey-management-las-wrapper", {
                attrs: {
                  optional: true,
                  las: _vm.internalEditQuestion.ratingReasonTitle,
                  "enabled-languages":
                    _vm.$store.state.surveyManagement.fullSurvey.data
                      .enabledLanguages,
                  "current-language": _vm.internalCurrentLanguage,
                  invalid:
                    _vm.editPageState.data.showValidation &&
                    !_vm.validation.ratingReasonTitle.valid,
                },
                on: {
                  change: function ($event) {
                    _vm.internalEditQuestion.ratingReasonTitle = $event
                  },
                  "change-language": function ($event) {
                    _vm.internalCurrentLanguage = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend",
              attrs: {
                label: _vm.$t("label.ratingReasonThreshold"),
                description: _vm.$t("description.ratingReasonThreshold"),
              },
            },
            [
              _c(
                "div",
                { staticClass: "slider-wrapper" },
                [
                  _c("slider-input", {
                    attrs: {
                      min: 0,
                      max: _vm.internalEditQuestion.scale,
                      "is-rating-reason-threshold": true,
                    },
                    model: {
                      value: _vm.internalEditQuestion.ratingReasonThreshold,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.internalEditQuestion,
                          "ratingReasonThreshold",
                          $$v
                        )
                      },
                      expression: "internalEditQuestion.ratingReasonThreshold",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend",
              attrs: { label: _vm.$t("label.activatedRatingReasons") },
            },
            [
              _vm.hasRatingReasons
                ? _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "draggable",
                        class: {
                          "with-placeholder":
                            _vm.internalEditQuestion.activatedRatingReasons
                              .length <= 1,
                          flashing: _vm.isDragging,
                        },
                        attrs: {
                          "data-empty-content": _vm.$t("dropMessage.activated"),
                        },
                        on: {
                          start: _vm.onStartDragging,
                          end: _vm.onEndDragging,
                        },
                        model: {
                          value:
                            _vm.internalEditQuestion.activatedRatingReasons,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.internalEditQuestion,
                              "activatedRatingReasons",
                              $$v
                            )
                          },
                          expression:
                            "internalEditQuestion.activatedRatingReasons",
                        },
                      },
                      "draggable",
                      _vm.draggingOptions,
                      false
                    ),
                    _vm._l(
                      _vm.internalEditQuestion.activatedRatingReasons,
                      function (activatedRatingReason, index) {
                        return _c("survey-management-edit-attribute", {
                          key: activatedRatingReason.key,
                          attrs: {
                            index: index,
                            disabled: false,
                            "is-new": activatedRatingReason.isNew,
                            las: activatedRatingReason.text,
                            deleted: false,
                            "current-language": _vm.internalCurrentLanguage,
                            invalid:
                              _vm.editPageState.data.showValidation &&
                              !_vm.validation.activatedRatingReasons.single[
                                index
                              ].valid,
                          },
                          on: {
                            "update-las": function ($event) {
                              activatedRatingReason.text = $event
                            },
                            "change-language": function ($event) {
                              _vm.internalCurrentLanguage = $event
                            },
                          },
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "survey-management-new-attribute-wrapper" },
                [
                  _c("survey-management-new-attribute", {
                    attrs: {
                      disabled: _vm.addNewRatingReasonButtonDisabled,
                      title: _vm.$t("button.newRatingReason"),
                    },
                    on: { click: _vm.addNewRatingReason },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasRatingReasons
            ? _c(
                "b-form-group",
                {
                  staticClass: "bold-uppercase-legend",
                  attrs: { label: _vm.$t("label.disabledRatingReasons") },
                },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "draggable",
                        class: {
                          "with-placeholder":
                            _vm.internalEditQuestion.disabledRatingReasons
                              .length <= 1,
                          flashing: _vm.isDragging,
                        },
                        attrs: {
                          "data-empty-content": _vm.$t("dropMessage.disabled"),
                        },
                        on: {
                          start: _vm.onStartDragging,
                          end: _vm.onEndDragging,
                        },
                        model: {
                          value: _vm.internalEditQuestion.disabledRatingReasons,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.internalEditQuestion,
                              "disabledRatingReasons",
                              $$v
                            )
                          },
                          expression:
                            "internalEditQuestion.disabledRatingReasons",
                        },
                      },
                      "draggable",
                      _vm.draggingOptions,
                      false
                    ),
                    _vm._l(
                      _vm.internalEditQuestion.disabledRatingReasons,
                      function (disabledRatingReason) {
                        return _c("survey-management-edit-attribute", {
                          key: disabledRatingReason.key,
                          staticClass: "survey-management-edit-attribute",
                          attrs: {
                            index: "disabled",
                            disabled: false,
                            "disabled-las": true,
                            deleted: disabledRatingReason.isNew,
                            "is-new": disabledRatingReason.isNew,
                            las: disabledRatingReason.text,
                            "current-language": _vm.internalCurrentLanguage,
                            invalid: false,
                          },
                          on: {
                            "change-language": function ($event) {
                              _vm.internalCurrentLanguage = $event
                            },
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-form-group", [
            _c(
              "div",
              {
                staticClass: "separating-box pb-3",
                class: {
                  disabled: !_vm.internalEditQuestion.customRatingReasonEnabled,
                },
              },
              [
                _c(
                  "label",
                  { staticClass: "separating-box-title" },
                  [
                    _c("b-form-checkbox", {
                      model: {
                        value:
                          _vm.internalEditQuestion.customRatingReasonEnabled,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.internalEditQuestion,
                            "customRatingReasonEnabled",
                            $$v
                          )
                        },
                        expression:
                          "internalEditQuestion.customRatingReasonEnabled",
                      },
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("label.customRatingReasonEnabled")) +
                        "\n      "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("survey-management-edit-attribute", {
                  attrs: {
                    index: _vm.internalEditQuestion.customRatingReasonEnabled
                      ? _vm.internalEditQuestion.activatedRatingReasons.length
                      : "disabled",
                    custom: true,
                    "has-dragging": false,
                    "disabled-las":
                      !_vm.internalEditQuestion.customRatingReasonEnabled,
                    "optional-las":
                      !_vm.internalEditQuestion.customRatingReasonEnabled,
                    "is-new":
                      _vm.internalEditQuestion.customRatingReasonEnabled &&
                      _vm.internalEditQuestion.customRatingReason.isNew,
                    las: _vm.internalEditQuestion.customRatingReason.text,
                    "current-language": _vm.internalCurrentLanguage,
                    invalid:
                      _vm.editPageState.data.showValidation &&
                      !_vm.validation.customRatingReason.valid,
                  },
                  on: {
                    "update-las": function ($event) {
                      _vm.internalEditQuestion.customRatingReason.text = $event
                    },
                    "change-language": function ($event) {
                      _vm.internalCurrentLanguage = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }