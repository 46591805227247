var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "print-quick-stat print-bordered-box",
      class: [
        _vm.hasIndexColoration ? "colored" : null,
        _vm.hasIndexColoration
          ? "color-" +
            _vm.getColorNameByIndex(
              _vm.data,
              _vm.$store.state.general.companyProperties
            )
          : null,
      ],
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "data" }, [
        _vm.data !== null
          ? _c("span", { staticClass: "value" }, [
              _vm._v(
                "\n      " + _vm._s(_vm._f("groupedNumber")(_vm.data, true, 1))
              ),
              _vm.isPercentage ? _c("span", [_vm._v("%")]) : _vm._e(),
            ])
          : _c("span", { staticClass: "value inactive" }, [_vm._v("n/a")]),
        _vm._v(" "),
        _vm.data !== null && _vm.change !== null
          ? _c(
              "span",
              { staticClass: "change" },
              [
                _vm.change > 0
                  ? _c("span", [
                      _vm._v(
                        "+" +
                          _vm._s(_vm._f("groupedNumber")(_vm.change, true, 1)) +
                          _vm._s(_vm.isChangePercentage ? "%" : "")
                      ),
                    ])
                  : _vm.change < 0
                  ? _c("span", [
                      _vm._v(
                        "–" +
                          _vm._s(
                            _vm._f("groupedNumber")(_vm.change * -1, true, 1)
                          ) +
                          _vm._s(_vm.isChangePercentage ? "%" : "")
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "±" +
                          _vm._s(_vm.change) +
                          _vm._s(_vm.isChangePercentage ? "%" : "")
                      ),
                    ]),
                _vm._v(" "),
                _vm.change > 0
                  ? _c("font-awesome", {
                      staticClass: "font-awesome long-arrow-up",
                      attrs: { icon: ["fas", "long-arrow-up"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.change < 0
                  ? _c("font-awesome", {
                      staticClass: "font-awesome long-arrow-down",
                      attrs: { icon: ["fas", "long-arrow-down"] },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.change === 0
                  ? _c("font-awesome", {
                      staticClass: "font-awesome exchange",
                      attrs: { icon: ["fas", "exchange"] },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }