//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import Alert from '@/components/shared/Alert';
import ConfirmService from '@/services/ConfirmService';
import { UserManagement } from '@/services/ResourceService';
import ScrollAnimatorService from '@/services/ScrollAnimatorService';
export default {
  name: 'ResetPasswordPanel',
  components: {
    Alert: Alert,
    Panel: Panel
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: null
    },
    userActivated: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      isSubmitting: false,
      successfullyResetPassword: false,
      serverErrorOccurred: false
    };
  },
  methods: {
    requestPasswordReset: function requestPasswordReset() {
      var _this = this;
      ConfirmService.createModal({
        name: 'confirm-password-reset',
        type: 'danger',
        title: this.$t('requestResetModal.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestResetModal.text'),
        textIsHtml: true,
        okayButton: this.$t('requestResetModal.okay'),
        dismissButton: this.$t('requestResetModal.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this.resetUserPassword();
          }
        }
      });
    },
    resetUserPassword: function resetUserPassword() {
      this.isSubmitting = true;
      this.serverErrorOccurred = false;
      this.successfullyResetPassword = false;
      UserManagement.resetUserPassword(this.$store.state.general.companyId, this.userId).then(this.handleSuccess, this.handleError);
    },
    handleSuccess: function handleSuccess() {
      this.isSubmitting = false;
      this.successfullyResetPassword = true;
      ScrollAnimatorService.scrollToTopOfElement(this.$el);
    },
    handleError: function handleError() {
      this.isSubmitting = false;
      this.serverErrorOccurred = true;
      ScrollAnimatorService.scrollToTopOfElement(this.$el);
    }
  }
};