import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import Panel from '@/components/shared/Panel';
import StartMeasuringModal from '@/components/tablet-migration/StartMeasuringModal';
import { TabletMigration, Location } from '@/services/ResourceService';
import { Bus as VuedalsBus } from 'vuedals';
import moment from 'moment';
export default {
  name: 'TabletMigrationPool',
  components: {
    Panel: Panel,
    Alert: Alert
  },
  data: function data() {
    return {
      isLoading: true,
      fatalErrorOccurred: false,
      items: [],
      locations: []
    };
  },
  props: {
    updateData: {
      type: Number,
      required: true
    }
  },
  watch: {
    updateData: function updateData() {
      this.loadData();
    }
  },
  computed: {
    fields: function fields() {
      // fields in computed allows translation to adapt
      return [{
        key: 'number',
        label: this.$t('fields.number'),
        sortable: true
      }, {
        key: 'currentLocation',
        label: this.$t('fields.currentLocation'),
        sortable: false
      }, {
        key: 'lastActiveLocation',
        label: this.$t('fields.lastActiveLocation'),
        sortable: true
      }, {
        key: 'lastActiveTimestamp',
        label: this.$t('fields.lastActiveTimestamp'),
        sortable: true
      }, {
        key: 'type',
        label: this.$t('fields.type'),
        sortable: true
      }, {
        key: 'newMeasurement',
        label: ' '
      }];
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      Promise.all([TabletMigration.getTabletsInPool(this.$store.state.general.companyId), Location.getAllowedAndActiveLocations(this.$store.state.general.companyId)]).then(function (responses) {
        _this.items = responses[0].data;
        _this.locations = responses[1].data;
        _this.isLoading = false;
      }, function () {
        _this.fatalErrorOccurred = true;
        _this.isLoading = false;
      });
    },
    showModal: function showModal(data) {
      var _this2 = this;
      data['locations'] = this.locations;
      VuedalsBus.$emit('new', {
        title: this.$t('modalTitle', {
          number: data.number
        }),
        name: 'start-measuring-modal',
        closeOnBackdrop: false,
        props: {
          data: data
        },
        component: StartMeasuringModal,
        onClose: function onClose(response) {
          if (response.adapted) {
            _this2.$emit('data-updated');
          }
        }
      });
    },
    formatDateTime: function formatDateTime(date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY HH:mm');
      }
    }
  }
};