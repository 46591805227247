var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("h1", { staticClass: "site-title" }, [
          _vm._v(_vm._s(_vm.$t("siteTitle"))),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "float-right" },
          [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [
                _vm.isInOverview
                  ? _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: { click: _vm.createLocation },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("createLocation")) +
                            "\n          "
                        ),
                      ]
                    )
                  : _c(
                      "b-button",
                      {
                        attrs: { variant: "secondary" },
                        on: { click: _vm.navigateToOverview },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("backToOverview")) +
                            "\n          "
                        ),
                      ]
                    ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view", { key: _vm.routerKey })],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }