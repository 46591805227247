var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-view" }, [
    _c(
      "div",
      { staticClass: "auth-box" },
      [
        _c("div", { staticClass: "logo", style: _vm.customDomainStyles }, [
          _c("img", { attrs: { src: _vm.logoSrc } }),
        ]),
        _vm._v(" "),
        _c(
          "b-card",
          [
            _c("h1", [_vm._t("title")], 2),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm._t("content"),
          ],
          2
        ),
        _vm._v(" "),
        _vm.isCustomDomain
          ? _c(
              "a",
              {
                staticClass: "opnn-logo-small",
                attrs: { href: "https://www.opiniooon.ch", target: "_blank" },
              },
              [_c("img", { attrs: { src: _vm.opnnLogo } })]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }