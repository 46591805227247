var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      attrs: {
        "panel-title": _vm.title,
        "is-loading": _vm.isLoading,
        "loading-title-class": "w-25",
        "loading-min-height": 450,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.isLoading
              ? _c("div", { staticClass: "loading-placeholder" }, [
                  _c("div", { staticClass: "data w-75" }),
                ])
              : _c(
                  "div",
                  {
                    class: {
                      "has-not-index-calculation": !_vm.hasIndexCalculation,
                    },
                  },
                  [
                    _c("div", { staticClass: "row location-list-header" }, [
                      _vm.groupBy === "location"
                        ? _c("div", { staticClass: "col-xs-6 col-md-3" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.changeSort("locationName")
                                  },
                                },
                              },
                              [
                                _c("sort-icon", {
                                  attrs: {
                                    active: _vm.sortBy === "locationName",
                                    "sort-order": _vm.sortOrder,
                                  },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("locations")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xs-6 col-md-3" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeSort("locationGroupName")
                              },
                            },
                          },
                          [
                            _c("sort-icon", {
                              attrs: {
                                active: _vm.sortBy === "locationGroupName",
                                "sort-order": _vm.sortOrder,
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("locationGroup")) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.hasIndexCalculation
                        ? _c(
                            "div",
                            {
                              staticClass: "col-xs-6",
                              class: {
                                "col-md-4": _vm.groupBy === "location",
                                "col-md-7": _vm.groupBy !== "location",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.changeSort("averageIndex")
                                    },
                                  },
                                },
                                [
                                  _c("sort-icon", {
                                    attrs: {
                                      active: _vm.sortBy === "averageIndex",
                                      "sort-order": _vm.sortOrder,
                                      "sort-type": "numeric",
                                    },
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("averageIndex")) +
                                      "\n            "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "col-xs-6",
                              class: {
                                "col-md-4": _vm.groupBy === "location",
                                "col-md-7": _vm.groupBy !== "location",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.changeSort("satisfactionRate")
                                    },
                                  },
                                },
                                [
                                  _c("sort-icon", {
                                    attrs: {
                                      active: _vm.sortBy === "satisfactionRate",
                                      "sort-order": _vm.sortOrder,
                                      "sort-type": "numeric",
                                    },
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("satisfactionInPercentage")
                                      ) +
                                      "\n            "
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-md-2 text-right" },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.changeSort("totalCounter")
                                },
                              },
                            },
                            [
                              _c("sort-icon", {
                                attrs: {
                                  active: _vm.sortBy === "totalCounter",
                                  "sort-order": _vm.sortOrder,
                                  "sort-type": "numeric",
                                },
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("totalCounter")) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.sortedLocations, function (location) {
                      return _c(
                        "div",
                        { key: location.id, staticClass: "location row" },
                        [
                          _vm.groupBy === "location"
                            ? _c("div", { staticClass: "col-12 col-md-3" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "location-name cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.routeToDashboardWithLocation(
                                          location.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(location.locationName) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.hasIndexCalculation
                                  ? _c(
                                      "div",
                                      { staticClass: "location-data" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("satisfied", [
                                                location.satisfactionCounter,
                                                location.satisfactionCounter +
                                                  location.nonSatisfactionCounter,
                                              ])
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-md-3" }, [
                            _vm.groupBy === "location"
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "location-group-name cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.routeToDashboardWithLocationGroupByLocationId(
                                            location.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(location.locationGroupName) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "location-group-name cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.routeToDashboardWithLocationGroupByLocationGroupId(
                                            location.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(location.locationGroupName) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.hasIndexCalculation
                                    ? _c(
                                        "div",
                                        { staticClass: "location-data" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("satisfied", [
                                                  location.satisfactionCounter,
                                                  location.satisfactionCounter +
                                                    location.nonSatisfactionCounter,
                                                ])
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-12 pt-4",
                              class: {
                                "col-md-4": _vm.groupBy === "location",
                                "col-md-7": _vm.groupBy !== "location",
                              },
                            },
                            [
                              _vm.hasIndexCalculation
                                ? _c("opn-progress", {
                                    attrs: {
                                      variant: _vm.getColorNameByIndex(
                                        location.averageIndex,
                                        _vm.$store.state.general
                                          .companyProperties
                                      ),
                                      precision: 1,
                                      "show-progress": "",
                                      animated: "",
                                    },
                                    model: {
                                      value: location.averageIndex,
                                      callback: function ($$v) {
                                        _vm.$set(location, "averageIndex", $$v)
                                      },
                                      expression: "location.averageIndex",
                                    },
                                  })
                                : _c("opn-progress", {
                                    attrs: {
                                      precision: 1,
                                      "show-progress": "",
                                      animated: "",
                                      "is-percentage": "",
                                    },
                                    model: {
                                      value: location.satisfactionRate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          location,
                                          "satisfactionRate",
                                          $$v
                                        )
                                      },
                                      expression: "location.satisfactionRate",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-md-2 text-right location-counter",
                            },
                            [
                              _c(
                                "small",
                                [
                                  _c("font-awesome", {
                                    attrs: { icon: ["fas", "user"] },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("groupedNumber")(
                                      location.totalCounter
                                    )
                                  ) +
                                  "\n            "
                              ),
                              _vm.hasIndexCalculation &&
                              location.countOfAllFeedbacksWithoutSatisfactionData >
                                0
                                ? _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.d200",
                                          modifiers: {
                                            hover: true,
                                            d200: true,
                                          },
                                        },
                                      ],
                                      staticClass:
                                        "location-counter-info-circle",
                                      attrs: {
                                        title: _vm.$t(
                                          location.countOfAllFeedbacksWithoutSatisfactionData >
                                            1
                                            ? "tooltip.notAllFeedbacksHaveSatisfactionPlural"
                                            : "tooltip.notAllFeedbacksHaveSatisfaction",
                                          [
                                            location.countOfAllFeedbacksWithoutSatisfactionData,
                                            location.totalCounter,
                                          ]
                                        ),
                                      },
                                    },
                                    [
                                      _c("font-awesome", {
                                        staticClass: "font-awesome",
                                        attrs: { icon: ["far", "info-circle"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "float-right",
          attrs: { slot: "dropdown" },
          slot: "dropdown",
        },
        [
          _c("b-form-select", {
            attrs: { options: _vm.groupingOptions },
            on: { input: _vm.onChangeGrouping },
            model: {
              value: _vm.groupBy,
              callback: function ($$v) {
                _vm.groupBy = $$v
              },
              expression: "groupBy",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }