var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scale-order-icon" },
    _vm._l(_vm.points, function (point) {
      return _c("div", {
        key: point.rating,
        staticClass: "scale-order-icon-point",
        class: point.color,
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }