var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("panel", { attrs: { "is-loading": false } }, [
        _c(
          "div",
          { attrs: { slot: "title" }, slot: "title" },
          [
            [
              _vm._v(
                _vm._s(_vm.$t(_vm.isCreation ? "createFilter" : "editFilter"))
              ),
            ],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { slot: "content" }, slot: "content" },
          [
            _c("alert", {
              staticClass: "positioned-alert",
              attrs: {
                type: "success",
                show: _vm.successfullySaved && !_vm.isSubmitting,
                icon: ["fas", "check"],
                title: _vm.$t("success.title"),
                message: _vm.$t(
                  _vm.isCreation
                    ? "success.createMessage"
                    : "success.editMessage"
                ),
              },
            }),
            _vm._v(" "),
            _c("alert", {
              staticClass: "positioned-alert",
              attrs: {
                type: "danger",
                show: _vm.validationErrorOccurred && !_vm.isSubmitting,
                icon: ["fas", "exclamation-triangle"],
                title: _vm.$t("validationError.title"),
                message: _vm.$t("validationError.message"),
              },
            }),
            _vm._v(" "),
            _c("alert", {
              staticClass: "positioned-alert",
              attrs: {
                type: "danger",
                show: _vm.serverErrorOccurred && !_vm.isSubmitting,
                icon: ["fas", "exclamation-triangle"],
                title: _vm.$t("serverError.title"),
                message: _vm.$t("serverError.message"),
              },
            }),
            _vm._v(" "),
            !_vm.isLoading
              ? _c("FilterEditForm", {
                  attrs: {
                    "form-data": _vm.editedFilterData,
                    "validation-errors": _vm.validationErrors,
                    disabled: _vm.isSubmitting,
                    "is-creation": _vm.isCreation,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "submit-button-spinner-wrapper" },
                  [
                    _vm.isSubmitting
                      ? _c("div", { staticClass: "spinner-wrapper" }, [
                          _c("div", { staticClass: "opn-spinner" }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "submit-button float-right",
                        attrs: {
                          variant: "primary",
                          disabled: _vm.isSubmitting,
                        },
                        on: { click: _vm.submitForm },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.isCreation
                                ? _vm.$t("buttons.create")
                                : _vm.$t("buttons.save")
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }