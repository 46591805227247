import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import SliderInput from '@/components/shared/form-input/SliderInput';
import SurveyManagementLasWrapper from '@/components/survey-management/edit-question/questions/SurveyManagementLasWrapper';
import SurveyManagementEditAttribute from '@/components/survey-management/edit-question/questions/SurveyManagementEditAttribute';
import SurveyManagementNewAttribute from '@/components/survey-management/edit-question/questions/SurveyManagementNewAttribute';
import draggable from 'vuedraggable';
export default {
  name: 'SurveyManagementEditRatingReasons',
  components: {
    SliderInput: SliderInput,
    SurveyManagementLasWrapper: SurveyManagementLasWrapper,
    SurveyManagementEditAttribute: SurveyManagementEditAttribute,
    SurveyManagementNewAttribute: SurveyManagementNewAttribute,
    draggable: draggable
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    editQuestion: {
      type: Object,
      required: true
    },
    currentLanguage: {
      type: String,
      required: true
    }
  },
  created: function created() {
    this.setInternalEditQuestion();
    this.setInternalCurrentLanguage();
  },
  data: function data() {
    return {
      internalEditQuestion: null,
      internalCurrentLanguage: null,
      isDragging: false
    };
  },
  computed: {
    hasRatingReasons: function hasRatingReasons() {
      return this.internalEditQuestion.activatedRatingReasons.length + this.internalEditQuestion.disabledRatingReasons.length > 0;
    },
    draggingOptions: function draggingOptions() {
      return {
        swap: true,
        group: 'ratingReasons',
        forceFallback: true,
        handle: '.drag-icon',
        disabled: false,
        swapThreshold: 0.6,
        animation: 150
      };
    },
    editPageState: function editPageState() {
      return this.$store.state.surveyManagement.editPageState || {};
    },
    validation: function validation() {
      return this.$store.getters['surveyManagement/editQuestionValidation'];
    },
    addNewRatingReasonButtonDisabled: function addNewRatingReasonButtonDisabled() {
      var hasActivatedRRs = this.internalEditQuestion.activatedRatingReasons.length > 0;
      var hasInvalidRatingReasons = this.validation.activatedRatingReasons.valid === false;
      return hasActivatedRRs && hasInvalidRatingReasons;
    }
  },
  methods: {
    setInternalEditQuestion: function setInternalEditQuestion() {
      this.internalEditQuestion = this.cloneDeep(this.editQuestion);
    },
    setInternalCurrentLanguage: function setInternalCurrentLanguage() {
      this.internalCurrentLanguage = this.currentLanguage;
    },
    onStartDragging: function onStartDragging() {
      this.isDragging = true;
      window.document.body.classList.add('cursor-grabbing');
    },
    onEndDragging: function onEndDragging() {
      var _this = this;
      this.isDragging = false;
      window.document.body.classList.remove('cursor-grabbing');

      // reset all disabled rating reasons to its initial state (the server state or empty for new reasons)
      this.$nextTick(function () {
        if (_this.internalEditQuestion.disabledRatingReasons) {
          _this.internalEditQuestion.disabledRatingReasons.forEach(function (disabledRatingReason) {
            // clear newly created rating reasons
            if (disabledRatingReason.isNew) {
              disabledRatingReason.text = null;
            } else {
              // a disabled already existing rating reason's text is reset to the server state
              // either it was already disabled (within disabledRatingReasons) or it was active before (within activatedRatingReasons)
              var foundItem = _this.question.activatedRatingReasons.find(function (item) {
                return item.ratingReasonId === disabledRatingReason.id;
              }) || _this.question.disabledRatingReasons.find(function (item) {
                return item.ratingReasonId === disabledRatingReason.id;
              });
              if (typeof foundItem !== 'undefined') {
                disabledRatingReason.text = _.cloneDeep(foundItem.text);
              }
            }
          });
        }
      });
    },
    addNewRatingReason: function addNewRatingReason() {
      this.internalEditQuestion.activatedRatingReasons.push({
        id: null,
        text: null,
        isNew: true,
        type: 'REGULAR',
        key: "REGULAR-NEW-".concat(Math.random())
      });
    },
    cloneDeep: _.cloneDeep
  },
  watch: {
    internalEditQuestion: {
      deep: true,
      handler: function handler() {
        if (this.internalEditQuestion && !this.internalEditQuestion.customRatingReasonEnabled && !_.isEqual(this.internalEditQuestion.customRatingReason.text, this.question.customRatingReasonText)) {
          this.internalEditQuestion.customRatingReason.text = this.cloneDeep(this.question.customRatingReasonText);
        }
        if (!_.isEqual(this.internalEditQuestion, this.editQuestion)) {
          this.$emit('update-edit-question', this.cloneDeep(this.internalEditQuestion));
        }
      }
    },
    editQuestion: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalEditQuestion, this.editQuestion)) {
          this.setInternalEditQuestion();
        }
      }
    },
    internalCurrentLanguage: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalCurrentLanguage, this.currentLanguage)) {
          this.$emit('change-language', this.internalCurrentLanguage);
        }
      }
    },
    currentLanguage: {
      deep: true,
      handler: function handler() {
        if (!_.isEqual(this.internalCurrentLanguage, this.currentLanguage)) {
          this.setInternalCurrentLanguage();
        }
      }
    }
  }
};