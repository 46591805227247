var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "feedback-modal",
      class: {
        "not-has-index-calculation": !_vm.hasIndexCalculation,
        "feedback-print-container": _vm.isPrintView,
      },
    },
    [
      !_vm.isPrintView
        ? _c("modal-icon-button", {
            attrs: {
              index: 0,
              icon: ["far", "times"],
              message: _vm.$t("message.closeModal"),
              "color-type": "danger",
              "tooltip-placement": "right",
            },
            on: { click: _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isPrintView
        ? _c("modal-icon-button", {
            attrs: {
              index: 1,
              icon: ["fas", "cloud-download-alt"],
              message: _vm.$t("message.exportAsPdf"),
              "color-type": "info",
              "tooltip-placement": "right",
            },
            on: { click: _vm.exportAsPdf },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasIndexCalculation && !_vm.isPrintView
        ? _c(
            "div",
            {
              staticClass: "left-bar",
              class: [
                _vm.displayAsSpam ? "disabled" : null,
                !_vm.isLoading ? _vm.satisfactionColor : null,
              ],
            },
            [
              !_vm.isLoading && _vm.feedbackData.averageIndex !== null
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right.html.d200",
                          modifiers: {
                            hover: true,
                            right: true,
                            html: true,
                            d200: true,
                          },
                        },
                      ],
                      staticClass: "value",
                      attrs: {
                        title: _vm.$t("satisfactionScore", {
                          value: _vm.escapeHTML(
                            _vm.$options.filters.groupedNumber(
                              _vm.feedbackData.averageIndex,
                              true,
                              4
                            )
                          ),
                        }),
                      },
                    },
                    [
                      _c("font-awesome", {
                        staticClass: "font-awesome",
                        attrs: { icon: ["far", "smile"] },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm._f("groupedNumber")(
                                _vm.feedbackData.averageIndex,
                                true,
                                1
                              )
                            ) +
                            "\n      "
                        ),
                      ]),
                    ],
                    1
                  )
                : !_vm.isLoading && _vm.feedbackData.averageIndex === null
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right.html.d200",
                          modifiers: {
                            hover: true,
                            right: true,
                            html: true,
                            d200: true,
                          },
                        },
                      ],
                      staticClass: "value",
                      attrs: { title: _vm.$t("noSatisfactionScore") },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "no-value-wrapper" },
                        [
                          _c("font-awesome", {
                            staticClass: "font-awesome",
                            attrs: { icon: ["far", "smile"] },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "feedback-wrapper",
              class: { "is-spam": _vm.displayAsSpam },
            },
            [
              !_vm.hasIndexCalculation && !_vm.isPrintView
                ? _c(
                    "div",
                    {
                      class: [_vm.satisfactionColor, "satisfaction-indicator"],
                    },
                    [
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.satisfactionLabel)),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "indicator" }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canMarkAsSpam && !_vm.isPrintView
                ? _c("modal-icon-button", {
                    attrs: {
                      index: 2,
                      icon: ["far", "trash-alt"],
                      "strike-through": _vm.displayAsSpam,
                      disabled: _vm.isMarkingAsSpam,
                      message: _vm.$t("message.markAsSpam"),
                      "message-strike-through": _vm.$t("message.unmarkAsSpam"),
                      "tooltip-placement": "right",
                      "color-type": "info",
                    },
                    on: { click: _vm.checkToggleSpam },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isPrintView
                ? _c("div", { staticClass: "header-row" }, [
                    _c(
                      "div",
                      { staticClass: "location-name" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["fas", "map-marker-alt"] },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.feedbackData.locationName)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "timestamp" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["far", "calendar"] },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDateTime(_vm.feedbackData.timestamp)
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isPrintView
                ? _c("div", { staticClass: "header-row-print" }, [
                    _c("p", { staticClass: "print-title" }, [
                      _vm._v(_vm._s(_vm.$t("modalTitle"))),
                    ]),
                    _vm._v(" "),
                    !_vm.hasIndexCalculation
                      ? _c(
                          "div",
                          {
                            class: [
                              _vm.satisfactionColor,
                              "satisfaction-indicator-print",
                            ],
                          },
                          [
                            _c("div", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.satisfactionLabel)),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "indicator" }),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "index-indicator-print",
                            class: [
                              "value",
                              _vm.displayAsSpam ? "disabled" : null,
                              !_vm.isLoading ? _vm.satisfactionColor : null,
                            ],
                          },
                          [
                            !_vm.isLoading &&
                            _vm.feedbackData.averageIndex !== null
                              ? _c(
                                  "div",
                                  {
                                    attrs: {
                                      title: _vm.$t("satisfactionScore", {
                                        value: _vm.escapeHTML(
                                          _vm.$options.filters.groupedNumber(
                                            _vm.feedbackData.averageIndex,
                                            true,
                                            4
                                          )
                                        ),
                                      }),
                                    },
                                  },
                                  [
                                    _c("font-awesome", {
                                      staticClass: "font-awesome",
                                      attrs: { icon: ["far", "smile"] },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm._f("groupedNumber")(
                                              _vm.feedbackData.averageIndex,
                                              true,
                                              1
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : !_vm.isLoading &&
                                _vm.feedbackData.averageIndex === null
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "value",
                                    attrs: {
                                      title: _vm.$t("noSatisfactionScore"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "no-value-wrapper" },
                                      [
                                        _c("font-awesome", {
                                          staticClass: "font-awesome",
                                          attrs: { icon: ["far", "smile"] },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "location-name-print" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["fas", "map-marker-alt"] },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.feedbackData.locationName)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "timestamp-print" },
                      [
                        _c("font-awesome", {
                          staticClass: "font-awesome",
                          attrs: { icon: ["far", "calendar"] },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.formatDateTime(_vm.feedbackData.timestamp)
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "header-row" },
                _vm._l(_vm.feedbackData.parameters, function (ref) {
                  var answer = ref.answer
                  var parameter = ref.parameter
                  var allowedSurveyParameterId = ref.allowedSurveyParameterId
                  return _c(
                    "div",
                    {
                      key: "SurveyParameter" + allowedSurveyParameterId,
                      staticClass: "parameters",
                    },
                    [
                      _c("strong", [_vm._v(_vm._s(parameter))]),
                      _vm._v(": " + _vm._s(answer) + "\n      "),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("alert", {
                attrs: {
                  message: _vm.$t("spamMessage"),
                  title: _vm.$t("spamTitle"),
                  type: "warning",
                  icon: ["fas", "trash-alt"],
                  closeable: false,
                  show: _vm.displayAsSpam,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(_vm.feedbackData.reasonSpam)),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3833930857
                ),
              }),
              _vm._v(" "),
              _c(
                "alert",
                {
                  attrs: {
                    message: _vm.$t("contactRequestMessage"),
                    title: _vm.$t("contactRequestTitle"),
                    type: "info",
                    closeable: false,
                    show: _vm.contactRequest,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "icon" },
                    [
                      _c("contact-request-icon", {
                        attrs: { "circle-color": "rgb(86, 185, 255)" },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _vm.isPrintView
                ? _c(
                    "div",
                    { staticClass: "questions-print" },
                    _vm._l(_vm.feedbackData.questions, function (q, index) {
                      return _c(
                        "div",
                        { staticClass: "question-print-wrapper" },
                        [
                          _c("question", {
                            key: q.questionId + q.questionType,
                            attrs: { question: q, index: index + 1 },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    { staticClass: "questions" },
                    _vm._l(_vm.feedbackData.questions, function (q, index) {
                      return _c("question", {
                        key: q.questionId + q.questionType,
                        attrs: { question: q, index: index + 1 },
                      })
                    }),
                    1
                  ),
            ],
            1
          )
        : _c("div", { staticClass: "spinner-wrapper" }, [
            _c("div", { staticClass: "opn-spinner" }),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }