var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-md-5" }, [
          _c("h1", { staticClass: "site-title" }, [
            _vm._v(_vm._s(_vm.$t("siteTitle"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-7" }, [
          _c("span", { staticClass: "float-right" }, [
            _c(
              "span",
              [
                _c("opn-date-range-picker", {
                  attrs: {
                    "is-loading": _vm.isLoading,
                    value: _vm.filter.dateRange,
                  },
                  on: { "change-daterange": _vm.dateRangeChanged },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("location-group-select", {
              attrs: {
                "is-loading": _vm.isLoading,
                "pre-selected-ids": _vm.filter.location,
                "show-archived": true,
              },
              on: { "change-locations": _vm.locationsChanged },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.currentUserIsSuperAdmin
        ? _c("search-filter-selector", {
            staticClass: "filter-button float-right",
            attrs: {
              "is-loading": _vm.isLoading,
              "pre-selected-search-filter-id":
                _vm.filter.location.selectedSearchFilterId,
            },
            on: { "change-locations": _vm.locationsChanged },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-secondary btn-sm filter-button float-right",
          class: { filtered: _vm.timelineFilterIsNotDefault },
          on: {
            click: function ($event) {
              _vm.showFilterPopup = true
            },
          },
        },
        [
          _c(
            "small",
            [
              _c("font-awesome", {
                staticClass: "font-awesome",
                attrs: { icon: ["fas", "filter"] },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.timelineFilterIsNotDefault
            ? _c("span", [_vm._v(_vm._s(_vm.$t("filter.buttonFiltered")))])
            : _c("span", [_vm._v(_vm._s(_vm.$t("filter.buttonDefault")))]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row float-right clear-button" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("clear-filter", {
              attrs: { "is-loading": _vm.isLoading, "is-timeline": true },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showFilterPopup
        ? _c("div", {
            staticClass: "filter-popup-click-area",
            on: {
              click: function ($event) {
                _vm.showFilterPopup = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-popup", class: { shown: _vm.showFilterPopup } },
        [
          _c("modal-icon-button", {
            attrs: { index: 0, icon: ["far", "times"], "color-type": "danger" },
            on: {
              click: function ($event) {
                _vm.showFilterPopup = false
              },
            },
          }),
          _vm._v(" "),
          _vm.hasIndexCalculation
            ? _c(
                "div",
                { staticClass: "filter-label" },
                [
                  _vm.$store.state.general.companyProperties !== null
                    ? _c("satisfaction-index-range", {
                        attrs: {
                          disabled: _vm.isLoading,
                          value: _vm.timelineFilter.index,
                        },
                        on: {
                          input: function ($event) {
                            _vm.timelineFilter.index = $event
                            _vm.timelineFilterAdapted()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _c("label", { staticClass: "filter-label" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("satisfactionFeedbacks"))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.timelineFilter.satisfaction,
                          expression: "timelineFilter.satisfaction",
                        },
                      ],
                      staticClass: "form-control filter-select",
                      class: {
                        green: _vm.timelineFilter.satisfaction === "SATISFIED",
                        red: _vm.timelineFilter.satisfaction === "DISSATISFIED",
                      },
                      attrs: { disabled: _vm.isLoading },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.timelineFilter,
                              "satisfaction",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          _vm.timelineFilterAdapted,
                        ],
                      },
                    },
                    _vm._l(
                      _vm.timelineFilterEnums.satisfaction.values,
                      function (value) {
                        return _c(
                          "option",
                          { key: value, domProps: { value: value } },
                          [_vm._v(_vm._s(_vm.$t("satisfaction." + value)))]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ]),
          _vm._v(" "),
          _c("label", { staticClass: "filter-label" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("commentFeedbacks"))),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "value" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.timelineFilter.comments,
                      expression: "timelineFilter.comments",
                    },
                  ],
                  staticClass: "form-control filter-select",
                  class: { blue: _vm.timelineFilter.comments !== "ALL" },
                  attrs: { disabled: _vm.isLoading },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.timelineFilter,
                          "comments",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.timelineFilterAdapted,
                    ],
                  },
                },
                _vm._l(
                  _vm.timelineFilterEnums.comments.values,
                  function (value) {
                    return _c(
                      "option",
                      { key: value, domProps: { value: value } },
                      [_vm._v(_vm._s(_vm.$t("comments." + value)))]
                    )
                  }
                ),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "filter-label" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("spamFeedbacks"))),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "value" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.timelineFilter.spam,
                      expression: "timelineFilter.spam",
                    },
                  ],
                  staticClass: "form-control filter-select",
                  class: { blue: _vm.timelineFilter.spam !== "ALL" },
                  attrs: { disabled: _vm.isLoading },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.timelineFilter,
                          "spam",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.timelineFilterAdapted,
                    ],
                  },
                },
                _vm._l(_vm.timelineFilterEnums.spam.values, function (value) {
                  return _c(
                    "option",
                    { key: value, domProps: { value: value } },
                    [_vm._v(_vm._s(_vm.$t("spam." + value)))]
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "filter-label" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("emailFeedbacks"))),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "value" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.timelineFilter.email,
                      expression: "timelineFilter.email",
                    },
                  ],
                  staticClass: "form-control filter-select",
                  class: { blue: _vm.timelineFilter.email !== "ALL" },
                  attrs: { disabled: _vm.isLoading },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.timelineFilter,
                          "email",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.timelineFilterAdapted,
                    ],
                  },
                },
                _vm._l(_vm.timelineFilterEnums.email.values, function (value) {
                  return _c(
                    "option",
                    { key: value, domProps: { value: value } },
                    [_vm._v(_vm._s(_vm.$t("email." + value)))]
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "filter-label" }, [
            _c("span", { staticClass: "label align-top" }, [
              _vm._v(_vm._s(_vm.$t("barcodeFeedbacks"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "value" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.timelineFilter.barcode.filterByBarcode,
                      expression: "timelineFilter.barcode.filterByBarcode",
                    },
                  ],
                  staticClass: "form-control filter-select",
                  class: {
                    blue: _vm.timelineFilter.barcode.filterByBarcode !== "ALL",
                  },
                  attrs: { disabled: _vm.isLoading },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.timelineFilter.barcode,
                          "filterByBarcode",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.timelineFilterAdapted,
                    ],
                  },
                },
                _vm._l(
                  _vm.timelineFilterEnums.barcode.values,
                  function (value) {
                    return _c(
                      "option",
                      { key: value, domProps: { value: value } },
                      [_vm._v(_vm._s(_vm.$t("barcode." + value)))]
                    )
                  }
                ),
                0
              ),
              _vm._v(" "),
              _vm.timelineFilter.barcode.filterByBarcode === "ONLY_WITH_BARCODE"
                ? _c(
                    "div",
                    { staticClass: "pos-rel" },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control barcode-filter",
                        attrs: {
                          type: "search",
                          placeholder: _vm.$t("barcode.placeholder"),
                          disabled: _vm.isLoading,
                        },
                        nativeOn: {
                          change: function ($event) {
                            return _vm.timelineFilterAdapted($event)
                          },
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.timelineFilterAdapted($event)
                          },
                        },
                        model: {
                          value: _vm.timelineFilter.barcode.barcodeFilterString,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.timelineFilter.barcode,
                              "barcodeFilterString",
                              $$v
                            )
                          },
                          expression:
                            "timelineFilter.barcode.barcodeFilterString",
                        },
                      }),
                      _vm._v(" "),
                      _vm.timelineFilter.barcode.barcodeFilterString &&
                      _vm.timelineFilter.barcode.barcodeFilterString.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "cancel-icon",
                              on: { click: _vm.resetBarcodeTimelineFilter },
                            },
                            [
                              _c("font-awesome", {
                                attrs: { icon: ["far", "times"] },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _vm.timelineFilterIsNotDefault
            ? _c("div", { staticClass: "show-all-feedbacks" }, [
                _c("hr"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-sm",
                    attrs: { disabled: _vm.isLoading },
                    on: {
                      click: function ($event) {
                        return _vm.resetFeedbackFilter()
                      },
                    },
                  },
                  [
                    _c(
                      "small",
                      { staticClass: "disable-filter-icon" },
                      [
                        _c("font-awesome", {
                          attrs: { icon: ["fas", "filter"] },
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("filter.showAllFeedbacks")) +
                        "\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "clearfix mb-3" }),
      _vm._v(" "),
      _vm.$store.getters["general/hasIndexCalculation"]
        ? _c("color-legend", {
            staticClass: "color-legend",
            attrs: {
              "is-loading": _vm.isLoading,
              small: true,
              "company-properties": _vm.$store.state.general.companyProperties,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center mt-5" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading || _vm.timeline.length > 0,
                expression: "isLoading || timeline.length > 0",
              },
            ],
            staticClass: "timeline mb-2 col-xs-12 col-sm-6",
            class: { "has-data": _vm.timeline.length > 0 },
          },
          [
            _vm._l(_vm.timeline, function (entry) {
              return _c("timeline-entry", {
                key: entry.answerSetId,
                attrs: { entry: entry },
                on: {
                  "entry-clicked": function ($event) {
                    return _vm.openFeedbackModal(entry.answerSetId)
                  },
                },
              })
            }),
            _vm._v(" "),
            _c(
              "infinite-loading",
              {
                ref: "infiniteLoading",
                attrs: {
                  identifier: _vm.infiniteScrollIdentifier,
                  spinner: "waveDots",
                },
                on: { infinite: _vm.onInfinite },
              },
              [
                _c("span", { attrs: { slot: "no-more" }, slot: "no-more" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("noMoreFeedbacks")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "text-center pos-rel",
                    attrs: { slot: "spinner" },
                    slot: "spinner",
                  },
                  [
                    _c("div", { staticClass: "spinner-wrapper" }, [
                      _c("div", { staticClass: "opn-spinner" }),
                    ]),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("empty-state", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading && _vm.timeline.length === 0,
            expression: "!isLoading && timeline.length === 0",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }