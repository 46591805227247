var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "print-quick-statistics print-section" }, [
    _c("div", { staticClass: "print-h2" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("title")) + "\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-4 print-quick-stat-element" },
        [
          _c("print-quick-stat", {
            attrs: {
              title: _vm.hasIndexCalculation
                ? _vm.$t("overallAverageIndex")
                : _vm.$t("overallSatisfaction"),
              "show-additional-characters": true,
              "has-index-coloration": _vm.hasIndexCalculation,
              "is-percentage": !_vm.hasIndexCalculation,
              "is-change-percentage": !_vm.hasIndexCalculation,
              data: _vm.hasIndexCalculation
                ? _vm.data.averageIndex
                : _vm.data.satisfactionRate,
              change: _vm.hasIndexCalculation
                ? _vm.data.change.averageIndexAbsolute
                : _vm.data.change.satisfactionRateAbsolute,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-4 print-quick-stat-element" },
        [
          _c("print-quick-stat", {
            attrs: {
              title: _vm.$t("feedbacks"),
              "is-change-percentage": true,
              data: _vm.data.numberOfFeedbacks,
              change: _vm.data.change.numberOfFeedbacksPercent,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-4 print-quick-stat-element" },
        [
          _c("print-quick-stat", {
            attrs: {
              title: _vm.$t("comments"),
              "is-change-percentage": true,
              data: _vm.data.numberOfComments,
              change: _vm.data.numberOfCommentsPercent,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }