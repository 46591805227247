var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goal-overview" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c(
        "div",
        { staticClass: "mr-2 ml-2" },
        [
          _c("alert", {
            attrs: {
              icon: ["fas", "info"],
              type: "info",
              title: _vm.$t("userNotSelected.title"),
              message: _vm.$t("userNotSelected.message"),
              show: !_vm.hasUserSelected,
              closeable: false,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.hasUserSelected
      ? _c("div", { staticClass: "row mb-2" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "panel",
                {
                  attrs: {
                    "panel-title": _vm.$t("panelTitle.active"),
                    "is-loading": false,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _vm.isLoading
                        ? _c("div", { staticClass: "spinner-wrapper" }, [
                            _c("div", { staticClass: "opn-spinner" }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isLoading
                        ? _c("goal-table", {
                            attrs: {
                              type: "active",
                              goals: _vm.activeGoals,
                              "is-loading": _vm.isLoading,
                            },
                            on: {
                              "edit-goal": _vm.editGoal,
                              "delete-goal": _vm.requestDeleteGoal,
                              "view-details": _vm.viewDetails,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasUserSelected
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 mt-4 custom-tabs" },
            [
              _c(
                "b-card",
                { attrs: { "no-block": "" } },
                [
                  _c(
                    "b-tabs",
                    { attrs: { card: "", "no-body": "" } },
                    [
                      _c(
                        "b-tab",
                        {
                          attrs: {
                            title: _vm.$t("panelTitle.completed"),
                            active: "",
                          },
                        },
                        [
                          _vm.isLoading
                            ? _c("div", { staticClass: "spinner-wrapper" }, [
                                _c("div", { staticClass: "opn-spinner" }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isLoading
                            ? _c("goal-table", {
                                attrs: {
                                  type: "completed",
                                  goals: _vm.completedGoals,
                                  "is-loading": _vm.isLoading,
                                },
                                on: {
                                  "edit-goal": _vm.editGoal,
                                  "archive-goal": _vm.archiveGoal,
                                  "view-details": _vm.viewDetails,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: _vm.$t("panelTitle.archived") } },
                        [
                          _vm.isLoading
                            ? _c("div", { staticClass: "spinner-wrapper" }, [
                                _c("div", { staticClass: "opn-spinner" }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isLoading
                            ? _c("goal-table", {
                                attrs: {
                                  type: "archived",
                                  goals: _vm.archivedGoals,
                                  "is-loading": _vm.isLoading,
                                },
                                on: {
                                  "unarchive-goal": _vm.unarchiveGoal,
                                  "delete-goal": _vm.requestDeleteGoal,
                                  "view-details": _vm.viewDetails,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }