//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    icon: {
      type: Array,
      required: false,
      default: function _default() {
        return null;
      }
    }
  }
};