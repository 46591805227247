var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      staticClass: "survey-management-new-attribute",
      class: { disabled: _vm.disabled, invalid: _vm.invalid },
      attrs: { size: "sm", variant: "outline-secondary" },
      on: {
        click: function ($event) {
          !_vm.disabled ? _vm.$emit("click", $event) : null
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "plus" },
        [
          _c("font-awesome", {
            staticClass: "icon",
            attrs: { icon: ["fas", "plus"] },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.title !== null
        ? _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }