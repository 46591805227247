import Vue from 'vue';
import Principal from '@/services/auth/Principal';
var hasAuthority = {
  bind: function bind(el, binding) {
    defineVisibility(el, binding);
  },
  update: function update(el, binding) {
    defineVisibility(el, binding);
  }
};
var defineVisibility = function defineVisibility(el, binding) {
  Principal.hasAuthority(binding.value).then(function (hasAuthority) {
    if (hasAuthority) {
      el.classList.remove('d-none');
    } else {
      el.classList.add('d-none');
    }
  });
};
Vue.directive('hasAuthority', hasAuthority);