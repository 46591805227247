import _toConsumableArray from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.error.cause.js";
import { CommentAnswers, Comparison, CustomChoiceAttributeAnswers, CustomRatingReasonAnswers, TextAnswers } from '@/services/ResourceService';
export default {
  getPagedTextAnswers: function getPagedTextAnswers(surveyId, question, queryParameters, filter, page) {
    var isFilteredByChoiceAttributes = typeof queryParameters.choiceAttributeIds !== 'undefined' && queryParameters.choiceAttributeIds !== null && queryParameters.choiceAttributeIds.length > 0;
    var isChoiceQuestion = question.questionType === 'CHOICE_QUESTION';
    if (!page) {
      page = 0;
    }
    var queryParams = [queryParameters.companyId, queryParameters.locationIds, queryParameters.start, queryParameters.end].concat(_toConsumableArray(isFilteredByChoiceAttributes ? [queryParameters.choiceAttributeIds] : []));
    var surveyParams = [surveyId,
    // All but ChoiceQuestions are filtered by the questionId
    isChoiceQuestion ? question.customChoiceAttributeId : question.questionId];
    var pagedParams = [filter, page];
    var params = [].concat(_toConsumableArray(queryParams), surveyParams, pagedParams);
    if (question.questionType === 'RATING_QUESTION') {
      return isFilteredByChoiceAttributes ? Comparison.getCustomRatingReasons.apply(Comparison, _toConsumableArray(params)) : CustomRatingReasonAnswers.get.apply(CustomRatingReasonAnswers, _toConsumableArray(params));
    } else if (question.questionType === 'CHOICE_QUESTION') {
      return isFilteredByChoiceAttributes ? Comparison.getCustomChoiceAttributes.apply(Comparison, _toConsumableArray(params)) : CustomChoiceAttributeAnswers.get.apply(CustomChoiceAttributeAnswers, _toConsumableArray(params));
    } else if (question.questionType === 'COMMENT_QUESTION') {
      return isFilteredByChoiceAttributes ? Comparison.getCommentAnswers.apply(Comparison, _toConsumableArray(params)) : CommentAnswers.get.apply(CommentAnswers, _toConsumableArray(params));
    } else if (question.questionType === 'TEXT_QUESTION') {
      return isFilteredByChoiceAttributes ? Comparison.getTextAnswers.apply(Comparison, _toConsumableArray(params)) : TextAnswers.get.apply(TextAnswers, _toConsumableArray(params));
    }
    throw new Error('Invalid question type.');
  }
};