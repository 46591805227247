//
//
//
//
//
//

export default {
  name: 'EmptyState',
  data: function data() {
    return {};
  },
  props: {
    message: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    actualMessage: function actualMessage() {
      return this.message || this.$t('defaultMessage');
    }
  }
};