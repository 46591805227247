//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SurveyManagementPanel',
  props: {
    padded: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    hasTitle: function hasTitle() {
      return !!this.$slots.title;
    }
  }
};