import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import LocationAdministrationOverviewTable from '@/components/location-administration/LocationAdministrationOverviewTable';
import { LocationAdministration } from '@/services/ResourceService';
import ConfirmService from '@/services/ConfirmService';
export default {
  name: 'LocationAdministrationOverviewPanel',
  components: {
    Alert: Alert,
    LocationAdministrationOverviewTable: LocationAdministrationOverviewTable
  },
  data: function data() {
    return {
      isLoading: false,
      errorOccurred: false,
      filter: '',
      activeLocations: [],
      archivedLocations: []
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.isLoading = true;
      var companyId = this.$store.state.general.companyId;
      Promise.all([LocationAdministration.getActiveLocations(companyId), LocationAdministration.getArchivedLocations(companyId)]).then(function (response) {
        _this.activeLocations = response[0].data;
        _this.archivedLocations = response[1].data;
        _this.isLoading = false;
      }, function () {
        _this.errorOccurred = true;
        _this.isLoading = false;
      });
    },
    archiveLocation: function archiveLocation(location) {
      var _this2 = this;
      this.isLoading = true;
      var companyId = this.$store.state.general.companyId;
      LocationAdministration.archiveLocation(companyId, location.id).then(function () {
        _this2.$store.commit('general/setAllLocations', []);
        _this2.loadData();
      }, function () {
        _this2.errorOccurred = true;
        _this2.isLoading = false;
      });
    },
    requestArchiveLocation: function requestArchiveLocation(location) {
      var _this3 = this;
      ConfirmService.createModal({
        name: 'confirm-archive-location',
        type: 'warning',
        title: this.$t('requestArchiveModal.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestArchiveModal.text', {
          locationName: location.name
        }),
        textIsHtml: true,
        okayButton: this.$t('requestArchiveModal.okay'),
        dismissButton: this.$t('requestArchiveModal.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this3.archiveLocation(location);
          }
        }
      });
    },
    unarchiveLocation: function unarchiveLocation(location) {
      var _this4 = this;
      this.isLoading = true;
      var companyId = this.$store.state.general.companyId;
      LocationAdministration.unarchiveLocation(companyId, location.id).then(function () {
        _this4.$store.commit('general/setAllLocations', []);
        _this4.loadData();
      }, function () {
        _this4.errorOccurred = true;
        _this4.isLoading = false;
      });
    },
    deleteLocation: function deleteLocation(location) {
      var _this5 = this;
      this.isLoading = true;
      var companyId = this.$store.state.general.companyId;
      LocationAdministration.deleteLocation(companyId, location.id).then(function () {
        _this5.$store.commit('general/setAllLocations', []);
        _this5.loadData();
      }, function () {
        _this5.errorOccurred = true;
        _this5.isLoading = false;
      });
    },
    requestDeleteLocation: function requestDeleteLocation(location) {
      var _this6 = this;
      ConfirmService.createModal({
        name: 'confirm-delete-location',
        type: 'danger',
        title: this.$t('requestDeleteModal.title'),
        icon: ['fas', 'exclamation-triangle'],
        text: this.$t('requestDeleteModal.text', {
          locationName: location.name
        }),
        textIsHtml: true,
        okayButton: this.$t('requestDeleteModal.okay'),
        dismissButton: this.$t('requestDeleteModal.dismiss'),
        onClose: function onClose(param) {
          if (param && param.okay) {
            _this6.deleteLocation(location);
          }
        }
      });
    }
  }
};