//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ChangeOverTimeChartLegend',
  props: {
    small: {
      type: Boolean,
      required: true
    },
    useIndexCalculation: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      showLegend: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    // show animation
    this.$nextTick(function () {
      _this.showLegend = true;
    });
  }
};