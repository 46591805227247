var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "confirm" },
    [
      _c("modal-icon-button", {
        attrs: {
          index: 0,
          icon: ["far", "times"],
          message: _vm.$t("tooltip.closeModal"),
          "color-type": "danger",
          "tooltip-placement": "right",
        },
        on: { click: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "main" }, [
        !_vm.textIsHtml
          ? _c("p", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))])
          : _c("p", {
              staticClass: "text",
              domProps: { innerHTML: _vm._s(_vm.text) },
            }),
        _vm._v(" "),
        _vm.requireMessage
          ? _c(
              "div",
              { staticClass: "input-message" },
              [
                _c("form-input", {
                  attrs: {
                    id: "input-message",
                    type: "text",
                    "max-length": 191,
                  },
                  model: {
                    value: _vm.message,
                    callback: function ($$v) {
                      _vm.message = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "message",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "button-row text-right" },
          [
            _vm.dismissButton
              ? _c(
                  "b-button",
                  {
                    attrs: { variant: "secondary", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.$vuedals.close({ okay: false })
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.dismissButton))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: {
                  variant: _vm.type === "danger" ? _vm.type : "primary",
                  size: "sm",
                  disabled: _vm.requireMessage && !_vm.message,
                },
                on: { click: _vm.submit },
              },
              [_vm._v(_vm._s(_vm.okayDefaultInternally))]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }