var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timeline-entry" },
    [
      _c(
        "b-card",
        {
          staticClass: "feedback hover mb-4",
          class: [
            {
              "is-spam": _vm.entry.isSpam,
              "has-email":
                _vm.entry.emailAnswers && _vm.entry.emailAnswers.length,
              "has-contact-request": _vm.entry.contactRequest,
              "average-index": _vm.hasIndexCalculation,
            },
            _vm.colorClass,
          ],
          on: { click: _vm.entryClicked },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.bottom.d200",
                  modifiers: { hover: true, bottom: true, d200: true },
                },
              ],
              staticClass: "spam",
              attrs: { title: _vm.$t("spamTitle") },
            },
            [_c("font-awesome", { attrs: { icon: ["fas", "trash-alt"] } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.bottom.d200",
                  modifiers: { hover: true, bottom: true, d200: true },
                },
              ],
              staticClass: "email",
              attrs: { title: _vm.$t("emailTitle") },
            },
            [_c("span", [_vm._v("E-MAIL")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.bottom.d200",
                  modifiers: { hover: true, bottom: true, d200: true },
                },
              ],
              staticClass: "contact-request",
              attrs: { title: _vm.$t("contactTitle") },
            },
            [_c("contact-request-icon")],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [
            _c("span", { staticClass: "location-name" }, [
              _vm._v(_vm._s(_vm.entry.locationName)),
            ]),
            _vm._v(" "),
            _vm.hasIndexCalculation && _vm.entry.averageIndex !== null
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottom.d200.html",
                        modifiers: {
                          hover: true,
                          bottom: true,
                          d200: true,
                          html: true,
                        },
                      },
                    ],
                    staticClass: "average-index",
                    attrs: { title: _vm.averageIndexTooltipText },
                  },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["far", "smile"] },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("groupedNumber")(
                            _vm.entry.averageIndex,
                            true,
                            1
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                )
              : _vm.hasIndexCalculation && _vm.entry.averageIndex === null
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottom.d200.html",
                        modifiers: {
                          hover: true,
                          bottom: true,
                          d200: true,
                          html: true,
                        },
                      },
                    ],
                    staticClass: "no-average-index",
                    attrs: { title: _vm.$t("noSatisfactionScore") },
                  },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["far", "smile"] },
                    }),
                  ],
                  1
                )
              : _c(
                  "span",
                  { staticClass: "satisfaction-label", class: _vm.colorClass },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.satisfactionLabel) + "\n      "
                    ),
                  ]
                ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "clearfix" }),
          _vm._v(" "),
          _vm.entry.commentAnswers.length > 0
            ? _c("div", { staticClass: "content" }, [
                _c("hr"),
                _vm._v(" "),
                _vm.entry.commentAnswers[0]
                  ? _c("span", { staticClass: "comment" }, [
                      _vm._v(_vm._s(_vm.entry.commentAnswers[0])),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.entry.commentAnswers[1]
                  ? _c(
                      "div",
                      { staticClass: "more-comments" },
                      [
                        _c("b-badge", { attrs: { variant: "secondary" } }, [
                          _vm._v("..."),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.hasIndexCalculation
        ? _c("span", { staticClass: "timeline-bullet" })
        : _c("span", {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.bottom.d200",
                modifiers: { hover: true, bottom: true, d200: true },
              },
            ],
            staticClass: "timeline-bullet",
            class: [
              { "satisfaction-rate": !_vm.hasIndexCalculation },
              _vm.colorClass,
            ],
            attrs: { title: _vm.satisfactionLabel },
          }),
      _vm._v(" "),
      _c("div", { staticClass: "time-ago" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.top.d200",
                modifiers: { hover: true, top: true, d200: true },
              },
            ],
            attrs: { title: _vm.formatDateTime(_vm.entry.timestamp) },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.formatDateFromNow(_vm.entry.timestamp)) +
                "\n    "
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }