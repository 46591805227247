import "core-js/modules/es.function.name.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalIconButton from '@/components/shared/ModalIconButton';
import SurveyManagementPanel from '@/components/survey-management/SurveyManagementPanel';
import { SurveyManagement } from '@/services/ResourceService';
import { convertEditQuestionToSubmitStructure } from '@/services/SurveyManagementService';
import FormStepIndicator from '@/components/shared/FormStepIndicator';
export default {
  name: 'SurveyManagementSuccess',
  components: {
    SurveyManagementPanel: SurveyManagementPanel,
    FormStepIndicator: FormStepIndicator,
    ModalIconButton: ModalIconButton
  },
  created: function created() {
    if (!this.editPageState.data.success && !this.editPageState.data.error) {
      if (this.editPageState.name === 'SAVING_ADDING') {
        this.saveAdding();
      } else if (this.editPageState.name === 'SAVING_EDIT') {
        this.saveEditing();
      } else {
        throw new Error('Invalid save state.');
      }
    }
  },
  methods: {
    saveAdding: function saveAdding() {
      var _this = this;
      var errorHandler = function errorHandler() {
        return _this.$store.dispatch('surveyManagement/editPageStateSavingAdding', {
          questionNr: _this.editPageState.data.questionNr,
          questionId: _this.editPageState.data.questionId,
          questionType: _this.editPageState.data.questionType,
          success: false,
          error: true
        });
      };
      var successHandler = function successHandler() {
        return _this.$store.dispatch('surveyManagement/editPageStateSavingAdding', {
          questionNr: _this.editPageState.data.questionNr,
          questionId: _this.editPageState.data.questionId,
          questionType: _this.editPageState.data.questionType,
          success: true,
          error: false
        });
      };
      return Promise.all([SurveyManagement.addExistingQuestionToSurvey(this.$store.state.general.companyId, this.$store.state.surveyManagement.selectedSurveyId, {
        expectedQuestionNrZeroBased: this.editPageState.data.questionNr,
        questionType: this.editPageState.data.questionType,
        questionId: this.editPageState.data.questionId
      }), this.getTimeoutPromise(1000)]).then(successHandler, errorHandler);
    },
    saveEditing: function saveEditing() {
      var _this2 = this;
      var errorHandler = function errorHandler() {
        return _this2.$store.dispatch('surveyManagement/editPageStateSavingEdit', {
          questionNr: _this2.editPageState.data.questionNr,
          question: _this2.editPageState.data.question,
          editQuestion: _this2.editPageState.data.editQuestion,
          isNew: _this2.editPageState.data.isNew,
          success: false,
          error: true
        });
      };
      var successHandler = function successHandler() {
        return _this2.$store.dispatch('surveyManagement/editPageStateSavingEdit', {
          questionNr: _this2.editPageState.data.questionNr,
          question: _this2.editPageState.data.question,
          editQuestion: _this2.editPageState.data.editQuestion,
          isNew: _this2.editPageState.data.isNew,
          success: true,
          error: false
        });
      };
      var dto = convertEditQuestionToSubmitStructure(this.editPageState.data.editQuestion, this.editPageState.data.question.questionType, this.editPageState.data.question.questionId, this.editPageState.data.questionNr);
      if (this.editPageState.data.isNew) {
        return Promise.all([SurveyManagement.createNewQuestion(this.$store.state.general.companyId, this.$store.state.surveyManagement.selectedSurveyId, dto), this.getTimeoutPromise(1000)]).then(successHandler, errorHandler);
      } else {
        return Promise.all([SurveyManagement.updateQuestion(this.$store.state.general.companyId, this.$store.state.surveyManagement.selectedSurveyId, dto), this.getTimeoutPromise(1000)]).then(successHandler, errorHandler);
      }
    },
    getTimeoutPromise: function getTimeoutPromise(delay) {
      return new Promise(function (resolve) {
        window.setTimeout(function () {
          resolve();
        }, delay);
      });
    },
    close: function close() {
      var _this3 = this;
      return this.$store.dispatch('surveyManagement/editPageStateInitial').then(function () {
        _this3.$store.dispatch('surveyManagement/fetchFullSurvey');
      });
    }
  },
  computed: {
    editPageState: function editPageState() {
      return this.$store.state.surveyManagement.editPageState || {};
    },
    currentStepIndex: function currentStepIndex() {
      var stepIndex = 0;
      if (this.editPageState.name === 'SAVING_ADDING') {
        stepIndex++;
      } else if (this.editPageState.name === 'SAVING_EDIT') {
        if (this.editPageState.data.isNew) {
          stepIndex++;
        }
        stepIndex++;
        if (['RATING_QUESTION_SMILEY', 'RATING_QUESTION_SLIDER'].includes(this.editPageState.data.question.questionType)) {
          stepIndex++;
        }
        if (['CHOICE_QUESTION_SINGLE_CHOICE', 'CHOICE_QUESTION_MULTIPLE_CHOICE'].includes(this.editPageState.data.question.questionType)) {
          stepIndex++;
        }
      }
      if (this.editPageState.data.success) {
        stepIndex++;
      }
      return stepIndex;
    },
    steps: function steps() {
      var steps = [];
      if (this.editPageState.name === 'SAVING_ADDING') {
        steps.push(this.$t('pageName.addQuestion'));
      } else if (this.editPageState.name === 'SAVING_EDIT') {
        if (this.editPageState.data.isNew) {
          steps.push(this.$t('pageName.createNew'));
        }
        steps.push(this.$t('pageName.edit'));
        if (['RATING_QUESTION_SMILEY', 'RATING_QUESTION_SLIDER'].includes(this.editPageState.data.question.questionType)) {
          steps.push(this.$t('pageName.reasons'));
        }
        if (['CHOICE_QUESTION_SINGLE_CHOICE', 'CHOICE_QUESTION_MULTIPLE_CHOICE'].includes(this.editPageState.data.question.questionType)) {
          steps.push(this.$t('pageName.choiceAttributes'));
        }
      }
      steps.push(this.$t('pageName.success'));
      return steps;
    }
  }
};