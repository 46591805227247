import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SurveyManagementQuestionWrapper',
  props: {
    questionNr: {
      type: Number,
      required: true,
      validator: function validator(item) {
        return item >= 0;
      }
    },
    isFirstQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isLastQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    hideNumber: {
      type: Boolean,
      required: false,
      default: false
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    },
    darkened: {
      type: Boolean,
      required: false,
      default: false
    },
    wasRecentlySorted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isOnlyQuestion: function isOnlyQuestion() {
      return this.isLastQuestion && this.isFirstQuestion;
    },
    editPageState: function editPageState() {
      return this.$store.state.surveyManagement.editPageState || {};
    },
    questionHasMargin: function questionHasMargin() {
      if (!this.editPageState.data || this.editPageState.data.questionNr !== this.questionNr) {
        return false;
      }
      if (['EDIT_QUESTION', 'SAVING_EDIT'].includes(this.editPageState.name) && this.editPageState.data.isNew) {
        return true;
      }
      if (['ADD_CREATE_QUESTION', 'SAVING_ADDING'].includes(this.editPageState.name)) {
        return true;
      }
      return false;
    }
  }
};