//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LoadingIndicator',
  props: {
    hasOverlay: {
      type: Boolean,
      required: false,
      default: false
    },
    positionFixed: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};