var fieldNames = {
  WEB_SOURCE_ID: 'WEB_SOURCE_ID',
  DESCRIPTION: 'DESCRIPTION',
  LOCATIONS: 'LOCATIONS',
  USE_RECAPTCHA: 'USE_RECAPTCHA',
  EMBEDDED_TYPE: 'EMBEDDED_TYPE',
  LOCATION_PICKER: {
    BACKGROUND_IMAGE: 'BACKGROUND_IMAGE',
    LOGO_DARK: 'LOGO_DARK',
    LOGO_BRIGHT: 'LOGO_BRIGHT',
    LOCATION_GROUP_PICKER_TEXT: 'LOCATION_GROUP_PICKER_TEXT',
    LOCATION_PICKER_TEXT: 'LOCATION_PICKER_TEXT',
    DEFAULT_LANGUAGE: 'DEFAULT_LANGUAGE',
    LOCATION_GROUP_PICKER_ENABLED: 'LOCATION_GROUP_PICKER_ENABLED',
    ENABLED_LANGUAGES: 'ENABLED_LANGUAGES'
  }
};
export { fieldNames };