var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashed-box" }, [
    _c(
      "div",
      {
        staticClass: "inner-box",
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      [
        _c("div", { staticClass: "center-wrapper" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.$t("text")))]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plus-wrapper" }, [
      _c("div", { staticClass: "plus" }, [_vm._v("+")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }