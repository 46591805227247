import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.push.js";
import axios from 'axios';
import qs from 'qs';
import { API_URL } from '@/config/constants';
import ProgressBar from '@/config/ProgressBar';
import Router from '@/config/Router';
import Translation from '@/config/Translation';
import Store from '@/store/Store';
import Auth from '@/services/auth/Auth';
import Vue from 'vue';
import { Bus as VuedalsBus } from 'vuedals';
var Http = axios.create({
  baseURL: API_URL,
  // Since axios doesn't support multiple values for a single key (?key=1&key=2), we use qs.stringify()
  // as suggested here: https://github.com/mzabriskie/axios#request-config
  // @see https://github.com/mzabriskie/axios/issues/777
  paramsSerializer: function paramsSerializer(params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat'
    });
  }
});

// Request interceptor
Http.interceptors.request.use(function (config) {
  config.xsrfCookieName = 'XSRF-TOKEN';
  config.xsrfHeaderName = 'X-XSRF-TOKEN';
  ProgressBar.start();
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Response interceptor
Http.interceptors.response.use(function (response) {
  ProgressBar.done();
  return response;
}, function (error) {
  ProgressBar.done();
  return Promise.reject(error);
});
Http.interceptors.response.use(undefined, function (error) {
  if (error.response && error.config && error.request) {
    var status = getStatusCodeFromErrorResponse(error);
    if (status === 401 || status === 403) {
      if (error.config.url === '/api/account' && error.config.method === 'get' || /\/?logout/.test(error.config.url) || /\/?authentication/.test(error.config.url) || /^\/print/.test(Router.currentRoute.fullPath)) {
        return Promise.reject(error);
      }
      Vue.toasted.global.info(Translation.t('toasts.expired'));
      Auth.logout();
      VuedalsBus.$emit('close', {
        $index: 0
      });
    }
  }
  return Promise.reject(error);
});
Http.interceptors.response.use(function (response) {
  Store.commit('general/setUnderMaintenance', false);
  return response;
}, function (error) {
  var status = getStatusCodeFromErrorResponse(error);
  var backendOffline = /^50(3|4)$/.test(status);
  if (status >= 500 && !backendOffline) {
    Vue.toasted.global.error(Translation.t('toasts.internalError', {
      status: status
    }));
  }
  if (backendOffline) {
    Store.commit('general/setUnderMaintenance', true);
    Router.push({
      name: 'maintenance'
    });
    VuedalsBus.$emit('close', {
      $index: 0
    });
  } else {
    Store.commit('general/setUnderMaintenance', false);
  }
  return Promise.reject(error);
});
function getStatusCodeFromErrorResponse(error) {
  return error.response && error.response.status && parseInt(error.response.status, 10) ? parseInt(error.response.status, 10) : 0;
}
export default Http;