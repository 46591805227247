import _toConsumableArray from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.error.cause.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import ComparisonService from '@/services/ComparisonService';
export default {
  name: 'SurveyManagementOverviewTable',
  props: {
    surveys: {
      type: Array,
      required: true
    },
    filter: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    tableFields: function tableFields() {
      var _this = this;
      // Fields in computed allows translation to adapt
      return [{
        key: 'surveyName',
        label: this.$t('fields.surveyName'),
        sortable: true
      }, {
        key: 'numberOfEnabledQuestions',
        label: this.$t('fields.questionsTotal'),
        sortable: true
      }, {
        key: 'usedInNumberOfActiveLocations',
        label: this.$t('fields.status'),
        sortable: true,
        sortByFormatted: true,
        formatter: function formatter(value, key, item) {
          return item.usedInNumberOfActiveLocations > 0;
        }
      }, {
        key: 'enabledLanguages',
        label: this.$t('fields.enabledLanguages'),
        sortable: false,
        formatter: function formatter(languages) {
          // Map array of [DE, EN, et.c] to translated languages
          var translatedLanguages = languages.map(function (language) {
            return _this.$t('languages.' + language.toLowerCase());
          });
          var enabledLanguages = {
            hasMultipleLanguages: false,
            enabledLanguagesJoined: languages[0],
            enabledLanguagesCount: languages.length
          };
          if (languages.length > 1) {
            // If multiple languages in survey, sort translated languages and join array into readable list
            var sortedLanguages = _toConsumableArray(translatedLanguages).sort(function (a, b) {
              return a.localeCompare(b);
            });
            enabledLanguages.hasMultipleLanguages = true;
            enabledLanguages.enabledLanguagesJoined = sortedLanguages.join(', ');
          }
          return enabledLanguages;
        }
      }, {
        key: 'lastAnswerSetNotSpamWithThisSurvey',
        label: this.$t('fields.lastFeedback'),
        sortable: true,
        formatter: function formatter(value) {
          return value ? moment(value) : value;
        }
      }, {
        key: 'actions',
        label: '',
        sortable: false
      }];
    }
  },
  methods: {
    sortCompare: function sortCompare(aRow, bRow, key, sortDesc, formatter) {
      var a = formatter ? formatter(aRow[key], key, aRow) : aRow[key];
      var b = formatter ? formatter(bRow[key], key, bRow) : bRow[key];
      if (key === 'surveyName') {
        return a.localeCompare(b);
      } else if (key === 'numberOfAnswerSetsIncludingSpam' || key === 'usedInNumberOfActiveLocations' || key === 'lastAnswerSetNotSpamWithThisSurvey' || key === 'numberOfEnabledQuestions') {
        return ComparisonService.compare(a, b);
      }
      throw new Error("Sorting by key '".concat(key, "' is not enabled."));
    },
    archiveSurvey: function archiveSurvey(survey) {
      this.$emit('archive-survey', survey);
    },
    unarchiveSurvey: function unarchiveSurvey(survey) {
      this.$emit('unarchive-survey', survey);
    },
    deleteSurvey: function deleteSurvey(survey) {
      this.$emit('delete-survey', survey);
    },
    assignSurvey: function assignSurvey(survey) {
      this.$emit('assign-survey', survey);
    }
  }
};