//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChangeOverTimeChart from '@/components/shared/change-over-time/ChangeOverTimeChart';
import { getDefaultSelectedInterval } from '@/services/ChangeOverTimeHelperService';
export default {
  name: 'PrintChangeOverTime',
  components: {
    ChangeOverTimeChart: ChangeOverTimeChart
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    selectedInterval: function selectedInterval() {
      return getDefaultSelectedInterval(this.data);
    },
    selectedData: function selectedData() {
      if (this.selectedInterval === null) {
        return [];
      }
      return this.data[this.selectedInterval].data;
    }
  }
};