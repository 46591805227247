import _objectSpread from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import Alert from '@/components/shared/Alert';
import opnDateRangePicker from '@/components/shared/opnDateRangePicker';
import LocationGroupSelect from '@/components/shared/LocationGroupSelect';
import moment from 'moment';
import QueryUtils from '@/services/utils/QueryUtils';
import { API_URL } from '@/config/constants';
import { Pdf } from '@/services/ResourceService';
import FormatUtils from '@/services/utils/FormatUtils';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import SearchFilterSelector from '@/components/shared/saved-search-filter/SearchFilterSelector';
import { mapState } from 'vuex';
export default {
  name: 'PdfReport',
  components: {
    Panel: Panel,
    Alert: Alert,
    opnDateRangePicker: opnDateRangePicker,
    LocationGroupSelect: LocationGroupSelect,
    SearchFilterSelector: SearchFilterSelector
  },
  data: function data() {
    var _this = this;
    var allowedLanguages = ['DE', 'EN', 'FR', 'IT'];
    return {
      isLoading: false,
      noData: false,
      checkData: {},
      form: {
        dateRange: [],
        locationIds: [],
        // TODO [clean-code] this could be completely replaced by location.queryLocations
        location: {}
      },
      language: function () {
        return _this.$store.state && _this.$store.state.general.user && _this.$store.state.general.user.language && allowedLanguages.includes(_this.$store.state.general.user.language) ? _this.$store.state.general.user.language : 'DE';
      }(),
      allowedLanguages: allowedLanguages,
      components: [],
      dateFormat: 'DD.MM.YYYY',
      checkIsWaiting: false,
      enableFormWatcher: false,
      componentOptions: ['BASIC', 'QUICK_STATISTICS', 'CHANGE_OVER_TIME', 'LOCATION_PERFORMANCE', 'SURVEY_ANALYSIS', 'CUSTOM_ANSWERS', 'TEXT_ANSWERS', 'MATRIX', 'COMMENTS']
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    currentUser: function currentUser(state) {
      return state.general.user;
    }
  })), {}, {
    currentUserIsSuperAdmin: function currentUserIsSuperAdmin() {
      return this.currentUser.authorities.includes('ROLE_SUPER_ADMIN');
    },
    requestUrl: function requestUrl() {
      var companyId = this.$store.state.general.companyId;
      var url = "/company/".concat(companyId, "/export/pdf-report");
      var startParam = 'start=' + moment(this.form.dateRange[0]).format(this.dateFormat);
      var endParam = 'end=' + moment(this.form.dateRange[1]).format(this.dateFormat);
      var langParam = 'language=' + this.language;
      var componentsParam = 'components=' + this.components.join(',');
      var locationParam = 'l=' + this.form.locationIds.join(',');
      var requestUrlWithoutLocations = "".concat(API_URL).concat(url, "?").concat(startParam, "&").concat(endParam, "&").concat(langParam, "&").concat(componentsParam);
      if (this.form.locationIds.length === 0) {
        return requestUrlWithoutLocations;
      }
      return "".concat(requestUrlWithoutLocations, "&").concat(locationParam);
    },
    tooManyComments: function tooManyComments() {
      return !this.isLoading && !this.noData && this.checkData.numberOfComments > 500;
    },
    tooManySurveys: function tooManySurveys() {
      return !this.isLoading && !this.noData && this.checkData.numberOfSurveys > 7;
    },
    checkDataTitle: function checkDataTitle() {
      if (!this.isLoading && !this.noData) {
        return this.$t('tooltip.checkDataTitle', {
          numberOfFeedbacks: FormatUtils.escapeHTML(this.$options.filters.groupedNumber(this.checkData.numberOfFeedbacks)),
          numberOfSurveys: FormatUtils.escapeHTML(this.$options.filters.groupedNumber(this.checkData.numberOfSurveys)),
          numberOfComments: FormatUtils.escapeHTML(this.$options.filters.groupedNumber(this.checkData.numberOfComments))
        });
      }
      return '';
    }
  }),
  created: function created() {
    var _this2 = this;
    this.form.dateRange = QueryUtils.getDateRangeFilter();
    if (this.allowedLanguages.indexOf(this.$store.state.general.language) !== -1) {
      this.language = this.$store.state.general.language;
    }
    this.components = function () {
      var checkedComponents = [];
      _this2.componentOptions.forEach(function (item) {
        if (item !== 'COMMENTS') {
          checkedComponents.push(item);
        }
      });
      return checkedComponents;
    }();
    this.checkParameters();
    CompanyPropertiesService.update();
  },
  methods: {
    checkParameters: function checkParameters() {
      var _this3 = this;
      if (this.isLoading) {
        this.checkIsWaiting = true;
        return;
      } else {
        this.checkIsWaiting = false;
      }
      this.isLoading = true;
      this.noData = false;
      this.checkData = {};
      Pdf.check.apply(null, [this.$store.state.general.companyId, this.form.locationIds, moment(this.form.dateRange[0]).format(this.dateFormat), moment(this.form.dateRange[1]).format(this.dateFormat), this.components, this.language]).then(function (response) {
        _this3.isLoading = false;
        _this3.enableFormWatcher = true;
        _this3.checkData = response.data;
        if (_this3.checkIsWaiting) {
          _this3.checkParameters();
        }
      }, function () {
        _this3.isLoading = false;
        _this3.enableFormWatcher = true;
        _this3.noData = true;
        if (_this3.checkIsWaiting) {
          _this3.checkParameters();
        }
      });
    },
    dateRangeChanged: function dateRangeChanged(newDateRange) {
      this.form.dateRange = [moment(newDateRange[0]).toDate(), moment(newDateRange[1]).toDate()];
    },
    changeLocations: function changeLocations(newLocations) {
      if (newLocations.queryLocations) {
        this.form.locationIds = newLocations.queryLocations;
        this.form.location = newLocations;
      } else {
        this.form.locationIds = [];
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function handler() {
        if (this.enableFormWatcher) {
          this.checkParameters();
        }
      }
    },
    tooManyComments: function tooManyComments(newValue) {
      if (newValue) {
        this.components = this.components.filter(function (component) {
          return component !== 'COMMENTS';
        });
      }
    },
    tooManySurveys: function tooManySurveys(newValue) {
      if (newValue) {
        this.components = this.components.filter(function (component) {
          return component !== 'SURVEY_ANALYSIS';
        });
      }
    },
    components: function components(newValue) {
      if (newValue && !newValue.includes('SURVEY_ANALYSIS') && (newValue.includes('CUSTOM_ANSWERS') || newValue.includes('TEXT_ANSWERS'))) {
        this.components = this.components.filter(function (component) {
          return component !== 'CUSTOM_ANSWERS' && component !== 'TEXT_ANSWERS';
        });
      }
    }
  }
};