//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import Alert from '@/components/shared/Alert';
import { Notification } from '@/services/ResourceService';
import ScrollAnimatorService from '@/services/ScrollAnimatorService';
export default {
  name: 'EditNotifications',
  data: function data() {
    return {
      form: {
        REGULAR: {
          enabled: false,
          interval: 'EVERY_WEEK'
        },
        CONTACT_REQUEST: {
          enabled: false,
          interval: 'EVERY_DAY'
        }
      },
      initialLoading: true,
      isLoading: false,
      successfullySaved: false,
      errorOccurred: false,
      fatalErrorOccurred: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (!this.isSuperAdmin) {
      Notification.get().then(function (response) {
        if (response.data !== null) {
          _this.form = response.data;
        }
        _this.initialLoading = false;
      }, function () {
        _this.fatalErrorOccurred = true;
        _this.initialLoading = false;
      });
    }
  },
  components: {
    Panel: Panel,
    Alert: Alert
  },
  methods: {
    saveInfo: function saveInfo() {
      var _this2 = this;
      if (!this.isSuperAdmin) {
        this.isLoading = true;
        this.successfullySaved = false;
        this.errorOccurred = false;
        var requestBody = this.form;
        Notification.update(requestBody).then(function () {
          _this2.successfullySaved = true;
          _this2.isLoading = false;
          ScrollAnimatorService.scrollToTopOfElement(_this2.$el);
        }, function () {
          _this2.errorOccurred = true;
          _this2.isLoading = false;
          ScrollAnimatorService.scrollToTopOfElement(_this2.$el);
        });
      }
    }
  },
  computed: {
    isSuperAdmin: function isSuperAdmin() {
      return this.$store.state.general.user && this.$store.state.general.user.authorities && this.$store.state.general.user.authorities.indexOf('ROLE_SUPER_ADMIN') > -1;
    }
  }
};