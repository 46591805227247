export default {
  setOverviewTable: function setOverviewTable(state, data) {
    data.refreshDate = new Date();
    state.overviewTable = data;
  },
  setFullSurvey: function setFullSurvey(state, data) {
    data.refreshDate = new Date();
    state.fullSurvey = data;
  },
  setCategories: function setCategories(state, data) {
    data.refreshData = new Date();
    state.categories = data;
  },
  setSelectedSurveyId: function setSelectedSurveyId(state, surveyId) {
    state.selectedSurveyId = surveyId;
  },
  setEditPageState: function setEditPageState(state, editPageState) {
    state.editPageState = editPageState;
  },
  setEditPageStateLasFocus: function setEditPageStateLasFocus(state, isFocused) {
    state.editPageState.data.anyLasIsFocused = isFocused;
  }
};