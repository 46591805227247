var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-criteria", class: _vm.variant },
    [_vm._t("default", [_vm._v(_vm._s(_vm.text))])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }