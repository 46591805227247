import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import opnProgress from '@/components/shared/opnProgress';
export default {
  name: 'PrintAttributeDistributionChart',
  components: {
    opnProgress: opnProgress
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: function validator(item) {
        return ['ratingReason', 'choiceAttribute'].includes(item);
      }
    },
    surveyId: {
      type: Number,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  computed: {
    attributes: function attributes() {
      if (this.question.questionType === 'RATING_QUESTION') {
        return this.question.ratingReasons;
      } else if (this.question.questionType === 'CHOICE_QUESTION') {
        return this.question.choiceAttributes;
      }
      return null;
    }
  },
  methods: {}
};