var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "loading-placeholder" },
              [_c("questions-loading")],
              1
            )
          : _c(
              "div",
              {
                key: "loaded",
                staticClass: "questions",
                class: { "is-narrow": _vm.isNarrow },
              },
              _vm._l(_vm.data.questions, function (q, index) {
                return _c("question", {
                  key: q.questionType + "_" + q.questionId,
                  attrs: {
                    question: q,
                    "survey-id": _vm.data.surveyId,
                    "query-parameters": _vm.queryParameters,
                    counter: index + 1,
                    "is-narrow": _vm.isNarrow,
                  },
                })
              }),
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }