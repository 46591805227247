//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
export default {
  name: 'ContactRequestIcon',
  components: {
    FontAwesomeLayers: FontAwesomeLayers
  },
  props: {
    circleColor: {
      type: String,
      required: false,
      default: 'white'
    }
  }
};