export default {
  user: null,
  language: null,
  isAuthenticated: false,
  allLocations: [],
  selectedUser: null,
  companyId: null,
  underMaintenance: false,
  comparison: null,
  printView: false,
  companyProperties: null,
  ie: false,
  mainProperties: null,
  possibleLanguagesLas: ['DE', 'FR', 'IT', 'EN', 'ES', 'PT', 'SQ', 'MK', 'TR'] // see Language.java
};