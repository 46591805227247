var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attribute-distribution-chart" },
    _vm._l(_vm.attributes, function (attribute) {
      return _c("div", { staticClass: "row distribution-row" }, [
        _vm.type === "ratingReason" && attribute.type === "STANDARD"
          ? _c("div", { staticClass: "rating-reason" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-6 overflow-ellipsis attribute-distribution-title",
                  attrs: {
                    title: _vm.$options.filters.languageAwareString(
                      attribute.text,
                      true
                    ),
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.languageAwareString(attribute.text)
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 attribute-distribution-bar" }, [
                _c(
                  "div",
                  { staticClass: "bar" },
                  [
                    _c("opn-progress", {
                      attrs: {
                        value: attribute.distribution.percent,
                        "is-pdf": "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bar-text" },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["fas", "user"] },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("groupedNumber")(
                            attribute.distribution.absolute
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ])
          : _vm.type === "ratingReason" && attribute.type === "CUSTOM"
          ? _c("div", { staticClass: "custom-rating-reason" }, [
              _c(
                "div",
                { staticClass: "col-6 attribute-distribution-title" },
                [
                  _c("font-awesome", {
                    staticClass: "font-awesome icon-pencil",
                    attrs: { icon: ["fas", "comment-edit"] },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.languageAwareString(attribute.text)
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-6 attribute-distribution-bar" }, [
                _c(
                  "div",
                  { staticClass: "bar" },
                  [
                    _c("opn-progress", {
                      attrs: {
                        value: attribute.distribution.percent,
                        "is-pdf": "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bar-text" },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["fas", "user"] },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("groupedNumber")(
                            attribute.distribution.absolute
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ])
          : _vm.type === "choiceAttribute" && attribute.type === "STANDARD"
          ? _c("div", { staticClass: "choice-attribute" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-4 overflow-ellipsis attribute-distribution-title",
                  attrs: {
                    title: _vm.$options.filters.languageAwareString(
                      attribute.text,
                      true
                    ),
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.languageAwareString(attribute.text)
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-8 attribute-distribution-bar" }, [
                _c(
                  "div",
                  { staticClass: "bar" },
                  [
                    _c("opn-progress", {
                      attrs: {
                        value: attribute.distribution.percent,
                        "is-pdf": "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bar-text" },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["fas", "user"] },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("groupedNumber")(
                            attribute.distribution.absolute
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ])
          : _vm.type === "choiceAttribute" && attribute.type === "CUSTOM"
          ? _c("div", { staticClass: "custom-choice-attribute" }, [
              _c(
                "div",
                { staticClass: "col-4 attribute-distribution-title" },
                [
                  _c("font-awesome", {
                    staticClass: "font-awesome icon-pencil",
                    attrs: { icon: ["fas", "comment-edit"] },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$options.filters.languageAwareString(attribute.text)
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-8 attribute-distribution-bar" }, [
                _c(
                  "div",
                  { staticClass: "bar" },
                  [
                    _c("opn-progress", {
                      attrs: {
                        value: attribute.distribution.percent,
                        "is-pdf": "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bar-text" },
                  [
                    _c("font-awesome", {
                      staticClass: "font-awesome",
                      attrs: { icon: ["fas", "user"] },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("groupedNumber")(
                            attribute.distribution.absolute
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }