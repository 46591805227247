//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Panel from '@/components/shared/Panel';
import Alert from '@/components/shared/Alert';
import ValidationErrorList from '@/components/shared/ValidationErrorList';
import { Account } from '@/services/ResourceService';
import ScrollAnimatorService from '@/services/ScrollAnimatorService';
import ValidationHelperService from '@/services/ValidationHelperService';
export default {
  name: 'ChangeUserData',
  data: function data() {
    return {
      form: {},
      isLoading: false,
      serverErrorOccurred: false,
      validationErrorOccurred: false,
      validationErrors: null,
      successfullySaved: false
    };
  },
  mounted: function mounted() {
    var user = this.$store.state.general.user;
    this.form = {
      email: user.email,
      firstName: user.firstName,
      language: user.language,
      lastName: user.lastName,
      login: user.login
    };
  },
  components: {
    Panel: Panel,
    Alert: Alert,
    ValidationErrorList: ValidationErrorList
  },
  methods: {
    saveInfo: function saveInfo() {
      var _this = this;
      this.isLoading = true;
      this.successfullySaved = false;
      this.serverErrorOccurred = false;
      var submitForm = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        language: this.form.language
      };
      Account.update(submitForm).then(function () {
        _this.successfullySaved = true;
        _this.validationErrorOccurred = false;
        _this.validationErrors = null;
        _this.$store.commit('general/updateAccount', _this.form);
        _this.$locale.change(_this.form.language.toLowerCase());
        _this.isLoading = false;
        ScrollAnimatorService.scrollToTopOfElement(_this.$el);
      }, function (error) {
        _this.isLoading = false;
        var data = error.response.data;
        var errCode = data.status.code;
        var payload = data.payload;
        if (errCode === 400 && payload && payload.length > 0) {
          _this.validationErrorOccurred = true;
          _this.validationErrors = ValidationHelperService.parseValidationErrorsWithTranslations(payload, _this);
        } else {
          _this.serverErrorOccurred = true;
        }
        ScrollAnimatorService.scrollToTopOfElement(_this.$el);
      });
    },
    getFieldState: function getFieldState(fieldName) {
      return ValidationHelperService.getFieldState(fieldName, this.validationErrors);
    },
    getFieldErrors: function getFieldErrors(fieldName) {
      return ValidationHelperService.getFieldErrors(fieldName, this.validationErrors);
    }
  }
};