import _objectSpread from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.error.cause.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ComparisonService from '@/services/ComparisonService';
import LocationGroupTableEntry from '@/components/shared/LocationGroupTableEntry';
import { mapState } from 'vuex';
import { DisplayUsernamesConfigurations } from '@/constants/displayUsernamesConfigurations';
var FieldKeys = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  FIRST_NAME_LAST_NAME: 'firstNameLastName',
  LAST_NAME_FIRST_NAME: 'lastNameFirstName',
  EMAIL: 'email',
  ROLE: 'role',
  LOCATIONS: 'locations',
  LAST_LOGIN: 'last-login',
  ACTIONS: 'actions',
  ACTIVATED: 'activated'
};
export default {
  name: 'UserManagementOverviewTable',
  components: {
    LocationGroupTableEntry: LocationGroupTableEntry
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    filter: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'active'
    },
    displayUserNamesConfiguration: {
      type: String,
      required: false,
      default: DisplayUsernamesConfigurations.FIRSTNAME_LASTNAME_TWO_COLUMNS
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    currentUserId: function currentUserId(state) {
      return state.general.user.id;
    }
  })), {}, {
    isTableActiveUsers: function isTableActiveUsers() {
      return this.type === 'active';
    },
    isTableDeactivatedUsers: function isTableDeactivatedUsers() {
      return this.type === 'deactivated';
    },
    fields: function fields() {
      // fields in computed allows translation to adapt
      var result = [{
        key: FieldKeys.EMAIL,
        label: this.$t('fields.email'),
        sortable: true
      }, {
        key: FieldKeys.ROLE,
        label: this.$t('fields.role'),
        sortable: true
      }, {
        key: FieldKeys.LOCATIONS,
        label: this.$t('fields.locations'),
        sortable: false
      }, {
        key: FieldKeys.LAST_LOGIN,
        label: this.$t('fields.last-login'),
        sortable: true
      }, {
        key: FieldKeys.ACTIONS,
        label: ' ',
        sortable: false
      }];
      if (this.displayUserNamesConfiguration === DisplayUsernamesConfigurations.FIRSTNAME_LASTNAME_SAME_COLUMN) {
        result.unshift({
          key: FieldKeys.FIRST_NAME_LAST_NAME,
          label: this.$t('fields.name'),
          sortable: true
        });
      } else if (this.displayUserNamesConfiguration === DisplayUsernamesConfigurations.LASTNAME_FIRSTNAME_SAME_COLUMN) {
        result.unshift({
          key: FieldKeys.LAST_NAME_FIRST_NAME,
          label: this.$t('fields.name'),
          sortable: true
        });
      } else if (this.displayUserNamesConfiguration === DisplayUsernamesConfigurations.FIRSTNAME_LASTNAME_TWO_COLUMNS) {
        result.unshift({
          key: FieldKeys.LAST_NAME,
          label: this.$t('fields.lastName'),
          sortable: true
        });
        result.unshift({
          key: FieldKeys.FIRST_NAME,
          label: this.$t('fields.firstName'),
          sortable: true
        });
      } else if (this.displayUserNamesConfiguration === DisplayUsernamesConfigurations.LASTNAME_FIRSTNAME_TWO_COLUMNS) {
        result.unshift({
          key: FieldKeys.FIRST_NAME,
          label: this.$t('fields.firstName'),
          sortable: true
        });
        result.unshift({
          key: FieldKeys.LAST_NAME,
          label: this.$t('fields.lastName'),
          sortable: true
        });
      }
      return result;
    }
  }),
  methods: {
    sortCompare: function sortCompare(aRow, bRow, key) {
      var a = aRow[key];
      var b = bRow[key];
      if (key === FieldKeys.FIRST_NAME_LAST_NAME) {
        a = [aRow[FieldKeys.FIRST_NAME], aRow[FieldKeys.LAST_NAME]].join(' ');
      } else if (key === FieldKeys.LAST_NAME_FIRST_NAME) {
        a = [aRow[FieldKeys.LAST_NAME], aRow[FieldKeys.FIRST_NAME]].join(' ');
      }
      if (key === FieldKeys.FIRST_NAME || key === FieldKeys.LAST_NAME || key === FieldKeys.EMAIL || key === FieldKeys.FIRST_NAME_LAST_NAME || key === FieldKeys.LAST_NAME_FIRST_NAME) {
        return ComparisonService.compareStrings(a, b);
      } else if (key === FieldKeys.ROLE) {
        return ComparisonService.compareRoles(a, b);
      } else if (key === FieldKeys.ACTIVATED || key === FieldKeys.LAST_LOGIN) {
        // Invert sort order so last login is first / activated users are first
        return ComparisonService.compare(b, a);
      }
      throw new Error("Sorting by key '".concat(key, "' is not activated."));
    },
    deactivateUser: function deactivateUser(item) {
      this.$emit('deactivate-user', item);
    },
    activateUser: function activateUser(item) {
      this.$emit('activate-user', item);
    },
    deleteUser: function deleteUser(item) {
      this.$emit('delete-user', item);
    }
  }
};