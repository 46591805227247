import "core-js/modules/es.object.to-string.js";
import Store from '@/store/Store';
import { CompanyProperties } from '@/services/ResourceService';
export default {
  update: function update() {
    return CompanyProperties.fetch(Store.state.general.companyId).then(function (response) {
      var companyProperties = response.data;
      Store.commit('general/setCompanyProperties', companyProperties);
      return Promise.resolve(companyProperties);
    }, function (response) {
      return response;
    });
  }
};