//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FormStepIndicator',
  props: {
    steps: {
      type: Array,
      required: true
    },
    stepIndex: {
      type: Number,
      required: true
    }
  }
};