var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "questions-loading" },
    _vm._l(5, function (item) {
      return _c("div", { key: item, staticClass: "box" }, [
        _vm._m(0, true),
        _vm._v(" "),
        _c("div", { staticClass: "small-title" }),
        _vm._v(" "),
        _c("div", { staticClass: "large-title" }),
        _vm._v(" "),
        _c("div", { staticClass: "circle" }),
        _vm._v(" "),
        _c("div", { staticClass: "separator" }),
        _vm._v(" "),
        _c("div", { staticClass: "right-element" }),
        _vm._v(" "),
        _c("div", { staticClass: "right-element" }),
        _vm._v(" "),
        _c("div", { staticClass: "right-element" }),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "timeline-bubble" }, [
      _c("div", { staticClass: "line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }