import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import { getColorNameByIndex } from '@/services/ColorService';
import FormatUtils from '@/services/utils/FormatUtils';
import ContactRequestIcon from '@/components/shared/ContactRequestIcon';
export default {
  name: 'Feedback',
  components: {
    ContactRequestIcon: ContactRequestIcon
  },
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  computed: {
    satisfactionLabel: function satisfactionLabel() {
      if (this.entry.isSatisfied === true) {
        return this.$t('satisfied');
      } else if (this.entry.isSatisfied === false) {
        return this.$t('dissatisfied');
      } else {
        return this.$t('neutral');
      }
    },
    colorClass: function colorClass() {
      if (this.hasIndexCalculation) {
        return getColorNameByIndex(this.entry.averageIndex, this.$store.state.general.companyProperties);
      } else {
        return {
          green: this.entry.isSatisfied === true,
          red: this.entry.isSatisfied === false,
          grey: this.entry.isSatisfied === null
        };
      }
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    averageIndexTooltipText: function averageIndexTooltipText() {
      var value = FormatUtils.escapeHTML(this.$options.filters.groupedNumber(this.entry.averageIndex, true, 4));
      return "".concat(this.$t('satisfactionScore'), ": <b>").concat(value, "</b>");
    }
  },
  methods: {
    entryClicked: function entryClicked() {
      this.$emit('entry-clicked');
    },
    formatDateFromNow: function formatDateFromNow(date) {
      if (date) {
        return moment(date).fromNow();
      }
    },
    formatDateTime: function formatDateTime(date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY HH:mm');
      }
    }
  }
};