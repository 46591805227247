var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.internalEditQuestion !== null &&
    _vm.internalCurrentLanguage !== null
    ? _c(
        "div",
        { staticClass: "survey-management-edit-choice-attributes" },
        [
          _c(
            "b-form-group",
            {
              staticClass: "bold-uppercase-legend pt-2",
              attrs: {
                label: _vm.hasRegularChoiceAttributes
                  ? _vm.$t("label.activatedRegularChoiceAttributes")
                  : _vm.$t("label.regularChoiceAttributes"),
              },
            },
            [
              _vm.hasRegularChoiceAttributes
                ? _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "draggable",
                        class: {
                          "with-placeholder":
                            _vm.internalEditQuestion
                              .activatedRegularChoiceAttributes.length <= 1,
                          flashing: _vm.isDraggingRegularChoiceAttribute,
                        },
                        attrs: {
                          "data-empty-content": _vm.$t("dropMessage.activated"),
                        },
                        on: {
                          start: _vm.onStartDraggingRegularChoiceAttribute,
                          end: _vm.onEndDragging,
                        },
                        model: {
                          value:
                            _vm.internalEditQuestion
                              .activatedRegularChoiceAttributes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.internalEditQuestion,
                              "activatedRegularChoiceAttributes",
                              $$v
                            )
                          },
                          expression:
                            "internalEditQuestion.activatedRegularChoiceAttributes",
                        },
                      },
                      "draggable",
                      _vm.regularChoiceAttributeDraggingOptions,
                      false
                    ),
                    _vm._l(
                      _vm.internalEditQuestion.activatedRegularChoiceAttributes,
                      function (activatedRegularChoiceAttribute, index) {
                        return _c("survey-management-edit-attribute", {
                          key: activatedRegularChoiceAttribute.key,
                          attrs: {
                            index: index,
                            disabled: false,
                            "is-new": activatedRegularChoiceAttribute.isNew,
                            las: activatedRegularChoiceAttribute.text,
                            deleted: false,
                            "current-language": _vm.internalCurrentLanguage,
                            invalid:
                              _vm.editPageState.data.showValidation &&
                              !_vm.validation.activatedRegularChoiceAttributes
                                .single[index].valid,
                          },
                          on: {
                            "update-las": function ($event) {
                              activatedRegularChoiceAttribute.text = $event
                            },
                            "change-language": function ($event) {
                              _vm.internalCurrentLanguage = $event
                            },
                          },
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "survey-management-new-attribute-wrapper" },
                [
                  _c("survey-management-new-attribute", {
                    attrs: {
                      disabled: _vm.addNewRegularChoiceAttributeButtonDisabled,
                      title: _vm.$t("button.newRegularChoiceAttribute"),
                      invalid:
                        _vm.editPageState.data.showValidation &&
                        !_vm.validation.numberOfActivatedChoiceAttributes.valid,
                    },
                    on: { click: _vm.addNewRegularChoiceAttribute },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasRegularChoiceAttributes
            ? _c(
                "b-form-group",
                {
                  staticClass: "bold-uppercase-legend pt-2 mb-5",
                  attrs: {
                    label: _vm.$t("label.disabledRegularChoiceAttributes"),
                  },
                },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "draggable",
                        class: {
                          "with-placeholder":
                            _vm.internalEditQuestion
                              .disabledRegularChoiceAttributes.length <= 1,
                          flashing: _vm.isDraggingRegularChoiceAttribute,
                        },
                        attrs: {
                          "data-empty-content": _vm.$t("dropMessage.disabled"),
                        },
                        on: {
                          start: _vm.onStartDraggingRegularChoiceAttribute,
                          end: _vm.onEndDragging,
                        },
                        model: {
                          value:
                            _vm.internalEditQuestion
                              .disabledRegularChoiceAttributes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.internalEditQuestion,
                              "disabledRegularChoiceAttributes",
                              $$v
                            )
                          },
                          expression:
                            "internalEditQuestion.disabledRegularChoiceAttributes",
                        },
                      },
                      "draggable",
                      _vm.regularChoiceAttributeDraggingOptions,
                      false
                    ),
                    _vm._l(
                      _vm.internalEditQuestion.disabledRegularChoiceAttributes,
                      function (disabledRegularChoiceAttribute) {
                        return _c("survey-management-edit-attribute", {
                          key: disabledRegularChoiceAttribute.key,
                          staticClass: "survey-management-edit-attribute",
                          attrs: {
                            index: "disabled",
                            disabled: false,
                            "disabled-las": true,
                            deleted: disabledRegularChoiceAttribute.isNew,
                            "is-new": disabledRegularChoiceAttribute.isNew,
                            las: disabledRegularChoiceAttribute.text,
                            "current-language": _vm.internalCurrentLanguage,
                            invalid: false,
                          },
                          on: {
                            "change-language": function ($event) {
                              _vm.internalCurrentLanguage = $event
                            },
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-group",
            { staticClass: "bold-uppercase-legend pt-2" },
            [
              _c("template", { slot: "label" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.hasCustomChoiceAttributes
                        ? _vm.$t("label.activatedCustomChoiceAttributes")
                        : _vm.$t("label.customChoiceAttributes")
                    ) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _vm.hasCustomChoiceAttributes
                ? _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "draggable",
                        class: {
                          "with-placeholder":
                            _vm.internalEditQuestion
                              .activatedCustomChoiceAttributes.length <= 1,
                          flashing: _vm.isDraggingCustomChoiceAttribute,
                        },
                        attrs: {
                          "data-empty-content": _vm.$t("dropMessage.activated"),
                        },
                        on: {
                          start: _vm.onStartDraggingCustomChoiceAttribute,
                          end: _vm.onEndDragging,
                        },
                        model: {
                          value:
                            _vm.internalEditQuestion
                              .activatedCustomChoiceAttributes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.internalEditQuestion,
                              "activatedCustomChoiceAttributes",
                              $$v
                            )
                          },
                          expression:
                            "internalEditQuestion.activatedCustomChoiceAttributes",
                        },
                      },
                      "draggable",
                      _vm.customChoiceAttributeDraggingOptions,
                      false
                    ),
                    _vm._l(
                      _vm.internalEditQuestion.activatedCustomChoiceAttributes,
                      function (activatedCustomChoiceAttribute, index) {
                        return _c("survey-management-edit-attribute", {
                          key: activatedCustomChoiceAttribute.key,
                          attrs: {
                            custom: true,
                            index: index,
                            disabled: false,
                            "is-new": activatedCustomChoiceAttribute.isNew,
                            las: activatedCustomChoiceAttribute.text,
                            deleted: false,
                            "current-language": _vm.internalCurrentLanguage,
                            invalid:
                              _vm.editPageState.data.showValidation &&
                              !_vm.validation.activatedCustomChoiceAttributes
                                .single[index].valid,
                          },
                          on: {
                            "update-las": function ($event) {
                              activatedCustomChoiceAttribute.text = $event
                            },
                            "change-language": function ($event) {
                              _vm.internalCurrentLanguage = $event
                            },
                          },
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "survey-management-new-attribute-wrapper" },
                [
                  _c("survey-management-new-attribute", {
                    attrs: {
                      disabled: _vm.addNewCustomChoiceAttributeButtonDisabled,
                      title: _vm.$t("button.newCustomChoiceAttribute"),
                      invalid:
                        _vm.editPageState.data.showValidation &&
                        !_vm.validation.numberOfActivatedChoiceAttributes.valid,
                    },
                    on: { click: _vm.addNewCustomChoiceAttribute },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.hasCustomChoiceAttributes
            ? _c(
                "b-form-group",
                {
                  staticClass: "bold-uppercase-legend pt-2",
                  attrs: {
                    label: _vm.$t("label.disabledCustomChoiceAttributes"),
                  },
                },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "draggable",
                        class: {
                          "with-placeholder":
                            _vm.internalEditQuestion
                              .disabledCustomChoiceAttributes.length <= 1,
                          flashing: _vm.isDraggingCustomChoiceAttribute,
                        },
                        attrs: {
                          "data-empty-content": _vm.$t("dropMessage.disabled"),
                        },
                        on: {
                          start: _vm.onStartDraggingCustomChoiceAttribute,
                          end: _vm.onEndDragging,
                        },
                        model: {
                          value:
                            _vm.internalEditQuestion
                              .disabledCustomChoiceAttributes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.internalEditQuestion,
                              "disabledCustomChoiceAttributes",
                              $$v
                            )
                          },
                          expression:
                            "internalEditQuestion.disabledCustomChoiceAttributes",
                        },
                      },
                      "draggable",
                      _vm.customChoiceAttributeDraggingOptions,
                      false
                    ),
                    _vm._l(
                      _vm.internalEditQuestion.disabledCustomChoiceAttributes,
                      function (disabledCustomChoiceAttribute) {
                        return _c("survey-management-edit-attribute", {
                          key: disabledCustomChoiceAttribute.key,
                          staticClass: "survey-management-edit-attribute",
                          attrs: {
                            custom: true,
                            index: "disabled",
                            disabled: false,
                            "disabled-las": true,
                            deleted: disabledCustomChoiceAttribute.isNew,
                            "is-new": disabledCustomChoiceAttribute.isNew,
                            las: disabledCustomChoiceAttribute.text,
                            "current-language": _vm.internalCurrentLanguage,
                            invalid: false,
                          },
                          on: {
                            "change-language": function ($event) {
                              _vm.internalCurrentLanguage = $event
                            },
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }