//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CompanyStatisticsRow',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    }
  }
};