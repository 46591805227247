var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    { attrs: { "is-loading": false } },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm._v("\n    " + _vm._s(_vm.$t("panelTitle")) + "\n    "),
          !_vm.isLoading && !_vm.hasError
            ? _c(
                "b-button",
                {
                  staticClass: "edit-goal-button",
                  attrs: { variant: "primary" },
                  on: { click: _vm.editGoal },
                },
                [_vm._v(_vm._s(_vm.$t("editGoalButton")))]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "content" },
        [
          _c("alert", {
            staticClass: "positioned-alert",
            attrs: {
              icon: ["fas", "exclamation-triangle"],
              type: "danger",
              title: _vm.$t("error.title"),
              message: _vm.$t("error.message"),
              show: _vm.hasError && !_vm.isLoading,
            },
          }),
          _vm._v(" "),
          _vm.isLoading && !_vm.hasError
            ? _c("div", { staticClass: "spinner-wrapper" }, [
                _c("div", { staticClass: "opn-spinner" }),
              ])
            : !_vm.isLoading && !_vm.hasError
            ? [
                _c(
                  "b-row",
                  { staticClass: "goal-detail title" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "title", attrs: { cols: "3" } },
                      [_vm._v(_vm._s(_vm.$t("label.title")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "content", attrs: { cols: "9" } },
                      [_vm._v(_vm._s(_vm.goal.title))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "goal-detail description" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "title", attrs: { cols: "3" } },
                      [_vm._v(_vm._s(_vm.$t("label.description")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "content", attrs: { cols: "9" } },
                      [_vm._v(_vm._s(_vm.goal.description))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "goal-detail date-range" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "title", attrs: { cols: "3" } },
                      [_vm._v(_vm._s(_vm.$t("label.dateRange")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "content", attrs: { cols: "9" } },
                      [
                        _vm._v(
                          _vm._s(_vm.formatDate(_vm.goal.startDate)) +
                            " –\n          " +
                            _vm._s(_vm.formatDate(_vm.goal.endDate))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "goal-detail goal-type" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "title", attrs: { cols: "3" } },
                      [_vm._v(_vm._s(_vm.$t("label.goalType")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "content", attrs: { cols: "9" } },
                      [_vm._v(_vm._s(_vm.$t("goalType." + _vm.goal.goalType)))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "goal-detail goal-value" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "title", attrs: { cols: "3" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.goal.goalType === "OVERALL_SATISFACTION"
                              ? _vm.$t("label.valueInPercent")
                              : _vm.$t("label.value")
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "content", attrs: { cols: "9" } },
                      [
                        _vm._v(
                          _vm._s(_vm.goal.value) +
                            _vm._s(
                              _vm.goal.goalType === "OVERALL_SATISFACTION"
                                ? "%"
                                : ""
                            )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "goal-detail goal-domain" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "title", attrs: { cols: "3" } },
                      [_vm._v(_vm._s(_vm.$t("label.goalDomain")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "content", attrs: { cols: "9" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("goalDomain." + _vm.goal.goalDomainType)
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.goal.goalDomainType === "LOCATION"
                  ? _c(
                      "b-row",
                      { staticClass: "goal-detail goal-locations" },
                      [
                        _c(
                          "b-col",
                          { staticClass: "title", attrs: { cols: "3" } },
                          [_vm._v(_vm._s(_vm.$t("label.locations")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { staticClass: "content", attrs: { cols: "9" } },
                          [_vm._v(_vm._s(_vm.formattedLocations))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.goal.goalDomainType === "LOCATION_GROUP"
                  ? _c(
                      "b-row",
                      { staticClass: "goal-detail goal-locations" },
                      [
                        _c(
                          "b-col",
                          { staticClass: "title", attrs: { cols: "3" } },
                          [_vm._v(_vm._s(_vm.$t("label.locationGroups")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { staticClass: "content", attrs: { cols: "9" } },
                          [_vm._v(_vm._s(_vm.formattedLocationGroups))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "goal-detail goal-priority" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "title", attrs: { cols: "3" } },
                      [_vm._v(_vm._s(_vm.$t("label.priority")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "content", attrs: { cols: "9" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("priority." + _vm.goal.priority)) +
                            "\n          "
                        ),
                        _vm.goal.priority === "HIGH"
                          ? _c("font-awesome", {
                              staticClass: "priority-icon red",
                              attrs: { icon: ["far", "long-arrow-up"] },
                            })
                          : _vm.goal.priority === "LOW"
                          ? _c("font-awesome", {
                              staticClass: "priority-icon blue",
                              attrs: { icon: ["far", "long-arrow-down"] },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }