import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from '@/components/shared/Alert';
import ModalIconButton from '@/components/shared/ModalIconButton';
import { SurveyManagement } from '@/services/ResourceService';
import SurveyManagementPanel from '@/components/survey-management/SurveyManagementPanel';
import FormSelect from '@/components/shared/form-input/FormSelect';
import FormSelectItemGroup from '@/components/shared/form-input/FormSelectItemGroup';
import FormStepIndicator from '@/components/shared/FormStepIndicator';
import { convertQuestionToEditQuestion, getInitialLanguage } from '@/services/SurveyManagementService';
import { getAllQuestionTypes } from '@/services/SurveyManagementUtilService';
export default {
  name: 'SurveyManagementNewQuestion',
  components: {
    Alert: Alert,
    ModalIconButton: ModalIconButton,
    SurveyManagementPanel: SurveyManagementPanel,
    FormSelect: FormSelect,
    FormSelectItemGroup: FormSelectItemGroup,
    FormStepIndicator: FormStepIndicator
  },
  props: {
    isLast: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      isLoading: false,
      hasError: false,
      questionTypeOptions: getAllQuestionTypes(),
      questionPool: [],
      selectedQuestionType: null,
      selectedQuestionId: null,
      questionShell: null
    };
  },
  methods: {
    cancel: function cancel() {
      this.$store.dispatch('surveyManagement/editPageStateInitial');
    },
    addNewQuestionToSurvey: function addNewQuestionToSurvey() {
      return this.$store.dispatch('surveyManagement/editPageStateEditQuestion', {
        questionNr: this.editPageState.data.questionNr,
        question: this.questionShell,
        editQuestion: convertQuestionToEditQuestion(this.questionShell),
        currentLanguage: getInitialLanguage(this.$store.state.surveyManagement.fullSurvey.data.enabledLanguages, this.$store.state.general.language.toUpperCase()),
        isNew: true,
        anyLasIsFocused: false,
        subpage: 'EDIT',
        showValidation: false
      });
    },
    addExistingQuestionToSurvey: function addExistingQuestionToSurvey() {
      return this.$store.dispatch('surveyManagement/editPageStateSavingAdding', {
        questionNr: this.editPageState.data.questionNr,
        questionId: this.selectedQuestionId,
        questionType: this.selectedQuestionType,
        success: false,
        error: false
      });
    },
    loadQuestionPoolAndQuestionShell: function loadQuestionPoolAndQuestionShell() {
      var _this = this;
      this.hasError = false;
      this.isLoading = true;
      this.questionPool = [];
      this.questionShell = null;
      var args = [this.$store.state.general.companyId, this.selectedSurveyId, this.selectedQuestionType];
      Promise.all([SurveyManagement.getQuestionPoolOfTypeNotInSurvey.apply(this, args), SurveyManagement.getEmptyQuestionShellByType.apply(this, args)]).then(function (responses) {
        _this.isLoading = false;
        _this.questionPool = _this.sortQuestionsByLanguageAwareString(responses[0].data);
        _this.questionShell = responses[1].data;
      }, function () {
        _this.isLoading = false;
        _this.hasError = true;
      });
    },
    sortQuestionsByLanguageAwareString: function sortQuestionsByLanguageAwareString(languageAwareStringArray) {
      var _this2 = this;
      return languageAwareStringArray.map(function (item) {
        item.hasDuplicatedText = false;
        return item;
      }).sort(function (a, b) {
        if (a.questionText !== null && Object.keys(a.questionText).length > 0 && b.questionText !== null && Object.keys(b.questionText).length > 0) {
          var sortValue = _this2.$options.filters.languageAwareString(a.questionText, true).localeCompare(_this2.$options.filters.languageAwareString(b.questionText, true));
          if (sortValue === 0) {
            a.hasDuplicatedText = true;
            b.hasDuplicatedText = true;
            if (a.questionId !== null && b.questionId !== null) {
              return a - b;
            } else {
              return 0;
            }
          }
          return sortValue;
        } else {
          if (a.questionId !== null && b.questionId !== null) {
            return a - b;
          } else {
            return 0;
          }
        }
      });
    }
  },
  computed: {
    initial: function initial() {
      return this.$store.state.surveyManagement.fullSurvey.data.questions.length === 0;
    },
    editPageState: function editPageState() {
      return this.$store.state.surveyManagement.editPageState;
    },
    visibleQuestionTypeOptions: function visibleQuestionTypeOptions() {
      return this.questionTypeOptions.filter(function (option) {
        return option.visibleInSelect;
      });
    },
    selectedQuestionTypeOption: function selectedQuestionTypeOption() {
      var _this3 = this;
      return this.questionTypeOptions.find(function (option) {
        return option.value === _this3.selectedQuestionType;
      });
    },
    selectedQuestion: function selectedQuestion() {
      if (this.questionPool !== null) {
        var selectedQuestion = this.questionPool.find(function (question) {
          return question.questionId;
        });
        if (typeof selectedQuestion !== 'undefined') {
          return selectedQuestion;
        }
      }
      return null;
    },
    selectedSurveyId: function selectedSurveyId() {
      return this.$store.state.surveyManagement.selectedSurveyId;
    },
    pages: function pages() {
      var pages = [];
      if (this.selectedQuestionId !== null) {
        pages.push(this.$t('pageName.addQuestion'));
      } else {
        pages.push(this.$t('pageName.createNew'));
        pages.push(this.$t('pageName.edit'));
        if (['RATING_QUESTION_SMILEY', 'RATING_QUESTION_SLIDER'].includes(this.selectedQuestionType)) {
          pages.push(this.$t('pageName.reasons'));
        }
        if (['CHOICE_QUESTION_SINGLE_CHOICE', 'CHOICE_QUESTION_MULTIPLE_CHOICE'].includes(this.selectedQuestionType)) {
          pages.push(this.$t('pageName.choiceAttributes'));
        }
      }
      pages.push(this.$t('pageName.success'));
      return pages;
    }
  },
  watch: {
    selectedQuestionType: function selectedQuestionType() {
      this.selectedQuestionId = null;
      this.loadQuestionPoolAndQuestionShell();
    }
  }
};