// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/transparent-image-background.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-8e3e2c6a]:export {\n  green: #67c892;\n  green-light: #9ec867;\n  yellow: #edc84c;\n  orange: #e9ad6a;\n  red: #e96a6a;\n  secondaryColor: #30415d;\n  primaryColor: #5dbfe2;\n  primaryColorDark: #4886a5;\n  gray: #a1a1a1;\n}\n.form-image-uploader .image-preview[data-v-8e3e2c6a] {\n  padding-bottom: 8px;\n}\n.form-image-uploader .image-preview .image[data-v-8e3e2c6a] {\n    max-width: 100%;\n    max-height: 180px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0/16px 16px repeat;\n    margin-bottom: 3px;\n}\n.form-image-uploader .image-preview .image-subtext[data-v-8e3e2c6a] {\n    font-size: 13px;\n}\n.form-image-uploader .button-wrapper .hidden-file-input[data-v-8e3e2c6a] {\n  display: none;\n}\n.form-image-uploader.invalid[data-v-8e3e2c6a] {\n  border: 1px solid #dc3545;\n  border-radius: 0.25rem;\n}\n", ""]);
// Exports
module.exports = exports;
