//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Auth from '@/services/auth/Auth';
import Alert from '@/components/shared/Alert';
import AuthView from '@/components/authentication/AuthView';
import PasswordValidationErrorList from '@/components/shared/PasswordValidationErrorList';
export default {
  name: 'PasswordRecoveryVerify',
  components: {
    Alert: Alert,
    AuthView: AuthView,
    PasswordValidationErrorList: PasswordValidationErrorList
  },
  data: function data() {
    return {
      passwordInput: '',
      passwordInputVisible: false,
      submitting: false,
      errorExpiredOccurred: false,
      finished: false,
      passwordValidationErrorOccurred: false,
      passwordValidationErrors: []
    };
  },
  props: {
    token: {
      type: String,
      required: true
    },
    isActivation: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.submitting = true;
      this.errorExpiredOccurred = false;
      this.passwordValidationErrorOccurred = false;
      Auth.passwordRecoveryFinish({
        key: this.token,
        newPassword: this.passwordInput
      }).then(function () {
        _this.submitting = false;
        _this.finished = true;
      }, function (error) {
        _this.submitting = false;
        var data = error.response.data;
        var errCode = data.status.code;
        var payload = data.payload;
        if (errCode === 400 && payload && payload.length > 0) {
          _this.passwordValidationErrorOccurred = true;
          _this.passwordValidationErrors = payload;
        } else {
          _this.errorExpiredOccurred = true;
        }
      });
    },
    toggleNewPasswordVisibility: function toggleNewPasswordVisibility() {
      this.passwordInputVisible = !this.passwordInputVisible;
    }
  }
};