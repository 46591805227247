var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("language-aware-string-input", {
    attrs: {
      "current-language": _vm.currentLanguage,
      "enabled-languages": _vm.enabledLanguages,
      las: _vm.las,
      disabled: _vm.disabled,
      optional: _vm.optional,
      placeholder: _vm.placeholder,
      invalid: _vm.invalid,
      "any-las-is-focused": _vm.anyLasIsFocused,
    },
    on: {
      "set-las-focus": _vm.setLasFocus,
      change: _vm.onChange,
      "change-language": _vm.onChangeLanguage,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }