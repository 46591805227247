var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "web-source-qr-code-modal" },
    [
      _c("modal-icon-button", {
        attrs: {
          index: 0,
          icon: ["far", "times"],
          message: _vm.$t("message.closeModal"),
          "color-type": "danger",
          "tooltip-placement": "right",
        },
        on: { click: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("div", { staticClass: "qr-code-wrapper" }, [
          _c(
            "div",
            { staticClass: "canvas-scaler", style: _vm.canvasScalerStyle },
            [
              _c("QRCanvas", {
                attrs: { id: "qrCodeCanvas", options: _vm.qrCodeOptions },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "size-indicator" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.settings.qrCodeSize + " x " + _vm.settings.qrCodeSize
                ) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
      ]),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex " },
                  [
                    _c(
                      "b-dropdown",
                      {
                        staticClass: "mr-2",
                        attrs: { variant: "primary" },
                        scopedSlots: _vm._u([
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("font-awesome", {
                                  attrs: { icon: ["far", "arrow-to-bottom"] },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("button.downloadQRCode")) +
                                    "\n            "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "b-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.downloadQRCode("png")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("button.downloadQRCodeAsPNG")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.downloadQRCode("jpeg")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("button.downloadQRCodeAsJPEG")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.showEmbedQRCodeModal()
                          },
                        },
                      },
                      [
                        _c("font-awesome", {
                          attrs: { icon: ["far", "code"] },
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("button.embedQRCode")) +
                            "\n          "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-auto p-2 bd-highlight" }, [
                      _c("div", { staticClass: "d-flex justify-content-end" }, [
                        _c(
                          "span",
                          {
                            staticClass: "clickable-link mx-auto",
                            on: {
                              click: function ($event) {
                                _vm.showSettings = !_vm.showSettings
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("button.showAdvancedSettings")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.showSettings
                ? _c("b-col", { attrs: { cols: "12" } }, [_c("hr")])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showSettings
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-foreground",
                            "label-cols": "3",
                            "content-cols": "7",
                            label: _vm.$t("input.foreground"),
                            "label-for": "input-foreground",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "input-foreground", type: "color" },
                            model: {
                              value: _vm.settings.qrCodeForeground,
                              callback: function ($$v) {
                                _vm.$set(_vm.settings, "qrCodeForeground", $$v)
                              },
                              expression: "settings.qrCodeForeground",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-qrcode-size",
                            "label-cols": "3",
                            "content-cols": "7",
                            label: _vm.$t("input.size"),
                            "label-for": "size-number",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "input-group-reset" },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "size",
                                  type: "range",
                                  min: "250",
                                  max: "1200",
                                  size: "md",
                                  step: "1",
                                },
                                model: {
                                  value: _vm.settings.qrCodeSize,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.settings, "qrCodeSize", $$v)
                                  },
                                  expression: "settings.qrCodeSize",
                                },
                              }),
                              _vm._v(" "),
                              _c("b-form-input", {
                                staticClass: "mr-n2 short-input",
                                attrs: {
                                  id: "size-number",
                                  type: "number",
                                  min: "250",
                                  max: "1200",
                                  step: "1",
                                },
                                model: {
                                  value: _vm.settings.qrCodeSize,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.settings, "qrCodeSize", $$v)
                                  },
                                  expression: "settings.qrCodeSize",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSettings
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-background",
                            "label-cols": "3",
                            "content-cols": "7",
                            label: _vm.$t("input.background"),
                            "label-for": "input-background",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.qrCodeBackgroundOptions },
                            model: {
                              value: _vm.settings.qrCodeBackground,
                              callback: function ($$v) {
                                _vm.$set(_vm.settings, "qrCodeBackground", $$v)
                              },
                              expression: "settings.qrCodeBackground",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-qrcode-padding",
                            "label-cols": "3",
                            "content-cols": "7",
                            label: _vm.$t("input.padding"),
                            "label-for": "padding-number",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "input-group-reset" },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "padding",
                                  type: "range",
                                  min: "0",
                                  max: "100",
                                  size: "md",
                                  step: "1",
                                },
                                model: {
                                  value: _vm.settings.qrCodePadding,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.settings, "qrCodePadding", $$v)
                                  },
                                  expression: "settings.qrCodePadding",
                                },
                              }),
                              _vm._v(" "),
                              _c("b-form-input", {
                                staticClass: "mr-n2 short-input",
                                attrs: {
                                  id: "padding-number",
                                  type: "number",
                                  min: "0",
                                  max: "100",
                                  step: "1",
                                },
                                model: {
                                  value: _vm.settings.qrCodePadding,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.settings, "qrCodePadding", $$v)
                                  },
                                  expression: "settings.qrCodePadding",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSettings
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-effect",
                            "label-cols": "3",
                            "content-cols": "7",
                            label: _vm.$t("input.effect"),
                            "label-for": "input-effect",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.qrCodeEffectOptions },
                            model: {
                              value: _vm.settings.qrCodeEffect,
                              callback: function ($$v) {
                                _vm.$set(_vm.settings, "qrCodeEffect", $$v)
                              },
                              expression: "settings.qrCodeEffect",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _vm.settings.qrCodeEffect
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "fieldset-qrcode-radius",
                                "label-cols": "3",
                                "content-cols": "7",
                                label: _vm.$t("input.radius"),
                                "label-for": "radius-number",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mb-2 input-group-reset" },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "radius",
                                      type: "range",
                                      min: "0",
                                      max: "100",
                                      size: "md",
                                      step: "1",
                                    },
                                    model: {
                                      value: _vm.settings.qrCodeRadiusValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settings,
                                          "qrCodeRadiusValue",
                                          $$v
                                        )
                                      },
                                      expression: "settings.qrCodeRadiusValue",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("b-form-input", {
                                    staticClass: "mr-n2 short-input",
                                    attrs: {
                                      id: "radius-number",
                                      type: "number",
                                      min: "0",
                                      max: "100",
                                      step: "1",
                                    },
                                    model: {
                                      value: _vm.settings.qrCodeRadiusValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settings,
                                          "qrCodeRadiusValue",
                                          $$v
                                        )
                                      },
                                      expression: "settings.qrCodeRadiusValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSettings
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "9" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-logo",
                            label: _vm.$t("input.logo"),
                            "label-cols": "2",
                            "content-cols": "",
                            "label-for": "input-logo",
                          },
                        },
                        [
                          _c("FormImageUploader", {
                            attrs: {
                              id: "input-logo",
                              accept: "image/png, image/svg+xml, image/jpeg",
                            },
                            on: { input: _vm.onImageChanged },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }