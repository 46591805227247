var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey-management-preview-choice-question" },
    [
      _c(
        "transition-group",
        {
          staticClass: "choice-list-wrapper",
          attrs: { name: "choice-list", tag: "div" },
        },
        [
          _vm._l(
            _vm.question.activatedRegularChoiceAttributes,
            function (choiceAttribute, index) {
              return _c(
                "div",
                {
                  key: choiceAttribute.id
                    ? "activatedRegularChoiceAttribute-" + choiceAttribute.id
                    : "newActivatedRegularChoiceAttribute-" + index,
                  staticClass: "choice-attribute",
                  class: {
                    "single-choice":
                      _vm.questionType === "CHOICE_QUESTION_SINGLE_CHOICE",
                    "multiple-choice":
                      _vm.questionType === "CHOICE_QUESTION_MULTIPLE_CHOICE",
                  },
                },
                [
                  _c("span", { staticClass: "checkbox" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.lasPreviewTranslation(choiceAttribute.text)) +
                      "\n    "
                  ),
                ]
              )
            }
          ),
          _vm._v(" "),
          _vm._l(
            _vm.question.activatedCustomChoiceAttributes,
            function (choiceAttribute, index) {
              return _c(
                "div",
                {
                  key: choiceAttribute.id
                    ? "activatedCustomChoiceAttribute-" + choiceAttribute.id
                    : "newActivatedCustomChoiceAttribute-" + index,
                  staticClass: "choice-attribute custom-choice-attribute",
                  class: {
                    "single-choice":
                      _vm.questionType === "CHOICE_QUESTION_SINGLE_CHOICE",
                    "multiple-choice":
                      _vm.questionType === "CHOICE_QUESTION_MULTIPLE_CHOICE",
                  },
                },
                [
                  _c("span", { staticClass: "checkbox" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.lasPreviewTranslation(choiceAttribute.text)) +
                      "\n      "
                  ),
                  _c("survey-management-question-type-alpha-numeric-icon", {
                    staticClass: "custom-choice-attribute-icon",
                    attrs: { type: "alpha" },
                  }),
                ],
                1
              )
            }
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }