import _objectSpread from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import Alert from '@/components/shared/Alert';
import opnDateRangePicker from '@/components/shared/opnDateRangePicker';
import LocationGroupSelect from '@/components/shared/LocationGroupSelect';
import { Comparison } from '@/services/ResourceService';
import SearchFilterSelector from '@/components/shared/saved-search-filter/SearchFilterSelector';
import { mapState } from 'vuex';
export default {
  name: 'FormDateAndLocations',
  components: {
    opnDateRangePicker: opnDateRangePicker,
    LocationGroupSelect: LocationGroupSelect,
    Alert: Alert,
    SearchFilterSelector: SearchFilterSelector
  },
  props: {
    parentForm: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      form: {},
      isLoading: false,
      noData: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    currentUser: function currentUser(state) {
      return state.general.user;
    }
  })), {}, {
    startDate: function startDate() {
      return moment(this.form.startInSeconds).toDate();
    },
    endDate: function endDate() {
      return moment(this.form.endInSeconds).toDate();
    },
    currentUserIsSuperAdmin: function currentUserIsSuperAdmin() {
      return this.currentUser.authorities.includes('ROLE_SUPER_ADMIN');
    }
  }),
  created: function created() {
    this.initialize();
    this.validateData();
  },
  methods: {
    initialize: function initialize() {
      this.form = Object.assign({}, this.parentForm);
    },
    dateRangeChanged: function dateRangeChanged(newDateRange) {
      this.form.startInSeconds = moment(newDateRange[0]).valueOf();
      this.form.endInSeconds = moment(newDateRange[1]).valueOf();
      this.validateData();
      this.$emit('change-form', this.form);
    },
    changeLocations: function changeLocations(newLocations) {
      this.form.locations = newLocations;
      this.validateData();
      this.$emit('change-form', this.form);
    },
    validateData: function validateData() {
      var _this = this;
      this.isLoading = true;
      Comparison.hasDataWithDateAndLocations(this.$store.state.general.companyId, this.form.locations.queryLocations, moment(this.form.startInSeconds).format('DD.MM.YYYY'), moment(this.form.endInSeconds).format('DD.MM.YYYY')).then(function () {
        _this.isLoading = false;
        _this.noData = false;
      }, function () {
        _this.isLoading = false;
        _this.noData = true;
      });
    }
  },
  watch: {
    parentForm: {
      deep: true,
      handler: function handler() {
        this.initialize();
      }
    }
  }
};