import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.ends-with.js";
export default {
  encodeFileAsDataURL: function encodeFileAsDataURL(file) {
    return new Promise(function (resolve, reject) {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = function () {
        return resolve(fileReader.result);
      };
      fileReader.onerror = function (error) {
        return reject(error);
      };
    });
  },
  getByteSizeOfBase64StringAsInt: function getByteSizeOfBase64StringAsInt(base64Data) {
    if (typeof base64Data !== 'string') {
      return null;
    }
    var paddingSize = 0;
    if (base64Data.endsWith('==')) {
      paddingSize = 2;
    } else if (base64Data.endsWith('=')) {
      paddingSize = 1;
    }
    return base64Data.length * 3 / 4 - paddingSize;
  },
  formatNumberOfBytes: function formatNumberOfBytes(numberOfBytes, numbersAfterComma) {
    if (typeof numberOfBytes !== 'number') {
      return null;
    }
    if (isNaN(numbersAfterComma) || numbersAfterComma < 0 || numbersAfterComma > 10) {
      numbersAfterComma = 1;
    }
    var commaShift = Math.pow(10, numbersAfterComma);
    if (numberOfBytes < 1024) {
      return numberOfBytes + 'B';
    }
    if (numberOfBytes < 1024 * 1024) {
      return Math.round(numberOfBytes / 1024 * commaShift) / commaShift + 'KB';
    }
    if (numberOfBytes < 1024 * 1024 * 1024) {
      return Math.round(numberOfBytes / (1024 * 1024) * commaShift) / commaShift + 'MB';
    }
    return Math.round(numberOfBytes / (1024 * 1024 * 1024) * commaShift) / commaShift + 'GB';
  }
};