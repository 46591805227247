import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import QueryService from '@/services/QueryService';
import QueryUtils from '@/services/utils/QueryUtils';
import Router from '@/config/Router';
import Store from '@/store/Store';
var RoutingUtils = {
  routeToDashboardWithLocation: function routeToDashboardWithLocation(locationId) {
    var locationFilter = QueryUtils.getLocationFilter();
    locationFilter.locations.push(locationId);
    locationFilter.queryLocations.push(locationId);
    var filter = QueryService.get('filter') || {};
    filter.location = locationFilter;
    var query = QueryService.getAll() || {};
    query.filter = filter;
    Router.push({
      name: 'dashboard',
      query: QueryService.encode(query)
    });
  },
  routeToDashboardWithLocationGroupByLocationId: function routeToDashboardWithLocationGroupByLocationId(locationId) {
    if (Store.state.general.allLocations && Store.state.general.allLocations.length > 0) {
      var locationGroupId = null;
      Store.state.general.allLocations.forEach(function (item) {
        if (item.id === locationId && item.locationGroup) {
          locationGroupId = item.locationGroup.id;
        }
      });
      if (locationGroupId) {
        RoutingUtils.routeToDashboardWithLocationGroupByLocationGroupId(locationGroupId);
      }
    }
  },
  routeToDashboardWithLocationGroupByLocationGroupId: function routeToDashboardWithLocationGroupByLocationGroupId(locationGroupId) {
    var locationsWithThatGroupId = [];
    Store.state.general.allLocations.forEach(function (item) {
      if (item.locationGroup && item.locationGroup.id === locationGroupId) {
        locationsWithThatGroupId.push(item.id);
      }
    });
    if (locationsWithThatGroupId.length === 0) {
      var _filter = QueryService.get('filter') || {};
      _filter.location = QueryUtils.getLocationFilter();
      var _query = QueryService.getAll() || {};
      _query.filter = _filter;
      Router.push({
        name: 'dashboard',
        query: QueryService.encode(_query)
      });
    }
    var locationFilter = QueryUtils.getLocationFilter();
    locationFilter.locationGroups.push(locationGroupId);
    locationFilter.queryLocations = locationsWithThatGroupId;
    var filter = QueryService.get('filter') || {};
    filter.location = locationFilter;
    var query = QueryService.getAll() || {};
    query.filter = filter;
    Router.push({
      name: 'dashboard',
      query: QueryService.encode(query)
    });
  }
};
export default RoutingUtils;