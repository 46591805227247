import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getColorNameByIndex, getColorNameBySatisfactionRate } from '@/services/ColorService';
export default {
  name: 'PrintMatrix',
  props: {
    data: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    locationIdToLocationGroupMapping: function locationIdToLocationGroupMapping() {
      return this.data.locationIdToLocationGroupMapping;
    },
    tableData: function tableData() {
      var tableData = [];
      var sourceData = this.data.locations;
      sourceData.rows.sort(function (a, b) {
        return a.locationName.localeCompare(b.locationName);
      });
      var headerRow = [];
      tableData.push(headerRow);

      // top-left 'locations' cell
      headerRow.push({
        cellId: 'top-left-cell',
        isTopLeftCell: true,
        isCategoryCell: false,
        isLocationCell: false,
        isValueCell: false
      });

      // Matrix category cells
      sourceData.categories.forEach(function (category) {
        headerRow.push({
          cellId: 'category-' + category.id,
          isTopLeftCell: false,
          isCategoryCell: true,
          isLocationCell: false,
          isValueCell: false,
          categoryId: category.id,
          categoryName: category.name
        });
      });

      // Value & location cells
      sourceData.rows.forEach(function (row) {
        var dataRow = [];
        dataRow.push({
          cellId: 'location-' + row.locationId,
          isTopLeftCell: false,
          isCategoryCell: false,
          isLocationCell: true,
          isValueCell: false,
          locationId: row.locationId,
          locationName: row.locationName
        });
        var index = 0;
        row.cells.forEach(function (cell) {
          dataRow.push({
            cellId: 'value-' + row.locationId + '-' + index,
            isTopLeftCell: false,
            isCategoryCell: false,
            isLocationCell: false,
            isValueCell: true,
            averageIndex: cell !== null ? cell.averageIndex : null,
            satisfactionRate: cell !== null ? cell.satisfactionRate : null,
            counter: cell !== null ? cell.counter : null
          });
          index++;
        });
        tableData.push(dataRow);
      });
      return tableData;
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    }
  },
  methods: {
    getColorNameByIndex: getColorNameByIndex,
    getColorNameBySatisfactionRate: getColorNameBySatisfactionRate
  }
};