import "core-js/modules/es.object.to-string.js";
import Store from '@/store/Store';
import Http from '@/config/Http';
var MainProperties = {
  get: function get() {
    if (Store.state.general.mainProperties !== null) {
      return Promise.resolve(Store.state.general.mainProperties);
    }
    var port = window.location.port;
    var domain = window.location.hostname + (port !== 80 && port !== 443 && port !== '' ? ":".concat(port) : '');
    return Http.get('/main-properties', {
      params: {
        domain: domain
      }
    }).then(function (response) {
      Store.commit('general/setMainProperties', response.data);
      return Promise.resolve(Store.state.general.mainProperties);
    }, function () {
      Store.commit('general/setMainProperties', null);
      return Promise.resolve(null);
    });
  }
};
export default MainProperties;