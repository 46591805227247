var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-search-filter-modal" },
    [
      _c("modal-icon-button", {
        attrs: {
          index: 0,
          icon: ["far", "times"],
          "color-type": "danger",
          message: _vm.$t("tooltip.closeModal"),
          "tooltip-placement": "top",
        },
        on: { click: _vm.closeModal },
      }),
      _vm._v(" "),
      _vm.errorOccurred
        ? _c(
            "div",
            { staticClass: "mr-2 ml-2" },
            [
              _c("alert", {
                attrs: {
                  icon: ["fas", "times"],
                  type: "danger",
                  title: _vm.$t("error.title"),
                  message: _vm.$t("error.message"),
                  show: _vm.errorOccurred,
                  closeable: false,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "bold-uppercase-legend",
                          attrs: {
                            label: _vm.$t("form.label.searchFilterName"),
                          },
                        },
                        [
                          _c("form-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t(
                                "form.placeholder.searchFilterName"
                              ),
                              "max-length": 191,
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "bold-uppercase-legend",
                          attrs: { label: _vm.$t("form.label.locations") },
                        },
                        [
                          _c("location-group-select", {
                            attrs: {
                              id: "input-locations",
                              "is-loading": false,
                              limit: 999,
                              "is-in-form": true,
                              "pre-selected-ids": _vm.preselectedIds,
                            },
                            on: { "change-locations": _vm.locationsChanged },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: {
                        type: "submit",
                        variant: "primary",
                        disabled: !_vm.isValid || _vm.isLoading,
                      },
                      on: { click: _vm.createSearchFilter },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("form.button.saveSearchFilter")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }