import Vue from 'vue';
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon as FontAwesomeComponent } from '@fortawesome/vue-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faAt as falAt, faCalendarAlt as falCalendarAlt, faCheckCircle as falCheckCircle, faCheckSquare as falCheckSquare, faCommentLines as falCommentLines, faExclamationTriangle as falExclamationTriangle, faFileAlt as falFileAlt, faPlus as falPlus, faSlidersHSquare as falSlidersHSquare, faSmile as falSmile } from '@fortawesome/pro-light-svg-icons';
import { faAlignSlash as farAlignSlash, faAngleDoubleRight as farAngleDoubleRight, faAngleDown as farAngleDown, faAngleLeft as farAngleLeft, faArrowToBottom as farArrowToBottom, faBarcodeRead as farBarcodeRead, faBullseyeArrow as farBullseyeArrow, faCalendar as farCalendar, faCalendarAlt as farCalendarAlt, faCheckCircle as farCheckCircle, faCode as farCode, faCommentDots as farCommentDots, faCommentLines as farCommentLines, faExclamationTriangle as farExclamationTriangle, faEye as farEye, faEyeSlash as farEyeSlash, faInfoCircle as farInfoCircle, faLongArrowDown as farLongArrowDown, faLongArrowUp as farLongArrowUp, faMapMarkerAlt as farMapMarkerAlt, faPause as farPause, faPencil as farPencil, faPlay as farPlay, faPlus as farPlus, faPollH as farPollH, faQrcode as farQrcode, faSignIn as farSignIn, faSmile as farSmile, faSortNumericDown as farSortNumericDown, faSortNumericUp as farSortNumericUp, faSortAlphaUp as farSortAlphaUp, faSortAlphaDown as farSortAlphaDown, faTh as farTh, faTimes as farTimes, faTrash as farTrash, faTrashAlt as farTrashAlt, faWifiSlash as farWifiSlash, faClone } from '@fortawesome/pro-regular-svg-icons';
import { faBan as fasBan, faBrowser as fasBrowser, faBuilding as fasBuilding, faChartLine as fasChartLine, faCheck as fasCheck, faCircle as fasCircle, faClipboardList as fasClipboardList, faCloudDownloadAlt as fasCloudDownloadAlt, faCommentEdit as fasCommentEdit, faComments as fasComments, faEnvelope as fasEnvelope, faExchange as fasExchange, faExclamation as fasExclamation, faExclamationTriangle as fasExclamationTriangle, faFileCsv as fasFileCsv, faFilePdf as fasFilePdf, faFilter as fasFilter, faInfo as fasInfo, faInfoCircle as fasInfoCircle, faKeyboard as fasKeyboard, faListOl as fasListOl, faLock as fasLock, faLongArrowDown as fasLongArrowDown, faLongArrowUp as fasLongArrowUp, faMapMarkerAlt as fasMapMarkerAlt, faPlus as fasPlus, faPollH as fasPollH, faQuestion as fasQuestion, faSearch as fasSearch, faSignOut as fasSignOut, faSpinnerThird as fasSpinnerThird, faStarOfLife as fasStarOfLife, faTabletAndroidAlt as fasTabletAndroidAlt, faTasks as fasTasks, faTimes as fasTimes, faTrashAlt as fasTrashAlt, faUser as fasUser, faUsers as fasUsers, faUserCircle as fasUserCircle } from '@fortawesome/pro-solid-svg-icons';
FontAwesomeLibrary.add(fadSpinnerThird);
FontAwesomeLibrary.add(falAt, falCalendarAlt, falCheckCircle, falCheckSquare, falCommentLines, falExclamationTriangle, falFileAlt, falPlus, falSlidersHSquare, falSmile);
FontAwesomeLibrary.add(farAlignSlash, farAngleDoubleRight, farAngleDown, farAngleLeft, farArrowToBottom, farBarcodeRead, farBullseyeArrow, farCalendar, farCalendarAlt, farCheckCircle, farCode, farCommentDots, farCommentLines, farExclamationTriangle, farEye, farEyeSlash, farInfoCircle, farLongArrowDown, farLongArrowUp, farMapMarkerAlt, farPause, farPencil, farPlay, farPlus, farPollH, farQrcode, farSignIn, farSmile, farSortNumericDown, farSortNumericUp, farSortAlphaUp, farSortAlphaDown, farTh, farTimes, farTrash, farTrashAlt, farWifiSlash, faClone);
FontAwesomeLibrary.add(fasBan, fasBrowser, fasBuilding, fasChartLine, fasCheck, fasCircle, fasClipboardList, fasCloudDownloadAlt, fasCommentEdit, fasComments, fasEnvelope, fasExchange, fasExclamation, fasExclamationTriangle, fasFileCsv, fasFilePdf, fasFilter, fasInfo, fasInfoCircle, fasKeyboard, fasListOl, fasLock, fasLongArrowDown, fasLongArrowUp, fasMapMarkerAlt, fasPlus, fasPollH, fasQuestion, fasSearch, fasSignOut, fasSpinnerThird, fasStarOfLife, fasTabletAndroidAlt, fasTasks, fasTimes, fasTrashAlt, fasUser, fasUsers, fasUserCircle);
FontAwesomeLibrary.add(faApple, faAndroid);
Vue.component('font-awesome', FontAwesomeComponent);