var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.d200",
          value: { placement: _vm.tooltipPlacement },
          expression: "{ placement: tooltipPlacement }",
          modifiers: { hover: true, d200: true },
        },
      ],
      staticClass: "modal-icon-button",
      class: [
        _vm.colorType,
        {
          "horizontal-in-box": _vm.horizontalInBox,
          "strike-through": _vm.strikeThrough,
        },
      ],
      style: _vm.styleObject,
      attrs: { title: _vm.finalMessage },
      on: { click: _vm.clickFunction },
    },
    [
      _c(
        "div",
        { staticClass: "icon" },
        [
          _c("font-awesome", {
            staticClass: "icon",
            attrs: { icon: _vm.icon },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }