//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Comparison } from '@/services/ResourceService';
import FilterCriteriaDate from '@/components/comparison/filter-criteria/FilterCriteriaDate';
import FilterCriteriaLocation from '@/components/comparison/filter-criteria/FilterCriteriaLocation';
import moment from 'moment';
export default {
  name: 'FormSurvey',
  components: {
    FilterCriteriaDate: FilterCriteriaDate,
    FilterCriteriaLocation: FilterCriteriaLocation
  },
  props: {
    parentForm: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      form: {},
      isLoading: true,
      possibleSurveys: []
    };
  },
  computed: {
    startDate: function startDate() {
      return moment(this.form.startInSeconds).format('DD.MM.YYYY');
    },
    endDate: function endDate() {
      return moment(this.form.endInSeconds).format('DD.MM.YYYY');
    }
  },
  created: function created() {
    this.initialize();
  },
  mounted: function mounted() {
    this.loadPossibleSurveys();
  },
  methods: {
    initialize: function initialize() {
      this.form = Object.assign({}, this.parentForm);
    },
    loadPossibleSurveys: function loadPossibleSurveys() {
      var _this = this;
      this.isLoading = true;
      Comparison.getPossibleSurveysInSelection(this.$store.state.general.companyId, this.form.locations.queryLocations, this.startDate, this.endDate).then(function (response) {
        _this.possibleSurveys = response.data;
        if (response.data.length === 1 && _this.form.surveyId === null) {
          _this.form.surveyId = response.data[0].surveyId;
        }
        _this.isLoading = false;
      }, function () {
        _this.possibleSurveys = [];
        _this.isLoading = false;
      });
    }
  },
  watch: {
    parentForm: {
      deep: true,
      handler: function handler() {
        this.initialize();
      }
    }
  }
};