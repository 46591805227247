import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.atob.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import "core-js/modules/web.btoa.js";
import "core-js/modules/es.json.stringify.js";
import Router from '@/config/Router';
export default {
  getAll: function getAll() {
    if (Router.currentRoute.query) {
      return getReadableQuery(Router.currentRoute.query.q);
    } else {
      return {};
    }
  },
  get: function get(key) {
    if (Router.currentRoute.query) {
      var query = getReadableQuery(Router.currentRoute.query.q);
      if (query[key]) {
        return query[key];
      } else {
        return undefined;
      }
    }
  },
  put: function put(key, value) {
    var query = {};
    if (Router.currentRoute.query) {
      query = getReadableQuery(Router.currentRoute.query.q);
    }
    query[key] = value;
    var base64Query = getBase64Query(query);
    if (Router.currentRoute.query && Router.currentRoute.query.q === base64Query) {
      return Promise.resolve();
    }
    return new Promise(function (resolve, reject) {
      Router.push({
        path: Router.currentRoute.path,
        query: {
          q: base64Query
        }
      }, resolve, reject);
    });
  },
  putMulti: function putMulti(items) {
    // items has the format { key: value, key2: value2 }
    var query = {};
    if (Router.currentRoute.query) {
      query = getReadableQuery(Router.currentRoute.query.q);
    }
    var keys = Object.keys(items);
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var value = items[key];
      query[key] = value;
    }
    var base64Query = getBase64Query(query);
    if (Router.currentRoute.query && Router.currentRoute.query.q === base64Query) {
      return Promise.resolve();
    }
    return new Promise(function (resolve, reject) {
      Router.push({
        path: Router.currentRoute.path,
        query: {
          q: base64Query
        }
      }, resolve, reject);
    });
  },
  clearAll: function clearAll() {
    if (hasNoQuery()) {
      return Promise.resolve();
    }
    return new Promise(function (resolve, reject) {
      Router.push({
        path: Router.currentRoute.path
      }, resolve, reject);
    });
  },
  clear: function clear(key) {
    if (hasNoQuery()) {
      return Promise.resolve();
    } else {
      var query = getReadableQuery(Router.currentRoute.query.q);
      if (query[key]) {
        delete query[key];
        var base64Query = getBase64Query(query);
        return new Promise(function (resolve, reject) {
          Router.push({
            path: Router.currentRoute.path,
            query: {
              q: base64Query
            }
          }, resolve, reject);
        });
      }
    }
  },
  clearMulti: function clearMulti(keys) {
    // keys has the format ['key1', 'key2']
    if (keys && !hasNoQuery()) {
      var query = getReadableQuery(Router.currentRoute.query.q);
      keys.forEach(function (key) {
        if (query[key]) {
          delete query[key];
        }
      });
      var base64Query = getBase64Query(query);
      return new Promise(function (resolve, reject) {
        Router.push({
          path: Router.currentRoute.path,
          query: {
            q: base64Query
          }
        }, resolve, reject);
      });
    } else {
      return Promise.resolve();
    }
  },
  encode: function encode(q) {
    var encodedString = null;
    try {
      // the input (q) might be invalid
      encodedString = getBase64Query(q);
      // eslint-disable-next-line no-empty
    } catch (e) {}

    // invalid encodedString (null or '') won't be passed
    return encodedString ? {
      q: encodedString
    } : null;
  },
  encodedQuery: function encodedQuery() {
    var encodedString = null;
    if (Router.currentRoute.query && Router.currentRoute.query.q) {
      var query = getReadableQuery(Router.currentRoute.query.q);
      if (Object.keys(query).length !== 0) {
        encodedString = getBase64Query(query);
      }
    }

    // invalid encodedString (null or '') won't be returned
    return encodedString ? {
      q: encodedString
    } : null;
  }
};
var getReadableQuery = function getReadableQuery(base64Query) {
  if (typeof base64Query !== 'undefined' && base64Query !== null) {
    var output = {};
    try {
      output = JSON.parse(window.atob(base64Query));
      // output = JSON.parse(base64Query);
    } catch (e) {
      window.console.warn('Invalid routing parameter');
    }
    return output;
  } else {
    return {};
  }
};
var getBase64Query = function getBase64Query(query) {
  if (typeof query !== 'undefined' && query !== null) {
    return window.btoa(JSON.stringify(query));
    // return JSON.stringify(query);
  } else {
    return '';
  }
};
var hasNoQuery = function hasNoQuery() {
  var current = Router.currentRoute;
  return !current || !current.query || Object.keys(current.query).length < 1;
};