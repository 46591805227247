import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import Vue from 'vue';
import Store from '@/store/Store';
import FormatUtils from '@/services/utils/FormatUtils';
export var languageAwareString = function languageAwareString(_languageAwareString, noHtml) {
  // If no languageAwareString is provided
  if (typeof _languageAwareString === 'undefined' || _languageAwareString === null) {
    return;
  }
  var currentLanguage = Store.state.general.language.toUpperCase();
  if (!_languageAwareString.hasOwnProperty(currentLanguage) || _languageAwareString[currentLanguage] === null || _languageAwareString[currentLanguage] === '') {
    if (_languageAwareString.hasOwnProperty('EN') && _languageAwareString['EN'] != null && _languageAwareString['EN'] !== '') {
      if (noHtml) {
        return FormatUtils.escapeHTML(_languageAwareString['EN']);
      } else {
        return '<em>' + FormatUtils.escapeHTML(_languageAwareString['EN']) + '</em>';
      }
    }
    if (_languageAwareString.hasOwnProperty('DE') && _languageAwareString['DE'] != null && _languageAwareString['DE'] !== '') {
      if (noHtml) {
        return FormatUtils.escapeHTML(_languageAwareString['DE']);
      } else {
        return '<em>' + FormatUtils.escapeHTML(_languageAwareString['DE']) + '</em>';
      }
    }
    for (var language in _languageAwareString) {
      if (_languageAwareString.hasOwnProperty(language) && _languageAwareString[language] !== null && language !== 'id') {
        if (noHtml) {
          return FormatUtils.escapeHTML(_languageAwareString[language]);
        } else {
          return '<em>' + FormatUtils.escapeHTML(_languageAwareString[language]) + '</em>';
        }
      }
    }
  }
  return FormatUtils.escapeHTML(_languageAwareString[currentLanguage]);
};
Vue.filter('languageAwareString', languageAwareString);