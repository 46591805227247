import _objectSpread from "/builds/RXxibax4-/0/opiniooon/opnn-analytics/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Bus as VuedalsBus } from 'vuedals';
import moment from 'moment';
import InfiniteLoading from 'vue-infinite-loading';
import QueryService from '@/services/QueryService';
import QueryUtils from '@/services/utils/QueryUtils';
import { Timeline } from '@/services/ResourceService';
import opnDateRangePicker from '@/components/shared/opnDateRangePicker';
import LocationGroupSelect from '@/components/shared/LocationGroupSelect';
import ClearFilter from '@/components/shared/ClearFilter';
import EmptyState from '@/components/shared/EmptyState';
import ModalIconButton from '@/components/shared/ModalIconButton';
import TimelineEntry from '@/components/timeline/TimelineEntry';
import FeedbackModal from '@/components/shared/FeedbackModal';
import CompanyPropertiesService from '@/services/CompanyPropertiesService';
import SatisfactionIndexRange from '@/components/timeline/SatisfactionInputRange';
import ColorLegend from '@/components/shared/ColorLegend';
import SearchFilterSelector from '@/components/shared/saved-search-filter/SearchFilterSelector';
import { mapState } from 'vuex';
export default {
  name: 'Timeline',
  components: {
    InfiniteLoading: InfiniteLoading,
    opnDateRangePicker: opnDateRangePicker,
    LocationGroupSelect: LocationGroupSelect,
    ClearFilter: ClearFilter,
    EmptyState: EmptyState,
    TimelineEntry: TimelineEntry,
    ModalIconButton: ModalIconButton,
    SatisfactionIndexRange: SatisfactionIndexRange,
    ColorLegend: ColorLegend,
    SearchFilterSelector: SearchFilterSelector
  },
  data: function data() {
    return {
      timeline: [],
      isLoading: true,
      nextPage: 0,
      filter: {
        location: {},
        dateRange: []
      },
      showFilterPopup: false,
      timelineFilter: QueryUtils.getTimelineFilter(),
      timelineFilterEnums: QueryUtils.getTimelineFilterEnums(),
      infiniteScrollIdentifier: 0
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    currentUser: function currentUser(state) {
      return state.general.user;
    }
  })), {}, {
    timelineFilterIsNotDefault: function timelineFilterIsNotDefault() {
      return QueryUtils.timelineFilterIsNotDefault(this.timelineFilter);
    },
    hasIndexCalculation: function hasIndexCalculation() {
      return this.$store.getters['general/hasIndexCalculation'];
    },
    currentUserIsSuperAdmin: function currentUserIsSuperAdmin() {
      return this.currentUser.authorities.includes('ROLE_SUPER_ADMIN');
    }
  }),
  mounted: function mounted() {
    this.initializePage();
  },
  methods: {
    initializePage: function initializePage() {
      this.isLoading = true;
      this.loadFilterParams();
      this.resetTimeline();
    },
    openFeedbackModal: function openFeedbackModal(answerSetId) {
      var _this = this;
      VuedalsBus.$emit('new', {
        title: this.$t('modalTitle'),
        name: 'feedback-modal',
        closeOnBackdrop: false,
        props: {
          answerSetId: answerSetId
        },
        component: FeedbackModal,
        onClose: function onClose(options) {
          if (options.spamStateChanged) {
            _this.resetTimeline();
          }
        }
      });
    },
    loadFilterParams: function loadFilterParams() {
      this.isLoading = true;
      var filter = QueryService.get('filter') || {};
      var timelineFilter = QueryService.get('timelineFilter') || {};
      this.filter.dateRange = QueryUtils.getDateRangeFilter(filter.dateRange);
      this.filter.location = QueryUtils.getLocationFilter(filter.location);
      this.timelineFilter = QueryUtils.getTimelineFilter(timelineFilter);
    },
    onInfinite: function onInfinite(infiniteLoadingState) {
      this.loadFeedback(infiniteLoadingState);
    },
    resetFeedbackFilter: function resetFeedbackFilter() {
      var _this2 = this;
      this.timelineFilter = QueryUtils.getTimelineFilter();
      this.timelineFilterAdapted();
      setTimeout(function () {
        _this2.showFilterPopup = false;
      }, 200);
    },
    resetTimeline: function resetTimeline() {
      var _this3 = this;
      this.isLoading = true;
      this.timeline = [];
      this.nextPage = 0;
      this.$nextTick(function () {
        _this3.infiniteScrollIdentifier += 1;
      });
    },
    resetBarcodeTimelineFilter: function resetBarcodeTimelineFilter() {
      this.timelineFilter.barcode.barcodeFilterString = null;
      this.timelineFilterAdapted();
    },
    loadFeedback: function loadFeedback(infiniteLoadingState) {
      var _this4 = this;
      if (!infiniteLoadingState) {
        infiniteLoadingState = {
          loaded: function loaded() {},
          complete: function complete() {},
          reset: function reset() {}
        };
      }
      this.isLoading = true;
      var additionalParams = {
        satisfaction: this.timelineFilter.satisfaction,
        comments: this.timelineFilter.comments,
        email: this.timelineFilter.email,
        spam: this.timelineFilter.spam
      };
      if (this.hasIndexCalculation) {
        additionalParams.filterByIndex = this.timelineFilter.index.filterByIndex;
        additionalParams.minimalIndex = this.timelineFilter.index.minimalIndex;
        additionalParams.maximalIndex = this.timelineFilter.index.maximalIndex;
      } else {
        additionalParams.satisfaction = this.timelineFilter.satisfaction;
      }
      additionalParams.filterByBarcode = this.timelineFilter.barcode.filterByBarcode;
      additionalParams.barcodeFilterString = this.timelineFilter.barcode.barcodeFilterString;
      Promise.all([Timeline.getTimeline(this.$store.state.general.companyId, this.filter.location.queryLocations, moment(this.filter.dateRange[0]).format('DD.MM.YYYY'), moment(this.filter.dateRange[1]).format('DD.MM.YYYY'), this.nextPage, additionalParams), CompanyPropertiesService.update()]).then(function (responses) {
        var data = responses[0].data;
        _this4.isLoading = false;
        if (data) {
          _this4.timeline = _this4.timeline.concat(data.content);
          _this4.nextPage = data.number + 1;
          infiniteLoadingState.loaded();
          if (data.last === true) {
            infiniteLoadingState.complete();
          }
        } else {
          infiniteLoadingState.complete();
        }
      }).catch(function () {
        _this4.isLoading = false;
        _this4.timeline = [];
        infiniteLoadingState.complete();
      });
    },
    dateRangeChanged: function dateRangeChanged(dateRange) {
      this.filter.dateRange = dateRange;
      QueryService.put('filter', this.filter);
    },
    locationsChanged: function locationsChanged(locationFilter) {
      this.filter.location = locationFilter;
      QueryService.put('filter', this.filter);
    },
    timelineFilterAdapted: function timelineFilterAdapted() {
      QueryService.put('timelineFilter', this.timelineFilter);
    }
  },
  watch: {
    $route: function $route() {
      this.loadFilterParams();
      this.resetTimeline();
    }
  }
};