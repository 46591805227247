var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "one-side-comparison" }, [
    _c(
      "div",
      { staticClass: "pos-rel" },
      [
        _vm.editMode
          ? [
              _c("form-box", {
                attrs: { "parent-config": _vm.config },
                on: {
                  close: function ($event) {
                    _vm.editMode = false
                  },
                  "save-form": _vm.updateForm,
                },
              }),
            ]
          : [
              _vm.config.dirty
                ? [
                    _c("dashed-box", {
                      on: {
                        click: function ($event) {
                          _vm.editMode = true
                        },
                      },
                    }),
                  ]
                : _vm.isValid
                ? [
                    _c("selection-box", {
                      attrs: { "parent-config": _vm.config },
                      on: {
                        "clear-config": function ($event) {
                          return _vm.$emit("clear-config")
                        },
                        "edit-config": function ($event) {
                          _vm.editMode = true
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("simple-quick-statistics", {
                      attrs: { "parent-config": _vm.config },
                    }),
                    _vm._v(" "),
                    _c("change-over-time-box", {
                      attrs: { "parent-config": _vm.config },
                    }),
                    _vm._v(" "),
                    _c("survey-box", {
                      attrs: { "parent-config": _vm.config },
                    }),
                  ]
                : !_vm.isValid
                ? [_vm._m(0)]
                : _vm._e(),
            ],
        _vm._v(" "),
        _c("div", {
          staticClass: "overlay",
          class: { "edit-mode": _vm.editMode },
          on: {
            click: function ($event) {
              _vm.editMode = false
            },
          },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "check-validity-spinner" }, [
      _c("div", { staticClass: "opn-spinner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }