// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/transparent-image-background.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-64e4a993]:export {\n  green: #67c892;\n  green-light: #9ec867;\n  yellow: #edc84c;\n  orange: #e9ad6a;\n  red: #e96a6a;\n  secondaryColor: #30415d;\n  primaryColor: #5dbfe2;\n  primaryColorDark: #4886a5;\n  gray: #a1a1a1;\n}\n.web-source-qr-code-modal .qr-code-wrapper[data-v-64e4a993] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0/16px 16px repeat;\n  width: 810px;\n  height: 500px;\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.web-source-qr-code-modal .qr-code-wrapper .size-indicator[data-v-64e4a993] {\n    font-family: 'Input Mono', 'Consolas', monospace;\n    position: absolute;\n    border-radius: 6px;\n    padding: 2px 7px;\n    background-color: #f1f1f1;\n    bottom: 10px;\n    left: 10px;\n    font-size: 12px;\n}\n.web-source-qr-code-modal .short-input[data-v-64e4a993] {\n  width: 75px;\n  -webkit-box-flex: 0;\n      -ms-flex: none;\n          flex: none;\n}\n.web-source-qr-code-modal .input-group-reset input[data-v-64e4a993] {\n  font-size: 14px;\n}\n", ""]);
// Exports
module.exports = exports;
