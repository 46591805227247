var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enabledLanguages.includes(_vm.currentLanguage) &&
    _vm.internalLas !== null
    ? _c(
        "div",
        {
          staticClass: "language-aware-string-input",
          class: { disabled: _vm.disabled, invalid: _vm.invalid },
        },
        [
          _c(
            "div",
            { staticClass: "input-field" },
            [
              _c("span", { staticClass: "current-language-indicator" }, [
                _vm._v(_vm._s(_vm.currentLanguage)),
              ]),
              _vm._v(" "),
              _c("form-input", {
                ref: "input",
                attrs: {
                  invalid: _vm.invalid,
                  disabled: _vm.disabled,
                  placeholder: _vm.placeholder,
                  "max-length": 191,
                },
                on: { blur: _vm.onBlur },
                model: {
                  value: _vm.internalLas[_vm.currentLanguage],
                  callback: function ($$v) {
                    _vm.$set(_vm.internalLas, _vm.currentLanguage, $$v)
                  },
                  expression: "internalLas[currentLanguage]",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.enabledLanguages.length !== 1
            ? _c(
                "div",
                { staticClass: "language-switch" },
                _vm._l(_vm.enabledLanguages, function (language) {
                  return _c(
                    "div",
                    {
                      key: language,
                      staticClass: "language-switch-button",
                      class: { active: _vm.currentLanguage === language },
                      on: {
                        click: function ($event) {
                          return _vm.clickChangeLanguage(language)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "language" }, [
                        _vm._v(_vm._s(language)),
                      ]),
                      _vm._v(" "),
                      _vm.optional && _vm.lasIsEmpty
                        ? _c("font-awesome", {
                            staticClass: "icon align-slash",
                            attrs: { icon: ["far", "align-slash"] },
                          })
                        : _vm.stringIsEmptyOrContainsInvalidCharacters(
                            _vm.internalLas[language]
                          )
                        ? _c("font-awesome", {
                            staticClass: "icon exclamation-triangle",
                            class: { red: _vm.invalid },
                            attrs: { icon: ["far", "exclamation-triangle"] },
                          })
                        : _c("font-awesome", {
                            staticClass: "icon check-circle",
                            attrs: { icon: ["far", "check-circle"] },
                          }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }