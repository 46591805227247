var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "42px",
        height: "41px",
        viewBox: "0 0 42 41",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("defs", [
        _c("polygon", {
          attrs: {
            id: "path-1",
            points:
              "0.0890093747 40.9747362 40.8451041 40.9747362 40.8451041 0.232216418 0.0890093747 0.232216418",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Smileys",
                transform: "translate(-91.000000, 0.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "ok",
                    transform: "translate(91.526740, 0.000000)",
                  },
                },
                [
                  _c("g", { attrs: { id: "Group-39" } }, [
                    _c("mask", { attrs: { id: "mask-2", fill: "white" } }, [
                      _c("use", { attrs: { "xlink:href": "#path-1" } }),
                    ]),
                    _vm._v(" "),
                    _c("g", { attrs: { id: "Clip-38" } }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M20.9242705,38.3033231 C20.7691641,38.3075137 20.6124274,38.3091433 20.4577868,38.3091433 C10.7843652,38.3091433 3.01111331,30.7327749 2.76121969,21.0606419 C2.63895114,16.3329489 4.3658488,11.8406278 7.62354866,8.41132178 C10.8817143,4.98201573 15.2803544,3.0259377 20.0094688,2.90394474 C20.1648081,2.89998695 20.3215447,2.89789165 20.4761853,2.89789165 C30.149374,2.89789165 37.922626,10.4744929 38.1729854,20.1463931 C38.4252079,29.9058303 30.6873557,38.0509559 20.9242705,38.3033231 M40.8382068,20.0777138 C40.7001016,14.7309764 38.5127755,9.73298983 34.6793655,6.00428884 C30.72555,2.15871673 25.4789485,0.0915878478 19.9405327,0.239655644 C14.4994659,0.379807867 9.43847967,2.63039181 5.69007538,6.57583985 C1.94213688,10.5215207 -0.0446687756,15.6901577 0.0959982757,21.1295539 C0.233870617,26.4762914 2.42119669,31.4740452 6.25460673,35.202979 C10.08103,38.9249284 15.1254809,40.9748293 20.4577868,40.9748293 C20.6352509,40.9748293 20.814811,40.9725012 20.9932066,40.9673793 C32.2258433,40.6772968 41.1283908,31.3061884 40.8382068,20.0777138",
                        id: "Fill-37",
                        fill: "#333333",
                        mask: "url(#mask-2)",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M17.0008009,15.9291615 C16.9626065,14.4575632 15.7385237,13.2953706 14.2664104,13.3333188 C12.7942971,13.371267 11.6319308,14.5951545 11.6698923,16.0667528 C11.7078538,17.5385839 12.9317037,18.7005437 14.4045157,18.6623626 C15.8763961,18.6244145 17.0387624,17.4007598 17.0008009,15.9291615",
                      id: "Fill-40",
                      fill: "#333333",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M26.5298525,13.3334585 C25.0577392,13.3714067 23.8951399,14.5950613 23.9335672,16.0664269 C23.9712958,17.538258 25.1956115,18.7004505 26.6674919,18.6622695 C28.1396052,18.6243213 29.3019715,17.4006667 29.26401,15.9290684 C29.2262815,14.45747 28.0017329,13.2952775 26.5298525,13.3334585",
                      id: "Fill-42",
                      fill: "#333333",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M28.2737978,27.0880489 L12.6611525,27.0880489 C11.9273083,27.0880489 11.3322681,27.682881 11.3322681,28.4162356 C11.3322681,29.1498231 11.9273083,29.7446552 12.6611525,29.7446552 L28.2737978,29.7446552 C29.0074091,29.7446552 29.6024494,29.1498231 29.6024494,28.4162356 C29.6024494,27.682881 29.0074091,27.0880489 28.2737978,27.0880489",
                      id: "Fill-44",
                      fill: "#333333",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }