import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
import _ from 'lodash';
export default {
  install: function install(Vue) {
    Vue.filter('number', function (number, precision) {
      if (_.isNil(number)) {
        return number;
      } else {
        if (!(_.isString(number) || _.isNumber(number)) || _.isNaN(number)) {
          return '';
        }
        return _.round(number, precision).toFixed(precision);
      }
    });
    Vue.filter('groupedNumber', function (number, rounded, charAfterComma) {
      if (_.isNil(number) || !number.toLocaleString) {
        return number;
      } else {
        if (!(_.isString(number) || _.isNumber(number)) || _.isNaN(number)) {
          return '';
        }
        if (rounded) {
          if (charAfterComma) {
            var factor = Math.pow(10, charAfterComma);
            return (Math.round(number * factor) / factor).toLocaleString('de-CH', {
              maximumFractionDigits: 10
            });
          } else {
            return Math.round(number).toLocaleString('de-CH');
          }
        } else {
          return number.toLocaleString('de-CH');
        }
      }
    });
  }
};