import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PrintSingleComment from '@/components/print/report/PrintSingleComment';
export default {
  name: 'PrintComments',
  components: {
    PrintSingleComment: PrintSingleComment
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    var grid = this.$el.getElementsByClassName('comment-grid')[0];
    var allCommentEls = grid.querySelectorAll('.print-comment');
    var rowSpans = new Array(allCommentEls.length);
    allCommentEls.forEach(function (el, index) {
      rowSpans[index] = _this.calculateCommentElementRowSpan(grid, el);
    });
    allCommentEls.forEach(function (el, index) {
      el.style.gridRowEnd = 'span ' + rowSpans[index];
      var contentEl = el.querySelector('.content');
      contentEl.style.height = '100%';
    });
  },
  methods: {
    calculateCommentElementRowSpan: function calculateCommentElementRowSpan(grid, el) {
      var rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
      var rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
      var contentEl = el.querySelector('.content');
      var contentPadding = 2 * 10;
      var rowSpan = Math.ceil((contentEl.getBoundingClientRect().height + contentPadding + rowGap) / (rowHeight + rowGap));
      return rowSpan;
    }
  }
};