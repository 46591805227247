import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FormInput',
  props: {
    value: {
      required: false,
      type: String,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: function validator(item) {
        return item === null || ['text', 'number'].includes(item);
      }
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off',
      validator: function validator(v) {
        return ['on', 'off'].includes(v);
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      type: Number,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    onBlur: function onBlur(event) {
      this.$emit('blur', event);
    },
    changeValue: function changeValue(value) {
      var _this = this;
      var adaptedValue = value;
      if (value === null || value.trim() === '') {
        adaptedValue = null;
      } else {
        if (this.type === 'number') {
          adaptedValue = value.replace(/[^0-9\-.,]/g, '');
        } else {
          adaptedValue = value.replace(/^\s+/, '').replace(/(\s)\s+$/, '$1');
        }
      }
      // first emit the changed value to trigger an update of the value
      this.$emit('input', value);

      // then, if the value was adapted, retrigger this change
      // otherwise, when the previousValue === adaptedValue, no rerendering is made
      if (value !== adaptedValue) {
        this.$nextTick(function () {
          _this.$emit('input', adaptedValue);
        });
      }
    }
  }
};