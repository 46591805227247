import "core-js/modules/es.function.name.js";
import _ from 'lodash';
import { Bus as VuedalsBus } from 'vuedals';
import ConfirmHeader from '@/components/shared/ConfirmHeader';
import Confirm from '@/components/shared/Confirm';

/*
 * The API for ConfirmService requires the following properties to
 * be passed: name, title, text, type
 *
 * The following properties are optional: okayButton,
 * dismissButton, icon, textIsHtml
 *
 * The configuration is then passed to Vuedals (every original
 * property except header and props can be used additionally)
 */
var defaultConfig = {
  props: {},
  escapable: true,
  closeOnBackdrop: false,
  size: 'sm',
  component: Confirm,
  header: {
    component: ConfirmHeader,
    props: {}
  }
};
export default {
  createModal: function createModal(config) {
    if (config.name && config.text && config.title && config.type && typeof config.header === 'undefined' && typeof config.props === 'undefined') {
      var finalConfig = _.merge({}, defaultConfig, config);
      finalConfig.header.props.type = config.type;
      finalConfig.header.props.title = config.title;
      finalConfig.header.props.icon = config.icon;
      finalConfig.props.closeOnBackdrop = config.closeOnBackdrop;
      finalConfig.props.okayButton = config.okayButton;
      finalConfig.props.dismissButton = config.dismissButton;
      finalConfig.props.text = config.text;
      finalConfig.props.textIsHtml = config.textIsHtml;
      finalConfig.props.type = config.type;
      finalConfig.props.requireMessage = config.requireMessage;
      delete finalConfig.title;
      VuedalsBus.$emit('new', finalConfig);
    } else {
      window.console.warning('ConfirmService requires different parameters.');
    }
  }
};