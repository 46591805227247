var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goal-table" },
    [
      _c("b-table", {
        attrs: {
          responsive: "",
          striped: "",
          hover: "",
          items: _vm.goals,
          fields: _vm.fields,
          "sort-by": "startDate",
          "show-empty": true,
          "empty-text": _vm.$t("emptyTableText"),
          "sort-compare": _vm.goalTableSort,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(priority)",
            fn: function (data) {
              return [
                data.value === "HIGH"
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.d200.window",
                            modifiers: {
                              hover: true,
                              d200: true,
                              window: true,
                            },
                          },
                        ],
                        staticClass: "priority-icon-wrapper",
                        attrs: {
                          title: _vm.$t("tooltip.priority." + data.value),
                        },
                      },
                      [
                        _c("font-awesome", {
                          staticClass: "priority-icon red",
                          attrs: { icon: ["far", "long-arrow-up"] },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                data.value === "LOW"
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.d200.window",
                            modifiers: {
                              hover: true,
                              d200: true,
                              window: true,
                            },
                          },
                        ],
                        staticClass: "priority-icon-wrapper",
                        attrs: {
                          title: _vm.$t("tooltip.priority." + data.value),
                        },
                      },
                      [
                        _c("font-awesome", {
                          staticClass: "priority-icon blue",
                          attrs: { icon: ["far", "long-arrow-down"] },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(title)",
            fn: function (data) {
              return [
                _c("span", { staticClass: "goal-title" }, [
                  !_vm.isLoading
                    ? _c(
                        "a",
                        {
                          staticClass: "clickable-link",
                          on: {
                            click: function ($event) {
                              return _vm.viewDetails(data.item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(data.value) + "\n        "
                          ),
                        ]
                      )
                    : _c("a", [_vm._v(_vm._s(data.value))]),
                ]),
              ]
            },
          },
          {
            key: "cell(goalDomainType)",
            fn: function (data) {
              return [
                _vm.goalHasMultipleLocationsOrGroups(data.item)
                  ? [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.html.d200.window",
                              modifiers: {
                                hover: true,
                                html: true,
                                d200: true,
                                window: true,
                              },
                            },
                          ],
                          staticClass: "text-nowrap",
                          attrs: {
                            title: _vm.getGoalDomainTypeTooltip(data.item),
                          },
                        },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "badge-fix",
                              attrs: { variant: "secondary" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getGoalAmountOfLocationsOrGroups(
                                    data.item
                                  )
                                )
                              ),
                            ]
                          ),
                          _vm._v(
                            " \n          " +
                              _vm._s(_vm.$t("goalDomainType." + data.value)) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ]
                  : data.item.goalDomainType === "LOCATION"
                  ? [
                      _vm._v(
                        "\n        " +
                          _vm._s(data.item.locations[0].name) +
                          "\n      "
                      ),
                    ]
                  : data.item.goalDomainType === "LOCATION_GROUP"
                  ? [
                      _vm._v(
                        "\n        " +
                          _vm._s(data.item.locationGroups[0].name) +
                          "\n      "
                      ),
                    ]
                  : data.item.goalDomainType === "COMPANY"
                  ? [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("goalDomainType." + data.value)) +
                          "\n      "
                      ),
                    ]
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(goalDateRange)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.formatDate(data.item.startDate)) +
                    " -\n      " +
                    _vm._s(_vm.formatDate(data.item.endDate)) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(goalCurrentValue)",
            fn: function (data) {
              return [
                data.item.statistics.satisfactionRate
                  ? _c(
                      "span",
                      {
                        staticClass: "number",
                        class: [
                          data.item.statistics.satisfactionRate >
                          data.item.value
                            ? "green"
                            : "red",
                        ],
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm._f("groupedNumber")(
                                data.item.statistics.satisfactionRate,
                                true,
                                2
                              )
                            ) +
                            _vm._s(
                              data.item.goalType === "OVERALL_SATISFACTION"
                                ? "%"
                                : ""
                            ) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !data.item.statistics.satisfactionRate
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.d200.window",
                            modifiers: {
                              hover: true,
                              d200: true,
                              window: true,
                            },
                          },
                        ],
                        staticClass: "no-data",
                        attrs: {
                          title: _vm.$t("tooltip.currentValueNotAvailable"),
                        },
                      },
                      [
                        !data.item.statistics.satisfactionRate
                          ? _c("font-awesome", {
                              staticClass: "info-circle",
                              attrs: { icon: ["far", "info-circle"] },
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("noCurrentValue")) +
                            "\n      "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(goalTargetValue)",
            fn: function (data) {
              return [
                !_vm.goalMatchesCompanySetting(data.item)
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.d200.window",
                            modifiers: {
                              hover: true,
                              d200: true,
                              window: true,
                            },
                          },
                        ],
                        staticClass: "number",
                        attrs: {
                          title: _vm.$t(
                            "tooltip.goalTypeDoesNotMatchCompanySetting"
                          ),
                        },
                      },
                      [
                        _c("font-awesome", {
                          staticClass: "exclamation-triangle",
                          attrs: { icon: ["far", "exclamation-triangle"] },
                        }),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm._f("groupedNumber")(data.item.value, true, 2)
                            ) +
                            _vm._s(
                              data.item.goalType === "OVERALL_SATISFACTION"
                                ? "%"
                                : ""
                            ) +
                            "\n      "
                        ),
                      ],
                      1
                    )
                  : _c("span", { staticClass: "number" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("groupedNumber")(data.item.value, true, 2)
                        ) +
                          _vm._s(
                            data.item.goalType === "OVERALL_SATISFACTION"
                              ? "%"
                              : ""
                          )
                      ),
                    ]),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex float-right" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-1",
                        attrs: {
                          variant: "info",
                          size: "sm",
                          disabled: _vm.isLoading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.viewDetails(data.item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("actions.viewDetails")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.type === "active" || _vm.type === "completed"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              variant: "primary",
                              size: "sm",
                              disabled: _vm.isLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editGoal(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("actions.editGoal")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "completed"
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "secondary",
                              size: "sm",
                              disabled: _vm.isLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.archiveGoal(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("actions.archiveGoal")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "archived"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              variant: "secondary",
                              size: "sm",
                              disabled: _vm.isLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.unarchiveGoal(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("actions.unarchiveGoal")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === "active" || _vm.type === "archived"
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "danger",
                              size: "sm",
                              disabled: _vm.isLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteGoal(data.item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("actions.deleteGoal")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }