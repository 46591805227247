//
//
//
//

import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
export default {
  name: 'ProgressBar',
  components: {
    NprogressContainer: NprogressContainer
  }
};